<template lang="pug">
div
    .card__body.p-4.pt-0(v-if="status_search_dcd === 'loading'")
        Loading(show="true")
    .card__body.p-4.pt-0(v-else-if="Object.keys(search_dcd).length")
        .card(v-if="search_dcd?.digitalAssets")
            .card__header
                h2.text-xl Cyber Print
            .card__body
                .grid.grid-cols-3.gap-4(
                    v-if="search_dcd?.digitalAssets?.loginCredentials"
                    v-for="data in search_dcd?.digitalAssets?.loginCredentials"
                    :class="{'border-custom':search_dcd?.digitalAssets?.loginCredentials.length>1}"
                )
                    .flex-grow(v-if="data?.email")
                        h3.mb-6.text-white Emails
                        .rollout
                            span.text-xs Email
                            p.text-light {{ data?.email }}
                    .flex-grow(v-if="data?.hashedPassword || data?.salt || data?.nickName || data?.userName || data?.password")
                        h3.mb-6.text-white Cyber Data Account
                        .rollout(v-if="data?.hashedPassword")
                            span.text-xs Encrypted Password
                            p.text-light {{ data?.hashedPassword }}
                        
                        .rollout(v-if="data?.salt")
                            span.text-xs Salt Hash
                            p.text-light {{ data?.salt }}
                        
                        .rollout(v-if="data?.nickName")
                            span.text-xs Nick Name
                            p.text-light {{ data?.nickName }}
                        
                        .rollout(v-if="data?.userName")
                            span.text-xs User Name
                            p.text-light {{ data?.userName }}

                        .rollout(v-if="data?.password")
                            span.text-xs Known Pasword
                            p.text-light {{ data?.password }}
                    
                    .flex-grow(v-if="data?.IPaddress||data?.mobileOS||data?.keyboardLanguage||data?.userAgent")
                        h3.mb-6.text-white Cyber Assets
                        .rollout(v-if="data?.IPaddress")
                            span.text-xs IP Address
                            p.text-light {{ data?.IPaddress }}
                    
                        .rollout(v-if="data?.mobileOS")
                            span.text-xs Mobile OS
                            p.text-light {{ data?.mobileOS }}
                    
                        .rollout(v-if="data?.keyboardLanguage")
                            span.text-xs Cyber Language
                            p.text-light {{ data?.keyboardLanguage }}

                        .rollout(v-if="data?.userAgent")
                            span.text-xs Cyber User Agent
                            p.text-light {{ data?.userAgent }}

        .card(v-if="search_dcd?.personalInfo")
            .card__header
                h2.text-xl Digital Print
            .card__body
                .grid.grid-cols-4.gap-4
                    .flex-grow(v-if="search_dcd?.personalInfo?.declaredByPerson?.generalInformation")
                        h3.mb-6.text-white Digital Information
                        .rollout(v-if="search_dcd?.personalInfo?.declaredByPerson?.generalInformation?.birthDate")
                            span.text-xs DOB
                            p.text-light {{ toString(search_dcd?.personalInfo?.declaredByPerson?.generalInformation?.birthDate, null) }}
                        .rollout(v-if="search_dcd?.personalInfo?.declaredByPerson?.generalInformation?.firstName")
                            span.text-xs Digital First Name
                            p.text-light {{ toString(search_dcd?.personalInfo?.declaredByPerson?.generalInformation?.firstName, null) }}
                        
                        .rollout(v-if="search_dcd?.personalInfo?.declaredByPerson?.generalInformation?.lastName")
                            span.text-xs Digital Last Name
                            p.text-light {{ toString(search_dcd?.personalInfo?.declaredByPerson?.generalInformation?.lastName, null) }}

                        .rollout(v-if="search_dcd?.personalInfo?.declaredByPerson?.generalInformation?.fullName")
                            span.text-xs Digital Full Name
                            p.text-light {{ toString(search_dcd?.personalInfo?.declaredByPerson?.generalInformation?.fullName, null) }}
                        
                        .rollout(v-if="search_dcd?.personalInfo?.declaredByPerson?.generalInformation?.additionalInfo")
                            span.text-xs Additional Information
                            p.text-light {{ toString(search_dcd?.personalInfo?.declaredByPerson?.generalInformation?.additionalInfo, null) }}
                    
                    .flex-grow(v-if="search_dcd?.personalInfo?.nationalIDInfo")
                        h3.mb-6.text-white Digital Citizen ID
                        .rollout(v-for="data, index in search_dcd?.personalInfo?.nationalIDInfo" v-if="data?.nationalID")
                            span.text-xs Citizen ID
                            button.link.flex.items-center.text-sm.text-left(@click="detailNik(true, data?.nationalID)")
                                span {{ data?.nationalID }}
                            //- button
                                .text-link {{ data?.nationalID }}
                        //- .rollout(v-for="data in search_dcd?.personalInfo?.validatedPersonalInfo" v-if="data?.birthDate")
                        //-     span.text-xs DOB
                        //-     p.text-light {{ data?.birthDate }}

                        //- template(v-for="data, index in search_dcd?.personalInfo?.validatedPersonalInfo" v-if="search_dcd?.personalInfo?.validatedPersonalInfo && index==0")
                            .rollout(v-if="data?.birthPlace.city")
                                span.text-xs City
                                p.text-light {{ data?.birthPlace.city }}

                            .rollout(v-if="data?.birthPlace.district")
                                span.text-xs District
                                p.text-light {{ data?.birthPlace.district }}

                            .rollout(v-if="data?.birthPlace.province")
                                span.text-xs Province
                                p.text-light {{ data?.birthPlace.province }}

                            .rollout(v-if="data?.birthPlace.zipCode")
                                span.text-xs Zip Code
                                p.text-light {{ data?.birthPlace.zipCode }}

                            .rollout(v-if="data?.gender")
                                span.text-xs Gender
                                p.text-light {{ data?.gender }}
                    
                    
                    .flex-grow(v-if="search_dcd?.personalInfo?.validatedPersonalInfo")
                        h3.mb-6.text-white Identity Footprint
                        //- .rollout(v-for="data in search_dcd?.personalInfo?.validatedPersonalInfo" v-if="data?.birthDate")
                        //-     span.text-xs DOB
                        //-     p.text-light {{ data?.birthDate }}

                        template(v-for="data in search_dcd?.personalInfo?.validatedPersonalInfo")
                            .rollout(v-for="data in search_dcd?.personalInfo?.validatedPersonalInfo" v-if="data?.birthDate")
                                span.text-xs DOB
                                p.text-light {{ data?.birthDate }}

                            .rollout(v-if="data?.birthPlace?.city")
                                span.text-xs City
                                p.text-light {{ data?.birthPlace?.city }}

                            .rollout(v-if="data?.birthPlace?.district")
                                span.text-xs District
                                p.text-light {{ data?.birthPlace?.district }}

                            .rollout(v-if="data?.birthPlace?.province")
                                span.text-xs Province
                                p.text-light {{ data?.birthPlace?.province }}

                            .rollout(v-if="data?.birthPlace?.zipCode")
                                span.text-xs Zip Code
                                p.text-light {{ data?.birthPlace?.zipCode }}

                            .rollout(v-if="data?.gender")
                                span.text-xs Gender
                                p.text-light {{ data?.gender }}
                    
                    .flex-grow(v-if="search_dcd?.personalInfo?.declaredByPerson?.geoLocation?.geoLocationGeneralInfo")
                        h3.mb-6.text-white Detected Geolocation
                        .rollout(v-for="data in search_dcd?.personalInfo?.declaredByPerson?.geoLocation?.geoLocationGeneralInfo" v-if="data?.address")
                            span.text-xs Location
                            p.text-light {{ data?.address }}
                        .rollout(v-for="data in search_dcd?.personalInfo?.declaredByPerson?.geoLocation?.geoLocationGeneralInfo" v-if="data?.country")
                            span.text-xs Country
                            p.text-light {{ data?.country }}
        
        .card(v-if="search_dcd?.telecom")
            .card__header
                h2.text-xl Telecom Close Print
            .card__body
                .grid.grid-cols-5.gap-4(v-for="data, idx in search_dcd?.telecom")
                    //- (v-if="data?.cellCompany")
                    .rollout
                        span.text-xs Provider Name
                        p.text-light {{data?.cellCompany || '-'}}
        
                    //- (v-if="data?.mobileNumber")
                    .rollout
                        span.text-xs MSISDN
                        p.text-light {{data?.mobileNumber || '-'}}

                    //- (v-if="data?.numberRegisterTime")
                    .rollout
                        span.text-xs Detection Date
                        p.text-light {{data?.numberRegisterTime || '-'}}
                    
                    .rollout(v-if="data?.target && data.target.length > 0")
                        span.text-xs Known Target
                        //- p.text-light {{data?.target || '-'}}
                        button.link.flex.items-center.text-sm.text-left(
                            @click="detailTarget(target)"
                            v-for="target in data.target.slice(0,4)"
                            :class="{'mb-2':data.target.length > 1}"
                        )
                            span {{ target.name }}
                        template(v-if="data.target.length > 4")
                            button.link(@click="getTarget(true, data.target)") More
                    
                    .rollout(v-if="data?.target && data.target.length > 0")
                        span.text-xs Known Case
                        //- p.text-light {{data?.target || '-'}}
                        .flex(
                            v-for="target, idx in data.target"
                        ) 
                            span(:class="{'mt-5':idx > 0}") {{ toString(target.cases) }}
        
        .card(v-if="search_dcd?.physicalAssets?.vehicles")
            .card__header
                h2.text-xl Physical Print
            .card__body
                h3.mb-6.text-white Vehicle Print
                .grid.grid-cols-4.gap-4.border-custom(
                    v-for="data in search_dcd?.physicalAssets?.vehicles"
                    :class="{'border-custom':search_dcd?.physicalAssets?.vehicles.length>1}"
                )
                    .rollout
                        span.text-xs Digital Vehicle Code
                        p.text-light {{ data?.proofOfOwnershipNumber }}
                    
                    .rollout(v-if="data?.vehicleBrand")
                        span.text-xs Vehicle Manufacture
                        p.text-light {{ data?.vehicleBrand }}

                    .rollout(v-if="data?.vehicleColor")
                        span.text-xs Vehicle Color
                        p.text-light {{ data?.vehicleColor }}
                    
                    .rollout(v-if="data?.vehicleModel")
                        span.text-xs Vehicle MOdel
                        p.text-light {{ data?.vehicleModel }}
                    
                    .rollout(v-if="data?.vehicleMotorNumber")
                        span.text-xs Vehicle Engine Number
                        p.text-light {{ data?.vehicleMotorNumber }}

                    .rollout(v-if="data?.vehicleNumber")
                        span.text-xs Vehicle Plate Number
                        p.text-light {{ data?.vehicleNumber }}

                    .rollout(v-if="data?.vehicleYear")
                        span.text-xs Production Year-CC
                        p.text-light {{ data?.vehicleYear }}

                    .rollout(v-if="data?.vin")
                        span.text-xs VIN
                        p.text-light {{ data?.vin }}
        
        .card(v-if="search_dcd?.professionalInformation")
            .card__header
                h2.text-xl Other Digital Data
            .card__body
                h3.mb-6.text-white Workplace
                .grid.grid-cols-3.gap-4
                    .rollout(v-for="data in search_dcd?.professionalInformation?.workplaces?.roles" v-if="data.jobTitle")
                        span.text-xs Work Title
                        p.text-light {{ data.jobTitle }}
                    .rollout(v-for="data in search_dcd?.professionalInformation?.workplaces?.workplaceGeneralInfo" v-if="data.company")
                        span.text-xs Workplace Info
                        p.text-light {{ data.company }}

        .card(v-if="search_dcd?.educationInformation")
            .card__header
                h2.text-xl Other Identified Data
            .card__body
                h3.mb-6.text-white Education Info
                .grid.grid-cols-3.gap-4
                    .rollout(v-for="data in search_dcd?.educationInformation?.educationGeneralInfo" v-if="data.educationTitle")
                        span.text-xs Education Title
                        p.text-light {{ data.educationTitle }}
                
                        .card__body.p-4.pt-0
        .card(v-if="'possiblePerson' in search_dcd")
            .card__header
                h2.text-xl Possible Person
            .card__body
                h3.mb-6.text-white Data Person
                .grid.grid-cols-4.gap-4.border-custom(
                    v-for="data in search_dcd?.possiblePerson"
                    :class="{'border-custom':search_dcd?.possiblePerson.length>1}"
                )
                    .rollout
                        span.text-xs Name
                        p.text-light {{ data?.NAME }}
                    
                    .rollout
                        span.text-xs Citizen ID
                        button.link.flex.items-center.text-sm.text-left(@click="detailNik(true, data?.NIK)")
                            span {{ data?.NIK }}

        //- template
        //- .card
            .card__header
                h2.text-xl Cyber Print
            .card__body
                .grid.grid-cols-3.gap-4
                    .flex-grow
                        .rollout
                            span.text-xs Email
                            p.text-light name@domain.com
                    
                    .flex-grow
                        h3.mb-6.text-white Cyber Data Account
                        .rollout
                            span.text-xs Encrypted Password
                            p.text-light bc6b79c7716722cb383321e40f31734bce0c3598
                        
                        .rollout
                            span.text-xs Salt Hash
                            p.text-light bc6b79c7716722cb383321
                        
                        .rollout
                            span.text-xs Nick Name
                            p.text-light @NickNameAccount
                        
                        .rollout
                            span.text-xs User Name
                            p.text-light User Full Name

                        .rollout
                            span.text-xs User Name
                            p.text-light User Full Name

                        .rollout
                            span.text-xs Known Pasword
                            p.text-light @NickNameAccount_User_Name123
                    
                    .flex-grow
                        h3.mb-6.text-white Cyber Assets
                        .rollout
                            span.text-xs IP Address
                            p.text-light 127.0.0.1
                    
                        .rollout
                            span.text-xs Mobile OS
                            p.text-light Android

                        .rollout
                            span.text-xs Cyber Language
                            p.text-light ID

                        .rollout
                            span.text-xs Cyber User Agent
                            p.text-light Agent XYZ

        //- .card
            .card__header
                h2.text-xl Digital Print
            .card__body
                .grid.grid-cols-3.gap-4
                    .flex-grow
                        h3.mb-6.text-white Digital Information
                        .rollout
                            span.text-xs DOB
                            p.text-light 12 March 2023
                        .rollout
                            span.text-xs Digital First Name
                            p.text-light -
                        
                        .rollout
                            span.text-xs Digital Last Name
                            p.text-light -

                        .rollout
                            span.text-xs Digital Full Name
                            p.text-light -
                    
                    .flex-grow
                        h3.mb-6.text-white Digital Citizen ID
                        .rollout
                            span.text-xs Citizen ID
                            p.text-light 12 March 2023
                        .rollout
                            span.text-xs DOB
                            p.text-light -
                        
                        .rollout
                            span.text-xs City
                            p.text-light -

                        .rollout
                            span.text-xs District
                            p.text-light -

                        .rollout
                            span.text-xs Province
                            p.text-light -

                        .rollout
                            span.text-xs Zip Code
                            p.text-light -

                        .rollout
                            span.text-xs Gender
                            p.text-light -
        
        //- .card
            .card__header
                h2.text-xl Telecom Close Print
            .card__body
                .grid.grid-cols-3.gap-4
                    .rollout
                        span.text-xs Provider Name
                        p.text-light -
        
                    .rollout
                        span.text-xs MSISDN
                        p.text-light -

                    .rollout
                        span.text-xs Detection Date
                        p.text-light -
        
        //- .card
            .card__header
                h2.text-xl Physical Print
            .card__body
                h3.mb-6.text-white Vehicle Print
                .grid.grid-cols-3.gap-4
                    .rollout
                        span.text-xs Digital Vehicle Code
                        p.text-light -
                    
                    .rollout
                        span.text-xs Vehicle Manufacture
                        p.text-light -

                    .rollout
                        span.text-xs Vehicle Color
                        p.text-light -
                    
                    .rollout
                        span.text-xs Vehicle Engine Number
                        p.text-light -

                    .rollout
                        span.text-xs Vehicle Plate Number
                        p.text-light -

                    .rollout
                        span.text-xs Production Year-CC
                        p.text-light -

                    .rollout
                        span.text-xs VIN
                        p.text-light -
        
        //- .card
            .card__header
                h2.text-xl Other Digital Data
            .card__body
                h3.mb-6.text-white Workplace
                .grid.grid-cols-3.gap-4
                    .rollout
                        span.text-xs Work Roles
                        p.text-light -
                    .rollout
                        span.text-xs Work Title
                        p.text-light -
                    .rollout
                        span.text-xs Workplace Info
                        p.text-light -

        //- .card
            .card__header
                h2.text-xl Other Identified Data
            .card__body
                h3.mb-6.text-white Education Info
                .grid.grid-cols-3.gap-4
                    .rollout
                        span.text-xs Education Title
                        p.text-light -

    //- Empty Data of ID Convert           
    .card__body.p-4.pt-0(v-else-if="typeValue=='id_convert'")
        .card
            .card__header
                h2.text-xl Digital Print
            .card__body
                .grid.grid-cols-4.gap-4
                    .flex-grow
                        h3.mb-6.text-white Digital Citizen ID
                        .rollout
                            span.text-xs Citizen ID
                            button.link.flex.items-center.text-sm.text-left(@click="detailNik(true, keyValue)")
                                span {{ keyValue }}
    .card__body.p-4.pt-32(v-else)
        NoDataAvailable

    .popup-layer.is-show(
        v-if="modalNik"
    )
        .popup-container.xl
            .popup-container__header
                .flex.items-center
                    h2.text-xl Digital ID - {{selectedNik}}

                button.btn-icon-40(
                    @click="detailNik(false)"
                )
                    IconSet( type="close")

            .popup-container__body
                PersonalInfo
                FamilyInfo
                
            .popup-container__footer
                .flex.justify-end.w-full
                    button.btn-primary(@click="detailNik(false)") Hide
</template>


<script>
import IconSet from '@/components/IconSet.vue';
import Loading from '@/components/Loading.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';
import Spinner from '@/components/Spinner.vue';
import { mapState } from 'vuex';
import PersonalInfo from '@/pages/result/profile/Personal.vue'
import FamilyInfo from '@/pages/result/profile/Family.vue'
import { encrypt } from '@/util/crypto';

export default {
    name: 'pageDcdResult',
    props: {
        keyValue : String,
        typeValue : String,
        sourceValue : String
    },
    components: {
        IconSet,
        Loading,
        NoDataAvailable,            
        Spinner,    
        PersonalInfo,
        FamilyInfo
    },
    data() {
        return {
            modalNik: false,
            selectedNik: null,
        }
    },
    computed: {
        ...mapState('dcd', [
            'status_search_dcd',
            'search_dcd',
        ]),
        ...mapState('profile', [
            'profile_identity',
            'profile_family',
        ]),
        route_name() {
            return this.$route.name;
        },
        id() {
            return this.$route.params.id;
        },

    },
    methods: {
        async getData() {
            this.$store.dispatch('dcd/searchDcd', [this.keyValue, this.typeValue, this.sourceValue]);
        },
        detailNik(status=false, nik=null){
            if(status) {
                EventEmit.$emit('overflowHidden', true);
            } else {
                EventEmit.$emit('overflowHidden', false);
            }
            this.modalNik = status
            this.selectedNik = nik
        },
        detailTarget(data) {
            // console.log('click')
            var msisdn = this.checkMsisdn(data)
            if (msisdn.length > 0) {
                // this.$router.push({ name: 'pageTargetDetailHome', params: { id: data.id ,key: encrypt(msisdn[0].value + '&&msisdn') }  })
                const routeData = this.$router.resolve({ name: 'pageTargetDetailHome', params: { id: data.id ,key: encrypt(msisdn[0].value + '&&msisdn') }  })
                // const routeData = this.$router.resolve({name: 'routeName', query: {data: "someData"}});
                window.open(routeData.href, '_blank');
            } else {
                let clues = data.clues
                const routeData = this.$router.resolve({ name: 'pageTargetDetailHome', params: { id: data.id ,key: encrypt(clues[0].value + '&&'+clues[0].name) }  })
                window.open(routeData.href, '_blank');
                // EventEmit.$emit('error', 'Target does not have a MSISDN clue')
            }
        },
        checkMsisdn(data) {
            var msisdn = data.clues.filter(function (clue) {
                return clue.name === 'msisdn'
            })
            return msisdn
        },
    },
    watch: {
        route_name() {
            this.getData();
        },
        selectedNik(nik) {
            if(nik != null){
                this.$store.dispatch('profile/getResultData', [nik, 'nik']);
            }
        },

    },
    async beforeCreate() {
        await this.$store.commit('dcd/setStatusSearchDcd', 'loading');
        await this.$store.commit('dcd/setSearchDcd', {});
    },
    async mounted() {
        this.getData();
    },
}
</script>
<style lang="sass">
.border-custom
    @apply .border-2 .rounded-lg .p-4 .mb-4
    border-color: hsl(0deg 0% 67% / 37%)
</style>