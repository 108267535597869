import axios from "@/axios-main";
import axiosOrigin from "axios";

let cancelList, cancelMonitoringList, cancelMonitoringActiveList, cancelMeetingPointList;
var cancelToken = axiosOrigin.CancelToken;

const multi_tracking = {
  namespaced: true,
  state: {
    // module
    status: false,

    //target
    status_list: {
      status: false,
      cancel: null,
      code: null,
    },

    status_detail: {
      status: false,
      cancel: null,
      code: null,
    },
    list: {},
    detail: {},
    
    // monitoring
    monitoring_list: {},
    status_monitoring_list: {
      status: false,
      cancel: null,
      code: null,
    },

    // monitoring active
    monitoring_active_list: {},
    status_monitoring_active_list: {
      status: false,
      cancel: null,
      code: null,
    },

    // meeting_point
    meeting_point_list: {},
    status_meeting_point_list: {
      status: false,
      cancel: null,
      code: null,
    },
    meeting_point_detail: {},
    status_meeting_point_detail: {
      status: false,
      cancel: null,
      code: null,
    },

    // global
    redirect: "index", // index / detail
    show_navbar: true, // index / detail
    
    meeting_point_detail_from_notification: false,
  },
  mutations: {
    // module
    setStatus: (state, status) => {
      state.status = status;
    },
    // target
    setStatusList: (state, status) => {
      state.status_list = status;
    },
    setStatusDetail: (state, status) => {
      state.status_detail = status;
    },

    setList: (state, data) => {
      state.list = data;
    },
    setDetail: (state, data) => {
      state.detail = data;
    },
    
    // monitoring
    setStatusMonitoringList: (state, status) => {
      state.status_monitoring_list = status;
    },
    setMonitoringList: (state, data) => {
      state.monitoring_list = data;
    },

    // monitoring active
    setStatusMonitoringActiveList: (state, status) => {
      state.status_monitoring_active_list = status;
    },
    setMonitoringActiveList: (state, data) => {
      state.monitoring_active_list = data;
    },

    // meeting_point
    setStatusMeetingPointList: (state, status) => {
      state.status_meeting_point_list = status;
    },
    setMeetingPointList: (state, data) => {
      state.meeting_point_list = data;
    },
    setStatusMeetingPointDetail: (state, status) => {
      state.status_meeting_point_detail = status;
    },
    setMeetingPointDetail: (state, data) => {
      state.meeting_point_detail = data;
    },

    // global
    setShowNavbar: (state, boolean) => {
      state.show_navbar = boolean;
    },
    setRedirect: (state, value) => {
      state.redirect = value;
    },

    setMeetingPointFromNotification: (state, boolean) => {
      state.meeting_point_detail_from_notification = boolean;
    },
  },
  getters: {},
  actions: {
    async getList({ commit, dispatch, state }, [limit=null, page=null, keyword=null, order_by=null, from_date=null, to_date=null, monitoring_active=null]) {

      if(state.status_list.cancel != null && state.status_list.status == 'loading')
        await state.status_list.cancel('Operation canceled by the user.');

      let params = {
        limit: limit,
        page: page,
        keyword: keyword,
        order_by: order_by,
        from_date: from_date,
        to_date: to_date,
      };

      if (monitoring_active==true)
        params.monitoring_active = 'true';

      axios.get('api/v1/multi-target/list', {
        params: params,
        cancelToken: new cancelToken(function executor(c) {
          cancelList = c;
          commit('setStatusList', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setList', data);

        if (Object.keys(data).length===0)
          result.status = 'empty';

        commit('setStatusList', result);
      })
      .catch((resp) => {
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelList;
        }

        commit('setStatusList', result);
        if (!axiosOrigin.isCancel(resp)) {
        let code = resp.response && resp.response.status ? resp.response.status : null
        let message = resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null
        dispatch('errors/handleApiResponse', [code, message], { root: true });
        }
      });
    },

    getDetail({ commit, dispatch }, id) {

      let params = new FormData();
      params.append('id', id);
    
      return axios.post('api/v1/multi-target', params)
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        let code = resp.response && resp.response.status ? resp.response.status : null
        if (Object.keys(data).length===0) {
          commit('setStatusDetail', {
            status: 'empty',
            cancel: null,
            code: code
          });
        } else {
          commit('setStatusDetail', {
            status: true,
            cancel: null,
            code: code
          });
        }
        commit('setDetail', data);
        // return 'success';
        return {result: 'success', code: code, data: (resp.response && resp.response.data ? resp.response.data : null)};
      })
      .catch((resp) => {
        // console.log(resp)
        let code = resp.response && resp.response.status ? resp.response.status : null
        commit('setStatusDetail', {
          status: 'failed',
          cancel: null,
          code: code
        });
        let message = resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null
        dispatch('errors/handleApiResponse', [code, message], { root: true });
        return {result: 'failed', code: code, data: (resp.response && resp.response.data ? resp.response.data : null)};
      });
    },

    create({ dispatch }, [
      group_name       = null,
      cases           = null,
      target          = null,
      additional_info = null,
      description     = null
    ]) {

      let params = new FormData();
      params.append('group_name', group_name);
      params.append('cases', cases);
      params.append('target', target);
      params.append('description', description);
      params.append('additional_info', additional_info);

      return axios.post('api/v1/multi-target/create', params)
      .then((resp) => {

        if (resp.status === 200 && resp.data.message === 'Data Successfully Created') {
          return {result: 'success', data: resp.data};
        } else {
          return {result: 'failed', data: resp.data};
        }
      })
      .catch((resp) => {
        // console.log(resp)
        let code = resp.response && resp.response.status ? resp.response.status : null
        let message = resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null
        dispatch('errors/handleApiResponse', [code, message], { root: true });
        return {result: 'failed', code: code, data: (resp.response && resp.response.data ? resp.response.data : null)};
      });
    },

    update({ dispatch }, [
      id,
      group_name       = null,
      cases           = null,
      target          = null,
      additional_info = null,
      description     = null
    ]) {

      let params = new FormData();
      params.append('id', id);
      params.append('group_name', group_name);
      params.append('cases', cases);
      params.append('target', target);
      params.append('description', description);
      params.append('additional_info', additional_info);
    
      return axios.post('api/v1/multi-target/update', params)
      .then((resp) => {
        // console.log(resp.data)
        
        if (resp.status === 200 && resp.data.message === 'Data Successfully Updated') {
          return {result: 'success', data: resp.data};
        } else {
          return {result: 'failed', data: resp.data};
        }
      })
      .catch((resp) => {
        // console.log(resp)
        let code = resp.response && resp.response.status ? resp.response.status : null
        let message = resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null
        dispatch('errors/handleApiResponse', [code, message], { root: true });
        return {result: 'failed', code: code, data: (resp.response && resp.response.data ? resp.response.data : null)};
      });
    },

    delete({ dispatch }, id) {

      let params = new FormData();
      params.append('id', id);

      return axios.post('api/v1/multi-target/delete', params)
      .then((resp) => {
        // console.log(resp)
        
        if (resp.status === 200 && resp.data.message === 'Data Successfully Deleted') {
          return 'success';
        } else {
          return 'failed';
        }
      })
      .catch((resp) => {
        // console.log(resp)
        let code = resp.response && resp.response.status ? resp.response.status : null
        let message = resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null
        dispatch('errors/handleApiResponse', [code, message], { root: true });
        return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
      });

    },

    // Monitoring
    postMonitoring({ dispatch }, [
      multi_tracking_id = null,
      status            = null,
      target            = null,
      frequency         = null,
      time_period       = null,
    ]) {
      // console.log('================================= in postMonitoring()')
      let params = new FormData();

      params.append('multi_tracking_id', multi_tracking_id);
      params.append('status', status);
      params.append('target', target);
      params.append('frequency', frequency);  
      params.append('time_period', time_period);
      
      return axios.post('api/v1/monitoring/multi', params)
      .then((resp) => {

        if (resp.status === 200) {
          return {result: 'success', data: resp.data};
        } else {
          return {result: 'failed', data: resp.data};
        }
      })
      .catch((resp) => {
        // console.log(resp)
        let code = resp.response && resp.response.status ? resp.response.status : null
        let message = resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null
        dispatch('errors/handleApiResponse', [code, message], { root: true });
        return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
      });
    },

    async getMonitoringList({ commit, dispatch, state }, [multi_tracking_id, limit='', page='', keyword='', from_date='', to_date='']) {

      if (state.status_monitoring_list.cancel !== null && state.status_monitoring_list.status === 'loading')
        await state.status_monitoring_list.cancel('Operation canceled by the user.');

      let params = new FormData();

      params.append('multi_tracking_id', multi_tracking_id);
      params.append('limit', limit);
      params.append('page', page);
      params.append('keyword', keyword);
      params.append('from_date', from_date);
      params.append('to_date', to_date);

      axios.post('api/v1/monitoring/multi/list', params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelMonitoringList = c;
          commit('setStatusMonitoringList', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setMonitoringList', data);

        if (Object.keys(data).length===0)
          result.status = 'empty';

        commit('setStatusMonitoringList', result);
      })
      .catch((resp) => {
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelMonitoringList;
        }

        commit('setStatusMonitoringList', result);
        if (!axiosOrigin.isCancel(resp)) {
        let code = resp.response && resp.response.status ? resp.response.status : null
        let message = resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null
        dispatch('errors/handleApiResponse', [code, message], { root: true });
          
        }
      });
    },

    async getMonitoringActiveList({ commit, dispatch, state }, [multi_tracking_id, limit='', page='', keyword='', from_date='', to_date='']) {
      
      if (state.status_monitoring_active_list.cancel !== null && state.status_monitoring_active_list.status === 'loading')
        await state.status_monitoring_active_list.cancel('Operation canceled by the user.');

      let params = new FormData();
  
      params.append('multi_tracking_id', multi_tracking_id);
      params.append('limit', limit);
      params.append('page', page);
      params.append('keyword', keyword);
      params.append('from_date', from_date);
      params.append('to_date', to_date);
      
      axios.post('api/v1/monitoring/multi/list-active', params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelMonitoringActiveList = c;
          commit('setStatusMonitoringActiveList', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setMonitoringActiveList', data);

        if (Object.keys(data).length===0)
          result.status = 'empty';

        commit('setStatusMonitoringActiveList', result);
      })
      .catch((resp) => {
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelMonitoringActiveList;
        }

        commit('setStatusMonitoringActiveList', result);
        if (!axiosOrigin.isCancel(resp)) {
        let code = resp.response && resp.response.status ? resp.response.status : null
        let message = resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null
        dispatch('errors/handleApiResponse', [code, message], { root: true });
        
        }
      });
    },

    // Meeting Point
    async getMeetingPointList({ commit, dispatch, state }, [multi_tracking_id, target_id, limit='', page='', from_date='', to_date='']) {

      if (state.status_meeting_point_list.cancel !== null && state.status_meeting_point_list.status === 'loading')
        await state.status_meeting_point_list.cancel('Operation canceled by the user.');
    
      let params = new FormData();
  
      params.append('multi_tracking_id', multi_tracking_id);
      params.append('target_id', target_id);
      params.append('limit', limit);
      params.append('page', page);
      params.append('from_date', from_date);
      params.append('to_date', to_date);
    
      axios.post('api/v1/meeting-point/list', params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelMeetingPointList = c;
          commit('setStatusMeetingPointList', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setMeetingPointList', data);

        if (Object.keys(data).length===0)
          result.status = 'empty';

        commit('setStatusMeetingPointList', result);
      })
      .catch((resp) => {
        // console.log(resp)
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelMeetingPointList;
        }

        commit('setStatusMeetingPointList', result);
        if (!axiosOrigin.isCancel(resp)) {
        let code = resp.response && resp.response.status ? resp.response.status : null
        let message = resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null
        dispatch('errors/handleApiResponse', [code, message], { root: true });
        
        }
      });
    },
  
    getMeetingPointDetail({ commit, dispatch }, [id]) {
  
      commit('setStatusMeetingPointDetail', {cancel: null, code: null, status: 'loading'});
    
      let params = new FormData();
  
      params.append('id', id);
    
      return axios.post('api/v1/meeting-point', params)
      .then((resp) => {
        let data = resp.data;
        commit('setMeetingPointDetail', data);
        commit('setStatusMeetingPointDetail', {cancel: null, code: null, status: true});
        return 'success';
      })
      .catch((resp) => {
        // console.log(resp)
        let code = resp.response && resp.response.status ? resp.response.status : null
        commit('setStatusMeetingPointDetail', {
          status: 'failed',
          cancel: null,
          code: code
        });
        let message = resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null
        dispatch('errors/handleApiResponse', [code, message], { root: true });
        return 'failed';
      });
    },
    
  }
};

export default multi_tracking;
