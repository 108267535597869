<template lang="pug">
.card.card--tab
    .card--tab__header
        h2.text-xl Data Usage
    .card--tab__body
        .box-tabs
            vue-tabs
                v-tab(title="Domains")
                    DataUsageDomains
                v-tab(title="App Categories")
                    DataUsageAppCategories
                v-tab(title="Web Categories")
                    DataUsageWebCategories
                v-tab(title="Referer Domain")
                    DataUsageRefererDomains
                v-tab(title="Referer URL")
                    DataUsageRefererUrl
</template>

<script>
import { VueTabs,VTab } from 'vue-nav-tabs'
import DataUsageDomains from './DataUsageDomains.vue'
import DataUsageAppCategories from './DataUsageAppCategories.vue'
import DataUsageWebCategories from './DataUsageWebCategories.vue'
import DataUsageRefererDomains from './DataUsageRefererDomains.vue'
import DataUsageRefererUrl from './DataUsageRefererUrl.vue'

export default {
    name: 'BrowsingLogDataUsage',
    components: {
        VueTabs,
        VTab,
        DataUsageDomains,
        DataUsageAppCategories,
        DataUsageWebCategories,
        DataUsageRefererDomains,
        DataUsageRefererUrl
    }
}
</script>