import axios from "@/axios-main";
import axiosOrigin from "axios";
// import { generateRandomString } from '@/util/crypto';

let cancelSubheader;
var cancelToken = axiosOrigin.CancelToken;

const subheader = {
  namespaced: true,
  state: {
    module:'Subheader',
    // request_id: null,

    // load data subheader
    status: false,
    status_subheader: {
      status: false,
      cancel: null,
      code: null
    },
    status_subheader_without_name: {
      status: false,
      cancel: null,
      code: null
    },
    status_subheader_name: {
      status: false,
      cancel: null,
      code: null
    },

    // subheader
    subheader: {},
    subheader_without_name: {},
    subheader_name: {},
  },
  mutations: {
    // setRequestId: (state, id) => {
    //   state.request_id = id;
    // },
    
    // load data subheader
    setStatus: (state, status) => {
      state.status = status;
    },
    setStatusSubheader: (state, status) => {
      state.status_subheader = status;
      state.status_subheader_name = status;
      state.status_subheader_without_name = status;
    },

    // subheader
    setSubheader: (state, data) => {
      state.subheader = data;
    },

    setStatusSubheaderName: (state, status) => {
      state.status_subheader_name = status;
    },

    // subheader
    setSubheaderName: (state, data) => {
      state.subheader_name = data;
    },

    setStatusSubheaderWithoutName: (state, status) => {
      state.status_subheader_without_name = status;
    },

    // subheader
    setSubheaderWithoutName: (state, data) => {
      state.subheader_without_name = data;
    },
  },
  getters: {},
  actions: {
    async getResultData({ state, commit, dispatch }, [key, type, service, addParams = null, getData='full']) {

      // let request_id = generateRandomString(50);
      // await commit('setRequestId', request_id);
      // if (request_id !== state.request_id)
      //   return;

      // console.log('masuk getResultData subheader');
      // console.log(key);
      // console.log(type);
      // console.log(service);
      // console.log('state.status');
      // console.log(state.status);
      // console.log('state.status_subheader.status');
      // console.log(state.status_subheader.status);
      // console.log('state.status_subheader_name.status');
      // console.log(state.status_subheader_name.status);
      // console.log('state.status_subheader_without_name.status');
      // console.log(state.status_subheader_without_name.status);
      // console.log(getData);
      var getSubheader = false
      if((getData == 'full' || getData == 'subintel') && (state.status != true && state.status_subheader.status !=true)) {
        getSubheader = true
        await dispatch('setLoading', [getData]);
        commit('setStatus', true);
      } else if (getData == 'onlyName' && state.status_subheader_name.status !=true) {
        getSubheader = true
        await dispatch('setLoading', [getData]);
      } else if (['withoutName','withoutNameLbs','withoutNameSubintel'].includes(getData) && state.status_subheader_without_name.status !=true) {
        getSubheader = true
        await dispatch('setLoading', [getData]);
      }
      // console.log('state.status');
      // console.log(state.status);
      // console.log('state.status_subheader.status');
      // console.log(state.status_subheader.status);
      // console.log('state.status_subheader_name.status');
      // console.log(state.status_subheader_name.status);
      // console.log('state.status_subheader_without_name.status');
      // console.log(state.status_subheader_without_name.status);
      if(getSubheader) {
        await dispatch('getSubheader', [key, type, service, addParams, getData]);
      }
    },

    async setLoading({ commit, state }, [getData, force=false]) {
      await commit('setStatus', 'loading');
      // console.log('getData xxxxx')
      // console.log(getData)
      // console.log('state.status');
      // console.log(state.status);
      // console.log('state.status_subheader.status');
      // console.log(state.status_subheader.status);
      // console.log('state.status_subheader_name.status');
      // console.log(state.status_subheader_name.status);
      // console.log('state.status_subheader_without_name.status');
      // console.log(state.status_subheader_without_name.status);
      // console.log('force');
      // console.log(force);
      if((getData == 'full' || getData == 'subintel') && ((state.status != true && state.status_subheader.status !=true) || force)) {
        await commit('setStatusSubheader', {
          status: 'loading',
          cancel: state.status_subheader.cancel,
          code: null
        });
      } else if (getData == 'onlyName' && (state.status_subheader_name.status !=true || force)) {
        await commit('setStatusSubheaderName', {
          status: 'loading',
          cancel: state.status_subheader_name.cancel,
          code: null
        });
      } else if (['withoutName','withoutNameLbs','withoutNameSubintel'].includes(getData) && (state.status_subheader_without_name.status !=true || force)) {
        await commit('setStatusSubheaderWithoutName', {
          status: 'loading',
          cancel: state.status_subheader_without_name.cancel,
          code: null
        });
      }
    },

    async setEmpty({ state, commit }) {
      commit('setStatus', false);

      if (state.status_subheader.cancel !== null && state.status_subheader.status === 'loading')
        await state.status_subheader.cancel('Operation canceled by the user.');
      commit('setStatusSubheader', {
        status: false,
        cancel: null,
        code: null
      });
      commit('setStatusSubheaderName', {
        status: false,
        cancel: null,
        code: null
      });
      commit('setStatusSubheaderWithoutName', {
        status: false,
        cancel: null,
        code: null
      });

      commit('setSubheader', {});
      commit('setSubheaderName', {});
      commit('setSubheaderWithoutName', {});
    },

    async getSubheader({ commit, state, dispatch, rootGetters }, [key, type, service_source, addParams, getData]) {
      var statusSubheader = null
      var setStatusSubheader = null
      var setSubheader = null
      var endpoint = null
      if (getData == 'full' || getData == 'subintel') {
        statusSubheader = state.status_subheader
        setStatusSubheader = 'setStatusSubheader'
        setSubheader = 'setSubheader'
        if (getData == 'full')
          endpoint = 'subheader'
        else
          endpoint = 'subheader-subintel'
      } else if (getData == 'onlyName') {
        statusSubheader = state.status_subheader_name
        setStatusSubheader = 'setStatusSubheaderName'
        setSubheader = 'setSubheaderName'
        endpoint = 'subheader-name'
      } else if (['withoutName','withoutNameLbs','withoutNameSubintel'].includes(getData)) {
        statusSubheader = state.status_subheader_without_name
        setStatusSubheader = 'setStatusSubheaderWithoutName'
        setSubheader = 'setSubheaderWithoutName'
        if (getData == 'withoutName')
          endpoint = 'subheader-target'
        else if (getData == 'withoutNameSubintel')
          endpoint = 'subheader-subintel'
        else 
          endpoint = 'subheader-target-lbs' 
      }

      if (statusSubheader.cancel !== null && statusSubheader.status === 'loading')
        await statusSubheader.cancel('Operation canceled by the user.');
      
      let service = 'service';
      if (service_source === 'internal') {
        service = 'service-int'
      } else if (service_source === 'external') {
        service = 'service-ext'
      }

      let params = new FormData();
      params.append('key', key);
      params.append('type', type);
      if (addParams != null) {        
        for (var keyParam in addParams ) {
          params.append(keyParam, addParams[keyParam]);
        }
      }

      console.log({getData, service, endpoint})
      if (['withoutNameLbs', 'withoutNameSubintel'].includes(getData)){
        console.log('get from local storage')
        var lsKey = key + '_' + service_source + '_subheader-realtime';
        console.log({lsKey})
        try {
          let resp = rootGetters['config/getApiResp'](lsKey)
          if (resp==null) throw "Empty"

          resp = JSON.parse(resp)
          console.log({resp})
          if (resp && Object.keys(resp).length>0 && resp.is_realtime && resp.ls_key && resp.status_result){
          
            commit(setSubheader, resp);
            
            commit(setStatusSubheader, resp.status_result);

            return
          }
        } catch (error) {
          console.log('error local storage')
          console.log({error})
        }
      }

      await axios.post('api/v1/'+service+'/overall/'+endpoint, params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelSubheader = c;
          commit(setStatusSubheader, {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (['service-ext', 'service'].includes(service) && ['withoutNameLbs'].includes(getData))
          data['is_realtime'] = true
          data['ls_key'] = key + '_' + service_source + '_subheader-realtime'
          data['status_result'] = result
          // data.is_realtime = true

        console.log({data})
        
        commit(setSubheader, data);

        // if (Object.keys(data).length===0)
        //   result.status = 'empty';
        // setTimeout(() => {
          
        commit(setStatusSubheader, result);
        if (setStatusSubheader == 'setStatusSubheaderName') {
          // call profile
          dispatch('getProfile');
        }
        // }, 5000);
      })
      .catch((resp) => {
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelSubheader;
        }

        commit(setStatusSubheader, result);
        if (setStatusSubheader == 'setStatusSubheaderName') {
          // call profile
          dispatch('getProfile');
        }
        // HIDE 
        // if (!axiosOrigin.isCancel(resp))
        // dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null), state.module], { root: true });
      });
    },
    async getProfile({ state,rootState, dispatch, commit}) {
      // console.log('rootState.profile.fetchProfile')
      // console.log(rootState.profile.fetchProfile)
      // console.log('state.status_subheader_name')
      // console.log(state.status_subheader_name)
      // console.log('rootState.profile.dataRequest')
      // console.log(rootState.profile.dataRequest)
      if (rootState.profile.fetchProfile && state.status_subheader_name !== 'loading') {
        // console.log('=====getSubheader --- personal')
        await dispatch('profile/getResultData', rootState.profile.dataRequest, {root: true});
        commit('profile/setFetchProfile', false, {root: true})
        commit('profile/setDataRequest', [], {root: true})
      }
    },
    async writeLocalStorageRealtime({ state,rootState, dispatch, commit}, [value]) {
      console.log('writeLocalStorageRealtime')
      let subNoName = this.subheader_without_name
      let subheader = this.subheader
      
      console.log({subNoName, subheader, value})
      let fixSubheader = {}
      
      if (value && Object.keys(value).length>0)
        fixSubheader = value
      else if (this.subheader_without_name && Object.keys(this.subheader_without_name).length>0)
        fixSubheader = this.subheader_without_name
      else if (this.subheader && Object.keys(this.subheader).length>0)
        fixSubheader = this.subheader

      console.log({fixSubheader})
      if (fixSubheader && Object.keys(fixSubheader).length>0 && fixSubheader.is_realtime && fixSubheader.ls_key && fixSubheader.status_result){
        let lsKey = fixSubheader.ls_key;

        let dt = {
              resp: value, 
              lsKey: lsKey
            }

        console.log('write local storage')
        console.log({dt})
        dispatch('config/addApiResp', dt, {root: true});
      }

      // console.log({subNoName, subheader, fixSubheader})
    },
  }
};

export default subheader;
