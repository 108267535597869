<template lang="pug">
.card-content
    .flex.flex-wrap(
        class="flex-col-reverse lg:flex-row"
    )
        template(v-if="status_ip_address.status === 'loading'")
            template(v-if="route_name === 'TargetDetailBrowsingLog'")
                Loading(show="true")
            template(v-else)
                LoadingAnimation(show="true" title="ip-address")
        template(v-else)
            NoDataAvailable(v-if='filterNull')
            template(v-else-if="ip_address.chart_client_ip && ip_address.chart_client_ip.categories !== undefined && Object.keys(ip_address.chart_client_ip.series).length > 0 && Object.keys(ip_address.top_client_ip).length > 0")
                .block(class="w-full mb-4 lg:w-8/12 lg:mb-0")
                    .box-charts
                        highcharts(
                            :options="chartOptions"
                        )

                .block(class="w-full lg:w-4/12")
                    .flex(
                        class="pl-0 lg:pl-10 flex-row lg:flex-col"
                    )

                    .top-info
                        .top-info__header
                            h3.font-bold Top IP Client
                        .top-info__body
                            .list-info(v-for="ip in ip_address.top_client_ip")
                                span {{ ip.name }}
                                strong.text-light {{ ip.counter }}

            NoDataAvailable(v-else)

</template>

<script>
import { mapState } from 'vuex';
import Loading from '@/components/Loading.vue';
import LoadingAnimation from '@/components/LoadingAnimation.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';

export default {
    name: 'IpAddressClient',
    components: {
        Loading,
        NoDataAvailable,
        LoadingAnimation
    },
    data() {
        return {
            title: '',
            chartType: 'bar',
            seriesColor: '#6fcd98',
            colorInputIsSupported: null,
            filterNull: false,
            chartOptions: {
                chart: {
                    type: 'bar'
                },
                title: {
                    text: null
                },
                xAxis: {
                    categories: [],
                    title: {
                        text: null
                    },
                    labels: {
                        step: 1
                    }
                },
                yAxis: {
                    title: {
                        text: null
                    },
                },
                series: [],
                legend:{ enabled:false },
                credits: {
                    enabled: false
                },
            },
            dataCat: null,
            dataSeries: null
        }
    },
    computed: {
        ...mapState('browsingLog', [
          'status_ip_address',
          'ip_address',
        ]),
        route_name() {
            return this.$route.name;
        },
    },
    watch: {
        ip_address() {
            if (this.ip_address.chart_client_ip && Object.prototype.hasOwnProperty.call(this.ip_address.chart_client_ip, "categories") && Object.keys(this.ip_address.chart_client_ip).length > 0 && Object.keys(this.ip_address.chart_client_ip.series).length > 0) {
               this.filterChart()
            }
        },
        // filterDateRange() {},
    },
    methods: {
        filterChart(){
            this.dataCat = Object.assign([], JSON.parse(JSON.stringify(this.ip_address.chart_client_ip.categories)));
            this.dataSeries = Object.assign([], JSON.parse(JSON.stringify(this.ip_address.chart_client_ip.series)));

            var categoriesIpClient = []
            var seriesDataIpClient = []

            if (this.dataCat.length == 0) {
                this.filterNull=true
            } else {
                this.filterNull=false            

                categoriesIpClient = this.dataCat
                seriesDataIpClient = this.dataSeries
                
                this.chartOptions.xAxis.categories = categoriesIpClient;
                this.chartOptions.series = seriesDataIpClient
            }
        }
    },
    mounted() {
        if (this.ip_address.chart_client_ip && Object.prototype.hasOwnProperty.call(this.ip_address.chart_client_ip, "categories") && Object.keys(this.ip_address.chart_client_ip).length > 0 && Object.keys(this.ip_address.chart_client_ip.series).length > 0) {
           this.filterChart()
        }
    },
}
</script>
