<template lang="pug">
.container-auth
    .logo-helix
        <svg viewBox="0 0 82 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.8539 0.272003V24.272H12.3775V14.0798H4.45789V24.272H0.0159912V0.272003H4.45789V10.2921H12.3775V0.272003H16.8539Z" fill="url(#paint0_linear)"/>
            <path d="M35.7655 20.381V24.272H22.5432V0.272003H35.0769V4.02523H26.8473V9.63786H33.3896V13.2878H26.8473V20.381H35.7655Z" fill="url(#paint1_linear)"/>
            <path d="M51.6 20.4843V24.272H40.0649V0.272003H44.4379V20.4843H51.6Z" fill="url(#paint2_linear)"/>
            <path d="M55.2951 0.272003H59.7026V24.272H55.2951V0.272003Z" fill="url(#paint3_linear)"/>
            <path d="M76.3696 24.272L72.0827 14.9922L67.9679 24.272H62.8374L69.1559 11.9105L62.9407 0.272003H68.1057L72.341 9.43126L76.4385 0.272003H81.5002L75.2333 12.3409L81.6724 24.272H76.3696Z" fill="url(#paint4_linear)"/>
            <defs>
            <linearGradient id="paint0_linear" x1="69.577" y1="-31.6821" x2="10.2027" y2="34.5621" gradientUnits="userSpaceOnUse">
            <stop stop-color="#F8B653"/>
            <stop offset="1" stop-color="#43EA92"/>
            </linearGradient>
            <linearGradient id="paint1_linear" x1="69.577" y1="-31.6821" x2="10.2027" y2="34.5621" gradientUnits="userSpaceOnUse">
            <stop stop-color="#F8B653"/>
            <stop offset="1" stop-color="#43EA92"/>
            </linearGradient>
            <linearGradient id="paint2_linear" x1="69.577" y1="-31.6821" x2="10.2027" y2="34.5621" gradientUnits="userSpaceOnUse">
            <stop stop-color="#F8B653"/>
            <stop offset="1" stop-color="#43EA92"/>
            </linearGradient>
            <linearGradient id="paint3_linear" x1="69.577" y1="-31.6821" x2="10.2027" y2="34.5621" gradientUnits="userSpaceOnUse">
            <stop stop-color="#F8B653"/>
            <stop offset="1" stop-color="#43EA92"/>
            </linearGradient>
            <linearGradient id="paint4_linear" x1="69.577" y1="-31.6821" x2="10.2027" y2="34.5621" gradientUnits="userSpaceOnUse">
            <stop stop-color="#F8B653"/>
            <stop offset="1" stop-color="#43EA92"/>
            </linearGradient>
            </defs>
        </svg>
    .box.mb-4
        template(v-if="resetEmail === false")
            .box__header.text-center.border-b.p-4.pb-8
                h2.text-2xl.font-bold.mb-2.text-light Please Reset Your Password
                p.text-sm Please fill form below with new password
                    <br> make sure it's only you that know.
            .box__body.p-8
                .field.w-full.mb-6
                    .field__label
                        label.block.text-sm.font-bold.mb-2(for="account") New Password
                    .field__form
                        input.field-style.field-style--large.w-full(id="account" type="password" placeholder="Write new password here" required)
                        span.block.pt-1.text-xs.text-red-600
                .field.w-full.mb-6
                    .field__label
                        label.block.text-sm.font-bold.mb-2(for="account") Confrim Password
                    .field__form
                        input.field-style.field-style--large.w-full(id="account" type="email" placeholder="Confrim your new password" required)
                        span.block.pt-1.text-xs.text-red-600
                button.btn-primary.w-full(type='submit' @click="login") Reset
        template(v-else)
            .box-body.p-8.text-center
                .icon-info.w-32.h-32.flex.items-center.justify-center.m-auto.mb-4
                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                h2.text-xl.mb-4.text-light Reset email succeed!
                p.mb-8 Please login with new password
                router-link.link(to="login") Login now

    span.text-gray-500.text-xs.text-center.block © {{ app_name }} {{ new Date().getFullYear() }}

</template>

<script>
import { env } from '@/config/env';
export default {
    name: 'PageLogin',
    data(){
        return {
            app_name: env.VUE_APP_APP_NAME,
            // logo: env.VUE_APP_APP_LOGO,
            // app_name: this.$store.getters.getTitleGlobal,
            // logo: this.$store.getters.getLogo,
            username : "",
            password : "",
            error_username : null,
            error_password: null,
            resetEmail: false,
        }
    },
    methods : {
        validateEmail(email) {
            /* eslint-disable no-useless-escape */
            const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        login(e){
            e.preventDefault();

            this.error_username = null;
            this.error_password = null;

            if (this.username.length === 0 || this.password.length === 0) {
                // alert('Username and password is required!');
                this.error_username = (this.username.length==0 ? 'Email is required.' : null);
                this.error_password = (this.password.length==0 ? 'Password is required.' : null);

                this.$swal.fire({
                    title: 'Username or password is required!',
                    icon: 'warning',
                    timer: 3000,
                });
                return;
            }

            // if use email
            if (!this.validateEmail(this.username)) {
                this.error_username = 'The email must be a valid email address.';
                this.$swal.fire({
                    title: 'Email must be a valid email address.',
                    icon: 'warning',
                    timer: 3000,
                });
                return;
            }

            // loading
            EventEmit.$emit('showLoading');

            // with auth api
            this.$store.dispatch('auth/login', [this.username, this.password])
            .then((resp) => {

                // console.log('after login request login in Login.vue');
                // console.log(resp)

                if (resp.result === 'success') {

                    // this.$router.push({ name: 'PageDashboard' });
                    this.$router.push('/');

                    // this.$swal.close();
                    this.$swal.fire({
                        title: 'Welcome, '+ resp.data.name +'!',
                        icon: 'success',
                        timer: 3000,
                    });

                    this.username = '';
                    this.password = '';
                    this.error_username = null;
                    this.error_password = null;

                    document.getElementById('username').focus();
                } else {
                    
                    this.error_username = (resp.data && resp.data.message ? resp.data.message : null);

                    this.$swal.fire({
                        icon: 'error',
                        title: 'Failed login!',
                        text: (resp.data && resp.data.message ? resp.data.message : 'Something wrong.'),
                        // timer: 3000,
                    });
                }
            });
        }
    },
    mounted() {
        document.getElementById('username').focus();
    },
}
</script>

<style lang="sass" scoped>
    .container-auth
        max-width: 420px
        margin: 32px auto

        .logo-helix
            margin: 3rem auto
            width: 128px
            svg
                width: 100%
        
        .box
            background-color: $color-secondary-500
            .btn-primary
                padding: 0.75rem 2rem
                font-size: 1.2rem
                font-weight: bold
                background-color: $color-main-500
                color: $color-secondary-600
                transition: all 0.3s ease-in-out
                &:disabled
                    opacity: 0.2
                &:hover
                    background-color: $color-main-400

    // error field
    .error 
        color: red

    @media screen and (max-width: 480px)
        .container-auth
            .box
                background-color: transparent

</style>
