<template lang="pug">
.profile-shell
    .box
        .box__header
            TitlePage(
                title="Profile"
                :breadcrumb="breadcrumb"
                )
        .box__body
            .card.card--table.card--no-bg
                .card__header.p-4
                    h2.text-xl Summary Account
                    //- .header-cta
                        
                .card__body
                    form.container-form
                        .group-fields.border-b
                            .field
                                .field__label
                                    label.block.text-sm.font-bold Name <sup>*</sup>
                                .field__form
                                    input.field-style(type="text" placeholder="Write name here" v-model="model.name")
                                    .pt-3
                                        .message.text-xs.text-red-500(v-if="$v.model.name.$error") This name is required &amp; max length is 255 char.
                                        .message.text-xs.text-red-500(v-if="error_name !== ''") {{ error_name }}

                            .field
                                .field__label
                                    label.block.text-sm.font-bold Email <sup>*</sup>
                                .field__form
                                    input.field-style(type="email" placeholder="Write email here" v-model="model.email")
                                    .pt-3
                                        .message.text-xs.text-red-500(v-if="$v.model.email.$error") This email is required, max length is 255 char, &amp; must an email format.
                                        .message.text-xs.text-red-500(v-if="error_email !== ''") {{ error_email }}

                            .field
                                .field__label
                                    label.block.text-sm.font-bold Phone
                                .field__form
                                    input.field-style(type="number" placeholder="Write phone here" v-model="model.phone")
                                    .pt-3
                                        .message.text-xs.text-red-500(v-if="$v.model.phone.$error") This phone max length is 255 char.
                                        .message.text-xs.text-red-500(v-if="error_phone !== ''") {{ error_phone }}

                            .field
                                .field__label
                                    label.block.text-sm.font-bold Role
                                .field__form
                                    input.field-style.readonly(type="text" placeholder="Write role here" v-model="model.role_id" readonly="")
                                    .pt-3
                                        .message.text-xs.text-red-500(v-if="$v.model.role_id.$error") This role is required &amp; max length is 255 char.
                                        .message.text-xs.text-red-500(v-if="error_role_id !== ''") {{ error_role_id }}

                            //- .field
                                .field__label
                                    label.block.text-sm.font-bold Client
                                .field__form
                                    input.field-style.readonly(type="text" placeholder="Write client here" v-model="model.client_id" readonly="")
                                    .pt-3
                                        .message.text-xs.text-red-500(v-if="$v.model.client_id.$error") This client is required &amp; max length is 255 char.
                                        .message.text-xs.text-red-500(v-if="error_client_id !== ''") {{ error_client_id }}

                            .field
                                .field__label
                                    label.block.text-sm.font-bold Status
                                .field__form
                                    input.field-style.readonly(type="text" placeholder="Write status here" v-model="model.status" readonly="")
                                    .pt-3
                                        .message.text-xs.text-red-500(v-if="$v.model.status.$error") This status is required.
                                        .message.text-xs.text-red-500(v-if="error_status !== ''") {{ error_status }}

                        //- .group-fields.border-b(v-if="checkFeatureObject('profile_password_change')")
                        .group-fields.border-b(v-if="canChangePassword")
                            .field
                                .field__label
                                    label.block.text-sm.font-bold Password
                                .field__form
                                    input.field-style(type="password" placeholder="Write password here" v-model="model.password")
                                    .pt-3
                                        .message.text-xs.text-red-500(v-if="$v.model.password.$error") This password minimum 6 characters{{ route_name === 'pageUsersCreate' ? ', required, &amp; ' : ' &amp;'}} max length is 255 char.
                                        .message.text-xs.text-red-500(v-if="error_password !== ''") {{ error_password }}

                            .field
                                .field__label
                                    label.block.text-sm.font-bold Password Confirmation
                                .field__form
                                    input.field-style(type="password" placeholder="Write password confirmation here" v-model="model.password_confirmation")
                                    .pt-3
                                        .message.text-xs.text-red-500(v-if="$v.model.password_confirmation.$error") This password confirmation must be same as password.
                                        .message.text-xs.text-red-500(v-if="error_password_confirmation !== ''") {{ error_password_confirmation }}

                        .flex.items-center.justify-end.mb-8
                            button.btn.btn-default(type="button" @click="reset()")
                                | Reset
                            //- button.btn-primary-o.ml-2(type="submit" @click="submit($event, true)")
                                | Save &amp; Continue
                            button.btn.btn-primary.ml-4(type="submit" @click="submit($event)")
                                | Save

</template>

<script>
import { mapState } from 'vuex';
import TitlePage from '@/components/shell/TitlePage.vue';
import Spinner from '@/components/Spinner.vue';
import IconSet from '@/components/IconSet.vue';
import { required, requiredIf, minLength, maxLength, email, sameAs } from "vuelidate/lib/validators";
import { checkFeatureObject } from '@/config/features';

export default {
    name: 'PageProfile',
    components: {
        TitlePage,
        Spinner,
        IconSet,
    },
    data() {
        return {
            breadcrumb: [
                {
                    name: 'Profile',
                    url: '/profile',
                },
            ],

            model: {
                // photo: '',
                name: '',
                email: '',
                phone: '',
                client_id: '',
                role_id: '',
                status: '',
                password: '',
                password_confirmation: '',
            },


            // // error field
            // error_photo: '',
            error_name: '',
            error_email: '',
            error_phone: '',
            error_client_id: '',
            error_role_id: '',
            error_status: '',
            error_password: '',
            error_password_confirmation: '',

            imgAvatar: '/images/default-avatar.png',
        };
    },
    validations: {
        model: {
            name: {
                required,
                maxLength: maxLength(255)
            },
            email: {
                required,
                maxLength: maxLength(255),
                email
            },
            phone: {
                maxLength: maxLength(255)
            },
            client_id: {
                // required,
            },
            role_id: {
                // required,
            },
            status: {
                // required,
            },
            password: {
                // required: requiredIf(function() {
                //     return this.route_name === 'pageProfile';
                // }),
                minLength: minLength(6),
                maxLength: maxLength(255),

                // goodPassword: (password) => { //a custom validator!
                //     return password.length >= 6
                //     // /[a-z]/.test(password) &&
                //     // /[A-Z]/.test(password) &&
                //     // /[0-9]/.test(password)
                // },
            },
            password_confirmation: {
                required: requiredIf(function() {
                    return this.model.password.length > 0;
                }),
                minLength: minLength(6),
                maxLength: maxLength(255),
                sameAsPassword: sameAs('password')
            },
        },
    },
    computed: {
        ...mapState('auth', ['user','profile']),
        route_name() {
            return this.$route.name;
        },
        canChangePassword(){
            try {
                return this.user && this.user.role ? ['superadmin','admin'].includes(this.user.role.label) : false
            } catch (error) {
                console.log(error)
            }
            return false
        }
    },
    watch: {
        route_name() {
            this.getData();
        },
        // form data
        profile(newData) {
            // this.model.photo = (newData.photo ? newData.photo : '');
            this.model.name = (newData.name ? newData.name : '');
            this.model.email = (newData.email ? newData.email : '');
            this.model.phone = (newData.phone ? newData.phone : '');
            this.model.role_id = (newData.role && newData.role.name ? newData.role.name : '');
            this.model.client_id = (newData.client && newData.client.name ? newData.client.name : '');
            this.model.status = (newData.status ? (newData.status === 1 || newData.status === '1' ? 'Active' : 'Inactive') : '');
            // this.status = (newData.status ? newData.status : '');
            // this.password = (newData.password ? newData.password : '');
        },
    },
    methods: {
        checkFeatureObject(e) {
            return checkFeatureObject(e)
        },
        changeImgAvatar(e) {
            const file = e.target.files[0];
            this.imgAvatar = URL.createObjectURL(file);
        },
        getDefaultImage(e) {
            e.target.src = this.imgAvatar;
        },
        setEmptyModel(all=true) {
            for (let key in Object.keys(this.model)) {
                if (all === true)
                    this.model[Object.keys(this.model)[key]] = '';
            }
        },
        setErrors(empty=true, errors=null) {
            if (empty === true) {
                this.error_name = '';
                this.error_email = '';
                this.error_phone = '';
                this.error_role_id = '';
                this.error_client_id = '';
                this.error_status = '';
                this.error_password = '';
                this.error_password_confirmation = '';
            } else if (empty !== true && errors !== null) {
                this.error_name = (errors.name ? errors.name : '');
                this.error_email = (errors.email ? errors.email : '');
                this.error_phone = (errors.phone ? errors.phone : '');
                this.error_client_id = (errors.client_id ? errors.client_id : '');
                this.error_role_id = (errors.role_id ? errors.role_id : '');
                this.error_status = (errors.status ? errors.status : '');
                this.error_password = (errors.password ? errors.password : '');
                // this.error_password_confirmation = (errors.password_confirmation ? errors.password_confirmation : '');
            }
        },
        getData(update=false) {
            this.reset(true);

            if (this.route_name === 'pageProfile') {
                
                if (!update) 
                    EventEmit.$emit('showLoading', true);

                this.$store.dispatch('auth/getProfile')
                .then((resp) => {
                    if (resp === 'success') {
                        // EventEmit.$emit('showLoading', false);

                        if (!update) {
                            EventEmit.$emit('showLoading', false);
                        } else {
                            this.$swal.fire({
                                icon: 'success',
                                title: 'Data successfully updated!',
                                timer: 3000,
                            });
                        }

                    } else {
                        EventEmit.$emit('error');
                    }
                });
            }
        },
        reset(direct=false) {
            if (direct === true) {
                // this.setEmptyModel(false);
                this.setEmptyModel(true);
                // reset vuelidate
                this.$nextTick(() => { this.$v.$reset(); });
                this.setErrors(true);
                window.scrollTo(0,0);
            } else {
                this.$swal.fire({
                    icon: 'warning',
                    title: 'Are you sure you want to reset form?',
                    // text: id,
                    confirmButtonText: 'Yes!',
                    confirmButtonColor: '#DD6B55',
                    showCancelButton: true,
                    allowOutsideClick: false,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        // if (this.route_name === 'pageProfile') {
                        //     this.setEmptyModel(false);
                        //     // reset vuelidate
                        //     this.$nextTick(() => { this.$v.$reset(); });
                        //     this.setErrors(true);
                        //     window.scrollTo(0,0);
                        // } else {
                            this.getData();
                        // }
                    }
                });
            }
        },
        submit(e, save_continue=false) {
            e.preventDefault();
            this.$v.$touch();

            if (this.$v.model.$invalid) {
                for (let key in Object.keys(this.$v.model)) {
                    const input = Object.keys(this.$v.model)[key];
                    if (input.includes("$")) return false;
                    if (this.$v.model[input].$error) {
                        break;
                    }
                }
                // EventEmit.$emit('error', 'Please fill the form correctly.');
                // EventEmit.$emit('showLoading', false);
                window.scrollTo(0,0);
                return;
            }

            EventEmit.$emit('showLoading', true);
            let model_data = Object.keys(this.model).filter((item) => { return (item !== 'password_confirmation' && item !== 'client_id' && item !== 'role_id' && item !== 'status'); }).map((key) => this.model[key]);

            // console.log(model_data);
            // EventEmit.$emit('showLoading', false);
            // return;

            // model_data = [this.id].concat(model_data);

            this.$store.dispatch('auth/updateProfile', model_data)
            .then((resp) => {
                if (resp.result === 'success') {                        
                    // if (save_continue === false)
                    //     this.$router.push('/users');
                    
                    this.getData(true);

                    // this.$swal.fire({
                    //     icon: 'success',
                    //     title: 'Data successfully updated!',
                    //     timer: 3000,
                    // });
                } else {
                    if (resp.data.message && resp.data.message.errors) {
                        this.setErrors(false, resp.data.message.errors);
                        // EventEmit.$emit('error', null, resp.data.message.errors);
                        EventEmit.$emit('showLoading', false);
                        window.scrollTo(0,0);
                    } else {
                        EventEmit.$emit('error');
                    }
                }
            });
        },
    },
    created() {
        // 
    },
    mounted() {
        this.getData();
    },
}
</script>

<style lang="sass" scoped>
    input.readonly
        opacity: 0.4
        background: #e2e8f0
</style>
