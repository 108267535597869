<template lang="pug">
.not-found
    img.logo-global(alt="Vue logo" :src="logo")
    h1.monospace 403
    h1.monospace Forbidden
    p You are not allowed to access this page.
    //- a.btn-primary-o.mt-6.mr-5(@click="$router.go(-1)") Back
    router-link.btn-primary-o.mt-6(to="/") Home

</template>

<script>
export default {
    name: 'ForbiddenPage',
    data(){
        return {
            logo: this.$store.getters['config/getLogo'],
        };
    },
}
</script>

<style lang="sass" scoped>
.not-found
    text-align: center
    .logo-global
        max-width: 250px
        margin: 0 auto 24px auto

    max-width: 420px
    margin: 32px auto

    button.btn-primary
        letter-spacing: 1px
</style>