<template lang="pug">
//-
.field(class="lg:mr-3 lg:mb-0 lg:w-64 mr-0 w-full mb-4")
	.field-style(v-if="status_log_management_table.status == 'loading'")
		.field-style--loading
			Spinner(show="true" size="20")
	.relative(v-else)
		// select.field-style(v-model="table_choose")
		select.field-style(:checked="table_choose" @change="$emit('change_table_choose', $event.target.value)")
			option(v-for="(label, key) in log_management_table" :key="key" :value="key") {{ label }}
		.field-icon
			IconSet(
				type="chevron-down"
			) 
</template>

<script>
//
import { mapState } from 'vuex';
import IconSet from '@/components/IconSet.vue';
import Spinner from '@/components/Spinner.vue';

export default {

name: 'IndexFilterTable',
components: {
	IconSet,
	Spinner,
},
model: {
	prop: 'table_choose',
	event: 'change_table_choose'
},
props: {
	table_choose: String
},
// data(){ return {
// 	table_choose: '',
// }},
computed: {
	...mapState('logManagement', [
		'status_log_management_table',
		'log_management_table',
	]),
},
mounted() {
	this.$store.dispatch('logManagement/getListTable');
},

}
</script>