import axios from "@/axios-main";
import axiosOrigin from "axios";

let cancelList, cancelNotesList;
var cancelToken = axiosOrigin.CancelToken;

const meIdTrack = {
	namespaced: true,
	state: {
		// load data imei sv
		status_list: {
			status: false,
			cancel: null,
			code: null,
		},
		status_detail: false,
		status_detail_list: false,

		// imei sv
		list: {},
		detail: {},
		detail_list: {},

		// notes
		notes_list: [],
		status_notes_list: {
			status: false,
			cancel: null,
			code: null
		},
		data_pagination: { 
			current_page: 1,
			has_next: false,
			has_previous: false,
			next_page_number: 1,
			per_page: 10,
			previous_page_number: null,
			total_data: 0,
			total_page: 1,
		},
		is_loading_next: false,
		
	},
	mutations: {
		// load data imei sv
		setStatusList: (state, status) => {
			state.status_list = status;
		},
		setStatusDetail: (state, status) => {
			state.status_detail = status;
		},
		setStatusDetailList: (state, status) => {
			state.status_detail_list = status;
		},

		// imei sv
		setList: (state, data) => {
			state.list = data;
		},
		setDetail: (state, data) => {
			state.detail = data;
		},
		setDetailList: (state, data) => {
			state.detail_list = data;
		},

		// notes
		setNotesList: (state, data) => {
			state.notes_list = data;
		},
		setStatusNotesList: (state, status) => {
			state.status_notes_list = status;
		},
		setDataPagination: (state, data) => {
			state.data_pagination = data;
		},
		setIsLoadingNext: (state, status) => {
			state.is_loading_next = status;
		},
	},
	getters: {},
	actions: {
		async getList({state, commit, dispatch}, 
			// [limit = null, page = null, keyword = null, order_by = null, from_date = null, to_date = null, user_id = null]) {
				[limit = null, page = null, keyword = null, user_id = null]) {

			if (state.status_list.cancel !== null && state.status_list.status === 'loading')
				await state.status_list.cancel('Operation canceled by the user.');

			let params = new FormData();
			params.append('limit', limit);
			params.append('page', page);
			params.append('keyword', keyword);
			params.append('user_id', user_id);
			// params.append('order_by', order_by); // error if null (data empty)
			// params.append('from_date', from_date);
			// params.append('to_date', to_date);
			axios.post('api/v1/me-id-track/list', params, {
					cancelToken: new cancelToken(function executor(c) {
						cancelList = c;
						commit('setStatusList', {
							status: 'loading',
							cancel: c,
							code: null,
						});
					}),
				})
				.then((resp) => {
					// console.log(resp.data)
					let data = resp.data;
					let result = {
						status: true,
						cancel: null,
						code: resp.response && resp.response.status ? resp.response.status : null,
					};

					commit('setList', data);

					if (Object.keys(data).length === 0)
						result.status = 'empty';

					commit('setStatusList', result);
				})
				.catch((resp) => {
					// console.log(resp)
					let result = {
						status: 'failed',
						cancel: null,
						code: resp.response && resp.response.status ? resp.response.status : null,
					};

					if (axiosOrigin.isCancel(resp)) {
						result.status = 'loading';
						result.cancel = cancelList;
					}

					commit('setStatusList', result);
					if (!axiosOrigin.isCancel(resp))
						dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null)], {
							root: true
						});
				});
		},

		getDetail({commit, dispatch}, id) {

			let params = new FormData();
			params.append('id', id);
			commit('setStatusDetail', 'loading');

			return axios.post('api/v1/me-id-track/detail', params)
				.then((resp) => {
					// console.log(resp.data)
					let data = resp.data;
					commit('setDetail', data);
					commit('setStatusDetail', true);	
					return {result: 'success', data: data};
				})
				.catch((resp) => {
					// console.log(resp)
					dispatch('errors/handleApiResponse', [(resp.response && resp.response.status ? resp.response.status : null)], {
						root: true
					});
					commit('setStatusDetail', false);	
					return 'failed';
				});
		},

		create({ dispatch }, [imei = null, cases = null, description=null, notes = null]) {

			let params = new FormData();
			params.append('imei', imei);
			params.append('cases', cases);
			params.append('description', description);
			params.append('notes', notes);

			return axios.post('api/v1/me-id-track/create', params)
				.then((resp) => {
					// console.log(resp.data)
					if (resp.status === 200 && resp.data.message === 'Data Successfully Created') {
						return {
							result: 'success',
							data: resp.data
						};
					} else {
						return {
							result: 'failed',
							data: resp.data
						};
					}
				})
				.catch((resp) => {
					// console.log(resp)
					dispatch('errors/handleApiResponse', [(resp.response && resp.response.status ? resp.response.status : null)], {
						root: true
					});
					return {
						result: 'failed',
						data: (resp.response && resp.response.data ? resp.response.data : null)
					};
				});
		},

		update({ dispatch }, [id = null, description=null, status=null]) {

			let params = new FormData();
			params.append('id', id);
			if (![null, ''].includes(description)) {
				params.append('description', description);
			}
			if (![null, ''].includes(status)) {
				params.append('status', status);
			}

			return axios.post('api/v1/me-id-track/update', params)
				.then((resp) => {
					// console.log(resp.data)
					if (resp.status === 200 && resp.data.message === 'Data Successfully Updated') {
						return {
							result: 'success',
							data: resp.data
						};
					} else {
						return {
							result: 'failed',
							data: resp.data
						};
					}
				})
				.catch((resp) => {
					// console.log(resp)
					dispatch('errors/handleApiResponse', [(resp.response && resp.response.status ? resp.response.status : null)], {
						root: true
					});
					return {
						result: 'failed',
						data: (resp.response && resp.response.data ? resp.response.data : null)
					};
				});
		},

		delete({dispatch}, id) {
			let params = new FormData();
			params.append('id', id);
			return axios.post('api/v1/me-id-track/delete', params)
				.then((resp) => {
					// console.log(resp)
					if (resp.status === 200 && resp.data.message === 'Data Successfully Deleted') {
						return 'success';
					} else {
						return 'failed';
					}
				})
				.catch((resp) => {
					// console.log(resp)
					dispatch('errors/handleApiResponse', [(resp.response && resp.response.status ? resp.response.status : null)], {
						root: true
					});
					return {
						result: 'failed',
						data: (resp.response && resp.response.data ? resp.response.data : null)
					};
				});
		},
		getDetailList({
			commit,
			dispatch
		}, [imei_sv, source="internal"]) {
			commit('setStatusDetailList', 'loading');
			let params = new FormData();
			params.append('imei_sv', imei_sv);
			params.append('source', source);
			return axios.post('api/v1/me-id-track/search', params)
				.then((resp) => {
					// console.log('then')
					// console.log(resp.data)
					let data = resp.data;

					commit('setDetailList', data);
					commit('setStatusDetailList', true);
					return 'success';
				})
				.catch((resp) => {
					// console.log('catch')
					// console.log(resp)
					commit('setStatusDetailList', 'failed');
					dispatch('errors/handleApiResponse', [(resp.response && resp.response.status ? resp.response.status : null)], {
						root: true
					});
					return 'failed';
				});
		},

		// Notes
		async getNotesList({ state, commit, dispatch }, [imei_id='', loadMore=false]) {
			// async getNotesList({ state, commit, dispatch }, [imei_id='', limit='', page='']) {
			if (state.status_notes_list.cancel !== null && state.status_notes_list.status === 'loading')
				await state.status_notes_list.cancel('Operation canceled by the user.');
		
			let params = new FormData();
		
			params.append('imei_id', imei_id);
			params.append('limit', state.data_pagination.per_page);
			params.append('page', state.data_pagination.next_page_number?state.data_pagination.next_page_number:1);
			// params.append('limit', limit);
			// params.append('page', page);
			// params.append('keyword', keyword);
			// params.append('from_date', from_date);
			// params.append('to_date', to_date);
			
			axios.post('api/v1/me-id-track/notes/list', params, {
				cancelToken: new cancelToken(function executor(c) {
				cancelNotesList = c;
				commit('setStatusNotesList', {
					status: 'loading',
					cancel: c,
					code: null,
				});
				}),
			})
			.then((resp) => {
				// console.log(resp.data)
				let data = resp.data;
				let result = {
				status: true,
				cancel: null,
				code: resp.response && resp.response.status ? resp.response.status : null,
				};
		
				if (Object.keys(data).length===0) {
				result.status = 'empty';
				} else {
				const dataPagination = {
					current_page: parseInt(data.current_page),
					has_next: data.has_next,
					has_previous: data.has_previous,
					next_page_number: parseInt(data.next_page_number),
					per_page: parseInt(data.per_page),
					previous_page_number: parseInt(data.previous_page_number),
					total_data: parseInt(data.total_data),
					total_page: parseInt(data.total_page),
				}
				commit('setDataPagination', dataPagination);
		
				if(loadMore) {
					data = state.notes_list.concat(data.data);
				} else {
					data = data.data;
				}
				}
				
				commit('setNotesList', data);
				commit('setStatusNotesList', result);        
				commit('setIsLoadingNext', false);
			})
			.catch((resp) => {
				// console.log(resp)
				let result = {
				status: 'failed',
				cancel: null,
				code: resp.response && resp.response.status ? resp.response.status : null,
				};
		
				if (axiosOrigin.isCancel(resp)) {
				result.status = 'loading';
				result.cancel = cancelNotesList;
				} else {
				commit('setIsLoadingNext', false)
				}
		
				commit('setStatusNotesList', result);
				if (!axiosOrigin.isCancel(resp))
				dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
			});
			},
		
		createNotes({ dispatch }, [imei_id = null, notes = null]) {
			let params = new FormData();
			params.append('imei_id', imei_id);
			params.append('notes', notes);
		
			return axios.post('api/v1/me-id-track/notes/create', params)
			.then((resp) => {
		
				if (resp.status === 200) {
				return {result: 'success', data: resp.data};
				} else {
				return {result: 'failed', data: resp.data};
				}
			})
			.catch((resp) => {
				// console.log(resp)
				dispatch('errors/handleApiResponse', [(resp.response && resp.response.status ? resp.response.status : null)], { root: true });
				return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
			});
		},
	}
};

export default meIdTrack;