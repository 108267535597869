<template lang="pug">
.card.card--tab
    .card--tab__header
        h2.text-xl Duration Domain
    .card--tab__body
        .box-tabs
            vue-tabs
                v-tab(title="Domains")
                    DurationDomainDomains
                v-tab(title="App Categories")
                    DurationDomainAppCategories
                v-tab(title="Web Categories")
                    DurationDomainWebCategories
                v-tab(title="Referer Domain")
                    DurationDomainRefererDomain
                v-tab(title="Referer URL")
                    DurationDomainRefererUrl
</template>

<script>
import { VueTabs,VTab } from 'vue-nav-tabs'
import DurationDomainDomains from './DurationDomainDomains.vue'
import DurationDomainAppCategories from './DurationDomainAppCategories.vue'
import DurationDomainWebCategories from './DurationDomainWebCategories.vue'
import DurationDomainRefererDomain from './DurationDomainRefererDomain.vue'
import DurationDomainRefererUrl from './DurationDomainRefererUrl.vue'

export default {
    name: 'BrowsingLogDurationDomain',
    components: {
        VueTabs,
        VTab,
        DurationDomainDomains,
        DurationDomainAppCategories,
        DurationDomainWebCategories,
        DurationDomainRefererDomain,
        DurationDomainRefererUrl
    }
}
</script>