import supportENVHelper from './supports/_env'

const env = supportENVHelper.defaultValue({

// BASE_URL: [String, ''],
// NODE_ENV: [String, ''],

VUE_APP_ENV: [String, 'develop'],
VUE_APP_CONF_SERVICE_NAME: [String, 'toronto'],
VUE_APP_CONF_SERVICE_URL: [String, 'https://spring-boot-dev.notitiaopportuna.xyz/'],

VUE_APP_API_URL: [String, ''],
VUE_APP_APP_KEY: [String, ''],
VUE_APP_SECRET_KEY: [String, ''],
VUE_APP_SERVER_AUTH_PASSWORD: [String, ''],
VUE_APP_SERVER_AUTH_USERNAME: [String, ''],

VUE_APP_ENCRYPT_RECENT_LOCATION_SECRET_LIMIT: [Number, 32],
VUE_APP_PROJECT_IDF: [String, ''],

VUE_APP_DEV_MOD_CHECKING_LABEL: [String, 'Checking...'],
VUE_APP_DEV_MOD_CHECKING_TIME: [Number, 60],
VUE_APP_DEV_MOD_CLICKING_TIME: [Number, 2.5],
VUE_APP_DEV_MOD_CLICK_COUNT: [Number, 5],

VUE_APP_FCM_API_KEY: [String, ''],
VUE_APP_FCM_APP_ID: [String, ''],
VUE_APP_FCM_AUTH_DOMAIN: [String, ''],
VUE_APP_FCM_DATABASE_URL: [String, ''],
VUE_APP_FCM_FCM_LIBRARY: [String, ''],
VUE_APP_FCM_MEASUREMENT_ID: [String, ''],
VUE_APP_FCM_PROJECT_ID: [String, ''],
VUE_APP_FCM_PUBLIC_VAPID_KEY: [String, ''],
VUE_APP_FCM_SDK_LIBRARY: [String, ''],
VUE_APP_FCM_SENDER_ID: [String, ''],
VUE_APP_FCM_SERVER_KEY: [String, ''],
VUE_APP_FCM_STORAGE_BUCKET: [String, ''],
VUE_APP_FCM_TOPIC: [String, ''],

VUE_APP_FEATURES: [String, ''],
VUE_APP_FEATURES_OBJECT: [String, ''],

VUE_APP_GMAP_API_KEY: [String, ''],
VUE_APP_OSM_CDN_URL: [String, 'http://{s}.tile.osm.org/'],

VUE_APP_LOCATION_FILTER: [String, 'all'],
VUE_APP_LOCATION_RANGE: [Number, 13],

VUE_APP_BROWSING_FILTER: [String, 'all'],
VUE_APP_BROWSING_RANGE: [Number, 6],

VUE_APP_ENCRYPT_RECENT_LOCATION: [Boolean, false],

VUE_APP_DEFAULT_SIZE: [Number, 2000],
VUE_APP_DEFAULT_RANGE: [Number, 6],
VUE_APP_DEFAULT_IMAGE: [String, '/images/no-image.png'],
VUE_APP_DEFAULT_IMAGE_PROFILE: [String, '/images/no-image-profile.jpg'],
VUE_APP_APP_NAME: [String, 'Helix'],
VUE_APP_APP_LOGO: [String, '/images/logo-helix-fix.png'],
VUE_APP_APP_LOGO_GLOBAL_HEADER: [String, '/images/logo-helix-fix--gradient.png'],
VUE_APP_APP_FAVICON: [String, '/favicon.ico'],
VUE_APP_TERRITORY_VIRTUAL_MARKER_RADIUS: [Number, 500],
VUE_APP_MEETING_POINT_MARKER_RADIUS: [Number, 200],
VUE_APP_MAX_IMAGE: [Number, 10],
VUE_APP_MAX_PHOTOS: [Number, 5],
VUE_APP_DEFAULT_AGE_RANGE: [Boolean, false],
VUE_APP_MAX_AGE: [Number, 200],
VUE_APP_MIN_AGE: [Number, 0],
VUE_APP_VENDOR_MAP: [String, 'osm'],
VUE_APP_VALIDATE_RANGE: [Number, 365],
VUE_APP_VALIDATE_RANGE_TV: [Number, 90],
VUE_APP_VALIDATE_RANGE_APP_USAGE_SOURCE: [Number, 90],
VUE_APP_VALIDATE_RANGE_APP_USAGE_LOCAL: [Number, 365],
VUE_APP_VALIDATE_RANGE_QUERY_SOURCE: [Number, 365],
VUE_APP_VALIDATE_RANGE_QUERY_LOCAL: [Number, 365],
VUE_APP_OVERALL_RANGE: [Number, 13],
VUE_APP_AERIAL_RANGE: [Number, 6],
VUE_APP_ADVANCE_FILTER_AERIAL_TARGET: [Boolean, false],
VUE_APP_DEVICE_RANGE: [Number, 89],
VUE_APP_IDLE_WAITING_TIME: [Number, 0],
VUE_APP_SERVICE_FR: [String, 'openvino'],
VUE_APP_NOTIFICATION_FILTER_TYPE: [Boolean, false],
VUE_APP_MONITORING_TIME: [String, ''],
VUE_APP_MAX_IMEI: [Number, 15],
VUE_APP_MIN_IMEI: [Number, 15],
VUE_APP_MAX_IMEI_SV: [Number, 16],
VUE_APP_MIN_IMEI_SV: [Number, 16],
VUE_APP_OP_HIDE_USERS: [Boolean, false],
VUE_APP_HAMBURGER_ANALYST_HARDCODE: [Boolean, false],
VUE_APP_LOCATION_MOVEMENT_RANGE: [Number, 13],
VUE_APP_HISTORY_DEVICE_ROLES_RANGE: [Number, 89],

// VUE_APP_HIDE_PREDICTION_NAME: [Boolean, false],
// VUE_APP_HIDE_MULTI_TRACKING: [Boolean, false],
// VUE_APP_MONITORING_PERIOD: [Boolean, false],
// VUE_APP_NOTIFICATION: [Boolean, false],
// VUE_APP_REMAINING_QUOTA: [Boolean, false],

VUE_APP_MULTI_TRACKING_TARGET_LIMIT: [Number, 0],

VUE_APP_WEEKEND_HOME_BEGIN: [String, '10:00 PM'],
VUE_APP_WEEKEND_HOME_END: [String, '06:00 AM'],
VUE_APP_WEEKDAY_HOME_BEGIN: [String, '10:00 PM'],
VUE_APP_WEEKDAY_HOME_END: [String, '04:00 AM'],

VUE_APP_WEEKDAY_OFFICE_BEGIN: [String, '09:00 AM'],
VUE_APP_WEEKDAY_OFFICE_END: [String, '05:00 PM'],

VUE_APP_API_VERSION_LOG_MANAGEMENT: [String, 'v1'],
VUE_APP_API_VERSION_LOG_ACTIVITY: [String, 'v1'],
VUE_APP_API_VERSION_LOG_AUTH: [String, 'v1'],
VUE_APP_API_VERSION_LOG_TRACKING_TARGET: [String, 'v1'],
VUE_APP_API_VERSION_LICENSE: [String, 'v1'],

VUE_APP_MSISDN_INDONESIAN_NUMBER: [Boolean, true],

VUE_APP_SHOW_COPY_TARGET: [Boolean, false],
VUE_APP_ORDER_COPY_TARGET: [String, 'datetime|msisdn|imsi|provider|imei|device|tac|enb|lac|ci|network|bts|address|state|map'],
VUE_APP_HEADER_COPY_TARGET: [String, '==INTERNAL USE ONLY, DATA PRESENTED BY "{operator_name}"=='],

// PENULISAN ENV MASIH TETAP DENGAN 'VUE_APP_*'
// GLOBAL_FILTER_TIMEPICKER_STATUS: [Boolean, true],
OVERALL_FILTER_TIMEPICKER_STATUS: [Boolean, false],
LOCATION_MOVEMENT_FILTER_TIMEPICKER_STATUS: [Boolean, false],
LOCATION_FILTER_TIMEPICKER_STATUS: [Boolean, true],
BROWSING_FILTER_TIMEPICKER_STATUS: [Boolean, true],
AERIAL_FILTER_TIMEPICKER_STATUS: [Boolean, true],
HISTORY_DEVICE_ROLES_FILTER_TIMEPICKER_STATUS: [Boolean, false],
DEVICE_HISTORY_FILTER_TIMEPICKER_STATUS: [Boolean, false],
TETHERED_DEVICE_FILTER_TIMEPICKER_STATUS: [Boolean, false],
GLOBAL_FILTER_TIMEPICKER24_STATUS: [Boolean, true],
GLOBAL_FILTER_MINUTE_INCREMENT: [Number, 1],
GLOBAL_FILTER_LOCALE_FIRSTDAY: [Number, 1],
GLOBAL_FILTER_LOCALE_FORMAT: [Boolean, true],
GLOBAL_FILTER_TIMEPICKER_FORMAT: [String, "YYYY-MM-DD HH:mm"],

// AUTO TRIM, AUTO NUMBER, SEPARATOR ONLY "," AND "|"
// TEST_ARRAY: [Array, "1, 2, 3.4, 5.6, string, String, STRING, string string, string-string, string_string"],
// TEST_ARRAY: [Array, "1| 2| 3.4| 5.6| string| String| STRING| string string| string-string| string_string"],

LOG_MANAGEMENT__INDEX__FILTER_TABLE__SHOWN: [Boolean, true],
LOG_MANAGEMENT__INDEX__FILTER_TABLE__ROLES_BYPASS: [Array, "superadmin"],

USERS__FORM__SHOW_WEB_DEVICE_ID: [Boolean, false],
})

// env.GLOBAL_FILTER_DATE_FORMAT = "YYYY-MM-DD"
// if (env.GLOBAL_FILTER_TIMEPICKER_STATUS)
// 	env.GLOBAL_FILTER_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss"

// console.log(`TEST_ARRAY: ${JSON.stringify(env.TEST_ARRAY)}`)

export { env };