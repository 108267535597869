<template lang="pug">
.card-content
    template(v-if="status_detail_location.status === 'loading'")
        template(v-if="route_name === 'TargetDetailLocationMovement' || route_name === 'TargetDetailLocationLog'")
            Loading(show="true")
        template(v-else)
            LoadingAnimation(show="true" title="detail-location")

    template(v-else)
        .flex.flex-wrap(v-if="detail_location.detail_location_region && (typeof detail_location.detail_location_region.categories != 'undefined' && detail_location.detail_location_region.categories.length > 0) && detail_location.detail_location_region.series.length > 0 && detail_location.detail_location_region.pie.length > 0 && chartLoaded")
            .block(class="w-full mb-4 lg:w-8/12 lg:mb-0")
                .box-charts
                    highcharts(
                        :options="chartBar"
                    )
            .block(class="w-full mb-4 lg:w-4/12 lg:mb-0")
                .box-charts
                    highcharts(
                        :options="chartPie"
                    )

        NoDataAvailable(v-else)

</template>

<script>
import { mapState } from 'vuex';
import Loading from '@/components/Loading.vue';
import LoadingAnimation from '@/components/LoadingAnimation.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';

import addColorInSeries from '@/config/chart';

export default {
    name: 'DetailLocationRegion',
    components: {
        Loading,
        NoDataAvailable,
        LoadingAnimation
    },
    data() {
        return {
            title: '',
            seriesColor: '#6fcd98',
            chartLoaded: false,
            colorInputIsSupported: null,
            chartBar: {
                 colors: [
                    "#2caffe", "#544fc5", "#00e272", "#fe6a35", "#6b8abc", "#d568fb", "#2ee0ca", "#fa4b42", "#feb56a", "#91e8e1"
                ],
                chart: {
                    type: 'bar'
                },
                title: {
                    text: null
                },
                xAxis: {
                    categories: [],
                    crosshair: true,
                    title: {
                        text: null
                    },
                    labels: {
                        step: 1
                    }
                },
                yAxis: {
                    title: {
                        text: null
                    },
                },
                tooltip: {
                    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                    pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                        '<td style="padding:0"><b>{point.y:1f}</b></td></tr>',
                    footerFormat: '</table>',
                    shared: true,
                    useHTML: true
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0
                    }
                },
                series: [],
                credits: {
                    enabled: false
                },
            },
            chartPie: {
                 colors: [
                    "#2caffe", "#544fc5", "#00e272", "#fe6a35", "#6b8abc", "#d568fb", "#2ee0ca", "#fa4b42", "#feb56a", "#91e8e1"
                ],
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie'
                },
                title: {
                    text: 'LOCATION BY REGION',
                    style: {
                        // color: '#fff',
                        fontSize: '11px'
                    }
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                },
                accessibility: {
                    point: {
                        valueSuffix: '%'
                    }
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: false
                        },
                        showInLegend: true
                    }
                },
                series: [{
                    name: 'Region',
                    colorByPoint: true,
                    data: [],
                }],
                credits: {
                    enabled: false
                },
            }
        }
    },
    computed: {
        ...mapState('locationLog', [
          'status_detail_location',
          'detail_location',
        ]),
        route_name() {
            return this.$route.name;
        },
    },
    watch: {
        detail_location() {
            this.startChart();
        },
        chartLoaded() {},
    },
    methods: {
        startChart() {
            if (this.detail_location.detail_location_region && (typeof this.detail_location.detail_location_region.categories != 'undefined' && this.detail_location.detail_location_region.categories.length > 0) && this.detail_location.detail_location_region.series.length > 0 && this.detail_location.detail_location_region.pie.length > 0) {
                // console.log(this.detail_location.detail_location_region);

                this.chartBar.xAxis.categories = this.detail_location.detail_location_region.categories;
                
                const [bar_series, pie_series] = addColorInSeries(this.detail_location.detail_location_region.series, this.detail_location.detail_location_region.pie)
                
                this.chartPie.series[0].data = pie_series;
                this.chartBar.series = bar_series;

                // this.chartBar.series = this.detail_location.detail_location_region.series;
                // this.chartPie.series[0].data = this.detail_location.detail_location_region.pie;

                this.chartLoaded = true;
            }
        },
    },
    mounted() {
        this.startChart();
    },
}
</script>
