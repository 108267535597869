import moment from 'moment';
import axiosOrigin from "axios";
import axios from "@/axios-main";
import SecureLS from "secure-ls";

import Vue from 'vue';

import { env } from "@/config/env";
import { checkFeature } from "@/config/features";

let cancelConfig;
var cancelToken = axiosOrigin.CancelToken;
const ls = new SecureLS({ isCompression: false });

const config = {
  namespaced: true,
  state: {
    status_config: {
      status: false,
      cancel: null,
      code: null,
    },

    config: {},
    isIdle: false,

    loadingComponents: {},
  },
  mutations: {
    setIsIdle: (state, status) => {
      state.isIdle = status;
    },
    IDLE_CHANGED: (state, isIdle) => {
      state.isIdle = isIdle;
    },

    setStatusConfig: (state, status) => {
      state.status_config = status;
    },

    setConfig: (state, data) => {
      state.config = data;
    },


    SET_ELAPSED(state, { routeName, elapsed, title }) {
      if (!state.loadingComponents[routeName]) {
        Vue.set(state.loadingComponents, routeName, {});
      }
      if (!state.loadingComponents[routeName][title]) {
        Vue.set(state.loadingComponents[routeName], title, {});
      }
      Vue.set(state.loadingComponents[routeName][title], 'elapsed', elapsed);
      // console.log(`SET_ELAPSED for ${routeName} (${title}):`, elapsed);
    },
    SET_TIMER(state, { routeName, timer, title }) {
      if (!state.loadingComponents[routeName]) {
        Vue.set(state.loadingComponents, routeName, {});
      }
      if (!state.loadingComponents[routeName][title]) {
        Vue.set(state.loadingComponents[routeName], title, {});
      }
      Vue.set(state.loadingComponents[routeName][title], 'timer', timer);
    },
    SET_LAST_TIMESTAMP(state, { routeName, timestamp, title }) {
      if (!state.loadingComponents[routeName]) {
        Vue.set(state.loadingComponents, routeName, {});
      }
      if (!state.loadingComponents[routeName][title]) {
        Vue.set(state.loadingComponents[routeName], title, {});
      }
      Vue.set(state.loadingComponents[routeName][title], 'lastTimestamp', timestamp);
    },
    CLEAR_TIMER(state, { routeName, title }) {
      if (state.loadingComponents[routeName] && state.loadingComponents[routeName][title] && state.loadingComponents[routeName][title].timer) {
        clearInterval(state.loadingComponents[routeName][title].timer);
        Vue.set(state.loadingComponents[routeName][title], 'timer', null);
      }
    },
  },
  getters: {
    getDefaultRecordSize() {
      return env.VUE_APP_DEFAULT_SIZE ? env.VUE_APP_DEFAULT_SIZE : 2000;
    },
    getDefaultDate() {
      return function (
        range_days = env.VUE_APP_DEFAULT_RANGE ? env.VUE_APP_DEFAULT_RANGE : 6, date_format="YYYY-MM-DD"
      ) {
        let to_date = moment()
        to_date = to_date.millisecond(99999)
        to_date = to_date.second(59)
        to_date = to_date.minute(59)
        to_date = to_date.hour(23)
        to_date = to_date.format(date_format)

        let start_date = moment().subtract(range_days, 'days')
        start_date = start_date.millisecond(0)
        start_date = start_date.second(0)
        start_date = start_date.minute(0)
        start_date = start_date.hour(0)
        start_date = start_date.format(date_format)

        return {
          from_date: start_date,
          to_date: to_date,
        };
      };
    },
    getDefaultImage() {
      return env.VUE_APP_DEFAULT_IMAGE
        ? env.VUE_APP_DEFAULT_IMAGE
        : "/images/no-image.png";
    },
    getDefaultImageProfile() {
      return env.VUE_APP_DEFAULT_IMAGE_PROFILE
        ? env.VUE_APP_DEFAULT_IMAGE_PROFILE
        : "/images/no-image-profile.jpg";
    },

    getImage(image) {
      return function (image = "default") {
        let img = "/images/socmed/web.png";
        switch (image) {
          case "instagram.com":
            img = "/images/socmed/instagram.png";
            break;

          case "facebook.com":
            img = "/images/socmed/facebook.png";
            break;

          case "youtube.com":
            img = "/images/socmed/youtube.png";
            break;

          case "twitter.com":
            img = "/images/socmed/twitter.png";
            break;

          case "tiktok.com":
            img = "/images/socmed/tiktok.png";
            break;
          case "telegram":
            img = "/images/socmed/telegram.png";
            break;

          default:
            break;
        }
        return img;
      };
    },

    getPrefixOperator() {
      return {
        51010: "t",
        51021: "i",
        51001: "i",
        51008: "x",
        51011: "x",
        51089: "h",
        51009: "s",
        51028: "s",
      };
    },

    getTitleGlobal() {
      return env.VUE_APP_APP_NAME ? env.VUE_APP_APP_NAME : "Helix";
    },
    getLogo() {
      return env.VUE_APP_APP_LOGO
        ? env.VUE_APP_APP_LOGO
        : "/images/logo-helix-fix.png";
    },
    getLogoGlobalHeader() {
      return env.VUE_APP_APP_LOGO_GLOBAL_HEADER
        ? env.VUE_APP_APP_LOGO_GLOBAL_HEADER
        : "/images/logo-helix-fix--gradient.png";
    },
    getFavicon() {
      return env.VUE_APP_APP_FAVICON ? env.VUE_APP_APP_FAVICON : "/favicon.ico";
    },
    getTerritoryVirtualMarkerRadius() {
      return env.VUE_APP_TERRITORY_VIRTUAL_MARKER_RADIUS &&
        Number.isInteger(parseInt(env.VUE_APP_TERRITORY_VIRTUAL_MARKER_RADIUS))
        ? parseInt(env.VUE_APP_TERRITORY_VIRTUAL_MARKER_RADIUS)
        : 500;
    },
    getMeetingPointMarkerRadius() {
      return env.VUE_APP_MEETING_POINT_MARKER_RADIUS &&
        Number.isInteger(parseInt(env.VUE_APP_MEETING_POINT_MARKER_RADIUS))
        ? parseInt(env.VUE_APP_MEETING_POINT_MARKER_RADIUS)
        : 200;
    },
    getFreqMonitoring() {
      return [
        {
          name: "5 Minutes",
          value: "every5minutes",
          // value: '*/5 * * * *',
        },
        {
          name: "15 Minutes",
          value: "every15minutes",
        },
        {
          name: "30 Minutes",
          value: "every30minutes",
        },
        {
          name: "1 Hours",
          value: "every1hour",
        },
        {
          name: "2 Hours",
          value: "every2hours",
        },
        {
          name: "1 Days",
          value: "every1day",
        },
      ];
    },
    getMaxImageSize() {
      // in MB
      if (env.VUE_APP_MAX_IMAGE != undefined) {
        return env.VUE_APP_MAX_IMAGE;
      } else {
        return 10;
      }
    },
    getMaxPhotos() {
      if (checkFeature("watchlist")) {
        if (env.VUE_APP_MAX_PHOTOS != undefined) {
          return env.VUE_APP_MAX_PHOTOS;
        } else {
          return 5;
        }
      } else {
        return 100;
      }
    },
    getDefaultAgeRange() {
      var setDefaultAgeRange = env.VUE_APP_DEFAULT_AGE_RANGE || false;
      var minAgeRange = env.VUE_APP_MIN_AGE || 0;
      var maxAgeRange = env.VUE_APP_MAX_AGE || 200;

      return {
        set: setDefaultAgeRange,
        min: minAgeRange,
        max: maxAgeRange,
      };
    },
    getMapVendor() {
      // return 'osm';
      return typeof env.VUE_APP_VENDOR_MAP !== undefined
        ? env.VUE_APP_VENDOR_MAP
        : "osm";
    },
    getValidateRange() {
      return env.VUE_APP_VALIDATE_RANGE ? env.VUE_APP_VALIDATE_RANGE : 365;
    },
    getValidateRangeTV() {
      return env.VUE_APP_VALIDATE_RANGE_TV ? env.VUE_APP_VALIDATE_RANGE_TV : 90;
    },
    // DEPRECATED
    getValidateRangeAppUsage() {
      return env.VUE_APP_VALIDATE_RANGE_APP_USAGE ? env.VUE_APP_VALIDATE_RANGE_APP_USAGE : 30;
    },
    getValidateRangeQueryAppUsageByRoute() {
      return function (route) {
        let rangeHistorical = env.VUE_APP_VALIDATE_RANGE_APP_USAGE_SOURCE ? env.VUE_APP_VALIDATE_RANGE_APP_USAGE_SOURCE : 90;
        let rangeDetailTarget = env.VUE_APP_VALIDATE_RANGE_APP_USAGE_LOCAL ? env.VUE_APP_VALIDATE_RANGE_APP_USAGE_LOCAL : 365;

        const detailTargetRoute = [
          'TargetDetailOverall',
        ]

        return detailTargetRoute.includes(route) ? rangeDetailTarget : rangeHistorical;
      }
    },
    getValidateRangeQueryByRoute() {
      return function (route) {
        let rangeSource = env.VUE_APP_VALIDATE_RANGE_QUERY_SOURCE ? env.VUE_APP_VALIDATE_RANGE_QUERY_SOURCE : 365;
        let rangeLocal = env.VUE_APP_VALIDATE_RANGE_QUERY_LOCAL ? env.VUE_APP_VALIDATE_RANGE_QUERY_LOCAL : 365;
        
        const detailTargetRoute = [
          'TargetDetailOverall',
          'TargetDetailLocationMovement',
          'TargetDetailLocationLog',
          'TargetDetailBrowsingLog',
          'TargetDetailAerialLog',
          'TargetDetailHistoryDeviceRoles',
          'TargetDetailDeviceHistory',
          'TargetDetailTetheredDevice',
          'pageTargetDetailHome',
          'TargetDetailRecentLocation',
        ]

        return detailTargetRoute.includes(route) ? rangeLocal : rangeSource;
      }
    },
    getDateRangeByRoute() {
      return function (route) {
        let range = env.VUE_APP_DEFAULT_RANGE ? env.VUE_APP_DEFAULT_RANGE : 6;

        switch (route) {
          case "overall":
          case "ResultOverall":
          case "ResultHistoricalOverall":
          case "TargetDetailOverall":
            range = env.VUE_APP_OVERALL_RANGE 
              ? env.VUE_APP_OVERALL_RANGE 
              : 13;
            break;

          case "locationMovement":
          case "ResultLocationMovement":
          case "ResultHistoricalLocationMovement":
          case "TargetDetailLocationMovement":
            range = env.VUE_APP_LOCATION_MOVEMENT_RANGE
              ? env.VUE_APP_LOCATION_MOVEMENT_RANGE
              : 13;
            break;

          case "locationLog":
          case "ResultLocationLog":
          case "ResultHistoricalLocationLog":
          case "TargetDetailLocationLog":
            range = env.VUE_APP_LOCATION_RANGE
              ? env.VUE_APP_LOCATION_RANGE
              : 13;
            break;

          case "browsingLog":
          case "ResultBrowsingLog":
          case "ResultHistoricalBrowsingLog":
          case "TargetDetailBrowsingLog":
            range = env.VUE_APP_BROWSING_RANGE 
              ? env.VUE_APP_BROWSING_RANGE 
              : 6;
            break;

          case "aerialLog":
          case "ResultAerialLog":
          case "ResultHistoricalAerialLog":
          case "TargetDetailAerialLog":
            range = env.VUE_APP_AERIAL_RANGE 
              ? env.VUE_APP_AERIAL_RANGE 
              : 13;
            break;

          case "historyDeviceRoles":
          case "ResultHistoryDeviceRoles":
          case "ResultHistoricalHistoryDeviceRoles":
          case "TargetDetailHistoryDeviceRoles":
            range = env.VUE_APP_HISTORY_DEVICE_ROLES_RANGE
              ? env.VUE_APP_HISTORY_DEVICE_ROLES_RANGE
              : 89;
            break;

          case "deviceHistory":
          case "ResultDeviceHistory":
          case "ResultHistoricalDeviceHistory":
          case "TargetDetailDeviceHistory":
            range = env.VUE_APP_DEVICE_RANGE 
              ? env.VUE_APP_DEVICE_RANGE 
              : 89;
            break;

          case "tetheredDevice":
          case "ResultTetheredDevice":
          case "ResultHistoricalTetheredDevice":
          case "TargetDetailTetheredDevice":
            range = env.VUE_APP_DEVICE_RANGE 
              ? env.VUE_APP_DEVICE_RANGE 
              : 89;
            break;
        }
        return range;
      };
    },
    getDateFormatFilterByRoute() {
      return function (route, returnStatus = false) {
        let date_format = "YYYY-MM-DD";
        let status = false;

        switch (route) {
          case "overall":
          case "ResultOverall":
          case "ResultHistoricalOverall":
          case "TargetDetailOverall":
            status = env.OVERALL_FILTER_TIMEPICKER_STATUS;
            break;

          case "locationMovement":
          case "ResultLocationMovement":
          case "ResultHistoricalLocationMovement":
          case "TargetDetailLocationMovement":
            status = env.LOCATION_MOVEMENT_FILTER_TIMEPICKER_STATUS;
            break;

          case "locationLog":
          case "ResultLocationLog":
          case "ResultHistoricalLocationLog":
          case "TargetDetailLocationLog":
            status = env.LOCATION_FILTER_TIMEPICKER_STATUS;
            break;

          case "browsingLog":
          case "ResultBrowsingLog":
          case "ResultHistoricalBrowsingLog":
          case "TargetDetailBrowsingLog":
            status = env.BROWSING_FILTER_TIMEPICKER_STATUS;
            break;

          case "aerialLog":
          case "ResultAerialLog":
          case "ResultHistoricalAerialLog":
          case "TargetDetailAerialLog":
            status = env.AERIAL_FILTER_TIMEPICKER_STATUS;
            break;

          case "historyDeviceRoles":
          case "ResultHistoryDeviceRoles":
          case "ResultHistoricalHistoryDeviceRoles":
          case "TargetDetailHistoryDeviceRoles":
            status = env.HISTORY_DEVICE_ROLES_FILTER_TIMEPICKER_STATUS;
            break;

          case "deviceHistory":
          case "ResultDeviceHistory":
          case "ResultHistoricalDeviceHistory":
          case "TargetDetailDeviceHistory":
            status = env.DEVICE_HISTORY_FILTER_TIMEPICKER_STATUS;
            break;

          case "tetheredDevice":
          case "ResultTetheredDevice":
          case "ResultHistoricalTetheredDevice":
          case "TargetDetailTetheredDevice":
            status = env.TETHERED_DEVICE_FILTER_TIMEPICKER_STATUS;
            break;
        }
        if (returnStatus) {        
          return status;
        }
        if (status) {
          // date_format = "YYYY-MM-DD HH:mm:ss"
          date_format = env.GLOBAL_FILTER_TIMEPICKER_FORMAT
        }
        // console.log('+++route', route)
        // console.log('+++status', status)
        // console.log('+++returnStatus', returnStatus)
        // console.log('+++date_format', date_format)
        return date_format;
      };
    },
    getIdleWaitingTime() {
      let idle_waiting_time = {
        minutes: 5,
        miliseconds: 5 * 60000,
      };

      if (env.VUE_APP_IDLE_WAITING_TIME) {
        let minutes = parseInt(env.VUE_APP_IDLE_WAITING_TIME);
        idle_waiting_time.minutes = minutes;
        idle_waiting_time.miliseconds = minutes * 60000;
      }

      return idle_waiting_time;
    },
    getApiResp() {
      return function (lsKey) {
        let resp = null;
        try {
          resp = ls.get(lsKey);
        } catch (error) {
          console.log("getApiResp:error");
          console.log(error);
        }
        return resp;
      };
    },
    getServiceFR() {
      return env.VUE_APP_SERVICE_FR ? env.VUE_APP_SERVICE_FR : "openvino";
    },

    getLoadingInterval() {
      return env.VUE_APP_LOADING_ANIMATION_INTERVAL ? env.VUE_APP_LOADING_ANIMATION_INTERVAL : "5,10,30,40";
    },

    getLoadingIntervalByRoute() {
      return function (route) {
        let range = env.VUE_APP_LOADING_ANIMATION_INTERVAL ? env.VUE_APP_LOADING_ANIMATION_INTERVAL : "5,10,30,40";

        switch (route) {
          case "recentLocation":
          case "ResultRecentLocation":
          case "ResultRealtimeRecentLocation":
          case "TargetDetailRecentLocation":
            range = env.VUE_APP_LOADING_ANIMATION_INTERVAL_RECENT_LOCATION ? env.VUE_APP_LOADING_ANIMATION_INTERVAL_RECENT_LOCATION : "5,10,30,40";
            break;
     
          case "overall":
          case "ResultOverall":
          case "ResultHistoricalOverall":
          case "TargetDetailOverall":
          case "deviceHistory":
          case "ResultDeviceHistory":
          case "ResultHistoricalDeviceHistory":
          case "TargetDetailDeviceHistory":
          case "tetheredDevice":
          case "ResultTetheredDevice":
          case "ResultHistoricalTetheredDevice":
          case "TargetDetailTetheredDevice":
            range = env.VUE_APP_LOADING_ANIMATION_INTERVAL_HISTORY ? env.VUE_APP_LOADING_ANIMATION_INTERVAL_HISTORY : "10,90,180,200";
            break;

          case "browsingLog":
          case "ResultBrowsingLog":
          case "ResultHistoricalBrowsingLog":
          case "TargetDetailBrowsingLog":
          case "locationLog":
          case "ResultLocationLog":
          case "ResultHistoricalLocationLog":
          case "TargetDetailLocationLog":
          case "aerialLog":
          case "ResultAerialLog":
          case "ResultHistoricalAerialLog":
          case "TargetDetailAerialLog":
            range = env.VUE_APP_LOADING_ANIMATION_INTERVAL_LOG ? env.VUE_APP_LOADING_ANIMATION_INTERVAL_LOG : "10,30,40,50";
            break;

        }
        return range;
      };
    },

    getIsRemainingQuota() {
      // return (env.VUE_APP_REMAINING_QUOTA && env.VUE_APP_REMAINING_QUOTA.toLowerCase() == 'true' ? true : false);
      return checkFeature("remaining_quota");
    },
    getIsWatchlistQuota() {
      // return (env.VUE_APP_REMAINING_QUOTA && env.VUE_APP_REMAINING_QUOTA.toLowerCase() == 'true' ? true : false);
      return checkFeature("watchlist");
    },

    getIsUseNotification() {
      let notif = {
        is_active: false,
        filter_type: false,
      };

      // if (env.VUE_APP_NOTIFICATION && env.VUE_APP_NOTIFICATION.toLowerCase() == 'true')

      // KHUSUS | BEGIN
      // untuk check undefined
      let check_env = {
        vue_app_fcm_topic: env.VUE_APP_FCM_TOPIC,
        vue_app_fcm_server_key: env.VUE_APP_FCM_SERVER_KEY,
        vue_app_fcm_sender_id: env.VUE_APP_FCM_SENDER_ID,
        vue_app_fcm_public_vapid_key: env.VUE_APP_FCM_PUBLIC_VAPID_KEY,
        vue_app_fcm_api_key: env.VUE_APP_FCM_API_KEY,
        vue_app_fcm_auth_domain: env.VUE_APP_FCM_AUTH_DOMAIN,
        vue_app_fcm_database_url: env.VUE_APP_FCM_DATABASE_URL,
        vue_app_fcm_project_id: env.VUE_APP_FCM_PROJECT_ID,
        vue_app_fcm_storage_bucket: env.VUE_APP_FCM_STORAGE_BUCKET,
        vue_app_fcm_app_id: env.VUE_APP_FCM_APP_ID,
        vue_app_fcm_measurement_id: env.VUE_APP_FCM_MEASUREMENT_ID,
        vue_app_fcm_sdk_library: env.VUE_APP_FCM_SDK_LIBRARY,
        vue_app_fcm_fcm_library: env.VUE_APP_FCM_FCM_LIBRARY,
      };
      // KHUSUS | END

      let check_undefined = Object.values(check_env).every((x) =>
        [undefined, ""].includes(x)
      );
      if (checkFeature("notification") && !check_undefined)
        notif.is_active = true;

      if (env.VUE_APP_NOTIFICATION_FILTER_TYPE) notif.filter_type = true;

      return notif;
    },
    // getHidePredictionName() {
    //   return (env.VUE_APP_HIDE_PREDICTION_NAME && env.VUE_APP_HIDE_PREDICTION_NAME.toLowerCase() == 'true' ? true : false);
    // },
    // getHideMultiTracking() {
    //   return (env.VUE_APP_HIDE_MULTI_TRACKING && env.VUE_APP_HIDE_MULTI_TRACKING.toLowerCase() == 'true' ? true : false);
    // },
    getIsMonitoringPeriod() {
      // return (env.VUE_APP_MONITORING_PERIOD && env.VUE_APP_MONITORING_PERIOD.toLowerCase() == 'true' ? true : false);
      return checkFeature("target|monitoring_period");
    },
    getIsMultiMonitoringPeriod() {
      return checkFeature("multi_tracking|monitoring_period");
    },
    getMonitoringPeriod() {
      var monitoring_time = {
        stop6hours: {
          text: "6 Hours",
          options: [
            {
              name: "5 Minutes",
              value: "every5minutes",
            },
            {
              name: "15 Minutes",
              value: "every15minutes",
            },
          ],
        },
        stop12hours: {
          text: "12 Hours",
          options: [
            {
              name: "30 Minutes",
              value: "every30minutes",
            },
            {
              name: "1 Hours",
              value: "every1hour",
            },
          ],
        },
      };
      try {
        monitoring_time = JSON.parse(env.VUE_APP_MONITORING_TIME);
        console.log("monitoring_time from env");
      } catch (error) {
        console.log("monitoring_time default");
        console.log(error);
      }
      // console.log('monitoring_time')
      // console.log(monitoring_time)
      // console.log('monitoring_time json string')
      // console.log(JSON.stringify(monitoring_time))
      return monitoring_time;
    },
    getValidationImei() {
      return {
        min: env.VUE_APP_MIN_IMEI ? env.VUE_APP_MIN_IMEI : 15,
        max: env.VUE_APP_MAX_IMEI ? env.VUE_APP_MAX_IMEI : 15,
      };
    },
    getValidationImeiSv() {
      return {
        min: env.VUE_APP_MIN_IMEI_SV ? env.VUE_APP_MIN_IMEI_SV : 16,
        max: env.VUE_APP_MAX_IMEI_SV ? env.VUE_APP_MAX_IMEI_SV : 16,
      };
    },
    getOperatorHideUsers() {
      return env.VUE_APP_OP_HIDE_USERS ? env.VUE_APP_OP_HIDE_USERS : true;
    },
    getHamburgerAnalystHardcode() {
      let state = false;
      if (env.VUE_APP_HAMBURGER_ANALYST_HARDCODE) state = true;

      return state;
    },

    getElapsed: (state) => ({ routeName, title }) => {
      if (state.loadingComponents[routeName] && state.loadingComponents[routeName][title]) {
        return state.loadingComponents[routeName][title].elapsed || 0;
      }
      return 0;
    },


  },
  actions: {
    addApiResp({ commit }, dt = null) {
      var tmpApiResp;
      // var lsKey = key + '_' + service + '_' + module + '_' + record_size + '_' + from_date + '-' + to_date;
      if (!ls.get("listApiResp")) {
        tmpApiResp = [];
      } else {
        tmpApiResp = JSON.parse(ls.get("listApiResp"));
      }
      if (!(tmpApiResp instanceof Array)) {
        tmpApiResp = [];
      }
      tmpApiResp.push(dt.lsKey);
      // console.log('tmpApiResp')
      // console.log(tmpApiResp)
      ls.set("listApiResp", JSON.stringify(tmpApiResp));
      ls.set(dt.lsKey, JSON.stringify(dt.resp));
      // console.log("addApiResp ls.get('listApiResp')")
      // console.log(ls.get('listApiResp'))
    },

    async clearApiResp() {
      // console.log('masuk clear')
      // console.log(ls.get('listApiResp'))
      var tmpApiResp;
      if (!ls.get("listApiResp")) {
        tmpApiResp = [];
        // console.log('masuk clear []')
      } else {
        tmpApiResp = JSON.parse(ls.get("listApiResp"));
        // console.log('masuk clear ada')
        // console.log(tmpApiResp)
      }
      // console.log('masuk clear tmpApiResp')
      // console.log(tmpApiResp)
      if (!(tmpApiResp instanceof Array)) {
        tmpApiResp = [];
      }

      // console.log('masuk clear tmpApiResp')
      // console.log(tmpApiResp)
      try {
        tmpApiResp.forEach((k) => ls.remove(k));
      } catch (error) {
        console.log("error remove");
        console.log(error);
      }

      ls.set("listApiResp", JSON.stringify([]));
    },

    async getConfig({ state, commit, dispatch }) {
      if (
        state.status_config.cancel !== null &&
        state.status_config.status === "loading"
      )
        await state.status_config.cancel("Operation canceled by the user.");

      axios
        .get("api/v1/config", {
          // params: params,
          cancelToken: new cancelToken(function executor(c) {
            cancelConfig = c;
            commit("setStatusConfig", {
              status: "loading",
              cancel: c,
              code: null,
            });
          }),
        })
        .then((resp) => {
          // console.log(resp.data)
          let data = resp.data;
          let result = {
            status: true,
            cancel: null,
            code:
              resp.response && resp.response.status
                ? resp.response.status
                : null,
          };

          commit("setConfig", data);

          if (Object.keys(data).length === 0) result.status = "empty";

          commit("setStatusConfig", result);
        })
        .catch((resp) => {
          // console.log(resp)
          let result = {
            status: "failed",
            cancel: null,
            code:
              resp.response && resp.response.status
                ? resp.response.status
                : null,
          };

          if (axiosOrigin.isCancel(resp)) {
            result.status = "loading";
            result.cancel = cancelConfig;
          }

          // commit('setConfig', {});
          commit("setStatusConfig", result);
          dispatch(
            "errors/handleApiResponse",
            [
              result.code,
              resp.response && resp.response.data && resp.response.data.message
                ? resp.response.data.message
                : null,
            ],
            { root: true }
          );
        });
    },

    startTimer({ commit, state, dispatch }, { routeName, title }) {
      if (!state.loadingComponents[routeName] || !state.loadingComponents[routeName][title] || !state.loadingComponents[routeName][title].timer) {
        const timer = setInterval(() => {
          dispatch('updateElapsed', { routeName, title });
        }, 1000);
        commit('SET_TIMER', { routeName, timer, title });
      }
      if (!state.loadingComponents[routeName] || !state.loadingComponents[routeName][title] || !state.loadingComponents[routeName][title].lastTimestamp) {
        commit('SET_ELAPSED', { routeName, elapsed: 0, title });
        commit('SET_LAST_TIMESTAMP', { routeName, timestamp: Date.now(), title });
      }
    },
    updateElapsed({ commit, state }, { routeName, title }) {
      const now = Date.now();
      const lastTimestamp = state.loadingComponents[routeName][title].lastTimestamp || now;
      const elapsed = Math.floor((now - lastTimestamp) / 1000);
      commit('SET_ELAPSED', { routeName, elapsed, title });
    },
    resetTimer({ commit }, { routeName, title }) {
      commit('CLEAR_TIMER', { routeName, title });
      commit('SET_ELAPSED', { routeName, elapsed: 0, title });
      commit('SET_LAST_TIMESTAMP', { routeName, timestamp: null, title });
    },
  },
};

export default config;
