import axios from "@/axios-main";
import axiosOrigin from "axios";

let cancelPredictionName;
var cancelToken = axiosOrigin.CancelToken;

const predictionName = {
  namespaced: true,
  state: {
    module: 'Prediction Name',
    status: false,
    status_prediction_name: {
      status: false,
      cancel: null,
      code: null,
    },

    prediction_name: {},
  },
  mutations: {
    setStatus: (state, status) => {
      state.status = status;
    },
    setStatusPredictionName: (state, status) => {
      state.status_prediction_name = status;
    },

    setPredictionName: (state, data) => {
      state.prediction_name = data;
    },
  },
  getters: {},
  actions: {
    async getResultData({ state, rootGetters, dispatch, commit }, [key, type, service_source=null]) {

      await dispatch('setLoading');

      await dispatch('getPredictionName', [key, type, service_source]);

      commit('setStatus', true);
    },

    async setLoading({ commit, state }) {
      await commit('setStatus', 'loading');

      await commit('setStatusPredictionName', {
        status: 'loading',
        cancel: state.status_prediction_name.cancel,
        code: null
      });
    },

    async setEmpty({ commit, state }) {
      commit('setStatus', false);

      if (state.status_prediction_name.cancel !== null && state.status_prediction_name.status === 'loading')
        await state.status_prediction_name.cancel('Operation canceled by the user.');
      commit('setStatusPredictionName', {
        status: false,
        cancel: null,
        code: null,
      });

      commit('setPredictionName', {});
    },

    async getPredictionName({ commit, dispatch, state, rootState }, [key, type, service_source]) {

      // let service = 'service';
      // if (service_source === 'internal') {
      //   service = 'service-int'
      // } else if (service_source === 'external') {
      //   service = 'service-ext'
      // }

      if (state.status_prediction_name.cancel !== null && state.status_prediction_name.status === 'loading')
        await state.status_prediction_name.cancel('Operation canceled by the user.');

      if (type !== 'msisdn')
        key = rootState.subheader && rootState.subheader.msisdn ? rootState.subheader.msisdn : null;
      
      let params = new FormData();
      params.append('key', key);
      params.append('type', type);

      await axios.post('api/v1/service/prediction/prediction-name', params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelPredictionName = c;
          commit('setStatusPredictionName', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        // console.log(resp)
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setPredictionName', data);

        if (Object.keys(data).length===0)
          result.status = 'empty';
        
        commit('setStatusPredictionName', result);
      })
      .catch((resp) => {
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        // for development only !!!
        // let data = {
        //   "name": 'name',
        //   "phoneNumber": '628712123012',
        //   "country": "ID",
        //   "displayName": "aaa",
        //   "profileImage": null,
        //   "email": null,
        //   "isSpam": false,
        //   "tags": [
        //       'a',
        //       'ab',
        //       'abc',
        //       'aaa',
        //       'abb',
        //       'acc',
        //       'aaa',
        //   ]
        // };
        // commit('setPredictionName', data);
        // result.status = true;

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelPredictionName;
        }

        commit('setStatusPredictionName', result);
        if (!axiosOrigin.isCancel(resp))
        dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null), state.module], { root: true });
      });
    },
  }
};

export default predictionName;
