<template lang="pug">
.global-class
    .popup-layer.popup-layer--session(
        v-bind:class="{'is-show' : (user !== null && isIdle === true && isIdle !== false)}"
    )
        .popup-container
            .popup-container__header
                h2.text-xl Session Expired

            .popup-container__body
                .mb-4
                    p You have left this browser idle for {{ idle_waiting_time }} minutes. <span class="text-red-500">{{ time/1000 }}</span> second left before logout.

    component(:is="layout")
        router-view
</template>

<script>
import { mapState } from 'vuex';
import firebase from 'firebase/app';
import 'firebase/app';
import 'firebase/messaging';
// import fcmConfig from '@/config/fcm';
import { encrypt, decrypt } from '@/util/crypto';
// import { IdleVueMixin } from "idle-vue";
import { localStorageMixin } from '@/util/mixins/localStorageMixin';
// import { checkFeature } from '@/config/features';

const applayout = 'app';

export default {
    name: 'MainApp',
    components: {
        // 
    },
    mixins: [localStorageMixin],
    data: function () {
        return {
            favicon: this.$store.getters['config/getFavicon'],
            title: this.$store.getters['config/getTitleGlobal'],
            time: 10000,
            from_this_page: false,
        };
    },
    metaInfo() {
        return {
            title: this.title,
            titleTemplate: (this.title === this.$store.getters['config/getTitleGlobal'] ? this.title : '%s |  ' + this.$store.getters['config/getTitleGlobal']),
        };
    },
    computed: {
        ...mapState('auth', ['user']),
        ...mapState('config', ['isIdle']),
        layout() {
            return (this.$route.meta.layout || applayout) + '-layout'
        },
        route() {
            return this.$route;
        },
        idle_waiting_time() {
            return this.$store.getters['config/getIdleWaitingTime'].minutes;
        },
    },
    watch: {
        layout() { },
        route(newData) {
            this.changeTitle();
        },
        isIdle(newData, oldData) {
            // if (this.user !== null && newData === true && oldData === false)
            if (this.user !== null && newData === true)
                this.startIdle();

            // if (this.user !== null) {
            //     if (newData === true && oldData === false)
            //         this.startIdle();
            //     // } else if (newData === false && oldData === false) {
            //     //     this.$store.commit('config/IDLE_CHANGED', false);
            //     //     this.resetAppIdleTimeout();
            //     // }
            // }
        },
    },
    methods: {
        changeTitle() {
            // console.log("this.route.matched")
            // console.log(this.route.matched)
            let title = this.title
            try {
                switch (this.route.matched[0].name) {
                    case 'DashboardPage':
                        title = 'Dashboard';
                        break;

                    case 'ForgotPage':
                        title = 'Forgot Password';
                        break;
                    case 'LoginPage':
                        title = 'Login';
                        break;
                    case 'ResetPage':
                        title = 'Reset Password';
                        break;
                    case 'PageLicense':
                        title = 'License';
                        break;
                    case 'PageLicenseLog':
                        title = 'License Log';
                        break;
                    case 'PageLicenseExpired':
                        title = 'License Expired';
                        break;

                    case 'ResultShell':
                        title = 'Result';
                        switch (this.route.matched[1].name) {
                            case 'ResultRecentLocation':
                                title = 'Recent Location - ' + title;
                                break;
                            case 'ResultOverall':
                                title = 'Overall - ' + title;
                                break;
                            case 'ResultLocationMovement':
                                title = 'Location Movement - ' + title;
                                break;
                            case 'ResultLocationLog':
                                title = 'Location Log - ' + title;
                                break;
                            case 'ResultBrowsingLog':
                                title = 'Browsing Log - ' + title;
                                break;
                            case 'ResultAerialLog':
                                title = 'Aerial Log - ' + title;
                                break;
                            case 'ResultProfile':
                                title = 'Digital ID - ' + title;
                                break;
                            case 'ResultHistoryDeviceRoles':
                                title = 'History Device Roles - ' + title;
                                break;
                            case 'ResultDeviceHistory':
                                title = 'Device History - ' + title;
                                break;
                            case 'ResultTetheredDevice':
                                title = 'Tethered Device - ' + title;
                                break;
                            case 'ResultPredictionName':
                                title = 'Prediction Name - ' + title;
                                break;
                        }
                        break;

                    case 'ResultRealtimeShell':
                        title = 'Result Realtime';
                        switch (this.route.matched[1].name) {
                            case 'ResultRealtimeRecentLocation':
                                title = 'Recent Location - ' + title;
                                break;
                        }
                        break;

                    case 'ResultHistoricalShell':
                        title = 'Result Historical';
                        switch (this.route.matched[1].name) {
                            case 'ResultHistoricalOverall':
                                title = 'Overall - ' + title;
                                break;
                            case 'ResultHistoricalLocationMovement':
                                title = 'Location Movement - ' + title;
                                break;
                            case 'ResultHistoricalLocationLog':
                                title = 'Location Log - ' + title;
                                break;
                            case 'ResultHistoricalBrowsingLog':
                                title = 'Browsing Log - ' + title;
                                break;
                            case 'ResultHistoricalAerialLog':
                                title = 'Aerial Log - ' + title;
                                break;
                            case 'ResultHistoricalProfile':
                                title = 'Digital ID - ' + title;
                                break;
                            case 'ResultHistoricalHistoryDeviceRoles':
                                title = 'History Device Roles - ' + title;
                                break;
                            case 'ResultHistoricalDeviceHistory':
                                title = 'Device History - ' + title;
                                break;
                            case 'ResultHistoricalTetheredDevice':
                                title = 'Tethered Device - ' + title;
                                break;
                            case 'ResultHistoricalPredictionName':
                                title = 'Prediction Name - ' + title;
                                break;
                            case 'ResultHistoricalDigitalFootprint':
                                title = 'Digital Footprint - ' + title;
                                break;
                        }
                        break;

                    // target detail
                    case 'pageTargetDetail':
                        title = 'Detail - Target';
                        switch (this.route.matched[1].name) {
                            case 'pageTargetDetailHome':
                                title = 'Target Information - ' + title;
                                break;
                            case 'TargetDetailRecentLocation':
                                title = 'Recent Location - ' + title;
                                break;
                            case 'TargetDetailOverall':
                                title = 'Overall - ' + title;
                                break;
                            case 'TargetDetailLocationMovement':
                                title = 'Location Movement - ' + title;
                                break;
                            case 'TargetDetailLocationLog':
                                title = 'Location Log - ' + title;
                                break;
                            case 'TargetDetailBrowsingLog':
                                title = 'Browsing Log - ' + title;
                                break;
                            case 'TargetDetailAerialLog':
                                title = 'Aerial Log - ' + title;
                                break;
                            case 'TargetDetailProfile':
                                title = 'Digital ID - ' + title;
                                break;
                            case 'TargetDetailHistoryDeviceRoles':
                                title = 'History Device Roles - ' + title;
                                break;
                            case 'TargetDetailDeviceHistory':
                                title = 'Device History - ' + title;
                                break;
                            case 'TargetDetailTetheredDevice':
                                title = 'Tethered Device - ' + title;
                                break;
                            case 'TargetDetailPredictionName':
                                title = 'Prediction Name - ' + title;
                                break;
                        }
                        break;
                    case 'pageNotifications':
                        title = 'Notifications';
                        break;
                    case 'pageTrackingHistory':
                        title = 'Tracking History';
                        break;
                    case 'pageProfile':
                        title = 'Digital ID';
                        break;
                    case 'PageForbidden':
                        title = 'Forbidden';
                        break;
                    case 'PageNotFound':
                        title = 'Not Found';
                        break;

                    case 'pageImeiSv':
                        title = 'Device Track';
                        switch (this.route.matched[1].name) {
                            case 'pageImeiSvIndex':
                                break;
                            case 'pageImeiSvDetail':
                                title = 'Detail - ' + title;
                                break;
                        }
                        break;
                    case 'meIdTrack':
                        title = 'ME-ID Track';
                        switch (this.route.matched[1].name) {
                            case 'pagemeIdTrackIndex':
                                break;
                            case 'pagemeIdTrackDetail':
                                title = 'Detail - ' + title;
                                break;
                        }
                        break;
                    case 'pageCompareTarget':
                        title = 'Compare Target Loc';
                        switch (this.route.matched[1].name) {
                            case 'pageCompareTargetIndex':
                                break;
                            case 'pageCompareTargetDetail':
                                title = 'Detail - ' + title;
                                break;
                        }
                        break;
                    case 'pageUltimo':
                        title = 'Ultimo';
                        switch (this.route.matched[1].name) {
                            case 'pageUltimoIndex':
                                break;
                        }
                        break;
                    // for undefined result: 
                    //     roles
                    //     users
                    //     tactical-device
                    //     monitoring-log
                    //     target
                    //     multi-tracking
                    //     case-management
                    //     territory-virtual
                    //     acl -> edit
                    case undefined:
                        switch (this.route.matched[1].name) {
                            case 'pageTargetTagsIndex':
                                title = 'Digital Trace';
                                break;
                            case 'pageTargetTagsDetail':
                                title = 'Detail - Digital Trace';
                                break;

                            // License
                            case 'pageLicenseIndex':
                                title = 'License';
                                break;
                            case 'pageLicenseHistory':
                                title = 'History - License';
                                break;
                            case 'pageLicenseCreate':
                                title = 'Add - License';
                                break;
                            case 'pageLicenseEdit':
                                title = 'Edit - License';
                                break;

                            // Modules
                            case 'pageModulesIndex':
                                title = 'Modules';
                                break;
                            case 'pageModulesCreate':
                                title = 'Add - Modules';
                                break;
                            case 'pageModulesEdit':
                                title = 'Edit - Modules';
                                break;

                            // Functions
                            case 'pageFunctionsIndex':
                                title = 'Functions';
                                break;
                            case 'pageFunctionsCreate':
                                title = 'Add - Functions';
                                break;
                            case 'pageFunctionsEdit':
                                title = 'Edit - Functions';
                                break;

                            // Roles
                            case 'pageRolesIndex':
                                title = 'Roles';
                                break;
                            case 'pageRolesCreate':
                                title = 'Add - Roles';
                                break;
                            case 'pageRolesEdit':
                                title = 'Edit - Roles';
                                break;

                            // Users
                            case 'pageUsersIndex':
                                title = 'Users';
                                break;
                            case 'pageUsersCreate':
                                title = 'Add - Users';
                                break;
                            case 'pageUsersEdit':
                                title = 'Edit - Users';
                                break;
                            case 'pageUsersLogActivity':
                                title = 'Log Activity - Users';
                                break;

                            // Versions
                            case 'pageVersionsIndex':
                                title = 'Versions';
                                break;
                            case 'pageVersionsCreate':
                                title = 'Add - Versions';
                                break;
                            case 'pageVersionsEdit':
                                title = 'Edit - Versions';
                                break;

                            // TacticalDevice
                            case 'pageTacticalDeviceIndex':
                                title = 'Tactical Devices';
                                break;
                            case 'pageTacticalDeviceCreate':
                                title = 'Add - Tactical Devices';
                                break;
                            case 'pageTacticalDeviceEdit':
                                title = 'Edit - Tactical Devices';
                                break;
                            case 'pageTacticalDeviceMaps':
                                title = 'Maps - Tactical Devices';
                                break;

                            // MonitoringLog
                            case 'pageMonitoringLogIndex':
                                title = 'Activity - Monitoring Log';
                                break;
                            case 'pageMonitoringLogChart':
                                title = 'Chart - Monitoring Log';
                                break;

                            // Target
                            case 'pageTargetIndex':
                                title = 'Target';
                                break;
                            case 'pageTargetCreate':
                                title = 'Add - Target';
                                break;
                            case 'pageTargetEdit':
                                title = 'Edit - Target';
                                break;
                            case 'pageTargetMaps':
                                title = 'Maps - Target';
                                break;
                            case 'pageTargetSummary':
                                title = 'Summary - Target';
                                break;
                            case 'pageTargetHistory':
                                title = 'History - Target';
                                break;

                            // MultiTracking
                            case 'pageMultiTrackingIndex':
                                title = 'Multi Tracking';
                                break;
                            case 'pageMultiTrackingCreate':
                                title = 'Add - Multi Tracking';
                                break;
                            case 'pageMultiTrackingEdit':
                                title = 'Edit - Multi Tracking';
                                break;
                            case 'pageMultiTrackingMeetingPointDetail':
                                title = 'Detail Meeting Point - Meeting Point - Detail - Multi Tracking';
                                break;
                            case 'pageMultiTrackingDetail':
                                title = 'Detail - Multi Tracking';
                                switch (this.route.matched[2].name) {
                                    case 'pageMultiTrackingMeetingPoint':
                                        title = 'Meeting Point - ' + title;
                                        break;
                                    case 'pageMultiTrackingLocationHistory':
                                        title = 'Location History - ' + title;
                                        break;
                                    case 'pageMultiTrackingMonitoringHistory':
                                        title = 'Monitoring History - ' + title;
                                        break;
                                }
                                break;

                            // CaseManagement
                            case 'pageCaseManagementIndex':
                                title = 'Case Management';
                                break;
                            case 'pageCaseManagementCreate':
                                title = 'Add - Case Management';
                                break;
                            case 'pageCaseManagementEdit':
                                title = 'Edit - Case Management';
                                break;

                            // TerritoryVirtual
                            case 'pageTerritoryVirtualIndex':
                                title = 'Territory Virtual';
                                break;
                            case 'pageTerritoryVirtualCreate':
                                title = 'Add - Territory Virtual';
                                break;
                            case 'pageTerritoryVirtualEdit':
                                title = 'Edit - Territory Virtual';
                                break;
                            case 'pageTerritoryVirtualDetail':
                                title = 'Detail - Territory Virtual';
                                break;
                            case 'pageTerritoryVirtualDetailNotification':
                                title = 'Detail Notifications - Territory Virtual';
                                break;
                            // TerritoryVirtual
                            case 'territory-virtual-index':
                                title = 'Territory Virtual';
                                break;
                            case 'territory-virtual-create':
                                title = 'Add - Territory Virtual';
                                break;
                            case 'territory-virtual-edit':
                                title = 'Edit - Territory Virtual';
                                break;
                            case 'territory-virtual-detail':
                                title = 'Detail - Territory Virtual';
                                break;
                            // Acl
                            case 'pageAclEdit':
                                title = 'Access Control List';
                                break;

                            case 'pageLogManagement':
                                title = 'Log Management';
                                break;

                            // case undefined:
                            //     break;

                            // Dcd
                            case 'pageDcdIndex':
                                title = 'Digital Cyber Data';
                                break;
                            case 'pageDcdCreate':
                                title = 'Add - Digital Cyber Data';
                                break;
                            case 'pageDcdDetail':
                                title = 'Detail - Digital Cyber Data';
                                break;
                            case 'pageDcdEdit':
                                title = 'Edit - Digital Cyber Data';
                                break;
                        }
                        break;

                    default:
                        title = this.$store.getters['config/getTitleGlobal'];
                }
            } catch (error) {
                // console.log(this.route)
                // console.log(error)
            }
            this.title = title;
        },
        showLoading(show = true, scrollTop = false) {
            if (show) {
                this.$swal.fire({
                    title: '',
                    html: '<div class="save_loading"><svg viewBox="0 0 140 140" width="140" height="140"><g class="outline"><path d="m 70 28 a 1 1 0 0 0 0 84 a 1 1 0 0 0 0 -84" stroke="rgba(0,0,0,0.1)" stroke-width="4" fill="none" stroke-linecap="round" stroke-linejoin="round"></path></g><g class="circle"><path d="m 70 28 a 1 1 0 0 0 0 84 a 1 1 0 0 0 0 -84" stroke="#71BBFF" stroke-width="4" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-dashoffset="200" stroke-dasharray="300"></path></g></svg></div><div><h4>Loading...</h4></div>',
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    // onClose: () => {
                    onAfterClose: () => {
                        // console.log('not being called');
                        if (scrollTop) {
                            window.scrollTo(0, 0);
                        }
                    }
                });

            } else {
                // this.$swal.close();
                this.$swal.clickCancel();
            }
        },
        error(message = null, data = null, title = null) {
            // console.log('Error in main app')
            let errors = '';

            if (data !== null) {
                errors = errors + '<section class="container-alert">';
                Object.keys(data).forEach(function (item) {
                    // errors += data[item] + '<br><br>';
                    // errors += '<div class="swal2-html-container" style="display: block;">' + data[item] + '<br><div>';
                    // errors = errors + '<div class="swal2-html-container mt-2" style="display: block;">' + data[item] + '<div>';
                    errors = errors + '<div class="container-alert__list">' + data[item] + '</div>';
                });
                errors = errors + '</section>';
            }

            // console.log({message, errors})
            this.$swal.fire({
                icon: 'error',
                // title: 'Failed!',
                title: (title !== null ? title : 'Failed!'),
                text: (message !== null ? message : 'Something wrong.'),
                html: errors,
                // timer: 3000
            });
        },
        warning(message = null, data = null) {
            // console.log('warning in main app')
            let warnings = '';

            if (data !== null) {
                Object.keys(data).forEach(function (item) {
                    // warnings += data[item] + '<br><br>';
                    // warnings += '<div class="swal2-html-container" style="display: block;">' + data[item] + '<br><div>';
                    warnings = warnings + '<div class="swal2-html-container mt-2" style="display: block;">' + data[item] + '<div>';
                });
            }
            // console.log({message, warnings})
            this.$swal.fire({
                icon: 'warning',
                title: 'Warning!',
                text: (message !== null ? message : 'Data still not found, please try again.'),
                html: warnings,
                // timer: 3000
            });
        },
        async clickNotification(type, data) {
            if (type === 'territory_virtual') {
                // console.log('check data fcm')
                // console.log(data.data)
                // console.log(this.$route.name)
                // console.log(this.$route.params.id)
                // console.log(this.$route.params.target_id)
                // console.log(decrypt(this.$route.params.msisdn))

                for (var j = 0; j < data.data.length; j++) {
                    if (data.data[j].key == 'target') {
                        var target = JSON.parse(data.data[j].value);
                    } else if (data.data[j].key == 'territory_virtual_id') {
                        var territory_virtual_id = data.data[j].value;
                    }
                    //  else if(data.data[j].key == 'history_territory_virtual_id') {
                    //     var history_territory_virtual_id = data.data[j].value;
                    // }
                }

                // console.log(target)
                // console.log(territory_virtual_id)

                if (this.$route.name === 'pageTerritoryVirtualDetailNotification') {
                    if (this.$route.params.id === territory_virtual_id && this.$route.params.target_id === target.id && decrypt(this.$route.params.msisdn) === target.msisdn) {
                        // console.log('on same id && target_id && msisdn')
                        EventEmit.$emit('territoryNotificationOnDetail');
                    } else {
                        // console.log('on same page')
                        this.$router.push({ name: 'pageTerritoryVirtualDetailNotification', params: { id: territory_virtual_id, target_id: target.id, msisdn: encrypt(target.msisdn) } });
                    }
                } else {
                    // console.log('on different page')
                    this.$router.push({ name: 'pageTerritoryVirtualDetailNotification', params: { id: territory_virtual_id, target_id: target.id, msisdn: encrypt(target.msisdn) } });
                }
            }
            else if (type === 'meeting_point') {

                for (var i = 0; i < data.data.length; i++) {
                    if (data.data[i].key == 'meeting_point_id') {
                        var meetingPointId = data.data[i].value
                    } else if (data.data[i].key == 'multi_tracking_id') {
                        var multiTrackingId = data.data[i].value
                    }
                }
                if (this.$route.name === 'pageMultiTrackingMeetingPointDetail') {
                    if ((this.$route.params.id === data.multiTrackingId) && (this.$route.params.idMeet === data.meetingPointId)) {
                        // console.log('on same id')
                        EventEmit.$emit('meetingPointNotification');
                    } else {
                        // console.log('on same page')
                        this.$router.push({ name: 'pageMultiTrackingMeetingPointDetail', params: { id: multiTrackingId, idMeet: meetingPointId } });
                    }
                } else {
                    // console.log('on different page')
                    this.$router.push({ name: 'pageMultiTrackingMeetingPointDetail', params: { id: multiTrackingId, idMeet: meetingPointId } });
                }
            }
            else if (type === 'target_active_again' || type === 'multitracking_active_again') {
                // console.log(type)
                // console.log(data)

                for (var i = 0; i < data.data.length; i++) {
                    if (data.data[i].key == 'target') {
                        var target = JSON.parse(data.data[i].value);
                    }
                }

                // targetOrMultitrackingActiveAgain
                // console.log(this.$route.name)

                // if (this.$route.name === 'pageTargetDetail') {
                // if (this.$route.matched[0].name === 'pageTargetDetail' && this.$route.name === 'pageTargetDetailHome') {
                if (this.$route.name === 'pageTargetDetailHome') {
                    // path:'/target/:id/detail/:key?',
                    if ((this.$route.params.id === target.id) && (decrypt(this.$route.params.key) === target.msisdn)) {
                        // console.log('on same id and msisdn')
                        EventEmit.$emit('targetOrMultitrackingActiveAgain');
                    } else {
                        // console.log('on same page')
                        this.$router.push({ name: 'pageTargetDetailHome', params: { id: target.id, key: encrypt(target.msisdn + '&&msisdn') } });
                    }
                } else {
                    // console.log('on different page')
                    this.$router.push({ name: 'pageTargetDetailHome', params: { id: target.id, key: encrypt(target.msisdn + '&&msisdn') } });
                }
            }

            // update notification count
            await this.$store.dispatch('notification/update', data.id)
                .then(() => {
                    this.$store.dispatch('notification/getCount');
                });
        },
        startIdle() {
            // idle
            this.time = 10000;
            let timerId = setInterval(() => {
                this.time -= 1000;

                // if (!this.isIdle || this.isIdle === false) clearInterval(timerId);
                if (this.isIdle === false) clearInterval(timerId);

                if (this.time < 1) {
                    clearInterval(timerId);
                    // Your logout function should be over here

                    // console.log('emit logout start');
                    // if (this.user !== null)
                    EventEmit.$emit('logout');
                }
            }, 1000);
        },

        handleLocalStorageChange() {
            const state_data = this.ls_getData();

            if (state_data && state_data.config) {
                if (typeof state_data.config.isIdle != "undefined" && state_data.config.isIdle === false)
                    this.resetAppIdleTimeout();
                // this.$store.commit('config/IDLE_CHANGED', false);
            }
        },
    },
    created() {
        // set icon
        const favicon = document.querySelector('head link[rel="icon"]');
        favicon.setAttribute('href', this.favicon);

        // check is use feature notification 
        // if (checkFeature('notification')) {
        if (this.$store.getters['config/getIsUseNotification'].is_active) {
            const config = {
                apiKey: this.$fcmConfig.API_KEY,
                authDomain: this.$fcmConfig.AUTH_DOMAIN,
                databaseURL: this.$fcmConfig.DATABASE_URL,
                projectId: this.$fcmConfig.PROJECT_ID,
                storageBucket: this.$fcmConfig.STORAGE_BUCKET,
                messagingSenderId: this.$fcmConfig.SENDER_ID,
                appId: this.$fcmConfig.APP_ID,
                measurementId: this.$fcmConfig.MEASUREMENT_ID,
            };

            firebase.initializeApp(config)

            let messaging = firebase.messaging();

            messaging.usePublicVapidKey(this.$fcmConfig.PUBLIC_VAPID_KEY)

            // Vue.prototype.$messaging = firebase.messaging()

            navigator.serviceWorker.register('/firebase-messaging-sw.js')
                // navigator.serviceWorker.register(firebaseMessagingSw)
                .then((registration) => {
                    // console.log('serviceWorker registered', registration)
                    // Vue.prototype.$messaging.useServiceWorker(registration)
                    messaging.useServiceWorker(registration)
                }).catch(err => {
                    console.log(err)
                })


            // let active_token;
            var self = this;
            messaging.requestPermission().then(() => {
                console.log('Notification permission granted.')

                messaging.getToken().then((token) => {
                    // console.log('New token created: ', token)
                    self.$store.commit('notification/setFcmToken', token);
                    self.$store.dispatch('notification/fcmSubscribeToTopic');
                    // this.saveNotificationToken(token)
                })
            }).catch((err) => {
                console.log('Unable to get permission to notify.', err)
            })

            messaging.onTokenRefresh(function () {
                messaging.getToken().then(function (newToken) {
                    // console.log('Token refreshed: ', newToken)
                    self.$store.commit('notification/setFcmToken', newToken);
                    self.$store.dispatch('notification/fcmSubscribeToTopic');
                    // this.saveNotificationToken(newToken)
                }).catch(function (err) {
                    console.log('Unable to retrieve refreshed token ', err)
                })
            })

            messaging.onMessage(function (payload) {
                // console.log("Message received. ", payload);
                // console.log("Message received.");
                // console.log(self.$route.name);

                var src = '/sounds/to-the-point-568.mp3';
                var c = document.createElement('audio');
                c.src = src; c.play();

                // check if login
                if (self.user === null)
                    return;

                // if (self.$route.name !== 'pageNotifications') {
                //     self.$store.dispatch('notification/getCount');
                // }

                if (self.$route.name === 'pageNotifications') {
                    EventEmit.$emit('notificationOnPageNotifications');
                } else {
                    self.$store.dispatch('notification/getCount');
                }

                let data = null;
                let type = payload.data.type;

                if (payload.data && payload.data.data && typeof payload.data.data === 'string') {
                    // console.log(payload.data.data);
                    data = JSON.parse(payload.data.data);
                }
                //  else {
                //     data = payload.data.data ? payload.data.data : 'no data';
                // }

                // console.log(data);
                // console.log(type);

                if (payload.data && payload.data.type && data !== null) {
                    self.$swal.fire({
                        icon: 'info',
                        title: payload.notification.title,
                        // text: payload.notification.body,
                        // html: '<br>' + payload.notification.body,
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        onOpen: (toast) => {
                            // update notification count

                            toast.addEventListener('mouseenter', self.$swal.stopTimer)
                            toast.addEventListener('mouseleave', self.$swal.resumeTimer)

                            toast.addEventListener('click', function () {
                                // console.log('notif clicked')
                                self.clickNotification(type, data);
                            });
                        }
                    });
                }
            });
        }

        EventEmit.$on('showLoading', (status = true, scrollTop = false) => {
            this.showLoading(status, scrollTop);
        });

        EventEmit.$on('error', (message = null, data = null, title = null) => {
            this.error(message, data, title);
        });

        EventEmit.$on('warning', (message = null, data = null) => {
            this.warning(message, data);
        });
    },
    mounted() {
        console.log(encrypt("https://bff-master-stag.notitiaopportuna.xyz/", false, true))
        console.log(encrypt("azJaSkx0dnRpMjE1NDMxNjQ4NjEwMjIw", false, true))
        console.log(encrypt("VGF1Y0ljSWhteElxUkJQSGRlTkhJcDE2NDg2MTAyMjA=", false, true))
        console.log(decrypt("U2FsdGVkX1+V1g1XqdNwzypqCTmSTuBGWK5htTYlwEvakdovb2Blc9BHtWk2sbH4u1+WN459kozNTc5qSRj9Ug=="))
        this.changeTitle();

        // listen mouse & keyboard activity
        Object.keys(window).forEach(key => {
            if (/^on(key|mouse)/.test(key)) {
                window.addEventListener(key.slice(2), event => {
                    // console.log(event);
                    // console.log(this.isIdle);
                    // this.$store.commit('config/IDLE_CHANGED', false);
                    if (this.user !== null)
                        this.resetAppIdleTimeout();
                });
            }
        });

        // listen localStorage changes, for multiple tabs
        this.ls_attachListener(this.handleLocalStorageChange);
    },
}
</script>

<style lang="sass">

    .small-heading
        @apply .text-xs .uppercase .font-bold .mb-4
        letter-spacing: 0.05em
        
    .save_loading
        width: 140px
        height: 140px
        margin: 0 auto
        animation-duration: 0.5s
        animation-timing-function: linear
        animation-iteration-count: infinite
        animation-name: loadingSweetalert2
        transform-origin: 50% 50%

    @keyframes loadingSweetalert2
        100%
            transform: rotate(-360deg) translate(0,0)

    .tooltip
        display: block !important
        z-index: 10000

        .tooltip-inner
            max-width: 300px
            text-align: center
            // background: black
            background-color: rgba(0, 0, 0, 0.8)
            color: white
            // border-radius: 16px
            border-radius: 5px
            // padding: 5px 10px 4px
            padding: 10px

        .tooltip-arrow
            width: 0
            height: 0
            border-style: solid
            position: absolute
            margin: 5px
            border-color: black
            z-index: 1

        &[x-placement^="top"]
            margin-bottom: 5px

        .tooltip-arrow
            border-width: 5px 5px 0 5px
            border-left-color: transparent !important
            border-right-color: transparent !important
            border-bottom-color: transparent !important
            bottom: -5px
            left: calc(50% - 5px)
            margin-top: 0
            margin-bottom: 0

        &[x-placement^="bottom"]
            margin-top: 5px

        .tooltip-arrow
            border-width: 0 5px 5px 5px
            border-left-color: transparent !important
            border-right-color: transparent !important
            border-top-color: transparent !important
            top: -5px
            left: calc(50% - 5px)
            margin-top: 0
            margin-bottom: 0

        &[x-placement^="right"]
            margin-left: 5px

        .tooltip-arrow
            border-width: 5px 5px 5px 0
            border-left-color: transparent !important
            border-top-color: transparent !important
            border-bottom-color: transparent !important
            left: -5px
            top: calc(50% - 5px)
            margin-left: 0
            margin-right: 0

        &[x-placement^="left"]
            margin-right: 5px

        .tooltip-arrow
            border-width: 5px 0 5px 5px
            border-top-color: transparent !important
            border-right-color: transparent !important
            border-bottom-color: transparent !important
            right: -5px
            top: calc(50% - 5px)
            margin-left: 0
            margin-right: 0

        &[aria-hidden='true']
            visibility: hidden
            opacity: 0
            transition: opacity .15s, visibility .15s

        &[aria-hidden='false']
            visibility: visible
            opacity: 1
            transition: opacity .15s

    .popup-layer--session
        // z-index: 999999 !important
        z-index: 1059 !important

    .container-alert
        border: 1px solid #a0aec0
        border-radius: 4px
        background-color: #f7fafc
        &__list
            border-bottom: 1px solid #a0aec0
            border-radius: 4px
            text-align: left
            font-size: 16px
            line-height: 24px
            padding: 1rem
            &:last-child
                margin-bottom: 0px
                border-bottom: 0px
</style>
