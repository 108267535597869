<template lang="pug">
.card.card--tab
    .card--tab__header
        h2.text-xl IP Address
    .card--tab__body
        .box-tabs
            vue-tabs
                v-tab(title="IP Server")
                    IpAddressServer

                v-tab(title="IP Client")
                    IpAddressClient
</template>

<script>
import { VueTabs,VTab } from 'vue-nav-tabs'
import IpAddressServer from './IpAddressServer.vue'
import IpAddressClient from './IpAddressClient.vue'
export default {
    name: 'BrowsingLogIpAddress',
    components: {
        VueTabs,
        VTab,
        IpAddressServer,
        IpAddressClient
    }
}
</script>