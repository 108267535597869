import { env } from '@/config/env';

const historyDeviceRoles = {
  namespaced: true,
  state: {
    module: 'History Device Roles',
    default_range: env.VUE_APP_HISTORY_DEVICE_ROLES_RANGE ? env.VUE_APP_HISTORY_DEVICE_ROLES_RANGE : 13,
    default_export: env.VUE_APP_HISTORY_DEVICE_ROLES_FILTER ? env.VUE_APP_HISTORY_DEVICE_ROLES_FILTER : 'all',

    record_size: null,
    from_date: null,
    to_date: null,

    status: false,

  },
  mutations: {
    setRecordSize: (state, data) => {
      state.record_size = data;
    },
    setFromDate: (state, data) => {
      state.from_date = data;
    },
    setToDate: (state, data) => {
      state.to_date = data;
    },
    setStatus: (state, status) => {
      state.status = status;
    },
  },
  getters: {},
  actions: {
    async getResultData({
      state,
      rootGetters,
      commit,
      dispatch
    }, [key, type, service_source = null]) {

      const default_date = rootGetters['config/getDefaultDate'](state.default_range);

      let record_size = state.record_size !== null ? state.record_size : rootGetters['config/getDefaultRecordSize'];
      let from_date = state.from_date !== null ? state.from_date : default_date.from_date;
      let to_date = state.to_date !== null ? state.to_date : default_date.to_date;

      await dispatch('setLoading');   
      await Promise.all([
        dispatch('deviceHistory/getDeviceHistory', [key, type, record_size, from_date, to_date, service_source], {root:true}),
        dispatch('tetheredDevice/getTetheredDevice', [key, type, record_size, from_date, to_date, service_source], {root:true})
      ]);   

      commit('setStatus', true);
    },

    setLoading({ commit, dispatch }) {
      commit('setStatus', 'loading');
      dispatch('deviceHistory/setLoading', null, {root:true});
      dispatch('tetheredDevice/setLoading', null, {root:true});
    },

    setEmpty({
      commit,
      dispatch
    }) {
      commit('setStatus', false);
      dispatch('deviceHistory/setEmpty', null, {root:true});
      dispatch('tetheredDevice/setEmpty', null, {root:true});
    },
  },
};

export default historyDeviceRoles;
