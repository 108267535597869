<template lang="pug">
.monitoring-log-activity
    .box__body
        .card.card--table.card--no-bg
            .card__header.card--table__header
                h2.text-xl Users Log
            .card--table__body
                .box-filter.px-4.pt-6
                    //- showing data information mobile
                    .m-only
                        .filter-info
                            .flex.items-center.justify-between.mb-3
                                Strong.uppercase.text-xs.font-bold Filtered by
                                button.btn-default-o.btn-small(
                                    type='button'
                                    v-on:click="showFilter = !showFilter"
                                    ) Change Filter
                            p {{ dateRange.startDate }} -  {{ dateRange.endDate }}, All Target, 10 entries

                    //- end showing data information mobile
                    .mobile-filter(
                        v-bind:class="{ 'is-show' : showFilter}"
                    )
                        .m-only
                            .flex.items-center.justify-between.p-4.border-b
                                h2.text-xl Filter
                                button.btn-icon-40(
                                    v-on:click="showFilter = !showFilter"
                                )
                                    IconSet(type="close")
                        .items-filter
                            //- .field.box-find(class="lg:mr-3 lg:mb-0 mr-0 mb-4")
                                .relative
                                    .field.relative.w-full
                                        input.field-style(
                                            placeholder="Select user (write minimum 3 characters)"
                                            v-model="user_keyword"
                                            @keyup="searchUser()"
                                        )
                                        .field-icon
                                            IconSet(type="search")

                                        .box-find-selected(v-if="user_selected")
                                            .flex.items-center.justify-between
                                                .flex.items-center
                                                    figure.mr-3
                                                        //- img.w-6.h-6.rounded-full.object-fit(src="https://randomuser.me/api/portraits/men/41.jpg")
                                                    span {{user_keyword}}
                                                button.btn-icon-24(@click="setEmptyUser" v-tooltip="'Remove user'")
                                                    IconSet(type="close")

                                    .p-4.absolute.container-box-users(v-if="status_list.status === 'loading'")
                                        .flex.flex-wrap.items-center.justify-center
                                            Spinner(show="true" size="24")
                                    .p-4.absolute.container-box-users(v-else-if="list.data && list.data.length > 0")
                                        .small-heading Choose User
                                        .box-users
                                            .item-user(
                                                v-for="(user,index) in list.data"
                                                :key="user.id"
                                                @click="selectUser(user)"
                                            )
                                                //- figure.mr-3
                                                    img.w-6.h-6.rounded-full.object-fit(src="https://randomuser.me/api/portraits/men/41.jpg")
                                                span {{ user.name }}

                                            //- .item-user
                                                figure.mr-3
                                                    img.w-6.h-6.rounded-full.object-fit(src="https://randomuser.me/api/portraits/men/41.jpg")
                                                span Raul Andrews
                                    .p-4.absolute.container-box-users(v-else-if="list.data && list.data.length == 0")
                                        | No data available.

                            .field(class="lg:mr-3 lg:mb-0 lg:w-64 mr-0 w-full mb-4")
                                .relative.field-style.w-full
                                    date-range-picker(
                                        v-if="dateRange.startDate && dateRange.endDate" 
                                        ref="picker" 
                                        v-model="dateRange" 
                                        @update="pickerUpdate($event)" 
                                        :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }" 
                                        :date-format="dateFormat" 
                                        opens="right"
                                        :ranges="default_ranges"
                                        autoApply=true
                                    )
                                        div(
                                            slot="input" 
                                            slot-scope="picker") 
                                                | {{ picker.startDate | date}} - {{ picker.endDate  | date}}

                                    .field-icon
                                        IconSet(type="calendar")

                            .field(class="lg:mr-3 lg:mb-0 lg:w-32 mr-0 w-full mb-4")
                                select.field-style#filter-record-size(@change="sizeChange($event)" v-model="items_per_page" style="min-width:80px")
                                    option(v-for="(s,index) in size" :key="index" :value="s") {{ s }}
                                .field-icon
                                    IconSet(
                                        type="chevron-down"
                                    )

                            button.btn-primary.mr-3(
                                @click="filterList()"
                            ) Filter
                            button.btn-default(
                                @click="resetList()"
                            ) Reset

                            .flex.items-center.flex-grow.gap-3

                            .relative.box-more
                                button.btn-primary.m-btn-icon
                                    IconSet(type="download")
                                    span.m-hide.ml-3 Export

                                .box-popup-more
                                    ul
                                        li
                                            JsonCSV.btn-primary-o.btn-small(
                                                :data="exportData"
                                                :name="csvName"
                                            ) Download CSV

                                        li
                                            JsonCSV.btn-primary-o.btn-small(
                                                :data="exportData"
                                                :name="xlsName"
                                                delimiter=";"
                                            ) Download XLS
                    //- end filter

                //- | {{users_log.data}}
                .box-table.box-table--monitoring
                    table.table
                        thead.m-hide
                            tr
                                th(v-for="(th,index) in tableThead" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}
                        tbody
                            tr(v-if="status_users_log.status === 'loading'")
                                td.text-center(:colspan="tableThead.length")
                                    .flex.flex-wrap.items-center.justify-center
                                        Spinner(show="true" size="48")
                                    
                            template(v-else-if="users_log.data && users_log.data.length > 0")
                                tr(v-for="(log, index) in users_log.data" :key="index")
                                    td 
                                        .grid
                                            span {{ log.user && log.user.name?log.user.name : '-' }} 
                                            span {{ log.user? (log.user.username?log.user.username:(log.user.email?log.user.email:'-')) : (log.email?log.email:'-') }} 
                                    td 
                                        template(v-if="log.function === 'login'")
                                            small.inline-block.bg-green-200.rounded.py-1.px-2.text-green-600.uppercase.text-center.font-bold(style="min-width:85px") Login
                                        template(v-else-if="log.function === 'copy'")
                                            small.inline-block.bg-green-200.rounded.py-1.px-2.text-green-600.uppercase.text-center.font-bold(style="min-width:85px") Copy
                                        template(v-else)
                                            small.inline-block.bg-red-200.rounded.py-1.px-2.text-red-600.uppercase.text-center.font-bold(style="min-width:85px") Logout
                                    td {{ log.created_at?log.created_at:'-' }}
                                    td
                                        template(v-if="log.response.code === 200 || log.response.code === 201")
                                            small.inline-block.bg-green-200.rounded.py-1.px-2.text-green-600.uppercase.text-center.font-bold(style="min-width:85px") Success
                                        template(v-else)
                                            small.inline-block.bg-red-200.rounded.py-1.px-2.text-red-600.uppercase.text-center.font-bold(style="min-width:85px") Failed
                                    
                            tr(v-else)
                                td.text-center(:colspan="tableThead.length") No Data Available
                //- .card__footer
                .box-table-footer(v-if="status_users_log.status !== 'loading'")
                    .pagination
                        vue-ads-pagination(
                            :total-items="total_data"
                            :items-per-page="items_per_page"
                            :page="page"
                            :max-visible-pages="5"
                        )
                            template(slot="buttons" slot-scope="props")
                                vue-ads-page-button(
                                    v-for="(button, key) in props.buttons"
                                    :key="key"
                                    v-bind="button"
                                    :class="{'bg-yellow-dark': button.active}"
                                    @page-change="page = button.page"
                                    @range-change="start = button.start; end = button.end"
                                )
</template>

<script>
import moment from 'moment';
import IconSet from '@/components/IconSet.vue';
import DateRangePicker from 'vue2-daterange-picker';
import { mapState } from 'vuex';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
import Spinner from '@/components/Spinner.vue';
import Loading from '@/components/Loading.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';
import JsonCSV from 'vue-json-csv'

export default {
    name: 'PageUsersLog',
    components: {
        IconSet,
        Spinner,
        VueAdsPagination,
        VueAdsPageButton,
        DateRangePicker,
        Loading,
        NoDataAvailable,
        JsonCSV
    },
    data() {
        return {
            showFilter: false,
            ShowResponseCode: false,
            filterUser: '',
            optFilterUser: [],

            from_date: '',
            to_date: '',
            dateRange: {
                startDate: '',
                endDate: '',
            },
            default_ranges: {
                'Today': [moment().toDate(), moment().toDate()],
                'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                'Last 7 days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                'Last week': [moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(), moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate()],
            },
            total_data: 0,
            items_per_page: 10,

            size: [10, 25, 50, 100],

            page: 0,
            start: 0,
            end: 0,

            keyword: '',
            timeout: null,

            user_keyword:'',
            user_id:null,
            user_selected:false,

            xlsName: 'users_log_'+moment().format('YYYY-MM-DD_HH:mm:ss')+'.xls',
            csvName: 'users_log_'+moment().format('YYYY-MM-DD_HH:mm:ss')+'.csv',
            exportData: [], 
        };
    },
    computed: {
        ...mapState('auth', ['user']),
        // ...mapState('users', [
        //     'status_list',
        //     'list',
        // ]),
        ...mapState('usersLog', [
            'status_users_log',
            'users_log',
        ]),
        route() {
            return this.$route;
        },
        route_name() {
            return this.$route.name;
        },
        tableThead(){ 
            let thead = [
                {
                    name: 'Name',
                },
                // {
                //     name: 'Email',
                // },
                {
                    name: 'Activity',
                },{
                    name: 'Datetime',
                }
                ,{
                    name: 'Status',
                }
            ];
            // if(this.user.role.label === 'superadmin'){
            //     thead.unshift({
            //         name: 'Response',
            //     })
            // }

            return thead;
        }
    },
    watch: {
        route_name(newData, oldData) {
            if (newData === 'PageUsersLog') {
                if (oldData !== 'PageUsersLog') {
                    this.items_per_page = 10;
                    this.page = 0;
                    this.keyword = '';
                    // this.$store.commit('users/setDetail', {});
                    this.$store.commit('usersLog/setUsersLogData', {});
                }

                this.getData(true);
            }
        },
        users_log(newData) {
            if (Object.keys(newData).length > 0) {
                this.total_data = parseInt(newData.total_data);
                // this.page = (parseInt(newData.current_page) - 1);
            }
            this.getDataExport(newData.data)
        },
        total_data() {
            // this.pagination();
        },
        // items_per_page() {
            // this.getData();
            // this.pagination();
        // },
        page() {
            this.getData();
        },
        keyword() {},
        // user_keyword(newData) {
        //     if (newData.length < 3)
        //         this.$store.commit('users/setList', {});
        // },
    },
    methods: {
        getData() {
            // this.$store.commit('users/setList', {});
            // for dispatch get data
            this.$store.dispatch('usersLog/getList', [this.user_id, this.items_per_page, (this.page + 1), this.keyword, this.from_date, this.to_date]);
        },
        pageChange(page) {
            this.page = page;
        },
        sizeChange() {
            this.page = 0;
        },
        datetimeFormat(datetime) {
            return moment(datetime, 'YYYY-MM-DDTHH:mm:ssZ').format('DD MMM YYYY HH:mm:ssZ');
        },
        /* eslint-disable no-unused-vars */
        dateFormat (classes, date) {
            if (!classes.disabled) {
                classes.disabled = moment(date.getTime()).subtract({ hours: 12})._d > new Date();
            }
            return classes;
        },
        resetList() {
            this.$swal.fire({
                icon: 'warning',
                title: 'Are you sure you want to reset filter?',
                confirmButtonText: 'Yes!',
                confirmButtonColor: '#DD6B55',
                showCancelButton: true,
                allowOutsideClick: false,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.defaultFilter()
                    this.getData()
                }
            });
        },
        filterList() {
            this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');
            
            this.getData();
        },
        defaultFilter(){
            let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
            const default_date = this.$store.getters['config/getDefaultDate'](def);

            this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
            this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();
            this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');

            this.user_keyword=''
            this.user_id=null
            this.items_per_page=10
        },
        async pickerUpdate(e) {
            // console.log('========= DateRangePicker on update')
            // console.log(e)
            let ranges = moment(e.endDate.getTime()).diff(moment(e.startDate.getTime()), 'days') + 1;

            if (ranges > this.$store.getters['config/getValidateRange']) {
                let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
                const default_date = this.$store.getters['config/getDefaultDate'](def);
                
                this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
                this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();
    
                this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');
                
                this.$swal.fire({
                    icon: 'error',
                    title: 'Date range is not more than ' + this.$store.getters['config/getValidateRange'] + ' days!',
                    text: 'Your date range is ' + ranges + ' days.',
                })

                // return;
            } else {
                this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');
                this.page = 0;
            }
        },
        // searchUser() {
        //     // clear timeout variable
        //     clearTimeout(this.timeout);

        //     var self = this;
        //     this.timeout = setTimeout(function () {
        //         if (self.user_keyword.length > 2) {
        //             self.$store.commit('users/setList', {});
        //             self.$store.dispatch('users/getList', [30, 1, self.user_keyword]);
        //         }
        //     }, 650);
        // },
        // selectUser(user){
        //     this.user_keyword = user.name
        //     this.user_id = user.id
        //     this.$store.commit('users/setList', {});
        //     this.user_selected = true
        // },
        // setEmptyUser() {
        //     this.user_keyword = ''
        //     this.user_id=null
        //     this.user_selected = false
        // },
        getDataExport(data) {
            if (data.length > 0) {
                this.exportData = data.map((item) => {
                    return {
                        name            : item.user.name?item.user.name:'-',
                        activity        : item.function?item.function:'-',
                        datetime        : item.created_at?item.created_at:'-',
                        status	        : (item.response.code === 200 || item.response.code === 201)?'success':'failed',
                    };
                })
            } else {
                this.exportData = [{
                    name            : '-',
                    activity        : '-',
                    datetime        : '-',
                    status	        : '-',
                }];
            }
        },
    },
    // created() {
    //     this.defaultFilter()
    //     this.getData();
    // },
    mounted() {
        // if (this.route_name === 'pageMonitoringLog') {
            // reset date filter
        this.defaultFilter()
        this.getData();
        // }
    },
    filters: {
        date: function (date) {
            return moment(date).format('YYYY-MM-DD');
        },
    },
}
</script>

<style lang="sass" scoped>
    .box-table--monitoring
        tr
            th, td
                @apply .whitespace-no-wrap

    .box-find
        width: 100%
        max-width: 400px
        background-color: $color-secondary-500
        border-radius: 4px
        position: relative

    .container-box-users
        top: 100%
        z-index: 100
        background-color: $color-secondary-700
        width: 100%

    .box-users
        .item-user
            @apply .flex .items-center .p-2 .border-b .cursor-pointer
            &:hover 
                background-color: $color-secondary-600
            &:last-child
                border-bottom: 0px
    .box-find-selected
        background-color: $color-secondary-600
        padding-top: 2px
        @apply .absolute .inset-0 .rounded .px-2 .border
        border-color: #2d3061

    .box-more
        &:hover
            .box-popup-more
                display: block


    .box-popup-more
        @apply .absolute
        top: 100%
        right: 0px
        z-index: 999
        font-size: 14px
        min-width: 168px
        display: none
        // transform: translateY(16px)
        padding-top: 16px
        ul
            background-color: $color-secondary-700
            @apply .grid .p-6 .gap-5
            grid-template-rows: repeat(2, minmax(0, 1fr))
            grid-auto-flow: column
            li
                cursor: pointer
                a
                    &:hover
                        color: $color-main-500

</style>