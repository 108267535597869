import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate';
import SecureLS from "secure-ls";

import config from "./modules/config";
import auth from "./modules/auth";
import errors from "./modules/errors";
import dashboard from "./modules/dashboard";
import clients from "./modules/clients";
import modules from "./modules/modules";
import functions from "./modules/functions";
import roles from "./modules/roles";
import users from "./modules/users";
import acl from "./modules/acl";

import subheader from "./modules/result/subheader";
import recentLocation from "./modules/result/recentLocation";
import overall from "./modules/result/overall";
import locationMovement from "./modules/result/locationMovement";
import locationLog from "./modules/result/locationLog";
import browsingLog from "./modules/result/browsingLog";
import aerialLog from "./modules/result/aerialLog";
import profile from "./modules/result/profile";
// import deviceLog from "./modules/result/deviceLog";
import historyDeviceRoles from "./modules/result/historyDeviceRoles";
import deviceHistory from "./modules/result/deviceHistory";
import tetheredDevice from "./modules/result/tetheredDevice";
import predictionName from "./modules/result/predictionName";

import target from "./modules/target";
import watchlist from "./modules/watchlist";
import multiTracking from "./modules/multiTracking";
import caseManagement from "./modules/caseManagement";
import territoryVirtual from "./modules/territoryVirtual";
import locationEs from "./modules/locationEs";
import tacticalDevice from "./modules/tacticalDevice";
import trackingHistory from "./modules/trackingHistory";

import notification from "./modules/notification";

import license from "./modules/license";
import faceRecognition from "./modules/faceRecognition";

import springbootConfig from "./modules/springbootConfig";

import logManagement from "./modules/logManagement";

import usersLog from "./modules/usersLog";

import versions from "./modules/versions";
import imeiSv from "./modules/imeiSv";
import meIdTrack from "./modules/meIdTrack";
import querybyNIK from "./modules/querybyNIK";
import predictionMSISDN from "./modules/predictionMSISDN";
import vehicle from "./modules/vehicle";
import searchbyName from "./modules/searchbyName";
import compareTarget from "./modules/compareTarget";
import targetTags from "./modules/targetTags";
import dcd from "./modules/dcd";

import ultimo from "./modules/ultimo";

const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isIdle: false,
  },
  mutations: {},
  getters: {},
  actions: {},
  modules: {
    config,
    auth,
    errors,
    dashboard,
    clients,
    modules,
    functions,
    roles,
    users,
    acl,
    // result
    subheader,
    recentLocation,
    overall,
    locationMovement,
    locationLog,
    browsingLog,
    aerialLog,
    profile,
    // deviceLog,
    historyDeviceRoles,
    deviceHistory,
    tetheredDevice,
    predictionName,
    //
    target,
    watchlist,
    logManagement,
    multiTracking,
    caseManagement,
    territoryVirtual,
    locationEs,
    tacticalDevice,
    trackingHistory,
    notification,
    license,
    faceRecognition,
    springbootConfig,
    versions,
    usersLog,
    imeiSv,
    meIdTrack,
    querybyNIK,
    predictionMSISDN,
    vehicle,
    searchbyName,
    compareTarget,
    targetTags,
    dcd,

    ultimo,
  },
  plugins: [createPersistedState({
    storage: {
      getItem: key => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: key => ls.remove(key)
    },
    paths: [
      // 'isIdle',
      'config.isIdleState',
      'config.isIdle',
      // 'idleVue.isIdle',
      'auth',

      'recentLocation.record_size',
      'recentLocation.from_date',
      'recentLocation.to_date',

      'overall.record_size',
      // 'overall.app_size',
      'overall.from_date',
      'overall.to_date',

      'locationMovement.record_size',
      'locationMovement.from_date',
      'locationMovement.to_date',
      
      'locationLog.record_size',
      'locationLog.from_date',
      'locationLog.to_date',

      'browsingLog.record_size',
      'browsingLog.from_date',
      'browsingLog.to_date',

      'aerialLog.record_size',
      'aerialLog.from_date',
      'aerialLog.to_date',

      // 'deviceLog.record_size',
      // 'deviceLog.from_date',
      // 'deviceLog.to_date',

      'historyDeviceRoles.record_size',
      'historyDeviceRoles.from_date',
      'historyDeviceRoles.to_date',

      'deviceHistory.record_size',
      'deviceHistory.from_date',
      'deviceHistory.to_date',

      'tetheredDevice.record_size',
      'tetheredDevice.from_date',
      'tetheredDevice.to_date',

      'multiTracking.meeting_point_detail_from_notification',

      'notification.fcm_token',
      'notification.count',
    ],
  })],
});
