<template lang="pug">
.box__body
    .card.card--table.card--no-bg
        .card__header.card--table__header
            h2.text-xl List Modules
            .header-cta
                .block
                    router-link.btn-primary.m-btn-icon(
                        :to="{ name: 'pageModulesCreate', params: {} }"
                    )
                        IconSet(type="add")
                        span.m-hide.block.ml-3 Add Module
        .card--table__body
            .box-filter-table.p-4.pt-0
                .field(
                    class="mr-0 lg:mr-4"
                )
                    input.field-style(
                        v-model="keyword"
                        type="text"
                        placeholder="Search here..."
                        @keyup="searchKeyword"
                    )
                    .field-icon
                        IconSet(
                            type="search"
                        )
                .field
                    select.field-style#filter-record-size(@change="sizeChange($event)" v-model="items_per_page")
                        option(v-for="(s,index) in size" :key="index" :value="s") {{ s }}
                    .field-icon
                        IconSet(
                            type="chevron-down"
                        )
            .box-table
                table.table
                    thead
                        tr
                            th(v-for="(th,index) in tableThead" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}
                    tbody
                        tr(v-if="status_list.status === 'loading'")
                            td.text-center(:colspan="tableThead.length")
                                .flex.flex-wrap.items-center.justify-center
                                    Spinner(show="true" size="48")
                                
                        template(v-else-if="list && list.data && list.data.length > 0")
                            tr(v-for="(data,index) in list.data" :key="data.id")
                                td {{ data.name ? data.name : '-' }}
                                td {{ data.label ? data.label : '-' }}
                                td 
                                    small.whitespace-no-wrap {{ data.description ? (data.description.length > 16 ? data.description.substring(0, 16)+'...' : data.description) : '-' }}
                                td.action.action--three
                                    .flex.items-center.justify-end
                                        router-link.btn-icon.btn-icon-40(:to="'/modules/'+data.id+'/edit'" v-tooltip="'Edit'")
                                            IconSet(type="edit")
                                        button.btn-icon.btn-icon-40(
                                            @click="deleteData(data.id, data.name)"
                                            v-tooltip="'Delete'"
                                        )
                                            IconSet(type="delete")
                        tr(v-else)
                            td.text-center(:colspan="tableThead.length") No Data Available

        .card__footer(v-if="status_list.status !== 'loading'")
            .box-table-footer
                .pagination
                    vue-ads-pagination(
                        :total-items="total_data"
                        :items-per-page="items_per_page"
                        :page="page"
                        :max-visible-pages="5"
                    )
                        template(slot="buttons" slot-scope="props")
                            vue-ads-page-button(
                                v-for="(button, key) in props.buttons"
                                :key="key"
                                v-bind="button"
                                :class="{'bg-yellow-dark': button.active}"
                                @page-change="page = button.page"
                                @range-change="start = button.start; end = button.end"
                            )
</template>

<script>
import { mapState } from 'vuex';
import IconSet from '@/components/IconSet.vue';
import Spinner from '@/components/Spinner.vue';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
// import '../../../node_modules/vue-ads-pagination/dist/vue-ads-pagination.css';

export default {
    name: 'ModulesPageIndex',
    components: {
        IconSet,
        Spinner,
        VueAdsPagination,
        VueAdsPageButton,
    },
    data() {
        return {
            tableThead: [
                {
                    name: 'Name',
                    // number: true,
                },{
                    name: 'Label',
                },{
                    name: 'Description',
                },{
                    name: '',
                    action: true,
                },
            ],
            
            total_data: 0,
            items_per_page: 10,

            size: [10, 25, 50, 100],

            page: 0,
            start: 0,
            end: 0,

            keyword: '',
            timeout: null,
        };
    },
    computed: {
        ...mapState('modules', [
            'status_list',
            'list',
        ]),
        route_name() {
            return this.$route.name;
        },
    },
    watch: {
        route_name(newData, oldData) {
            if (newData === 'pageModulesIndex') {
                if (oldData !== 'pageModulesIndex') {
                    this.items_per_page = 10;
                    this.page = 0;
                    this.keyword = '';
                    this.$store.commit('modules/setList', {});
                }

                this.getData();
            }
        },
        list(newData) {
            if (Object.keys(newData).length > 0) {
                this.total_data = parseInt(newData.total_data);
                // this.page = (parseInt(newData.current_page) - 1);
            } else {
                this.total_data = 0;
            }
        },
        items_per_page() {
            this.getData();
        },
        page() {
            this.getData();
        },
    },
    methods: {
        getData() {
            // for dispatch get data
            if (this.route_name === 'pageModulesIndex')
                this.$store.dispatch('modules/getList', [this.items_per_page, (this.page + 1), this.keyword]);
        },
        deleteData(id, name) {
            this.$swal.fire({
                icon: 'warning',
                title: 'Are you sure you want to delete?',
                text: 'Module : ' + name,
                confirmButtonText: 'Yes!',
                confirmButtonColor: '#DD6B55',
                showCancelButton: true,
                allowOutsideClick: false,
            })
            .then((result) => {

                if (result.isConfirmed) {
                    EventEmit.$emit('showLoading', true);

                    this.$store.dispatch('modules/delete', [id])
                    .then((resp) => {
                        if (resp === 'success') {
                            this.page = 0;

                            this.getData();

                            this.$swal.fire({
                                icon: 'success',
                                title: 'Data successfully deleted!',
                                timer: 3000,
                            });
                        } else {
                            if (resp.data && resp.data.message) {
                                // this.error(resp.data.message);
                                EventEmit.$emit('error', resp.data.message);
                            } else {
                                EventEmit.$emit('error');
                            }
                        }
                    });
                }
            });
        },
        pageChange(page) {
            this.page = page;
        },
        rangeChange(start, end) {
            this.start = start;
            this.end = end;
        },
        sizeChange() {
            this.page = 0;
        },
        searchKeyword() {
            clearTimeout(this.timeout);            
            var self = this;
            this.timeout = setTimeout(function () {
                self.page = 0;
                self.$store.commit('modules/setList', {});
                self.getData();
            }, 650);
        },
    },
    mounted() {
        this.getData();
    },

}
</script>

<style lang="sass">
.status-active span,
.status-inactive span
    font-size: 11px
    font-weight: bold
    border-radius: 4px
    text-transform: uppercase
    display: inline-block
    padding: 4px 8px
    min-width: 75px
    text-align: center

.status-active
    span
        background-color: #c6f6d5
        color: #2f855a

.status-inactive
    span
        background-color: #efebeb
        color: #545353

</style>
