<template lang="pug">
.box__body
    .card.card--table.card--no-bg
        .card__header.card--table__header
            h2.text-xl Log Activity {{ detail.name ? '- ' + detail.name : '' }}
            //- h2.text-xl Log Activity 
                span
                    Spinner(show="true" size="48")
            .header-cta
                .block
                    router-link.btn-default.m-btn-icon(to="/users")
                        IconSet(type="chevron-left")
                        span.m-hide.block.ml-3 Back

        .card__body
            .box-filter-table.p-4.pt-0
                .field(
                    class="mr-0 lg:mr-4"
                )
                    input.field-style(
                        v-model="keyword"
                        type="text"
                        placeholder="Search here..."
                        @keyup="searchKeyword"
                    )
                    .field-icon
                        IconSet(
                            type="search"
                        )
                .field
                    select.field-style#filter-record-size(@change="sizeChange($event)" v-model="items_per_page")
                        option(v-for="(s,index) in size" :key="index" :value="s") {{ s }}
                    .field-icon
                        IconSet(
                            type="chevron-down"
                        )
            .box-table.px-4
                table.table
                    thead
                        tr
                            th(v-for="(th,index) in tableThead" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}
                    tbody
                        tr(v-if="status_log_activity.status === 'loading'")
                            td.text-center(:colspan="tableThead.length")
                                .flex.flex-wrap.items-center.justify-center
                                    Spinner(show="true" size="48")
                                
                        template(v-else-if="log_activity && log_activity.data && log_activity.data.length > 0")
                            tr(v-for="(data,index) in log_activity.data" :key="data.id")
                                //- td {{ data.key ? data.key : '-' }}
                                //- td {{ data.type ? data.type : '-' }}
                                //- td {{ data.size ? data.size : '-' }}
                                //- td {{ data.from_date ? data.from_date : '-' }}
                                //- td {{ data.to_date ? data.to_date : '-' }}
                                //- td {{ data.function ? data.function : '-' }}
                                //- td {{ data.module ? data.module : '-' }}
                                //- //- td
                                //-     | Code: 
                                //-     span(v-if="data.response.code === 200" style="color: #19d78c;")
                                //-         | {{ data.response.code }}
                                //-     span.field--error(v-else)
                                //-         | {{ data.response.code }}
                                //-     br
                                //-     | Message: {{ data.response.message }}
                                //- td {{ data.path_url ? data.path_url : '-' }}
                                //- td {{ data.created_at ? data.created_at.split('+')[0] : '-' }}
                                //- td
                                //-     template(v-if="data.response.code === 200 || data.response.code === 201")
                                //-         small.inline-block.bg-green-200.rounded.py-1.px-2.text-green-600.uppercase.text-center.font-bold(style="min-width:85px") Success
                                //-     template(v-else)
                                //-         small.inline-block.bg-red-200.rounded.py-1.px-2.text-red-600.uppercase.text-center.font-bold(style="min-width:85px") Failed
                                //- td.action.action--three
                                //-     button.btn-icon.btn-icon-40(v-tooltip="'Show Response'" @click="getResponse(true, data.response)")
                                //-         IconSet(type="code")
                                td
                                    template(v-if="data.response.code === 200 || data.response.code === 201")
                                        .label-green.text-xs Success
                                    template(v-else)
                                        .label-red.text-xs Failed
                                td 
                                    .text-xs {{ data.user?data.user.name:'-' }}
                                td 
                                    .text-xs {{ data.key?data.key:'-' }}
                                td 
                                    .text-xs {{ data.type?data.type:'-' }}
                                td 
                                    .text-xs {{ data.from_date?data.from_date:'-' }}
                                td 
                                    .text-xs {{ data.to_date?data.to_date:'-' }}
                                td 
                                    .text-xs {{ data?.wording_module?data?.wording_module:(data?.function?getModule(data?.function):'-') }}
                                td 
                                    .text-xs {{ data.created_at.split('+')[0] }}
                                td 
                                    .text-xs {{ data.browser?data.browser:'-' }}
                                td 
                                    .text-xs {{ data.os?data.os:'-' }}
                                td 
                                    .text-xs {{ data.ip?data.ip:'-' }}
                        tr(v-else)
                            td.text-center(:colspan="tableThead.length") No Data Available

        .card__footer(v-if="status_log_activity.status !== 'loading'")
            .box-table-footer
                //- span.text-xs Showing 10 from 100
                //- .pagination
                    ul
                        li
                            span 1
                        li
                            router-link(to="")  2
                        li
                            router-link(to="")  3

                //- .mt-5
                .pagination
                    vue-ads-pagination(
                        :total-items="total_data"
                        :items-per-page="items_per_page"
                        :page="page"
                        :max-visible-pages="5"
                    )
                        template(slot="buttons" slot-scope="props")
                            vue-ads-page-button(
                                v-for="(button, key) in props.buttons"
                                :key="key"
                                v-bind="button"
                                :class="{'bg-yellow-dark': button.active}"
                                @page-change="page = button.page"
                                @range-change="start = button.start; end = button.end"
                            )

    .popup-layer(
        v-bind:class="{'is-show' : openResponse}"
    )
        .popup-container
            .popup-container__header
                h2.text-xl Name's Notes
                button.btn-icon-40(
                    @click="getResponse(false)"
                )
                    IconSet( type="close")

            .popup-container__body
                .mb-2
                    strong Code: {{tmpLogCode}}
                .mb-2
                    vue-json-pretty(:data="tmpLogMessage")

</template>

<script>
import { mapState } from 'vuex';
import IconSet from '@/components/IconSet.vue';
import Spinner from '@/components/Spinner.vue';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
// import '../../../node_modules/vue-ads-pagination/dist/vue-ads-pagination.css';
import VueJsonPretty from 'vue-json-pretty';

export default {
    name: 'UsersPageLogActivity',
    components: {
        IconSet,
        Spinner,
        VueAdsPagination,
        VueAdsPageButton,
        VueJsonPretty,
    },
    data() {
        return {
            tableThead: [
                // {
                //     name: 'Key',
                //     // number: true,
                // },{
                //     name: 'Type',
                // },{
                //     name: 'Size',
                // },{
                //     name: 'From Date',
                // },{
                //     name: 'To Date',
                // },{
                //     name: 'Function',
                // },{
                //     name: 'Module',
                // // },{
                // //     name: 'Response',
                // },{
                //     name: 'Path URL',
                // },{
                //     name: 'Searched On',
                // },{
                //     name: 'Status',
                // },{
                //     name: 'Action',
                //     action: true,
                // },
                {
                    name: 'Status',
                },{
                    name: 'User',
                },{
                    name: 'Key',
                },{
                    name: 'Type',
                },
                {
                    name: 'From Date',
                },{
                    name: 'To Date',
                },
                {
                    name: 'Module',
                },
                {
                    name: 'Searched On',
                },{
                    name: 'Browser',
                },{
                    name: 'Platform',
                },{
                    name: 'IP',
                },
        
            ],
            
            total_data: 0,
            items_per_page: 10,

            size: [10, 25, 50, 100],

            page: 0,
            start: 0,
            end: 0,

            keyword: '',
            timeout: null,

            openResponse: false,
            tmpLogCode: null,
            tmpLogMessage: null,
        };
    },
    computed: {
        ...mapState('users', [
            'status_log_activity',
            'status_detail',
            'log_activity',
            'detail',
        ]),
        route_name() {
            return this.$route.name;
        },
        id() {
            return this.$route.params.id;
        },
    },
    watch: {
        route_name(newData, oldData) {
            if (newData === 'pageUsersLogActivity') {
                if (oldData !== 'pageUsersLogActivity') {
                    this.items_per_page = 10;
                    this.page = 0;
                    this.keyword = '';
                    this.$store.commit('users/setDetail', {});
                    this.$store.commit('users/setLogActivity', {});
                }

                this.getData(true);
            }
        },
        id() {},
        log_activity(newData) {
            if (Object.keys(newData).length > 0) {
                this.total_data = parseInt(newData.total_data);
                // this.page = (parseInt(newData.current_page) - 1);
            } else {
                this.total_data = 0;
            }
        },
        items_per_page() {
            this.getData();
        },
        page() {
            this.getData();
        },
    },
    methods: {
        getResponse(open=true, log=null){
            if (open) {
                this.tmpLogCode = log.code?log.code:null
                this.tmpLogMessage = null
                if (log.message) {
                    var msg = log.message
                    msg = msg.replace(/'/g, '"').replace(/None/g, '"None"').replace(/False/g, '"False"').replace(/'/g, '"').replace(/True/g, '"True"')
                    this.tmpLogMessage = JSON.parse(msg)
                }
                EventEmit.$emit('overflowHidden', true);
                this.openResponse = true
            } else {
                this.tmpLogCode = null
                this.tmpLogMessage = null
                this.openResponse = false
                EventEmit.$emit('overflowHidden', false);
            }
        },
        getData(first=false) {
            // for dispatch get data
            if (first) {
                this.$store.dispatch('users/getDetail', this.id)
                .then((resp) => {
                    if (resp === 'success') {
                        // EventEmit.$emit('showLoading', false);
                    } else {
                        // EventEmit.$emit('error');
                    }
                });
            }

            this.$store.dispatch('users/getLogActivity', [this.id, this.items_per_page, (this.page + 1), this.keyword]);

            // if (this.log_activity.total_page && this.log_activity.total_page > 1) {
                // console.log(this.log_activity.total_page)
                // this.pagination();
            // }
        },
        pageChange(page) {
            this.page = page;
        },
        rangeChange(start, end) {
            this.start = start;
            this.end = end;
        },
        sizeChange() {
            this.page = 0;
        },
        pagination() {
            // update fontawesome to iconfont
            var paginationButtonPreview = document.getElementsByClassName("fa-angle-left");

            if (paginationButtonPreview[0]) {
                var classListPrev = paginationButtonPreview[0].classList;

                if (Object.values(classListPrev).filter(key => ['iconfont'].includes(key)).length === 0) {
                    paginationButtonPreview[0].classList.add('iconfont');
                    paginationButtonPreview[0].classList.add('iconleft');
                    paginationButtonPreview[0].style.fontSize = '12px';
                }
            }

            var paginationButtonNext = document.getElementsByClassName("fa-angle-right");

            if (paginationButtonNext[0]) {
                var classListNext = paginationButtonNext[0].classList;

                if (Object.values(classListNext).filter(key => ['iconfont'].includes(key)).length === 0) {
                    paginationButtonNext[0].classList.add('iconfont');
                    paginationButtonNext[0].classList.add('iconright');
                    paginationButtonNext[0].style.fontSize = '12px';
                }
            }
        },
        searchKeyword() {
            clearTimeout(this.timeout);            
            var self = this;
            this.timeout = setTimeout(function () {
                self.page = 0;
                self.$store.commit('users/setLogActivity', {});
                self.getData();
            }, 650);
        },
        getModule(string) {
            let def = '-'
            string = string.toLowerCase()
            console.log("+++getModule|string: ", string)
            switch(string) {
                case 'kk':
                case 'ktp':
                    def = 'Digital ID';
                    break;
                case 'recent-location':
                    def = 'Recent Location';
                    break;
                case 'subheader':
                case 'subheader-subintel':
                case 'subheader-target-lbs':
                case 'subheader-target':
                    def = 'Summary Data';
                    break;
                case 'subheader-name':
                    def = 'Profile Name';
                    break;
                case 'app-usage':
                    def = 'App Usage';
                    break;
                case 'browsing-all':
                    def = 'Browsing Log';
                    break;
                case 'device-history':
                    def = 'Device History';
                    break;
                case 'aerial':
                    def = 'Aerial Log';
                    break;
                case 'location-log':
                case 'location-aerial-log':
                    def = 'Location Log';
                    break;
                case 'tethered-device':
                    def = 'Tethered Device';
                    break;
                case 'location-movement':
                    def = 'Location Movement';
                    break;
                case 'history-device-roles':
                    def = 'History Device Roles';
                    break;
                case 'prediction-name':
                    def = 'Prediction Name';
                    break;
                case 'recent-location-detail':
                    def = 'Detail Target - Recent Location';
                    break;
                case 'personal-data':
                    def = 'Digital Trace';
                    break;
                case 'fr':
                case 'fr-find':
                case 'fr-new-search':
                    def = 'Face Recognition';
                    break;
            }
            return def
        },
    },
    created() {
        this.getData(true);
    },
    mounted() {
        this.openResponse = false;
        this.tmpLogCode = null;
        this.tmpLogMessage = null;
        // 
    },

}
</script>

<style lang="sass">
.status-active span,
.status-inactive span
    font-size: 11px
    font-weight: bold
    border-radius: 4px
    text-transform: uppercase
    display: inline-block
    padding: 4px 8px
    min-width: 75px
    text-align: center

.status-active
    span
        background-color: #c6f6d5
        color: #2f855a

.status-inactive
    span
        background-color: #efebeb
        color: #545353

</style>
