<template lang="pug">
.card.card--table.card--no-bg
    .card--table__body
        //- filter
        .box-filter.px-4.pt-6
            //- showing data information mobile
            .m-only
                .filter-info
                    .flex.items-center.justify-between.mb-3
                        Strong.uppercase.text-xs.font-bold Filtered by
                        button.btn-default-o.btn-small(
                            type='button'
                            v-on:click="showFilter = !showFilter"
                            ) Change Filter
                    p {{ dateRange.startDate }} -  {{ dateRange.endDate }}, All Target, 10 entries

            //- end showing data information mobile
            .mobile-filter(
                v-bind:class="{ 'is-show' : showFilter}"
            )
                .m-only
                    .flex.items-center.justify-between.p-4.border-b
                        h2.text-xl Filter
                        button.btn-icon-40(
                            v-on:click="showFilter = !showFilter"
                        )
                            IconSet(type="close")
                .items-filter
                    .field(class="lg:mr-3 lg:mb-0 lg:w-64 mr-0 w-full mb-4")
                        .relative.field-style.w-full
                            date-range-picker(
                                v-if="dateRange.startDate && dateRange.endDate" 
                                ref="picker" 
                                v-model="dateRange" 
                                @update="pickerUpdate($event)" 
                                :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }" 
                                :date-format="dateFormat" 
                                opens="right"
                                :ranges="default_ranges"
                                autoApply=true
                            )
                                div(
                                    slot="input" 
                                    slot-scope="picker") 
                                        | {{ picker.startDate | date}} - {{ picker.endDate  | date}}

                            .field-icon
                                IconSet(type="calendar")

                    .field(class="lg:mr-3 lg:mb-0 lg:w-56 mr-0 w-full mb-4")
                        select.field-style.w-full(name="filter-target" v-model="filterTarget")
                            option(selected value='') - All Targets - 
                            option(v-for="opt in optFilterTarget" :key="opt.msisdn" :value="opt.msisdn") {{ opt.name }}    
                        .field-icon
                            IconSet(type="chevron-down")

                    .field(class="lg:mr-3 lg:mb-0 lg:w-32 mr-0 w-full mb-4")
                        select.field-style#filter-record-size(@change="sizeChange($event)" v-model="items_per_page" style="min-width:80px")
                            option(v-for="(s,index) in size" :key="index" :value="s") {{ s }}
                        .field-icon
                            IconSet(type="chevron-down")

                    //- button.btn-primary.mr-3(
                    //-     @click="filter()"
                    //-     v-on:click="showFilter = !showFilter;"
                    //- ) Filter
                    //- button.btn-default(
                    //-     @click="reset()"
                    //-     v-on:click="showFilter = !showFilter;"
                    //- ) Reset

                    .block(v-if="meeting_point_list.data && meeting_point_list.data.length > 0")
                        .flex.flex-wrap.items-center.justify-between
                            .field.mr-3.relative
                            JsonCSV(
                                :data="csvData"
                                :name="csvName"
                                class="btn btn-primary"
                            )
                                | Download CSV
        //- end filter

        .box-table
            table.table
                thead
                    tr
                        th(v-for="(th,index) in tableThead" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}
                tbody
                    tr(v-if="status_meeting_point_list.status === 'loading'")
                        td.text-center(:colspan="tableThead.length")
                            .flex.flex-wrap.items-center.justify-center
                                Spinner(show="true" size="48")
                    
                    template(v-else-if="meeting_point_list.data && meeting_point_list.data.length > 0")
                        tr(v-for="(data,index) in meeting_point_list.data" :key="data.id")
                            td 
                                .text-xs {{data.multi_tracking.group_name}}
                            td(style="width:40%" v-if="data.target && data.target.length > 0")
                                .flex.items-center.list-target
                                    .text-xs {{meetingLocation(data.target)}}
                            td(v-if="data.target && data.target.length > 0")
                                .list-target
                                    .list-target__avatar(v-for="target in data.target.slice(0, 9)")
                                        .hidden {{ target.target_name }} : {{ target.msisdn && target.msisdn.length > 0 ? target.msisdn : '-' }}
                                        figure.w-10.h-10.rounded-full.overflow-hidden(v-tooltip="target.target_name")
                                            img.w-full.h-full(:src="target.photo ? target.photo : getDefaultImage"
                                                @error="getDefaultImage")

                                    .list-target__more.w-12.h-12.overflow-hidden.rounded-full.bg-white.border-4.flex.items-center.justify-center.bg-white(v-if="data.target.length > 10")
                                        span.text-sm +{{ data.target.length - 10 }}
                            
                            td 
                                .text-xs {{data.created_at}}
                            td.text-center
                                div.inline-flex.items-center
                                    router-link.btn-small.btn-default.ml-2(:to="'/multi-tracking/'+id+'/meeting-point/'+data.id" v-tooltip="'Meeting Point'")
                                        IconSet(type="maps")
                    tr(v-else)
                        td.text-center(:colspan="tableThead.length") No Data Available

    .card__footer(v-if="status_meeting_point_list.status !== 'loading'")
        .box-table-footer
            .pagination
                vue-ads-pagination(
                    :total-items="total_data"
                    :items-per-page="items_per_page"
                    :page="page"
                    :max-visible-pages="5"
                )
                    template(slot="buttons" slot-scope="props")
                        vue-ads-page-button(
                            v-for="(button, key) in props.buttons"
                            :key="key"
                            v-bind="button"
                            :class="{'bg-yellow-dark': button.active}"
                            @page-change="page = button.page"
                            @range-change="start = button.start; end = button.end"
                        )

</template>

<script>
import { mapState } from 'vuex';
import IconSet from '@/components/IconSet.vue';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import Spinner from '@/components/Spinner.vue';
import moment from 'moment';
import JsonCSV from 'vue-json-csv'
import MultiTrackingShellVue from '../MultiTrackingShell.vue';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';

export default {
    name: 'MultiTrackingMeetingPoint',
    components: {
        IconSet,
        DateRangePicker,
        Spinner,
        JsonCSV,
        VueAdsPagination,
        VueAdsPageButton,
    },
    data() {
        return {
            showFilter: false,
            dataFilterShow: false,
            tableThead: [
                {
                    name: 'Group Name',
                },{
                    name: 'Meeting Location',
                },{
                    name: 'Target',
                },{
                    name: 'Datetime',
                },{
                    name: 'Maps',
                    action: true,
                },
            ],
            
            total_data: 0,
            items_per_page: 10,

            size: [10, 25, 50, 100],

            page: 0,
            start: 0,
            end: 0,

            keyword: '',
            timeout: null,

            optFilterTarget: [],
            filterTarget: '',

            from_date: '',
            to_date: '',

            dateRange: {
                startDate: '',
                endDate: '',
            },
            default_ranges: {
                'Today': [moment().toDate(), moment().toDate()],
                'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                'Last 7 days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                'Last week': [moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(), moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate()],
                // 'This month': [moment().startOf('month'), moment().endOf('month')],
                // 'Last month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                // 'This year': [moment().startOf('year'), moment().endOf('year')],
            },
            defaultImage: this.$store.getters['config/getDefaultImageProfile'],
            csvName: 'meeting_point_list_'+moment().format('YYYY-MM-DD_HH:mm:ss')+'.csv',
            csvData: [],
        }
    },
    computed: {
        ...mapState('multiTracking',[
            'status_meeting_point_list',
            'meeting_point_list',
            'detail',
        ]),
        route() {
            return this.$route;
        },
        route_name() {
            return this.$route.name;
        },
        id() {
            return this.$route.params.id;
        },
    },
    methods: {
        datetimeFormat(datetime) {
            return moment(datetime, 'YYYY-MM-DDTHH:mm:ssZ').format('DD MMM YYYY HH:mm:ssZ');
        },
        /* eslint-disable no-unused-vars */
        dateFormat (classes, date) {
            if (!classes.disabled) {
                classes.disabled = moment(date.getTime()).subtract({ hours: 12})._d > new Date();
            }
            return classes;
        },
        setData(data) {
            this.optFilterTarget= []
            this.filterTarget= ''
            var targets = data.target
            for (var i = 0; i < targets.length; i++) {
                var tmpTarget = {
                    'id': targets[i].id,
                    'name': targets[i].target_name,
                }
                this.optFilterTarget.push(tmpTarget)
            }
            this.getData();
        },
        getData() {
            this.$store.dispatch('multiTracking/getMeetingPointList', [this.id, this.filterTarget, this.items_per_page, (this.page + 1), this.from_date, this.to_date]);

            if (this.meeting_point_list.data && this.meeting_point_list.data.length > 0) {
                this.getDataCsv(this.meeting_point_list.data)
            }

            if (this.meeting_point_list.total_page && this.meeting_point_list.total_page > 1) {
                this.pagination();
            }
        },
        getDataCsv(data) {
            this.csvData = data.map((item) => {
                return {
                    group_name: item.multi_tracking?item.multi_tracking.group_name:'-',
                    meeting_location: item.target?this.meetingLocation(item.target):'-',
                    coordinates: item.target?this.meetingCoordinates(item.target):'-',
                    target: item.target?this.targetData(item.target):'-',
                    datetime: item.created_at?item.created_at:'-',
                };
            })
        },
        meetingLocation(target){
            var string = 'Around '
            for (var i = 0; i < target.length; i++) {
                if (target[i].location!='') {
                    string = string + target[i].location
                }
                if (i+1 != target.length && target[i].location!='') {
                    string = string + ' and '
                }
            }
            return string
        },
        meetingCoordinates(target){
            var string = 'Around '
            for (var i = 0; i < target.length; i++) {
                if (target[i].coordinates && target[i].coordinates[0].lat && target[i].coordinates[0].lng) {
                    string = string + "(lat: " + target[i].coordinates[0].lat +", lng: " + target[i].coordinates[0].lng+")"
                    break;
                }
            }
            return string
        },
        targetData(target){
            var string = ''
            for (var i = 0; i < target.length; i++) {
                if (target[i].target_name!='') {
                    string = string + target[i].target_name
                }
                if (target[i].msisdn!='') {
                    string = string + ' (' + target[i].msisdn + ')'
                }
                if (i+1 != target.length) {
                    string = string + ', '
                }
            }
            return string
        },
        pageChange(page) {
            this.page = page;
        },
        rangeChange(start, end) {
            this.start = start;
            this.end = end;
        },
        sizeChange() {
            this.page = 0;
        },
        pagination() {
            // update fontawesome to iconfont
            var paginationButtonPreview = document.getElementsByClassName("fa-angle-left");

            if (paginationButtonPreview[0]) {
                var classListPrev = paginationButtonPreview[0].classList;

                if (Object.values(classListPrev).filter(key => ['iconfont'].includes(key)).length === 0) {
                    paginationButtonPreview[0].classList.add('iconfont');
                    paginationButtonPreview[0].classList.add('iconleft');
                    paginationButtonPreview[0].style.fontSize = '12px';
                }
            }

            var paginationButtonNext = document.getElementsByClassName("fa-angle-right");

            if (paginationButtonNext[0]) {
                var classListNext = paginationButtonNext[0].classList;

                if (Object.values(classListNext).filter(key => ['iconfont'].includes(key)).length === 0) {
                    paginationButtonNext[0].classList.add('iconfont');
                    paginationButtonNext[0].classList.add('iconright');
                    paginationButtonNext[0].style.fontSize = '12px';
                }
            }
        },
        searchKeyword() {
            // clear timeout variable
            clearTimeout(this.timeout);
            
            var self = this;
            this.timeout = setTimeout(function () {
                // enter this block of code after 1 second
                // handle stuff, call search API etc.
                self.page = 0;
                self.$store.commit('multiTracking/setList', {});

                // if (self.keyword.length > 0) {
                    self.getData();
                // }
            }, 650);
        },
        
        getDefaultImage(e) {
            e.target.src = this.defaultImage;
        },
        async pickerUpdate(e) {
            let ranges = moment(e.endDate.getTime()).diff(moment(e.startDate.getTime()), 'days') + 1;

            if (ranges > this.$store.getters['config/getValidateRange']) {
                const default_date = this.$store.getters['config/getDefaultDate']();

                this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
                this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();

                this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');

                this.$swal.fire({
                    icon: 'error',
                    title: 'Date range is not more than ' + this.$store.getters['config/getValidateRange'] + ' days!',
                    text: 'Your date range is ' + ranges + ' days.',
                })

                // return;
            } else {
                this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');

                this.getData();
            }
        },
    },
    watch: {
        detail() {
            if (this.detail != undefined && this.detail.target != undefined) {
                this.setData(this.detail)
            }

        },
        meeting_point_list(newData) {
            if (Object.keys(newData).length > 0) {
                this.total_data = parseInt(newData.total_data);
                // this.page = (parseInt(newData.current_page) - 1);
            } else {
                this.total_data = 0;
            }
            if (this.meeting_point_list.data && this.meeting_point_list.data.length > 0) {
                this.getDataCsv(this.meeting_point_list.data)
            }
        },
        total_data() {
            // this.pagination();
            if (isNaN(this.total_data)) {            
                this.total_data = 0
            }
        },
        items_per_page() {
            this.getData();
            // this.pagination();
        },
        page() {
            this.getData();
        },
        keyword() {},
        filterTarget(){
            this.getData();
        },
        route(newData, oldData) {
            if (newData.name === 'pageMultiTrackingDetail') {
                if (oldData !== 'pageMultiTrackingDetail') {
                    this.optFilterTarget= []
                    this.filterTarget= ''
                    this.items_per_page = 10
                    this.page = 0
                    this.keyword = ''
                    this.$store.commit('multiTracking/setMeetingPointList', {})
                    this.$store.commit('multiTracking/setStatusMeetingPointList', {cancel: null, code: null, status: 'loading'})
                }

                if (this.detail != undefined && this.detail.target != undefined) {
                    this.setData(this.detail)
                }
            }
        },
    },
    filters: {
        date: function (date) {
            return moment(date).format('YYYY-MM-DD');
        }
    },
    mounted() {
        if (this.route.matched[1].name === 'pageMultiTrackingDetail') {
            // reset date filter
            const default_date = this.$store.getters['config/getDefaultDate']();

            this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
            this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();

            this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');
            
            if (this.detail != undefined && this.detail.target != undefined) {
                this.setData(this.detail)
            }
        }
    },
}
</script>
<style lang="sass" scoped>
    .btn-primary
        cursor: pointer !important
</style>