import router from "@/router";

const errors = {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    async handleApiResponse ({ commit }, [status_code=null, message='Something wrong, please try again.', module=null, forbiddenRedirect=false]) {
      if (status_code !== null) {
        switch(status_code) {
          case 401:
            await commit('auth/clearAuth', null, { root: true });

            if (router.currentRoute.name !== 'LoginPage')
              router.push({ name: 'LoginPage' });
            break;

          case 403:
            if (forbiddenRedirect){
              var msg = message || "You do not have permission to perform this action."
              EventEmit.$emit('warning', msg);
            }else if (router.currentRoute.name !== 'PageForbidden')
              router.push({ name: 'PageForbidden' });
            break;

          // case 404:
          //   if (router.currentRoute.name !== 'PageNotFound')
          //     router.push({ name: 'PageNotFound' });
          //   break;

          case 421:
            var msg = (module? module+': ' : '') + message
            EventEmit.$emit('warning', msg);
            break;

          case 422:
            break;

          case 429:
            EventEmit.$emit('error', 'You have reached the limit, please try again after a few minutes.');
            break;
          
          default:
            var msg = (module? module+': ' : '') + message
            console.log({message, msg})
            EventEmit.$emit('warning', msg);
            // EventEmit.$emit('error');
        } 
      } else {
        // Error Cors
      }
    },
  }
};

export default errors;
