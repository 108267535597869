<template lang="pug">
.card.card--tab
    .card--tab__header
        h2.text-xl Detail Location
    .card--tab__body
        .box-tabs
            vue-tabs
                v-tab(title="City")
                    DetailLocationCity
                v-tab(title="Province")
                    DetailLocationProvince
                v-tab(title="Region")
                    DetailLocationRegion
                v-tab(title="Village")
                    DetailLocationVillage
</template>

<script>
import { VueTabs,VTab } from 'vue-nav-tabs'
import DetailLocationCity from './DetailLocationCity.vue'
import DetailLocationProvince from './DetailLocationProvince.vue'
import DetailLocationRegion from './DetailLocationRegion.vue'
import DetailLocationVillage from './DetailLocationVillage.vue'
export default {
    name: 'LocationLogDetailLocation',
    components: {
        VueTabs,
        VTab,
        DetailLocationCity,
        DetailLocationProvince,
        DetailLocationRegion,
        DetailLocationVillage
    }
}
</script>