<template lang="pug">
.target-shell
    .box
        .box__header
            TitlePage(
                title="Monitoring Log"
                :breadcrumb="breadcrumb"
                )
        .box__body
            nav.menu
                ul
                    li
                        router-link(to="/monitoring-log") Activity
                    li
                        router-link(to="/monitoring-log/chart")  Chart
            router-view
    
</template>

<script>
import TitlePage from '@/components/shell/TitlePage.vue'
export default {
    name: 'pageMonitoringLog',
    components: {
        TitlePage,
    },
    data() {
        return {
            breadcrumb: [
                {
                    name: 'Monitoring Log',
                    url: '/monitoring-log'
                }
            ]
        }
    }
}
</script>

<style lang="sass">
    nav.menu
        ul
            @apply .flex .items-center .border-b
            li
                display: block
                a
                    @apply .block .py-4 .px-6 .relative
                    transition: all 0.3s ease-in-out
                    &::before
                        content: ''
                        height: 2px
                        width: 100%
                        position: absolute
                        bottom: -1px
                        left: 0px
                        background-color: transparent
                        transition: all 0.3s ease-in-out
                    &:hover
                        color: $color-main-500
                    &.router-link-exact-active
                        color: $color-main-500
                        &::before
                            background-color: $color-main-500
</style>