<template lang="pug">
.title-page
    h1.text-2xl.text-light {{ title }}
    .breadcrumb
        ul.flex.items-center
            li
                router-link(to="/") Dashboard
                IconSet(type="chevron-right")
            li(v-for="(list,index) in breadcrumb" :key="index")
                template(v-if="list !== null")
                    template(v-if="(index + 1) === breadcrumb.length")
                        span.block.overflow-hidden.width-12 {{ list.name}}
                    template(v-else)
                        router-link(:to="list.url") {{ list.name}}
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
    
</template>

<script>
import IconSet from '@/components/IconSet.vue'
import config from "@/config/api";

export default {
    name: 'TitlePage',
    props: {
        title : String,
        breadcrumb: Array
    },
    components: {
        IconSet
    },
}
</script>

<style lang="sass">
    .title-page
        @apply .relative .flex .items-center .justify-between .py-4

        .breadcrumb
            ul
                li
                    display: flex
                    align-items: center
                    &:first-child
                        a
                            padding-left: 0px
                    i.icon
                        svg
                            width: 12px !important
                            height: 12px !important

                    span,
                    a
                        font-size: 12px
                        padding: 0px 8px
                    a
                        opacity: 0.8
                        &:hover
                            text-decoration: underline
                    span
                        font-weight: bold
                        max-width: 300px
                        white-space: nowrap
                        text-overflow: ellipsis

    @media screen and (max-width: 480px)
        .title-page
            margin-bottom: 0px
            padding: 0px
            border-bottom-width: 1px
            @apply .flex-col-reverse .justify-start .items-start
            h1
                @apply .p-4
            .breadcrumb
                @apply .w-full .p-4
                border-top: 1px solid $color-secondary-500
                border-bottom: 1px solid $color-secondary-500
</style>