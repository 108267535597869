<template lang="pug">
.card-content
    .box-charts
        template(v-if="status_app_usage.status === 'loading'")
            template(v-if="route_name === 'TargetDetailOverall'")
                Loading(show="true")
            template(v-else)
                LoadingAnimation(show="true" title="overall-data-usage")
        template(v-else)
            template(v-if="app_usage.data_usage_app && Object.keys(app_usage.data_usage_app).length > 0 && Object.keys(app_usage.data_usage_app.series).length > 0 && chartOptions.dataAppCat !== null && chartOptions.xAxis.categories.length > 0 && chartOptions.series.length > 0")
                highcharts(:options="chartOptions")

            NoDataAvailable(v-else)
</template>

<script>
// import {Chart} from 'highcharts-vue';
import { mapState } from 'vuex';
import Loading from '@/components/Loading.vue';
import LoadingAnimation from '@/components/LoadingAnimation.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';

export default {
    name: 'OverallDataUsageApp',
    components: {
        // highcharts: Chart,
        Loading,
        NoDataAvailable,
        LoadingAnimation
    },
    data(){
        return {
            title: '',
            chartType: 'bar',
            colorInputIsSupported: null,
            color: '#6fcd98',

            chartOptions: {
                chart: {
                    type: 'bar'
                },
                title: {
                    text: null
                },
                xAxis: {
                    categories: [],
                    crosshair: true,
                    title: {
                        text: null
                    },
                    labels: {
                        step: 1
                    }
                },
                yAxis: {
                    title: {
                        text: null
                    },
                },
                tooltip: {
                    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                    pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                        '<td style="padding:0"><b>{point.y:1f}</b></td></tr>',
                    footerFormat: '</table>',
                    shared: true,
                    useHTML: true
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0
                    }
                },
                series: [{
                    // red dark theme
                    color: '#9c2c2c',
                    borderColor: '#9c2c2c',
                }],
                credits: {
                    enabled: false
                },
                legend: {
                    navigation: {
                        activeColor: '#43EA92',
                        style: {
                            fontWeight: 'bold',
                            color: '#fff'
                        }
                    }
                },
            },
        }
    },
    computed: {
        ...mapState('overall', [
            'status_app_usage',
            'app_usage',
        ]),
        route_name() {
            return this.$route.name;
        },        
    },
    watch: {
        app_usage(newData) {
            if (newData.data_usage_app && Object.keys(newData.data_usage_app).length > 0 && Object.keys(newData.data_usage_app.series).length > 0) {
                // console.log(newData)
                this.chartOptions.xAxis.categories = newData.data_usage_app.categories;
                this.chartOptions.series = newData.data_usage_app.series;
                if (this.chartOptions.series.length > 20) {
                    this.chartOptions.chart.height = '100%' // (9 / 16 * 100) + '%'  16:9 ratio,
                }
            }
        },
    },
    methods: {
        // 
    },
    mounted() {
        if (this.app_usage && this.app_usage.data_usage_app && Object.keys(this.app_usage.data_usage_app).length > 0 && Object.keys(this.app_usage.data_usage_app.series).length > 0) {
            // console.log(this.app_usage)
            this.chartOptions.xAxis.categories = this.app_usage.data_usage_app.categories;
            this.chartOptions.series = this.app_usage.data_usage_app.series;
            if (this.chartOptions.series.length > 20) {
                this.chartOptions.chart.height = '100%' // (9 / 16 * 100) + '%'  16:9 ratio,
            }
        }
    },
}
</script>
