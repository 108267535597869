<template lang="pug">
.card.card--tab
    .card--tab__header
        h2.text-xl Access Domain
    .card--tab__body
        .box-tabs
            vue-tabs
                v-tab(title="Domains")
                    AccessDomainDomains
                v-tab(title="App Categories")
                    AccessDomainAppCategories
                v-tab(title="Web Categories")
                    AccessDomainWebCategories
                v-tab(title="Referer Domain")
                    AccessDomainRefererDomains
                v-tab(title="Referer URL")
                    AccessDomainRefererUrl
</template>

<script>
import { VueTabs,VTab } from 'vue-nav-tabs'
import AccessDomainDomains from './AccessDomainDomains.vue'
import AccessDomainAppCategories from './AccessDomainAppCategories.vue'
import AccessDomainWebCategories from './AccessDomainWebCategories.vue'
import AccessDomainRefererDomains from './AccessDomainRefererDomains.vue'
import AccessDomainRefererUrl from './AccessDomainRefererUrl.vue'
export default {
    name: 'BrowsingLogAccessDomain',
    components: {
        VueTabs,
        VTab,
        AccessDomainDomains,
        AccessDomainAppCategories,
        AccessDomainWebCategories,
        AccessDomainRefererDomains,
        AccessDomainRefererUrl,
    }
}
</script>