import axios from "@/axios-main";
import axiosOrigin from "axios";

let cancelList, cancelListMap, cancelMapLocation;
var cancelToken = axiosOrigin.CancelToken;

const tacticalDevice = {
  namespaced: true,
  state: {
    status: false,
    status_list: {
      status: false,
      cancel: null,
      code: null,
    },
    status_list_map: {
      status: false,
      cancel: null,
      code: null,
    },
    status_detail: {
      status: false,
      cancel: null,
      code: null,
    },
    status_map_location: {
      status: false,
      cancel: null,
      code: null,
    },

    list: {},
    list_map: {},
    detail: {},
    map_location: {},
  },
  mutations: {
    setStatus: (state, status) => {
      state.status = status;
    },
    setStatusList: (state, status) => {
      state.status_list = status;
    },
    setStatusListMap: (state, status) => {
      state.status_list_map = status;
    },
    setStatusDetail: (state, status) => {
      state.status_detail = status;
    },
    setStatusMapLocation: (state, status) => {
      state.status_map_location = status;
    },

    setList: (state, data) => {
      state.list = data;
    },
    setListMap: (state, data) => {
      state.list_map = data;
    },
    setDetail: (state, data) => {
      state.detail = data;
    },
    setMapLocation: (state, data) => {
      state.map_location = data;
    },
  },
  getters: {},
  actions: {
    getList({ commit, dispatch, state }, [limit=null, page=null, keyword=null, order_by=null, from_date=null, to_date=null, map=false]) {

      if (map === true) {
        if(state.status_list_map.cancel != null && state.status_list_map.status == 'loading'){
            state.status_list_map.cancel('Operation canceled by the user.');
        }
      } else {
        if(state.status_list.cancel != null && state.status_list.status == 'loading'){
            state.status_list.cancel('Operation canceled by the user.');
        }
      }

      let params = new FormData();
      params.append('limit', limit);
      params.append('page', page);
      params.append('keyword', keyword);
      // params.append('order_by', order_by);
      params.append('from_date', from_date);
      params.append('to_date', to_date);

      axios.post('api/v1/tactical-device/list', params, {
        cancelToken: new cancelToken(function executor(c) {
          if (map === true) {
            cancelListMap = c;
          } else {
            cancelList = c;
          }
          commit((map === true ? 'setStatusListMap' : 'setStatusList'), {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit((map === true ? 'setListMap' : 'setList'), data);

        if (Object.keys(data).length === 0)
          result.status = 'empty';

        commit((map === true ? 'setStatusListMap' : 'setStatusList'), result);
      })
      .catch((resp) => {
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = (map === true ? cancelListMap : cancelList);
        }

        commit((map === true ? 'setStatusListMap' : 'setStatusList'), result);
        if (!axiosOrigin.isCancel(resp))
        dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
      });
    },

    async getMapLocation({ state, commit, dispatch }, id='') {
      if(state.status_map_location.cancel != null && state.status_map_location.status == 'loading')
          await state.status_map_location.cancel('Operation canceled by the user.');

      let params = new FormData();
      params.append('device_id', id);
    
      axios.post('api/v1/user-tactical-location/list', params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelMapLocation = c;
          commit('setStatusMapLocation', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        // temporary, copy response data
        // data = [
        //     {
        //         "id": "5f47acbcdc767200072159b6",
        //         "device": {
        //             "id": 1,
        //             "name": "test yusuf",
        //             "device_id": "866984039744287",
        //             "description": "ini untuk test yusuf JANGAN DIUBAH ATAU DIHAPUS"
        //         },
        //         "user": {
        //             "id": "ad90d219-80c3-4fc3-a764-9b57463a546f",
        //             "name": "yusuf tactical",
        //             "photo": "",
        //             "fcm_device_id": "fR8XY4vQTD2AH1G_qHqOle:APA91bHpElJTPOieGJwPVBbCjhWicN-o79cQ7fmti8KrRUAhw-6NNj_YEsi1DdXTmt0uqY9hhYqJkm8cRd5ihfY7D-i7ul-pqruOCEZK7X1-mcaOirVshkfF-C38LCkxeS6F93v8G6mi"
        //         },
        //         "client": {
        //             "id": 105,
        //             "name": "ASTRO",
        //             "label": "astro",
        //             "description": "Astro"
        //         },
        //         "lat": "-7.7778931",
        //         "lng": "110.40738",
        //         "created_at": "2020-08-27 12:53:16+0000",
        //         "updated_at": "2020-08-27 12:53:16+0000"
        //     },
        //     {
        //         "id": "5f47b4c3dc767200072159b8",
        //         "device": {
        //             "id": 15,
        //             "name": "redmi note 9",
        //             "device_id": "test21",
        //             "description": "ini untuk test yusuf JANGAN DIUBAH"
        //         },
        //         "user": {
        //             "id": "3e6a3b8b-975d-4016-9bf3-c621b6576d50",
        //             "name": "yusuf tactical",
        //             "photo": "",
        //             "fcm_device_id": "dcP8p05pSoaSw9uVzD4wuO:APA91bH1-YWesoKUGRDTybsfFMd-Ku7DJ9uD1ckcnNf8NksQIGRzZdtI2uTthxv2Eq5u-mO466JdUanE14JHrIQdWuMAOfy7Cq-v4ZTffPt6Zqn3XOXvWMuTgI0wOlzEEwZ9xAlFepm8"
        //         },
        //         "client": {
        //             "id": 105,
        //             "name": "ASTRO",
        //             "label": "astro",
        //             "description": "Astro"
        //         },
        //         "lat": "0",
        //         "lng": "0",
        //         "created_at": "2020-08-27 13:27:31+0000",
        //         "updated_at": "2020-08-27 13:27:31+0000"
        //     }
        // ];

        commit('setMapLocation', data);

        if (Object.keys(data).length===0)
          result.status = 'empty';

        commit('setStatusMapLocation', result);
      })
      .catch((resp) => {
        // console.log(resp)
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelMapLocation;
        }

        commit('setStatusMapLocation', result);
        if (!axiosOrigin.isCancel(resp))
        dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
      });
    },

    getDetail({ commit, dispatch }, id) {

      let params = new FormData();
      params.append('id', id);
    
      return axios.post('api/v1/tactical-device', params)
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        let code = resp.response && resp.response.status ? resp.response.status : null;

        commit('setStatusDetail', {
          status: (Object.keys(data).length===0 ? 'empty' : true),
          cancel: null,
          code: code
        });

        commit('setDetail', data);
        return 'success';
      })
      .catch((resp) => {
        // console.log(resp)
        let code = resp.response && resp.response.status ? resp.response.status : null;
        commit('setStatusDetail', {
          status: 'failed',
          cancel: null,
          code: code
        });
        dispatch('errors/handleApiResponse', [code], { root: true });
        return 'failed';
      });
    },

    create({ dispatch }, [
      name        = null,
      device_id   = null,
      users       = null,
      description = null
    ]) {

      let params = new FormData();
      params.append('name', name);
      params.append('device_id', device_id);
      params.append('users', users);
      params.append('description', description);

      return axios.post('api/v1/tactical-device/create', params)
      .then((resp) => {
        return {
          result: (resp.status === 200 && resp.data.message === 'Data Successfully Created' ? 'success' : 'failed'),
          data: resp.data
        };
      })
      .catch((resp) => {
        // console.log(resp)
        let code = resp.response && resp.response.status ? resp.response.status : null;
        dispatch('errors/handleApiResponse', [code], { root: true });
        return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
      });
    },

    update({ dispatch }, [
      id,
      name        = null,
      device_id   = null,
      users       = null,
      description = null
    ]) {

      let params = new FormData();
      params.append('id', id);
      params.append('name', name);
      params.append('device_id', device_id);
      params.append('users', users);
      params.append('description', description);
    
      return axios.post('api/v1/tactical-device/update', params)
      .then((resp) => {
        // console.log(resp.data)
        return {
          result: (resp.status === 200 && resp.data.message === 'Data Successfully Updated' ? 'success' : 'failed'),
          data: resp.data
        };
      })
      .catch((resp) => {
        // console.log(resp)
        let code = resp.response && resp.response.status ? resp.response.status : null;
        dispatch('errors/handleApiResponse', [code], { root: true });
        return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
      });
    },

    delete({ commit, dispatch }, id) {

      let params = new FormData();
      params.append('id', id);

      return axios.post('api/v1/tactical-device/delete', params)
      .then((resp) => {
        // console.log(resp)
        return (resp.status === 200 && resp.data.message === 'Data Successfully Deleted' ? 'success' : 'failed');
      })
      .catch((resp) => {
        // console.log(resp)
        let code = resp.response && resp.response.status ? resp.response.status : null;
        dispatch('errors/handleApiResponse', [code], { root: true });
        return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
      });

    },
  }
};

export default tacticalDevice;
