<template lang="pug">
.card.card--tab
    .card--tab__header
        h2.text-xl {{ title }}
    .card--tab__body
        .box-tabs
            vue-tabs
                v-tab(title="City")
                    //- p AerialCity
                    template(v-if="status_aerial_log.status === 'loading'")
                        template(v-if="['TargetDetailAerialLog', 'TargetDetailLocationMovement'].includes(route_name)")
                            Loading(show="true")
                        template(v-else)
                            LoadingAnimation(show="true" title="aerial-top")
                    template(v-else)
                        .list-area(v-if="aerial_log.top_location && aerial_log.top_location.top_city && Object.keys(aerial_log.top_location.top_city).length > 0")
                            .block(v-for="(city,index) in aerial_log.top_location.top_city" :key="index")
                                .list-item.flex.items-center.justify-between.px-4.py-2
                                    span.text-light {{ city.name }}
                                    strong.monospace {{ city.counter }}
                        NoDataAvailable(v-else)

                v-tab(title="Province")
                    //- p AerialProvice
                    template(v-if="status_aerial_log.status === 'loading'")
                        template(v-if="['TargetDetailAerialLog', 'TargetDetailLocationMovement'].includes(route_name)")
                            Loading(show="true")
                        template(v-else)
                            LoadingAnimation(show="true" title="aerial-top")
                    template(v-else)
                        .list-area(v-if="aerial_log.top_location && aerial_log.top_location.top_province && Object.keys(aerial_log.top_location.top_province).length > 0")
                            .block(v-for="(province,index) in aerial_log.top_location.top_province" :key="index")
                                .list-item.flex.items-center.justify-between.px-4.py-2
                                    span.text-light {{ province.name }}
                                    strong.monospace {{ province.counter }}
                        NoDataAvailable(v-else)

                v-tab(title="Region")
                    //- p AerialRegion
                    template(v-if="status_aerial_log.status === 'loading'")
                        template(v-if="['TargetDetailAerialLog', 'TargetDetailLocationMovement'].includes(route_name)")
                            Loading(show="true")
                        template(v-else)
                            LoadingAnimation(show="true" title="aerial-top")
                    template(v-else)
                        .list-area(v-if="aerial_log.top_location && aerial_log.top_location.top_region && Object.keys(aerial_log.top_location.top_region).length > 0")
                            .block(v-for="(region,index) in aerial_log.top_location.top_region" :key="index")
                                .list-item.flex.items-center.justify-between.px-4.py-2
                                    span.text-light {{ region.name }}
                                    strong.monospace {{ region.counter }}
                        NoDataAvailable(v-else)

                v-tab(title="Village")
                    //- p AerialVillage
                    template(v-if="status_aerial_log.status === 'loading'")
                        template(v-if="['TargetDetailAerialLog', 'TargetDetailLocationMovement'].includes(route_name)")
                            Loading(show="true")
                        template(v-else)
                            LoadingAnimation(show="true" title="aerial-top")
                    template(v-else)
                        .list-area(v-if="aerial_log.top_location && aerial_log.top_location.top_village && Object.keys(aerial_log.top_location.top_village).length > 0")
                            .block(v-for="(village,index) in aerial_log.top_location.top_village" :key="index")
                                .list-item.flex.items-center.justify-between.px-4.py-2
                                    span.text-light {{ village.name }}
                                    strong.monospace {{ village.counter }}
                        NoDataAvailable(v-else)

</template>

<script>
import { VueTabs,VTab } from 'vue-nav-tabs';
import { mapState } from 'vuex';
import Loading from '@/components/Loading.vue';
import LoadingAnimation from '@/components/LoadingAnimation.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';

export default {
    name: 'AerialLogTop',
    props: {
        title: {
            type: String,
            default: 'Highest Aerial Log'
        },
    },
    components: {
        VueTabs,
        VTab,
        Loading,
        NoDataAvailable,
        LoadingAnimation
    },
    computed: {
        ...mapState('aerialLog', [
            'status_aerial_log',
            'aerial_log',
        ]),
        route_name() {
            return this.$route.name;
        },
    },
}
</script>