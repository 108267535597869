import axios from "@/axios-main";
import axiosOrigin from "axios";
import { env } from '@/config/env';

let cancelLocationLog, cancelActivityLocation, cancelSignalStatus, cancelDetailLocation, cancelLocationHistory, cancelPossibleLocation;
var cancelToken = axiosOrigin.CancelToken;

const locationLog = {
  namespaced: true,
  state: {
    module: 'Location Log',
    default_range: env.VUE_APP_LOCATION_RANGE ? env.VUE_APP_LOCATION_RANGE : 13,
    default_export: env.VUE_APP_LOCATION_FILTER ? env.VUE_APP_LOCATION_FILTER : 'all',

    record_size: null,
    from_date: null,
    to_date: null,

    status: false,
    status_location_log: {
      status: false,
      cancel: null,
      code: null
    },
    status_activity_location: {
      status: false,
      cancel: null,
      code: null
    },
    status_signal_status: {
      status: false,
      cancel: null,
      code: null
    },
    status_detail_location: {
      status: false,
      cancel: null,
      code: null
    },
    status_location_history: {
      status: false,
      cancel: null,
      code: null
    },
    status_possible_location: {
      status: false,
      cancel: null,
      code: null
    },

    activity_location: {},
    signal_status: {},
    detail_location: {},
    location_history: {},
    possible_location: {},
  },
  mutations: {
    setRecordSize: (state, data) => {
      state.record_size = data;
    },
    setFromDate: (state, data) => {
      state.from_date = data;
    },
    setToDate: (state, data) => {
      state.to_date = data;
    },

    setStatus: (state, status) => {
      state.status = status;
    },
    setStatusLocationLog: (state, status) => {
      state.status_location_log = status;
    },
    setStatusActivityLocation: (state, status) => {
      state.status_activity_location = status;
    },
    setStatusSignalStatus: (state, status) => {
      state.status_signal_status = status;
    },
    setStatusDetailLocation: (state, status) => {
      state.status_detail_location = status;
    },
    setStatusLocationHistory: (state, status) => {
      state.status_location_history = status;
    },
    setStatusPossibleLocation: (state, status) => {
      state.status_possible_location = status;
    },

    setActivityLocation: (state, data) => {
      state.activity_location = data;
    },
    setSignalStatus: (state, data) => {
      state.signal_status = data;
    },
    setDetailLocation: (state, data) => {
      state.detail_location = data;
    },
    setLocationHistory: (state, data) => {
      state.location_history = data;
    },
    setPossibleLocation: (state, data) => {
      state.possible_location = data;
    },
  },
  getters: {},
  actions: {
    async getResultData({
      state,
      rootGetters,
      commit,
      dispatch
    }, [key, type, service_source = null]) {

      const default_date = rootGetters['config/getDefaultDate'](state.default_range);

      let record_size = state.record_size !== null ? state.record_size : rootGetters['config/getDefaultRecordSize'];
      let from_date = state.from_date !== null ? state.from_date : default_date.from_date;
      let to_date = state.to_date !== null ? state.to_date : default_date.to_date;

      await dispatch('setLoading');
      
      await dispatch('getLocationLog', [key, type, record_size, from_date, to_date, service_source]);
      // await dispatch('getActivityLocation', [key, type, record_size, from_date, to_date]);
      // await dispatch('getSignalStatus', [key, type, record_size, from_date, to_date]);
      // await dispatch('getDetailLocation', [key, type, record_size, from_date, to_date]);
      // await dispatch('getPossibleLocation', [key, type, record_size, from_date, to_date]);
      // await dispatch('getLocationHistory', [key, type, record_size, from_date, to_date]);

      commit('setStatus', true);
    },

    async setLoading({ commit, state }) {
      await commit('setStatus', 'loading');

      var loading = function(sub_mod) {
        return {
          status: 'loading',
          cancel: state[sub_mod].cancel,
          code: null
        };
      };

      await commit('setStatusLocationLog', loading('status_location_log'));
      await commit('setStatusActivityLocation', loading('status_activity_location'));
      await commit('setStatusSignalStatus', loading('status_signal_status'));
      await commit('setStatusDetailLocation', loading('status_detail_location'));
      await commit('setStatusPossibleLocation', loading('status_possible_location'));
      await commit('setStatusLocationHistory', loading('status_location_history'));
    },

    async setEmpty({
      state,
      commit
    }) {
      commit('setStatus', false);

      let default_status = {
        status: false,
        cancel: null,
        code: null
      };

      if (state.status_location_log.cancel !== null && state.status_location_log.status === 'loading')
        await state.status_location_log.cancel('Operation canceled by the user.');
      commit('setStatusLocationLog', default_status);

      if (state.status_activity_location.cancel !== null && state.status_activity_location.status === 'loading')
        await state.status_activity_location.cancel('Operation canceled by the user.');
      commit('setStatusActivityLocation', default_status);

      if (state.status_signal_status.cancel !== null && state.status_signal_status.status === 'loading')
        await state.status_signal_status.cancel('Operation canceled by the user.');
      commit('setStatusSignalStatus', default_status);

      if (state.status_detail_location.cancel !== null && state.status_detail_location.status === 'loading')
        await state.status_detail_location.cancel('Operation canceled by the user.');
      commit('setStatusDetailLocation', default_status);

      if (state.status_possible_location.cancel !== null && state.status_possible_location.status === 'loading')
        await state.status_possible_location.cancel('Operation canceled by the user.');
      commit('setStatusPossibleLocation', default_status);

      if (state.status_location_history.cancel !== null && state.status_location_history.status === 'loading')
        await state.status_location_history.cancel('Operation canceled by the user.');
      commit('setStatusLocationHistory', default_status);

      commit('setActivityLocation', {});
      commit('setSignalStatus', {});
      commit('setDetailLocation', {});
      commit('setPossibleLocation', {});
      commit('setLocationHistory', {});
    },

    async getLocationLog({
      commit,
      dispatch,
      state,
      rootGetters
    }, [key, type, record_size, from_date, to_date, service_source]) {

      let service = 'service';
      let cache = false;
      
      if (service_source === 'internal') {
        service = 'service-int'
      } else if (service_source === 'external') {
        service = 'service-ext'
        cache = true;
      }
      // var lsKey = key + '_' + service_source + '_location-aerial-log_' + record_size + '_' + from_date + '-' + to_date;
      var lsKey = key + '_' + service_source + '_location-aerial-log_' + from_date + '-' + to_date;
      
      try {
        
        let resp = rootGetters['config/getApiResp'](lsKey)
        if (resp==null || resp=='') throw "Empty"
        
        dispatch('setAllData', JSON.parse(resp));
      } catch (error) {
        console.log(error)
        if (state.status_location_log.cancel !== null && state.status_location_log.status === 'loading')
          await state.status_location_log.cancel('Operation canceled by the user.');

        let params = new FormData();
        params.append('key', key);
        params.append('type', type);
        params.append('size', record_size);
        params.append('from_date', from_date);
        params.append('to_date', to_date);

        // API utk mendapatkan location log
        // await axios.post('api/v1/'+service+'/overall/location-log', params, {

        // API utk mendapatkan location & aerial log
        await axios.post('api/v1/' + service + '/overall/location-aerial-log', params, {
          cancelToken: new cancelToken(function executor(c) {
            cancelLocationLog = c;
            commit('setStatusLocationLog', {
              status: 'loading',
              cancel: c,
              code: null,
            });
          }),
        })
        .then((resp) => {
          dispatch('setAllData', resp);
          if (cache) {            
            let dt = {
              resp: resp, 
              lsKey: lsKey
            }
            dispatch('config/addApiResp', dt, {root: true});
          }
        })
        .catch((resp) => {
          // console.log(resp)
          let result = {
            status: 'failed',
            cancel: null,
            code: resp.response && resp.response.status ? resp.response.status : null,
          };

          if (axiosOrigin.isCancel(resp)) {
            result.status = 'loading';
            result.cancel = cancelLocationLog;
          }

          commit('setStatusActivityLocation', result);
          commit('setStatusLocationLog', result);
          commit('setStatusSignalStatus', result);
          commit('setStatusDetailLocation', result);
          commit('setStatusLocationHistory', result);
          commit('setStatusPossibleLocation', result);
          if (!axiosOrigin.isCancel(resp)){
            // dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null),state.module], { root: true });
            let respMsg = resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null
            if(respMsg!=null && result.code != 500) {
              dispatch('errors/handleApiResponse', [result.code, respMsg, state.module], { root: true });
            }
          } 
        });
      }
    },

    async setAllData({
      commit
    }, resp) {
      let data_all = resp.data.location_log;
      let data = {};
      let result = {
        status: true,
        cancel: null,
        code: resp.response && resp.response.status ? resp.response.status : null,
      };

      // activity log -------------------------------------
      data = {
        data_location: {},
        heatmap: [],
        recent_location: {},
      };
      result.status = true;
      data.data_location = data_all?.data_location?.data_location || {};
      data.heatmap = data_all?.data_location?.heatmap || [];
      data.recent_location = data_all?.recent_location || {};

      commit('setActivityLocation', data);

      if (Object.keys(data.data_location).length===0 && Object.keys(data.heatmap).length===0 && (Object.keys(data.recent_location).length===0 || (data.recent_location.features[0].geometry.coordinates && (data.recent_location.features[0].geometry.coordinates[0]==="" || data.recent_location.features[0].geometry.coordinates[1]==="")))) {

        result.status = 'empty';

      } else if (Object.keys(data.data_location).length===0 || Object.keys(data.heatmap).length===0 || (data.recent_location.features[0].geometry.coordinates && (data.recent_location.features[0].geometry.coordinates[0]==="" || data.recent_location.features[0].geometry.coordinates[1]===""))) {

        result.status = 'partially-empty';
      }

      commit('setStatusActivityLocation', result);

      // signal status -------------------------------------
      data = data_all?.signal_status || {};
      result.status = true;

      commit('setSignalStatus', data);

      if (Object.keys(data).length===0) {
        result.status = 'empty';

      } else {
        if (
          (typeof data.signal_status.categories == "undefined" || data.signal_status.categories.length == 0) &&
          data.signal_status.series.length == 0 &&
          data.summary['4G'] == 0 && data.summary['3G'] == 0 && data.summary['2G'] == 0
        ) {
          
          result.status = 'empty';

        } else if (
          typeof data.signal_status.categories == "undefined" ||
          data.signal_status.categories.length == 0 ||
          data.signal_status.series.length == 0 ||
          data.summary['4G'] == 0 || data.summary['3G'] == 0 || data.summary['2G'] == 0
        ) {
          
          result.status = 'partially-empty';
        }
      }

      commit('setStatusSignalStatus', result);

      // detail location -------------------------------------
      data = data_all?.detail_location || {};
      result.status = true;

      commit('setDetailLocation', data);

      try {
        if (Object.keys(data).length===0) {
          result.status = 'empty';

        } else {

          if (
            (typeof data.detail_location_village.categories == "undefined" || data.detail_location_village.categories.length == 0) &&
            data.detail_location_village.series.length == 0 &&
            data.detail_location_village.pie.length == 0 &&

            (typeof data.detail_location_region.categories == "undefined" || data.detail_location_region.categories.length == 0) &&
            data.detail_location_region.series.length == 0 &&
            data.detail_location_region.pie.length == 0 &&

            (typeof data.detail_location_province.categories == "undefined" || data.detail_location_province.categories.length == 0) &&
            data.detail_location_province.series.length == 0 &&
            data.detail_location_province.pie.length == 0 &&

            (typeof data.detail_location_city.categories == "undefined" || data.detail_location_city.categories.length == 0) &&
            data.detail_location_city.series.length == 0 &&
            data.detail_location_city.pie.length == 0
          ) {
            
            result.status = 'empty';

          } else if (
            typeof data.detail_location_village.categories == "undefined" ||
            data.detail_location_village.categories.length == 0 ||
            data.detail_location_village.series.length == 0 ||
            data.detail_location_village.pie.length == 0 ||

            typeof data.detail_location_region.categories == "undefined" ||
            data.detail_location_region.categories.length == 0 ||
            data.detail_location_region.series.length == 0 ||
            data.detail_location_region.pie.length == 0 ||

            typeof data.detail_location_province.categories == "undefined" ||
            data.detail_location_province.categories.length == 0 ||
            data.detail_location_province.series.length == 0 ||
            data.detail_location_province.pie.length == 0 ||

            typeof data.detail_location_city.categories == "undefined" ||
            data.detail_location_city.categories.length == 0 ||
            data.detail_location_city.series.length == 0 ||
            data.detail_location_city.pie.length == 0
          ) {
            
            result.status = 'partially-empty';
          }
        }
      } catch {
        result.status = 'failed';
      }

      commit('setStatusDetailLocation', result);

      // location history -------------------------------------
      data = data_all?.history_location || {};
      result.status = true;

      commit('setLocationHistory', data);

      if (data.length===0)
        result.status = 'empty';

      commit('setStatusLocationHistory', result);

      // possible location -------------------------------------
      data = data_all?.possible_location || {};
      result.status = true;

      commit('setPossibleLocation', data);

      if (Object.keys(data).length===0) {
        result.status = 'empty';

      } else {
        if (Object.keys(data.possible_home).length===0 && Object.keys(data.possible_office).length===0 && Object.keys(data.possible_top).length===0) {
          
          result.status = 'empty';

        } else if (Object.keys(data.possible_home).length===0 || Object.keys(data.possible_office).length===0 || Object.keys(data.possible_top).length===0) {
          
          result.status = 'partially-empty';

        }
      }

      commit('setStatusPossibleLocation', result);
    },
    async getActivityLocation({ commit, dispatch, state }, [key, type, record_size, from_date, to_date]) {

      if (state.status_activity_location.cancel !== null && state.status_activity_location.status === 'loading')
        await state.status_activity_location.cancel('Operation canceled by the user.');

      let params = new FormData();
      params.append('key', key);
      params.append('type', type);
      params.append('size', record_size);
      params.append('from_date', from_date);
      params.append('to_date', to_date);

      await axios.post('api/v1/service/overall/history-location', params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelActivityLocation = c;
          commit('setStatusActivityLocation', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        // let data = resp.data;
        let data = {
          data_location: {},
          heatmap: [],
          recent_location: {},
        };
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        data.data_location = resp.data.data_location.data_location;
        data.heatmap = resp.data.data_location.heatmap;
        data.recent_location = resp.data.recent_location;

        commit('setActivityLocation', data);
        // console.log(data);
        // return;

        if (Object.keys(data.data_location).length===0 && Object.keys(data.heatmap).length===0 && (data.recent_location.features[0].geometry.coordinates && (data.recent_location.features[0].geometry.coordinates[0]==="" || data.recent_location.features[0].geometry.coordinates[1]===""))) {

          result.status = 'empty';
          
        } else if (Object.keys(data.data_location).length===0 || Object.keys(data.heatmap).length===0 || (data.recent_location.features[0].geometry.coordinates && (data.recent_location.features[0].geometry.coordinates[0]==="" || data.recent_location.features[0].geometry.coordinates[1]===""))) {

          result.status = 'partially-empty';
        }

        commit('setStatusActivityLocation', result);
      })
      .catch((resp) => {
        // console.log(resp)
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelActivityLocation;
        }

        commit('setStatusActivityLocation', result);
        if (!axiosOrigin.isCancel(resp))
        dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null), state.module], { root: true });
      });
    },

    async getSignalStatus({ commit, dispatch, state }, [key, type, record_size, from_date, to_date]) {
      
      if (state.status_signal_status.cancel !== null && state.status_signal_status.status === 'loading')
        await state.status_signal_status.cancel('Operation canceled by the user.');

      let params = new FormData();
      params.append('key', key);
      params.append('type', type);
      params.append('size', record_size);
      params.append('from_date', from_date);
      params.append('to_date', to_date);
    
      await axios.post('api/v1/service/overall/signal-status', params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelSignalStatus = c;
          commit('setStatusSignalStatus', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setSignalStatus', data);

        if (Object.keys(data).length===0) {
          result.status = 'empty';

        } else {
          if (
            (typeof data.signal_status.categories == "undefined" || data.signal_status.categories.length == 0) &&
            data.signal_status.series.length == 0 &&
            data.summary['4G'] == 0 && data.summary['3G'] == 0 && data.summary['2G'] == 0
          ) {

            result.status = 'empty';

          } else if (
            typeof data.signal_status.categories == "undefined" ||
            data.signal_status.categories.length == 0 ||
            data.signal_status.series.length == 0 ||
            data.summary['4G'] == 0 || data.summary['3G'] == 0 || data.summary['2G'] == 0
          ) {

            result.status = 'partially-empty';

          }
        }

        commit('setStatusSignalStatus', result);
      })
      .catch((resp) => {
        // console.log(resp)
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelSignalStatus;
        }

        commit('setStatusSignalStatus', result);
        if (!axiosOrigin.isCancel(resp))
        dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null), state.module], { root: true });
      });
    },

    async getDetailLocation({ commit, dispatch, state }, [key, type, record_size, from_date, to_date]) {

      if (state.status_detail_location.cancel !== null && state.status_detail_location.status === 'loading')
        await state.status_detail_location.cancel('Operation canceled by the user.');

      let params = new FormData();
      params.append('key', key);
      params.append('type', type);
      params.append('size', record_size);
      params.append('from_date', from_date);
      params.append('to_date', to_date);

      await axios.post('api/v1/service/overall/detail-location', params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelDetailLocation = c;
          commit('setStatusDetailLocation', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setDetailLocation', data);

        if (Object.keys(data).length===0) {
          result.status = 'empty';

        } else {

          if (
            (typeof data.detail_location_village.categories == "undefined" || data.detail_location_village.categories.length == 0) &&
            data.detail_location_village.series.length == 0 &&
            data.detail_location_village.pie.length == 0 &&

            (typeof data.detail_location_region.categories == "undefined" || data.detail_location_region.categories.length == 0) &&
            data.detail_location_region.series.length == 0 &&
            data.detail_location_region.pie.length == 0 &&

            (typeof data.detail_location_province.categories == "undefined" || data.detail_location_province.categories.length == 0) &&
            data.detail_location_province.series.length == 0 &&
            data.detail_location_province.pie.length == 0 &&

            (typeof data.detail_location_city.categories == "undefined" || data.detail_location_city.categories.length == 0) &&
            data.detail_location_city.series.length == 0 &&
            data.detail_location_city.pie.length == 0
          ) {
            
            result.status = 'empty';

          } else if (
            typeof data.detail_location_village.categories == "undefined" ||
            data.detail_location_village.categories.length == 0 ||
            data.detail_location_village.series.length == 0 ||
            data.detail_location_village.pie.length == 0 ||

            typeof data.detail_location_region.categories == "undefined" ||
            data.detail_location_region.categories.length == 0 ||
            data.detail_location_region.series.length == 0 ||
            data.detail_location_region.pie.length == 0 ||

            typeof data.detail_location_province.categories == "undefined" ||
            data.detail_location_province.categories.length == 0 ||
            data.detail_location_province.series.length == 0 ||
            data.detail_location_province.pie.length == 0 ||

            typeof data.detail_location_city.categories == "undefined" ||
            data.detail_location_city.categories.length == 0 ||
            data.detail_location_city.series.length == 0 ||
            data.detail_location_city.pie.length == 0
          ) {
            
            result.status = 'partially-empty';
          }
        }

        commit('setStatusDetailLocation', result);
      })
      .catch((resp) => {
        // console.log(resp)
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelDetailLocation;
        }

        commit('setStatusDetailLocation', result);
        if (!axiosOrigin.isCancel(resp))
        dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null), state.module], { root: true });
      });
    },

    async getPossibleLocation({ getters, commit, dispatch, state }, [key, type, record_size, from_date, to_date]) {

      if (state.status_possible_location.cancel !== null && state.status_possible_location.status === 'loading')
        await state.status_possible_location.cancel('Operation canceled by the user.');

      let params = new FormData();
      params.append('key', key);
      params.append('type', type);
      params.append('size', record_size);
      params.append('from_date', from_date);
      params.append('to_date', to_date);
    
      await axios.post('api/v1/service/overall/possible-location', params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelPossibleLocation = c;
          commit('setStatusPossibleLocation', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setPossibleLocation', data);

        if (Object.keys(data).length===0) {
          result.status = 'empty';

        } else {
          if (Object.keys(data.possible_home).length===0 && Object.keys(data.possible_office).length===0 && Object.keys(data.possible_top).length===0) {

            result.status = 'empty';

          } else if (Object.keys(data.possible_home).length===0 || Object.keys(data.possible_office).length===0 || Object.keys(data.possible_top).length===0) {
            
            result.status = 'partially-empty';
          }
        }

        commit('setStatusPossibleLocation', result);
      })
      .catch((resp) => {
        // console.log(resp)
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelPossibleLocation;
        }

        commit('setStatusPossibleLocation', result);
        if (!axiosOrigin.isCancel(resp))
        dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null), state.module], { root: true });
      });
    },

    async getLocationHistory({ commit, dispatch, state }, [key, type, record_size, from_date, to_date]) {

      if (state.status_location_history.cancel !== null && state.status_location_history.status === 'loading')
        await state.status_location_history.cancel('Operation canceled by the user.');

      let params = new FormData();
      params.append('key', key);
      params.append('type', type);
      params.append('size', record_size);
      params.append('from_date', from_date);
      params.append('to_date', to_date);

      await axios.post('api/v1/service/overall/location-history', params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelLocationHistory = c;
          commit('setStatusLocationHistory', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setLocationHistory', data);

        if (data.length===0)
          result.status = 'empty';

        commit('setStatusLocationHistory', result);
      })
      .catch((resp) => {
        // console.log(resp)
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelLocationHistory;
        }

        commit('setStatusLocationHistory', result);
        if (!axiosOrigin.isCancel(resp))
        dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null), state.module], { root: true });
      });
    },
  },
};

export default locationLog;
