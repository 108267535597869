<template lang="pug">
.card.card--table.card--no-bg.square
    .card--table__header
        h2.text-xl List Monitoring Active
    .card--table__body
        .box-filter.px-4.pt-6
            .field(class="lg:mr-3 lg:mb-0 lg:w-32 mr-0 w-full mb-4")
                select.field-style#filter-record-size(@change="sizeChange($event)" v-model="items_per_page" style="min-width:80px")
                    option(v-for="(s,index) in size" :key="index" :value="s") {{ s }}
                .field-icon
                    IconSet(type="chevron-down")

        .box-table
            table.table
                thead
                    tr
                        th(v-for="(th,index) in tableThead" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}
                tbody
                    tr(v-if="status_monitoring_active_list.status === 'loading'")
                        td.text-center(:colspan="tableThead.length")
                            .flex.flex-wrap.items-center.justify-center
                                Spinner(show="true" size="48")

                    template(v-else-if="monitoring_active_list.data && monitoring_active_list.data.length > 0")
                        tr(v-for="(data,index) in monitoring_active_list.data" :key="data.id")
                            td 
                                button.link.flex.items-center(@click="detailTarget(data)")
                                    .mr-2
                                        figure.w-8.h-8.rounded-full.overflow-hidden
                                            img.w-full.h-full.object-cover.rounded-full.bg-gray-100(:src="data.target.photo ? data.target.photo : getDefaultImage" @error="getDefaultImage")

                                    strong.text-left {{ data.target.name }}
                            td {{data.msisdn}}
                            td {{freqName(data.frequency)}}
                            td(:class="data.status.toLowerCase()==='started' ? 'status-active' : 'status-inactive'") 
                                span {{ data.status }}
                            td 
                                .text-xs {{data.started_at}} • {{data.started_by.name}} 
                            td 
                                .text-xs {{data.stoped_at}} • {{data.stoped_by.name}}

                    tr(v-else)
                        td.text-center(:colspan="tableThead.length") No Data Available

    .card__footer(v-if="status_monitoring_active_list.status !== 'loading'")
        .box-table-footer
            .pagination
                vue-ads-pagination(
                    :total-items="total_data"
                    :items-per-page="items_per_page"
                    :page="page"
                    :max-visible-pages="5"
                )
                    template(slot="buttons" slot-scope="props")
                        vue-ads-page-button(
                            v-for="(button, key) in props.buttons"
                            :key="key"
                            v-bind="button"
                            :class="{'bg-yellow-dark': button.active}"
                            @page-change="page = button.page"
                            @range-change="start = button.start; end = button.end"
                        )
</template>

<script>
import moment from 'moment';
import IconSet from '@/components/IconSet.vue';
// import DateRangePicker from 'vue2-daterange-picker';
import { mapState } from 'vuex';
import Spinner from '@/components/Spinner.vue';
import { encrypt } from '@/util/crypto';
// import JsonCSV from 'vue-json-csv'
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';

export default {
    name: 'MultiTrackingMonitoringActive',
    components: {
        IconSet,
        // DateRangePicker,
        // JsonCSV,
        Spinner,
        VueAdsPagination,
        VueAdsPageButton,
    },
    data() {
        return {
            showFilter: false,
            tableThead: [
                // {
                //     name: 'Group Name',
                // },
                {
                    name: 'Target',
                },{
                    name: 'MSISDN',
                },{
                    name: 'Frequency',
                },{
                    name: 'Status',
                },{
                    name: 'Start',
                },{
                    name: 'Stop',
                }
            ],
            
            total_data: 0,
            items_per_page: 10,

            size: [10, 25, 50, 100],

            page: 0,
            start: 0,
            end: 0,

            keyword: '',
            timeout: null,

            defaultImage: this.$store.getters['config/getDefaultImageProfile'],
            freqOpt: this.$store.getters['config/getFreqMonitoring'],
            // csvName: 'multi_monitoring_history_list_'+moment().format('YYYY-MM-DD_HH:mm:ss')+'.csv',
            // csvData: [],
        }
    },
    computed: {
        ...mapState('multiTracking', [
            'status_monitoring_active_list',
            'monitoring_active_list',
        ]),
        route() {
            return this.$route;
        },
        route_name() {
            return this.$route.name;
        },
        id() {
            return this.$route.params.id;
        },
    },
    methods: {
        freqName(frequency) {
            var freq = this.freqOpt.filter( val => val.value == frequency )
            if (freq.length > 0) {
                return freq[0].name
            } else {
                return '-'
            }

        },
        // datetimeFormat(datetime) {
        //     return moment(datetime, 'YYYY-MM-DDTHH:mm:ssZ').format('DD MMM YYYY HH:mm:ssZ');
        // },
        getData() {
            this.$store.dispatch('multiTracking/getMonitoringActiveList', [this.id, this.items_per_page, (this.page + 1), this.keyword, this.from_date, this.to_date]);

            if (this.monitoring_active_list.data && this.monitoring_active_list.data.length > 0) {
                // this.getDataCsv(this.monitoring_active_list.data)
            }
            if (this.monitoring_active_list.total_page && this.monitoring_active_list.total_page > 1) {
                // console.log(this.monitoring_active_list.total_page)
                this.pagination();
            }
        },
        // getDataCsv(data) {
        //     this.csvData = data.map((item) => {
        //         return {
        //             group_name: item.multi_tracking?item.multi_tracking.group_name:'-',
        //             target: item.target?item.target.name:'-',
        //             frequency: item.frequency?this.freqName(item.frequency):'-',
        //             status: item.status?item.status:'-',
        //             msisdn: item.msisdn?item.msisdn:'-',
        //             started_at: item.started_at?item.started_at:'-',
        //             started_by: item.started_by?item.started_by.name:'-',
        //             stoped_at: item.stoped_at?item.stoped_at:'-',
        //             stoped_by: item.stoped_by?item.stoped_by.name :'-',
        //         };
        //     })
        // },
        pageChange(page) {
            this.page = page;
        },
        rangeChange(start, end) {
            this.start = start;
            this.end = end;
        },
        sizeChange() {
            this.page = 0;
        },
        pagination() {
            // update fontawesome to iconfont
            var paginationButtonPreview = document.getElementsByClassName("fa-angle-left");

            if (paginationButtonPreview[0]) {
                var classListPrev = paginationButtonPreview[0].classList;

                if (Object.values(classListPrev).filter(key => ['iconfont'].includes(key)).length === 0) {
                    paginationButtonPreview[0].classList.add('iconfont');
                    paginationButtonPreview[0].classList.add('iconleft');
                    paginationButtonPreview[0].style.fontSize = '12px';
                }
            }

            var paginationButtonNext = document.getElementsByClassName("fa-angle-right");

            if (paginationButtonNext[0]) {
                var classListNext = paginationButtonNext[0].classList;

                if (Object.values(classListNext).filter(key => ['iconfont'].includes(key)).length === 0) {
                    paginationButtonNext[0].classList.add('iconfont');
                    paginationButtonNext[0].classList.add('iconright');
                    paginationButtonNext[0].style.fontSize = '12px';
                }
            }
        },
        // searchKeyword() {
        //     // clear timeout variable
        //     clearTimeout(this.timeout);
            
        //     var self = this;
        //     this.timeout = setTimeout(function () {
        //         // enter this block of code after 1 second
        //         // handle stuff, call search API etc.
        //         self.page = 0;
        //         self.$store.commit('multiTracking/setMonitoringActiveList', {});

        //         // if (self.keyword.length > 0) {
        //             self.getData();
        //         // }
        //     }, 650);
        // },
        getDefaultImage(e) {
            e.target.src = this.defaultImage;
        },
        detailTarget(data) {
            var msisdn = data.msisdn
            this.$router.push({
                name: 'pageTargetDetailHome',
                params: { 
                    id: data.target.id,
                    key: encrypt(msisdn + '&&msisdn') 
                }  
            })
        },
    },
    watch: {
        monitoring_active_list(newData) {
            if (Object.keys(newData).length > 0) {
                this.total_data = parseInt(newData.total_data);
            } else {
                this.total_data = 0;
            }
            if (this.monitoring_active_list.data && this.monitoring_active_list.data.length > 0) {
                // this.getDataCsv(this.monitoring_active_list.data)
            }
        },
        total_data() {
            if (isNaN(this.total_data)) {            
                this.total_data = 0
            }
        },
        items_per_page() {
            this.getData();
        },
        page() {
            this.getData();
        },
        keyword() {},
        route(newData, oldData) {
            if (newData === 'PageMultiTrackingDetail') {
                if (oldData !== 'PageMultiTrackingDetail') {
                    this.items_per_page = 10;
                    this.page = 0;
                    this.keyword = '';
                    this.$store.commit('multiTracking/setMonitoringActiveList', {});
                    this.$store.commit('multiTracking/setStatusMonitoringActiveList', {cancel: null, code: null, status: 'loading'});
                }

                this.getData();
            }
        },
    },
    mounted() {
        if (this.route.matched[1].name === 'pageMultiTrackingDetail') {
            // this.pagination();
            this.getData();
        }
    },
}
</script>