<template lang="pug">
.target-shell
    .box
        .box__header
            TitlePage(
                title="Prediction Name"
                :breadcrumb="breadcrumb"
                )
        .box__body
            .card.square.card--no-bg
                .card__header
                    h2.text-xl Popular Name
                    
                .card__body
                    ul.prediction-name-lists.single-column
                        li(v-for="item in PopularName")
                            p {{ item.name }}

            .card.square.card--no-bg.square-last
                .card__header
                    h2.text-xl Lists Name
                    .header-cta
                        span.mr-4.text-xs Current View
                        button.btn-primary-o.btn-small(
                            v-on:click="currentViewLists = !currentViewLists"
                        )
                            template(v-if="currentViewLists === true")
                                span Lists
                            template(v-else)
                                span Columns
                .card__body
                    ul.prediction-name-lists(
                        v-bind:class="{'single-column': currentViewLists}"
                    )
                        li(v-for="item in ListsName")
                            p {{ item.name }}
</template>

<script>
import TitlePage from '@/components/shell/TitlePage.vue'
import IconSet from '@/components/IconSet.vue';

export default {
    name: 'pageMonitoringLogIndex',
    components: {
        IconSet,
        TitlePage,
    },
    data() {
        return {
            currentViewLists: false,
            breadcrumb: [
                {
                    name: 'Prediction Name',
                    // url: 'target'
                }
            ],

            PopularName: [
                {
                    name: 'Agil Baka'
                },
                {
                    name: 'Devi Krisdiansyah'
                },
                {
                    name: 'Devi TLab'
                }
            ],
            ListsName: [
                {name: 'Almon'},
                {name: 'Anggur'},
                {name: 'Apel'},
                {name: 'Aprikot'},
                {name: 'Alpukat'},
                {name: 'Ara'},
                {name: 'Aren'},
                {name: 'Arbei'},
                {name: 'Asam'},
                {name: 'Bacang'},
                {name: 'Belimbing'},
                {name: 'Belimbing sayur'},
                {name: 'Bengkuang'},
                {name: 'Benda'},
                {name: 'Beri hitam'},
                {name: 'Beri emu'},
                {name: 'Bit'},
                {name: 'Binjai'},
                {name: 'Bisbul'},
                {name: 'Blackcurrant'},
                {name: 'Blewah'},
                {name: 'Bluberi'},
                {name: 'Cempaka'},
                {name: 'Cempedak'},
                {name: 'Ceplukan'},
                {name: 'Cermai'},
                {name: 'Ceri'},
                {name: 'Cokelat'},
                {name: 'Cranberry'},
                {name: 'Delima'},
                {name: 'Duku'},
                {name: 'Durian'},
                {name: 'Duwet'},
                {name: 'Erbis'},
            ],
        }
    },
    computed: {
        // here
    },
    watch: {
        // here
    },
    methods: {
        // here
    },
    mounted() {
        // here
    },
}
</script>

<style lang="sass" scoped>
    .prediction-name-lists
        columns: 4
        &.single-column
            columns: 1

        li 
            @apply .py-2
            p
                font-size:  18px

</style>