<template lang="pug">
.box-action.flex.items-center.justify-center.py-32.flex-col
    h1.text-2xl.text-light.mb-4 {{ title }}
    button.btn-primary(
        type='submit'
        v-if="isKey"
        @click="search()"
    )
        span.block.mr-2.font-bold Search {{ title }}
        IconSet(type="search")
    button.btn-primary.btn-primary-o--disabled(
        v-else
        disabled="disabled"
        style="cursor: not-allowed;"
    ) 
        span.block.mr-2.font-bold Search {{ title }}
        IconSet(type="search")
</template>

<script>
import IconSet from '@/components/IconSet.vue'
import { decrypt } from '@/util/crypto';
import { mapState } from 'vuex';

export default {
    name: 'ActionSearchResult',
    components: {
        IconSet,
    },
    props: {
        title : String
    },
    computed: {
        ...mapState({
            status_subheader_name: (state) => state.subheader.status_subheader_name.status,
        }),
        route() {
            return this.$route;
        },
        route_name() {
            return this.$route.name;
        },
        isKey() {
            return this.$route.params.key ? true : false;
        },
    },
    watch: {
        isKey() {},
    },
    methods: {      
        decryptData() {
            let data = null;

            // if (this.$route.params.key) {
            if (this.$route.params.key) {
                let decrypt_data = decrypt(this.$route.params.key);
                decrypt_data = decrypt_data.split('&&');
                // console.log(decrypt_data);

                var key = decrypt_data[0];
                var type = decrypt_data[1];

                data = { key: key, type: type };
            }
            return data;
        },
        search() {
            const decrypt_data = this.decryptData();
            if (decrypt_data !== null) {
                let mod = '';
                let service = '';
                
                switch(this.route.matched[0].name) {
                    case 'ResultShell':
                        service = 'default';
                        break;

                    case 'ResultRealtimeShell':
                    case 'ResultHistoricalShell':
                        service = 'external';
                        break;
                    
                    case 'pageTargetDetail':
                        service = 'internal';
                        break;

                }

                switch(this.route_name) {
                    case 'ResultRecentLocation':
                    case 'ResultRealtimeRecentLocation':
                    case 'TargetDetailRecentLocation':
                        mod = 'recentLocation';
                        break;

                    case 'ResultOverall':
                    case 'ResultHistoricalOverall':
                    case 'TargetDetailOverall':
                        mod = 'overall';
                        break;

                    case 'ResultLocationMovement':
                    case 'ResultHistoricalLocationMovement':
                    case 'TargetDetailLocationMovement':
                        mod = 'locationMovement';
                        break;

                    case 'ResultLocationLog':
                    case 'ResultHistoricalLocationLog':
                    case 'TargetDetailLocationLog':
                        mod = 'locationLog';
                        break;

                    case 'ResultBrowsingLog':
                    case 'ResultHistoricalBrowsingLog':
                    case 'TargetDetailBrowsingLog':
                        mod = 'browsingLog';
                        break;

                    case 'ResultAerialLog':
                    case 'ResultHistoricalAerialLog':
                    case 'TargetDetailAerialLog':
                        mod = 'aerialLog';
                        break;

                    case 'ResultProfile':
                    case 'ResultHistoricalProfile':
                    case 'TargetDetailProfile':
                        mod = 'profile';
                        break;

                    case 'ResultHistoryDeviceRoles':
                    case 'ResultHistoricalHistoryDeviceRoles':
                    case 'TargetDetailHistoryDeviceRoles':
                        mod = 'historyDeviceRoles';
                        break;

                    case 'ResultDeviceHistory':
                    case 'ResultHistoricalDeviceHistory':
                    case 'TargetDetailDeviceHistory':
                        mod = 'deviceHistory';
                        break;

                    case 'ResultTetheredDevice':
                    case 'ResultHistoricalTetheredDevice':
                    case 'TargetDetailTetheredDevice':
                        mod = 'tetheredDevice';
                        break;

                    case 'ResultPredictionName':
                    case 'ResultHistoricalPredictionName':
                    case 'TargetDetailPredictionName':
                        mod = 'predictionName';
                        break;
                }
                // NOTE:START
                // get data profile adter subheader done

                // Original
                // this.$store.dispatch(mod + '/getResultData', [decrypt_data.key, decrypt_data.type, service]);
                
                if (mod == 'profile') {
                    if (this.status_subheader_name !== 'loading') {
                        this.$store.dispatch(mod + '/getResultData', [decrypt_data.key, decrypt_data.type, service]);
                    } else {
                        EventEmit.$emit('profileAction', [decrypt_data.key, decrypt_data.type, service])
                        this.$store.dispatch('profile/setLoading');
                    }
                } else {
                    this.$store.dispatch(mod + '/getResultData', [decrypt_data.key, decrypt_data.type, service]);
                }
                // NOTE:END

            }
        },
    },
    created() {
        // 
    },
    mounted() {
        // this.dateChange();
    },
}
</script>