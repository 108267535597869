<template lang="pug">
.card
    .card__header
        h2.text-xl(id="recent_location") Recent Location
    .card__body
        .flex.flex-wrap(
            v-if="status_recent_location.status === 'loading'"
            class="lg:-mx-2"
        )
            template(v-if="route_name === 'TargetDetailRecentLocation'")
                Loading(show="true")
            template(v-else)
                LoadingAnimation(show="true" title="recent-location")
        .flex.flex-wrap(v-else)
            .block(class="w-full mb-4 lg:w-4/12 lg:mb-0")
                .rollout.pb-4
                    span.text-xs Operator
                    p.text-light
                        | {{ parsingPropertiesData('provider') }}
                //- .rollout.pb-4
                    span.text-xs Signal
                    p.text-light 
                        | {{ parsingPropertiesData('signal') }}
                .rollout.pb-4
                    span.text-xs BTS
                    p.text-light 
                        | {{ parsingPropertiesData('bts') }}
                //- .rollout.pb-4
                    span.text-xs IMEI
                    p.text-light 
                        | {{ parsingPropertiesData('imei') }}
                //- .rollout.pb-4
                    span.text-xs IMSI
                    p.text-light 
                        | {{ parsingPropertiesData('imsi') }}
                .rollout.pb-4
                    span.text-xs TAC
                    p.text-light 
                        | {{ parsingPropertiesData('tac') }}
                .rollout.pb-4
                    span.text-xs LAC
                    p.text-light 
                        | {{ parsingPropertiesData('lac') }}
                //- .rollout.pb-4
                    span.text-xs Manufacture &amp; Device Series
                    p.text-light 
                        | {{ parsingPropertiesData('ttype') }}
                .rollout.pb-4
                    span.text-xs ENB
                    p.text-light 
                        | {{ parsingPropertiesData('enb') }}
                .rollout.pb-4
                    span.text-xs CI
                    p.text-light 
                        | {{ parsingPropertiesData('ci') }}
                        //- | {{ parsingPropertiesData('enb') }}
                        //- template(v-if="recent_location.features")
                        //-     template(v-if="(recent_location.features[0].properties.enb && recent_location.features[0].properties.enb.length > 0) && (recent_location.features[0].properties.ci && recent_location.features[0].properties.ci.length > 0)")
                        //-         | {{ recent_location.features[0].properties.enb }} {{ recent_location.features[0].properties.ci }}

                        //-     template(v-else-if="(recent_location.features[0].properties.enb && recent_location.features[0].properties.enb.length > 0) && (!recent_location.features[0].properties.ci || !recent_location.features[0].properties.ci.length > 0)")
                        //-         | {{ recent_location.features[0].properties.enb }}

                        //-     template(v-else-if="(recent_location.features[0].properties.ci && recent_location.features[0].properties.ci.length > 0) && (!recent_location.features[0].properties.enb || !recent_location.features[0].properties.enb.length > 0)")
                        //-         | {{ recent_location.features[0].properties.ci }}

                        //-     template(v-else)
                        //-         | -
                        //- template(v-else)
                        //-     | -
                //- .rollout.pb-4
                    span.text-xs MCC
                    p.text-light 
                        | {{ parsingPropertiesData('mcc') }}
                .rollout.pb-4
                    span.text-xs State
                    p.text-light 
                        | {{ parsingPropertiesData('state') }}
                //- .rollout.pb-4
                    span.text-xs LAC
                    p.text-light 
                        | {{ parsingPropertiesData('lac') }}
                .rollout.pb-4
                    span.text-xs Country
                    p.text-light 
                        | {{ parsingPropertiesData('country') }}
                .rollout.pb-4
                    span.text-xs Date time
                    p.text-light 
                        | {{ parsingPropertiesData('dtm') }}
                .rollout.pb-4
                    span.text-xs Address
                    p.text-light
                        | {{ parsingPropertiesData('alamat') }}
                
                //- ADDING BUTTON COPY PASTE DATA BY Agil
                .pb-4(v-if="route.matched[0].name == 'ResultRealtimeShell'")
                    .flex.gap-3
                        router-link.btn-primary.m-btn-icon(           
                            :to="{ name: first_historical.name, params: { id: id, key: key } }"
                        ) Detail Historical Target
                            //- span(@click="toDetailHistoryLink($event)") Detail Historical Target
                        CopyRecentLocationTarget.btn-primary-o(label="Copy Data" :recent_location="copy_target_data" log_path="Realtime CP" icon_size="s" v-if="showCopyTarget")
                        //- button.btn-primary-o(@click="copyTargetToClipboard" v-if="showCopyTarget")
                        //-     span.pb-0.block.pr-2 Copy Data
                        //-     i: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-copy"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>

                .pb-4(v-else-if="showCopyTarget")
                    CopyRecentLocationTarget.btn-primary-o(label="Copy Data" :recent_location="copy_target_data" log_path="History Realtime CP" icon_size="s")
                    //- button.btn-primary-o
                    //-     span.pb-0.block.pr-2(@click="copyTargetToClipboard") Copy Data
                    //-     i: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-copy"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>
                
            //- .block(class="w-full lg:w-8/12")
            //- BoxMap
            //- .box-map

            template(v-if="Object.keys(recent_location).length > 0 && mapLoaded")
                .block(class="w-full lg:w-8/12")
                    template(v-if="mapVendor === 'osm'")
                        l-map(:id="idOsm" :zoom="10" :center="center" :options="options" style="height: 100%; width: 100%;")
                            l-tile-layer(:url="url" :attribution="attribution")
                            l-marker(v-for="(location, index) in locations" :lat-lng="location.position" v-bind:key="index")
                                l-popup(:content="location.name")
                            //- LeafletHeatmap(:lat-lng="latLngHeat" :max="maxHeat" :radius="radHeat" :maxZoom="10")

                    template(v-else)
                        //- .box-map
                        gmap-map(ref="mapRef" :center="center" :zoom="10" :options="options" style="width:100%; height: 100%;")
                            gmap-marker(:position="google && new google.maps.LatLng(center)" :clickable="true" :draggable="false" @click="openInfoWindowTemplate")
                            gmap-info-window(:options="{ maxWidth: 300, pixelOffset: { width: 0, height: -35 } }" :position="infoWindow.position" :opened="infoWindow.open" @closeclick="infoWindow.open=false")
                                div(v-html="infoWindow.template")
                        //- p infoWindows{{ infoWindows }}

            .block(v-else class="w-full lg:w-8/12")
                //- .box-map
                NoDataAvailable

</template>

<script>
// import BoxMap from '@/components/Map.vue'
import { env } from '@/config/env';
import { mapState } from 'vuex';
import { gmapApi } from 'vue2-google-maps';
import moment from 'moment';
import Loading from '@/components/Loading.vue';
import LoadingAnimation from '@/components/LoadingAnimation.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';

// open street map
import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { GestureHandling } from "leaflet-gesture-handling";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import LeafletHeatmap from '@/components/LeafletHeatmap.vue';
import 'leaflet.heat';
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.imagePath = '';
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});
import CopyRecentLocationTarget from '@/pages/result/recent-location/components/CopyRecentLocationTarget.vue'

export default {
    name: 'RecentLocation',
    components: {
        // BoxMap,
        Loading,
        NoDataAvailable,
        LoadingAnimation,

        // open street map
        LMap,
        GestureHandling,
        LTileLayer,
        LMarker,
        LPopup,
        LeafletHeatmap,

        CopyRecentLocationTarget,
    },
    props: ['isKey'],
    data() {
        return {
            infoWindows: [],
            mapVendor: this.$store.getters['config/getMapVendor'],
            mapLoaded: false,

            // google map
            options: {
                // zoomControl: true,
                // mapTypeControl: true,
                // scaleControl: true,
                // streetViewControl: true,
                // rotateControl: true,
                // fullscreenControl: false,
                // disableDefaultUi: false
                // scrollwheel: false,
                gestureHandling: 'cooperative',
                styles: [
                    { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
                    { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
                    { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
                    {
                        featureType: 'administrative.locality',
                        elementType: 'labels.text.fill',
                        stylers: [{ color: '#d59563' }]
                    },
                    {
                        featureType: 'poi',
                        elementType: 'labels.text.fill',
                        stylers: [{ color: '#d59563' }]
                    },
                    {
                        featureType: 'poi.park',
                        elementType: 'geometry',
                        stylers: [{ color: '#263c3f' }]
                    },
                    {
                        featureType: 'poi.park',
                        elementType: 'labels.text.fill',
                        stylers: [{ color: '#6b9a76' }]
                    },
                    {
                        featureType: 'road',
                        elementType: 'geometry',
                        stylers: [{ color: '#38414e' }]
                    },
                    {
                        featureType: 'road',
                        elementType: 'geometry.stroke',
                        stylers: [{ color: '#212a37' }]
                    },
                    {
                        featureType: 'road',
                        elementType: 'labels.text.fill',
                        stylers: [{ color: '#9ca5b3' }]
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'geometry',
                        stylers: [{ color: '#746855' }]
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'geometry.stroke',
                        stylers: [{ color: '#1f2835' }]
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'labels.text.fill',
                        stylers: [{ color: '#f3d19c' }]
                    },
                    {
                        featureType: 'transit',
                        elementType: 'geometry',
                        stylers: [{ color: '#2f3948' }]
                    },
                    {
                        featureType: 'transit.station',
                        elementType: 'labels.text.fill',
                        stylers: [{ color: '#d59563' }]
                    },
                    {
                        featureType: 'water',
                        elementType: 'geometry',
                        stylers: [{ color: '#17263c' }]
                    },
                    {
                        featureType: 'water',
                        elementType: 'labels.text.fill',
                        stylers: [{ color: '#515c6d' }]
                    },
                    {
                        featureType: 'water',
                        elementType: 'labels.text.stroke',
                        stylers: [{ color: '#17263c' }]
                    }
                ],
            },
            center: {},
            markers: [],
            places: [],
            infoWindow: {
                position: {lat: 0, lng: 0},
                open: false,
                template: ''
            },
            // myLatlng: null,

            // openstreetmap
            // url: 'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
            url: `${env.VUE_APP_OSM_CDN_URL}{z}/{x}/{y}.png`,
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            locations: [],
            idOsm: 'openstreetmap-recent_location',
            newLoc: '',
            newLt: 0,
            newLng: 0,
            icon: L.icon({ iconUrl: "null", }),
            latLngHeat: [],
            maxHeat: 1.0,
            radHeat: 30,
            is_operator: false,
        };
    },
    computed: {
        ...mapState('recentLocation', [
            'status_recent_location',
            'recent_location',
        ]),
        ...mapState('subheader', [
            'subheader_without_name',
            'subheader',
        ]),
        ...mapState('auth', ['user']),
        ...mapState('target', [
            'detail',
        ]),
        google: gmapApi,
        id() {
            return this.$route.params.id;
        },
        key() {
            return this.$route.params.key;
        },
        route() {
            return this.$route;
        },
        route_name() {
            return this.$route.name;
        },
        first_historical() {
            let route_historical = {
                name: "ResultHistoricalOverall",
                path: "overall",
                snake: "overall"
            }
            try {
                let tmp_first = this.$router.options.routes.find((item) => item.name == "ResultHistoricalShell").children[0];
                route_historical = {
                    name: tmp_first.name,
                    path: tmp_first.path,
                    snake: tmp_first.path.replace("-", "_")
                }
            } catch (error) {
                console.log('=====error route_historical', error)
            }
            return route_historical
        },
        is_realtime() {
            return this.route?.matched[0]?.name == 'ResultRealtimeShell'
        },
        showCopyTarget() {
            return this.checkFeatureByAcl('recent_location', 'copy_cp')
        },
        copy_target_data() {
            let subheader = this.subheader
            let subheader_without_name = this.subheader_without_name
            const subheader_all = this.is_realtime ? { subheader, subheader_without_name } : {}
            return {
                ...this.recent_location,
                ...subheader_all,
                detail: this.detail ?? {}
            }
        }
    },
    watch: {
        recent_location() {
            this.startMap();
        },
        google() { },
    },
    methods: {
        toDetailHistoryLink(e) {
            console.log('toDetailHistoryLink - dispatch(subheader/writeLocalStorageRealtime)')

            let sub = this.subheader
            let subNoName = this.subheader_without_name
            let fixSub = {}

            if (subNoName && Object.keys(subNoName).length > 0)
                fixSub = subNoName
            if (fixSub.length == 0 && sub && sub.length > 0)
                fixSub = sub

            console.log({ fixSub, sub, subNoName })
            this.$store.dispatch('subheader/writeLocalStorageRealtime', [fixSub]);
            // EventEmit.$emit('overflowHidden', true);
        },
        parsingPropertiesData(i) {
            let recentLocation = this.recent_location

            if (! typeof recentLocation == 'object')
                // console.log('not object')
                return '-'

            recentLocation = recentLocation['features'] || [];
            recentLocation = recentLocation[0] || {};
            recentLocation = recentLocation['properties'] || {};
            recentLocation = recentLocation[i];

            if (typeof recentLocation == 'number')
                return recentLocation;

            if (typeof recentLocation == 'string')
                return recentLocation || '-';

            return '-'
        },
        // hideAllInfoWindows() {
        //     for (var i = 0; i < this.infoWindows.length; i++) {
        //         this.infoWindows[i].close();
        //     }
        // },
        moment: function (string, string_format, format) {
            return moment(string, string_format).format(format);
        },
        // startMap() {
        //     switch(this.mapVendor) {
        //       case 'osm':
        //         this.startMapOSM();
        //         break;
        //       default:
        //         this.startMapGMAP();
        //         break;
        //     }
        // },

        // startMapGMAP() {
        getCurrentRecentLocation() {
            let location = [];

            // not used, because moved to separate function
            if (this.recent_location !== undefined && typeof this.recent_location !== 'undefined' && this.recent_location !== null && Object.keys(this.recent_location).length > 0) {
                location = this.recent_location
            } else {
                return;
            }
            const result = location.features[0];
            const lng = parseFloat(result.geometry.coordinates[0] ? result.geometry.coordinates[0] : 0);
            const lat = parseFloat(result.geometry.coordinates[1] ? result.geometry.coordinates[1] : 0);

            return {location, result, lat, lng}
        },
        contentInfoWindow(){
            const {result, lat, lng} = this.getCurrentRecentLocation()

            // for marker
            const dtm = result.properties.dtm ? result.properties.dtm : '-';
            const bts = result.properties.bts ? result.properties.bts : '-';
            const state = result.properties.state ? result.properties.state : '-';
            const contentString = '<div id="content">' +
                '<div id="bodyContent">' +
                '<table>' +
                '<tr>' +
                '<td class="pr-1">Last Updated</td>' +
                '<td class="pr-1">:</td>' +
                '<td>' + dtm + '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>BTS</td>' +
                '<td>:</td>' +
                '<td>' + bts + '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>State</td>' +
                '<td>:</td>' +
                '<td>' + state + '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>Link Location</td>' +
                '<td>:</td>' +
                '<td><a target="_blank" href="https://www.google.com/maps?q=' + lat + ',' + lng + '" style="color: #43ea92;">' + 'Google Maps</a> ' +
                '</td>' +
                '</tr>' +
                '</table>' +
                '</div>' +
                '</div>';

            return contentString;
        },
        openInfoWindowTemplate() {
            const contentString = this.contentInfoWindow();
            this.infoWindow.position = this.center
            this.infoWindow.template = contentString
            this.infoWindow.open = true
        },
        startMap() {
            var self = this;

            // gmap
            self.infoWindows = [];
            self.markers = [];

            // osm
            self.locations = [];
            self.latLngHeat = [];

            const {lat, lng} = this.getCurrentRecentLocation()

            if (Number.isNaN(lat) || Number.isNaN(lng))
                return;

            this.center = {
                lat: lat,
                lng: lng,
            };

            this.mapLoaded = true;

            const contentString = this.contentInfoWindow();

            if(this.mapVendor == 'osm'){
                let centerTmp = [self.center['lat'], self.center['lng']];
                self.locations.push({ name: contentString, position: centerTmp });
            } else {
                self.$nextTick(() => {
                    self.$refs.mapRef.$mapPromise.then((map) => {
                        const google = self.google;

                        map.center = new google.maps.LatLng(self.center);
                        map.zoom = 10;
                    });
                });
            }
        },
    },
    mounted() {
        console.log('==mapVendor', this.mapVendor)
        console.log('==mapVendor', this.mapVendor)
        if (this.isKey)
            this.startMap();

        this.is_operator = ["operator", 'superadmin'].includes(this.user?.role?.label)
    },
}
</script>

<style lang="sass">
    .box-map
        @apply .flex .items-center .justify-center .w-full
        min-height: 450px
        background-color: $color-secondary-800
</style>
