<template lang="pug">
.card
    .card__header(v-if="title")
        h2.text-xl {{ title }}
    .card__body
        .box-map
            template(v-if="status_aerial_log.status === 'loading'")
                template(v-if="['TargetDetailAerialLog', 'TargetDetailLocationMovement'].includes(route_name)")
                    Loading(show="true")
                template(v-else)
                    LoadingAnimation(show="true" title="box-maps")
            template(v-else)
                .block#mapview(class="w-full lg:w-12/12" style="height: 450px;" v-if="aerial_log.heatmap && aerial_log.heatmap.length > 0 && mapLoaded")
                    l-map(v-if="mapVendor==='osm'" ref="mapRefOSM" :id="idOsm" :zoom="10" :center="center" :options="options" style="height: 100%;width: 100%;")
                        l-tile-layer(:url="url" :attribution="attribution")
                        l-marker(v-if="showMarkerOsm && locations.length > 0" v-for="(location, index) in locations" :lat-lng="location.position" :key="index" :ref="'marker_'+location.index")
                            l-popup(:content="location.name")
                        template(v-if="showMarkerOsm && Object.keys(locations_cluster).length > 0")
                            l-marker-cluster(v-for="(location, indexl) in locations_cluster" :options="cluster_opt" :key="indexl" :ref="'marker_cluster_'+indexl")
                                l-marker(v-for="(loc, index) in location" :lat-lng="loc.position" :key="index" :ref="'marker_'+loc.index")
                                    l-popup(:content="loc.name")
                            //- l-marker-cluster(v-for="(location, indexl) in locations_cluster" :options="cluster_opt" :key="indexl" :ref="'marker_cluster_'+indexl")
                                l-marker(v-for="(loc, index) in location" :lat-lng="loc.position" :key="index" :ref="'marker_'+loc.index")
                                    l-popup(:content="loc.name")
                        l-polyline(v-if="showPolylineOsm" :lat-lngs="polylineOsm.latlngs" :color="polylineOsm.color")
                        LeafletHeatmap(v-if="latLngHeat.length > 0 && showHeatmapOsm" :lat-lng="latLngHeat" :max="maxHeat" :radius="radHeat" :maxZoom="10")
                        l-control(position="topleft")
                            .found-result.is-show
                                //- span LControl
                                .mb-4
                                    .ui-checkbox
                                        input(id="toggle_marker" name="marker" type="checkbox" @change="toggleMarker($event)" checked)
                                        .style-checkbox.flex.items-center
                                            .checkbox.flex.items-center.justify-center
                                                IconSet(type="check")
                                            span Marker
                                .mb-4
                                    .ui-checkbox
                                        input(id="toggle_lines" name="lines" type="checkbox" @change="toggleLines($event)" checked)
                                        .style-checkbox.flex.items-center
                                            .checkbox.flex.items-center.justify-center
                                                IconSet(type="check")
                                            span Lines
                                .mb-4
                                    .ui-checkbox
                                        input(id="toggle_heatmap" name="heatmap" type="checkbox" @change="toggleHeatmap($event)" checked)
                                        .style-checkbox.flex.items-center
                                            .checkbox.flex.items-center.justify-center
                                                IconSet(type="check")
                                            span Heatmap
                                .found-result__footer(id="map__footer")
                                    span.block.mb-4 Heatmap Radius (meter)
                                    .list-size.field
                                        select.map-filter.field-style.h-10(id="radius-size" name="radius-size" @change="radiusHeatmap($event)")
                                            option(value="10") 10
                                            option(value="20") 20
                                            option(value="30" selected) 30
                                            option(value="40") 40
                        l-control-fullscreen(position="topright")
                    gmap-map#map(v-else ref="mapRef" :center="center" :zoom="10" :options="options" style="width:100%; height: 100%;")

                NoDataAvailable(v-else)
</template>

<script>
import { env } from '@/config/env';
import { mapState } from 'vuex';
import { gmapApi } from 'vue2-google-maps';
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import Loading from '@/components/Loading.vue';
import LoadingAnimation from '@/components/LoadingAnimation.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';
import IconSet from '@/components/IconSet.vue'
import {LMap, LTileLayer, LMarker, LPopup, LPolyline, LControl} from 'vue2-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
// import { GestureHandling } from "leaflet-gesture-handling";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import LeafletHeatmap from '@/components/LeafletHeatmap.vue';
import LControlFullscreen from 'vue2-leaflet-fullscreen';
// import 'leaflet.heat';
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.imagePath = '';
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'

export default {
    name: 'ResultAerialLogMaps',
    props: {
        title: {
            type: String,
            default: ''
        },
    },
    components: {
        IconSet,
        Loading,
        NoDataAvailable,
        LoadingAnimation,
        // open street map
        LMap,
        // GestureHandling,
        LTileLayer,
        LMarker,
        LPopup,
        LeafletHeatmap,
        LPolyline,
        'l-marker-cluster': Vue2LeafletMarkerCluster,
        LControl,
        LControlFullscreen,
    },
    data(){
        return {
            mapVendor: this.$store.getters['config/getMapVendor'],
            mapLoaded: false,
            
            options: {
                fullscreenControl : this.$store.getters['config/getMapVendor'] == 'gmap',
                gestureHandling: 'cooperative',
                styles: [
                    {elementType: 'geometry', stylers: [{color: '#242f3e'}]},
                    {elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}]},
                    {elementType: 'labels.text.fill', stylers: [{color: '#746855'}]},
                    {
						featureType: 'administrative.locality',
						elementType: 'labels.text.fill',
						stylers: [{color: '#d59563'}]
                    },
                    {
						featureType: 'poi',
						elementType: 'labels.text.fill',
						stylers: [{color: '#d59563'}]
                    },
                    {
						featureType: 'poi.park',
						elementType: 'geometry',
						stylers: [{color: '#263c3f'}]
                    },
                    {
						featureType: 'poi.park',
						elementType: 'labels.text.fill',
						stylers: [{color: '#6b9a76'}]
                    },
                    {
						featureType: 'road',
						elementType: 'geometry',
						stylers: [{color: '#38414e'}]
                    },
                    {
						featureType: 'road',
						elementType: 'geometry.stroke',
						stylers: [{color: '#212a37'}]
                    },
                    {
						featureType: 'road',
						elementType: 'labels.text.fill',
						stylers: [{color: '#9ca5b3'}]
                    },
                    {
						featureType: 'road.highway',
						elementType: 'geometry',
						stylers: [{color: '#746855'}]
                    },
                    {
						featureType: 'road.highway',
						elementType: 'geometry.stroke',
						stylers: [{color: '#1f2835'}]
                    },
                    {
						featureType: 'road.highway',
						elementType: 'labels.text.fill',
						stylers: [{color: '#f3d19c'}]
                    },
                    {
						featureType: 'transit',
						elementType: 'geometry',
						stylers: [{color: '#2f3948'}]
                    },
                    {
						featureType: 'transit.station',
						elementType: 'labels.text.fill',
						stylers: [{color: '#d59563'}]
                    },
                    {
						featureType: 'water',
						elementType: 'geometry',
						stylers: [{color: '#17263c'}]
                    },
                    {
						featureType: 'water',
						elementType: 'labels.text.fill',
						stylers: [{color: '#515c6d'}]
                    },
                    {
						featureType: 'water',
						elementType: 'labels.text.stroke',
						stylers: [{color: '#17263c'}]
                    }
                ],
            },
            infoWindows: [],
            center: {},
            markers: [],
            // places: [],
            heatmap: null,
            mapsLatLng: [],
            polyline: null,

            // openstreetmap
            // url: 'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
            url: `${env.VUE_APP_OSM_CDN_URL}{z}/{x}/{y}.png`,
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            locations: [],
            locations_cluster: [],
            cluster_opt: {
                iconCreateFunction: this._defaultIconCreateFunction
                // spiderfyOnMaxZoom: true,
                // showCoverageOnHover: true,
                // zoomToBoundsOnClick: true
            },
            idOsm: 'aerial_log-result',
            polylineOsm: {
                latlngs:[],
                color:'red'
            },
            newLoc: '',
            newLt : 0, 
            newLng : 0,
            icon: L.icon({iconUrl: "null",}),
            latLngHeat: [],
            maxHeat: 1.0,
            radHeat: 30,

            showMarkerOsm: true,
            showPolylineOsm: true,
            showHeatmapOsm: true,

            currentClickMarker: null,
            spideredMarker: [],

            gInfoWIndow: null,
        };
    },
    computed: {
        ...mapState('aerialLog', [
            'status',
            'from_date',
            'to_date',
            'status_aerial_log',
            'aerial_log',
        ]),
        google: gmapApi,
        key() {
            return this.$route.params.key;
        },
        isKey() {
            return this.key ? true : false;
        },
        route() {
            return this.$route;
        },
        route_name() {
            return this.$route.name;
        },
    },
    watch: {
        aerial_log() {
            this.startMap();
        },
        google() {},
        key() {},
        isKey() {},
        // from_date(newData, oldData) {
        //     if (newData !== oldData)
        //         this.getFilter();
        // },
        // to_date(newData, oldData) {
        //     if (newData !== oldData)
        //         this.getFilter();
        // },
        currentClickMarker(newData){
            // console.log({newData})
            if (!newData){
                console.log('watch currentClickMarker null value')
                return
            }
            const google = this.google; 
            const map = this.$refs.mapRef
            const infoWindow = this.gInfoWIndow; 
            google.maps.event.trigger(newData, 'click');

            infoWindow.close()
            infoWindow.setContent(newData.desc);
            infoWindow.open(map, newData);
        },
    },
    methods: {
        _defaultIconCreateFunction: function (cluster) {
            return new L.Icon({ 
                iconAnchor: [13, 40],
                iconUrl: require('leaflet/dist/images/marker-icon.png'),
                shadowUrl: require('leaflet/dist/images/marker-shadow.png')
            });
        },
        // ------
        hideAllInfoWindows() {
            for (let i=0; i < this.infoWindows.length; i++) {
                this.infoWindows[i].close();
            }
        },
        toggleMarker(e) {
            let self = this;
            switch(self.mapVendor) {
                case 'osm':
                    if (e.target.checked) {
                        this.showMarkerOsm = true;
                    } else {
                        this.showMarkerOsm = false;
                    }
                    break;

                default:
                    self.$nextTick(() => {
                        self.$refs.mapRef.$mapPromise.then((map) => {
                            for (let i = 0; i < self.markers.length; i++) {
                                if (e.target.checked) {
                                    self.markers[i].setVisible(true);
                                } else {
                                    self.markers[i].setVisible(false);
                                }
                            }
                        });
                    });
            }
        },
        toggleLines(e=null) {
            let self = this;
            switch(self.mapVendor) {
                case 'osm':
                    if (e.target.checked) {
                        self.showPolylineOsm = true;
                    } else {
                        self.showPolylineOsm = false;
                    }
                    break;

                default:
                    self.$nextTick(() => {
                        self.$refs.mapRef.$mapPromise.then((map) => {
                            self.polyline.setMap(self.polyline.getMap() ? null : map);
                        });
                    });
            }
        },
        toggleHeatmap(e=null) {
            let self = this;
            switch(self.mapVendor) {
                case 'osm':
                    if (e.target.checked) {
                        self.showHeatmapOsm = true;
                    } else {
                        self.showHeatmapOsm = false;
                    }
                    break;

                default:
                    self.$nextTick(() => {
                        self.$refs.mapRef.$mapPromise.then((map) => {
                            self.heatmap.setMap(self.heatmap.getMap() ? null : map);
                        });
                    });
            }
        },
        radiusHeatmap(e) {
            let self = this;
            switch(self.mapVendor) {
                case 'osm':
                    self.radHeat = parseInt(e.target.value);
                    break;

                default:
                    self.$nextTick(() => {
                        self.$refs.mapRef.$mapPromise.then((map) => {
                            self.heatmap.set('radius', parseInt(e.target.value));
                        });
                    });
            }
        },

        createInfoWindow(lat_lng='', datetime='-') {
            let contentString = '<div id="content">'+
                    '<div id="bodyContent">'+
                        '<p>Link Location: <a target="_blank" href="https://www.google.com/maps?q='+ lat_lng +'" style="color: #43ea92;">'+
                            'Google Maps</a> '+
                        '</p>'+
                        '<p>Date: '+datetime+'</p>'+
                '</div>'+
            '</div>';

            return contentString;
        },
        async startMap() {
            let self = this;

            // gmap
            self.infoWindows = [];
            self.markers = [];
            self.heatmap = null;
            self.mapsLatLng = [];
            self.polyline = null;

            // osm
            let locations = [];
            let locations_cluster = [];
            let check_coordinates = [];
            let check_coordinates_cluster = [];
            let check_coordinates_duplicate = [];

            self.polylineOsm = {
                latlngs: [],
                color:'red'
            };
            self.latLngHeat = [];

            if (this.aerial_log.heatmap && this.aerial_log.heatmap.length > 0 && this.isKey) {

                let heatmap_data = this.aerial_log.heatmap;
            
                let lat = parseFloat(heatmap_data[0][0]);
                let lng = parseFloat(heatmap_data[0][1]);

                this.center = {
                    lat: lat,
                    lng: lng,
                };
                this.mapLoaded = true;

                switch(self.mapVendor) {
                    case 'osm':
                        self.$nextTick(() => {
                            const map = self.$refs.mapRefOSM.mapObject
                            let bounds = [];

                            let heatmapData = {};
                            let maxValue = 0;
                            let heatmapDataFix = [];

                            Object.keys(heatmap_data).forEach(function(k){
                                let latTmp = parseFloat(heatmap_data[k][0]);
                                let lngTmp = parseFloat(heatmap_data[k][1]);
                                let centerTmp = [latTmp, lngTmp];

                                let dtm = heatmap_data[k][2]
                                try {
                                    if(heatmap_data[k][3].length>1) {
                                        dtm = heatmap_data[k][3][0] + " until " + heatmap_data[k][3].slice(-1)
                                    }
                                } catch (error) {
                                    console.log(error)
                                }
                                let contentString = self.createInfoWindow(centerTmp, dtm);
                                // cluster
                                if (check_coordinates.includes(centerTmp.toString())) {
                                    if (check_coordinates_duplicate.includes(centerTmp.toString()) === false) {
                                        check_coordinates_duplicate.push(centerTmp.toString());
                                    }
                                    check_coordinates_cluster.push(centerTmp.toString());

                                    locations_cluster.push({
                                        index: k+'_cluster',
                                        name:contentString,
                                        position:centerTmp, 
                                    });
                                } else {
                                    check_coordinates.push(centerTmp.toString());

                                    locations.push({
                                        index: k,
                                        name:contentString,
                                        position:centerTmp, 
                                    });
                                    // self.locations.push({name:contentString,position:centerTmp, iconColor:labelColor});
                                }
                                

                                self.polylineOsm.latlngs.push(centerTmp)
                                // self.polyline.color = labelColor

                                if (heatmapData[latTmp+','+lngTmp]){
                                    heatmapData[latTmp+','+lngTmp][2] += 1;
                                } else {
                                    heatmapData[latTmp+','+lngTmp] = [latTmp, lngTmp, 1];
                                }

                                if (maxValue < heatmapData[latTmp+','+lngTmp][2]){
                                    maxValue = heatmapData[latTmp+','+lngTmp][2]
                                }

                                bounds.push(centerTmp);
                            });

                            // cluster
                            if (check_coordinates.length > 0 || check_coordinates_cluster.length > 0) {
                                const delete_location_index = [];

                                for (let i = 0; i < check_coordinates.length; i++) {
                                    for (let j = 0; j < check_coordinates_duplicate.length; j++) {
                                        if (check_coordinates[i] === check_coordinates_duplicate[j]) {
                                            locations[i].index = locations[i].index+'_cluster'
                                            locations_cluster.push(locations[i]);
                                            delete_location_index.push(i);
                                        }
                                    }
                                }

                                if (delete_location_index.length > 0) {
                                    for (let j = delete_location_index.length - 1; j >= 0; j--) {
                                        locations.splice(delete_location_index[j], 1);
                                    }
                                }
                                self.locations = locations;

                                locations_cluster = locations_cluster.reduce((r, a) => {
                                    r[a.position] = [...r[a.position] || [], a];
                                    return r;
                                }, {});

                                self.locations_cluster = locations_cluster;
                            }

                            if (maxValue > 0){
                                Object.values(heatmapData).forEach(function(arr) {
                                    arr[2] = arr[2]/maxValue;
                                    heatmapDataFix.push(arr);
                                });
                                // console.log({heatmapDataFix})
                                self.latLngHeat = heatmapDataFix;
                            }

                            if (bounds.length)
                                map.fitBounds(L.latLngBounds([bounds]));
                        });
                        break;

                    default:
                        self.$nextTick(() => {
                            self.$refs.mapRef.$mapPromise.then((map) => {
                                const google = self.google;
                                self.gInfoWIndow = new google.maps.InfoWindow();
                                // ===== start ======
                                let data = heatmap_data
                                const infoWindow = self.gInfoWIndow;
                                const OverlappingMarkerSpiderfier = require('overlapping-marker-spiderfier')

                                const oms = new OverlappingMarkerSpiderfier(map, {
                                    markersWontMove: true,
                                    markersWontHide: true,
                                    keepSpiderfied: true,
                                    nudgeStackedMarkers:false,
                                });

                                google.maps.event.addListener(map, "click", function(event) {
                                    infoWindow.close();
                                });

                                oms.addListener('click', function(marker) {
                                    self.currentClickMarker = marker
                                });

                                oms.addListener('unspiderfy', function(markers) {
                                    let spidered = markers

                                    for (let i = 0; i < spidered.length; i ++) {
                                        let idMark = spidered[i].mark_id
                                        let index = self.spideredMarker.indexOf(idMark);
                                        if (index > -1) { 
                                            self.spideredMarker.splice(index, 1); 
                                        }
                                        
                                        let testIconMerge = spidered[i].cust_icon.iconMerge
                                        spidered[i].setIcon(testIconMerge);
                                    }
                                    self.currentClickMarker = null
                                });

                                
                                oms.addListener('spiderfy', function(markers) {
                                    let spidered = markers;
                                    let firstData = null;

                                    for (let i = 0; i < spidered.length; i ++) {

                                        let testIcon = spidered[i].cust_icon.icon
                                        let idMark = spidered[i].mark_id
                                        spidered[i].setIcon(testIcon);
                                        self.spideredMarker.push(idMark)
                                    }
                                    if (firstData)
                                        self.currentClickMarker = firstData
                                });

                                google.maps.event.addListener(map, 'zoom_changed', function() {        
                                    infoWindow.close();

                                    google.maps.event.addListener(map, 'idle', function() {
                                        let spidered = oms.markersNearAnyOtherMarker();
                                        spidered = spidered.filter(function(v){
                                            return !self.spideredMarker.includes(v.mark_id);
                                        });
                                    
                                        
                                        if (spidered.length){
                                            self.currentClickMarker = null;
                                        }
                                        
                                        for (let i = 0; i < spidered.length; i ++) {
                                            let testIconMerge = spidered[i].cust_icon.iconMerge;
                                            spidered[i].setIcon(testIconMerge);
                                        }

                                    });

                                }); 

                                let bounds = new google.maps.LatLngBounds();

                                let i;
                                let iconDefault = {
                                    url: '/images/gmaps-marker.svg',
                                    scaledSize: new google.maps.Size(35, 35)  // makes SVG icons work in IE
                                }
                                let iconMerge = {
                                    url: '/images/gmaps-marker.svg',
                                    scaledSize: new google.maps.Size(35, 35)  // makes SVG icons work in IE
                                    // url: '/images/green-people.svg',
                                    // scaledSize: new google.maps.Size(50, 50)  // makes SVG icons work in IE
                                }
                                
                                // marker
                                for (i = 0; i < data.length; i++) {
                                    let latTmp = parseFloat(data[i][0]);
                                    let lngTmp = parseFloat(data[i][1]);
                                    let centerTmp = [latTmp, lngTmp];

                                    const location = new google.maps.LatLng(latTmp, lngTmp) 
                                    bounds.extend(location);

                                    self.mapsLatLng[i] = location;
                                    
                                    let marker = new google.maps.Marker({
                                        position: location,
                                        map: map,
                                    });

                                    let dtm = data[i][2]
                                    try {
                                        if(data[i][3].length>1) {
                                            dtm = data[i][3][0] + " until " + data[i][3].slice(-1)
                                        }
                                    } catch (error) {
                                        console.log(error)
                                    }
                                    let contentString = self.createInfoWindow(centerTmp, dtm);                        
                                                                        
                                    marker.desc=contentString;
                                    marker.mark_id=i+1;
                                    marker.cust_icon = {
                                        icon: iconDefault,
                                        iconMerge: iconMerge,
                                    };

                                    self.markers.push(marker);                                    
                                    oms.addMarker(marker)
                                }

                                self.heatmap = new google.maps.visualization.HeatmapLayer({
                                    data: self.mapsLatLng
                                });
                                const renderer = {
                                    render({ count, position }, stats) {
                                        // change color if this cluster has more markers than the mean cluster
                                        // const color = count > Math.max(10, stats.clusters.markers.mean) ? "#ff0000" : "#0000ff";
                                        const color = count > Math.max(10, stats.clusters.markers.mean) ? "#43EA92" : "#43EA92";
                                        // create svg url with fill color
                                        const svg = window.btoa(`
                                        <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
                                            <circle cx="120" cy="120" opacity=".6" r="70" />
                                            <circle cx="120" cy="120" opacity=".3" r="90" />
                                            <circle cx="120" cy="120" opacity=".2" r="110" />
                                        </svg>`);
                                        // create marker using svg icon
                                        return new google.maps.Marker({
                                            position,
                                            icon: {
                                                url: `data:image/svg+xml;base64,${svg}`,
                                                scaledSize: new google.maps.Size(45, 45),
                                            },
                                            label: {
                                                text: String(count),
                                                // color: "rgba(255,255,255,0.9)",
                                                color: "#101220",
                                                fontSize: "12px",
                                            },
                                            title: `Cluster of ${count} markers`,
                                            // adjust zIndex to be above other markers
                                            zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
                                        });
                                    }
                                };
                                // Apply marker cluster
                                // new MarkerClusterer({
                                //     map: map,
                                //     markers: self.markers,
                                //     renderer,
                                //     maxZoom: 6,
                                // });
                                // Apply fitBounds
                                map.fitBounds(bounds);
                                // ===== end ======

                                self.polyline = new google.maps.Polyline({
									path: self.mapsLatLng,
									strokeColor: '#FF0000',
									strokeOpacity:0.8,
									strokeWeight:1.5
                                });

                                self.polyline.setMap(map);
                                self.heatmap.setMap(map);
                                self.heatmap.set('radius', 30);


                                // controlDiv.classList.add('is-show')
                                let toggle_box = '<div class="found-result is-show" style="display: none;">';
                                toggle_box += '<div class="">';
                                toggle_box += '<div>';
                                toggle_box += '<div class="mb-4">';
                                // toggle_box += '<label class="switch">';
                                // toggle_box += '<input onclick="toggleMarker(this)" name="marker" type="checkbox" checked>';
                                // toggle_box += '<span class="slider round"></span>';
                                // toggle_box += '</label>';
                                toggle_box += '<div class="ui-checkbox"><input id="toggle_marker" name="marker" type="checkbox" checked><div class="style-checkbox flex items-center"><div class="checkbox flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg></div><span>Marker</span></div></div>';

                                toggle_box += '</div>';
                                toggle_box += '<div class="mb-4">';
                                // toggle_box += '<label class="switch">';
                                // toggle_box += '<input onclick="toggleLines()" name="lines" type="checkbox" checked>';
                                // toggle_box += '<span class="slider round"></span>';
                                // toggle_box += '</label>';
                                toggle_box += '<div class="ui-checkbox"><input id="toggle_lines" name="lines" type="checkbox" checked><div class="style-checkbox flex items-center"><div class="checkbox flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg></div><span>Lines</span></div></div>';

                                toggle_box += '</div>';
                                toggle_box += '<div class="mb-4">';
                                // toggle_box += '<label class="switch">';
                                // toggle_box += '<input onclick="toggleHeatmap()" name="heatmap" type="checkbox" checked>';
                                // toggle_box += '<span class="slider round"></span>';
                                // toggle_box += '</label>';
                                toggle_box += '<div class="ui-checkbox"><input id="toggle_heatmap" name="heatmap" type="checkbox" checked><div class="style-checkbox flex items-center"><div class="checkbox flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg></div><span>Heatmap</span></div></div>';

                                toggle_box += '</div>';
                                toggle_box += '</div>';
                                toggle_box += '</div>';
                                toggle_box += '<div class="found-result__footer" id="map__footer">';
                                toggle_box += '<span class="block mb-4">Heatmap Radius (meter)</span>';
                                toggle_box += '<div class="list-size field">';
                                toggle_box += '<select class="map-filter field-style h-10" id="radius-size" ';
                                toggle_box += 'name="radius-size">';
                                toggle_box += '<option value="10">10</option>';
                                toggle_box += '<option value="20">20</option>';
                                toggle_box += '<option value="30" selected>30</option>';
                                toggle_box += '<option value="40">40</option>';
                                toggle_box += '</select>';
                                // icon arrow bottom
                                toggle_box += '<div class=" pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"><svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg></div>'
                                toggle_box += '</div>';
                                toggle_box += '</div>';
                                toggle_box += '</div>';

                                let get_map = document.getElementById('map');

                                let el = document.createElement( 'div' );
                                el.innerHTML = toggle_box;

                                this.btnToggleMarker = el.querySelector('#toggle_marker')
                                this.btnToggleMarker.addEventListener('change', this.toggleMarker)

                                this.btnToggleLines = el.querySelector('#toggle_lines')
                                this.btnToggleLines.addEventListener('change', this.toggleLines)

                                this.btnToggleHeatmap = el.querySelector('#toggle_heatmap')
                                this.btnToggleHeatmap.addEventListener('change', this.toggleHeatmap)

                                this.btnRadiusHeatmap = el.querySelector('#radius-size')
                                this.btnRadiusHeatmap.addEventListener('change', this.radiusHeatmap)
                                
                                get_map.after(el);
                                let controlDiv = document.querySelector('.found-result');
                                map.controls[google.maps.ControlPosition.LEFT_TOP].push(controlDiv);

                                setTimeout(function(){
                                    controlDiv.style.display = 'inline';
                                }, 1050);
                            });
                        });
                }
            }
        },
        showPopup(index, coor) {
            if (this.mapVendor == 'osm') {
                try {
                    const marker = this.$refs['marker_'+index][0].mapObject 
                    const map = this.$refs.mapRefOSM.mapObject
                    map.fitBounds(L.latLngBounds([[coor[0], coor[1]]]));
                    marker.openPopup()
                } catch (error) {
                    const marker = this.$refs['marker_'+index+'_cluster'][0].mapObject 
                    const cluster = this.$refs['marker_cluster_'+coor[0]+','+coor[1]]
                    for (let index = 0; index < cluster.length; index++) {
                        const elem = cluster[index].mapObject
                        elem.zoomToShowLayer(marker, function () {
                            marker.openPopup();
                        });
                    }
                }
            } else {
                const google = this.google;  
                const map = this.$refs.mapRef
                const markerLatLng = this.markers[index].getPosition()
                map.panTo(markerLatLng);
                try {
                    const mapObj = map.$mapObject
                    const mapZoom = mapObj.zoom
                    if (mapZoom < 17) {                        
                        let bounds = new google.maps.LatLngBounds();
                        bounds.extend(markerLatLng)
                        map.fitBounds(bounds); 
                    }
                } catch (error) {
                    console.log('=====error', error)
                }
                this.currentClickMarker = this.markers[index]
            }
            window.scrollTo({
                top: document.getElementById("mapview").offsetTop,
                left: 0,
                behavior: "smooth",
            });
        },
    },
    filters: {
    },
    created() {
        // 
    },
    mounted() {
        this.startMap();
        EventEmit.$on('showPopupAerialLog', (data) => {
            this.showPopup(data?.index, data?.coor)
        });
    
    },
}
</script>

<style lang="sass">
    .found-result
        background-color: rgba(255,255,255,.8)
        margin-left: 0px
        height: auto !important
        padding: 17px !important
        width: 200px !important
        span
            color: #000 !important
            font-size: 1.2em

    @media screen and (max-width: 480px)
        .found-result
            height: 25% !important

    // #map__footer
    //     display: none

    .map-filter
        height: 32px
        margin-right: 8px
        border: none
        text-align: center
        width: 100%
    .marker-cluster
        span
            mix-blend-mode: difference
</style>
