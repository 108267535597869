<template lang="pug">
.page-profile
    ActionSearchResult(
        v-if="status === false && $route.matched[0].name !== 'pageTargetDetail'"
        :title="title"
    )

    template(v-else)
        //- title
        TitleResult(:title="title")
        
        //- personal
        PersonalInfo
        //- Family
        FamilyInfo

</template>

<script>
import TitleResult from '@/components/result/Title.vue';
import ActionSearchResult from '@/components/result/ActionSearchResult.vue'
import PersonalInfo from './Personal.vue'
import FamilyInfo from './Family.vue'
import { mapState } from 'vuex';

export default {
    name: 'ResultProfile',
    components: {
        TitleResult,
        ActionSearchResult,
        PersonalInfo,
        FamilyInfo

    },
    data(){
        return {
            title:"Digital ID",
            // NOTE:START
            // get data profile adter subheader done
            // getProfile: false,
            // dataProfile: [],
            // NOTE:END
        }
    },
    computed:{
        ...mapState('profile', [
            'status',
            'fetchProfile',
            'dataRequest'
        ]),
        // NOTE:START
        // get data profile after subheader name done
        // ...mapState({
        //     status_subheader_name: (state) => state.subheader.status_subheader_name.status,
        // }),
        // NOTE:END
        route() {
            return this.$route;
        },
    },
    // NOTE:START
    // get data profile after subheader name done
        
    // watch: {
    //     async status_subheader_name(newVal) {
    //         console.log('=====watch:status_subheader_name --- personal')
    //         console.log(newVal)
    //         if (newVal !== 'loading') {
    //             if (this.getProfile) {
    //                 console.log('=====getSubheader --- personal')
    //                 await this.$store.dispatch('profile/getResultData', this.dataProfile);
    //                 this.$store.commit('profile/setFetchProfile', false)
    //                 this.$store.commit('profile/setDataRequest', [])
    //             }
    //         }

    //     }
    // },
    // mounted() {
    //     console.log('=====mounted --- personal')
    //     console.log(this.status)
    //     console.log(this.status_subheader_name)
    //     console.log(this.getProfile)
    //     console.log(this.dataProfile)
    //     if (this.status =='loading' && this.status_subheader_name !== 'loading') {
    //         console.log('aaa')
    //     }
    // },
    created() {
        EventEmit.$on('profileAction', (data=[]) => {
            // console.log('profileAction')
            // ini dimasukkan ke vuex
            this.$store.commit('profile/setFetchProfile', true)
            this.$store.commit('profile/setDataRequest', data)
        });
    },
    // NOTE:END

}
</script>