<template lang="pug">
.box__body
    .card.card--table.card--no-bg
        .card__header.card--table__header
            h2.text-xl {{ page_title }}

        .card__body
            form.container-form
                .table-static.w-full.table-acl
                    table
                        thead(v-if="tableThead.length > 0")
                            tr
                                th(v-for="(th,index) in tableThead" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')")
                                    .ui-checkbox(v-if="th.name !== null && th.name !== ''")
                                        input(type="checkbox" @change="roleCheckAll($event,th.id)" :value="th.id" v-model="checkedAllRoles")
                                        .style-checkbox
                                            .checkbox
                                                IconSet(type="check")
                                            strong Check All
                        tbody
                            tr(v-if="status_list === 'loading'")
                                td.text-center
                                    .flex.flex-wrap.items-center.justify-center
                                        Spinner(show="true" size="48")
                                    
                            template(v-else-if="list.module && list.module.length > 0")
                                template(v-for="(mod,index) in list.module")
                                    tr.roles
                                        th
                                        th(v-for="role in list.role" :key="role.id")
                                            | {{ role.name }}
                                    tr.modules
                                        th {{ mod.name }}
                                        template(v-if="list.role && list.role.length > 0")
                                            th.text-center(v-for="role in list.role" :key="role.id")
                                                .ui-checkbox
                                                    input(type="checkbox" @change="parentCheck($event,mod.id,role.id)" :value="mod.id+'_'+role.id" v-model="parentChecked")
                                                    .style-checkbox
                                                        .checkbox
                                                            IconSet(type="check")

                                    template(v-if="mod.function.length > 0")
                                        tr(v-for="(func,index) in mod.function" :key="func.id")
                                            td {{ func.name }}
                                            template(v-if="list.role && list.role.length > 0")
                                                td.text-center(v-for="role in list.role" :key="role.id")
                                                    .ui-checkbox
                                                        input(type="checkbox" :class="'module-'+ mod.id +'-role-'+ role.id" :value="mod.id+'_'+role.id+'_'+func.id" v-model="checkedData")
                                                        .style-checkbox
                                                            .checkbox
                                                                IconSet(type="check")

                            tr(v-else)
                                td.text-center No Data Available

                .flex.items-center.justify-end.py-8
                    button.btn.btn-default(type="button" @click="reset()")
                        | Reset
                    //- button.btn-primary-o.ml-2(type="submit" @click="submit($event, true)")
                        | Save &amp; Continue
                    button.btn.btn-primary.ml-4(type="submit" @click="submit($event)")
                        | Save

</template>

<script>
import { mapState } from 'vuex';
import IconSet from '@/components/IconSet.vue';
import Spinner from '@/components/Spinner.vue';

export default {
    name: 'AclPageForm',
    components: {
        IconSet,
        Spinner
    },
    data() {
        return {
            page_title: 'Configuration ACL ',
            checkedAllRoles: [],
            parentChecked: [],
            checkedData: [],
            tableThead: [],
        }
    },
    computed: {
        ...mapState('acl', [
            'status_list',
            'list',
        ]),
        route_name() {
            return this.$route.name;
        },
    },
    watch: {
        route_name() {
            this.getData();
        },
        status_list(newData) {
            if (newData === 'loading') {
                // EventEmit.$emit('showLoading', true);
            } else if (newData === 'empty') {
                EventEmit.$emit('error', 'Data is empty.');
            } else if (newData === 'failed') {
                EventEmit.$emit('error', 'Failed to get acl data.');
            } else {
                // EventEmit.$emit('showLoading', false);
            }
        },
        list(newData) {
            if (Object.keys(newData).length > 0) {

                // table thead
                let tHead = [
                    {
                        name: '',
                        // number: true,
                    }
                ];

                if (newData.role && newData.role.length > 0) {
                    for (var z = 0; z < newData.role.length; z++) {
                        tHead.push(newData.role[z]);
                    }
                }
                this.tableThead = tHead;

                // check checked value
                var modules = newData.module;
                var roles = newData.role;
                let checked = [];
                
                if (modules.length > 0) {
                    for (var i = 0; i < modules.length; i++) {
                        var functions = modules[i].function;
                        
                        if (functions.length > 0) {
                            for (var j = 0; j < functions.length; j++) {
                                // functions[j]

                                if (roles.length > 0) {
                                    for (var k = 0; k < roles.length; k++) {
                                        // roles[k]

                                        if (roles[k].function.filter(function(item) { 
                                            return item.module_id == modules[i].id && item.id == functions[j].id; 
                                        }).length > 0)
                                        checked.push(modules[i].id +'_'+ roles[k].id +'_'+ functions[j].id);
                                    }
                                }
                            }
                        }
                    }
                }

                this.checkedData = checked;
            } else {
                this.tableThead = [];
            }
        },
        tableThead() {},
    },
    methods: {
        roleCheckAll(e,role_id) {
            if (Object.keys(this.list).length > 0) {
                var modules = this.list.module;
                var roles = this.list.role;
                let parentChecked = this.parentChecked;
                let checkedData = this.checkedData;
                let splitParentChecked;
                let splitCheckedData;
                let index;

                if (modules.length > 0) {
                    for (var i = 0; i < modules.length; i++) {
                        var functions = modules[i].function;
                        
                        if (functions.length > 0) {
                            for (var j = 0; j < functions.length; j++) {
                                // functions[j]

                                if (roles.length > 0) {
                                    for (var k = 0; k < roles.length; k++) {
                                        // roles[k]

                                        if (e.target.checked) {

                                            if (roles[k].id == role_id && parentChecked.filter(function(item) { 
                                                splitParentChecked = item.split("_");
                                                return splitParentChecked[1] == role_id;
                                            }).length > 0) {
                                                // remove array
                                                index = parentChecked.indexOf(modules[i].id +'_'+ roles[k].id);
                                                if (index > -1)
                                                  parentChecked.splice(index, 1);
                                            }

                                            if (roles[k].id == role_id && checkedData.filter(function(item) { 
                                                splitCheckedData = item.split("_");
                                                return splitCheckedData[1] == role_id;
                                            }).length > 0) {
                                                // remove array
                                                index = checkedData.indexOf(modules[i].id +'_'+ roles[k].id +'_'+ functions[j].id);
                                                if (index > -1)
                                                  checkedData.splice(index, 1);
                                            }

                                            if (roles[k].id == role_id) {
                                                parentChecked.push(modules[i].id +'_'+ roles[k].id);
                                                checkedData.push(modules[i].id +'_'+ roles[k].id +'_'+ functions[j].id);
                                            }

                                        } else {

                                            if (roles[k].id == role_id) {
                                                index = parentChecked.indexOf(modules[i].id +'_'+ roles[k].id);
                                                if (index > -1)
                                                  parentChecked.splice(index, 1);

                                                index = checkedData.indexOf(modules[i].id +'_'+ roles[k].id +'_'+ functions[j].id);
                                                if (index > -1)
                                                  checkedData.splice(index, 1);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                this.parentChecked = parentChecked;
                this.checkedData = checkedData;
            }
        },
        parentCheck(e,module_id,role_id) {
            if (Object.keys(this.list).length > 0) {
                var modules = this.list.module;
                var roles = this.list.role;
                let checkedData = this.checkedData;
                let splitCheckedData;
                let index;

                if (modules.length > 0) {
                    for (var i = 0; i < modules.length; i++) {
                        var functions = modules[i].function;
                        
                        if (functions.length > 0) {
                            for (var j = 0; j < functions.length; j++) {
                                // functions[j]

                                if (roles.length > 0) {
                                    for (var k = 0; k < roles.length; k++) {
                                        // roles[k]

                                        if (e.target.checked) {

                                            if (modules[i].id == module_id && roles[k].id == role_id && checkedData.filter(function(item) { 
                                                splitCheckedData = item.split("_");

                                                return splitCheckedData[0] == module_id && splitCheckedData[1] == role_id && splitCheckedData[2] == functions[j].id;
                                            }).length > 0) {
                                                // console.log(modules[i].id +'_'+ roles[k].id +'_'+ functions[j].id);

                                                // remove array
                                                index = checkedData.indexOf(modules[i].id +'_'+ roles[k].id +'_'+ functions[j].id);
                                                if (index > -1)
                                                  checkedData.splice(index, 1);
                                            }

                                            if (modules[i].id == module_id && roles[k].id == role_id)
                                                // console.log('on parentCheck() checked');
                                                checkedData.push(modules[i].id +'_'+ roles[k].id +'_'+ functions[j].id);

                                        } else {

                                            if (modules[i].id == module_id && roles[k].id == role_id) {
                                                // console.log('on parentCheck() unchecked');

                                                index = checkedData.indexOf(modules[i].id +'_'+ roles[k].id +'_'+ functions[j].id);
                                                if (index > -1)
                                                  checkedData.splice(index, 1);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                this.checkedData = checkedData;
            }
        },
        getData() {
            if (this.route_name === 'pageAclEdit') {                
                this.checkedAllRoles = [];
                this.parentChecked = [];
                this.$store.commit('acl/setList', {});

                // for dispatch get data
                this.$store.dispatch('acl/getList');
            }
        },
        reset() {
            this.$swal.fire({
                icon: 'warning',
                title: 'Are you sure you want to reset form?',
                // text: id,
                confirmButtonText: 'Yes!',
                confirmButtonColor: '#DD6B55',
                showCancelButton: true,
                allowOutsideClick: false,
            })
            .then((result) => {
                if (result.isConfirmed)
                    this.getData();
            });
        },
        submit(e) {
            e.preventDefault();
            // console.log(this.checkedData);

            EventEmit.$emit('showLoading', true);

            let roles = (this.list.role ? this.list.role : []);

            if (roles.length > 0) {
                let data = [];
                let checkedData = this.checkedData;
                let splitCheckedData;
                let find;
                let current_role_index;

                data = roles.map(function(item) {
                    return {
                        role_id: item.id,
                        function: [],
                    };
                });

                if (checkedData.length > 0) {
                    for (var i = 0; i < checkedData.length; i++) {

                        splitCheckedData = checkedData[i].split("_");

                        if (data.length > 0) {
                            find = false;
                            current_role_index = null;
                            for (var j = 0; j < data.length; j++) {
                                if (data[j].role_id === parseInt(splitCheckedData[1])){
                                    find = true;
                                    current_role_index = j;
                                }
                            }

                            if (find)
                                data[current_role_index].function.push(parseInt(splitCheckedData[2]));
                        }
                    }
                }

                this.$store.dispatch('acl/update', [JSON.stringify(data)])
                .then((resp) => {
                    if (resp.result === 'success') {
                        this.getData();

                        this.$swal.fire({
                            icon: 'success',
                            title: 'Data successfully updated!',
                            timer: 3000,
                        });
                    } else {
                        if (resp.data.message && resp.data.message.errors) {
                            EventEmit.$emit('error', null, resp.data.message.errors);
                        } else {
                            EventEmit.$emit('error', (resp.data.message ? resp.data.message : null));
                        }
                    }
                });
            } else {
                EventEmit.$emit('error', 'No available data to save.');
            }
        },
    },
    created() {
        this.getData();
    },
    mounted() {
        // 
    },
}
</script>

<style lang="sass">

// input[readonly]
input.readonly
    opacity: 0.4
    background: #e2e8f0

.table-acl
    table
        border-width: 1px
        width: 100%
        thead
            tr
                th
                    font-size: 13px
                    text-align: center
                    padding: 0.5rem
                    white-space: nowrap
        tbody
            tr
                th
                    text-align: left
                    padding: 0.5rem
                td
                    padding: 0.5rem
                    border-bottom-width: 1px
                    border-bottom-style: solid
                    padding-left: 1.5rem
                    &.text-center
                        padding-left: 0.5rem
                &:hover
                    td
                        background-color: $color-secondary-600

            tr.roles
                th
                    text-align: center
                    background-color: $color-secondary-700
                    white-space: nowrap
            tr.modules
                th
                th.text-center
                    text-align: center


</style>
