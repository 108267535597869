<template lang="pug">
.license-log
    .card.card--table.card--no-bg
        .card__header.card--table__header
            h2.text-xl List Activated License
            .header-cta(v-if="checkActionButton()")
                .block
                    router-link.btn-primary.m-btn-icon(
                        :to="{ name: 'pageLicenseCreate', params: {} }"
                    )
                        IconSet(type="add")
                        span.m-hide.ml-3 Add License
        .card--table__body
            .box-table
                //- table.table
                    thead
                        tr
                            th License Name
                            th Serial Number
                            th QTY
                            th Installed In
                            th Binded In
                            th Status
                            th Activated Date
                            th Expired Date
                    tbody
                        tr(v-for="item in KeyData" :key="item.id")
                            td
                                .text-sm {{ item.name }}
                            td
                                .text-xs.label-dark {{ item.serial}}
                            td.text-center {{ item.qty }}
                            td
                                .label-dark {{ item.installed }}
                            td
                                .label-dark {{ item.binded }}
                            td
                                .w-24
                                    .label-green.uppercase Activated
                            td
                                .text-sm {{ item.activated }}
                            td
                                .text-sm {{ item.expired }}


                table.table
                    thead.m-hide
                        tr
                            th(v-for="(th,index) in tableThead" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}
                    tbody
                        tr(v-if="status_list.status === 'loading'")
                            td.text-center(:colspan="tableThead.length")
                                .flex.flex-wrap.items-center.justify-center
                                    Spinner(show="true" size="48")
                        template(v-else-if="list && list.data && list.data.length > 0")
                            tr(v-for="(data,index) in list.data" :key="data.id")
                                td
                                    .text-sm {{ data.licenseName }}
                                td
                                    .text-xs.label-dark {{ data.licenseKey }}
                                td.text-center {{ data.qty }}
                                td
                                    .label-dark {{ data.installedIn }}
                                td
                                    .label-dark {{ data.bindedIn }}
                                td
                                    .w-24
                                        .uppercase(:class="data.status.toLowerCase() === 'active' ? 'label-green' : 'label-red'") {{ data.status }}
                                td
                                    .text-sm {{ data.activatedAt }}
                                td
                                    .text-sm {{ data.expiredAt }}
                                
                                td(v-if="checkActionButton()")
                                    .flex.items-center.justify-end
                                        router-link.btn-icon-40.m-hide(
                                            :to="'/license/'+data.id+'/edit'"
                                            v-tooltip="'Edit'"
                                        )
                                            IconSet(type="edit")

                        tr(v-else)
                            td.text-center(:colspan="tableThead.length") No Data Available

            .box-table-footer(v-if="status_list.status !== 'loading'")
                .pagination
                    vue-ads-pagination(
                        :total-items="total_data"
                        :items-per-page="items_per_page"
                        :page="page"
                        :max-visible-pages="5"
                    )
                        template(slot="buttons" slot-scope="props")
                            vue-ads-page-button(
                                v-for="(button, key) in props.buttons"
                                :key="key"
                                v-bind="button"
                                :class="{'bg-yellow-dark': button.active}"
                                @page-change="page = button.page"
                                @range-change="start = button.start; end = button.end" 
                            )
</template>

<script>
import { mapState } from 'vuex';
import IconSet from '@/components/IconSet.vue';
import Spinner from '@/components/Spinner.vue';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';

export default {
    name: 'PagelicenseLog',
    components: {
        IconSet,
        Spinner,
        VueAdsPagination,
        VueAdsPageButton,
    },
    data(){
        return {
            nameKey: null,
            detailPublicKey: false,
            detailPrivateKey: false,

            // tableThead: [],
            total_data: 0,
            items_per_page: 100,

            size: [10, 25, 50, 100],

            page: 0,
            start: 0,
            end: 0,

            keyword: '',
            timeout: null,

            KeyData: [
                {
                    name: 'Proprietary Basic Decoding & Collection System Licenses',
                    serial: 'KA01-KXTF-SRSI-EYKA-HXEZ-EGHJ-PELE-GHJ',
                    qty: 1,
                    installed: '56HDH73',
                    binded: '56HDH73',
                    activated: 'Permanent',
                    expired: 'Permanent'
                },
                {
                    name: 'Basic Historical Targeted Device Activities License',
                    serial: 'KA02-CMIT-MGWA-TGRG-USFO-APLD-TELA-PLD',
                    qty: 1,
                    installed: '56HDH73',
                    binded: '56HDH73',
                    activated: '4 Dec 2020',
                    expired: '3 Dec 2021'
                },
                {
                    name: 'Basic Location Tracking & Monitoring License',
                    serial: 'KA03-GTNS-XKFI-BMEU-JPJJ-JEFT-TELJ-EFT',
                    qty: 1,
                    installed: '56HDH73',
                    binded: '56HDH73',
                    activated: '4 Dec 2020',
                    expired: '3 Dec 2021'
                },
                {
                    name: 'Basic Mobile Device Historical Usage License',
                    serial: 'KA04-HXOX-AJPG-OJSX-NGER-JPOM-NELJ-POM',
                    qty: 1,
                    installed: '56HDH73',
                    binded: '56HDH73',
                    activated: '4 Dec 2020',
                    expired: '3 Dec 2021'
                },
                {
                    name: 'Proprietary Basic Analysis & Monitoring System Licenses',
                    serial: 'KA05-CFES-HMRG-DDEF-NIGK-VAUX-PELV-AUX',
                    qty: 1,
                    installed: '51JDH73',
                    binded: '51JDH73',
                    activated: 'Permanent',
                    expired: 'Permanent'
                },
                {
                    name: 'Proprietary Basic Matcher Engine Licenses',
                    serial: 'KA06-CEOY-JKFL-DRTO-AHJP-NYDV-HELN-YDV',
                    qty: 1,
                    installed: '10JDH73',
                    binded: '10JDH73',
                    activated: 'Permanent',
                    expired: 'Permanent'
                },
                {
                    name: 'Proprietary Basic Matcher Engine Licenses',
                    serial: 'KA07-BXOA-PHJB-PSKW-TNMO-TSGQ-NELT-SGQ',
                    qty: 1,
                    installed: 'FZHDH73',
                    binded: 'FZHDH73',
                    activated: 'Permanent',
                    expired: 'Permanent'
                },
                {
                    name: 'Proprietary Basic Matcher Engine Licenses',
                    serial: 'KA08-AIJT-JXKB-QTAT-AHJM-ADTN-PELA-DTN',
                    qty: 1,
                    installed: 'GZHDH73',
                    binded: 'GZHDH73',
                    activated: 'Permanent',
                    expired: 'Permanent'
                },
                {
                    name: 'Proprietary Basic Matcher Engine Licenses',
                    serial: 'KA09-AVNJ-QCLD-IJST-PQBO-BZSC-RELB-ZSC',
                    qty: 1,
                    installed: 'HZHDH73',
                    binded: 'HZHDH73',
                    activated: 'Permanent',
                    expired: 'Permanent'
                },
                {
                    name: 'Proprietary Basic Database Management System',
                    serial: 'KA10-GYLL-TRGE-WPOW-SHIA-SGLL-LELS-GLL',
                    qty: 1,
                    installed: 'GMNDH73',
                    binded: 'GMNDH73',
                    activated: 'Permanent',
                    expired: 'Permanent'
                },
                {
                    name: 'Basic Real-Life Data Analytics License',
                    serial: 'KA11-HRDT-FDXH-TPTR-INKE-YMMC-BELY-MMC',
                    qty: 1,
                    installed: 'GMNDH73',
                    binded: 'GMNDH73',
                    activated: '4 Dec 2020',
                    expired: '3 Dec 2021'
                },
                {
                    name: 'Unlimited Target Licenses',
                    serial: 'KA12-CSQE-QGLE-TUKB-CPGZ-KMZG-FELK-MZG',
                    qty: 1,
                    installed: 'GMNDH73',
                    binded: 'GMNDH73',
                    activated: '4 Dec 2020',
                    expired: '3 Dec 2021'
                },
                {
                    name: 'User Access Licenses',
                    serial: 'KA13-HGLW-VHZM-QXBE-SRJX-HHGR-LELH-HG01',
                    qty: 1,
                    installed: 'GMNDH73',
                    binded: 'GMNDH73',
                    activated: '4 Dec 2020',
                    expired: '3 Dec 2021'
                },
                {
                    name: 'User Access Licenses',
                    serial: 'KA13-HGLW-VHZM-QXBE-SRJX-HHGR-LELH-HG02',
                    qty: 1,
                    installed: 'GMNDH73',
                    binded: 'GMNDH73',
                    activated: '4 Dec 2020',
                    expired: '3 Dec 2021'
                },
                {
                    name: 'User Access Licenses',
                    serial: 'KA13-HGLW-VHZM-QXBE-SRJX-HHGR-LELH-HG03',
                    qty: 1,
                    installed: 'GMNDH73',
                    binded: 'GMNDH73',
                    activated: '4 Dec 2020',
                    expired: '3 Dec 2021'
                },
                {
                    name: 'User Access Licenses',
                    serial: 'KA13-HGLW-VHZM-QXBE-SRJX-HHGR-LELH-HG04',
                    qty: 1,
                    installed: 'GMNDH73',
                    binded: 'GMNDH73',
                    activated: '4 Dec 2020',
                    expired: '3 Dec 2021'
                },
                {
                    name: 'User Access Licenses',
                    serial: 'KA13-HGLW-VHZM-QXBE-SRJX-HHGR-LELH-HG05',
                    qty: 1,
                    installed: 'GMNDH73',
                    binded: 'GMNDH73',
                    activated: '4 Dec 2020',
                    expired: '3 Dec 2021'
                },
                {
                    name: 'User Access Licenses',
                    serial: 'KA13-HGLW-VHZM-QXBE-SRJX-HHGR-LELH-HG06',
                    qty: 1,
                    installed: 'GMNDH73',
                    binded: 'GMNDH73',
                    activated: '4 Dec 2020',
                    expired: '3 Dec 2021'
                },
                {
                    name: 'User Access Licenses',
                    serial: 'KA13-HGLW-VHZM-QXBE-SRJX-HHGR-LELH-HG07',
                    qty: 1,
                    installed: 'GMNDH73',
                    binded: 'GMNDH73',
                    activated: '4 Dec 2020',
                    expired: '3 Dec 2021'
                },
                {
                    name: 'User Access Licenses',
                    serial: 'KA13-HGLW-VHZM-QXBE-SRJX-HHGR-LELH-HG08',
                    qty: 1,
                    installed: 'GMNDH73',
                    binded: 'GMNDH73',
                    activated: '4 Dec 2020',
                    expired: '3 Dec 2021'
                },
                {
                    name: 'User Access Licenses',
                    serial: 'KA13-HGLW-VHZM-QXBE-SRJX-HHGR-LELH-HG09',
                    qty: 1,
                    installed: 'GMNDH73',
                    binded: 'GMNDH73',
                    activated: '4 Dec 2020',
                    expired: '3 Dec 2021'
                },
                {
                    name: 'User Access Licenses',
                    serial: 'KA13-HGLW-VHZM-QXBE-SRJX-HHGR-LELH-HG10',
                    qty: 1,
                    installed: 'GMNDH73',
                    binded: 'GMNDH73',
                    activated: '4 Dec 2020',
                    expired: '3 Dec 2021'
                },
                {
                    name: 'User Data Encryptions License',
                    serial: 'KA14-NWID-MBEH-KHZT-JADK-OHSC-RELO-HSC',
                    qty: 1,
                    installed: 'GMNDH73',
                    binded: 'GMNDH73',
                    activated: '4 Dec 2020',
                    expired: '3 Dec 2021'
                },
                {
                    name: 'Proprietary Basic Location Mapper System Licenses',
                    serial: 'KA14-DZFE-GYQB-MCSY-RWMF-ITPF-PELI-TPF',
                    qty: 1,
                    installed: 'C0JDH73',
                    binded: 'C0JDH73',
                    activated: 'Permanent',
                    expired: 'Permanent'
                },
                {
                    name: 'Basic Real-Time Geolocation Tracking License',
                    serial: 'KA15-EYES-BPXH-OAKC-TLDH-LZPO-FELL-ZPO',
                    qty: 1,
                    installed: 'C0JDH73',
                    binded: 'C0JDH73',
                    activated: '4 Dec 2020',
                    expired: '3 Dec 2021'
                },
                {
                    name: 'Basic Multi-Target Geolocation Tracking License',
                    serial: 'KA16-LKKY-UAHJ-PRWP-PKMS-CMDK-VELC-MDK',
                    qty: 1,
                    installed: 'C0JDH73',
                    binded: 'C0JDH73',
                    activated: '4 Dec 2020',
                    expired: '3 Dec 2021'
                },
            ],
        }
    },
    computed: {
        ...mapState('license', [
            'status_list',
            'list',
        ]),
        ...mapState('auth', [
            'user',
        ]),
        route_name() {
            return this.$route.name;
        },
        tableThead() {
            let tableThead = [
                {
                    name: 'License Name',
                },
                {
                    name: 'Serial Number',
                },
                {
                    name: 'QTY',
                },
                {
                    name: 'Installed In',
                },
                {
                    name: 'Binded In',
                },
                {
                    name: 'Status',
                },
                {
                    name: 'Activated Date',
                },
                {
                    name: 'Expired Date',
                },
            ];

            if (this.checkActionButton())
                tableThead.push({
                    name: 'Action',
                    action: true,
                });

            return tableThead;
        },
    },
    watch: {
        route_name(newData, oldData) {
            if (newData === 'pageLicenseIndex') {
                if (oldData !== 'pageLicenseIndex') {
                    this.items_per_page = 10;
                    this.page = 0;
                    this.keyword = '';
                    this.$store.commit('license/setList', {});
                }

                this.getData();
            }
        },
        tableThead() {},
        list(newData) {
            if (Object.keys(newData).length > 0) {
                this.total_data = parseInt(newData.total_data);
                // this.page = (parseInt(newData.current_page) - 1);
            } else {
                this.total_data = 0;
            }
        },
        items_per_page() {
            this.getData();
        },
        page() {
            this.getData();
        },
    },
    methods: {
        checkActionButton() {
            return (Object.keys(this.user).length > 0 && this.user.role && this.user.role.label === 'superadmin')
        },
        openPublicKey(open = true, name = null) {
            if(open) {
                this.detailPublicKey = true;
                EventEmit.$emit('overflowHidden', true);
                this.nameKey = name
            } else {
                this.detailPublicKey = false;
                EventEmit.$emit('overflowHidden', false);
                this.nameKey = null 
            }
        },

        openPrivateKey(open = true, name = null) {
            if(open) {
                this.detailPrivateKey = true;
                EventEmit.$emit('overflowHidden', true);
                this.nameKey = name
            } else {
                this.detailPrivateKey = false;
                EventEmit.$emit('overflowHidden', false);
                this.nameKey = null 
            }
        },

        getData() {
            // for dispatch get data
            if (this.route_name === 'pageLicenseIndex') {
                // async getList({ state, commit, dispatch }, [limit=null, page=null, client_id=null, status=null]) {
                this.$store.dispatch('license/getList', [
                    this.items_per_page, 
                    (this.page + 1), 
                    // for testing only !!!
                    // 2
                    
                    // true value !!!
                    (Object.keys(this.user).length > 0 && this.user.client && this.user.client.id ? this.user.client.id : '')
                ]);
            }
        },
        pageChange(page) {
            this.page = page;
        },
        sizeChange() {
            this.page = 0;
        },
    },
    created() {
        this.getData();
    },
    mounted() {
        // 
    },
}
</script>