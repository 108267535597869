<template lang="pug">
.roles-shell
    .box
        .box__header
            TitlePage(
                title="Access Control List"
                :breadcrumb="breadcrumb"
                )
        .box__body
            router-view
</template>

<script>
import TitlePage from '@/components/shell/TitlePage.vue'
export default {
    name: 'AclShell',
    components: {
        TitlePage,
    },
    data() {
        return {
            // 
        }
    },
    computed: {
        route() {
            return this.$route;
        },
        // id() {
        //     return this.$route.params.id;
        // },
        breadcrumb() {
            let breadcrumb = [
                {
                    name: 'ACL',
                    url: '/acl',
                },
            ];

            return breadcrumb;
        },
    },
    watch: {
        route() {},
        id() {},
        breadcrumb() {},
    },
    created() {
        // 
    },
    mounted() {
        // 
    },
}
</script>
