<template lang="pug">
.box-loading.flex.items-center.justify-center
  .wrapper-loading(v-show="show")
    .loading-interaction
      .loading-interaction__icon
        .loading-interaction__load(v-if="loading_text == 'Connecting'")
          .connect
            .connect__bar.connect__bar--1
            .connect__bar.connect__bar--2
            .connect__bar.connect__bar--3
        .loading-interaction__load(v-else-if="loading_text == 'Collecting'")
          .collect
            .collect__layer.collect__layer--1
            .collect__layer.collect__layer--2
            .collect__layer.collect__layer--3
            .collect__layer.collect__layer--4
        
        .loading-interaction__load(v-else-if="loading_text == 'Downloading'")
          .box-download-animated
            .box-download-animated__icon
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevrons-down"><polyline points="7 13 12 18 17 13"></polyline><polyline points="7 6 12 11 17 6"></polyline></svg>
        
        .loading-interaction__load(v-else="loading_text == 'Analyzing'")
          .box-analizing
            .box-analizing__icon.box-analizing__icon--1
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-settings"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
            
            .box-analizing__icon.box-analizing__icon--2
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-settings"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
            

      .loading-interaction__info
        .flex.items-center.flex-col.gap-2(v-if="loading_text == 'Connecting'")
          .flex.items-center.justify-center
            .block.mr-2.text-white {{ loading_text }}
            .animate-loading
              .circle-loading
              .circle-loading
              .circle-loading
          //- p.text-sm Let's start by connecting to the server
        
        .flex.items-center.flex-col.gap-2(v-if="loading_text == 'Collecting'")
          .flex.items-center.justify-center
            .block.mr-2.text-white {{ loading_text }}
            .animate-loading
              .circle-loading
              .circle-loading
              .circle-loading
          //- p.text-sm Proceed to collect the required data.
        .flex.items-center.flex-col.gap-2(v-else-if="loading_text == 'Downloading'")
          .flex.items-center.justify-center
              .block.mr-2.text-white {{ loading_text }}
              .animate-loading
                .circle-loading
                .circle-loading
                .circle-loading
          //- p.text-sm Download the collected data
        
        .flex.items-center.flex-col.gap-2(v-else-if="loading_text == 'Analyzing'")
          .flex.items-center.justify-center
            .block.mr-2.text-white {{ loading_text }}
            .animate-loading
              .circle-loading
              .circle-loading
              .circle-loading
          //- p.text-sm Finally, we will analyze your data <br> the time of analzying based on the connection and your data.

</template>

<script>
export default {
  name: 'Loading',
  props: ['show', 'title'],
  data() {
        return {
            loading_text: 'Connecting',
        }
  },
  computed: {
    route_name() {
      return this.$route.name;
    },
    elapsed() {
      return this.$store.getters['config/getElapsed']({ routeName: this.route_name, title: this.title });
    },
  },
  created() {
    this.$store.dispatch('config/startTimer', { routeName: this.route_name, title: this.title });
    this.updateMessage(this.elapsed);
  },
  watch: {
    elapsed(newVal) {
      this.updateMessage(newVal);
    },
  },
  methods: {
    updateMessage(elapsed) {
      var intervalLoading = this.$store.getters['config/getLoadingIntervalByRoute'](this.route_name).split(',').map(Number)

      if (elapsed <= intervalLoading[0]) {
        this.loading_text = 'Connecting';
      } else if (elapsed <= intervalLoading[1]) {
        this.loading_text = 'Collecting';
      } else if (elapsed <= intervalLoading[2]) {
        this.loading_text = 'Downloading';
      } else if (elapsed <= intervalLoading[3]) {
        this.loading_text = 'Analyzing';
      } else if (elapsed > intervalLoading[3]) {
        this.loading_text = 'Analyzing';
      }
    },
  },
  beforeDestroy() {
    this.$store.commit('config/CLEAR_TIMER', { routeName: this.route_name, title: this.title });
  },
}
</script>

<style lang="sass">
.box-loading
  position: relative
  width: 100%
  min-height: 400px

  .animate-loading
    position: relative

.circle-loading
  width: 4px
  height: 4px
  position: absolute
  border-radius: 50%
  background-color: #ffffff
  left: 0px
  transform-origin: 50%
  animation: circle .5s alternate infinite ease

  &:nth-child(2)
    left: 8px
    animation-delay: .2s

  &:nth-child(3)
    left: 16px
    animation-delay: .3s

@keyframes circle
  0%
    opacity: 0

  40%
    opacity: 1

  100%
    opacity: 0

.wrapper-loading span
  font-size: 1em
  // color: #626262
  left: 15%

.loading-interaction
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  gap: 16px
  width: 400px
  height: 400px
  text-align: center
  &__icon
    // width: 56px
    // height: 56px
    width: 88px
    height: 88px
    display: flex
    align-items: center
    justify-content: center
    border-radius: 8px
    background-color: #43EA92

.connect
  transform: rotate(0.5turn)
  display: flex
  &__bar
    margin-left: 6px
    border-radius: 7px
    width: 12px
    background: #189554
    animation-fill-mode: forwards
    &--1
      height: 48px
      animation: fade 2s 1.8s infinite
    &--2
      height: 32px
      animation: fade 2s 1.4s infinite
    &--3
      height: 16px
      animation: fade 2s 1s infinite
@keyframes fade
  0%
    background: #6afbaf
  100%
    background: #189554

.collect
  position: relative
  display: flex
  width: 48px
  flex-wrap: wrap
  &__layer
    width: 24px
    height: 24px
    background: #189554
    &--1
      transform: translate(-2px,-2px)
      animation: collect-1 0.6s infinite alternate
    &--2
      transform: translate(2px,-2px)
      animation: collect-2 0.5s infinite alternate
    &--3
      transform: translate(-2px,2px)
      animation: collect-3 0.8s infinite alternate
    &--4
      transform: translate(2px,2px)
      animation: collect-4 1.2s infinite alternate

@keyframes collect-1
  0%
    transform: translate(-2px,-2px)
  100%
    transform: translate(0px,0px)

@keyframes collect-2
  0%
    transform: translate(2px,-2px)
  100%
    transform: translate(0px,0px)

@keyframes collect-3
  0%
    transform:  translate(-2px,2px)
  100%
    transform: translate(0px,0px)

@keyframes collect-4
  0%
    transform: translate(2px,2px)
  100%
    transform: translate(0px,0px)


.box-download-animated
  width: 48px
  height: 48px
  overflow: hidden
  border-radius: 6px
  background: #189554
  display: flex
  align-items: center
  justify-content: center
  animation: bounce-download 2s infinite alternate
  &__icon
    animation: download 1.2s infinite
    transform: translateY(-100%)
    color: #6afbaf
    svg
      width: 32px

@keyframes download
  0%
    transform: translateY(-100%)
  100%
    transform: translateY(100%)

@keyframes bounce-download
  0%
    transform: translateY(-2px)
  100%
    transform: translateY(2px)

.box-analizing
    position: relative
    width: 32px
    height: 32px
    display: flex
    align-items: center
    justify-content: center
    &__icon
      color: #189554
      svg
        width: 32px
      &--1
        transform: scale(1)translate(4px,-2px)
        animation: rotate-1 2s infinite alternate
      &--2
        transform: scale(0.8)translate(0px,4px)
        animation: rotate-2 2s infinite alternate


@keyframes rotate-1 
  0%
    transform: scale(1)translate(4px,-2px)rotate(0deg)
  100%
    transform: scale(1)translate(4px,-2px)rotate(-320deg)

@keyframes rotate-2
  0%
    transform: scale(0.8)translate(0px,4px)rotate(0deg)
  100%
    transform: scale(0.8)translate(0px,4px)rotate(320deg)
</style>
