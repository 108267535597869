<template lang="pug">
//- filter
.box-filter
    //- showing data information mobile
    .m-only
        .filter-info
            .flex.items-center.justify-between.mb-3
                Strong.uppercase.text-xs.font-bold Filtered by
                button.btn-default-o.btn-small(
                    type='button'
                    v-on:click="showFilter = !showFilter"
                    ) Change Filter
            //- p 10/10/2020 - 17/10/2020
            p {{ dateRange.startDate }} -  {{ dateRange.endDate }}

    //- end showing data information mobile
    .mobile-filter(
        v-bind:class="{ 'is-show' : showFilter}"
    )
        .m-only
            .flex.items-center.justify-between.p-4.border-b
                h2.text-xl Filter
                button.btn-icon-40(
                    v-on:click="showFilter = !showFilter"
                )
                    IconSet(type="close")
        .items-filter

            .field.mr-3
                .relative.mr-3.field-style
                    date-range-picker(
                        v-if="dateRange.startDate && dateRange.endDate" 
                        ref="picker" 
                        v-model="dateRange" 
                        @update="pickerUpdate($event)" 
                        :locale-data="filter_config.locale_data" 
                        :date-format="dateFormat" 
                        opens="right"
                        :ranges="default_ranges"
                        autoApply=true
                        
                        :time-picker-increment="filter_config.time_picker.minute_increment"
                        :time-picker="time_picker_status"
                        :time-picker24-hour="filter_config.time_picker.hour24_status"
                    )
                        div(
                            slot="input" 
                            slot-scope="picker") 
                                | {{ formattedDate(picker.startDate)}} - {{ formattedDate(picker.endDate)}}

                    .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2
                        IconSet(type="calendar")

            button.btn-primary.mr-3(
                v-if="isKey && moduleLoading === false"
                @click="filter()"
                v-on:click="showFilter = !showFilter"
            ) Filter
            button.btn-primary.mr-3.btn-primary--disabled(
                v-else
                disabled="disabled"
                style="cursor: not-allowed !important;"
            ) Filter

            button.btn-default(
                v-if="isKey && moduleLoading === false"
                @click="reset()"
                v-on:click="showFilter = !showFilter"
            ) Reset
            button.btn-default.btn-default--disabled(
                v-else
                disabled="disabled"
                style="cursor: not-allowed;"
            ) Reset

            template(v-if="retryShow")
                button.btn-primary.ml-3(
                    v-if="isKey && moduleLoading === false"
                    @click="filter()"
                    v-on:click="showFilter = !showFilter"
                ) Retry
                button.btn-primary.ml-3.btn-primary--disabled(
                    v-else
                    disabled="disabled"
                    style="cursor: not-allowed;"
                ) Retry
//- end

</template>

<script>
//
import { mapState } from 'vuex';
import { env } from '@/config/env';
import { decrypt } from '@/util/crypto';

import IconSet from '@/components/IconSet.vue'
import LogHelper from '@/helpers/log-helper';

import moment from 'moment';
import DateRangePicker from 'vue2-daterange-picker';

import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
    name: 'ResultFilter',
    props: ['appUsage'],
    components: {
        IconSet,
        DateRangePicker,
    },
    // props: ['isKey'],
    data(){
        return {
            showFilter: false,
            retryShow: false,

            isAppUsage: this.appUsage,

            moduleLoading: true,
            module: '',

            dateRange: {
                startDate: '',
                endDate: '',
            },

            filter_config: {
                locale_data: { 
                    firstDay: env.GLOBAL_FILTER_LOCALE_FIRSTDAY, 
                    format: env.GLOBAL_FILTER_LOCALE_FORMAT,
                },
                time_picker: {
                    status: env.GLOBAL_FILTER_TIMEPICKER_STATUS,
                    hour24_status: env.GLOBAL_FILTER_TIMEPICKER24_STATUS,
                    minute_increment: env.GLOBAL_FILTER_MINUTE_INCREMENT,
                }
            },
        }
    },
    computed: {
        ...mapState({
            // from_date_subheader: (state) => state.subheader.from_date,
            // to_date_subheader: (state) => state.subheader.to_date,

            from_date_recent_location: (state) => state.recentLocation.from_date,
            to_date_recent_location: (state) => state.recentLocation.to_date,

            from_date_overall: (state) => state.overall.from_date,
            to_date_overall: (state) => state.overall.to_date,
            default_range_overall: (state) => state.overall.default_range,

            from_date_location_movement: (state) => state.locationMovement.from_date,
            to_date_location_movement: (state) => state.locationMovement.to_date,
            default_range_location_movement: (state) => state.locationMovement.default_range,

            from_date_location_log: (state) => state.locationLog.from_date,
            to_date_location_log: (state) => state.locationLog.to_date,
            default_range_location_log: (state) => state.locationLog.default_range,

            from_date_browsing_log: (state) => state.browsingLog.from_date,
            to_date_browsing_log: (state) => state.browsingLog.to_date,
            default_range_browsing_log: (state) => state.browsingLog.default_range,

            from_date_aerial_log: (state) => state.aerialLog.from_date,
            to_date_aerial_log: (state) => state.aerialLog.to_date,
            default_range_aerial_log: (state) => state.aerialLog.default_range,

            // from_date_profile: (state) => state.profile.from_date,
            // to_date_profile: (state) => state.profile.to_date,

            // from_date_device_log: (state) => state.deviceLog.from_date,
            // to_date_device_log: (state) => state.deviceLog.to_date,

            from_date_history_device_roles: (state) => state.historyDeviceRoles.from_date,
            to_date_history_device_roles: (state) => state.historyDeviceRoles.to_date,
            default_range_history_device_roles: (state) => state.historyDeviceRoles.default_range,

            from_date_device_history: (state) => state.deviceHistory.from_date,
            to_date_device_history: (state) => state.deviceHistory.to_date,
            default_range_device_history: (state) => state.deviceHistory.default_range,

            from_date_tethered_device: (state) => state.tetheredDevice.from_date,
            to_date_tethered_device: (state) => state.tetheredDevice.to_date,
            default_range_tethered_device: (state) => state.tetheredDevice.default_range,

            // --------------------------------------------------------------

            // status_subheader: (state) => state.subheader.status,
            status_recent_location: (state) => state.recentLocation.status,
            status_recent_location_recent_location: (state) => state.recentLocation.status_recent_location,

            status_overall: (state) => state.overall.status,
            status_overall_app_usage: (state) => state.overall.status_app_usage,

            status_location_movement: (state) => state.locationMovement.status,
            // status_location_movement_location_log: (state) => state.locationMovement.status_location_log,

            status_location_log: (state) => state.locationLog.status,
            status_location_log_location_log: (state) => state.locationLog.status_location_log,

            status_browsing_log: (state) => state.browsingLog.status,
            status_browsing_log_browsing_log: (state) => state.browsingLog.status_browsing_log,

            status_aerial_log: (state) => state.aerialLog.status,
            status_aerial_log_aerial_log: (state) => state.aerialLog.status_aerial_log,

            // status_profile: (state) => state.profile.status,
            // status_profile_profile_identity: (state) => state.profile.status_profile_identity,
            // status_profile_profile_family: (state) => state.profile.status_profile_family,

            // status_device_log: (state) => state.deviceLog.status,
            status_history_device_roles: (state) => state.historyDeviceRoles.status,

            status_device_history: (state) => state.deviceHistory.status,
            status_device_history_device_history: (state) => state.deviceHistory.status_device_history,

            status_tethered_device: (state) => state.tetheredDevice.status,
            status_tethered_device_tethered_device: (state) => state.tetheredDevice.status_tethered_device,

        }),
        route() {
            return this.$route;
        },
        route_name() {
            return this.$route.name;
        },
        isKey() {
            return this.$route.params.key ? true : false;
        },
        default_ranges() {
            // let log_msg = new LogHelper("computed - default_ranges")

            let start_dt = moment()
            let end_dt = moment()
            // log_msg.push(`start_dt: ${start_dt}`)
            // log_msg.push(`end_dt: ${end_dt}`)
            
            if (this.dateRange.startDate){
                // log_msg.push("set start date")
                const start_dt_tmp = moment(this.dateRange.startDate, this.date_format)
                // log_msg.push(`start_dt_tmp: ${start_dt_tmp}`)

                start_dt.second(start_dt_tmp.second())
                start_dt.minute(start_dt_tmp.minute())
                start_dt.hour(start_dt_tmp.hour())
            }
            
            if (this.dateRange.endDate){
                // log_msg.push("set end date")

                const end_dt_tmp = moment(this.dateRange.endDate, this.date_format)
                // log_msg.push(`end_dt_tmp: ${end_dt_tmp}`)
                
                end_dt.second(end_dt_tmp.second())
                end_dt.minute(end_dt_tmp.minute())
                end_dt.hour(end_dt_tmp.hour())
            }

            // log_msg.push(this.dateRange)
            // log_msg.print()
            return {
                'Today': [
                    start_dt.toDate(),
                    end_dt.toDate()
                ],
                'Yesterday': [
                    start_dt.clone().subtract(1, 'days').startOf('day').toDate(),
                    end_dt.clone().subtract(1, 'days').endOf('day').toDate()
                ],
                'Last 7 days': [
                    start_dt.clone().subtract(6, 'days').startOf('day').toDate(),
                    end_dt.endOf('day').toDate()
                ],
                'Last week': [
                    start_dt.clone().subtract(1, 'week').startOf('isoWeek').toDate(),
                    end_dt.clone().subtract(1, 'week').endOf('isoWeek').toDate()
                ],
            }
        },
        date_format(){
            return this.$store.getters['config/getDateFormatFilterByRoute'](this.route_name)
        },
        time_picker_status(){
            return this.$store.getters['config/getDateFormatFilterByRoute'](this.route_name, true)
        },
        formattedDate() {
            return function (date) {
                return moment(date).format(this.date_format);
            };
        }
    },
    watch: {
        async route_name(newData) {
            await this.setModule();
            this.dateChange();
            this.retryChange();
        },
        isKey() {},

        // from_date(newData, oldData) {
        //     if (newData !== oldData)
        //         this.getFilter();
        // },
        // to_date(newData, oldData) {
        //     if (newData !== oldData)
        //         this.getFilter();
        // },

        // ------------------------------------------------

        from_date_recent_location() {
            this.dateChange();
        },
        to_date_recent_location() {
            this.dateChange();
        },

        from_date_overall() {
            this.dateChange();
        },
        to_date_overall() {
            this.dateChange();
        },

        from_date_location_movement() {
            this.dateChange();
        },
        to_date_location_movement() {
            this.dateChange();
        },
        
        from_date_location_log() {
            this.dateChange();
        },
        to_date_location_log() {
            this.dateChange();
        },

        from_date_browsing_log() {
            this.dateChange();
        },
        to_date_browsing_log() {
            this.dateChange();
        },

        from_date_aerial_log() {
            this.dateChange();
        },
        to_date_aerial_log() {
            this.dateChange();
        },

        // from_date_profile() {
        //     this.dateChange();
        // },
        // to_date_profile() {
        //     this.dateChange();
        // },

        // from_date_device_log() {
        //     this.dateChange();
        // },
        // to_date_device_log() {
        //     this.dateChange();
        // },

        from_date_history_device_roles() {
            this.dateChange();
        },
        to_date_history_device_roles() {
            this.dateChange();
        },

        from_date_device_history() {
            this.dateChange();
        },
        to_date_device_history() {
            this.dateChange();
        },

        from_date_tethered_device() {
            this.dateChange();
        },
        to_date_tethered_device() {
            this.dateChange();
        },

        // --------------------------------------------------

        async status_recent_location(newData) {
            if (this.module === 'recentLocation')
                await this.setLoading(newData);
            this.retryChange();
        },
        async status_overall(newData) {
            if (this.module === 'overall')
                await this.setLoading(newData);
            this.retryChange();
        },
        async status_location_movement(newData) {
            if (this.module === 'locationMovement')
                await this.setLoading(newData);
            this.retryChange();
        },
        async status_location_log(newData) {
            if (this.module === 'locationLog')
                await this.setLoading(newData);
            this.retryChange();
        },
        async status_browsing_log(newData) {
            if (this.module === 'browsingLog')
                await this.setLoading(newData);
            this.retryChange();
        },
        async status_aerial_log(newData) {
            if (this.module === 'aerialLog')
                await this.setLoading(newData);
            this.retryChange();
        },
        // async status_profile(newData) {
        //     if (this.module === 'profile')
        //         await this.setLoading(newData);
        //     this.retryChange();
        // },
        // async status_device_log(newData) {
        //     if (this.module === 'deviceLog')
        //         await this.setLoading(newData);
        //     this.retryChange();
        // },
        async status_history_device_roles(newData) {
            if (this.module === 'historyDeviceRoles')
                await this.setLoading(newData);
            this.retryChange();
        },
        async status_device_history(newData) {
            if (this.module === 'deviceHistory')
                await this.setLoading(newData);
            this.retryChange();
        },
        async status_tethered_device(newData) {
            if (this.module === 'tetheredDevice')
                await this.setLoading(newData);
            this.retryChange();
        },
    },
    methods: {
        setModule() {
            let mod = '';

            switch(this.route_name) {
                case 'ResultRecentLocation':
                case 'ResultRealtimeRecentLocation':
                case 'TargetDetailRecentLocation':
                    mod = 'recentLocation';
                    break;

                case 'ResultOverall':
                case 'ResultHistoricalOverall':
                case 'TargetDetailOverall':
                    mod = 'overall';
                    break;

                case 'ResultLocationMovement':
                case 'ResultHistoricalLocationMovement':
                case 'TargetDetailLocationMovement':
                    mod = 'locationMovement';
                    break;

                case 'ResultLocationLog':
                case 'ResultHistoricalLocationLog':
                case 'TargetDetailLocationLog':
                    mod = 'locationLog';
                    break;

                case 'ResultBrowsingLog':
                case 'ResultHistoricalBrowsingLog':
                case 'TargetDetailBrowsingLog':
                    mod = 'browsingLog';
                    break;

                case 'ResultAerialLog':
                case 'ResultHistoricalAerialLog':
                case 'TargetDetailAerialLog':
                    mod = 'aerialLog';
                    break;

                // case 'ResultProfile':
                // case 'TargetDetailProfile':
                //     mod = 'profile';
                //     break;

                // case 'ResultDeviceLog':
                // case 'TargetDetailDeviceLog':
                //     mod = 'deviceLog';
                //     break;
                case 'ResultHistoryDeviceRoles':
                case 'ResultHistoricalHistoryDeviceRoles':
                case 'TargetDetailHistoryDeviceRoles':
                    mod = 'historyDeviceRoles';
                    break;

                case 'ResultDeviceHistory':
                case 'ResultHistoricalDeviceHistory':
                case 'TargetDetailDeviceHistory':
                    mod = 'deviceHistory';
                    break;

                case 'ResultTetheredDevice':
                case 'ResultHistoricalTetheredDevice':
                case 'TargetDetailTetheredDevice':
                    mod = 'tetheredDevice';
                    break;

                // case 'ResultPredictionName':
                // case 'ResultHistoricalPredictionName':
                // case 'TargetDetailPredictionName':
                //     mod = 'predictionName';
                //     break;
            }

            this.module = mod;

            const module_status = {
                recentLocation: 'status_recent_location',
                overall: 'status_overall',
                locationMovement: 'status_location_movement',
                locationLog: 'status_location_log',
                browsingLog: 'status_browsing_log',
                aerialLog: 'status_aerial_log',
                historyDeviceRoles: 'status_history_device_roles',
                deviceHistory: 'status_device_history',
                tetheredDevice: 'status_tethered_device',
            };

            if (this[module_status[mod]])
                this.setLoading(this[module_status[mod]]);
        },
        setLoading(is_loading) {
            if (is_loading === 'loading')
                is_loading = true;
            else
                is_loading = false;

            this.moduleLoading = is_loading;
        },
        dateChange() {
            // console.log("=====this.route_name", this.route_name)
            if (this.route_name == 'ResultRecentLocation' || this.route_name == 'ResultRealtimeRecentLocation' || this.route_name == 'TargetDetailRecentLocation') {
                this.dateRange.startDate = this.from_date_recent_location;
                this.dateRange.endDate = this.to_date_recent_location;

            } else if (this.route_name == 'ResultOverall' || this.route_name == 'ResultHistoricalOverall' || this.route_name == 'TargetDetailOverall') {
                this.dateRange.startDate = this.from_date_overall;
                this.dateRange.endDate = this.to_date_overall;

            } else if (this.route_name == 'ResultLocationMovement' || this.route_name == 'ResultHistoricalLocationMovement' || this.route_name == 'TargetDetailLocationMovement') {
                this.dateRange.startDate = this.from_date_location_movement;
                this.dateRange.endDate = this.to_date_location_movement;

            } else if (this.route_name == 'ResultLocationLog' || this.route_name == 'ResultHistoricalLocationLog' || this.route_name == 'TargetDetailLocationLog') {
                this.dateRange.startDate = this.from_date_location_log;
                this.dateRange.endDate = this.to_date_location_log;

            } else if (this.route_name == 'ResultBrowsingLog' || this.route_name == 'ResultHistoricalBrowsingLog' || this.route_name == 'TargetDetailBrowsingLog') {
                this.dateRange.startDate = this.from_date_browsing_log;
                this.dateRange.endDate = this.to_date_browsing_log;

            } else if (this.route_name == 'ResultAerialLog' || this.route_name == 'ResultHistoricalAerialLog' || this.route_name == 'TargetDetailAerialLog') {
                this.dateRange.startDate = this.from_date_aerial_log;
                this.dateRange.endDate = this.to_date_aerial_log;

            // } else if (this.route_name == 'ResultProfile' || this.route_name == 'TargetDetailProfile') {
            //     this.dateRange.startDate = this.from_date_profile;
            //     this.dateRange.endDate = this.to_date_profile;

            // } else if (this.route_name == 'ResultDeviceLog' || this.route_name == 'TargetDetailDeviceLog') {
            //     this.dateRange.startDate = this.from_date_device_log;
            //     this.dateRange.endDate = this.to_date_device_log;

            } else if (this.route_name == 'ResultHistoryDeviceRoles' || this.route_name == 'ResultHistoricalHistoryDeviceRoles' || this.route_name == 'TargetDetailHistoryDeviceRoles') {
                this.dateRange.startDate = this.from_date_history_device_roles;
                this.dateRange.endDate = this.to_date_history_device_roles;

            } else if (this.route_name == 'ResultDeviceHistory' || this.route_name == 'ResultHistoricalDeviceHistory' || this.route_name == 'TargetDetailDeviceHistory') {
                this.dateRange.startDate = this.from_date_device_history;
                this.dateRange.endDate = this.to_date_device_history;

            } else if (this.route_name == 'ResultTetheredDevice' || this.route_name == 'ResultHistoricalTetheredDevice' || this.route_name == 'TargetDetailTetheredDevice') {
                this.dateRange.startDate = this.from_date_tethered_device;
                this.dateRange.endDate = this.to_date_tethered_device;
            }

            // console.log({...this.dateRange})
            if (this.dateRange.startDate === null || this.dateRange.endDate === null)
                this.defaultFilter();
        },
        retryChange() {
            if (this.route_name == 'ResultRecentLocation' || this.route_name == 'ResultRealtimeRecentLocation' || this.route_name == 'TargetDetailRecentLocation') {
                // if (this.status_recent_location_recent_location.code === 429)
                //     this.retryShow = true;

            } else if (this.route_name == 'ResultOverall' || this.route_name == 'ResultHistoricalOverall' || this.route_name == 'TargetDetailOverall') {
                if (this.status_overall_app_usage.code === 429)
                    this.retryShow = true;

            } else if (this.route_name == 'ResultLocationMovement' || this.route_name == 'ResultHistoricalLocationMovement' || this.route_name == 'TargetDetailLocationMovement') {
                // if (this.status_location_log_location_log.code === 429)
                //     this.retryShow = true;

            } else if (this.route_name == 'ResultLocationLog' || this.route_name == 'ResultHistoricalLocationLog' || this.route_name == 'TargetDetailLocationLog') {
                // if (this.status_location_log_location_log.code === 429)
                //     this.retryShow = true;

            } else if (this.route_name == 'ResultBrowsingLog' || this.route_name == 'ResultHistoricalBrowsingLog' || this.route_name == 'TargetDetailBrowsingLog') {
                // if (this.status_browsing_log_browsing_log.code === 429)
                //     this.retryShow = true;

            } else if (this.route_name == 'ResultAerialLog' || this.route_name == 'ResultHistoricalAerialLog' || this.route_name == 'TargetDetailAerialLog') {
                if (this.status_aerial_log_aerial_log.code === 429)
                    this.retryShow = true;

            // } else if (this.route_name == 'ResultProfile' || this.route_name == 'TargetDetailProfile') {
                // if (this.status_profile_profile_identity.code === 429 || this.status_profile_profile_family.code === 429)
                //     this.retryShow = true;

            // } else if(this.route_name == 'ResultDeviceLog' || this.route_name == 'TargetDetailDeviceLog') {
            } else if(this.route_name == 'ResultHistoryDeviceRoles' || this.route_name == 'ResultHistoricalHistoryDeviceRoles' || this.route_name == 'TargetDetailHistoryDeviceRoles') {
                // if (this.status_device_history_device_history.code === 429)
                //     this.retryShow = true;
            } else if(this.route_name == 'ResultDeviceHistory' || this.route_name == 'ResultHistoricalDeviceHistory' || this.route_name == 'TargetDetailDeviceHistory') {
                if (this.status_device_history_device_history.code === 429)
                    this.retryShow = true;

            } else if(this.route_name == 'ResultTetheredDevice' || this.route_name == 'ResultHistoricalTetheredDevice' || this.route_name == 'TargetDetailTetheredDevice') {
                if (this.status_tethered_device_tethered_device.code === 429)
                    this.retryShow = true;
            }
        },
        // getFilter() {    
        //     let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
        //     const default_date = this.$store.getters['config/getDefaultDate'](def);
        //     this.dateRange.startDate = this.from_date ?? default_date.from_date;
        //     this.dateRange.endDate = this.to_date ?? default_date.to_date;
        // },
        decryptData() {
            let data = null;

            // if (this.$route.params.key) {
            if (this.$route.params.key) {
                let decrypt_data = decrypt(this.$route.params.key);
                decrypt_data = decrypt_data.split('&&');
                // console.log(decrypt_data);

                var key = decrypt_data[0];
                var type = decrypt_data[1];

                data = { key: key, type: type };
            }
            return data;
        },
        filter() {
            const decrypt_data = this.decryptData();
            if (decrypt_data !== null && this.module.length > 0) {
                let service = '';

                switch(this.route.matched[0].name) {
                    case 'ResultShell':
                        service = 'default';
                        break;

                    case 'ResultRealtimeShell':
                    case 'ResultHistoricalShell':
                        service = 'external';
                        break;
                    
                    case 'pageTargetDetail':
                        service = 'internal';
                        break;
                }

                this.$store.dispatch(this.module + '/getResultData', [decrypt_data.key, decrypt_data.type, service]);
            }
        },
        reset() {
            this.$swal.fire({
                icon: 'warning',
                title: 'Are you sure you want to reset?',
                // text: 'Role : ' + name,
                confirmButtonText: 'Yes!',
                confirmButtonColor: '#DD6B55',
                showCancelButton: true,
                allowOutsideClick: false,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
                    const default_date = this.$store.getters['config/getDefaultDate'](def, this.date_format);

                    this.dateRange.startDate = moment(default_date.from_date, this.date_format).format(this.date_format);
                    this.dateRange.endDate = moment(default_date.to_date, this.date_format).format(this.date_format);

                    this.updateDateRange(default_date.from_date, default_date.to_date);

                    this.filter();
                }
            });
        },
        defaultFilter() {
            let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
            const default_date = this.$store.getters['config/getDefaultDate'](def, this.date_format);

            this.dateRange.startDate = moment(default_date.from_date, this.date_format).format(this.date_format);
            this.dateRange.endDate = moment(default_date.to_date, this.date_format).format(this.date_format);
            // this.dateRange.startDate = moment().subtract(6, 'days').toDate()
            // this.dateRange.endDate = moment().toDate();

            // this.from_date_input = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            // this.to_date_input = moment(this.dateRange.endDate).format('YYYY-MM-DD');

            // this.$store.commit('tetheredDevice/setFromDate', default_date.from_date);
            // this.$store.commit('tetheredDevice/setToDate', default_date.to_date);

            this.updateDateRange(default_date.from_date, default_date.to_date);

        },
        /* eslint-disable no-unused-vars */
        dateFormat (classes, date) {
            if (!classes.disabled) {
                classes.disabled = moment(date.getTime()).subtract({ hours: 12})._d > new Date();
            }
            return classes;
        },
        updateDateRange(from_date, to_date) {
            if (this.module.length > 0) {
                this.$store.commit(this.module + '/setFromDate', from_date);
                this.$store.commit(this.module + '/setToDate', to_date);
            }
        },
        async pickerUpdate(e) {
            let ranges = moment(e.endDate.getTime()).diff(moment(e.startDate.getTime()), 'days') + 1;
            let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)

            console.log({ranges, e})

            // if (ranges > def) {
            if (e.startDate.getTime() > e.endDate.getTime()) {
                this.$swal.fire({
                    icon: 'error',
                    title: 'Start datetime is more than End datetime',
                    text: 'start: '+moment(e.startDate, this.date_format).format(this.date_format)+' > end: ' + moment(e.endDate, this.date_format).format(this.date_format),
                });
                const default_date = this.$store.getters['config/getDefaultDate'](def, this.date_format);
                this.updateDateRange(default_date.from_date, default_date.to_date);
                await this.dateChange();
                return;
            // } else if (this.isAppUsage && ranges > this.$store.getters['config/getValidateRangeAppUsage']) {  
            } else if (this.isAppUsage && ranges > this.$store.getters['config/getValidateRangeQueryAppUsageByRoute'](this.route_name)) {  
                const default_date = this.$store.getters['config/getDefaultDate'](def, this.date_format);
                this.updateDateRange(default_date.from_date, default_date.to_date);
                await this.dateChange();

                // EventEmit.$emit('error', 'Date range is not more than ' + this.$store.getters['config/getValidateRangeAppUsage'] + ' days!');
                this.$swal.fire({
                    icon: 'error',
                    title: 'Date range is not more than ' + this.$store.getters['config/getValidateRangeQueryAppUsageByRoute'](this.route_name) + ' days!',
                    text: 'Your date range is ' + ranges + ' days.',
                });
                return;
            }
            // else if (ranges > this.$store.getters['config/getValidateRange']) {  
            else if (ranges > this.$store.getters['config/getValidateRangeQueryByRoute'](this.route_name)) {  
                const default_date = this.$store.getters['config/getDefaultDate'](def, this.date_format);
                this.updateDateRange(default_date.from_date, default_date.to_date);
                await this.dateChange();

                // EventEmit.$emit('error', 'Date range is not more than ' + this.$store.getters['config/getValidateRange'] + ' days!');
                this.$swal.fire({
                    icon: 'error',
                    title: 'Date range is not more than ' + this.$store.getters['config/getValidateRangeQueryByRoute'](this.route_name) + ' days!',
                    text: 'Your date range is ' + ranges + ' days.',
                });
                return;
            }

            this.updateDateRange(moment(this.dateRange.startDate).format(this.date_format), moment(this.dateRange.endDate).format(this.date_format));
        },
    },
    // filters: {
    //     date: function (date) {
    //         return moment(date).format(this.date_format);
    //     }
    // },
    async created() {
        await this.setModule();
    },
    async mounted() {
        this.dateChange();
        this.retryChange(); 
    },
}
</script>