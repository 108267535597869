<template lang="pug">
.blank-layout
    //- | this is for auth

    router-view

</template>

<script>
import SecureLS from "secure-ls";

export default {
    name: 'BlankLayout',
    data(){
        return {
            // 
        }
    },
    methods : {
        // 
    },
    async mounted() {
    	// console.log(JSON.parse(localStorage.getItem('vuex')))
    	// console.log('localStorage')
        // const ls = new SecureLS({ isCompression: false });

        // console.log(localStorage.getItem('vuex'))
        // console.log(JSON.parse(ls.get('vuex')).auth.token)
    },
}
</script>
