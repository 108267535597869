<template lang="pug">
.page-aerial-log
    ActionSearchResult(
        v-if="status === false && $route.matched[0].name !== 'pageTargetDetail'"
        :title="title"
    )

    template(v-else)
        //- title
        TitleResult(:title="title")
        
        //- filter advance
        FilterAdvanceResult(v-if="showAdvanceFilter")
        //- filter
        FilterResult(v-else)
        //- content 
        BoxMaps
        TimelineActivity 
        AerialTop
    

</template>

<script>
import TitleResult from '@/components/result/Title.vue';
import ActionSearchResult from '@/components/result/ActionSearchResult.vue'
import FilterResult from '@/components/result/Filter.vue';
import FilterAdvanceResult from '@/components/result/FilterAdvance.vue';
import IconSet from '@/components/IconSet.vue'
import BoxMaps from './BoxMaps.vue'
import TimelineActivity from './TimelineActivity.vue'
import AerialTop from './aerialTop/Index.vue'
import { mapState } from 'vuex';
import Loading from '@/components/Loading.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import moment from 'moment';
import { decrypt } from '@/util/crypto';
import { env } from '@/config/env';

export default {
    name: 'ResultAerialLog',
    components: {
        TitleResult,
        ActionSearchResult,
        FilterResult,
        FilterAdvanceResult,
        IconSet,
        BoxMaps,
        TimelineActivity,
        AerialTop,
        Loading,
        NoDataAvailable,
        DateRangePicker,
    },
    data(){
        return {
            title:"Aerial Log",
            showFilter: false,

            dateRange: {
                startDate: '',
                endDate: '',
            },
            default_ranges: {
                'Today': [moment().toDate(), moment().toDate()],
                'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                'Last 7 days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                'Last week': [moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(), moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate()],
            },
        };
    },
    computed: {
        ...mapState('aerialLog', [
            'status',
            'from_date',
            'to_date',
            'status_aerial_log',
            'aerial_log',
        ]),
        key() {
            return this.$route.params.key;
        },
        isKey() {
            return this.key ? true : false;
        },
        route() {
            return this.$route;
        },
        route_name() {
            return this.$route.name;
        },
        showAdvanceFilter(){
            return (this.$route.matched[0].name == 'pageTargetDetail' && env.VUE_APP_ADVANCE_FILTER_AERIAL_TARGET)
        }
    },
    watch: {
        aerial_log() {
        },
        google() {},
        key() {},
        isKey() {},
        from_date(newData, oldData) {
            if (newData !== oldData)
                this.getFilter();
        },
        to_date(newData, oldData) {
            if (newData !== oldData)
                this.getFilter();
        },
    },
    methods: {
        getFilter() {
            let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
            const default_date = this.$store.getters['config/getDefaultDate'](def);
            this.dateRange.startDate = this.from_date ?? default_date.from_date;
            this.dateRange.endDate = this.to_date ?? default_date.to_date;
        },
        decryptData() {
            let data = null;

            if (this.$route.params.key) {
                let decrypt_data = decrypt(this.$route.params.key);
                decrypt_data = decrypt_data.split('&&');
                // console.log(decrypt_data);

                var key = decrypt_data[0];
                var type = decrypt_data[1];

                data = { key: key, type: type };
            }
            return data;
        },
        filter() {
            const decrypt_data = this.decryptData();
            if (decrypt_data !== null)
                this.$store.dispatch('aerialLog/getResultData', [decrypt_data.key, decrypt_data.type]);
        },
        reset() {
            this.$swal.fire({
                icon: 'warning',
                title: 'Are you sure you want to reset?',
                // text: 'Role : ' + name,
                confirmButtonText: 'Yes!',
                confirmButtonColor: '#DD6B55',
                showCancelButton: true,
                allowOutsideClick: false,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
                    const default_date = this.$store.getters['config/getDefaultDate'](def);

                    this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
                    this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();

                    this.$store.commit('aerialLog/setFromDate', default_date.from_date);
                    this.$store.commit('aerialLog/setToDate', default_date.to_date);

                    this.filter();
                }
            });
        },
        defaultFilter() {
            let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
            const default_date = this.$store.getters['config/getDefaultDate'](def);

            this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
            this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();
            // this.dateRange.startDate = moment().subtract(6, 'days').toDate()
            // this.dateRange.endDate = moment().toDate();

            // this.from_date_input = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            // this.to_date_input = moment(this.dateRange.endDate).format('YYYY-MM-DD');

            this.$store.commit('aerialLog/setFromDate', default_date.from_date);
            this.$store.commit('aerialLog/setToDate', default_date.to_date);
        },
        /* eslint-disable no-unused-vars */
        dateFormat (classes, date) {
            if (!classes.disabled) {
                classes.disabled = moment(date.getTime()).subtract({ hours: 12})._d > new Date();
            }
            return classes;
        },
        async pickerUpdate(e) {
            // let ranges = moment(e.endDate.getTime()).diff(moment(e.startDate.getTime()), 'days') + 1;

            // this.from_date_input = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            // this.to_date_input = moment(this.dateRange.endDate).format('YYYY-MM-DD');

            this.$store.commit('aerialLog/setFromDate', moment(this.dateRange.startDate).format('YYYY-MM-DD'));
            this.$store.commit('aerialLog/setToDate', moment(this.dateRange.endDate).format('YYYY-MM-DD'));

        },
    },
    filters: {
        date: function (date) {
            return moment(date).format('YYYY-MM-DD');
        }
    },
    created() {
        // 
    },
    mounted() {
        this.getFilter();
    },
}
</script>