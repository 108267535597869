<template lang="pug">
.box__body
    .card.card--table.card--no-bg
        .card__header.card--table__header
            h2.text-xl {{ page_title }}
            .header-cta
                .block
                    router-link.btn-default.m-btn-icon(to="/tactical-device")
                        IconSet(type="chevron-left")
                        span.m-hide.block.ml-3 Back
        .card__body
            form.container-form
                .group-fields.border-b
                    .field
                        .field__label
                            label.block.text-sm.font-bold Name <sup>*</sup>
                        .field__form
                            input.field-style(type="text" placeholder="Write name here" v-model="model.name")
                            .py-3
                                .message.text-xs.text-red-500(v-if="$v.model.name.$error") This name is required &amp; max length is 255 char.
                                .message.text-xs.text-red-500(v-if="error_name !== ''") {{ error_name }}

                    .field
                        .field__label
                            label.block.text-sm.font-bold Device ID (IMEI) <sup>*</sup>
                        .field__form
                            input.field-style(type="text" placeholder="Write Device ID here" v-model="model.device_id" @keydown.space.prevent)
                            .py-3
                                .message.text-xs.text-red-500(v-if="$v.model.device_id.$error") This device id is required &amp; max length is 255 char.
                                .message.text-xs.text-red-500(v-if="error_device_id !== ''") {{ error_device_id }}

                    .field
                        .field__label
                            label.block.text-sm.font-bold User
                        .field__form
                            .relative
                                input.field-style#find-user-input(type="text" placeholder="Search user here (write min 3 characters)" v-model="users_keyword" @keyup="searchUsers()")
                                

                                .field-icon(v-if="Object.keys(user_selected).length == 0")
                                    IconSet(type="search")

                                .field-style--mirroring.absolute.top-0(v-else)
                                    .flex.items-center.justify-between
                                        .flex.items-center
                                            figure.w-6.h-6.mr-4
                                                img.w-full.h-full.object-cover.rounded-full.bg-gray-100(:src="imgAvatar")
                                            span.text-light.name-index {{ user_selected.name ? user_selected.name : '' }}
                                        button.btn-icon-24(v-if="user_selected.name" @click="setEmptyUser" v-tooltip="'Remove user'")
                                            IconSet(type="close")
                            .py-3
                                .message.text-xs.text-red-500(v-if="$v.model.user.$error") This user max length is 255 char.
                                .message.text-xs.text-red-500(v-if="error_user !== ''") {{ error_user }}

                            .pt-3
                                .flex.items-center.w-full.mb-4.mt-4.justify-center(v-if="status_tactical_list.status === 'loading'")
                                    .flex.flex-wrap.items-center.justify-center
                                        Spinner(show="true" size="48")

                                template(v-else-if="tactical_list.data && tactical_list.data.length > 0")
                                    .small-heading Choose a User
                                    .box-users
                                        .item-user(
                                            v-tooltip="'Choose this user'"
                                            v-for="(user,index) in tactical_list.data"
                                            :key="user.id"
                                            @click="chooseUser(user)"
                                        )
                                            figure.mr-3
                                                img.w-6.h-6.rounded-full.object-fit(:src="imgAvatar")
                                            span {{ user.name }}

                                        //- .item-user
                                        //-     figure.mr-3
                                        //-         img.w-6.h-6.rounded-full.object-fit(src="https://randomuser.me/api/portraits/men/41.jpg")
                                        //-     span Raul Andrews

                                        //- .item-user
                                            figure.mr-3
                                                img.w-6.h-6.rounded-full.object-fit(src="https://randomuser.me/api/portraits/men/41.jpg")
                                            span Raul Andrews

                                .flex.items-center.w-full.justify-center(v-else-if="tactical_list.data && tactical_list.data.length == 0")
                                    span No data available.


                .group-fields.border-b
                    .field
                        .field__label
                            label.block.text-sm.font-bold Description
                        .field__form
                            textarea.field-style(placeholder="Write description here" v-model="model.description" style="min-height: 200px;")
                            .pt-3
                                //- .message.text-xs.text-red-500(v-if="$v.model.description.$error") This description is required &amp; max length is 255 char.
                                .message.text-xs.text-red-500(v-if="error_description !== ''") {{ error_description }}


                .flex.items-center.justify-end.mb-8
                    button.btn.btn-default(type="button" @click="reset()")
                        | Reset
                    button.btn-primary-o.ml-2(type="submit" @click="submit($event, true)")
                        | Save &amp; Continue
                    button.btn.btn-primary.ml-4(type="submit" @click="submit($event)")
                        | Save

</template>

<script>
import { mapState } from 'vuex';
import IconSet from '@/components/IconSet.vue';
import Spinner from '@/components/Spinner.vue';
import { required, maxLength } from "vuelidate/lib/validators";

export default {
    name: 'TacticalDevicesCreate',
    components: {
        IconSet,
        Spinner,
    },
    data() {
        return {
            model: {
                name: '',
                device_id: '',
                user: '',
                description: '',
            },

            // error message
            error_name: '',
            error_device_id: '',
            error_user: '',
            error_description: '',

            users_keyword: '',
            user_selected: {},
            timeout: null,

            // ImgAvatar: require('@/assets/images/default-avatar.png'),
            imgAvatar: '/images/default-avatar.png',
        }
    },
    validations: {
        model: {
            name: {
                required,
                maxLength: maxLength(255)
            },
            device_id: {
                required,
                maxLength: maxLength(255)
            },
            user: {
                maxLength: maxLength(255)
            },
            description: {
                // 
            },
        },
    },
    computed: {
        ...mapState('tacticalDevice', [
            'detail',
        ]),
        ...mapState('users', [
            'status_tactical_list',
            'tactical_list',
        ]),
        route_name() {
            return this.$route.name;
        },
        id() {
            return this.$route.params.id;
        },
        page_title() {
            let title = 'Edit Tactical Device';
            if (this.route_name === 'pageTacticalDeviceCreate')
                title = 'Add Tactical Device';
            return title;
        },
    },
    watch: {
        route_name() {
            this.getData();
        },
        id() {},
        page_title() {},
        // form data
        detail(newData) {
            this.model.name = (newData.name ? newData.name : '');
            this.model.device_id = (newData.device_id ? newData.device_id : '');
            if (newData.users && newData.users[0]) {
                this.user_selected = newData.users[0];
                this.model.user = newData.users[0].id;
            } else {
                this.user_selected = {};
                this.model.user = '';
            }
            this.model.description = (newData.description ? newData.description : '');
        },
        users_keyword(newData) {
            if (newData.length < 3)
                this.$store.commit('users/setTacticalList', {});
        },
    },
    methods: {
        changeImgAvatar(e) {
            const file = e.target.files[0];
            this.imgAvatar = URL.createObjectURL(file);
        },
        searchUsers() {
            // clear timeout variable
            clearTimeout(this.timeout);

            var self = this;
            this.timeout = setTimeout(function () {
                if (self.users_keyword.length > 2) {
                    self.$store.commit('users/setTacticalList', {});
                    self.$store.dispatch('users/getTacticalList', [30, 1, self.users_keyword]);
                }
            }, 650);
        },
        chooseUser(user) {
            this.user_selected = user;
            this.model.user = user.id;

            // set empty search user keyword
            this.users_keyword = '';
        },
        setEmptyUser() {
            this.user_selected = {};
            this.model.user = '';
            document.getElementById('find-user-input').focus();
        },
        setEmptyModel(all=true) {
            for (let key in Object.keys(this.model)) {
                if (all === true)
                    this.model[Object.keys(this.model)[key]] = '';
            }

            this.users_keyword = '';
            this.user_selected = {};
            this.$store.commit('users/setTacticalList', {});
        },
        setErrors(empty=true, errors=null) {
            if (empty === true) {
                this.error_name = '';
                this.error_device_id = '';
                this.error_user = '';
                this.error_description = '';
            } else if (empty !== true && errors !== null) {
                this.error_name = (errors.name ? errors.name : '');
                this.error_device_id = (errors.device_id ? errors.device_id : '');
                this.error_user = (errors.user ? errors.user : '');
                this.error_description = (errors.description ? errors.description : '');
            }
        },
        getData() {
            this.reset(true);

            if (this.route_name === 'pageTacticalDeviceEdit') {
                EventEmit.$emit('showLoading', true);

                this.$store.dispatch('tacticalDevice/getDetail', this.id)
                .then((resp) => {
                    if (resp === 'success') {
                        EventEmit.$emit('showLoading', false);
                    } else {
                        EventEmit.$emit('error');
                    }
                });
            } else {
                if (this.route_name === 'pageTacticalDeviceCreate')
                    this.$store.commit('tacticalDevice/setDetail', {});
            }
        },
        reset(direct=false) {
            if (direct === true) {
                this.setEmptyModel(false);
                // reset vuelidate
                this.$nextTick(() => { this.$v.$reset(); });
                this.setErrors(true);
                window.scrollTo(0,0);
            } else {
                this.$swal.fire({
                    icon: 'warning',
                    title: 'Are you sure you want to reset form?',
                    // text: id,
                    confirmButtonText: 'Yes!',
                    confirmButtonColor: '#DD6B55',
                    showCancelButton: true,
                    allowOutsideClick: false,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        if (this.route_name === 'pageTacticalDeviceCreate') {
                            this.setEmptyModel(false);
                            // reset vuelidate
                            this.$nextTick(() => { this.$v.$reset(); });
                            this.setErrors(true);
                            window.scrollTo(0,0);
                        } else {
                            this.getData();
                        }
                    }
                });
            }
        },
        submit(e, save_continue=false) {
            e.preventDefault();
            this.$v.$touch();

            if (this.$v.model.$invalid) {
                for (let key in Object.keys(this.$v.model)) {
                    const input = Object.keys(this.$v.model)[key];
                    if (input.includes("$")) return false;
                    if (this.$v.model[input].$error) {
                        break;
                    }
                }
                // EventEmit.$emit('error', 'Please fill the form correctly.');
                // EventEmit.$emit('showLoading', false);
                window.scrollTo(0,0);
                return;
            }

            EventEmit.$emit('showLoading', true);
            let model_data = Object.keys(this.model).map((key) => this.model[key]);

            if (this.route_name === 'pageTacticalDeviceCreate') {

                this.$store.dispatch('tacticalDevice/create', model_data)
                .then((resp) => {
                    if (resp.result === 'success') {
                        if (save_continue === false) {
                            this.$router.push('/tactical-device');
                        } else {
                            this.$router.push('/tactical-device/'+ resp.data.data.id +'/edit');
                        }

                        this.$swal.fire({
                            icon: 'success',
                            title: 'Data successfully created!',
                            timer: 3000,
                        });
                    } else {
                        if (resp.data.message && resp.data.message.errors) {
                            this.setErrors(false, resp.data.message.errors);
                            // EventEmit.$emit('error', null, resp.data.message.errors);
                            EventEmit.$emit('showLoading', false);
                            window.scrollTo(0,0);
                        } else {
                            EventEmit.$emit('error');
                        }
                    }
                });

            // pageTacticalDeviceEdit
            } else {

                model_data = [this.id].concat(model_data);

                this.$store.dispatch('tacticalDevice/update', model_data)
                .then((resp) => {
                    if (resp.result === 'success') {                        
                        if (save_continue === false)
                            this.$router.push('/tactical-device');

                        this.$swal.fire({
                            icon: 'success',
                            title: 'Data successfully updated!',
                            timer: 3000,
                        });
                    } else {
                        if (resp.data.message && resp.data.message.errors) {
                            this.setErrors(false, resp.data.message.errors);
                            // EventEmit.$emit('error', null, resp.data.message.errors);
                            EventEmit.$emit('showLoading', false);
                            window.scrollTo(0,0);
                        } else {
                            EventEmit.$emit('error');
                        }
                    }
                });
            }
        },
    },
    created() {
        // 
    },
    mounted() {
        this.getData();
    },
}
</script>

<style lang="sass" scoped>
    .fields-box
        @apply .rounded
        background-color: $color-secondary-600
        &__item
            @apply .p-4 .rounded .flex .border-b
            &:last-child
                border-bottom: 0px
            .field-options
                input
                    margin-top: 1rem
                    &:first-child
                        margin-top: 0px
    .box-users
        .item-user
            @apply .flex .items-center .p-4 .border-b .cursor-pointer
            &:hover 
                background-color: $color-secondary-600
            &:last-child
                border-bottom: 0px
</style>
