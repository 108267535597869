<template lang="pug">
.card.card--tab
    .card--tab__header
        h2.text-xl Device Daily Usage
        .header-cta
            
    .card--tab__body
        .box-tabs
            vue-tabs
                v-tab(title="Applications")
                    OverallDataUsageApp
                v-tab(title="Categories")
                    OverallDataUsageCategories
                v-tab(title="Total")
                    OverallDataUsageTotal

</template>

<script>
import {VueTabs, VTab} from 'vue-nav-tabs'
import OverallDataUsageApp from './OverallDataUsageApp.vue'
import OverallDataUsageCategories from './OverallDataUsageCategories.vue'
import OverallDataUsageTotal from './OverallDataUsageTotal.vue'
export default {
    name: 'OverallDataUsage',
    components: {
        VueTabs,
        VTab,
        OverallDataUsageApp,
        OverallDataUsageCategories,
        OverallDataUsageTotal,
    }
}
</script>