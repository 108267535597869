<template lang="pug">
.box__body
    .card.card--table.card--no-bg
        .card__header.card--table__header
            h2.text-xl {{ page_title }}
            .header-cta
                .block
                    router-link.btn-default.m-btn-icon(to="/digital-cyber")
                        IconSet(type="chevron-left")
                        span.m-hide.block.ml-3 Back
        .card__body
            .container-form
                .group-fields.border-b
                    .field
                        .field__label
                            label.block.text-sm.font-bold Type <sup>*</sup>

                        .field__form
                            .relative
                                select.select-type.field-style(v-model="model.type")
                                    option(v-for="(t,index) in list_type" :key="index" :value="index") {{ t }}
                                .field-icon
                                    IconSet(type="chevron-down")
                    .field
                        .field__label
                            label.block.text-sm.font-bold Value <sup>*</sup>
                        .field__form
                            input.field-style(type="text" placeholder="Write Value here" v-model="model.value_data")
                            .pt-3
                                .message.text-xs.text-red-500(v-if="$v.model.value_data.$error") This value is required &amp; max length is 255 char.
                                .message.text-xs.text-red-500(v-if="error_value_data !== ''") {{ error_value_data }}
                    .flex.items-center.mb-6
                        // .block(v-if="isWatchlistQuota")
                        .block
                            button.btn-default Quota: {{ deepvoid_quota.status === 'loading' ? 'loading...' : (deepvoid_quota.usage || deepvoid_quota.usage == 0 ? numberWithDot(deepvoid_quota.usage) : '-') }}
                            // button.btn-default Watchlist: loading ...
                            
                    
                .flex.items-center.justify-end.mb-8
                    button.btn.btn-default(type="button" @click="reset()")
                        | Reset
                    button.btn.btn-primary.ml-4(
                        v-if="checkFeatureByAcl('profiling_target', null)"
                        type="submit" @click="submit($event)"
                    )
                        | Save

            // .container-form
            .container-form(style="max-width:100%;")
                .group-fields.border-b
                    .field
                        .field__label
                            label.block.text-sm.font-bold Response [{{ resp_deep_void && resp_deep_void.code ? resp_deep_void.code : '' }}]
                        .field__form(v-if="resp_deep_void && resp_deep_void.data")
                            // label.block.text-sm.font-bold {{ resp_deep_void && resp_deep_void.data ? resp_deep_void.data : '' }}
                            // vue-json-pretty(:data="resp_deep_void_data")
                            json-viewer(:value="resp_deep_void.data" copyable boxed sort)

</template>

<script>
import IconSet from '@/components/IconSet.vue';
import Spinner from '@/components/Spinner.vue';
import { mapState } from 'vuex';
import { required, maxLength } from "vuelidate/lib/validators";
import axios from "@/axios-main";
// import VueJsonPretty from 'vue-json-pretty'
// import 'vue-json-pretty/lib/styles.css'
// import JsonViewer from 'vue-json-viewer'
import JsonViewer from 'vue-json-viewer/ssr'
import 'vue-json-viewer/style.css'

export default {
    name: 'pageDeepVoidForm',
    components: {
        IconSet,
        Spinner,
        JsonViewer,
        // VueJsonPretty,
        // Multiselect,
    },
    data() {
        return {
            model: {
                type: 'email',
                value_data: '',
            },

            list_type: {
                email: 'Email',
                phoneNumber: 'Phone Number',
                hashPassword: 'Hash Password',
                password: 'Password',
                username: 'Username',
                ipAddress: 'IP Address',
                domain: 'Domain',
                nationalId: 'National ID',
            },

            // error message
            error_value_data: '',

            url_deep_void: '',
            resp_deep_void: {
                code:'',
                data:null,
            },
            resp_deep_void_data: null,
            deepvoid_quota: {
                usage: '-',
                status: 'else',
            }
        }
    },
    validations: {
        model: {
            value_data: {
                required,
                maxLength: maxLength(255)
            },
        },
    },
    computed: {
        ...mapState('auth', ['user']),
        route_name() {
            return this.$route.name;
        },
        page_title() {
            let title = 'Digital Cyber Data';
            return title;
        },
    },
    watch: {
        route_name() {
        },
        id() {},
        page_title() {},
    },
    methods: {
        setEmptyModel(all=true) {
            for (let key in Object.keys(this.model)) {
                if (all === true)
                    if (Object.keys(this.model)[key]==='type')
                        this.model[Object.keys(this.model)[key]] = 'email';
                    else
                        this.model[Object.keys(this.model)[key]] = '';
            }
            this.url_deep_void = ''
            this.resp_deep_void = {
                code:'',
                data:null,
            }
        },
        setErrors(empty=true, errors=null) {
            if (empty === true) {
                this.error_value_data = '';
            } else if (empty !== true && errors !== null) {
                this.error_value_data = errors;
            }
        },
        reset(direct=false) {
            this.setEmptyModel();
            // reset vuelidate
            this.$nextTick(() => { this.$v.$reset(); });
            this.setErrors(true);
            window.scrollTo(0,0);
        },
        submit(e, save_continue=false) {
            e.preventDefault();
            this.$v.$touch();

            if (this.$v.model.$invalid) {
                for (let key in Object.keys(this.$v.model)) {
                    const input = Object.keys(this.$v.model)[key];
                    // console.log({input})
                    if (input.includes("$")) return false;
                    if (this.$v.model[input].$error) {
                        break;
                    }
                }
                window.scrollTo(0,0);
                return;
            }
            
            EventEmit.$emit('showLoading', true);

            let token = "68316818-3ba3-4b51-964b-014a0764a095"
            //let base_url_api = "https://kraken-api.com/"
            let url_api = "api/v1/deep-void/get?token="+token+"&"
            
            if (this.model.type==='email')
                url_api += "Email="+this.model.value_data
            else if (this.model.type==='phoneNumber')
                url_api += "mobileNumber="+this.model.value_data
            else if (this.model.type==='hashPassword')
                url_api += "hashedPassword="+this.model.value_data
            else if (this.model.type==='password')
                url_api += "Password="+this.model.value_data
            else if (this.model.type==='username')
                url_api += "userName="+this.model.value_data
            else if (this.model.type==='ipAddress')// TODO
                url_api += "IPaddress="+this.model.value_data
            else if (this.model.type==='domain')
                url_api += "Domain="+this.model.value_data
            else if (this.model.type==='nationalId')
                url_api += "nationalID="+this.model.value_data

            this.url_deep_void = url_api
            let type = this.model.type
            let value_data = this.model.value_data
            // console.log({url_api, type, value_data})
            this.deepvoid_quota.status = 'loading'

            axios.get(url_api)
            .then((resp) => {
                // console.log('then')
                // console.log({resp})
                let data = resp.data;
                // console.log({data})
                // console.log(data.data)
                // let response = resp.response;
                // let status = resp.status;
                // let message = resp.message;
                // let responseCode = (resp.response && resp.response.status ? resp.response.status : null)
                // let responseMessage = (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null)
                // console.log({resp, data, response, responseCode, responseMessage, message, status})
                // if (data.data) {
                //     var msg = data.data
                //     msg = msg.replace(/'/g, '"').replace(/None/g, '"None"').replace(/False/g, '"False"').replace(/'/g, '"').replace(/True/g, '"True"')
                //     data.data = JSON.parse(msg)
                // }
                this.resp_deep_void = data
                this.resp_deep_void_data = data.data
                // this.resp_deep_void = resp.data
                // console.log('this.resp_deep_void')
                // console.log(this.resp_deep_void)
                // this.resp_deep_void_data = resp.data.data
                this.getLimit()
                
                EventEmit.$emit('showLoading', false);
            })
            .catch((resp) => {
                // console.log('catch')
                // console.log({resp})
                let data = resp.data;
                // console.log({data})
                // console.log(data.data)
                // let response = resp.response;
                // let status = resp.status;
                // let message = resp.message;
                let responseCode = (resp.response && resp.response.status ? resp.response.status : null)
                // let responseMessage = (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null)
                // console.log({resp, data, response, responseCode, responseMessage, message, status})
                if (responseCode && responseCode==500){
                    this.deepvoid_quota.status = 'else'
                } else {
                    this.getLimit()
                }
                
                this.resp_deep_void = data
                // this.resp_deep_void = {resp, data, response, responseCode, responseMessage, message, status}
                // this.resp_deep_void = resp.response
                
                EventEmit.$emit('showLoading', false);
            });

        },
        getLimit() {

            let token = "68316818-3ba3-4b51-964b-014a0764a095"
            //let base_url_api = "https://kraken-api.com/"
            let url_api = "api/v1/deep-void/get?tokenstats="+token

            this.url_deep_void = url_api
            let type = this.model.type
            let value_data = this.model.value_data
            // console.log({url_api, type, value_data})

            axios.get(url_api)
            .then((resp) => {
                // console.log('then')
                // console.log({resp})
                let data = resp.data;
                // console.log({data})
                // console.log(data.data)

                this.deepvoid_quota.usage = data && data.data && data.data.availableCredit? data.data.availableCredit : (this.deepvoid_quota.usage!='-'?this.deepvoid_quota.usage:'-')
                
                this.deepvoid_quota.status = 'else'
            })
            .catch((resp) => {
                // console.log('catch')
                // console.log({resp})
                let data = resp.data;
                // console.log({data})
                // console.log(data.data)
                this.deepvoid_quota.usage = data && data.data && data.data.availableCredit? data.data.availableCredit : (this.deepvoid_quota.usage!='-'?this.deepvoid_quota.usage:'-')
                
                this.deepvoid_quota.status = 'else'
            });

        }
    },
    created() {
    },
    mounted() {
        this.getLimit()
    },
}
// </script>

// <style lang="sass" scoped>
//     .fields-box
//         @apply .rounded
//         background-color: $color-secondary-600
//         &__item
//             @apply .p-4 .rounded .flex .border-b
//             &:last-child
//                 border-bottom: 0px
//             .field-options
//                 input
//                     margin-top: 1rem
//                     &:first-child
//                         margin-top: 0px
//     .targets-case
//         @apply .w-full
//         .target-item
//             @apply .flex .items-center .p-3 .w-full .rounded .cursor-pointer
//             transition: all 0.3s ease-in-out
//             &:hover
//                 background-color: $color-secondary-800

// </style>
