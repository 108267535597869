<template lang="pug">
.case-management-index
    .box__body
        .card.card--table.card--no-bg
            .card__header.card--table__header
                h2.text-xl Lists of Tactical Devices
                .header-cta
                    .block
                        router-link.btn-primary.m-btn-icon(
                            :to="{ name: 'pageTacticalDeviceCreate', params: {} }"
                        )
                            IconSet(type="add")
                            span.m-hide.ml-3 Add Tactical Device
            .card--table__body
                .box-filter-table.p-4.pt-0
                    .field(class="lg:mr-3 lg:mb-0 lg:w-64 mr-0 w-full mb-4")
                        input.field-style(
                            v-model="keyword"
                            type="text"
                            placeholder="Search here..."
                            @keyup="searchKeyword"
                        )
                        .field-icon
                            IconSet(
                                type="search"
                            )
                    .field(class="lg:mr-3 lg:mb-0 lg:w-32 mr-0 w-full mb-4")
                        select.field-style(@change="sizeChange($event)" v-model="items_per_page")
                            option(v-for="(s,index) in size" :key="index" :value="s") {{ s }}
                        .field-icon
                            IconSet(
                                type="chevron-down"
                            )
                .box-table.box-table--target
                    table.table
                        thead.m-hide
                            tr
                                th(v-for="(th,index) in tableThead" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}

                        tbody
                            tr(v-if="status_list.status === 'loading'")
                                td.text-center(:colspan="tableThead.length")
                                    .flex.flex-wrap.items-center.justify-center
                                        Spinner(show="true" size="48")
                            template(v-else-if="list && list.data && list.data.length > 0")
                                tr(v-for="(data,index) in list.data" :key="data.id")
                                    td
                                        .text-sm {{ data.name ? data.name : '-' }}
                                    td
                                        .text-sm {{ data.device_id ? data.device_id : '-' }}
                                    td
                                        .text-sm {{ data.users && data.users[0] && data.users[0].name ? data.users[0].name : '-' }}
                                    td
                                        .w-64
                                            .text-sm {{ data.description ? (data.description.length > 16 ? data.description.substring(0, 16)+'...' : data.description) : '-' }}
                                    td
                                        .flex.items-center.justify-end
                                            router-link.btn-icon-40.m-hide(
                                                :to="'/tactical-device/'+data.id+'/edit'"
                                                v-tooltip="'Edit'"
                                            )
                                                IconSet(type="edit")
                                            button.btn-icon-40.m-hide(
                                                @click="deleteData(data.id, data.name)"
                                                v-tooltip="'Delete'"
                                            )
                                                IconSet(type="delete")

                            tr(v-else)
                                td.text-center(:colspan="tableThead.length") No Data Available                    

                .box-table-footer
                    .pagination
                        vue-ads-pagination(
                            :total-items="total_data"
                            :items-per-page="items_per_page"
                            :page="page"
                            :max-visible-pages="5"
                        )
                            template(slot="buttons" slot-scope="props")
                                vue-ads-page-button(
                                    v-for="(button, key) in props.buttons"
                                    :key="key"
                                    v-bind="button"
                                    :class="{'bg-yellow-dark': button.active}"
                                    @page-change="page = button.page"
                                    @range-change="start = button.start; end = button.end" 
                                )

</template>

<script>
import { mapState } from 'vuex';
import IconSet from '@/components/IconSet.vue';
import Spinner from '@/components/Spinner.vue';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
// import '../../../node_modules/vue-ads-pagination/dist/vue-ads-pagination.css';

export default {
    name: 'PageTecticalDevicesIndex',
    components: {
        IconSet,
        Spinner,
        VueAdsPagination,
        VueAdsPageButton,
    },
    data() {
        return {
            tableThead: [
                {
                    name: 'Device Name',
                    // number: true,
                },{
                    name: 'Device ID (IMEI)',
                },{
                    name: 'User',
                },{
                    name: 'Description',
                },{
                    name: '',
                    action: true,
                },
            ],
            
            total_data: 0,
            items_per_page: 10,

            size: [10, 25, 50, 100],

            page: 0,
            start: 0,
            end: 0,

            keyword: '',
            timeout: null,
        };
    },
    computed: {
        ...mapState('tacticalDevice', [
            'status_list',
            'list',
        ]),
        route_name() {
            return this.$route.name;
        },
    },
    watch: {
        route_name(newData, oldData) {
            if (newData === 'pageTacticalDeviceIndex') {
                if (oldData !== 'pageTacticalDeviceIndex') {
                    this.items_per_page = 10;
                    this.page = 0;
                    this.keyword = '';
                    this.$store.commit('tacticalDevice/setList', {});
                }

                // this.$store.commit('tacticalDevice/setStatusList', { status: false, cancel: null, code: null });
                this.getData();
            }
        },
        list(newData) {
            if (Object.keys(newData).length > 0) {
                this.total_data = parseInt(newData.total_data);
                // this.page = (parseInt(newData.current_page) - 1);
            } else {
                this.total_data = 0;
            }
        },
        items_per_page() {
            this.getData();
        },
        page() {
            this.getData();
        },
    },
    methods: {
        getData() {
            // for dispatch get data
            if (this.route_name === 'pageTacticalDeviceIndex') {

                this.$store.dispatch('tacticalDevice/getList', [this.items_per_page, (this.page + 1), this.keyword]);

                // if (this.list.total_page && this.list.total_page > 1)
                    // this.pagination();
            }
        },
        deleteData(id, name) {
            this.$swal.fire({
                icon: 'warning',
                title: 'Are you sure you want to delete?',
                text: 'Tactical Device : ' + name,
                confirmButtonText: 'Yes!',
                confirmButtonColor: '#DD6B55',
                showCancelButton: true,
                allowOutsideClick: false,
            })
            .then((result) => {

                if (result.isConfirmed) {
                    EventEmit.$emit('showLoading', true);

                    this.$store.dispatch('tacticalDevice/delete', id)
                    .then((resp) => {
                        if (resp === 'success') {
                            this.page = 0;

                            this.getData();

                            this.$swal.fire({
                                icon: 'success',
                                title: 'Data successfully deleted!',
                                timer: 3000,
                            });
                        } else {
                            if (resp.data && resp.data.message) {
                                EventEmit.$emit('error', resp.data.message);
                            } else {
                                EventEmit.$emit('error');
                            }
                        }
                    });
                }
            });
        },
        pageChange(page) {
            this.page = page;
        },
        rangeChange(start, end) {
            this.start = start;
            this.end = end;
        },
        sizeChange() {
            this.page = 0;
        },
        searchKeyword() {
            clearTimeout(this.timeout);            
            var self = this;
            this.timeout = setTimeout(function () {
                self.page = 0;
                self.$store.commit('tacticalDevice/setList', {});
                self.getData();
            }, 650);
        },
    },
    created() {
        this.getData();
    },
    mounted() {
        // 
    },
}
</script>

<style lang="sass" scoped>
    .note
        &:hover
            text-decoration: underline
            cursor: pointer

</style>