import axios from "@/axios-main";
import axiosOrigin from "axios";

let cancelWatchlistQuota;
var cancelToken = axiosOrigin.CancelToken;

const watchlist = {
	namespaced: true,
	state: {
		status_watchlist_quota: {
			status: false,
			cancel: null,
			code: null,
		},

		watchlist_quota: {},
	},
	mutations: {
		setStatusWatchlistQuota: (state, status) => {
			state.status_watchlist_quota = status;
		},

		setRemainingQuota: (state, data) => {
			state.watchlist_quota = data;
		},
	},
	getters: {},
	actions: {
		async getQuota({state, commit, dispatch}) {
			if (state.status_watchlist_quota.cancel != null && state.status_watchlist_quota.status === 'loading')
				await state.status_watchlist_quota.cancel('Operation canceled by the user.');
	

			axios.post('api/v1/watchlist/get-quota', {
					cancelToken: new cancelToken(function executor(c) {
						cancelWatchlistQuota = c;
						commit('setStatusWatchlistQuota', {
							status: 'loading',
							cancel: c,
							code: null,
						});				
					}),
				})
				.then((resp) => {
					let data = resp.data;
					let result = {
						status: true,
						cancel: null,
						code: resp.response && resp.response.status ? resp.response.status : null,
					};

					if (Object.keys(data).length === 0)
						result.status = 'empty';

					commit('setRemainingQuota', data);
					commit('setStatusWatchlistQuota', result);
				})
				.catch((resp) => {
					// console.log(resp)
					let result = {
						status: 'failed',
						cancel: null,
						code: resp.response && resp.response.status ? resp.response.status : null,
					};

					if (axiosOrigin.isCancel(resp)) {
						result.status = 'loading';
						result.cancel = cancelWatchlistQuota;
						
					}

					commit('setStatusWatchlistQuota', result);
					if (!axiosOrigin.isCancel(resp))
						dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null)], {
							root: true
						});
				});
		},
	}
};

export default watchlist;