<template lang="pug">
.box__body
    .card.card--table.card--no-bg
        .card__header.card--table__header
            h2.text-xl {{ page_title }}
            .header-cta
                .block
                    router-link.btn-default.m-btn-icon(to="/roles")
                        IconSet(type="chevron-left")
                        span.m-hide.block.ml-3 Back
        .card__body
            form.container-form
                .group-fields.border-b
                    .field
                        .field__label
                            label.block.text-sm.font-bold Name <sup>*</sup>
                        .field__form
                            input.field-style(type="text" placeholder="Write name here" v-model="model.name")
                            .pt-3
                                .message.text-xs.text-red-500(v-if="$v.model.name.$error") This name is required &amp; max length is 255 char.
                                .message.text-xs.text-red-500(v-if="error_name !== ''") {{ error_name }}

                    .field
                        .field__label
                            label.block.text-sm.font-bold Label <sup>*</sup>
                        .field__form
                            input.field-style(type="text" placeholder="Write label here" v-model="model.label")
                            .pt-3
                                .message.text-xs.text-red-500(v-if="$v.model.label.$error") This label is required &amp; max length is 255 char.
                                .message.text-xs.text-red-500(v-if="error_label !== ''") {{ error_label }}

                    .field.field--radio.mb-4
                        .field__label
                            label.block.text-sm.font-bold Status <sup>*</sup>
                        .field__form
                            .ui-radio.mr-4
                                input(type="radio" name="status" value="1" v-model="model.status")
                                .style-radio
                                    .radio
                                    strong Active
                            .ui-radio
                                input(type="radio" name="status" value="0" v-model="model.status")
                                .style-radio
                                    .radio
                                    strong Inactive
                            .pt-3
                                .message.text-xs.text-red-500(v-if="$v.model.status.$error") This status is required.
                                .message.text-xs.text-red-500(v-if="error_status !== ''") {{ error_status }}

                    .field
                        .field__label
                            label.block.text-sm.font-bold Description
                        .field__form
                            textarea.field-style(placeholder="Write description here" v-model="model.description" style="min-height: 200px;")
                            .pt-3
                                //- .message.text-xs.text-red-500(v-if="$v.model.description.$error") This description is required &amp; max length is 255 char.
                                .message.text-xs.text-red-500(v-if="error_description !== ''") {{ error_description }}

                .flex.items-center.justify-end.mb-8
                    button.btn.btn-default(type="button" @click="reset()")
                        | Reset
                    button.btn-primary-o.ml-2(type="submit" @click="submit($event, true)")
                        | Save &amp; Continue
                    button.btn.btn-primary.ml-4(type="submit" @click="submit($event)")
                        | Save

</template>

<script>
import { mapState } from 'vuex';
import IconSet from '@/components/IconSet.vue';
import { required, maxLength } from "vuelidate/lib/validators";

export default {
    name: 'RolesPageForm',
    components: {
        IconSet
    },
    data() {
        return {
            model: {
                name: '',
                label: '',
                status: '',
                description: '',
            },

            // error message
            error_name: '',
            error_label: '',
            error_status: '',
            error_description: '',
        }
    },
    validations: {
        model: {
            name: {
                required,
                maxLength: maxLength(255)
            },
            label: {
                required,
                maxLength: maxLength(255)
            },
            status: {
                required,
            },
            description: {
                // 
            },
        },
    },
    computed: {
        ...mapState('roles', ['detail']),
        route_name() {
            return this.$route.name;
        },
        id() {
            return this.$route.params.id;
        },
        page_title() {
            let title = 'Edit Role';
            if (this.route_name === 'pageRolesCreate')
                title = 'Add Role';
            return title;
        },
    },
    watch: {
        route_name() {
            this.getData();
        },
        id() {},
        page_title() {},
        // form data
        detail(newData) {
            this.model.name = (newData.name ? newData.name : '');
            this.model.label = (newData.label ? newData.label : '');
            this.model.status = (newData.status ? (newData.status.toLowerCase() === 'active' ? '1' : '0') : '');
            this.model.description = (newData.description ? newData.description : '');
        },
        'model.label': function(newData) {
            if (newData !== '' && newData !== null)
                this.model.label = newData.replace(/\s+/g, '-').toLowerCase();
        },
    },
    methods: {
        setEmptyModel(all=true) {
            for (let key in Object.keys(this.model)) {
                if (all === true)
                    this.model[Object.keys(this.model)[key]] = '';
            }
        },
        setErrors(empty=true, errors=null) {
            if (empty === true) {
                this.error_name = '';
                this.error_label = '';
                this.error_status = '';
                this.error_description = '';
            } else if (empty !== true && errors !== null) {
                this.error_name = (errors.name ? errors.name : '');
                this.error_label = (errors.label ? errors.label : '');
                this.error_status = (errors.status ? errors.status : '');
                this.error_description = (errors.description ? errors.description : '');
            }
        },
        getData() {
            this.reset(true);

            if (this.route_name === 'pageRolesEdit') {
                EventEmit.$emit('showLoading', true);

                this.$store.dispatch('roles/getDetail', this.id)
                .then((resp) => {
                    if (resp === 'success') {
                        EventEmit.$emit('showLoading', false);
                    } else {
                        EventEmit.$emit('error');
                    }
                });
            } else {
                if (this.route_name === 'pageRolesCreate')
                    this.$store.commit('roles/setDetail', {});
            }
        },
        reset(direct=false) {
            if (direct === true) {
                this.setEmptyModel(false);
                // reset vuelidate
                this.$nextTick(() => { this.$v.$reset(); });
                this.setErrors(true);
                window.scrollTo(0,0);
            } else {
                this.$swal.fire({
                    icon: 'warning',
                    title: 'Are you sure you want to reset form?',
                    // text: id,
                    confirmButtonText: 'Yes!',
                    confirmButtonColor: '#DD6B55',
                    showCancelButton: true,
                    allowOutsideClick: false,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        if (this.route_name === 'pageRolesCreate') {
                            this.setEmptyModel(false);
                            // reset vuelidate
                            this.$nextTick(() => { this.$v.$reset(); });
                            this.setErrors(true);
                            window.scrollTo(0,0);
                        } else {
                            this.getData();
                        }
                    }
                });
            }
        },
        submit(e, save_continue=false) {
            e.preventDefault();
            this.$v.$touch();

            if (this.$v.model.$invalid) {
                for (let key in Object.keys(this.$v.model)) {
                    const input = Object.keys(this.$v.model)[key];
                    if (input.includes("$")) return false;
                    if (this.$v.model[input].$error) {
                        break;
                    }
                }
                // EventEmit.$emit('error', 'Please fill the form correctly.');
                // EventEmit.$emit('showLoading', false);
                window.scrollTo(0,0);
                return;
            }

            EventEmit.$emit('showLoading', true);
            let model_data = Object.keys(this.model).map((key) => this.model[key]);

            if (this.route_name === 'pageRolesCreate') {

                this.$store.dispatch('roles/create', model_data)
                .then((resp) => {
                    if (resp.result === 'success') {
                        if (save_continue === false) {
                            this.$router.push('/roles');
                        } else {
                            this.$router.push('/roles/'+ resp.data.data.id +'/edit');
                        }

                        this.$swal.fire({
                            icon: 'success',
                            title: 'Data successfully created!',
                            timer: 3000,
                        });
                    } else {
                        if (resp.data.message && resp.data.message.errors) {
                            this.setErrors(false, resp.data.message.errors);
                            // EventEmit.$emit('error', null, resp.data.message.errors);
                            EventEmit.$emit('showLoading', false);
                            window.scrollTo(0,0);
                        } else {
                            EventEmit.$emit('error');
                        }
                    }
                });

            // pageRolesEdit
            } else {

                model_data = [this.id].concat(model_data);

                this.$store.dispatch('roles/update', model_data)
                .then((resp) => {
                    if (resp.result === 'success') {                        
                        if (save_continue === false)
                            this.$router.push('/roles');

                        this.$swal.fire({
                            icon: 'success',
                            title: 'Data successfully updated!',
                            timer: 3000,
                        });
                    } else {
                        if (resp.data.message && resp.data.message.errors) {
                            this.setErrors(false, resp.data.message.errors);
                            // EventEmit.$emit('error', null, resp.data.message.errors);
                            EventEmit.$emit('showLoading', false);
                            window.scrollTo(0,0);
                        } else {
                            EventEmit.$emit('error');
                        }
                    }
                });
            }
        },
    },
    created() {
        // 
    },
    mounted() {
        this.getData();
    },
}
</script>

<style lang="sass">

// input[readonly]
input.readonly
    opacity: 0.4
    background: #e2e8f0

</style>
