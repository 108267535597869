<template lang="pug">
.card
    .card__header
        h2.text-xl Signal Status
    .card__body

        template(v-if="status_signal_status.status === 'loading'")
            template(v-if="route_name === 'TargetDetailLocationMovement' || route_name === 'TargetDetailLocationLog'")
                Loading(show="true")
            template(v-else)
                LoadingAnimation(show="true" title="location-log-signal")
        template(v-else)
            .flex.flex-wrap(
                class="flex-col-reverse lg:flex-row"
            )
                .block(class="w-full mb-4 lg:w-8/12 lg:mb-0")
                    .box-charts(v-if="signal_status.signal_status && (typeof signal_status.signal_status.categories != 'undefined' && signal_status.signal_status.categories.length > 0) && signal_status.signal_status.series.length > 0 && chartLoaded")
                        highcharts(
                            :options="chartOptions"
                        )

                    NoDataAvailable(v-else)

                .block(class="w-full lg:w-4/12")
                    .flex(
                        class="pl-0 lg:pl-10 flex-row lg:flex-col"
                    )
                        .rollout.flex-grow(
                            v-for="(value, name) in signal_status.summary"
                            class="pb-0 lg:pb-4"
                        )
                            span.text-sm {{ name }}
                            p.text-light.text-3xl
                                | {{ value||'-' }}
</template>

<script>
import { mapState } from 'vuex';
import Loading from '@/components/Loading.vue';
import LoadingAnimation from '@/components/LoadingAnimation.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';

export default {
    name: 'LocationLogSignal',
    components: {
        Loading,
        NoDataAvailable,
        LoadingAnimation
    },
    data(){
        return {
            chartLoaded: false,

            title: '',
            chartType: 'bar',
            seriesColor: '#6fcd98',
            colorInputIsSupported: null,
            chartOptions: {
                chart: {
                    type: 'bar'
                },
                title: {
                    text: null
                },
                xAxis: {
                    categories: [],
                    crosshair: true,
                    title: {
                        text: null
                    },
                    labels: {
                        step: 1
                    }
                },
                yAxis: {
                    title: {
                        text: null
                    },
                },
                tooltip: {
                    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                    pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                        '<td style="padding:0"><b>{point.y:1f}</b></td></tr>',
                    footerFormat: '</table>',
                    shared: true,
                    useHTML: true
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0
                    }
                },
                series: [],
                // series: [{
                //     name: 'Average Signal 10/8/20 - 12/12/20',
                //     color: '#6fcd98',
                //     data: [
                //         90,
                //         181,
                //         21,
                //         ],
                // }],
                credits: {
                    enabled: false
                },
            },
        };
    },
    computed: {
        ...mapState('locationLog', [
            'status_signal_status',
            'signal_status',
        ]),
        route_name() {
            return this.$route.name;
        },
    },
    watch: {
        signal_status() {
            this.startChart();
        },
        chartLoaded() {},
    },
    methods: {
        toggleSidebar() {
            EventEmit.$emit('toggleSibebar')
        },
        startChart() {
            if (this.signal_status.signal_status && (typeof this.signal_status.signal_status.categories != "undefined" && this.signal_status.signal_status.categories.length > 0) && this.signal_status.signal_status.series.length > 0) {

                this.chartOptions.xAxis.categories = this.signal_status.signal_status.categories;

                this.chartOptions.series = this.signal_status.signal_status.series;

                this.chartLoaded = true;
            }
        },

    },
    mounted() {
        this.startChart();
    },
}
</script>