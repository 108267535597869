<template lang="pug">
.container-auth
    .logo-helix
        <svg viewBox="0 0 82 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.8539 0.272003V24.272H12.3775V14.0798H4.45789V24.272H0.0159912V0.272003H4.45789V10.2921H12.3775V0.272003H16.8539Z" fill="url(#paint0_linear)"/>
            <path d="M35.7655 20.381V24.272H22.5432V0.272003H35.0769V4.02523H26.8473V9.63786H33.3896V13.2878H26.8473V20.381H35.7655Z" fill="url(#paint1_linear)"/>
            <path d="M51.6 20.4843V24.272H40.0649V0.272003H44.4379V20.4843H51.6Z" fill="url(#paint2_linear)"/>
            <path d="M55.2951 0.272003H59.7026V24.272H55.2951V0.272003Z" fill="url(#paint3_linear)"/>
            <path d="M76.3696 24.272L72.0827 14.9922L67.9679 24.272H62.8374L69.1559 11.9105L62.9407 0.272003H68.1057L72.341 9.43126L76.4385 0.272003H81.5002L75.2333 12.3409L81.6724 24.272H76.3696Z" fill="url(#paint4_linear)"/>
            <defs>
            <linearGradient id="paint0_linear" x1="69.577" y1="-31.6821" x2="10.2027" y2="34.5621" gradientUnits="userSpaceOnUse">
            <stop stop-color="#F8B653"/>
            <stop offset="1" stop-color="#43EA92"/>
            </linearGradient>
            <linearGradient id="paint1_linear" x1="69.577" y1="-31.6821" x2="10.2027" y2="34.5621" gradientUnits="userSpaceOnUse">
            <stop stop-color="#F8B653"/>
            <stop offset="1" stop-color="#43EA92"/>
            </linearGradient>
            <linearGradient id="paint2_linear" x1="69.577" y1="-31.6821" x2="10.2027" y2="34.5621" gradientUnits="userSpaceOnUse">
            <stop stop-color="#F8B653"/>
            <stop offset="1" stop-color="#43EA92"/>
            </linearGradient>
            <linearGradient id="paint3_linear" x1="69.577" y1="-31.6821" x2="10.2027" y2="34.5621" gradientUnits="userSpaceOnUse">
            <stop stop-color="#F8B653"/>
            <stop offset="1" stop-color="#43EA92"/>
            </linearGradient>
            <linearGradient id="paint4_linear" x1="69.577" y1="-31.6821" x2="10.2027" y2="34.5621" gradientUnits="userSpaceOnUse">
            <stop stop-color="#F8B653"/>
            <stop offset="1" stop-color="#43EA92"/>
            </linearGradient>
            </defs>
        </svg>
    form.box.mb-4
        .box__header.text-center.border-b.p-4.pb-8
            h2.text-2xl.font-bold.mb-2.text-light License Key
            p Please input the license key bellow to unlock your access to HELIX
        .box__body.p-8
            .field.w-full.mb-6
                .field__label
                .field__form
                    input.field-style.field-style--large.w-full.text-center(id="license" type="text" placeholder="Write the License Key" required)
                    //- span.block.pt-1.text-xs.text-red-600 error message
            button.btn-primary.w-full(type='submit' @click="activate") ACTIVATE
    span.text-gray-500.text-xs.text-center.block © {{ app_name }} {{ new Date().getFullYear() }}

</template>

<script>
import { env } from '@/config/env';
export default {
    name: 'PageLicense',
    data(){
        return {
            app_name: env.VUE_APP_APP_NAME
        }
    },
    methods : {
    },
    mounted() {
    },
}
</script>

<style lang="sass" scoped>
    .container-auth
        max-width: 420px
        margin: 32px auto

        .logo-helix
            margin: 3rem auto
            width: 128px
            svg
                width: 100%
        
        .box
            background-color: $color-secondary-500
            .btn-primary
                padding: 0.75rem 2rem
                font-size: 1.2rem
                font-weight: bold
                background-color: $color-main-500
                color: $color-secondary-600
                transition: all 0.3s ease-in-out
                &:disabled
                    opacity: 0.2
                &:hover
                    background-color: $color-main-400

    // error field
    .error 
        color: red

    @media screen and (max-width: 480px)
        .container-auth
            .box
                background-color: transparent

</style>
