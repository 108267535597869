<template lang="pug">
.page-location-log
	ActionSearchResult(
		v-if="status === false && $route.matched[0].name !== 'pageTargetDetail'"
		:title="title"
	)

	template(v-else)
		//- title
		TitleResult(:title="title")

		//- filter
		FilterResult

		//- Activity Location
		ActivityLocation(:isKey="isKey")

		//- Signal Status
		SignalStatus

		//- Detail Location
		DetailLocation

		//- possible Location
		PossibleLocation

		//- aerial log
		BoxMaps(title='Movement Mapper')
		TimelineActivity(title='Location Movement Activity')
		AerialTop(title='Highest Location Movement')

		//- Location Movement Data
		LocationLogData

</template>

<script>
import { mapState } from 'vuex';
import TitleResult from '@/components/result/Title.vue';
import ActionSearchResult from '@/components/result/ActionSearchResult.vue'
import FilterResult from '@/components/result/Filter.vue';
import IconSet from '@/components/IconSet.vue'
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import moment from 'moment';
import { decrypt } from '@/util/crypto';

import ActivityLocation from '../location-log/ActivityLocation.vue'
import SignalStatus from '../location-log/LocationLogSignal.vue'
import DetailLocation from '../location-log/detaillocation/Index.vue'
import PossibleLocation from '../location-log/possible/Index.vue'
import LocationLogData from '../location-log/LocationLogData.vue'

import BoxMaps from '../aerial-log/BoxMaps.vue'
import TimelineActivity from '../aerial-log/TimelineActivity.vue'
import AerialTop from '../aerial-log/aerialTop/Index.vue'


export default {
	name: 'ResultLocationLog',
	components: {
		TitleResult,
		ActionSearchResult,
		FilterResult,
		IconSet,
		DateRangePicker,

		ActivityLocation,
		DetailLocation,
		SignalStatus,
		PossibleLocation,
		LocationLogData,
		BoxMaps,
		TimelineActivity,
		AerialTop,
	},
	props: ['isKey'],
	data() {
		return {
			title: "Location Movement",
			showFilter: false,

			dateRange: {
				startDate: '',
				endDate: '',
			},
			default_ranges: {
				'Today': [moment().toDate(), moment().toDate()],
				'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
				'Last 7 days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
				'Last week': [moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(), moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate()],
			},
		}
	},
	computed: {
		...mapState('locationMovement', [
			'status',
			'from_date',
			'to_date',
		]),
		route_name() {
			return this.$route.name;
		},
		route() {
			return this.$route;
		},
	},
	watch: {
		route_name() {},
		from_date(newData, oldData) {
			if (newData !== oldData)
				this.getFilter();
		},
		to_date(newData, oldData) {
			if (newData !== oldData)
				this.getFilter();
		},
	},
	methods: {
		getFilter() {
			let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
			const default_date = this.$store.getters['config/getDefaultDate'](def);
			this.dateRange.startDate = this.from_date ?? default_date.from_date;
			this.dateRange.endDate = this.to_date ?? default_date.to_date;
		},
		decryptData() {
			let data = null;

			if (this.$route.params.key) {
				let decrypt_data = decrypt(this.$route.params.key);
				decrypt_data = decrypt_data.split('&&');
				// console.log(decrypt_data);

				var key = decrypt_data[0];
				var type = decrypt_data[1];

				data = { key: key, type: type };
			}
			return data;
		},
		filter() {
			const decrypt_data = this.decryptData();
			if (decrypt_data !== null)
				this.$store.dispatch('locationMovement/getResultData', [decrypt_data.key, decrypt_data.type]);
		},
		reset() {
			this.$swal.fire({
				icon: 'warning',
				title: 'Are you sure you want to reset?',
				// text: 'Role : ' + name,
				confirmButtonText: 'Yes!',
				confirmButtonColor: '#DD6B55',
				showCancelButton: true,
				allowOutsideClick: false,
			})
			.then((result) => {
				if (result.isConfirmed) {

					let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
					const default_date = this.$store.getters['config/getDefaultDate'](def);

					this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
					this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();

					this.$store.commit('locationMovement/setFromDate', default_date.from_date);
					this.$store.commit('locationMovement/setToDate', default_date.to_date);

					this.filter();
				}
			});
		},
		defaultFilter() {

			let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
			const default_date = this.$store.getters['config/getDefaultDate'](def);

			this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
			this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();

			this.$store.commit('locationMovement/setFromDate', default_date.from_date);
			this.$store.commit('locationMovement/setToDate', default_date.to_date);
		},
		/* eslint-disable no-unused-vars */
		dateFormat (classes, date) {
			if (!classes.disabled) {
				classes.disabled = moment(date.getTime()).subtract({ hours: 12})._d > new Date();
			}
			return classes;
		},
		async pickerUpdate(e) {
			this.$store.commit('locationMovement/setFromDate', moment(this.dateRange.startDate).format('YYYY-MM-DD'));
			this.$store.commit('locationMovement/setToDate', moment(this.dateRange.endDate).format('YYYY-MM-DD'));
		},
	},
	filters: {
		date: function (date) {
			return moment(date).format('YYYY-MM-DD');
		}
	},
	mounted() {
		// this.defaultFilter();
		this.getFilter();
	},
}
</script>

<style lang="sass">
.daterangepicker
    .ranges
        float: left

.field-date-time-picker
    .reportrange-text
        width: 100%
        box-shadow: none
        line-height: 40px
        padding: 0px 32px 0px 12px
        color: #3d3d3d
        border-color: #a0aec0
        background: #ffffff
        height: 40px
        -webkit-appearance: none !important
        -moz-appearance: none !important
        appearance: none !important
</style>
