import axios from "@/axios-main";
import axiosOrigin from "axios";

let cancelRemainingQuota, cancelRemainingQuotaTarget, cancelRemainingQuotaMultitracking;
var cancelToken = axiosOrigin.CancelToken;

const dashboard = {
  namespaced: true,
  state: {
    status_remaining_quota: {
      status: false,
      cancel: null,
      code: null,
    },
    status_remaining_quota_target: {
      status: false,
      cancel: null,
      code: null,
    },
    status_remaining_quota_multitracking: {
      status: false,
      cancel: null,
      code: null,
    },

    remaining_quota: {},
    remaining_quota_target: {},
    remaining_quota_multitracking: {},
  },
  mutations: {
    setStatusRemainingQuota: (state, status) => {
      state.status_remaining_quota = status;
    },
    setStatusRemainingQuotaTarget: (state, status) => {
      state.status_remaining_quota_target = status;
    },
    setStatusRemainingQuotaMultitracking: (state, status) => {
      state.status_remaining_quota_multitracking = status;
    },

    setRemainingQuota: (state, data) => {
      state.remaining_quota = data;
    },
    setRemainingQuotaTarget: (state, data) => {
      state.remaining_quota_target = data;
    },
    setRemainingQuotaMultitracking: (state, data) => {
      state.remaining_quota_multitracking = data;
    },
  },
  getters: {},
  actions: {

    async getRemainingQuota({ state, commit, dispatch }, from='dashboard') {

      switch(from) {
        case 'target':
          if(state.status_remaining_quota_target.cancel != null && state.status_remaining_quota_target.status === 'loading')
            await state.status_remaining_quota_target.cancel('Operation canceled by the user.');
          break;

        case 'multitracking':
          if(state.status_remaining_quota_multitracking.cancel != null && state.status_remaining_quota_multitracking.status === 'loading')
            await state.status_remaining_quota_multitracking.cancel('Operation canceled by the user.');
          break;

        default: // dashboard
          if(state.status_remaining_quota.cancel != null && state.status_remaining_quota.status === 'loading')
            await state.status_remaining_quota.cancel('Operation canceled by the user.');
      }

      let params = new FormData();
      params.append('usefor', 'single');
    
      axios.post('api/v1/remining-quota/get', params, {
        cancelToken: new cancelToken(function executor(c) {
          // cancelRemainingQuota = c;
          // commit('setStatusRemainingQuota', {
          //   status: 'loading',
          //   cancel: c,
          //   code: null,
          // });

          let loading = {
            status: 'loading',
            cancel: c,
            code: null,
          };

          switch(from) {
            case 'target':
              cancelRemainingQuotaTarget = c;
              commit('setStatusRemainingQuotaTarget', loading);
              break;

            case 'multitracking':
              cancelRemainingQuotaMultitracking = c;
              commit('setStatusRemainingQuotaMultitracking', loading);
              break;

            default: // dashboard
              cancelRemainingQuota = c;
              commit('setStatusRemainingQuota', loading);
          }
        }),
      })
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (Object.keys(data).length===0)
          result.status = 'empty';

        switch(from) {
          case 'target':
            commit('setRemainingQuotaTarget', data);
            commit('setStatusRemainingQuotaTarget', result);
            break;

          case 'multitracking':
            commit('setRemainingQuotaMultitracking', data);
            commit('setStatusRemainingQuotaMultitracking', result);
            break;

          default: // dashboard
            commit('setRemainingQuota', data);
            commit('setStatusRemainingQuota', result);
        }
      })
      .catch((resp) => {
        // console.log(resp)
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          // result.cancel = cancelRemainingQuota;
          switch(from) {
            case 'target':
              result.cancel = cancelRemainingQuotaTarget;
              break;

            case 'multitracking':
              result.cancel = cancelRemainingQuotaMultitracking;
              break;

            default: // dashboard
              result.cancel = cancelRemainingQuota;
          }
        }

        switch(from) {
          case 'target':
            commit('setStatusRemainingQuotaTarget', result);
            break;

          case 'multitracking':
            commit('setStatusRemainingQuotaMultitracking', result);
            break;

          default: // dashboard
            commit('setStatusRemainingQuota', result);
        }
	if (!axiosOrigin.isCancel(resp))
        dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null), null, true], { root: true });
      });
    },
  }
};

export default dashboard;
