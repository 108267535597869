<template lang="pug">
.card
    .card__header
        h2.text-xl Family Information
    .card__body
        template(v-if="status_profile_family.status === 'loading'")
            Loading(show="true")

        template(v-else)
            template(v-if="profile_family && Object.keys(profile_family).length > 0")

                .family-item.p-6(v-for="(family, index) in profile_family" :class="index !== (profile_family.length - 1) ? 'mb-8' : ''")
                    .grid(class="grid-cols-1 lg:grid-cols-4")
                        .block
                            .rollout
                                span.text-xs Name
                                p.text-light.uppercase
                                    | {{ family.full_name ? family.full_name : '-' }} ({{ family.family_conection ? family.family_conection : '-' }})
                                    //- router-link.link-text(v-if="family.full_name && family.full_name.length > 0" :to="'/search-by-name/'+ encrypt(family.full_name, 'name')" target="_blank")
                                    //-     | {{ family.full_name ? family.full_name : '-' }} ({{ family.family_conection ? family.family_conection : '-' }})
                                    //- template(v-else) {{ family.full_name ? family.full_name : '-' }} ({{ family.family_conection ? family.family_conection : '-' }})
                            .rollout
                                //- span.text-xs KTP/NIK
                                span.text-xs Citizen ID
                                p.text-light.uppercase {{ family.nik ? family.nik : '-' }}
                            .rollout
                                //- span.text-xs NKK
                                span.text-xs Family ID
                                p.text-light.uppercase {{ family.family_id ? family.family_id : '-' }}

                        .block
                            .rollout
                                span.text-xs Gender
                                p.text-light.uppercase {{ family.gender ? family.gender : '-' }}
                            .rollout
                                span.text-xs DOB
                                p.text-light.uppercase {{ family.birth_date ? family.birth_date : '-' }}
                            .rollout
                                span.text-xs POB
                                p.text-light.uppercase {{ family.birth_place ? family.birth_place : '-' }}
                            .rollout
                                span.text-xs Marital
                                p.text-light.uppercase {{ family.marital_status ? family.marital_status : '-' }}
                            //- .rollout
                                span.text-xs Zodiac
                                p.text-light.uppercase {{ family.zodiac ? family.zodiac : '-' }}
                        
                        .block
                            .rollout
                                span.text-xs Province
                                p.text-light.uppercase {{ family.address_province ? family.address_province : '-' }}
                            .rollout
                                span.text-xs City
                                p.text-light.uppercase {{ family.address_district ? family.address_district : '-' }}
                            .rollout
                                span.text-xs Sub District
                                p.text-light.uppercase {{ family.address_sub_district ? family.address_sub_district : '-' }}
                            .rollout
                                //- span.text-xs RT/RW
                                span.text-xs NH-ID/CA-ID
                                p.text-light.uppercase {{ family.address_rt ? family.address_rt : '-' }}/{{ family.address_rw ? family.address_rw : '-' }}
                            .rollout
                                span.text-xs Address
                                p.text-light.uppercase {{ family.address ? family.address : '-' }}
                        
                        //- .block
                            .rollout
                                    span.text-xs Phone
                                    template(v-if="family.phone && family.phone.length > 0 && family.phone[0]")
                                        ul
                                            li.mb-2(v-for="item in family.phone")
                                                .label-green {{ item }}
                                    template(v-else)
                                        p.text-gray-600.text-xs.italic Phone number not found

                        //- .block(v-if="family.photo && family.photo != '-'")
                            .avatar.w-32
                                img.w-full(:src="(family.photo && family.photo != '-') ? checkPhotoBase64(family.photo) : defaultImage" @error="getDefaultImage" alt="alt")
        
            NoDataAvailable(v-else)

</template>

<script>
import { mapState } from 'vuex';
import Loading from '@/components/Loading.vue';
import LoadingAnimation from '@/components/LoadingAnimation.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';
import { encrypt } from '@/util/crypto';

export default {
    name: 'ResultProfileFamily',
    components: {
        Loading,
        NoDataAvailable,
        LoadingAnimation
    },
    data: function() {
        return {
            defaultImage: this.$store.getters['config/getDefaultImageProfile'],
        };
    },
    computed: {
        ...mapState('profile', [
            'status_profile_family',
            'profile_family',
        ]),
        route_name() {
            return this.$route.name;
        },        
    },
    methods: {
        checkPhotoBase64(photo) {
            if (!(photo && photo.substring(0, 11)=='data:image/')){
                photo = 'data:image/jpeg;base64,'+photo
            }
            return photo
        },
        getDefaultImage(e) {
            e.target.src = this.defaultImage;
        },
        encrypt(key, type) {
            return encrypt(key + '&&' + type);
        },
    },
    mounted() {
        // 
    }
}
</script>

<style lang="sass" scoped>
    .family-item
        border: 1px solid lighten($color-secondary-500, 5% )
</style>