<template lang="pug">
.page-overall
    ActionSearchResult(
        v-if="status === false && $route.matched[0].name !== 'pageTargetDetail'"
        :title="title"
    )
    template(v-else)
        //- title
        TitleResult(:title="title")

        //- filter
        //- FilterResult

        //- Recent Location
        RecentLocation(:isKey="isKey")

        //- Log Recent Location
        LogRecentLocation(
            v-if="$route.matched[0].name === 'pageTargetDetail'"
            :isKey="isKey"
        )

</template>

<script>
import { mapState } from 'vuex';
import RecentLocation from './RecentLocation.vue';
import LogRecentLocation from './LogRecentLocation.vue';
import TitleResult from '@/components/result/Title.vue';
import ActionSearchResult from '@/components/result/ActionSearchResult.vue';
import FilterResult from '@/components/result/Filter.vue';
import IconSet from '@/components/IconSet.vue';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import moment from 'moment';
import { decrypt } from '@/util/crypto';

export default {
    name: 'ResultOverall',
    components: {
        RecentLocation,
        LogRecentLocation,
        TitleResult,
        ActionSearchResult,
        FilterResult,
        IconSet,
        DateRangePicker,
    },
    props: ['isKey'],
    data() {
        return {
            title: "Recent Location",
            showFilter: false,

            dateRange: {
                startDate: '',
                endDate: '',
            },
            default_ranges: {
                'Today': [moment().toDate(), moment().toDate()],
                'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                'Last 7 days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                'Last week': [moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(), moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate()],
            },
        };
    },
    computed: {
        ...mapState('recentLocation', [
            'status',
            // 'record_size',
            'from_date',
            'to_date',
        ]),
        route_name() {
            return this.$route.name;
        },
        route() {
            return this.$route;
        },
    },
    watch: {
        route_name() {},
        // record_size_input(newData) {
        //     this.$store.commit('recentLocation/setRecordSize', newData);
        // },
        from_date(newData, oldData) {
            if (newData !== oldData)
                this.getFilter();
        },
        to_date(newData, oldData) {
            if (newData !== oldData)
                this.getFilter();
        },
    },
    methods: {
        getFilter() {
            let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
            const default_date = this.$store.getters['config/getDefaultDate'](def);
            this.record_size_input = this.record_size ?? 200;
            this.app_size_input = this.app_size ?? 20;
            this.dateRange.startDate = this.from_date ?? default_date.from_date;
            this.dateRange.endDate = this.to_date ?? default_date.to_date;
        },
        decryptData() {
            let data = null;

            if (this.$route.params.key) {
                let decrypt_data = decrypt(this.$route.params.key);
                decrypt_data = decrypt_data.split('&&');
                // console.log(decrypt_data);

                var key = decrypt_data[0];
                var type = decrypt_data[1];

                data = { key: key, type: type };
            }
            return data;
        },
        filter() {
            const decrypt_data = this.decryptData();

            // if ((this.$route.matched[0].name === 'PageResult' || this.$route.matched[0].name === 'pageTargetDetail') && decrypt_data !== null) {
            if (decrypt_data !== null)
                this.$store.dispatch('recentLocation/getResultData', [decrypt_data.key, decrypt_data.type]);
        },
        reset() {
            this.$swal.fire({
                icon: 'warning',
                title: 'Are you sure you want to reset?',
                // text: 'Role : ' + name,
                confirmButtonText: 'Yes!',
                confirmButtonColor: '#DD6B55',
                showCancelButton: true,
                allowOutsideClick: false,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.record_size_input = 200;
                    this.app_size_input = 20;

                    this.$store.commit('recentLocation/setRecordSize', this.record_size_input);
                    this.$store.commit('recentLocation/setAppSize', this.app_size_input);

                    let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
                    const default_date = this.$store.getters['config/getDefaultDate'](def);

                    this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
                    this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();

                    this.$store.commit('recentLocation/setFromDate', default_date.from_date);
                    this.$store.commit('recentLocation/setToDate', default_date.to_date);

                    this.filter();
                }
            });
        },
        defaultFilter() {
            this.record_size_input = 200;
            this.app_size_input = 20;

            this.$store.commit('recentLocation/setRecordSize', this.record_size_input);
            this.$store.commit('recentLocation/setAppSize', this.app_size_input);

            let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
            const default_date = this.$store.getters['config/getDefaultDate'](def);

            this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
            this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();
            // this.dateRange.startDate = moment().subtract(6, 'days').toDate()
            // this.dateRange.endDate = moment().toDate();

            // this.from_date_input = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            // this.to_date_input = moment(this.dateRange.endDate).format('YYYY-MM-DD');

            this.$store.commit('recentLocation/setFromDate', default_date.from_date);
            this.$store.commit('recentLocation/setToDate', default_date.to_date);
        },
        /* eslint-disable no-unused-vars */
        dateFormat (classes, date) {
            if (!classes.disabled) {
                classes.disabled = moment(date.getTime()).subtract({ hours: 12})._d > new Date();
            }
            return classes;
        },
        async pickerUpdate(e) {
            // let ranges = moment(e.endDate.getTime()).diff(moment(e.startDate.getTime()), 'days') + 1;

            // this.from_date_input = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            // this.to_date_input = moment(this.dateRange.endDate).format('YYYY-MM-DD');

            this.$store.commit('recentLocation/setFromDate', moment(this.dateRange.startDate).format('YYYY-MM-DD'));
            this.$store.commit('recentLocation/setToDate', moment(this.dateRange.endDate).format('YYYY-MM-DD'));

        },
    },
    filters: {
      date: function (date) {
        return moment(date).format('YYYY-MM-DD');
      }
    },
    mounted() {
        // this.defaultFilter();
        console.log('=====$route.matched[0].name', this.$route.matched[0].name)
        this.getFilter();
    },
}
</script>

<style lang="sass">
html
    scroll-behavior: smooth
.daterangepicker
    .ranges
        float: left

.field-date-time-picker
    .reportrange-text
        width: 100%
        box-shadow: none
        line-height: 40px
        padding: 0px 32px 0px 12px
        color: #3d3d3d
        border-color: #a0aec0
        background: #ffffff
        height: 40px
        -webkit-appearance: none !important
        -moz-appearance: none !important
        appearance: none !important
</style>
