<template lang="pug">
.box__body
    .card.card--table.card--no-bg
        .card__header.card--table__header
            h2.text-xl {{ status_detail === 'loading'? "..." : (detail.type_view+': '+detail.key)}}
            .header-cta
                .flex.items-center.gap-2
                    .block
                        router-link.btn-default(:to="{ name: 'pageDcdIndex'}")
                            IconSet(type="chevron-left")
                            span.m-hide.ml-3 Back
                    .block
                        router-link.btn.btn-primary-o(:to="{ name: 'pageDcdEdit', params: {id:id}}")
                            span Edit Information
        ResultDcd(
            v-if="getResult"
            :source-value="source"
            :key-value="key"
            :type-value="type"
        )
</template>


<script>
import IconSet from '@/components/IconSet.vue';
import ResultDcd from './Result.vue';
import Loading from '@/components/Loading.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';
import Spinner from '@/components/Spinner.vue';
import { mapState } from 'vuex';

export default {
    name: 'pageDcdDetail',
    components: {
        IconSet,
        Loading,
        NoDataAvailable,            
        Spinner,  
        ResultDcd,  
    },
    data() {
        return {
            pageTitle: 'Search New Digital Cyber Data',
            modalNik: false,
            selectedNik: null,
            getResult: false,
            key: null,
            type: null,
        }
    },
    computed: {
        ...mapState('dcd', [
            'status_detail',
            'detail',
        ]),
        route_name() {
            return this.$route.name;
        },
        id() {
            return this.$route.params.id;
        },
        source() {
            return this.$route.params.source;
        },

    },
    methods: {
        handleBack(){
            this.$router.push('/dcd');
        },
        async getData() {
            this.getResult=false
            await this.$store.dispatch('dcd/getDetail', this.id)
            .then((resp) => {
                if (resp.result === 'success') {
                    // EventEmit.$emit('showLoading', false);

                    // TODO: CHECK DOUBLE REQUEST
                    // this.$store.dispatch('dcd/searchDcd', [resp.data?.key, resp.data?.type, this.source]);
                    this.getResult = true
                    this.key = resp.data?.key
                    this.type = resp.data?.type

                } else {
                    EventEmit.$emit('error');
                }
            });
        },
    },
    watch: {
        route_name() {
            this.getData();
        },
    },
    async created() {
        this.getData();
    },
}
</script>
<style lang="sass">
.border-custom
    @apply .border-2 .rounded-lg .p-4 .mb-4
    border-color: hsl(0deg 0% 67% / 37%)
</style>