<template lang="pug">
.target-index
    .box__body
        .card.card--table.card--no-bg
            .card__header.card--table__header
                h2.text-xl Table of Target History
            .card--table__body
                .box-filter-table.p-4.pt-0
                    .field(class="lg:mr-3 lg:mb-0 lg:w-64 mr-0 w-full mb-4")
                        input.field-style(
                            v-model="keyword"
                            type="text"
                            placeholder="Search here..."
                            @keyup="searchKeyword"
                        )
                        .field-icon
                            IconSet(
                                type="search"
                            )
                    .field(class="lg:mr-3 lg:mb-0 lg:w-32 mr-0 w-full mb-4")
                        select.field-style(@change="sizeChange($event)" v-model="items_per_page")
                            option(v-for="(s,index) in size" :key="index" :value="s") {{ s }}
                        .field-icon
                            IconSet(
                                type="chevron-down"
                            )
                .box-table.box-table--target
                    table.table
                        thead.m-hide
                            tr
                                th(v-for="(th,index) in tableThead" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}
                        tbody
                            tr(v-if="status_list_history.status === 'loading'")
                                td.text-center(:colspan="tableThead.length")
                                    .flex.flex-wrap.items-center.justify-center
                                        Spinner(show="true" size="48")
                            template(v-else-if="list_history && list_history.data && list_history.data.length > 0")
                                tr(v-for="(data,index) in list_history.data" :key="data.id")
                                    td
                                        //- button.link.flex.items-center.text-sm.text-left(@click="detailTarget(data)")
                                        .flex.items-center.text-sm.text-left
                                            .mr-2
                                                figure.w-8.h-8.rounded-full.overflow-hidden
                                                    img.w-full.h-full(:src="data.photos && data.photos[0] ? data.photos[0].file : getDefaultImage" @error="getDefaultImage")
                                            span {{ data.target_name }}
                                    td.col-target-label
                                        .target-label(:style="'background-color: '+ ( data.label ? data.label:'white') +';'")
                                    td.m-hide
                                        .pb-2(v-for="clue in countClues(data.clues)")
                                            .label-dark(:class="data.clues.length > 1 ? 'mr-2' : ''") {{ clue.count }} {{ clue.name }}
                                            //- .label-dark {{ clue.count }} {{ clue.name }}
                                    td 
                                        span.text-xs {{ toString(data.cases) }}
                                    //- td 
                                        .w-64
                                            template(v-if="data.notes")
                                                .text-sm.note(@click="getNotes(true, data.id, data.target_name)") {{data.notes?data.notes.notes:'No notes'}}
                                            template(v-else)
                                                button.link.text-sm(@click="getNotes(true, data.id, data.target_name)") {{data.notes?data.notes.notes:'Add a note'}}
                                    td.m-hide
                                        span.text-xs {{ data.created_by && data.created_by.name ? data.created_by.name : '-' }}
                                    td.m-hide
                                        span.text-xs {{ data.updated_at?data.updated_at:'-' }}

                            tr(v-else)
                                td.text-center(:colspan="tableThead.length") No Data Available                    

                .box-table-footer(v-if="status_list_history.status !== 'loading'")
                    .pagination
                        vue-ads-pagination(
                            :total-items="total_data"
                            :items-per-page="items_per_page"
                            :page="page"
                            :max-visible-pages="5"
                        )
                            template(slot="buttons" slot-scope="props")
                                vue-ads-page-button(
                                    v-for="(button, key) in props.buttons"
                                    :key="key"
                                    v-bind="button"
                                    :class="{'bg-yellow-dark': button.active}"
                                    @page-change="page = button.page"
                                    @range-change="start = button.start; end = button.end" 
                                )

    .popup-layer(
        v-bind:class="{'is-show' : openNote}"
    )
        .popup-container
            .popup-container__header
                h2.text-xl {{tmpTargetName}}'s Notes
                button.btn-icon-40(
                    @click="getNotes(false)"
                )
                    IconSet( type="close")

            .popup-container__body
                .mb-4
                    .field.w-full.mb-2
                        textarea.field-style(
                            v-model="tmpNote"
                            placeholder="Write new note here..."
                            style="min-height:60px"
                        )
                    .flex.justify-end
                        template(v-if="tmpNote && tmpNote.length>0")
                            button.btn-primary.btn.small(@click="addNote") Add note
                        template(v-else)
                            button.btn-primary.btn.small.btn-disabled(disabled) Add note
                .mb-4
                    .small-heading History Notes
                    template(v-if="status_notes_list.status == 'loading'")
                        .flex.flex-wrap.items-center.justify-center
                            Spinner(show="true" size="48")

                    .mb-4.pb-4.border-b(v-else-if="notes_list && notes_list.length > 0" v-for="note in notes_list")
                        p.mb-1 {{note.notes}}
                        small.text-xs.opacity-75 {{note.created_at?datetimeFormat(note.created_at,'DD MMM YYYY HH:mm:ss [WIB]'):'-'}} • added by {{note.created_by?note.created_by:'-'}}

                    .flex.flex-wrap.items-center.justify-center(v-else)
                        strong.block.text-xs.uppercase.text-light.mb-4 No History Available
                    .block                            
                        template(v-if="status_notes_list.status != 'loading' && (notes_list && notes_list.length > 0)")
                            small.text-xs.opacity-75(v-if="!is_loading_next") {{notes_list.length}} of {{data_pagination.total_data}} items
                            .flex.flex-wrap.items-center.justify-center
                                template(v-if="is_loading_next")
                                    Spinner(show="true" size="48")
                                template(v-else)
                                    template(v-if="data_pagination.current_page<data_pagination.total_page")
                                        button.btn-small.btn-primary(@click="handleNextNotes") Load More
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import IconSet from '@/components/IconSet.vue';
import Spinner from '@/components/Spinner.vue';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
import { encrypt } from '@/util/crypto';
import moment from 'moment';

export default {
    name: 'pageTargetHistory',
    components: {
        IconSet,
        Spinner,
        VueAdsPagination,
        VueAdsPageButton,
    },
    data() {
        return {            
            tableThead: [
                {
                    name: 'Target Name',
                },{
                    name: '',
                },{
                    name: 'Keyword',
                },{
                    name: 'Case Name',
                // },{
                    // name: 'Latest Note',
                },{
                    name: 'Created By',
                },{
                    name: 'Last Updated',
                },
                // {
                //     name: 'Action',
                //     action: true,
                // },
            ],
            total_data: 0,
            items_per_page: 10,

            size: [10, 25, 50, 100],

            page: 0,
            start: 0,
            end: 0,

            keyword: '',
            timeout: null,
            monitoring_active: '',

            defaultImage: this.$store.getters['config/getDefaultImageProfile'],

            openNote: false,

            tmpTargetId: null,
            tmpTargetName: null,
            tmpNote: null,

        }
    },
    computed: {
        ...mapState('target', [
            'status_list_history',
            'list_history',
            'status_notes_list',
            'notes_list',
            'is_loading_next',
            'data_pagination',
        ]),
        route_name() {
            return this.$route.name;
        },
    },
    watch: {
        route_name(newData, oldData) {
            if (newData === 'pageTargetHistory') {
                if (oldData !== 'pageTargetHistory') {
                    this.items_per_page = 10;
                    this.page = 0;
                    this.keyword = '';
                    this.$store.commit('target/setListHistory', {});
                }

                this.getData();
            }
        },
        list_history(newData) {
            if (Object.keys(newData).length > 0) {
                this.total_data = parseInt(newData.total_data);
                // this.page = (parseInt(newData.current_page) - 1);
            } else {
                this.total_data = 0;
            }
        },
        items_per_page() {
            this.getData();
        },
        page() {
            this.getData();
        },

    },
    methods: {
        getData() {
            // for dispatch get data
            console.log(this.route_name)
            if (this.route_name === 'pageTargetHistory') {
                console.log('masuk')
                this.$store.commit('target/setRedirect', 'index');        
                this.$store.dispatch('target/getListHistory', [this.items_per_page, (this.page + 1), this.keyword, null, null, null, null, null, this.monitoring_active]);
            }
        },
        pageChange(page) {
            this.page = page;
        },
        sizeChange() {
            this.page = 0;
        },
        searchKeyword() {
            clearTimeout(this.timeout);            
            var self = this;
            this.timeout = setTimeout(function () {
                self.page = 0;
                self.$store.commit('target/setListHistory', {});
                self.getData();
            }, 650);
        },
        getNotes(open=true, target_id=null,target_name=null) {
            this.$store.commit('target/setNotesList', {});
            if(open){
                this.tmpTargetId = target_id
                this.tmpTargetName = target_name
                this.$store.dispatch('target/getNotesList', [this.tmpTargetId]);
                this.openNote = true
                EventEmit.$emit('overflowHidden', true);
            } else {
                this.setEmptyNotes();
                this.setDefaulDataNotes();
                EventEmit.$emit('overflowHidden', false);
            }
        },
        handleNextNotes() {
            // console.log(target_id)
            this.$store.commit('target/setIsLoadingNext', true);        
            this.$store.dispatch('target/getNotesList', [this.tmpTargetId, true]);
        },
        addNote(){
            EventEmit.$emit('showLoading', true);
            this.$store.dispatch('target/createNotes', [this.tmpTargetId, this.tmpNote])
                .then((resp) => {
                    if (resp.result === 'success') {
                        // this.getNotes(false)
                        this.tmpNote = null
                        this.setDefaulDataNotes()

                        this.getNotes(true, this.tmpTargetId, this.tmpTargetName);
                        this.getData();

                        this.$swal.fire({
                            icon: 'success',
                            title: 'Note successfully added!',
                            timer: 3000,
                        });
                    } else {
                        if (resp.data.message) {
                            EventEmit.$emit('error', resp.data.message);
                        } else {
                            EventEmit.$emit('error');
                        }
                    }
                });
        },
        setEmptyNotes(){
            this.tmpTargetId = null
            this.tmpTargetName = null
            this.tmpNote = null
            this.openNote = false
        },
        setDefaulDataNotes(){
            const dataPagination = { 
                current_page: 1,
                has_next: false,
                has_previous: false,
                next_page_number: 1,
                per_page: 10,
                previous_page_number: null,
                total_data: 0,
                total_page: 1,
            }
            this.$store.commit('target/setDataPagination', dataPagination);
        },
        getDefaultImage(e) {
            e.target.src = this.defaultImage;
        },
        checkMsisdn(data) {
            var msisdn = data.clues.filter(function (clue) {
                return clue.name === 'msisdn'
            })
            return msisdn
        },
        detailTarget(data) {
            // console.log('click')
            var msisdn = this.checkMsisdn(data)
            if (msisdn.length > 0) {
                this.$router.push({ name: 'pageTargetDetailHome', params: { id: data.id ,key: encrypt(msisdn[0].value + '&&msisdn') }  })
            } else {
                let clues = data.clues
                this.$router.push({ name: 'pageTargetDetailHome', params: { id: data.id ,key: encrypt(clues[0].value + '&&'+clues[0].name) }  })
                // EventEmit.$emit('error', 'Target does not have a MSISDN clue')
            }
        },
        
        toString(array) {
            if (array && array.length > 0) {            
                return Object.keys(array).map(function(k){
                    return array[k].operation_name
                }).join(", ")
            } else {
                return '-'
            }
        },
        countClues(array) {
            var ret = {}
            if (this.list_history.data && this.list_history.data.length > 0) {
                for (let i in array) {
                    let key = array[i].name
                    ret[key] = {
                        name: key,
                        count: ret[key] && ret[key].count ? ret[key].count + 1 : 1
                    }
                }
                return Object.values(ret)
            }
            return Object.values(ret)
        },
        datetimeFormat(datetime, format="DD MMM YYYY HH:mm:ss") {
            return moment(datetime, 'YYYY-MM-DDTHH:mm:ssZ').format(format);
        },
    },
    created() {
        this.$store.commit('target/setShowNavbar', true);
        this.getData();
    },
    mounted() {
    },
}
</script>

<style lang="sass">
    .target-label
        @apply .w-3 .h-3 .rounded-full

    @media screen and (max-width: 480px)
        .box-table--target
            table.table
                tbody
                    tr
                        display: block
                        border-width: 1px
                        border-style: solid
                        position: relative
                        padding: 1rem
                        &:hover
                            td
                                background-color: transparent
                        td
                            display: block
                            width: 100%
                            border-bottom: 0!important
                            padding: 0.5rem 0rem
                            &.m-hide
                                display: none
                            &.col-target-label
                                position: absolute
                                left: 36px
                                top: 36px
                        .m-action-target
                            position: absolute
                            right: 1rem
                            top: 0.5rem
                            .btn-icon-40
                                width: 48px
                                height: 48px
                                svg,
                                i.icon
                                    width: 24px
                                    height: 24px
                            .m-hide
                                display: none
.note
    &:hover
        text-decoration: underline
        cursor: pointer

</style>