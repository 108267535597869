<template lang="pug">
.box-map
    p this is map
</template>

<script>
export default {
    name: 'BoxMap'
}
</script>

<style lang="sass">
    .box-map
        @apply .flex .items-center .justify-center .w-full
        min-height: 450px
        background-color: $color-secondary-800
</style>