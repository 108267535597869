<template lang="pug">
.card
    .card__header
        h2.text-xl.mb-4 Device Apps Usage
        .header-cta
            .field.mr-3
                select.field-style(@change="sizeAppsChange($event)")
                    option(v-for="(size, index) in optSizeApps" :key="index" :value="size.value") {{ size.text }}
                .field-icon
                    IconSet(type="chevron-down")

            .field.mr-3
                select.field-style(v-model="orderApps" @change="orderAppsChange($event)")
                    option(v-for="(order, index) in optOrderApps" :key="index" :value="order.value") {{ order.text }}
                .field-icon
                    IconSet(type="chevron-down")

    .card__body
        template(v-if="status_app_usage.status === 'loading'")
            template(v-if="route_name === 'TargetDetailOverall'")
                Loading(show="true")
            template(v-else)
                LoadingAnimation(show="true" title="app-usage")
                
        template(v-else)
            template(v-if="app_usage.app_usage && Object.keys(app_usage.app_usage).length > 0 && Object.keys(app_usage.app_usage).length >= sizeApps")
                .app-lists
                    .card-app(v-for="(n, index) in sizeApps" :key="index")
                        .mr-4
                            figure.w-12.h-12
                                img.w-full.h-full.rounded(:src="app_usage.app_usage[index][3]" @error="getDefaultImage" alt="")
                        .w-full
                            p.text-xs.font-bold.block.truncate.w-full {{ app_usage.app_usage[index][0] }}
                            small.text-red-600 {{ app_usage.app_usage[index][2] }}

            NoDataAvailable(v-else)

</template>

<script>
import { mapState } from 'vuex';
import Loading from '@/components/Loading.vue';
import LoadingAnimation from '@/components/LoadingAnimation.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';
import IconSet from '@/components/IconSet.vue';

export default {
    name: 'OverallAppUsage',
    components: {
        Loading,
        NoDataAvailable,
        IconSet,
        LoadingAnimation,
    },
    data() {
        return {
            defaultImage: this.$store.getters['config/getDefaultImage'],
            sizeApps: 20,
            optSizeApps: [
                {
                    value:20,
                    text:'20 Applications',
                },
                {
                    value:40,
                    text:'40 Applications',
                },
                {
                    value:60,
                    text:'60 Applications',
                },
                {
                    value:80,
                    text:'80 Applications',
                },
                {
                    value:100,
                    text:'100 Applications',
                },
                {
                    value:'all',
                    text:'All Applications',
                },
            ],
            orderApps: 'biggest',
            optOrderApps: [
                {
                    value: 'biggest',
                    text: 'Largest',
                },
                {
                    value: 'smallest',
                    text: 'Smallest',
                },
            ],
        }
    },
    computed: {
        ...mapState('overall', [
            'status_app_usage',
            'app_usage',
        ]),
        route_name() {
            return this.$route.name;
        },
    },
    watch: {
        app_usage(newData, oldData) {
            if (newData.app_usage && Object.keys(newData.app_usage).length > 0) {

                let totalApps = Object.keys(newData.app_usage).length;
                if (totalApps < 20) {
                    this.sizeApps = parseInt(totalApps);
                } else {
                    this.sizeApps = 20;
                }

                // console.log(totalApps)

                if (oldData.app_usage && (oldData.app_usage.length !== totalApps))
                    this.orderApps = 'biggest';
            } else {
                this.sizeApps = 20;
                this.orderApps = 'biggest';
                // console.log(this.orderApps)
            }
        },
    },
    methods: {
        getDefaultImage(e) {
            e.target.src = this.defaultImage;
        },
        sizeAppsChange(e) {
            if (!this.app_usage.app_usage)
                return;
            let appUsage = this.app_usage.app_usage
            let totalApps = appUsage.length;
            if (e.target.value == 'all' || totalApps < e.target.value) {
                this.sizeApps = parseInt(totalApps);
            } else {
                this.sizeApps = parseInt(e.target.value);
            }
        },
        orderAppsChange(e) {
            if (!this.app_usage.app_usage)
                return;
            let appUsage = this.app_usage.app_usage;

            // let sortApp = 
            appUsage.sort(function(a, b) {
                if (e.target.value === 'smallest')
                    return a[1] - b[1];
                return b[1] - a[1];
            });

            // console.log(sortApp);
        },
    },
    mounted() {
        if (this.app_usage.app_usage && Object.keys(this.app_usage.app_usage).length > 0) {
            let totalApps = Object.keys(this.app_usage.app_usage).length;
            if (totalApps < 20) {
                this.sizeApps = parseInt(totalApps);
            } else {
                this.sizeApps = 20;
            }
        }
    },
}
</script>

<style lang="sass" scoped>
    .app-lists
        @apply .grid .grid-cols-6 .gap-8

        .card-app
            @apply .flex .items-center .p-2 .rounded .truncate
            border: 1px solid lighten($color-secondary-500, 5% )

    @media screen and (max-width: 480px)
        .app-lists
            @apply .grid-cols-2 .gap-3
</style>