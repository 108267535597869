<template lang="pug">
.result-page__head.pt-4.flex.justify-between
    .flex.items-center
        .mr-4
            figure.w-12.h-12.rounded-full.overflow-hidden
                img(:src="status_detail.status !== 'loading' && detail.photos && detail.photos.length ? detail.photos[0].file : (detail.photo ? detail.photo : defaultImageProfile)" @error="getDefaultImageProfile")
        .block
            .flex.items-center.mb-2
                .w-2.h-2.rounded-full(:style="'background-color: '+ detail.label +';'")
                .mr-2
                strong(v-if="status_detail.status == 'loading'") loading...
                strong(v-else) {{detail.target_name}}
            .relative
                .target-keyword
                    span {{selectedClues?splitKey(selectedClues):''}}
                    button.link.ml-2.text-xs.font-bold(@click="openSearch()") Change Target
            //- .field
                .relative
                    select.field-style.field-style--clues(v-model="selectedClues" @change="changeClues()")
                        template(v-for="data in detail.clues")
                            option(
                                :value="data.value+'&&'+data.name"
                            ) {{ data.value }}
                            template(
                                v-if="data.detail && data.detail.length > 0"
                                v-for="detail in data.detail"
                            )
                                option(
                                    v-if="detail.value !== null && detail.value !== ''"
                                    :value="detail.value+'&&'+detail.name"
                                ) {{ detail.value }}
                    .field-icon
                        IconSet(
                            type="chevron-down"
                        )
    //- mobile only displaying the key of search
        example, search MSISDN 081234567890
    .m-only
        .flex.items-center.justify-between
            .block 
                span.text-xs MSISDN
                template(v-if="status_subheader.status === 'loading' || status_subheader_without_name.status === 'loading'")
                    Spinner(show="true" size="22")
                p.text-light.text-sm(v-else) {{ subheader.msisdn ? subheader.msisdn : (subheader_without_name.msisdn ? subheader_without_name.msisdn : 'MSISIDN not found') }}
            .block
            button.btn-icon-40(
                v-on:click="showHead = !showHead"
            )
                template(v-if="showHead === false")
                    IconSet(type="chevron-down")
                template(v-else)
                    IconSet(type="chevron-up")
    //- end
    .m-head-menu.flex.items-center(
        v-bind:class="{'is-show' : showHead}"
    )
        //- .rollout
            figure.w-12.h-12
                //- img.w-full.h-full.object-fit.rounded(src="https://static.bmdstatic.com/pk/product/medium/5de487ad80876.jpg")
                img.w-full.h-full.object-fit.rounded(:src="subheader.photo ? subheader.photo : defaultImage" @error="getDefaultImage")
        .rollout.rollout--name(v-if="!checkFeature('subheader|without_name')")
            span.text-xs Name
            template(v-if="status_subheader.status === 'loading' || status_subheader_name.status === 'loading'")
                Spinner(show="true" size="22")
            p(
                v-else
                :class="(subheader.name || subheader_name.name) ? 'text-light text-sm': 'text-gray-600 text-xs italic'"
            ) {{ subheader.name ? subheader.name : (subheader_name.name ? subheader_name.name : 'Name not found') }}
        .rollout
            span.text-xs.msisdn-status
                span MSISDN
                //- template(v-if="status_subheader.status == true || status_subheader_without_name.status == true")
                //- template(v-if="status_subheader_without_name.status == true")
                template(v-if="checkState()")
                    //- .label-gray(v-if="subheader.state == 'unknown' || subheader.state == 'switch off' || subheader.aol == '-1' || (subheader.aol == null && subheader.state == null)") offline
                    //- .label-green(v-else) online
                    template(v-if="subheader.sss || subheader_without_name.sss")
                        div(:class="'label-'+ (subheader.status || subheader_without_name.status =='online'?'green':'gray')") {{ subheader.status || subheader_without_name.status }}
                    template(v-else)
                        .label-green(v-if="['idle','busy','idle_'].includes(subheader.state) || ['idle','busy','idle_'].includes(subheader_without_name.state)") online
                        .label-gray(v-else) offline
            template(v-if="status_subheader.status === 'loading' || status_subheader_without_name.status === 'loading'")
                Spinner(show="true" size="22")
            .flex.justify-between.w-full(v-else)
                .flex-grow.flex.items-center
                    p(
                        :class="(subheader.msisdn || subheader_without_name.msisdn) ? 'text-light text-sm': 'text-gray-600 text-xs italic'"
                    )  {{ subheader.msisdn ? subheader.msisdn : (subheader_without_name.msisdn ? subheader_without_name.msisdn : 'MSISDN not found') }}
        .rollout
            span.text-xs IMEI
            template(v-if="status_subheader.status === 'loading'  || status_subheader_without_name.status === 'loading'")
                Spinner(show="true" size="22")
            p(
                v-else
                :class="(subheader.imei || subheader_without_name.imei) ? 'text-light text-sm': 'text-gray-600 text-xs italic'"
            ) {{ subheader.imei ? subheader.imei : (subheader_without_name.imei ? subheader_without_name.imei : 'IMEI not found') }}
        .rollout
            span.text-xs IMEI-SV
            template(v-if="status_subheader.status === 'loading'  || status_subheader_without_name.status === 'loading'")
                Spinner(show="true" size="22")
            p(
                v-else
                :class="(subheader.imeiSv || subheader_without_name.imeiSv) ? 'text-light text-sm': 'text-gray-600 text-xs italic'"
            ) {{ subheader.imeiSv ? subheader.imeiSv : (subheader_without_name.imeiSv ? subheader_without_name.imeiSv : 'IMEI-SV not found') }}
        .rollout
            span.text-xs IMSI
            template(v-if="status_subheader.status === 'loading'  || status_subheader_without_name.status === 'loading'")
                Spinner(show="true" size="22")
            p(
                v-else
                :class="(subheader.imsi || subheader_without_name.imsi) ? 'text-light text-sm': 'text-gray-600 text-xs italic'"
            ) {{ subheader.imsi ? subheader.imsi : (subheader_without_name.imsi ? subheader_without_name.imsi : 'IMSI not found') }}
        .rollout.rollout--device
            span.text-xs(v-if="is_realtime") Device
            span.text-xs.link.cursor-pointer(
                v-else
                @click="openDetailDevice(true)"
            ) Device
            template(v-if="status_subheader.status === 'loading'  || status_subheader_without_name.status === 'loading'")
                Spinner(show="true" size="22")
            p(
                v-tooltip="subheader.phone ? subheader.phone : (subheader_without_name.phone ? subheader_without_name.phone : '')"
                v-else
                :class="(subheader.phone || subheader_without_name.phone) ? 'text-light text-sm': 'text-gray-600 text-xs italic'"
            )
                | {{ subheader.phone ? subheader.phone : (subheader_without_name.phone ? subheader_without_name.phone : 'Device not found') }}

    //- popup
    .popup-layer(
        v-bind:class="{'is-show' : deviceDetail}"
    )
        .popup-container
            .popup-container__header
                h2.text-xl Device's Detail
                button.btn-icon-40(
                    @click="openDetailDevice(false)"
                )
                    IconSet( type="close")

            .popup-container__body
                template(
                    v-if="subheader.tagDevice && subheader.tagDevice.length > 0"
                )
                    .box-device(
                        v-for="data in subheader.tagDevice" :key="data"
                    )
                        .py-2
                            .label-dark {{ data }}
                template(
                    v-else-if="subheader_without_name.tagDevice && subheader_without_name.tagDevice.length > 0"
                )
                    .box-device(
                        v-for="data in subheader_without_name.tagDevice" :key="data"
                    )
                        .py-2
                            .label-dark {{ data }}
                template(v-else)
                    .flex.flex-wrap.items-center.justify-center.text-gray-600.italic
                        | Device's Detail not found
</template>


<script>
import { mapState } from 'vuex';
import IconSet from '@/components/IconSet.vue';
import Spinner from '@/components/Spinner.vue';
import { encrypt, decrypt } from '@/util/crypto';
import { checkFeature } from '@/config/features';

export default {
    name: 'ResultComponentsHead',
    components: {
        IconSet,
        Spinner,
    },
    data() {
        return {
            showHead : false,
            deviceDetail: false,
            defaultImage: '/images/no-image-device.jpg',
            selectedClues: null,
            defaultImageProfile: this.$store.getters['config/getDefaultImageProfile'],
            devicesData: [
                {
                    name : 'Device',
                },
                {
                    name : 'Mobile',
                },
                {
                    name : 'Smartphone',
                },
                {
                    name : 'Android',
                },
            ]
        }
    },
    computed: {
        ...mapState('subheader', [
            'status_subheader',
            'status_subheader_name',
            'status_subheader_without_name',
            'subheader',
            'subheader_name',
            'subheader_without_name',
        ]),
        ...mapState('target', [
            'detail',
            'status_detail',
        ]),
        route(){
            return this.$route;
        },
        key() {
            return this.$route.params.key;
        },
        id() {
            return this.$route.params.id;
        },
        route_name() {
            return this.$route.name;
        },
        first_historical() {
            let route_historical = {
                name: "ResultHistoricalOverall",
                path: "overall",
                snake: "overall"
            }
            try {
                let tmp_first = this.$router.options.routes.find((item) => item.name == "ResultHistoricalShell").children[0];
                route_historical = {
                    name: tmp_first.name,
                    path: tmp_first.path,
                    snake: tmp_first.path.replace("-", "_")
                }
            } catch (error) {
                console.log('=====error route_historical', error)
            }
            return route_historical
        },
        is_realtime() {
            return this.route.matched[0].name == "ResultRealtimeShell"
        },
    },
    methods: {
        checkState() {
            var onlyHist = this.route.matched[0].name != 'ResultHistoricalShell'

            var status = this.status_subheader_without_name.status == true
            
            var fromReal = status && ((this.subheader && this.subheader.is_realtime) || (this.subheader_without_name && this.subheader_without_name.is_realtime))
            var state = status && (this.subheader && this.subheader.state != null) || (this.subheader_without_name && this.subheader_without_name.state != null)
            console.log({fromReal, state, status, onlyHist})
            return (fromReal || onlyHist) && state

        },
        getDefaultImage(e) {
            e.target.src = this.defaultImage;
        },
        getDefaultImageProfile(e) {
            e.target.src = this.defaultImageProfile;
        },
        decryptData() {
            let data = null;

            if (this.$route.params.key) {
                data = decrypt(this.$route.params.key);
            }
            return data;
        },
        changeClues(){
            let key = this.selectedClues 
            let route_name = '';
            
            if (this.route.matched[0].name == "ResultRealtimeShell") {
                route_name = 'ResultRealtimeRecentLocation';
            } else if(this.route.matched[0].name == "ResultHistoricalShell") {
                route_name = this.first_historical.name;
            } else {
                route_name = 'ResultRecentLocation';
            }

            this.$router.push({
                name: route_name,
                params: { 
                    id: this.id,
                    key: encrypt(key) 
                }
            })
        },
        getData(){
            this.$store.commit('target/setStatusDetail', {
                status: 'loading',
                cancel: null,
                code: null
            })
            this.$store.dispatch('target/getDetail', [this.id])
        },
        splitKey(key) {
            let split_key = key.split('&&');
            return split_key[0];
        },
        openSearch() {
            EventEmit.$emit('openSearchTargetAdvance')
        },

        openDetailDevice(open = true) {
            if(open) {
                this.deviceDetail = true;
                EventEmit.$emit('overflowHidden', true);
            } else {
                this.deviceDetail = false;
                EventEmit.$emit('overflowHidden', false);
            }
        },
        checkFeature(feature) {
            return checkFeature(feature);
        },

    },
    watch: {
        detail(newData, oldData){
            if (newData.target_name === undefined) {
                this.getData()
            }
        },
        key(newData, oldData) {
            var decrypt_data = this.decryptData();
            this.selectedClues = decrypt_data

            this.getData();
        },
        id(newData, oldData) {
            this.getData();
        },
    },
    mounted() {
        console.log('mounted update')
        console.log(this.route.matched[0].name)
        if ((this.route.matched[0].name == "ResultRealtimeShell") || (this.route.matched[0].name == "ResultHistoricalShell")) { 
            
            if (this.detail.target_name === undefined || this.detail.id !== this.id) {
                this.getData()
            }

            var decrypt_data = this.decryptData();
            this.selectedClues = decrypt_data
        }
    }
}
</script>

<style lang="sass" scoped>

    .result-page__head
        .rollout
            @apply .mr-2 .mb-0
            width: 140px
            overflow: hidden
            &--name
                width: auto
                min-width: 140px
                max-width: 340px
            p
                @apply .truncate
        .field
            width: 180px
            .field-style--clues
                font-size: 13px
                padding: 0.5rem 1rem

        .target-keyword
            @apply .flex .items-center .text-sm .px-2 .py-1 .rounded .border .border-gray-800

        .msisdn-status
            // display: flex
            position: relative
            .label-gray,
            .label-green 
                padding: 0px 4px !important
                font-size: 11px
                margin-left: 6px
                height: 16px
                line-height: 18px
                position: absolute
                top: 0
                left: 45px

    @media screen and (max-width: 480px)
        .result-page__head
            padding: 1rem
        .m-head-menu
            display: none
            padding: 1rem 0rem
            &.is-show
                display: block
                
</style>