<template lang="pug">
.card-content
    .flex.flex-wrap(
        class="flex-col-reverse lg:flex-row"
    )
        template(v-if="status_usage.status === 'loading'")
            template(v-if="route_name === 'TargetDetailBrowsingLog'")
                Loading(show="true")
            template(v-else)
                LoadingAnimation(show="true" title="data-usage")
        template(v-else)
            NoDataAvailable(v-if='filterNull')
            template(v-else-if="usage.chart_usage_web_cat && usage.chart_usage_web_cat.categories !== undefined && Object.keys(usage.chart_usage_web_cat.series).length > 0 && Object.keys(usage.top_usage_web_cat).length > 0")
                .block(class="w-full mb-4 lg:w-8/12 lg:mb-0")
                    .box-charts
                        highcharts(
                            :options="chartOptions"
                        )

                .block(class="w-full lg:w-4/12")
                    .flex(
                        class="pl-0 lg:pl-10 flex-row lg:flex-col"
                    )

                    .top-info
                        .top-info__header
                            h3.font-bold Top Web Categories
                        .top-info__body
                            .list-info(v-for="domain in usage.top_usage_web_cat")
                                span {{ domain.name }}
                                strong.text-light {{ domain.counter }}

            NoDataAvailable(v-else)

</template>

<script>
import { mapState } from 'vuex';
import Loading from '@/components/Loading.vue';
import LoadingAnimation from '@/components/LoadingAnimation.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';

export default {
    name: 'DataUsageWebCategories',
    components: {
        Loading,
        NoDataAvailable,
        LoadingAnimation
    },
    data() {
        return {
            title: '',
            chartType: 'bar',
            seriesColor: '#6fcd98',
            colorInputIsSupported: null,
            chartOptions: {
                chart: {
                    type: 'bar'
                },
                title: {
                    text: null
                },
                xAxis: {
                    categories: [],
                    title: {
                        text: null
                    },
                    labels: {
                        step: 1
                    }
                },
                yAxis: {
                    title: {
                        text: null
                    },
                },
                series: [],
                legend:{ enabled:false },
                credits: {
                    enabled: false
                },
            },
            dataCat: null,
            dataSeries: null,
            filterNull: false,
        }
    },
    computed: {
        ...mapState('browsingLog', [
            'status_usage',
            'usage',
        ]),
        route_name() {
            return this.$route.name;
        },
    },
    watch: {
        usage() {
            if (this.usage.chart_usage_web_cat && this.usage.chart_usage_web_cat.categories !== undefined && Object.keys(this.usage.chart_usage_web_cat).length > 0 && Object.keys(this.usage.chart_usage_web_cat.series).length > 0) {
                this.filterChart()
            }
        },
        // filterDateRange() {},
    },
    methods: {
        filterChart(){
            this.dataCat = Object.assign([], JSON.parse(JSON.stringify(this.usage.chart_usage_web_cat.categories)));
            this.dataSeries = Object.assign([], JSON.parse(JSON.stringify(this.usage.chart_usage_web_cat.series)));

            var categoriesWebCat = []
            var seriesDataWebCat = []

            if (this.dataCat.length == 0) {
                this.filterNull=true
            } else {
                this.filterNull=false            

                categoriesWebCat = this.dataCat
                seriesDataWebCat = this.dataSeries
                
                this.chartOptions.xAxis.categories = categoriesWebCat;
                this.chartOptions.series = seriesDataWebCat
            }
        }
    },
    mounted() {
        if (this.usage.chart_usage_web_cat && this.usage.chart_usage_web_cat.categories !== undefined && Object.keys(this.usage.chart_usage_web_cat).length > 0 && Object.keys(this.usage.chart_usage_web_cat.series).length > 0) {
            this.filterChart()
        }
    },
}
</script>
