import { render, staticRenderFns } from "./DetailNotification.vue?vue&type=template&id=376634ae&scoped=true&lang=pug"
import script from "./DetailNotification.vue?vue&type=script&lang=js"
export * from "./DetailNotification.vue?vue&type=script&lang=js"
import style0 from "./DetailNotification.vue?vue&type=style&index=0&id=376634ae&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "376634ae",
  null
  
)

export default component.exports