<template lang="pug">
.card.card--tab
    .card--tab__header
        h2.text-xl Detail History
    .card--tab__body
        .box-tabs
            vue-tabs(
                @tab-change="handleTabChange"
                ref="tabsRef"
            )
                v-tab(
                    title="Monitoring History"
                )
                    MonitoringHistory
                v-tab(title="Location History")
                    LocationHistory

</template>

<script>
import { VueTabs,VTab } from 'vue-nav-tabs'
import { mapState } from 'vuex';
import MonitoringHistory from './MonitoringHistory.vue';
import LocationHistory from './LocationHistory.vue';

export default {
    name: 'TargetInformationHistory',
    components: {
        VueTabs,
        VTab,
        MonitoringHistory,
        LocationHistory,
    },
    computed: {
        ...mapState('locationEs', [
            'status_list'
        ]),
    },
    methods: {
        handleTabChange(tabIndex, newTab, oldTab){
            // console.log('startMapLocationHistory')
            if(newTab.title.toLowerCase() == 'location history' && this.status_list.status !== 'loading')
                EventEmit.$emit('startMapLocationHistory');
        },
    },
}
</script>