<template lang="pug">
.monitoring-log-activity
    .box__body
        .card.card--table.card--no-bg
            .card__header.card--table__header
                h2.text-xl Monitoring Log Activity
                .header-cta
                    .block
                        button.btn-primary.m-btn-icon
                            IconSet(type="download")
                            span.m-hide.ml-3
                            JsonCSV(
                                :data="csvData"
                                :name="csvName"
                            )
                                | Download CSV
            .card--table__body
                .box-filter.px-4.pt-6
                    //- showing data information mobile
                    .m-only
                        .filter-info
                            .flex.items-center.justify-between.mb-3
                                Strong.uppercase.text-xs.font-bold Filtered by
                                button.btn-default-o.btn-small(
                                    type='button'
                                    v-on:click="showFilter = !showFilter"
                                    ) Change Filter
                            p {{ dateRange.startDate }} -  {{ dateRange.endDate }}, All Target, 10 entries

                    //- end showing data information mobile
                    .mobile-filter(
                        v-bind:class="{ 'is-show' : showFilter}"
                    )
                        .m-only
                            .flex.items-center.justify-between.p-4.border-b
                                h2.text-xl Filter
                                button.btn-icon-40(
                                    v-on:click="showFilter = !showFilter"
                                )
                                    IconSet(type="close")
                        .items-filter
                            .field.box-find(class="lg:mr-3 lg:mb-0 mr-0 mb-4")
                                .relative
                                    .field.relative.w-full
                                        input.field-style(
                                            placeholder="Select user (write minimum 3 characters)"
                                            v-model="user_keyword"
                                            @keyup="searchUser()"
                                        )
                                        .field-icon
                                            IconSet(type="search")

                                        .box-find-selected(v-if="user_selected")
                                            .flex.items-center.justify-between
                                                .flex.items-center
                                                    figure.mr-3
                                                        //- img.w-6.h-6.rounded-full.object-fit(src="https://randomuser.me/api/portraits/men/41.jpg")
                                                    span {{user_keyword}}
                                                button.btn-icon-24(@click="setEmptyUser" v-tooltip="'Remove user'")
                                                    IconSet(type="close")

                                    .p-4.absolute.container-box-users(v-if="status_list.status === 'loading'")
                                        .flex.flex-wrap.items-center.justify-center
                                            Spinner(show="true" size="24")
                                    .p-4.absolute.container-box-users(v-else-if="user_keyword.length > 2 && list.data && list.data.length > 0")
                                        .small-heading Choose User
                                        .box-users
                                            .item-user(
                                                v-for="(user,index) in list.data"
                                                :key="user.id"
                                                @click="selectUser(user)"
                                            )
                                                //- figure.mr-3
                                                    img.w-6.h-6.rounded-full.object-fit(src="https://randomuser.me/api/portraits/men/41.jpg")
                                                span {{ user.name }}

                                            //- .item-user
                                                figure.mr-3
                                                    img.w-6.h-6.rounded-full.object-fit(src="https://randomuser.me/api/portraits/men/41.jpg")
                                                span Raul Andrews
                                    .p-4.absolute.container-box-users(v-else-if="list.data && list.data.length == 0")
                                        | No data available.

                            .field(class="lg:mr-3 lg:mb-0 lg:w-64 mr-0 w-full mb-4")
                                .relative.field-style.w-full
                                    date-range-picker(
                                        v-if="dateRange.startDate && dateRange.endDate" 
                                        ref="picker" 
                                        v-model="dateRange" 
                                        @update="pickerUpdate($event)" 
                                        :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }" 
                                        :date-format="dateFormat" 
                                        opens="right"
                                        :ranges="default_ranges"
                                        autoApply=true
                                    )
                                        div(
                                            slot="input" 
                                            slot-scope="picker") 
                                                | {{ picker.startDate | date}} - {{ picker.endDate  | date}}

                                    .field-icon
                                        IconSet(type="calendar")

                            .field(class="lg:mr-3 lg:mb-0 lg:w-32 mr-0 w-full mb-4")
                                select.field-style#filter-record-size(@change="sizeChange($event)" v-model="items_per_page")
                                    option(v-for="(s,index) in size" :key="index" :value="s") {{ s }}
                                .field-icon
                                    IconSet(
                                        type="chevron-down"
                                    )

                            button.btn-primary.mr-3(
                                @click="filterList()"
                            ) Filter
                            button.btn-default(
                                @click="resetList()"
                            ) Reset
                    //- end filter

                .box-table.box-table--monitoring
                    table.table
                        thead.m-hide
                            tr
                                th(v-for="(th,index) in tableThead" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}
                        tbody
                            tr(v-if="status_log_activity.status === 'loading'")
                                td.text-center(:colspan="tableThead.length")
                                    .flex.flex-wrap.items-center.justify-center
                                        Spinner(show="true" size="48")
                                    
                            template(v-else-if="log_activity.data && log_activity.data.length > 0")
                                tr(v-for="(data,index) in log_activity.data" :key="data.id")
                                    //- td(v-if="user.role.label === 'superadmin'")
                                        button.btn-icon-40(v-tooltip="'Show Response'" @click="getResponse(true, data.response)")
                                            IconSet(type="code")
                                    td
                                        template(v-if="data.response.code === 200 || data.response.code === 201")
                                            .label-green.text-xs Success
                                        template(v-else)
                                            .label-red.text-xs Failed
                                    td 
                                        .text-xs {{ data.user?data.user.name:'-' }}
                                    td(v-if="data?.type == 'image'") 
                                        button.link.text-xs(@click="openImage(true,data.key)") Image Target
                                    td(v-else) 
                                        .text-xs {{ data.key?data.key:'-' }}
                                    td 
                                        .text-xs {{ data.type?data.type:'-' }}
                                    //- td 
                                        .text-xs {{ data.size?data.size:'-' }}
                                    td 
                                        .text-xs {{ data.from_date?data.from_date:'-' }}
                                    td 
                                        .text-xs {{ data.to_date?data.to_date:'-' }}
                                    //- td 
                                        .text-xs {{ data.function?data.function:'-' }}
                                    td 
                                        .text-xs {{ data?.wording_module?data?.wording_module:(data?.function?getModule(data?.function):'-') }}
                                        //- .text-xs {{ data.module?getModule(data.module):'-' }}
                                    //- td 
                                        .text-xs {{ data.path_url?data.path_url:'-' }}
                                    td 
                                        .text-xs {{ data.created_at.split('+')[0] }}
                                    td 
                                        .text-xs {{ data.browser?data.browser:'-' }}
                                    td 
                                        .text-xs {{ data.os?data.os:'-' }}
                                    td 
                                        .text-xs {{ data.ip?data.ip:'-' }}
                                    
                            tr(v-else)
                                td.text-center(:colspan="tableThead.length") No Data Available
                //- .card__footer
                .box-table-footer(v-if="status_log_activity.status !== 'loading'")
                    .pagination
                        vue-ads-pagination(
                            :total-items="total_data"
                            :items-per-page="items_per_page"
                            :page="page"
                            :max-visible-pages="5"
                        )
                            template(slot="buttons" slot-scope="props")
                                vue-ads-page-button(
                                    v-for="(button, key) in props.buttons"
                                    :key="key"
                                    v-bind="button"
                                    :class="{'bg-yellow-dark': button.active}"
                                    @page-change="page = button.page"
                                    @range-change="start = button.start; end = button.end"
                                )
    .popup-layer(
        v-bind:class="{'is-show' : openResponse}"
    )
        .popup-container
            .popup-container__header
                h2.text-xl Response Code
                button.btn-icon-40(
                    @click="getResponse(false)"
                )
                    IconSet( type="close")

            .popup-container__body
                .mb-4
                    p Code : {{tmpLogCode}}
                .mb-4
                    //- p {{tmpLogMessage}}
                    vue-json-pretty(:data="tmpLogMessage")
    .popup-layer(
        v-bind:class="{'is-show' : imageModal}"
    )
        .popup-container
            .popup-container__header
                h2.text-xl Image Target
                button.btn-icon-40(
                    @click="openImage(false)"
                )
                    IconSet( type="close")

            .popup-container__body
                .flex.items-center.justify-center
                    img(:src="imageUrl" @error="getDefaultImage")
                
</template>

<script>
import moment from 'moment';
import IconSet from '@/components/IconSet.vue';
import DateRangePicker from 'vue2-daterange-picker';
import { mapState } from 'vuex';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
import Spinner from '@/components/Spinner.vue';
import JsonCSV from 'vue-json-csv'
import Loading from '@/components/Loading.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

export default {
    name: 'pageMonitoringLogIndex',
    components: {
        IconSet,
        Spinner,
        VueAdsPagination,
        VueAdsPageButton,
        DateRangePicker,
        JsonCSV,
        Loading,
        NoDataAvailable,
        VueJsonPretty,
    },
    data() {
        return {
            showFilter: false,
            ShowResponseCode: false,
            filterUser: '',
            optFilterUser: [],

            from_date: '',
            to_date: '',
            dateRange: {
                startDate: '',
                endDate: '',
            },
            default_ranges: {
                'Today': [moment().toDate(), moment().toDate()],
                'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                'Last 7 days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                'Last week': [moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(), moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate()],
            },
            total_data: 0,
            items_per_page: 10,

            size: [10, 25, 50, 100],

            page: 0,
            start: 0,
            end: 0,

            keyword: '',
            timeout: null,

            user_keyword:'',
            user_id:null,
            user_selected:false,

            csvName: 'log_monitoring_activity'+moment().format('YYYY-MM-DD_HH:mm:ss')+'.csv',
            csvData: [],
            
            openResponse: false,
            tmpLogCode:null,
            tmpLogMessage:null,

            defaultImage: this.$store.getters['config/getDefaultImageProfile'],
            imageModal: false,
            imageUrl: '',
        };
    },
    computed: {
        ...mapState('auth', ['user']),
        ...mapState('users', [
            'status_log_activity',
            'log_activity',
            'status_list',
            'list',
        ]),
        route() {
            return this.$route;
        },
        route_name() {
            return this.$route.name;
        },
        tableThead(){ 
            let thead = [
                {
                    name: 'Status',
                },{
                    name: 'User',
                },{
                    name: 'Key',
                },{
                    name: 'Type',
                },
                // {
                //     name: 'Size',
                // },
                {
                    name: 'From Date',
                },{
                    name: 'To Date',
                },
                // {
                //     name: 'Function',
                // },
                {
                    name: 'Module',
                },
                // {
                //     name: 'Path URL',
                // },
                {
                    name: 'Searched On',
                },{
                    name: 'Browser',
                },{
                    name: 'Platform',
                },{
                    name: 'IP',
                },
            ];
            // if(this.user.role.label === 'superadmin'){
            //     thead.unshift({
            //         name: 'Response',
            //     })
            // }

            return thead;
        }
    },
    watch: {
        route_name(newData, oldData) {
            if (newData === 'PageUsersLogActivity') {
                if (oldData !== 'PageUsersLogActivity') {
                    this.items_per_page = 10;
                    this.page = 0;
                    this.keyword = '';
                    this.$store.commit('users/setDetail', {});
                    this.$store.commit('users/setLogActivity', {});
                }

                this.getData(true);
            }
        },
        log_activity(newData) {
            if (Object.keys(newData).length > 0) {
                this.total_data = parseInt(newData.total_data);
                // this.page = (parseInt(newData.current_page) - 1);
            }
            this.getDataCsv(newData.data)

        },
        total_data() {
            // this.pagination();
        },
        // items_per_page() {
            // this.getData();
            // this.pagination();
        // },
        page() {
            this.getData();
        },
        keyword() {},
        user_keyword(newData) {
            if (newData.length < 3)
                this.$store.commit('users/setList', {});
        },
    },
    methods: {
        // openResponse(open = true) {
        //     if(open){
        //         this.ShowResponseCode = true,
        //         EventEmit.$emit('overflowHidden', true);
        //     } else {
        //         this.ShowResponseCode = false,
        //         EventEmit.$emit('overflowHidden', false);
        //     }
        // },
        getData() {
            this.$store.commit('users/setList', {});
            // for dispatch get data
            this.$store.dispatch('users/getLogActivity', [this.user_id, this.items_per_page, (this.page + 1), this.keyword, this.from_date, this.to_date]);

            if (this.log_activity.data && this.log_activity.data.length > 0) {
                this.getDataCsv(this.log_activity.data)
            // console.log(this.log_activity.total_page)
            }
            // this.pagination();
        },
        getDataCsv(data) {
            // let role = this.user.role.label
            if (data.length > 0) {
                this.csvData = data.map((item) => {
                    // if (role === 'superadmin') {
                    //     return {
                    //         key: item.key?item.key:'-',
                    //         type: item.type?item.type:'-',
                    //         // size: item.size?item.size:'-',
                    //         from_date: item.from_date?item.from_date:'-',
                    //         to_date: item.to_date?item.to_date:'-',
                    //         // function: item.function?item.function:'-',
                    //         // module: item.module?this.getModule(item.module):'-',
                    //         module: item.function?this.getModule(item.function):'-',
                    //         // path_url: item.path_url?item.path_url:'-',
                    //         created_at: item.created_at?item.created_at.split('+')[0]:'-',
                    //         browser: item.browser?item.browser:'-',
                    //         platform: item.os?item.os:'-',
                    //         ip: item.ip?item.ip:'-',
                    //         status: (item.response.code === 200 || item.response.code === 201)?'success':'failed',
                    //         user: item.user?item.user.name:'-',
                    //         // log_code: item.response?item.response.code:'-',
                    //         // log_message: item.response?item.response.message:'-',
                    //     };
                    // } else {
                    //     return {
                    //         key: item.key?item.key:'-',
                    //         type: item.type?item.type:'-',
                    //         // size: item.size?item.size:'-',
                    //         from_date: item.from_date?item.from_date:'-',
                    //         to_date: item.to_date?item.to_date:'-',
                    //         // function: item.function?item.function:'-',
                    //         module: item.function?this.getModule(item.function):'-',
                    //         // module: item.module?this.getModule(item.module):'-',
                    //         // path_url: item.path_url?item.path_url:'-',
                    //         created_at: item.created_at?item.created_at.split('+')[0]:'-',
                    //         browser: item.browser?item.browser:'-',
                    //         platform: item.os?item.os:'-',
                    //         ip: item.ip?item.ip:'-',
                    //         status: (item.response.code === 200 || item.response.code === 201)?'success':'failed',
                    //         user: item.user?item.user.name:'-',
                    //     };
                    // }
                    return {
                        status: (item.response.code === 200 || item.response.code === 201)?'success':'failed',
                        user: item.user?item.user.name:'-',
                        key: item.key?item.key:'-',
                        type: item.type?item.type:'-',
                        from_date: item.from_date?item.from_date:'-',
                        to_date: item.to_date?item.to_date:'-',
                        module: item?.wording_module?item?.wording_module:(item.function?this.getModule(item.function):'-'),
                        searched_on: item.created_at?item.created_at.split('+')[0]:'-',
                        browser: item.browser?item.browser:'-',
                        platform: item.os?item.os:'-',
                        ip: item.ip?item.ip:'-',
                    };
                })
            } else {
                this.csvData = [{
                    status: '-',
                    user: '-',
                    key: '-',
                    type: '-',
                    from_date: '-',
                    to_date: '-',
                    module: '-',
                    searched_on: '-',
                    browser: '-',
                    platform: '-',
                    ip: '-',
                }];
            }
        },
        pageChange(page) {
            this.page = page;
        },
        sizeChange() {
            this.page = 0;
        },
        // pagination() {
        //     // update fontawesome to iconfont
        //     var paginationButtonPreview = document.getElementsByClassName("fa-angle-left");

        //     if (paginationButtonPreview[0]) {
        //         var classListPrev = paginationButtonPreview[0].classList;

        //         if (Object.values(classListPrev).filter(key => ['iconfont'].includes(key)).length === 0) {
        //             paginationButtonPreview[0].classList.add('iconfont');
        //             paginationButtonPreview[0].classList.add('iconleft');
        //             paginationButtonPreview[0].style.fontSize = '12px';
        //         }
        //     }

        //     var paginationButtonNext = document.getElementsByClassName("fa-angle-right");

        //     if (paginationButtonNext[0]) {
        //         var classListNext = paginationButtonNext[0].classList;

        //         if (Object.values(classListNext).filter(key => ['iconfont'].includes(key)).length === 0) {
        //             paginationButtonNext[0].classList.add('iconfont');
        //             paginationButtonNext[0].classList.add('iconright');
        //             paginationButtonNext[0].style.fontSize = '12px';
        //         }
        //     }
        // },
        datetimeFormat(datetime) {
            return moment(datetime, 'YYYY-MM-DDTHH:mm:ssZ').format('DD MMM YYYY HH:mm:ssZ');
        },
        /* eslint-disable no-unused-vars */
        dateFormat (classes, date) {
            if (!classes.disabled) {
                classes.disabled = moment(date.getTime()).subtract({ hours: 12})._d > new Date();
            }
            return classes;
        },
        getResponse(open=true, log=null){
            if (open) {
                EventEmit.$emit('overflowHidden', true);
                this.tmpLogCode = log.code?log.code:null
                this.tmpLogMessage = null
                if (log.message) {
                    var msg = log.message
                    msg = msg.replace(/'/g, '"').replace(/None/g, '"None"').replace(/False/g, '"False"').replace(/'/g, '"').replace(/True/g, '"True"')
                    this.tmpLogMessage = JSON.parse(msg)
                }
                this.openResponse = true
            } else {
                EventEmit.$emit('overflowHidden', false);
                this.tmpLogCode = null
                this.tmpLogMessage = null
                this.openResponse = false
            }
        },
        resetList() {
            this.$swal.fire({
                icon: 'warning',
                title: 'Are you sure you want to reset filter?',
                confirmButtonText: 'Yes!',
                confirmButtonColor: '#DD6B55',
                showCancelButton: true,
                allowOutsideClick: false,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.defaultFilter()
                    this.getData()
                }
            });
        },
        filterList() {
            this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');
            
            this.getData();
        },
        defaultFilter(){
            let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
            const default_date = this.$store.getters['config/getDefaultDate'](def);

            this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
            this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();
            this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');

            this.user_keyword=''
            this.user_id=null
            this.items_per_page=10
        },
        async pickerUpdate(e) {
            // console.log('========= DateRangePicker on update')
            // console.log(e)
            let ranges = moment(e.endDate.getTime()).diff(moment(e.startDate.getTime()), 'days') + 1;

            if (ranges > this.$store.getters['config/getValidateRange']) {
                let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
                const default_date = this.$store.getters['config/getDefaultDate'](def);
                
                this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
                this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();
    
                this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');
                
                this.$swal.fire({
                    icon: 'error',
                    title: 'Date range is not more than ' + this.$store.getters['config/getValidateRange'] + ' days!',
                    text: 'Your date range is ' + ranges + ' days.',
                })

                // return;
            } else {
                this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');
            }
        },
        searchUser() {
            // clear timeout variable
            clearTimeout(this.timeout);

            var self = this;
            this.timeout = setTimeout(function () {
                if (self.user_keyword.length > 2) {
                    self.$store.commit('users/setList', {});
                    self.$store.dispatch('users/getList', [30, 1, self.user_keyword]);
                }
            }, 650);
        },
        selectUser(user){
            this.user_keyword = user.name
            this.user_id = user.id
            this.$store.commit('users/setList', {});
            this.user_selected = true
        },
        setEmptyUser() {
            this.user_keyword = ''
            this.user_id=null
            this.user_selected = false
        },
        getModule(string) {
            let def = '-'
            string = string.toLowerCase()
            console.log("+++getModule|string: ", string)
            switch(string) {
                case 'kk':
                case 'ktp':
                    def = 'Digital ID';
                    break;
                case 'recent-location':
                    def = 'Recent Location';
                    break;
                case 'subheader':
                case 'subheader-subintel':
                case 'subheader-target-lbs':
                case 'subheader-target':
                    def = 'Summary Data';
                    break;
                case 'subheader-name':
                    def = 'Profile Name';
                    break;
                case 'app-usage':
                    def = 'App Usage';
                    break;
                case 'browsing-all':
                    def = 'Browsing Log';
                    break;
                case 'device-history':
                    def = 'Device History';
                    break;
                case 'aerial':
                    def = 'Aerial Log';
                    break;
                case 'location-log':
                case 'location-aerial-log':
                    def = 'Location Log';
                    break;
                case 'tethered-device':
                    def = 'Tethered Device';
                    break;
                case 'location-movement':
                    def = 'Location Movement';
                    break;
                case 'history-device-roles':
                    def = 'History Device Roles';
                    break;
                case 'prediction-name':
                    def = 'Prediction Name';
                    break;
                case 'recent-location-detail':
                    def = 'Detail Target - Recent Location';
                    break;
                case 'personal-data':
                    def = 'Digital Trace';
                    break;
                case 'fr':
                case 'fr-find':
                case 'fr-new-search':
                    def = 'Face Recognition';
                    break;
            }
            return def
        },
        getDefaultImage(e) {
            e.target.src = this.defaultImage;
        },
        openImage(status, url='') {
            EventEmit.$emit('overflowHidden', status);
            this.imageModal = status
            this.imageUrl = url 
        }
    },
    // created() {
    //     this.defaultFilter()
    //     this.getData();
    // },
    mounted() {
        // if (this.route_name === 'pageMonitoringLog') {
            // reset date filter
        this.defaultFilter()
        this.getData();
        // }
    },
    filters: {
        date: function (date) {
            return moment(date).format('YYYY-MM-DD');
        },
    },
}
</script>

<style lang="sass" scoped>
    .box-table--monitoring
        tr
            th, td
                @apply .whitespace-no-wrap

    .box-find
        width: 100%
        max-width: 400px
        background-color: $color-secondary-500
        border-radius: 4px
        position: relative

    .container-box-users
        top: 100%
        z-index: 100
        background-color: $color-secondary-700
        width: 100%

    .box-users
        .item-user
            @apply .flex .items-center .p-2 .border-b .cursor-pointer
            &:hover 
                background-color: $color-secondary-600
            &:last-child
                border-bottom: 0px
    .box-find-selected
        background-color: $color-secondary-600
        padding-top: 2px
        @apply .absolute .inset-0 .rounded .px-2 .border
        border-color: #2d3061
    #filter-record-size
        min-width: 80px

</style>