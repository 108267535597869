<template lang="pug">
.digital-cyber-shell
    .box
        .box__header
            TitlePage(
                title="Ultimo"
                :breadcrumb="breadcrumb"
                )
        .box__body
            nav.menu
                ul
                    li
                        router-link(
                            :to="{ name: 'pageUltimoForm', params: {} }"
                        ) Fetch API
                    li
                        router-link(
                            :to="{ name: 'pageUltimoAsyncDataForm', params: {} }"
                        ) Fetch Async Data
            router-view

</template>

<script>
import TitlePage from '@/components/shell/TitlePage.vue'
export default {
    name: 'UltimoShell',
    components: {
        TitlePage,
    },
    data() {
        return {
        }
    },
    computed: {
        route() {
            return this.$route;
        },
        id() {
            return this.$route.params.id;
        },
        breadcrumb() {
            let breadcrumb = [
                {
                    name: 'Ultimo',
                    url: '/ultimo'
                }
            ];

            return breadcrumb;
        },
    },
    watch: {
        route(newData) {
            if (newData.matched[0] && newData.matched[0].components.default.name === 'UltimoShell')
                window.scrollTo(0,0);
        },
        id() {},
        breadcrumb() {},
    },
    created() {
    },
    mounted() {
        // 
    },
    beforeDestroy() {
        this.$store.dispatch('ultimo/resetAll'); 
    },
}
</script>