<template lang="pug">
.card.card--tab
    .card--tab__header
        h2.text-xl Possible Location
    .card--tab__body
        .box-tabs
            vue-tabs(
                @tab-change="handleTabChange"
                ref="tabsRef"
            )
                v-tab(title="Office")
                    PossibleLocationOffice
                v-tab(title="Home")
                    PossibleLocationHome
                v-tab(title="Top Location")
                    PossibleLocationTop
</template>

<script>
import { mapState } from 'vuex';
import { VueTabs,VTab } from 'vue-nav-tabs'
import PossibleLocationOffice from './PossibleLocationOffice.vue'
import PossibleLocationHome from './PossibleLocationHome.vue'
import PossibleLocationTop from './PossibleLocationTop.vue'

export default {
    name: 'LocationLogPossibleLocation',
    components: {
        VueTabs,
        VTab,
        PossibleLocationOffice,
        PossibleLocationHome,
        PossibleLocationTop,
    },
    computed: {
        ...mapState('locationLog', [
          'status_possible_location',
        ]),
    },
    methods: {
        handleTabChange(tabIndex, newTab, oldTab) {
            if (this.status_possible_location.status !== 'loading') {
                var tab = 'startMap'+newTab.title
                tab = tab.replace(/\s/g, '');
                // console.log(tab)
                EventEmit.$emit(tab)
            }
        },
    },
}
</script>