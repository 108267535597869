<template lang="pug">
.box__body
    .card.card--table.card--no-bg
        .card--table__body
            //- filter
            .box-filter
                //- .box-filter.px-4.pt-6
                //- showing data information mobile
                .m-only
                    .filter-info
                        .flex.items-center.justify-between.mb-3
                            Strong.uppercase.text-xs.font-bold Filtered by
                            button.btn-default-o.btn-small(
                                type='button'
                                v-on:click="showFilter = !showFilter"
                                ) Change Filter
                        p {{ dateRange.startDate }} -  {{ dateRange.endDate }}

                //- end showing data information mobile
                .mobile-filter(
                    v-bind:class="{ 'is-show' : showFilter}"
                )
                    .m-only
                        .flex.items-center.justify-between.p-4.border-b
                            h2.text-xl Filter
                            button.btn-icon-40(
                                v-on:click="showFilter = !showFilter"
                            )
                                IconSet(type="close")
                    .items-filter
                        .field.mr-3
                            .relative.mr-3.field-style
                                date-range-picker(
                                    v-if="dateRange.startDate && dateRange.endDate" 
                                    ref="picker" 
                                    v-model="dateRange" 
                                    @update="pickerUpdate($event)" 
                                    :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }" 
                                    :date-format="dateFormat" 
                                    opens="right"
                                    :ranges="default_ranges"
                                    autoApply=true
                                )
                                    div(
                                        slot="input" 
                                        slot-scope="picker") 
                                            | {{ picker.startDate | date}} - {{ picker.endDate  | date}}

                                .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2
                                    IconSet(type="calendar")

                        .field.mr-3
                            select.field-style#filter-record-size(@change="sizeChange($event)" v-model="items_per_page" style="min-width:80px")
                                    option(v-for="(s,index) in size" :key="index" :value="s") {{ s }}
                            .field-icon
                                IconSet(
                                    type="chevron-down"
                                )

                        .block(v-if="monitoring_list.data && monitoring_list.data.length > 0")
                            .flex.flex-wrap.items-center.justify-between
                                .field.mr-3.relative
                                JsonCSV(
                                    :data="csvData"
                                    :name="csvName"
                                    class="btn btn-primary"
                                )
                                    | Download CSV
            //- end filter
        .box-table.box-table--target
            table.table
                thead.m-hide
                    tr
                        th(v-for="(th,index) in tableThead" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}
                tbody
                    tr(v-if="status_monitoring_list.status === 'loading'")
                        td.text-center(:colspan="tableThead.length")
                            .flex.flex-wrap.items-center.justify-center
                                Spinner(show="true" size="48")
                            
                    template(v-else-if="monitoring_list.data && monitoring_list.data.length > 0")
                        tr(v-for="(data,index) in monitoring_list.data" :key="data.id")
                            //- td 
                                strong {{data.multi_tracking.group_name}}
                            //- td 
                                button.flex.items-center(@click="detailTarget(data)")
                                    figure.w-12.h-12.mr-4
                                        img.w-full.h-full.object-cover.rounded-full.bg-gray-100(:src="data.target.photo ? data.target.photo : getDefaultImage" @error="getDefaultImage")

                                    strong.text-link {{ data.target.name }}
                            td 
                                small {{data.started_at}} • {{data.started_by.name}} 
                            td 
                                small {{data.stoped_at}} • {{data.stoped_by.name}}
                            td {{freqName(data.frequency)}}
                            td {{data.msisdn}}
                            td(:class="data.status.toLowerCase()==='started' ? 'status-active' : 'status-inactive'") 
                                span {{ data.status }}
                    tr(v-else)
                        td.text-center(:colspan="tableThead.length") No Data Available
            .mt-5
            vue-ads-pagination(
                :total-items="total_data"
                :items-per-page="items_per_page"
                :page="page"
                :max-visible-pages="5"
            )
                template(slot="buttons" slot-scope="props")
                    vue-ads-page-button(
                        v-for="(button, key) in props.buttons"
                        :key="key"
                        v-bind="button"
                        :class="{'bg-yellow-dark': button.active}"
                        @page-change="page = button.page"
                        @range-change="start = button.start; end = button.end"
                    )
</template>

<script>
import IconSet from '@/components/IconSet.vue';
import { mapState } from 'vuex';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
// import '../../../../node_modules/vue-ads-pagination/dist/vue-ads-pagination.css';
import Spinner from '@/components/Spinner.vue';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import moment from 'moment';
import { encrypt,decrypt } from '@/util/crypto';
import JsonCSV from 'vue-json-csv';

export default {
    name: 'STMonitoringHistory',
    components: {
        IconSet,
        Spinner,
        VueAdsPagination,
        VueAdsPageButton,
        DateRangePicker,
        JsonCSV,
    },
    data() {
        return {
            showFilter: false,
            tableThead: [
                {
                    name: 'Start',
                },{
                    name: 'Stop',
                },{
                    name: 'Frequency',
                },{
                    name: 'MSISDN',
                },{
                    name: 'Status',
                }
            ],
            
            total_data: 0,
            items_per_page: 10,

            size: [10, 25, 50, 100],

            page: 0,
            start: 0,
            end: 0,

            keyword: '',
            timeout: null,

            from_date: '',
            to_date: '',

            dateRange: {
                startDate: '',
                endDate: '',
            },
            default_ranges: {
                'Today': [moment().toDate(), moment().toDate()],
                'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                'Last 7 days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                'Last week': [moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(), moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate()],
                // 'This month': [moment().startOf('month'), moment().endOf('month')],
                // 'This year': [moment().startOf('year'), moment().endOf('year')],
                // 'Last month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            },
            defaultImage: this.$store.getters['config/getDefaultImage'],
            freqOpt: this.$store.getters['config/getFreqMonitoring'],
            csvName: 'monitoring_history_list_'+moment().format('YYYY-MM-DD_HH:mm:ss')+'.csv',
            csvData: [],

        }
    },
    computed: {
        ...mapState('target', [
            'status_monitoring_list',
            'monitoring_list',
        ]),
        route_name() {
            return this.$route.name;
        },
        id() {
            return this.$route.params.id;
        },
        key() {
            return decrypt(this.$route.params.key).split('&&');
        },
    },
    methods: {
        freqName(frequency) {
            var freq = this.freqOpt.filter( val => val.value == frequency )
            if (freq.length > 0) {
                return freq[0].name
            } else {
                return '-'
            }

        },
        datetimeFormat(datetime) {
            // return moment(datetime, 'YYYY-MM-DDTHH:mm:ssZ').format('DD MMM YYYY HH:mm:ssZ');
            return datetime;
        },
        /* eslint-disable no-unused-vars */
        dateFormat (classes, date) {
            if (!classes.disabled) {
                classes.disabled = moment(date.getTime()).subtract({ hours: 12})._d > new Date();
            }
            return classes;
        },
        detailMaps(id) {
            console.log(id)
        },
        getData() {
            this.$store.dispatch('target/getMonitoringList', [this.id, this.key[0], this.items_per_page, (this.page + 1), this.keyword, this.from_date, this.to_date]);

            if (this.monitoring_list.total_page && this.monitoring_list.total_page > 1) {
                this.pagination();
            }
        },
        getDataCsv(data) {
            this.csvData = data.map((item) => {
                return {
                    started_at: item.started_at?item.started_at:'-',
                    started_by: item.started_by?item.started_by.name:'-',
                    stoped_at: item.stoped_at?item.stoped_at:'-',
                    stoped_by: item.stoped_by?item.stoped_by.name :'-',
                    frequency: item.frequency?this.freqName(item.frequency):'-',
                    msisdn: item.msisdn?item.msisdn:'-',
                    status: item.status?item.status:'-',
                };
            })
        },
        pageChange(page) {
            this.page = page;
        },
        rangeChange(start, end) {
            this.start = start;
            this.end = end;
        },
        sizeChange() {
            this.page = 0;
        },
        pagination() {
            // update fontawesome to iconfont
            var paginationButtonPreview = document.getElementsByClassName("fa-angle-left");

            if (paginationButtonPreview[0]) {
                var classListPrev = paginationButtonPreview[0].classList;

                if (Object.values(classListPrev).filter(key => ['iconfont'].includes(key)).length === 0) {
                    paginationButtonPreview[0].classList.add('iconfont');
                    paginationButtonPreview[0].classList.add('iconleft');
                    paginationButtonPreview[0].style.fontSize = '12px';
                }
            }

            var paginationButtonNext = document.getElementsByClassName("fa-angle-right");

            if (paginationButtonNext[0]) {
                var classListNext = paginationButtonNext[0].classList;

                if (Object.values(classListNext).filter(key => ['iconfont'].includes(key)).length === 0) {
                    paginationButtonNext[0].classList.add('iconfont');
                    paginationButtonNext[0].classList.add('iconright');
                    paginationButtonNext[0].style.fontSize = '12px';
                }
            }
        },
        searchKeyword() {
            // clear timeout variable
            clearTimeout(this.timeout);
            
            var self = this;
            this.timeout = setTimeout(function () {
                // enter this block of code after 1 second
                // handle stuff, call search API etc.
                self.page = 0;
                self.$store.commit('target/setTrackingList', {});

                // if (self.keyword.length > 0) {
                    self.getData();
                // }
            }, 650);
        },

        getDefaultImage(e) {
            e.target.src = this.defaultImage;
        },
        async pickerUpdate(e) {
            let ranges = moment(e.endDate.getTime()).diff(moment(e.startDate.getTime()), 'days') + 1;

            if (ranges > this.$store.getters['config/getValidateRangeQueryByRoute'](this.route_name)) {
                const default_date = this.$store.getters['config/getDefaultDate']();

                this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
                this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();

                this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');

                this.$swal.fire({
                    icon: 'error',
                    title: 'Date range is not more than ' + this.$store.getters['config/getValidateRangeQueryByRoute'](this.route_name) + ' days!',
                    text: 'Your date range is ' + ranges + ' days.',
                })

                // return;
            } else {
                this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');

                this.getData();
            }
        },
        detailTarget(data) {
            var msisdn = data.msisdn
            this.$router.push({ name: 'pageTargetDetailHome', params: { id: data.target.id ,key: encrypt(msisdn + '&&msisdn') }  })
        },
    },
    watch: {
        monitoring_list(newData) {
            if (Object.keys(newData).length > 0) {
                this.total_data = parseInt(newData.total_data);
                // this.page = (parseInt(newData.current_page) - 1);

                if (newData.data && newData.data.length > 0) {
                    this.getDataCsv(newData.data);
                }
            } else {
                this.total_data = 0;
            }
            // if (this.monitoring_list.data && this.monitoring_list.data.length > 0) {
            //     this.getDataCsv(this.monitoring_list.data);
            // }
        },
        total_data() {
            // this.pagination();
        },
        items_per_page() {
            this.getData();
            // this.pagination();
        },
        page() {
            this.getData();
        },
        keyword() {},
        // id() {
        //     if (this.id != undefined) {
        //         this.getData();
        //     }
        // },
        route_name(newData, oldData) {
            if (newData === 'pageTargetDetailHome') {
                if (oldData !== 'pageTargetDetailHome') {
                    // this.dateRange.startDate = moment().toDate();
                    // this.dateRange.endDate = moment().toDate();

                    // this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                    // this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');

                    const default_date = this.$store.getters['config/getDefaultDate']();
                    this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
                    this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();

                    this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                    this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');
            
                    this.items_per_page = 10;
                    this.page = 0;
                    this.keyword = '';
                    this.$store.commit('target/setMonitoringList', {});
                    this.$store.commit('target/setStatusMonitoringList', {
                        status: 'loading',
                        cancel: null,
                        code: null
                    });
                }

                this.getData();
            }
        },
        id() {},
        key() {},
    },
    filters: {
        date: function (date) {
            return moment(date).format('YYYY-MM-DD');
        },
    },

    mounted() {
        if (this.route_name === 'pageTargetDetailHome') {
            // reset date filter
            // this.dateRange.startDate = moment().toDate();
            // this.dateRange.endDate = moment().toDate();

            // this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            // this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');

            const default_date = this.$store.getters['config/getDefaultDate']();

            this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
            this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();

            this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');

            // this.pagination();
            this.getData();
        }
    },
}
</script>

<style lang="sass">
    .draw-area
        height: 500px
    .cell-info-window
        vertical-align: top
    .btn-primary
        cursor: pointer !important
</style>