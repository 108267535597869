import axios from "@/axios-main";
import axiosOrigin from "axios";
import { env } from '@/config/env';

let cancelAerialLog;
var cancelToken = axiosOrigin.CancelToken;

const aerialLog = {
  namespaced: true,
  state: {
    module:'Aerial Log',
    default_range: env.VUE_APP_AERIAL_RANGE?env.VUE_APP_AERIAL_RANGE:13,

    record_size: null,
    from_date: null,
    to_date: null,

    status: false,
    status_aerial_log: {
      status:false,
      cancel:null, 
      code:null
    },

    aerial_log: {},
  },
  mutations: {
    setRecordSize: (state, data) => {
      state.record_size = data;
    },
    setFromDate: (state, data) => {
      state.from_date = data;
    },
    setToDate: (state, data) => {
      state.to_date = data;
    },

    setStatus: (state, status) => {
      state.status = status;
    },
    setStatusAerialLog: (state, status) => {
      state.status_aerial_log = status;
    },

    setAerialLog: (state, data) => {
      state.aerial_log = data;
    },
  },
  getters: {},
  actions: {
    async getResultData({ state, rootGetters, commit, dispatch }, [key, type, service_source=null, from_date_custom=null, to_date_custom=null]) {
      console.log('=====from_date_custom', from_date_custom)
      console.log('=====to_date_custom', to_date_custom)

      const default_date = rootGetters['config/getDefaultDate'](state.default_range);

      let record_size = state.record_size!==null ? state.record_size : rootGetters['config/getDefaultRecordSize'];
      let from_date = state.from_date!==null ? state.from_date : default_date.from_date;
      let to_date = state.to_date!==null ? state.to_date : default_date.to_date;
      if (from_date_custom && to_date_custom) {
        from_date = from_date_custom
        to_date = to_date_custom
      }
      console.log('=====from_date', from_date)
      console.log('=====to_date', to_date)
      await dispatch('setLoading');
      
      await dispatch('getAerialLog', [key, type, record_size, from_date, to_date, service_source]);

      commit('setStatus', true);
    },

    async setLoading({ commit, state }) {
      await commit('setStatus', 'loading');
      await commit('setStatusAerialLog', {
        status: 'loading', 
        cancel: state.status_aerial_log.cancel,
        code: null
      });
    },

    async setEmpty({ state, commit }) {
      commit('setStatus', false);

      if (state.status_aerial_log.cancel !== null && state.status_aerial_log.status === 'loading')
        await state.status_aerial_log.cancel('Operation canceled by the user.');

      commit('setStatusAerialLog', {
        status: false,
        cancel: null,
        code: null
      });

      commit('setAerialLog', {});
    },

    async getAerialLog({ commit, dispatch, state, rootGetters }, [key, type, record_size, from_date, to_date, service_source]) {

      if (state.status_aerial_log.cancel !== null && state.status_aerial_log.status === 'loading')
        await state.status_aerial_log.cancel('Operation canceled by the user.');

      let service = 'service';
      let cache = false;

      if (service_source === 'internal') {
        service = 'service-int'
      } else if (service_source === 'external') {
        service = 'service-ext'
        cache = true;
      }
      var lsKey = key + '_' + service_source + '_location-aerial-log_' + from_date + '-' + to_date;
      try {
        let resp = rootGetters['config/getApiResp'](lsKey)
        if (resp==null) throw "Empty"
        
        dispatch('setAllData', JSON.parse(resp));

      } catch (error) {
        console.log(error)
        if (state.status_aerial_log.cancel !== null && state.status_aerial_log.status === 'loading')
          await state.status_aerial_log.cancel('Operation canceled by the user.');

        let params = new FormData();
        params.append('key', key);
        params.append('type', type);
        params.append('size', record_size);
        params.append('from_date', from_date);
        params.append('to_date', to_date);
      
        // await axios.post('api/v1/'+service+'/overall/aerial', params, {
        await axios.post('api/v1/' + service + '/overall/location-aerial-log', params, {
          cancelToken: new cancelToken(function executor(c) {
            cancelAerialLog = c;
            commit('setStatusAerialLog', {
              status: 'loading',
              cancel: c,
              code: null,
            });
          }),
        })
        .then((resp) => {
          dispatch('setAllData', resp);
          if (cache) {            
            let dt = {
              resp: resp, 
              lsKey: lsKey
            }
            dispatch('config/addApiResp', dt, {root: true});
          }
        })
        .catch((resp) => {
          let result = {
            status: 'failed',
            cancel: null,
            code: resp.response && resp.response.status ? resp.response.status : null
          };
  
          if (axiosOrigin.isCancel(resp)) {
            result.status = 'loading';
            result.cancel = cancelAerialLog;
          }
  
          commit('setStatusAerialLog', result);
          if (!axiosOrigin.isCancel(resp)){
            // dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null),state.module], { root: true });
            let respMsg = resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null
            if(respMsg!=null && result.code != 500) {
              dispatch('errors/handleApiResponse', [result.code, respMsg,state.module], { root: true });
            }
          } 
        });
      }
    },
    async setAllData({ commit }, resp) {
      // console.log(resp.data)
      let data = resp.data.aerial_log || {};
      let result = {
        status: true,
        cancel: null,
        code: resp.response && resp.response.status ? resp.response.status : null,
      };

      commit('setAerialLog', data);

      if (Object.keys(data).length===0) {
        result.status = 'empty';

      } else {
        if (
          data.heatmap.length == 0 &&
          Object.keys(data.top_location.top_city).length == 0 &&
          Object.keys(data.top_location.top_province).length == 0 &&
          Object.keys(data.top_location.top_region).length == 0 &&
          Object.keys(data.top_location.top_village).length == 0
        ) {
          result.status = 'empty';

        } else if (
          data.heatmap.length == 0 ||
          Object.keys(data.top_location.top_city).length == 0 ||
          Object.keys(data.top_location.top_province).length == 0 ||
          Object.keys(data.top_location.top_region).length == 0 ||
          Object.keys(data.top_location.top_village).length == 0
        ) {
          result.status = 'partially-empty';

        }
      }

      commit('setStatusAerialLog', result);
    },
  },
};

export default aerialLog;
