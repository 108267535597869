<template lang="pug">
button(@click="copyTargetToClipboard")
  span.pb-0.block.pr-2.mb-0(v-if="label" style="margin-bottom: 0 !important") {{ label }}
  //- i: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-copy"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>
  IconSet(type="copy" :size="icon_size")
</template>

<script>
import { env } from "@/config/env";
import { mapState } from 'vuex';
import IconSet from '@/components/IconSet.vue';

export default {
  name: 'CopyRecentLocationTarget',
  props: ['label', 'recent_location', 'log_path', 'icon_size'],
  components: {
    IconSet,
  },
  data: () => ({}),
  computed: {
    ...mapState('auth', ['user']),
  },
  methods: {
    async copyTargetToClipboard() {
      const copiedText = this.formatCopyTargetText(this.recent_location)
      // await navigator.clipboard.writeText(copiedText);
      if (navigator.clipboard && window.isSecureContext) {
        console.log("clipboard.writeText")
        await navigator.clipboard.writeText(copiedText);
        this.$store.dispatch('usersLog/storeLogCopy', ['copy', this.log_path, copiedText])
      } else {
        // Use the 'out of viewport hidden text area' trick
        const textArea = document.createElement("textarea");
        textArea.value = copiedText;

        // Move textarea out of the viewport so it's not visible
        textArea.style.position = "absolute";
        textArea.style.left = "-999999px";

        document.body.prepend(textArea);
        textArea.select();

        try {
          console.log("execCommand")
          document.execCommand('copy');
          this.$store.dispatch('usersLog/storeLogCopy', ['copy', this.log_path, copiedText])
        } catch (error) {
          console.error(error);
        } finally {
          textArea.remove();
        }
      }
    },
    formatCopyTargetText(data) {
      const headerText = this.implementHeaderDynamicConfig()
      const textOrders = env.VUE_APP_ORDER_COPY_TARGET.split("|");
      const subheader = data.subheader
      const subheader_without_name = data.subheader_without_name

      const rawDate = data?.features?.[0]?.properties?.dtm
      const datetime = rawDate ? (rawDate.includes('WIB') ? rawDate : rawDate + ' WIB') : '-'
      const imei = subheader?.imei ? subheader?.imei : (subheader_without_name?.imei ? subheader_without_name?.imei : data?.features?.[0]?.properties?.imei || "-")
      const imsi = subheader?.imsi ? subheader?.imsi : (subheader_without_name?.imsi ? subheader_without_name?.imsi : data?.features?.[0]?.properties?.imsi || "-")
      const device = subheader?.phone || subheader_without_name?.phone || data?.features?.[0]?.properties?.ttype || "-"
      console.log("Imei Source", subheader?.imei ? `Subheader` : (subheader_without_name?.imei ? `subheader_without_name` : `CP` || "-"))
      console.log("Imsi Source", subheader?.imsi ? `Subheader` : (subheader_without_name?.imsi ? `subheader_without_name` : `CP` || "-"))
      console.log("Device Source", subheader?.phone ? `Subheader` : (subheader_without_name?.phone ? `subheader_without_name` : `CP` || "-"))
      console.log(`device = ${subheader?.phone} || ${subheader_without_name?.phone} || ${data?.features?.[0]?.properties?.ttype} || "-"`)

      const fields = {
        'datetime': `TIME: ${datetime || "-"}\n`,
        'msisdn': `MSISDN: ${data?.features?.[0]?.properties?.msisdn || "-"}\n`,
        'imsi': `IMSI: ${imsi}\n`,
        'provider': `MNO: ${data?.features?.[0]?.properties?.provider || "-"}\n`,
        'imei': `IMEI: ${imei}\n`,
        'device': `DEVICE: ${device}\n`,
        'tac': `TAC: ${data?.features?.[0]?.properties?.tac || "-"}\n`,
        'enb': `ENB: ${data?.features?.[0]?.properties?.enb || "-"}\n`,
        'lac': `LAC: ${data?.features?.[0]?.properties?.lac || "-"}\n`,
        'ci': `CELL ID: ${data?.features?.[0]?.properties?.ci || "-"}\n`,
        'network': `BTS TYPE: ${data?.features?.[0]?.properties?.net || "-"}\n`,
        'bts': `BTS ID: ${data?.features?.[0]?.properties?.bts || "-"}\n`,
        'address': `BTS ADDRESS: ${data?.features?.[0]?.properties?.alamat || "-"}\n`,
        'state': `STATE: ${data?.features?.[0]?.properties?.state || "-"}\n`,
        'map': `BTS MAP: https://maps.google.com/maps?q=${data?.features[0]?.geometry?.coordinates[1] || 0
          },${data?.features[0]?.geometry?.coordinates[0] || 0}\n`
      }
      const fieldsArray = Object.entries(fields)

      let copiedText = `${headerText}\n`;
      copiedText += `\n`;
      for (const text of textOrders) {
        const index = fieldsArray.findIndex(obj => obj[0] == text)
        if (index !== -1)
          copiedText += fieldsArray[index][1]
      }

      return copiedText;
    },
    implementHeaderDynamicConfig() {
      let headerText = env.VUE_APP_HEADER_COPY_TARGET;
      const configs = {
        operator_name: this.user.name
      }
      const configsArray = Object.entries(configs)
      for (const config of configsArray) {
        headerText = headerText.replace(`{${config[0]}}`, config[1])
      }
      return headerText
    }
  }
}
</script>