<template lang="pug">
.target-maps
    .box__body
        .card.card--no-bg
            .card__header
                h2.text-xl Spread Tactical Device by Location
                .header-cta
                    .block
                        router-link.btn-primary.m-btn-icon(
                            :to="{ name: 'pageTacticalDeviceCreate', params: {} }"
                        )
                            IconSet(type="add")
                            span.m-hide.block.ml-3 Add Tactical Device
            .card__body
                .box-map
                    .container-box-map(style="z-index: 1000;")
                        .box-find.relative
                            .field.relative.w-full
                                input.field-style#find-device-input(placeholder="Search device's name (write minimum 3 characters)" v-model="device_keyword" @keyup="searchKeywordDevice")
                                .field-icon
                                    IconSet(type="search")

                                .box-find-selected(v-if="Object.keys(device_selected).length > 0")
                                    .flex.items-center.justify-between
                                        .flex.items-center
                                            //- figure.mr-3
                                                img.w-6.h-6.rounded-full.object-fit(src="https://randomuser.me/api/portraits/men/41.jpg")
                                            span {{ device_selected.name }}
                                        button.btn-icon-24(@click="setEmptyDevice" v-tooltip="'Remove device'")
                                            IconSet(type="close")

                            .p-4(v-if="status_list_map.status === 'loading' || list_map.data")

                                .flex.items-center.justify-center.border-b.p-4.text-center(v-if="status_list_map.status === 'loading'")
                                    Spinner(show="true" size="48")

                                template(v-if="list_map.data && list_map.data.length > 0")
                                    .small-heading Choose Device
                                    .box-users
                                        .item-user(v-for="(device,index) in list_map.data" :key="device.id" @click="selectDevice(device)")
                                            //- figure.mr-3
                                                img.w-6.h-6.rounded-full.object-fit(src="https://randomuser.me/api/portraits/men/41.jpg")
                                            span {{ device.name }}

                                .flex.items-center.justify-center.border-b.p-4.text-center(v-else-if="list_map.data && list_map.data.length == 0")
                                    span No Data Available

                    template(v-if="status_map_location.status === 'loading'")
                        Loading(show="true")

                    template(v-else)
                        .block(v-if="mapLoaded" class="w-full lg:w-12/12" style="height: 100%;")
                            gmap-map(ref="mapRef" :center="center" :zoom="11" :options="options" style="width:100%; height: 100%;")
                        .block(v-else class="w-full lg:w-12/12")
                            NoDataAvailable
</template>

<script>
import { mapState } from 'vuex';
import IconSet from '@/components/IconSet.vue'
import BoxMap from '@/components/Map.vue';
import Spinner from '@/components/Spinner.vue';
import Loading from '@/components/Loading.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';
import { gmapApi } from 'vue2-google-maps';
import moment from 'moment';

export default {
    name: 'TacticalDevicesMaps',
    components: {
        IconSet,
        BoxMap,
        Spinner,
        Loading,
        NoDataAvailable,
    },
    data: function() {
        return {
            // map
            options: {
                // zoomControl: true,
                // mapTypeControl: true,
                // scaleControl: true,
                // streetViewControl: true,
                // rotateControl: true,
                // fullscreenControl: false,
                // disableDefaultUi: false
                // scrollwheel: false,
                gestureHandling: 'cooperative',
                styles: [
                    {elementType: 'geometry', stylers: [{color: '#242f3e'}]},
                    {elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}]},
                    {elementType: 'labels.text.fill', stylers: [{color: '#746855'}]},
                    {
                      featureType: 'administrative.locality',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#d59563'}]
                    },
                    {
                      featureType: 'poi',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#d59563'}]
                    },
                    {
                      featureType: 'poi.park',
                      elementType: 'geometry',
                      stylers: [{color: '#263c3f'}]
                    },
                    {
                      featureType: 'poi.park',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#6b9a76'}]
                    },
                    {
                      featureType: 'road',
                      elementType: 'geometry',
                      stylers: [{color: '#38414e'}]
                    },
                    {
                      featureType: 'road',
                      elementType: 'geometry.stroke',
                      stylers: [{color: '#212a37'}]
                    },
                    {
                      featureType: 'road',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#9ca5b3'}]
                    },
                    {
                      featureType: 'road.highway',
                      elementType: 'geometry',
                      stylers: [{color: '#746855'}]
                    },
                    {
                      featureType: 'road.highway',
                      elementType: 'geometry.stroke',
                      stylers: [{color: '#1f2835'}]
                    },
                    {
                      featureType: 'road.highway',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#f3d19c'}]
                    },
                    {
                      featureType: 'transit',
                      elementType: 'geometry',
                      stylers: [{color: '#2f3948'}]
                    },
                    {
                      featureType: 'transit.station',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#d59563'}]
                    },
                    {
                      featureType: 'water',
                      elementType: 'geometry',
                      stylers: [{color: '#17263c'}]
                    },
                    {
                      featureType: 'water',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#515c6d'}]
                    },
                    {
                      featureType: 'water',
                      elementType: 'labels.text.stroke',
                      stylers: [{color: '#17263c'}]
                    }
                ],
            },
            infoWindows: [],
            mapLoaded: false,
            popupShow: false,
            center: {},
            markers: [],
            // places: [],
            // heatmap: null,
            mapsLatLng: [],
            polyline: null,

            marker_color: {
                green: '#48bb78',
                red: '#f56565',
                purple: '#9f7aea',
                orange: '#ed8936',
                blue: '#4299e1',
            },

            device_selected: {},
            device_keyword: '',
        };
    },
    computed: {
        ...mapState('tacticalDevice', [
            'status_list_map',
            'status_map_location',
            'list_map',
            'map_location',
        ]),
        google: gmapApi,
        route_name() {
            return this.$route.name;
        },
    },
    watch: {
        google() {},
        route_name(newData, oldData) {
            if (newData === 'pageTacticalDeviceMaps')
                this.getData();
        },
        map_location(newData) {
            if (newData.length > 0)
                this.startMap();
        },
    },
    methods: {
        getData(map=false) {
            if (this.route_name === 'pageTacticalDeviceMaps') {
                if (map === true) {
                    this.$store.dispatch('tacticalDevice/getList', [30, 1, this.device_keyword, null, null, null, true]);
                } else {
                    this.getMapLocation();
                }
            }
        },
        getMapLocation() {
            this.$store.dispatch('tacticalDevice/getMapLocation', (this.device_selected.id ? this.device_selected.id : ''));
        },
        textFocus(id) {
            document.getElementById(id).focus();
        },
        searchKeywordDevice() {
            // clear timeout variable
            clearTimeout(this.timeout);
            
            var self = this;
            this.timeout = setTimeout(function () {
                // enter this block of code after 1 second
                // handle stuff, call search API etc.
                self.$store.commit('tacticalDevice/setListMap', {});

                if (self.device_keyword.length > 2) {
                    self.getData(true);
                }
            }, 650);
        },
        selectDevice(device) {
            this.device_selected = device;
            this.popupShow = !this.popupShow;
            this.device_keyword = '';
            this.$store.commit('tacticalDevice/setListMap', {});
            this.getMapLocation();
        },
        setEmptyDevice() {
            this.device_selected = {};
            this.getMapLocation();
        },
        datetimeFormat(datetime) {
            // return moment(datetime, 'YYYY-MM-DDTHH:mm:ssZ').format('DD MMM YYYY HH:mm:ssZ');
            return moment(datetime, 'YYYY-MM-DD HH:mm:ss+0000').format('DD MMM YYYY HH:mm:ssZ');
        },
        hideAllInfoWindows() {
            for (var j=0; j < this.infoWindows.length; j++) {
                this.infoWindows[j].close();
            }
        },
        async startMap() {
            var self = this;
            // var mapsLatLng = [];

            // monas, jakarta
            this.center = {
                lat: -6.1763562,
                lng: 106.8227796,
            };

            this.mapLoaded = true;

            await self.$nextTick(() => {
                self.$refs.mapRef.$mapPromise.then((map) => {
                    const google = self.google;

                    // map.center = new google.maps.LatLng(self.center);

                    if (self.map_location.length > 0) {
                        var bounds = new google.maps.LatLngBounds();

                        for (var i = 0; i < self.map_location.length; i++) {

                            let marker = new google.maps.Marker({
                                position: {
                                    lat: parseFloat(self.map_location[i].lat),
                                    lng: parseFloat(self.map_location[i].lng)
                                },
                                map: map,
                                // icon: icon,
                                // draggable: true,
                            });
                            // console.log(marker);
                            self.markers.push(marker);

                            var dtm = (self.map_location[i].created_at ? self.datetimeFormat(self.map_location[i].created_at) : '-');
                            
                            var contentString = '<div id="content">'+
                                  '<div id="bodyContent">'+
                                  '<table>'+
                                      '<tr>'+
                                        '<td class="pr-1 cell-info-window whitespace-no-wrap">Device Name</td>'+
                                        '<td class="pr-1 cell-info-window">:</td>'+
                                        '<td class="cell-info-window">'+self.map_location[i].device.name+'</td>'+
                                      '</tr>'+
                                      '<tr>'+
                                        '<td class="cell-info-window whitespace-no-wrap">User</td>'+
                                        '<td class="cell-info-window">:</td>'+
                                        '<td class="cell-info-window">'+self.map_location[i].user.name+'</td>'+
                                      '</tr>'+
                                      '<tr>'+
                                        '<td class="cell-info-window whitespace-no-wrap">Datetime</td>'+
                                        '<td class="cell-info-window">:</td>'+
                                        '<td class="cell-info-window">'+dtm+'</td>'+
                                      '</tr>'+
                                      '<tr>'+
                                        '<td class="cell-info-window whitespace-no-wrap">Link Location</td>'+
                                        '<td class="cell-info-window">:</td>'+
                                        '<td class="cell-info-window"><a target="_blank" href="https://www.google.com/maps?q='+self.map_location[i].lat+','+self.map_location[i].lng+'" style="color: #43ea92;">'+'Google Maps</a> '+
                                        '</td>'+
                                      '</tr>'+
                                    '</table>'+
                                  '</div>'+
                                  '</div>';

                            let infowindow = new google.maps.InfoWindow({
                                content: contentString,
                                maxWidth: 400
                            });
                            
                            self.infoWindows.push(infowindow);
                            
                            google.maps.event.addListener(marker, 'click', function() {
                              // hideAllInfoWindows(map);
                              self.hideAllInfoWindows();
                              infowindow.open(map, marker);
                              // console.log(marker.position.lat(), marker.position.lng())
                            });

                            bounds.extend(new google.maps.LatLng(marker.position.lat(), marker.position.lng()));
                        }
                        // Apply fitBounds
                        map.fitBounds(bounds); 
                    }
                });
            });
        },
    },
    mounted() {
        this.getData();
    },
}
</script>

<style lang="sass" scoped>
    .box-map
        width: 100%
        height: 550px
        position: relative
        .container-box-map
            position: absolute
            width: 100%
            top: 0
            left: 0
            padding-top: 1rem
    .box-find
        width: 100%
        max-width: 450px
        margin: 0 auto
        background-color: $color-secondary-500
        border-radius: 4px

    .box-users
        .item-user
            @apply .flex .items-center .p-2 .border-b .cursor-pointer
            &:hover 
                background-color: $color-secondary-600
            &:last-child
                border-bottom: 0px
    .box-find-selected
        background-color: $color-secondary-500
        padding-top: 2px
        @apply .absolute .inset-0 .rounded .px-2
</style>