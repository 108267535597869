<template lang="pug">
.card
    .card__body
        //- .flex.flex-wrap(
        //-     class="lg:-mx-2"
        //-     v-if="status_deleted_photos.status == 'loading'"
        //- )
        //-     Loading(show="true")

        //- template(v-else)
            //- silent-box(v-if="gallery.length > 0" :gallery="gallery")

            //- NoDataAvailable(v-else)
        silent-box(v-if="gallery.length > 0" :gallery="gallery")
        template(v-if="gallery.length > 0 || status_deleted_photos.status == 'loading'")
            .flex.flex-wrap(
                class="lg:-mx-2"
                v-if="status_deleted_photos.status == 'loading'"
            )
                Loading(show="true")
            template(v-else-if="gallery.length > 0 && (deleted_photos.has_next && deleted_photos.has_next === true)")
                .flex.items-center.justify-center.w-full.mt-8
                    button.btn-small.btn-primary(@click="loadMore") Load More
            template(v-else)
                .flex.items-center.justify-center.w-full.mt-8
                    .message.text-xs.text-gray-600 all photos have been shown
        NoDataAvailable(v-else)

</template>

<script>
import { mapState } from 'vuex';
import Loading from '@/components/Loading.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';

export default {
    name: 'Photos',
    components: {
        Loading,
        NoDataAvailable,
    },
    data() {
        return {
            gallery: [],
            page: 1,
            limit: 8,
        }
    },
    computed: {
        ...mapState('target', [
            'deleted_photos',
            'status_deleted_photos',
        ]),
        id() {
            return this.$route.params.id;
        },
    },
    methods: {
        setGallery() {
            // let gallery = [];
            if (this.deleted_photos && this.deleted_photos.data && this.deleted_photos.data.length > 0) {
                for (var i = 0; i < this.deleted_photos.data.length; i++) {
                    // for (var j = 0; j < this.deleted_photos.data[i].photos.length; j++) {
                    this.gallery.push({
                        // src: this.deleted_photos.data[i].photos[j].file,
                        src: this.deleted_photos.data[i].file,
                        description: '',
                        alt: '',
                    });
                    // }
                }
            }
            // this.gallery = gallery;
        },
        getData() {
            this.$store.commit('target/setStatusDeletedPhotos', {
                status: 'loading',
                cancel: null,
                code: null
            })
            this.$store.dispatch('target/getDeletedPhotos', [this.id, this.page, this.limit])
        },
        async loadMore(){
            await this.page++;
            this.getData();
        }
    },
    watch: {
        deleted_photos() {
            this.setGallery();
        }
    },
    mounted() {
        this.getData();
        // this.setGallery();
    }
}
</script>

<style lang="sass">
    #silentbox-gallery
        @apply .grid .grid-cols-4 .gap-8
        .silentbox-item
            aspect-ratio: 1/1
            overflow: hidden
            display: block
            img
                width: 100%
                height: 100%
                object-fit: cover
</style>