<template lang="pug">
.box__body
    .card.card--table.card--no-bg
        .card__header.card--table__header
            h2.text-xl List of Digital Cyber Data
            .header-cta
                    .flex.items-center
                        .block
                            router-link.btn-primary.m-btn-icon(
                                :to="{ name: 'pageDcdCreate', params: {} }"
                            )
                                IconSet(type="add")
                                span.m-hide.ml-3 New Digital Cyber Data

        .card__body.card--table__body
            .box-filter-table.p-4.pt-0
                .field(class="lg:mr-3 lg:mb-0 lg:w-64 mr-0 w-full mb-4")
                    input.field-style(
                        v-model="keyword"
                        type="text"
                        placeholder="Search here..."
                        @keyup="searchKeyword"
                    )
                    .field-icon
                        IconSet(
                            type="search"
                        )
                .field(class="lg:mr-3 lg:mb-0 lg:w-32 mr-0 w-full mb-4")
                    select.field-style(@change="sizeChange($event)" v-model="items_per_page")
                        option(v-for="(s,index) in size" :key="index" :value="s") {{ s }}
                    .field-icon
                        IconSet(
                            type="chevron-down"
                        )

            .box-table
                table.table
                    thead.m-hide
                        tr
                            th(v-for="(th,index) in tableThead" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}
                    tbody
                        tr(v-if="status_list.status === 'loading'")
                            td.text-center(:colspan="tableThead.length")
                                .flex.flex-wrap.items-center.justify-center
                                    Spinner(show="true" size="48")
                        template(v-else-if="list && list.data && list.data.length > 0")
                            tr(v-for="(data,index) in list.data" :key="data.id")
                                td {{data.key}}
                                    //- span.label-dark.whitespace-no-wrap {{data.key}}
                                td
                                    span.label-dark.whitespace-no-wrap {{data.type_view}}
                                td {{ toString(data.cases) }}
                                    //- span.label-dark.whitespace-no-wrap {{ toString(data.cases) }}
                                td(style="padding-right:0px !important")
                                    .text-sm.truncate(style="width: 240px;") {{ data.description?data.description:'-' }}
                                td
                                    .w-48
                                        template(v-if="data.notes && data.notes.length > 0")
                                            .text-sm.note(@click="getNotes(true, data.id, data.key)") {{data.notes&&data.notes.notes?data.notes.notes:'No notes'}}
                                        template(v-else)
                                            button.link.text-sm(@click="getNotes(true, data.id, data.key)") {{data.notes&&data.notes.notes?data.notes.notes:'Add a note'}}

                                td
                                    span.text-sm {{ data.created_by && data.created_by.name?data.created_by.name:'-' }}
                                td
                                    span.text-sm {{ data.created_at?data.created_at:'-' }}
                                td
                                    .flex.items-center.justify-end
                                        .mr-8
                                            router-link.mr-2(:to="{name: 'pageDcdDetail', params: {id:data.id, source:'external'}}").btn-small.btn-primary-o Search
                                            router-link(:to="{name: 'pageDcdDetail', params: {id:data.id, source:'internal'}}").btn-small.btn-primary-o Detail
                                        router-link.btn-icon.btn-icon-40(
                                            v-tooltip="'Edit'"
                                            :to="{ name: 'pageDcdEdit', params: {id:data.id} }"
                                        )
                                            IconSet(type="edit")
                                        button.btn-icon-40(
                                            v-if="checkFeatureByAcl('dcd', 'delete')"
                                            @click="deleteData(data.id, data.key)"
                                            v-tooltip="'Delete'"
                                        )
                                            IconSet(type="delete")

                        tr(v-else)
                            td.text-center(:colspan="tableThead.length") No Data Available
            
            .box-table-footer(v-if="status_list.status !== 'loading'")
                .pagination
                    vue-ads-pagination(
                        :total-items="total_data"
                        :items-per-page="items_per_page"
                        :page="page"
                        :max-visible-pages="5"
                    )
                        template(slot="buttons" slot-scope="props")
                            vue-ads-page-button(
                                v-for="(button, key) in props.buttons"
                                :key="key"
                                v-bind="button"
                                :class="{'bg-yellow-dark': button.active}"
                                @page-change="page = button.page"
                                @range-change="start = button.start; end = button.end"
                            )
                            
        .popup-layer(
            v-bind:class="{'is-show' : openNote}"
        )
            .popup-container
                .popup-container__header
                    h2.text-xl {{dcdSelected}}'s Notes
                    button.btn-icon-40(
                        @click="getNotes(false)"
                    )
                        IconSet( type="close")

                .popup-container__body
                    .mb-4
                        .field.w-full.mb-2
                            textarea.field-style(
                                v-model="tmpNote"
                                placeholder="Write new note here..."
                                style="min-height:60px"
                            )
                        .flex.justify-end
                            template(v-if="tmpNote && tmpNote.length>0")
                                button.btn-primary.btn.small(@click="addNote") Add note
                            template(v-else)
                                button.btn-primary.btn.small.btn-disabled(disabled) Add note
                    .mb-4
                        .small-heading History Notes
                        template(v-if="status_notes_list.status == 'loading'")
                            .flex.flex-wrap.items-center.justify-center
                                Spinner(show="true" size="48")

                        .mb-4.pb-4.border-b(v-else-if="notes_list && notes_list.length > 0" v-for="note in notes_list")
                            p.mb-1 {{note.notes}}
                            small.text-xs.opacity-75 {{note.created_at}} • added by {{note.created_by?note.created_by:'-'}}

                        .flex.flex-wrap.items-center.justify-center(v-else)
                            strong.block.text-xs.uppercase.text-light.mb-4 No History Available
                        .block                            
                            template(v-if="status_notes_list.status != 'loading' && (notes_list && notes_list.length > 0)")
                                small.text-xs.opacity-75(v-if="!is_loading_next") {{notes_list.length}} of {{data_pagination.total_data}} items
                                .flex.flex-wrap.items-center.justify-center
                                    template(v-if="is_loading_next")
                                        Spinner(show="true" size="48")
                                    template(v-else)
                                        template(v-if="data_pagination.current_page<data_pagination.total_page")
                                            button.btn-small.btn-primary(@click="handleNextNotes") Load More    

</template>

<script>
import IconSet from '@/components/IconSet.vue';
import { mapState } from 'vuex';
import Spinner from '@/components/Spinner.vue';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';

export default {
    name: 'pageDcdIndex',
    components: {
        IconSet,
        Spinner,
        VueAdsPagination,
        VueAdsPageButton,
    },
    data() {
        return {
            tableThead: [
                {
                    name: 'Keyword',
                },{
                    name: 'Type',
                },{
                    name: 'Case',
                },{
                    name: 'Description',
                },{
                    name: 'Latest Note',
                },{
                    name: 'Created By',
                },{
                    name: 'Created At',
                },{
                    name: '',
                    action: true,
                },
            ],
            total_data: 0,
            items_per_page: 10,

            size: [10, 25, 50, 100],

            page: 0,
            start: 0,
            end: 0,

            keyword: '',
            timeout: null,
            
            user_keyword:'',
            user_id:'',
            user_selected:false,

            openNote: false,

            dcdIdSelected: null,
            dcdSelected: null,
            tmpNote: null,
        }
    },
    
    computed: {
        ...mapState('auth', ['user']),
        ...mapState('dcd', [
            'status_list',
            'list',
            'status_notes_list',
            'notes_list',
            'is_loading_next',
            'data_pagination',
        ]),
        route_name() {
            return this.$route.name;
        },
    
    },
    watch: {
        route_name(newData, oldData) {
            if (newData === 'pageDcdIndex') {
                if (oldData !== 'pageDcdIndex') {
                    this.defaultValue()
                }

                this.getData();
            }
        },
        list(newData) {
            if (Object.keys(newData).length > 0) {
                this.total_data = parseInt(newData.total_data);
                // this.page = (parseInt(newData.current_page) - 1);
            } else {
                this.total_data = 0;
            }
        },
        items_per_page() {
            this.getData();
        },
        page() {
            this.getData();
        },
        // user_keyword(newData) {
        //     if (newData.length < 3)
        //         this.$store.commit('users/setList', {});
        // },
    },
    methods: {
        defaultValue() {
            this.items_per_page = 10;
            this.page = 0;
            this.keyword = '';
            this.$store.commit('dcd/setList', {});
        },
        getData() {
            // for dispatch get data
            if (this.route_name === 'pageDcdIndex') {
                this.$store.dispatch('dcd/getList', [this.items_per_page, (this.page + 1), this.keyword, this.user_id]);
                // if (this.list.total_page && this.list.total_page > 1)
                    // this.pagination();
            }
        },
        pageChange(page) {
            this.page = page;
        },
        sizeChange() {
            this.page = 0;
        },
        searchKeyword() {
            clearTimeout(this.timeout);            
            var self = this;
            this.timeout = setTimeout(function () {
                self.page = 0;
                self.$store.commit('dcd/setList', {});
                self.getData();
            }, 650);
        },
        deleteData(id, name) {
            this.$swal.fire({
                icon: 'warning',
                title: 'Are you sure you want to delete?',
                text: 'DCD : ' + name,
                confirmButtonText: 'Yes!',
                confirmButtonColor: '#DD6B55',
                showCancelButton: true,
                allowOutsideClick: false,
            })
            .then((result) => {

                if (result.isConfirmed) {
                    EventEmit.$emit('showLoading', true);

                    this.$store.dispatch('dcd/delete', [id])
                    .then((resp) => {
                        if (resp === 'success') {
                            this.page = 0;

                            this.getData();

                            this.$swal.fire({
                                icon: 'success',
                                title: 'Data successfully deleted!',
                                timer: 3000,
                            });
                        } else {
                            if (resp.data && resp.data.message) {
                                EventEmit.$emit('error', resp.data.message);
                            } else {
                                EventEmit.$emit('error');
                            }
                        }
                    });
                }
            });
        },
        getNotes(open=true, dcd=null,key=null) {
            this.$store.commit('dcd/setNotesList', {});
            if(open){
                this.dcdIdSelected = dcd
                this.dcdSelected = key
                this.$store.dispatch('dcd/getNotesList', [this.dcdIdSelected]);
                this.openNote = true
                EventEmit.$emit('overflowHidden', true);
            } else {
                this.setEmptyNotes();
                this.setDefaulDataNotes();
                EventEmit.$emit('overflowHidden', false);
            }
        },
        handleNextNotes() {
            // console.log(dcd)
            this.$store.commit('dcd/setIsLoadingNext', true);        
            this.$store.dispatch('dcd/getNotesList', [this.dcdIdSelected, true]);
        },
        addNote(){
            EventEmit.$emit('showLoading', true);
            this.$store.dispatch('dcd/createNotes', [this.dcdIdSelected, this.tmpNote])
                .then((resp) => {
                    if (resp.result === 'success') {
                        // this.getNotes(false)
                        this.tmpNote = null
                        this.setDefaulDataNotes()

                        this.getNotes(true, this.dcdIdSelected, this.dcdSelected);
                        this.getData();

                        this.$swal.fire({
                            icon: 'success',
                            title: 'Note successfully added!',
                            timer: 3000,
                        });
                    } else {
                        if (resp.data.message) {
                            EventEmit.$emit('error', resp.data.message);
                        } else {
                            EventEmit.$emit('error');
                        }
                    }
                });
        },
        setEmptyNotes(){
            this.dcdIdSelected = null
            this.dcdSelected = null
            this.tmpNote = null
            this.openNote = false
        },
        setDefaulDataNotes(){
            const dataPagination = { 
                current_page: 1,
                has_next: false,
                has_previous: false,
                next_page_number: 1,
                per_page: 10,
                previous_page_number: null,
                total_data: 0,
                total_page: 1,
            }
            this.$store.commit('dcd/setDataPagination', dataPagination);
        },
    },
    mounted() {
        this.defaultValue()
    },
    created() {
        this.getData();
    },
}
</script>