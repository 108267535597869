<template lang="pug">
.territory-virtual-detail
    .box__body
        .card.card--no-bg.square.border-b
            .card__header
                h2.text-xl {{ detail_map_data.name ? detail_map_data.name : '-' }}
                .header-cta
                    .block
                        router-link.btn-default.m-btn-icon.mr-3(to="/notifications")
                            IconSet(type="chevron-left")
                            span.m-hide.ml-3 Back
            .card__body
                .grid.grid-cols-5.gap-8
                    .col-span-3
                        .rollout
                            span.text-xs Description
                            p.text-light {{ detail_map_data.description ? detail_map_data.description : '-' }}
                        .pt-3
                            .small-heading Selected target
                            .fields-box(v-if="detail_map_data.target && detail_map_data.target.length > 0")
                                .fields-box__item(v-for="(target, index) in detail_map_data.target" :key="index")
                                    .w-full
                                        .flex.justify-between.w-full
                                            .flex-grow.flex.items-center
                                                .mr-4
                                                    .w-3.h-3.rounded-full(:style="'background-color: '+ target.label +';'")
                                                .mr-4
                                                    figure.w-8.h-8.rounded-full.overflow-hidden
                                                        img(:src="target.photo ? target.photo : getDefaultImage" @error="getDefaultImage")
                                                span {{ target.target_name ? target.target_name : '-' }}

                                        .mt-4
                                            template(v-if="Object.keys(target.coordinates).length > 0")
                                                .ui-checkbox.ui-checkbox--small.mr-8(v-for="(msisdn, index) in target.coordinates" :key="index")
                                                    span {{ msisdn.msisdn }}
                                            .ui-checkbox.ui-checkbox--small.mr-8(v-else)
                                                span.text-xs No Data Available

                                            //- .ui-checkbox.ui-checkbox--small.mr-8
                                                input(type="checkbox" checked disabled)
                                                .style-checkbox
                                                    .checkbox
                                                        IconSet(type="check")
                                                    span 081234567890

                    .col-span-2
                        //- .w-full.bg-black(style="height:400px")
                        .w-full.box-map(style="height:400px")
                            gmap-map(v-if="detail_map_data.point || detail_map_data.target" ref="mapRefDetail" :center="center" :zoom="11" :options="options" style="width:100%; height: 100%;")
                            
                            NoDataAvailable(v-else)

        .card.card--no-bg.card--table.square.border-b
            .card--table__header
                h2.text-xl History Territory Virtual
            .card--table__body
                .box-filter-table.p-4.pt-0
                    //- .field(class="lg:mr-3 lg:mb-0 lg:w-64 mr-0 w-full mb-4")
                        input.field-style(
                            type="text"
                            placeholder="Search here..."
                        )
                        .field-icon
                            IconSet(
                                type="search"
                            )

                    .field.mr-3
                        .relative.mr-3.field-style
                            date-range-picker(
                                v-if="dateRange.startDate && dateRange.endDate" 
                                ref="picker" 
                                v-model="dateRange" 
                                @update="pickerUpdate($event)" 
                                :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }" 
                                :date-format="dateFormat" 
                                opens="right"
                                :ranges="default_ranges"
                                autoApply=true
                            )
                                div(
                                    slot="input" 
                                    slot-scope="picker") 
                                        | {{ picker.startDate | date}} - {{ picker.endDate  | date}}

                            .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2
                                IconSet(type="calendar")

                    .field(class="lg:mr-3 lg:mb-0 lg:w-32 mr-0 w-full mb-4")
                        select.field-style#filter-record-size(@change="sizeChange($event)" v-model="items_per_page" style="min-width:80px")
                            option(v-for="(s,index) in size" :key="index" :value="s") {{ s }}
                        .field-icon
                            IconSet(
                                type="chevron-down"
                            )
                .box-table.box-table--target
                    table.table
                        thead.m-hide
                            tr
                                th(v-for="(th,index) in tableThead" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}

                        tbody
                            tr(v-if="status_detail_history_list === 'loading'")
                                td.text-center(:colspan="tableThead.length")
                                    .flex.flex-wrap.items-center.justify-center
                                        Spinner(show="true" size="48")

                            template(v-else-if="detail_history_list.data && detail_history_list.data.length > 0")
                                tr(v-for="(territory,index) in detail_history_list.data" :key="territory.id")
                                    td
                                        .text-ms {{ territory.created_at ? datetimeFormat(territory.created_at) : '-' }}
                                    td
                                        .text-ms {{ territory.target && territory.target.name ? territory.target.name : '-' }}
                                    td
                                        .w-3.h-3.rounded-full(v-if="territory.target && territory.target.label" :class="[`inline-block w-4 h-4 rounded-full`]" :style="'background-color: '+ territory.target.label +';'")

                                        span(v-else) -
                                    td
                                        .text-ms {{ territory.msisdn ? territory.msisdn : '-' }}

                                    td
                                        //- .text-ms {{ territory.location && territory.location[0] ? territory.location[0].lat + ', ' + territory.location[0].lng : '-' }}

                                        .flex(v-if="territory.location && territory.location[0]")
                                            .label-dark.mr-2 Lat: {{ territory.location[0].lat }}
                                            .label-dark Long: {{ territory.location[0].lng }}
                                        .text-ms(v-else) -
                                    td
                                        .flex.items-center.justify-end
                                            a.btn-icon-40.m-hide(v-if="territory.location && territory.location[0]" :href="'https://www.google.com/maps?q=' + territory.location[0].lat + ',' + territory.location[0].lng" target="_blank")
                                                IconSet(type="maps")

                            tr(v-else)
                                td.text-center(:colspan="tableThead.length")
                                    .text-ms No Data Available

                            //- tr(v-for="item in DataHistory" :key="item.id")
                                td
                                    .text-ms {{ item.datetime }}
                                td
                                    .text-ms {{ item.target }}
                                td
                                    .w-3.h-3.rounded-full(style="background-color:red")
                                td
                                    .text-ms {{ item.msisdn }}
                                td
                                    .flex
                                        .label-dark.mr-2 Lang {{ item.coordinate }}
                                        .label-dark Lat {{ item.coordinate }}
                                td
                                    .flex.items-center.justify-end
                                        button.btn-icon-40.m-hide
                                            IconSet(type="edit")
                                        button.btn-icon-40.m-hide
                                            IconSet(type="delete")

                //- .card__footer
                .box-table-footer
                    //- span.text-xs Showing 10 from 100
                    //- .pagination
                        ul
                            li
                                span 1
                            li
                                router-link(to="")  2
                            li
                                router-link(to="")  3

                    //- .mt-5
                    .pagination
                        vue-ads-pagination(
                            :total-items="total_data"
                            :items-per-page="items_per_page"
                            :page="page"
                            :max-visible-pages="5"
                        )
                            template(slot="buttons" slot-scope="props")
                                vue-ads-page-button(
                                    v-for="(button, key) in props.buttons"
                                    :key="key"
                                    v-bind="button"
                                    :class="{'bg-yellow-dark': button.active}"
                                    @page-change="page = button.page"
                                    @range-change="start = button.start; end = button.end"
                                )

</template>

<script>
import IconSet from '@/components/IconSet.vue';
import { gmapApi } from 'vue2-google-maps';
import { mapState } from 'vuex';
import Spinner from '@/components/Spinner.vue';
import moment from 'moment';
import Loading from '@/components/Loading.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
// import '../../../node_modules/vue-ads-pagination/dist/vue-ads-pagination.css';
import { decrypt } from '@/util/crypto';

export default {
    name: 'PageTerritoryDetailNotification',
    components: {
        IconSet,
        Spinner,
        Loading,
        NoDataAvailable,
        DateRangePicker,
        VueAdsPagination,
        VueAdsPageButton,
    },
    data() {
        return {
            // map
            options: {
                // zoomControl: true,
                // mapTypeControl: true,
                // scaleControl: true,
                // streetViewControl: true,
                // rotateControl: true,
                // fullscreenControl: false,
                // disableDefaultUi: false
                // scrollwheel: false,
                gestureHandling: 'cooperative',
                styles: [
                    {elementType: 'geometry', stylers: [{color: '#242f3e'}]},
                    {elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}]},
                    {elementType: 'labels.text.fill', stylers: [{color: '#746855'}]},
                    {
                      featureType: 'administrative.locality',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#d59563'}]
                    },
                    {
                      featureType: 'poi',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#d59563'}]
                    },
                    {
                      featureType: 'poi.park',
                      elementType: 'geometry',
                      stylers: [{color: '#263c3f'}]
                    },
                    {
                      featureType: 'poi.park',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#6b9a76'}]
                    },
                    {
                      featureType: 'road',
                      elementType: 'geometry',
                      stylers: [{color: '#38414e'}]
                    },
                    {
                      featureType: 'road',
                      elementType: 'geometry.stroke',
                      stylers: [{color: '#212a37'}]
                    },
                    {
                      featureType: 'road',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#9ca5b3'}]
                    },
                    {
                      featureType: 'road.highway',
                      elementType: 'geometry',
                      stylers: [{color: '#746855'}]
                    },
                    {
                      featureType: 'road.highway',
                      elementType: 'geometry.stroke',
                      stylers: [{color: '#1f2835'}]
                    },
                    {
                      featureType: 'road.highway',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#f3d19c'}]
                    },
                    {
                      featureType: 'transit',
                      elementType: 'geometry',
                      stylers: [{color: '#2f3948'}]
                    },
                    {
                      featureType: 'transit.station',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#d59563'}]
                    },
                    {
                      featureType: 'water',
                      elementType: 'geometry',
                      stylers: [{color: '#17263c'}]
                    },
                    {
                      featureType: 'water',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#515c6d'}]
                    },
                    {
                      featureType: 'water',
                      elementType: 'labels.text.stroke',
                      stylers: [{color: '#17263c'}]
                    }
                ],
            },
            infoWindows: [],
            mapLoaded: false,
            center: {},
            markers: [],
            // places: [],
            // heatmap: null,
            mapsLatLng: [],
            polyline: null,

            rectangle_data: {},
            // rectangle_bounds: [],

            // default rectangle bounds -> monas, jakarta
            bounds: {
                north: -6.170255000000001,
                east: 106.83286432275388,
                south: -6.18188099999998,
                west: 106.8211273227539,
            },

            imgAvatar: this.$store.getters['config/getDefaultImageProfile'],

            target_selected: {},
            msisdn_selected: '',
            from_date: '',
            to_date: '',

            // date picker
            dateRange: {
                startDate: '',
                endDate: '',
            },
            default_ranges: {
                'Today': [moment().toDate(), moment().toDate()],
                'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                'Last 7 days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                'Last week': [moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(), moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate()],
            },

            // pagination history
            tableThead: [
                {
                    name: 'Datetime',
                    // number: true,
                },
                {
                    name: 'Target',
                },
                {
                    name: 'Label',
                },
                {
                    name: 'MSISDN',
                },
                {
                    name: 'Coordinate',
                },
                {
                    name: 'Action',
                    action: true,
                },
            ],

            total_data: 0,
            items_per_page: 10,

            size: [10, 25, 50, 100],

            page: 0,
            start: 0,
            end: 0,
        };
    },
    computed: {
        google: gmapApi,
        ...mapState('territoryVirtual', [
            'status_detail_map_data',
            'status_detail_history_list',
            'detail_map_data',
            'detail_history_list',
        ]),
        route_name() {
            return this.$route.name;
        },
        id() {
            return this.$route.params.id;
        },
        target_id() {
            return this.$route.params.target_id;
        },
        msisdn() {
            return this.$route.params.msisdn;
        },
        rectangle: {
            get() {
                // console.log(Object.keys(this.rectangle_data).length);
                if (!this.rectangle_data)
                    return this.google.maps.Rectangle;

                return this.rectangle_data;
            },
            set(v) {
                this.rectangle_data = v;
            },
        },
    },
    watch: {
        google() {},
        route_name() {
            // this.getData();
        },
        id() {
            this.getData();
        },
        target_id() {
            this.getData();
        },
        msisdn() {
            this.getData();
        },
        rectangle() {},
        detail_map_data(newData) {
            if ((newData.target && newData.target.length > 0) || (newData.point && newData.point[0]))
                this.startMap();
        },
        msisdn_selected() {},
        target_selected(newData) {
            if (Object.keys(newData).length > 0)
                this.getHistoryData();
        },
        dateRange() {
            // console.log(newData);
            // this.from_date = moment(newData.startDate).format('YYYY-MM-DD');
            // this.to_date = moment(newData.endDate).format('YYYY-MM-DD');
        },

        // pagination
        detail_history_list(newData) {
            if (Object.keys(newData).length > 0) {
                this.total_data = parseInt(newData.total_data);
                // this.page = (parseInt(newData.current_page) - 1);
            } else {
                this.total_data = 0;
            }
        },
        total_data() {},
        items_per_page() {
            this.getHistoryData('list');
        },
        page() {
            this.getHistoryData('list');
        },
    },
    methods: {
        changeImgAvatar(e) {
            const file = e.target.files[0];
            this.imgAvatar = URL.createObjectURL(file);
        },
        getDefaultImage(e) {
            e.target.src = this.imgAvatar;
        },
        datetimeFormat(datetime) {
            return moment(datetime, 'YYYY-MM-DD HH:mm:ss+0000').format('DD MMM YYYY HH:mm:ssZ');
        },
        hideAllInfoWindows(history=false) {
            for (var j=0; j < this.infoWindows.length; j++) {
                this.infoWindows[j].close();
            }
        },
        mapBounds(point=null) {
            var self = this;

            self.$nextTick(() => {
                self.$refs.mapRefDetail.$mapPromise.then((map) => {
                    const google = self.google;
                    var bounds = new google.maps.LatLngBounds();

                    if (point !== null) {
                        bounds.extend(new google.maps.LatLng(parseFloat(point.north_east.lat), parseFloat(point.north_east.lng)));
                        bounds.extend(new google.maps.LatLng(parseFloat(point.south_west.lat), parseFloat(point.south_west.lng)));
                    } else {
                        bounds.extend(new google.maps.LatLng(self.bounds.north, self.bounds.east));
                        bounds.extend(new google.maps.LatLng(self.bounds.south, self.bounds.west));
                    }

                    // add markers to bounds
                    if (self.markers.length > 0) {
                        for (var i = 0; i < self.markers.length; i++) {
                            bounds.extend(new google.maps.LatLng(self.markers[i].position.lat(), self.markers[i].position.lng()));
                        }
                    }

                    // Apply fitBounds
                    map.fitBounds(bounds); 
                });
            });
        },
        /* eslint-disable no-unused-vars */
        dateFormat (classes, date) {
            if (!classes.disabled) {

                // classes.disabled = date.getTime() > new Date();
                classes.disabled = moment(date.getTime()).subtract({ hours: 12})._d > new Date();
            }
            return classes;
        },
        sizeChange() {
            this.page = 0;
        },
        pagination() {
            // update fontawesome to iconfont
            var paginationButtonPreview = document.getElementsByClassName("fa-angle-left");

            if (paginationButtonPreview[0]) {
                var classListPrev = paginationButtonPreview[0].classList;

                if (Object.values(classListPrev).filter(key => ['iconfont'].includes(key)).length === 0) {
                    paginationButtonPreview[0].classList.add('iconfont');
                    paginationButtonPreview[0].classList.add('iconleft');
                    paginationButtonPreview[0].style.fontSize = '12px';
                }
            }

            var paginationButtonNext = document.getElementsByClassName("fa-angle-right");

            if (paginationButtonNext[0]) {
                var classListNext = paginationButtonNext[0].classList;

                if (Object.values(classListNext).filter(key => ['iconfont'].includes(key)).length === 0) {
                    paginationButtonNext[0].classList.add('iconfont');
                    paginationButtonNext[0].classList.add('iconright');
                    paginationButtonNext[0].style.fontSize = '12px';
                }
            }
        },
        async pickerUpdate(e) {
            let ranges = moment(e.endDate.getTime()).diff(moment(e.startDate.getTime()), 'days') + 1;

            if (ranges > this.$store.getters['config/getValidateRange']) {
                const default_date = this.$store.getters['config/getDefaultDate']();

                this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
                this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();

                this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');

                this.getHistoryData('list');

                // EventEmit.$emit('error', 'Date range is not more than ' + this.$store.getters['config/getValidateRange'] + ' days!');
                this.$swal.fire({
                    icon: 'error',
                    title: 'Date range is not more than ' + this.$store.getters['config/getValidateRange'] + ' days!',
                    text: 'Your date range is ' + ranges + ' days.',
                });
                // return;

            }
             else {
                this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');

                this.getHistoryData('list');
            }

            // this.target_selected = {};
        },
        async startMap() {
            var self = this;
            // var mapsLatLng = [];

            // monas, jakarta
            this.center = {
                lat: -6.1763562,
                lng: 106.8227796,
            };

            this.mapLoaded = true;

            await self.$nextTick(() => {
                self.$refs.mapRefDetail.$mapPromise.then((map) => {
                    const google = self.google;

                    if (self.detail_map_data.point && self.detail_map_data.point[0]) {

                        let bounds_rectangle = {
                            north: parseFloat(self.detail_map_data.point[0].north_east.lat),
                            east: parseFloat(self.detail_map_data.point[0].north_east.lng),
                            south: parseFloat(self.detail_map_data.point[0].south_west.lat),
                            west: parseFloat(self.detail_map_data.point[0].south_west.lng),
                        };

                        self.rectangle = new google.maps.Rectangle({
                            strokeColor: "#FF0000",
                            strokeOpacity: 0.8,
                            strokeWeight: 3,
                            fillColor: "#FFF",
                            fillOpacity: 0.35,
                            bounds: bounds_rectangle,
                            // editable: true,
                            // draggable: true,
                        });

                        self.rectangle.setMap(map);
                        self.mapBounds(self.detail_map_data.point[0]);
                    }


                    if (self.detail_map_data.target && self.detail_map_data.target.length > 0) {
                        for (var i = 0; i < self.detail_map_data.target.length; i++) {

                            Object.keys(self.detail_map_data.target[i].coordinates).forEach(function(k){

                                if (Object.keys(self.detail_map_data.target[i].coordinates[k]).length > 0) {

                                    var icon = {
                                        path: 'M31.5766 15.7225C35.366 15.7225 38.4379 12.6506 38.4379 8.86127C38.4379 5.07189 35.366 2 31.5766 2C27.7872 2 24.7153 5.07189 24.7153 8.86127C24.7153 12.6506 27.7872 15.7225 31.5766 15.7225Z M18 26.8175V41.4161C18 43.1679 19.4599 44.4817 21.0657 44.4817H23.9854L25.5911 59.5183C25.7372 60.9782 26.905 62 28.3649 62H34.6423C36.1022 62 37.2701 60.978 37.4161 59.5183L39.0219 44.4817H41.9416C43.6934 44.4817 45.0073 43.1679 45.0073 41.4161V26.8175C45.1533 14.9926 18 14.9926 18 26.8175Z',
                                        fillColor: self.detail_map_data.target[i].label,
                                        fillOpacity: 1,
                                        // strokeWeight: 1.2,
                                        strokeWeight: 1.2,
                                        strokeColor: '#ffffff',
                                        // scale: 1.25,
                                        scale: .65,
                                        anchor: new google.maps.Point(30,65),
                                    }
                                    
                                    var marker = new google.maps.Marker({
                                        position: {
                                            lat: parseFloat(self.detail_map_data.target[i].coordinates[k].location[0].lat),
                                            lng: parseFloat(self.detail_map_data.target[i].coordinates[k].location[0].lng)
                                        },
                                        map: map,
                                        icon: icon,
                                        // draggable: true,
                                    });

                                    self.markers.push(marker);

                                    var dtm = (self.detail_map_data.target[i].coordinates[k].created_at ? self.datetimeFormat(self.detail_map_data.target[i].coordinates[k].created_at) : '-');
                                    
                                    var contentString = '<div id="content">'+
                                          '<div id="bodyContent">'+
                                          '<table>'+
                                              '<tr>'+
                                                '<td class="pr-1 cell-info-window whitespace-no-wrap">Target</td>'+
                                                '<td class="pr-1 cell-info-window">:</td>'+
                                                '<td class="cell-info-window">'+self.detail_map_data.target[i].target_name+'</td>'+
                                              '</tr>'+
                                              '<tr>'+
                                                '<td class="cell-info-window whitespace-no-wrap">MSISDN</td>'+
                                                '<td class="cell-info-window">:</td>'+
                                                '<td class="cell-info-window">'+k+'</td>'+
                                              '</tr>'+
                                              '<tr>'+
                                                '<td class="cell-info-window whitespace-no-wrap">Datetime</td>'+
                                                '<td class="cell-info-window">:</td>'+
                                                '<td class="cell-info-window">'+dtm+'</td>'+
                                              '</tr>'+
                                              '<tr>'+
                                                '<td class="cell-info-window whitespace-no-wrap">Link Location</td>'+
                                                '<td class="cell-info-window">:</td>'+
                                                '<td class="cell-info-window"><a target="_blank" href="https://www.google.com/maps?q='+self.detail_map_data.target[i].coordinates[k].location[0].lat+','+self.detail_map_data.target[i].coordinates[k].location[0].lng+'" style="color: #43ea92;">'+'Google Maps</a> '+
                                                '</td>'+
                                              '</tr>'+
                                            '</table>'+
                                          '</div>'+
                                          '</div>';

                                    var infowindow = new google.maps.InfoWindow({
                                        content: contentString,
                                        maxWidth: 400
                                    });
                                    
                                    self.infoWindows.push(infowindow); 
                                    
                                    google.maps.event.addListener(marker, 'click', function() {
                                      // hideAllInfoWindows(map);
                                      self.hideAllInfoWindows();
                                      infowindow.open(map, marker);
                                    });
                                }

                            });

                        }

                    }

                    if (self.mapsLatLng.length > 0) {
                        self.polyline = new google.maps.Polyline({
                          path: self.mapsLatLng,
                          strokeColor: '#FF0000',
                          strokeOpacity:0.8,
                          strokeWeight:1.5
                        });

                        self.polyline.setMap(map);
                    }

                });
            });
        },
        async getData() {
            await this.$store.commit('territoryVirtual/setDetailMapData', {});
            this.$store.commit('territoryVirtual/setDetailMapHistory', {});
            this.$store.commit('territoryVirtual/setDetailHistoryList', {});

            if (this.route_name === 'pageTerritoryVirtualDetailNotification') {
                EventEmit.$emit('showLoading', true);
                const default_date = this.$store.getters['config/getDefaultDate']();

                if (this.from_date === '') {

                    this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
                    this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                }

                if (this.to_date === '') {

                    this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();
                    this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');
                }

                this.$store.dispatch('territoryVirtual/getDetailMapData', [this.id], this.target_id, decrypt(this.msisdn))
                .then((resp) => {
                    if (resp === 'success') {
                        EventEmit.$emit('showLoading', false);
                    } else {
                        EventEmit.$emit('error');
                    }
                });

                // this.$store.dispatch('territoryVirtual/getDetailMapHistory', [this.id])
                this.getHistoryData();
            }
        },
        async getHistoryData(section='all') {

            if (section === 'all' || section === 'list') {
                this.target_selected = {};
                // this.$store.commit('setTerritoryVirtualDetailHistoryList', {});
                // await this.$store.dispatch('getTerritoryVirtualDetailHistory', [this.id, this.target_selected.id, parseInt(this.target_selected.msisdn), this.items_per_page, (parseInt(this.page) + 1)]);
                // [id, target_id, msisdn, limit='', page='', from_date='', to_date='']
                await this.$store.dispatch('territoryVirtual/getDetailHistory', [this.id, this.target_id, parseInt(decrypt(this.msisdn)), this.items_per_page, (parseInt(this.page) + 1), this.from_date, this.to_date]);
                // this.pagination();
            }
        },
    },
    filters: {
      date: function (date) {
        return moment(date).format('YYYY-MM-DD');
      }
    },
    async mounted() {
        await this.getData();
        EventEmit.$on('territoryNotificationOnDetail', () => {
            this.getData();
        });
    },
}
</script>

<style lang="sass" scoped>
    .fields-box
        @apply .rounded
        background-color: $color-secondary-600
        &__item
            @apply .p-4 .rounded .flex .border-b
            &:last-child
                border-bottom: 0px
            .field-options
                input
                    margin-top: 1rem
                    &:first-child
                        margin-top: 0px
    .targets-case
        @apply .w-full
        .target-item
            @apply .flex .items-center .p-3 .w-full .rounded .cursor-pointer
            transition: all 0.3s ease-in-out
            &:hover
                background-color: $color-secondary-800

</style>