import Vue from 'vue';
import VueRx from 'vue-rx';
import router from './router/index';
import axios from "./axios-main";
import store from './store';
import MainApp from './MainApp.vue';
import AppLayout from './layouts/AppLayout.vue';
import BlankLayout from './layouts/BlankLayout.vue';
// import VueTabs from 'vue-nav-tabs'
import HighchartsVue from 'highcharts-vue'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VTooltip from 'v-tooltip';
import Vuelidate from "vuelidate";
import * as VueGoogleMaps from "vue2-google-maps";
import {ServerTable, ClientTable} from 'vue-tables-2';
import VueTableDynamic from 'vue-table-dynamic';
import VueMeta from 'vue-meta';
import VuejsClipper from 'vuejs-clipper';
import "vuejs-clipper/dist/vuejs-clipper.css";
import VueSilentbox from 'vue-silentbox'

import "./assets/sass/shell/_tailwind-stuff.sass";
// import './registerServiceWorker'
import fcmConfig from '@/config/fcm';

// import IdleVue from "idle-vue";
import IdleVue from "@/util/idle/src";
import { decrypt } from '@/util/crypto';
import { env } from '@/config/env';
import helpersMixin from '@/util/mixins/helpers';

import VueLazyload from 'vue-lazyload'
// import Highcharts from 'highcharts'
// import exportingInit from 'highcharts/modules/exporting'

// exportingInit(Highcharts)
Vue.component('app-layout', AppLayout)
Vue.component('blank-layout', BlankLayout)

// Vue.use(VueTabs)
Vue.use(HighchartsVue);
Vue.use(VueSweetalert2);
Vue.use(VTooltip);
Vue.use(Vuelidate);
Vue.use(VueSilentbox)
Vue.use(VueGoogleMaps, {
  load: {
    key: (env.VUE_APP_GMAP_API_KEY ? decrypt(env.VUE_APP_GMAP_API_KEY, false, true) : ''),
    libraries: ["places", "visualization"] // necessary for places input
  }
});
Vue.use(ClientTable, {}, false, 'bootstrap4', 'default')
Vue.use(ServerTable, {}, false, 'bootstrap4', 'default')
Vue.use(VueTableDynamic);
Vue.use(VueMeta);
Vue.use(fcmConfig);

Vue.use(VueRx);
Vue.use(VuejsClipper, {
  components: {
      clipperBasic: true,
      clipperPreview: true
  },
})

const eventsHub = new Vue();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  // idleTime: 5000, // 5 seconds
  idleTime: store.getters['config/getIdleWaitingTime'].miliseconds, // miliseconds
  startAtIdle: true,
  moduleName: 'config',
});

const errorimage = require('./assets/no-image-profile.jpg')
const loadimage = require('./assets/loading.gif')

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: errorimage,
  loading: loadimage,
  attempt: 3
})

Vue.mixin(helpersMixin);

Vue.config.productionTip = false;

window.EventEmit = new Vue();

router.beforeEach((to, from, next) => {
	// console.log('router.beforeEach()')
	// console.log(store.state.auth.token)
  let bearer_token = null
  if (store.state.auth.token && store.state.auth.token !== null) {
    bearer_token = "Bearer " + store.state.auth.token
    axios.defaults.headers.common["x-authorization"] = bearer_token;
  } else {
    axios.defaults.headers.common["x-authorization"] = null;
  }

  if (store.state.auth.not_trace_log && bearer_token) {
    axios.defaults.headers.common["x-authorization"] = "1"+bearer_token;
  }

  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.auth.token === null) {
      next({
        path: '/login',
          // params: { nextUrl: to.fullPath }
      })
    } else {
      if (store.state.auth.token) {
        next();

      } else {
        store.state.auth.token = null;
        next({
            path: '/login',
        });
      }
    }
  } else {
    if (store.state.auth.token !== null && (to.name === 'LoginPage' || to.name === 'ForgotPasswordPage')) {
      next({
        path: '/',
      });
    } else {
      next();
    }
  }
});

new Vue({
  router,
  store,
  render: (h) => h(MainApp),
}).$mount('#app');
