<template lang="pug">
.card.card--tab
    .card--tab__header
        h2.text-xl Browser Log
    .card--tab__body
        .box-tabs
            vue-tabs
                v-tab(title="Certificate")
                    BrowserLogCertificate
                v-tab(title="Signal")
                    BrowserLogSignal
                v-tab(title="Signaling Data")
                    BrowserLogDataUsage
                v-tab(title="Browser")
                    BrowserLogBrowser
</template>

<script>
import { VueTabs,VTab } from 'vue-nav-tabs'
import BrowserLogCertificate from './BrowserLogCertificate.vue'
import BrowserLogSignal from './BrowserLogSignal.vue'
import BrowserLogDataUsage from './BrowserLogDataUsage.vue'
import BrowserLogBrowser from './BrowserLogBrowser.vue'
export default {
    name: 'BrowsingLogBrowserLog',
    components: {
        VueTabs,
        VTab,
        BrowserLogCertificate,
        BrowserLogSignal,
        BrowserLogDataUsage,
        BrowserLogBrowser

    }
}
</script>