<template lang="pug">
.face-req-page
    .box
        .box__header
            TitlePage(
                title="Face Recognition"
                    :breadcrumb="breadcrumb"
                )
        .box__body
            .card.card--no-bg
                .fr-area
                    //- .box-upload-image.text-center.hidden
                    .box-upload-image.text-center(v-if="Object.keys(face_recognition).length === 0")
                        template(v-if="imgSrc === ''")
                            .w-32.m-auto
                                <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Person Circle</title><path d='M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm-50.22 116.82C218.45 151.39 236.28 144 256 144s37.39 7.44 50.11 20.94c12.89 13.68 19.16 32.06 17.68 51.82C320.83 256 290.43 288 256 288s-64.89-32-67.79-71.25c-1.47-19.92 4.79-38.36 17.57-51.93zM256 432a175.49 175.49 0 01-126-53.22 122.91 122.91 0 0135.14-33.44C190.63 329 222.89 320 256 320s65.37 9 90.83 25.34A122.87 122.87 0 01382 378.78 175.45 175.45 0 01256 432z'/></svg>
                            p.mb-3 Please upload photo of target
                            clipper-upload.btn-primary.w-full.mb-2(v-model="imgSrc" style="cursor: pointer;") Select Photo

                        template(v-else)
                            clipper-fixed.w-full.mb-2(
                                ref="clipper"
                                :src="imgSrc"
                                preview="fixed-preview"
                                :rotate="rotation"
                                :grid="true"
                                :corner="true"
                                shadow="rgba(0, 0, 0, 0.75)"
                                :ratio="ratio"
                                :wrapRatio="ratio"
                                :area="area"
                                :outline="outline"
                                :min-scale="min_scale"
                                bg-color="#000"
                                :zoomRate="zoomRate"
                            )
                            //- bg-color="transparent"
                            clipper-upload.link.w-full.my-2.text-xs(v-model="imgSrc" style="cursor: pointer;") Change Photo
                            .range-rotate.py-1.px-2.border.rounded.mb-2.text-center
                                span.text-center.text-xs Rotate Photo
                                clipper-range(
                                    v-model="rotation"
                                    style="width: 100%"
                                    :min="-180"
                                    :max="180"
                                )
                            button.btn-primary.w-full(@click="submit()") Search Target

                            //- button.btn-primary.w-full(disable) Searching Target...

                            //- # temporary, hapus soon
                            //- .block.mb-4(v-if="faceDetected.length > 0")
                            //-     h2 Found {{ faceDetected.length }} Objects
                            //-     .box-object 
                            //-         button.list-object(
                            //-             :class="{'list-object--active' : activeIndex === index}" 
                            //-             v-for="(face, index) in faceDetected" 
                            //-             @click="selectFace(index)"
                            //-             :key="index"
                            //-         )
                            //-             img(:src="face", @error="getDefaultImage")

                    //- .box-preview-photo.hidden
                    .box-preview-photo(v-else)
                        .photo-selected.flex.items-center.p-4.bg-black.rounded.mb-4
                            .w-12.h-12.bg-white
                                img.h-full.w-full.object-cover(:src="result")
                            .block.ml-4
                                p.text-sm You have been looking the target based this photo
                                //- button.link.text-xs Change Photo
                                clipper-upload.link.text-xs(v-model="imgSrc" style="cursor: pointer;") Change Photo

                        template(v-if="serviceFR == 'openvino' || (candidates && Object.keys(candidates).length > 0)")
                            .block.mb-4(v-if="face_recognition && face_recognition.data && face_recognition.data.faces")
                                h2 Choose a face to search
                                .box-object 
                                    button.list-object(
                                        :class="{'list-object--active' : activeIndex === index}" 
                                        @click="selectObj(candidate.similiar, index)"
                                        v-for="(candidate, index) in face_recognition.data.candidates" 
                                        :key="index"
                                    )
                                        img(:src="candidate.original", @error="getDefaultImage")

                            .block.mb-4(v-if="faceDetected.length > 0")
                                h2 Choose a face to search
                                .box-object 
                                    button.list-object(
                                        :class="{'list-object--active' : activeIndex === index}" 
                                        v-for="(face, index) in faceDetected" 
                                        @click="selectFace(index)"
                                        :key="index"
                                    )
                                        img(:src="face", @error="getDefaultImage")
                            
                            h2.text-xl.mb-4 Possible related target

                            template(v-if="candidatesLoading")
                                .flex.flex-wrap.items-center.justify-center
                                    Spinner(show="true" size="48")
                            
                            template(v-else-if="Object.keys(candidates).length == 0") 
                                h3.text-xl.mb-4.text-center There is no related targets.

                            template(v-else)
                                .border.p-4.flex.mb-8(
                                    v-for="(candidate, index) in candidates" 
                                    :key="index"
                                )
                                    .mr-8
                                        template(v-if="candidate.target_id == '-' || candidate.msisdn == '-'")
                                            span.block.w-56.h-56.overflow-hidden
                                                img.h-full.w-full.object-cover(v-lazy="candidate.face_image")
                                        template(v-else)
                                            router-link(:to="candidate.msisdn !== '-' ? '/target/'+ candidate.target_id +'/detail/'+ encrypt(candidate.msisdn +'&&msisdn') +'/home' : '/target/'+ candidate.target_id +'/detail/home'").block.w-56.h-56.overflow-hidden
                                                img.h-full.w-full.object-cover(v-lazy="candidate.face_image")
                                    .block.relative
                                        template(v-if="candidate.target_id == '-' || candidate.msisdn == '-'")
                                            span.font-bold.mb-4.block {{ candidate.face_label }}
                                        template(v-else)
                                            router-link(:to="candidate.msisdn !== '-' ? '/target/'+ candidate.target_id +'/detail/'+ encrypt(candidate.msisdn +'&&msisdn') +'/home' : '/target/'+ candidate.target_id +'/detail/home'").link.font-bold.mb-4.block {{ candidate.face_label }}

                                        .label-green.mb-4 {{ candidate.confidence }}%
                                        .mb-2
                                            span.text-xs.uppercase.opacity-75 Keyword
                                            p {{ candidate.msisdn }}
                                            
                                        .mb-2
                                            span.text-xs.uppercase.opacity-75 Case Name
                                            p {{ candidate.case }}

                        h2.text-xl.mb-4.text-center(v-else) There is no related targets.

</template>

<script>
import { mapState } from 'vuex';
import TitlePage from '@/components/shell/TitlePage.vue';
import { clipperFixed, clipperPreview, clipperRange, clipperUpload } from 'vuejs-clipper'
import { encrypt } from '@/util/crypto';
import Spinner from '@/components/Spinner.vue';
import * as faceapi from "face-api.js";

export default {
    name: 'PageFaceRecognition',
    components: {
        TitlePage,
        clipperFixed,
        clipperPreview,
        clipperRange,
        clipperUpload,
        Spinner,
    },
    data() {
        return {
            breadcrumb: [
                {
                    name: 'Face Recognition',
                    url: '/face-recognition'
                }
            ],
            rotation: 0,
            ratio: 1/1,
            outline: 6, // zoom
            imgSrc: '',
            // imgSrc: '/default-face-recognition.png',

            default_img: this.$store.getters['config/getDefaultImageProfile'],

            result: '',
            pixel: '',
            area: 100,
            min_scale: 0.1,
            zoomRate: 0.1,
            candidates: {},
            activeIndex: 0,
            candidatesLoading: false,

            faceDetected: [],
            respFR: [],
            serviceFR: this.$store.getters['config/getServiceFR'],

            loadFaceLandmark: 'load',
            loadFaceRecog: 'load',
            loadSsdMobile: 'load',
        }
    },
    computed: {
        ...mapState('faceRecognition', [
            'status_face_recognition',
            'face_recognition',
        ]),
        route_name() {
            return this.$route.name;
        },
        loadLibraryFR() {
            let loadFaceLandmarkTmp = this.loadFaceLandmark
            let loadFaceRecogTmp = this.loadFaceRecog
            let loadSsdMobileTmp = this.loadSsdMobile
            // console.log({loadFaceLandmarkTmp, loadFaceRecogTmp, loadSsdMobileTmp})
            if (loadFaceLandmarkTmp=='success' && loadFaceRecogTmp=='success' && loadSsdMobileTmp=='success')
                return 'success'
            if (loadFaceLandmarkTmp=='error' || loadFaceRecogTmp=='error' || loadSsdMobileTmp=='error')
                return 'error'
            return 'load'
        },
    },
    watch: {
        route_name() {},
        imgSrc() {
            this.setEmpty();
            this.$store.dispatch('faceRecognition/setEmpty');
        },
    },
    methods: {
        getDefaultImage(e) {
            e.target.src = this.default_img;
        },
        encrypt(string) {
            return encrypt(string);
        },
        dataURLtoFile(dataurl, filename) {
            const arr = dataurl.split(',')
            const mime = arr[0].match(/:(.*?);/)[1]
            const bstr = atob(arr[1])
            let n = bstr.length
            const u8arr = new Uint8Array(n)
            while (n) {
                u8arr[n - 1] = bstr.charCodeAt(n - 1)
                n -= 1 // to make eslint happy
            }
            return new File([u8arr], filename, { type: mime })
        },
        setEmpty() {
            // this.$store.dispatch('faceRecognition/setEmpty');
            this.faceDetected = [];
            this.respFR = [];
            this.candidates = {};
            this.activeIndex = 0;
        },
        async selectObj(data, index) {
            this.candidatesLoading = true
            this.candidates = data
            this.activeIndex = index
            setTimeout(() => this.candidatesLoading = false, 300);
        },
        async selectFace(index) {
            // console.log('index')
            // console.log(index)
            this.candidatesLoading = true
            this.activeIndex = index
            if (Object.keys(this.respFR[index]).length) {
                // sudah ada response
                setTimeout(() => this.candidatesLoading = false, 300);
                this.candidates = this.respFR[index].candidates
            } else {
                // get API
                let image = await this.dataURLtoFile(this.faceDetected[index])
                await this.getData(image, index)
                this.candidatesLoading = false
            }

        },
        async getData(image, index = null) {
            await this.$store.dispatch('faceRecognition/getFaceRecognition', image);
            
            switch(this.status_face_recognition.status) {
                case true: {
                    if (this.face_recognition.data && this.face_recognition.data.candidates && this.face_recognition.data.candidates.length > 0) {
                        if(this.face_recognition.data.faces){
                            this.candidates = this.face_recognition.data.candidates[0].similiar
                        }
                        else{
                            this.candidates = this.face_recognition.data.candidates
                        }

                        await this.showLoadingCustom(false);
                        this.$swal.fire({
                            icon: 'success',
                            title: 'Done!',
                            timer: 3000,
                        });

                    } else {
                        await this.showLoadingCustom(false);
                        EventEmit.$emit('error', 'Data is empty.');
                    }
                    let dataFR = {
                        candidates: [],
                        error: true,
                        message: "no faces detected"
                    }
                    let dataCandidate = {}
                    try {
                        if("data" in this.face_recognition){
                            dataFR = this.face_recognition.data
                        } 
                    } catch (error) {
                        //
                    }
                    try {
                        dataCandidate = dataFR.candidates
                    } catch (error) {
                        dataCandidate = {}
                    }
                    if (index != null) {
                        this.respFR[index] = dataFR
                        this.candidates = dataCandidate
                    }
                    break;
                }

                case 'failed':
                    await this.showLoadingCustom(false);
                    EventEmit.$emit('error');
                    break;

                default:
                    EventEmit.$emit('showLoading', false);
                    await this.showLoadingCustom(false);
                    break;
            }
        },
        async sleep(ms) {
            return new Promise((resolve) => {
                setTimeout(resolve, ms);
            });
        },
        async showLoadingCustom(show=true, message=null) {
            // console.log('showLoading log')
            // console.log({show, message})
            if (show) {
                message = message ? message.toString() : 'Loading...'
                this.$swal.fire({
                    title: '',
                    html: '<div class="save_loading"><svg viewBox="0 0 140 140" width="140" height="140"><g class="outline"><path d="m 70 28 a 1 1 0 0 0 0 84 a 1 1 0 0 0 0 -84" stroke="rgba(0,0,0,0.1)" stroke-width="4" fill="none" stroke-linecap="round" stroke-linejoin="round"></path></g><g class="circle"><path d="m 70 28 a 1 1 0 0 0 0 84 a 1 1 0 0 0 0 -84" stroke="#71BBFF" stroke-width="4" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-dashoffset="200" stroke-dasharray="300"></path></g></svg></div><div><h4>'+message+'</h4></div>',
                    showConfirmButton: false,
                    allowOutsideClick: false
                });
                // console.log({show, message})

            } else {
                this.$swal.close();
            }
        },
        async submit() {
            await this.showLoadingCustom(true, 'Searching Target...');
            // await EventEmit.$emit('showLoading', true, 'Searching Target...');
            const delayInMilliseconds = 1;

            setTimeout(async () => {
                if (this.serviceFR == 'openvino') {
                    await this.checkOpenvino()
                }

                if (this.imgSrc === '' ) {
                    await this.showLoadingCustom(false);
                    EventEmit.$emit('error', 'Please choose an image.');
                    return;
                }

                this.setEmpty();

                const canvas = this.$refs.clipper.clip();
                this.result = await canvas.toDataURL("image/jpeg", 1);
                let image = await this.dataURLtoFile(this.result);

                // await crop image by faces
                console.log('=====serviceFR: ' + this.serviceFR)
                if (this.serviceFR == 'openvino') {
                    await this.detectFace(canvas)
                } else {
                    this.getData(image)
                }
            }, delayInMilliseconds);
        },

        async checkOpenvino() {
            let checkLoadLib = '';
            let continueLoop = true;
            while (continueLoop){
                let loadLibraryFRTmp = this.loadLibraryFR
                if (loadLibraryFRTmp=='success'){
                    // console.log('this.loadLibraryFR: success')
                    continueLoop = false;
                } else if (loadLibraryFRTmp=='error'){
                    // console.log('this.loadLibraryFR: error')
                    checkLoadLib = 'error';
                    continueLoop = false;
                }

                // console.log('this.loadLibraryFR: load')
                if (!checkLoadLib){
                    // console.log('this.loadLibraryFR: load - first load')
                    // await EventEmit.$emit('showLoading', true, 'Load Library...');
                    await this.showLoadingCustom(true, 'Load Library...');
                    checkLoadLib = 'load';
                }
                await this.sleep(1000);
            }

            if (checkLoadLib=='load')
                // await EventEmit.$emit('showLoading', true, 'Searching Target...');
                await this.showLoadingCustom(true, 'Searching Target...');
            else if (checkLoadLib=='error'){
                // await EventEmit.$emit('showLoading', false);
                await this.showLoadingCustom(false);
                await EventEmit.$emit('error', 'Something wrong with library. Please reload page');
                return;
            }
        },
        async detectFace(img) {
            const input = img;
            
            if (input){
                const options = new faceapi.SsdMobilenetv1Options({
                    minConfidence: 0.3,
                    maxResults: 100,
                })
                let detections = await faceapi
                .detectAllFaces(input, options)
                // .detectAllFaces(input);
                .withFaceLandmarks()
                // .withFaceExpressions()
                // .withAgeAndGender()
                .withFaceDescriptors();
                // console.log({detections})
                let responseData = '';
                if (detections && detections.length){
                    detections.forEach((element, index) => {
                        this.cropFace(element.detection, index)
                    });
                    // if (detections.length>1){
                    //     console.log('===== face more than 1:', detections.length);
                    //     detections.forEach((element, index) => {
                    //         // console.log(element)
                    //         this.cropFace(element.detection, index)
                    //     });
                    // } else {
                    //     console.log('=====one face')
                    //     let tmpFace = await input.toDataURL("image/jpeg", 1);
                    //     let image = await this.dataURLtoFile(tmpFace);
                    //     this.getData(image, 0)
                    //     this.faceDetected.push(tmpFace)
                    //     // this.respFR.push({})
                    // }
                } else {
                     // send all crop
                    // console.log('tidak jelas')
                    console.log('=====no face detected')
                    let tmpFace = await input.toDataURL("image/jpeg", 1);
                    let image = await this.dataURLtoFile(tmpFace);
                    this.getData(image, 0)
                    this.faceDetected.push(tmpFace)
                    // this.respFR.push({})
                }

                if (responseData!=''){
                    this.$swal.fire({
                            icon: 'error',
                            title: 'Image Failed!',
                            text: responseData,
                        });
                }
            }
        },
        async cropFace(detection, index) {
            let box = detection._box
            let regionsToExtract = []
            try {
                // Calculate the extended area to include hair (adjust as needed)
                const extendWidth = Math.round(box._width * 0.15);
                const extendHeightTop = Math.round(box._height * 0.55); // extend upwards for hair
                const extendHeightBottom = Math.round(box._height * 0.2); // extend downwards for chin
    
                // Create a new faceapi.Rect that includes the hair area
                regionsToExtract = [
                    new faceapi.Rect(
                        Math.max(box._x - extendWidth, 0),
                        Math.max(box._y - extendHeightTop, 0),
                        box._width + extendWidth * 2,
                        box._height + extendHeightTop + extendHeightBottom
                    )
                ];
            } catch (error) {
                // console.log('error custom pixel')
                // console.log(error)
                regionsToExtract = [
                    new faceapi.Rect((box._x), (box._y), (box._width), (box._height))
                ]
            }
            const canvas = this.$refs.clipper.clip();
            // var tmpImg = await canvas.toDataURL("image/jpeg", 1);
            // actually extractFaces is meant to extract face regions from bounding boxes
            // but you can also use it to extract any other region
            // console.log('canvas')
            // console.log(canvas)
            const face = await faceapi.extractFaces(canvas, regionsToExtract)
            // console.log('face')
            // console.log(face[0])
            let tmpFace = await face[0].toDataURL("image/jpeg", 1);
            this.faceDetected.push(tmpFace)
            this.respFR.push({})
            if (index == 0) {
                let image = await this.dataURLtoFile(tmpFace);
                this.getData(image, index)
            } 
        },
    },
    beforeMount() {
        // this.submit()
        let self = this
        faceapi.loadFaceLandmarkModel('/weight').then((succ)=>{
            // console.log('loadFaceLandmarkModel SUCC')
            // console.log({succ})
            self.loadFaceLandmark = 'success'
        }).catch((err)=>{
            // console.log('loadFaceLandmarkModel ERR')
            // console.log({err})
            self.loadFaceLandmark = 'error'
        })
        faceapi.loadFaceRecognitionModel('/weight').then((succ)=>{
            // console.log('loadFaceRecognitionModel SUCC')
            // console.log({succ})
            self.loadFaceRecog = 'success'
        }).catch((err)=>{
            // console.log('loadFaceRecognitionModel ERR')
            // console.log({err})
            self.loadFaceRecog = 'error'
        })
        faceapi.loadSsdMobilenetv1Model('/weight').then((succ)=>{
            // console.log('loadSsdMobilenetv1Model SUCC')
            // console.log({succ})
            self.loadSsdMobile = 'success'
        }).catch((err)=>{
            // console.log('loadSsdMobilenetv1Model ERR')
            // console.log({err})
            self.loadSsdMobile = 'error'
        })
        // faceapi.loadTinyFaceDetectorModel('/weight');

    },
    mounted() {},
    created() {
        this.$store.commit('faceRecognition/setFaceRecognition', {});
    },
}
</script>

<style lang="sass" scoped>
    .ionicon
        fill: #ffffff

    .accuration
        position: absolute
        top: 0
        right: 0

    .box-upload-image
        max-width: 400px
        margin: 0 auto

    .fr-area
        padding: 2rem 0rem
        max-width: 560px
        margin: 0 auto
    .box-object
        display: flex
        align-items: center
        margin-top: 12px
        flex-wrap: wrap
        .list-object
            flex: 0 0 calc( 100% / 8 )
            img
                width: 100%
                height: 70px
                overflow: hidden
                object-fit: contain
                // object-fit: cover
                border: 4px solid transparent
                &:hover
                    opacity: 0.8
            &--active
                img
                    border-color: #70F2A1
                    &:hover
                        opacity: 1

</style>
