<template lang="pug">
.result-page
    //-  Head of target
        - Device + Name + Msisdn + IMSI + IMEI
    HeadFull(v-if="!isTargetDetail")
    //- Head(v-if="!isTargetDetail")
    //-  Head of detail target
    HeadProfile(v-else)

    //-  Menus of target
        - Overall + Location Log + Browsing Log + Aerial Log + Profile + Device
    Menus

    .result-page__content
        router-view(:isKey="isKey")
</template>

<script>
import Head from '@/components/result/Head.vue';
import HeadFull from '@/components/result/HeadFull.vue';
import HeadProfile from '@/components/result/HeadProfile.vue';
import Menus from '@/components/result/Menus.vue';
import { mapState } from 'vuex';
import { decrypt, generateRandomString } from '@/util/crypto';
// import moment from 'moment';
import { checkFeature } from '@/config/features';


export default {
    name:'ResultShell',
    components: {
        Head,
        HeadFull,
        HeadProfile,
        Menus,
    },
    data: function() {
        return {
            // get_data_id: null,
            isTargetDetail:false,
        };
    },
    computed: {
        ...mapState({
            status_subheader: (state) => state.subheader.status,
            status_recent_location: (state) => state.recentLocation.status,
            recent_location: (state) => state.recentLocation.recent_location,
            status_overall: (state) => state.overall.status,
            status_location_movement: (state) => state.locationMovement.status,
            status_location_log: (state) => state.locationLog.status,
            status_browsing_log: (state) => state.browsingLog.status,
            status_aerial_log: (state) => state.aerialLog.status,
            status_profile: (state) => state.profile.status,
            // status_device_log: (state) => state.deviceLog.status,
            status_history_device_roles: (state) => state.historyDeviceRoles.status,
            status_device_history: (state) => state.deviceHistory.status,
            status_tethered_device: (state) => state.tetheredDevice.status,
            status_prediction_name: (state) => state.predictionName.status,
        }),
        route() {
            return this.$route;
        },
        key() {
            return this.$route.params.key;
        },
        isKey() {
            return this.key ? true : false;
        },
        id() {
            return this.$route.params.id;
        },
        first_historical() {
            let route_historical = {
                name: "ResultHistoricalOverall",
                path: "overall",
                snake: "overall"
            }
            try {
                let tmp_first = this.$router.options.routes.find((item) => item.name == "ResultHistoricalShell").children[0];
                route_historical = {
                    name: tmp_first.name,
                    path: tmp_first.path,
                    snake: tmp_first.path.replace("-", "_")
                }
            } catch (error) {
                console.log('=====error route_historical', error)
            }
            return route_historical
        },
    },
    watch: {
        status_subheader(newStatus, oldStatus) {
            // Perform your desired actions when the value of status_subheader changes
            console.log('status_subheader changed:', newStatus);
            // You can also access the old value using `oldStatus`
            console.log('Previous status_subheader value:', oldStatus);
        },
        route(newRoute, oldRoute) {
            // console.log('=====new route', newRoute)
            // console.log('=====old route', oldRoute)
            if(newRoute.matched[0].name === 'pageTargetDetail'){
                this.isTargetDetail = true
            } else {
                this.isTargetDetail = false
            }
            if (
                    (
                        newRoute.matched[0].name === 'ResultShell' && oldRoute.matched[0].name === 'ResultShell'
                    ) || (
                        newRoute.matched[0].name === 'ResultRealtimeShell' && oldRoute.matched[0].name === 'ResultRealtimeShell'
                    ) || (
                        newRoute.matched[0].name === 'ResultHistoricalShell' && oldRoute.matched[0].name === 'ResultHistoricalShell'
                    ) || (
                        newRoute.matched[0].name === 'pageTargetDetail' && oldRoute.matched[0].name === 'pageTargetDetail'
                    )
                ) {
                this.getDataOnRouteChange(newRoute, oldRoute);
            } else if (newRoute.matched[0].name === 'ResultHistoricalShell' && oldRoute.matched[0].name === 'ResultRealtimeShell') {
                this.setEmptyResultData('subheader')
                this.getDataOnRouteChange(newRoute, oldRoute, true);
            }
        },
        async key(newData, oldData) {
            // console.log('===== key')
            // console.log(newData, oldData)
            await this.setEmptyResultData('all', true, true);
            await this.setDefaultDateRange();
            await this.getData();
        },
        isKey() {},
    },
    methods: {
        getDataOnRouteChange(newRoute, oldRoute, force=false) {
            // console.log('===== getDataOnRouteChange newRoute.matched[1].name', newRoute.matched[1].name)
            // console.log("=====newRoute.matched[1].name === 'TargetDetailRecentLocation' && this.status_recent_location === false", newRoute.matched[1].name === 'TargetDetailRecentLocation' && this.status_recent_location === false)
            if (
                (
                    (
                        newRoute.matched[1].name === this.first_historical.name && force
                    ) &&
                    this['status_'+this.first_historical.snake] === false
                ) || (
                    (
                        newRoute.matched[1].name === 'ResultRecentLocation' ||
                        // newRoute.matched[1].name === 'ResultRealtimeRecentLocation' ||
                        newRoute.matched[1].name === 'TargetDetailRecentLocation'
                    ) &&
                    this.status_recent_location === false
                ) || (
                    (
                        newRoute.matched[1].name === 'ResultOverall' ||
                        // newRoute.matched[1].name === 'ResultHistoricalOverall' ||
                        newRoute.matched[1].name === 'TargetDetailOverall'
                    ) &&
                    this.status_overall === false
                ) || (
                    (
                        newRoute.matched[1].name === 'ResultLocationMovement' ||
                        // newRoute.matched[1].name === 'ResultHistoricalLocationMovement' ||
                        newRoute.matched[1].name === 'TargetDetailLocationMovement'
                    ) && 
                    this.status_location_movement === false
                ) || (
                    
                    (
                        newRoute.matched[1].name === 'ResultLocationLog' ||
                        // newRoute.matched[1].name === 'ResultHistoricalLocationLog' ||
                        newRoute.matched[1].name === 'TargetDetailLocationLog'
                    ) &&
                    this.status_location_log === false
                ) || (
                    (
                        newRoute.matched[1].name === 'ResultBrowsingLog' ||
                        // newRoute.matched[1].name === 'ResultHistoricalBrowsingLog' ||
                        newRoute.matched[1].name === 'TargetDetailBrowsingLog'
                    ) &&
                    this.status_browsing_log === false
                ) || (
                    (
                        newRoute.matched[1].name === 'ResultAerialLog' ||
                        // newRoute.matched[1].name === 'ResultHistoricalAerialLog' ||
                        newRoute.matched[1].name === 'TargetDetailAerialLog'
                    ) && 
                    this.status_aerial_log === false
                ) || (
                    (
                        newRoute.matched[1].name === 'ResultProfile' ||
                        // newRoute.matched[1].name === 'ResultHistoricalProfile' ||
                        newRoute.matched[1].name === 'TargetDetailProfile'
                    ) &&
                    this.status_profile === false
                ) || (
                    (
                        newRoute.matched[1].name === 'ResultHistoryDeviceRoles' ||
                        // newRoute.matched[1].name === 'ResultHistoricalHistoryDeviceRoles' ||
                        newRoute.matched[1].name === 'TargetDetailHistoryDeviceRoles'
                    ) && 
                    this.status_history_device_roles === false
                ) || (
                    (
                        newRoute.matched[1].name === 'ResultDeviceHistory' ||
                        // newRoute.matched[1].name === 'ResultHistoricalDeviceHistory' ||
                        newRoute.matched[1].name === 'TargetDetailDeviceHistory'
                    ) && 
                    this.status_device_history === false
                ) || (
                    (
                        newRoute.matched[1].name === 'ResultTetheredDevice' ||
                        // newRoute.matched[1].name === 'ResultHistoricalTetheredDevice' || 
                        newRoute.matched[1].name === 'TargetDetailTetheredDevice'
                    ) && 
                    this.status_tethered_device === false
                ) || (
                    (
                        newRoute.matched[1].name === 'ResultPredictionName' ||
                        // newRoute.matched[1].name === 'ResultHistoricalPredictionName' || 
                        newRoute.matched[1].name === 'TargetDetailPredictionName'
                    ) && 
                    this.status_prediction_name === false
                )
            )
                // this.getData(true);
                this.getData();
        },
        async setEmptyResultData(set='all', reset_date_range=false, stop=false) {
            // console.log('masuk setEmptyResultData')
            let def = this.$store.getters['config/getDateRangeByRoute'](this.route.matched[1].name)
            let date_format = this.$store.getters['config/getDateFormatFilterByRoute'](this.route.matched[1].name)
            const default_date = this.$store.getters['config/getDefaultDate'](def, date_format);
            // set empty all result data
            if (set === 'all' || set === 'subheader')
                await this.$store.dispatch('subheader/setEmpty');
            if (set === 'all' || set === 'recentLocation') {
                if (reset_date_range) {
                    await this.$store.commit('recentLocation/setFromDate', default_date.from_date);
                    await this.$store.commit('recentLocation/setToDate', default_date.to_date);
                }
                await this.$store.dispatch('recentLocation/setEmpty');
            }
            if (set === 'all' || set === 'overall') {
                // if (stop)
                //     await this.$store.commit('overall/setStop', true);

                if (reset_date_range) {
                    await this.$store.commit('overall/setFromDate', default_date.from_date);
                    await this.$store.commit('overall/setToDate', default_date.to_date);
                }
                await this.$store.dispatch('overall/setEmpty');
            }
            if (set === 'all' || set === 'locationMovement') {
                if (reset_date_range) {
                    await this.$store.commit('locationMovement/setFromDate', default_date.from_date);
                    await this.$store.commit('locationMovement/setToDate', default_date.to_date);
                }
                await this.$store.dispatch('locationMovement/setEmpty');
            }
            if (set === 'all' || set === 'locationLog') {
                if (reset_date_range) {
                    await this.$store.commit('locationLog/setFromDate', default_date.from_date);
                    await this.$store.commit('locationLog/setToDate', default_date.to_date);
                }
                await this.$store.dispatch('locationLog/setEmpty');
            }
            if (set === 'all' || set === 'browsingLog') {
                if (reset_date_range) {
                    await this.$store.commit('browsingLog/setFromDate', default_date.from_date);
                    await this.$store.commit('browsingLog/setToDate', default_date.to_date);
                }
                await this.$store.dispatch('browsingLog/setEmpty');
            }
            if (set === 'all' || set === 'aerialLog') {
                if (reset_date_range) {
                    await this.$store.commit('aerialLog/setFromDate', default_date.from_date);
                    await this.$store.commit('aerialLog/setToDate', default_date.to_date);
                }
                await this.$store.dispatch('aerialLog/setEmpty');
            }
            if (set === 'all' || set === 'profile') {
                await this.$store.dispatch('profile/setEmpty');
            } 
            // if (set === 'all' || set === 'deviceLog') {
            //     if (reset_date_range) {
            //         await this.$store.commit('deviceLog/setFromDate', default_date.from_date);
            //         await this.$store.commit('deviceLog/setToDate', default_date.to_date);
            //     }
            //     await this.$store.dispatch('deviceLog/setEmpty');
            // }
            if (set === 'all' || set === 'historyDeviceRoles') {
                if (reset_date_range) {
                    await this.$store.commit('historyDeviceRoles/setFromDate', default_date.from_date);
                    await this.$store.commit('historyDeviceRoles/setToDate', default_date.to_date);
                }
                await this.$store.dispatch('historyDeviceRoles/setEmpty');
            }
            if (set === 'all' || set === 'deviceHistory') {
                if (reset_date_range) {
                    await this.$store.commit('deviceHistory/setFromDate', default_date.from_date);
                    await this.$store.commit('deviceHistory/setToDate', default_date.to_date);
                }
                await this.$store.dispatch('deviceHistory/setEmpty');
            }
            if (set === 'all' || set === 'tetheredDevice') {
                if (reset_date_range) {
                    await this.$store.commit('tetheredDevice/setFromDate', default_date.from_date);
                    await this.$store.commit('tetheredDevice/setToDate', default_date.to_date);
                }
                await this.$store.dispatch('tetheredDevice/setEmpty');
            }
            if (set === 'all' || set === 'predictionName') {
                await this.$store.dispatch('predictionName/setEmpty');
            }
        },
        async getData() {
            if ((this.route.matched[0] && this.route.matched[0].name === 'ResultShell') || (this.route.matched[0] && this.route.matched[0].name === 'ResultRealtimeShell') || (this.route.matched[0] && this.route.matched[0].name === 'ResultHistoricalShell') || (this.route.matched[0] && this.route.matched[0].name === 'pageTargetDetail')) {

                if (this.isKey) {

                    // let get_data_id = await generateRandomString(40);
                    // this.get_data_id = get_data_id;
                    // // console.log(get_data_id)
                    // if (get_data_id !== this.get_data_id)
                    //     return;

                    let decrypt_data = decrypt(this.key);
                    decrypt_data = decrypt_data.split('&&');

                    var key = decrypt_data[0];
                    var type = decrypt_data[1];

                    let action = '';

                    let service = '';

                    // console.log('this.route.matched[0].name')
                    // console.log(this.route.matched[0].name)
                    switch(this.route.matched[0].name) {
                        case 'ResultShell':
                            service = 'default';
                            break;

                        case 'ResultRealtimeShell':
                        case 'ResultHistoricalShell':
                            service = 'external';
                            break;
                        
                        case 'pageTargetDetail':
                            service = 'internal';
                            break;
                    }

                    // console.log('===== getData() this.route.matched[1].name: ', this.route.matched[1].name)
                    // console.log('===== getData() service: ', service)
                    // console.log(this.route)
                    switch(this.route.matched[1].name) {
                        case 'pageTargetDetailHome':
                            this.$store.dispatch('target/setLoading');
                            this.$store.commit('target/setRedirect', 'detail');        
                            this.$store.dispatch('target/getResultData',[this.id]);
                            break;
                        case 'ResultRecentLocation':
                        case 'ResultRealtimeRecentLocation':
                        case 'TargetDetailRecentLocation':
                            this.$store.dispatch('recentLocation/setLoading');
                            action = 'recentLocation/getResultData';
                            break;
                        case 'ResultOverall':
                        case 'ResultHistoricalOverall':
                        case 'TargetDetailOverall':
                            // set loading before subheader
                            this.$store.dispatch('overall/setLoading');
                            action = 'overall/getResultData';
                            break;
                        case 'ResultLocationMovement':
                        case 'ResultHistoricalLocationMovement':
                        case 'TargetDetailLocationMovement':
                            this.$store.dispatch('locationMovement/setLoading');
                            action = 'locationMovement/getResultData';
                            break;
                        case 'ResultLocationLog':
                        case 'ResultHistoricalLocationLog':
                        case 'TargetDetailLocationLog':
                            this.$store.dispatch('locationLog/setLoading');
                            action = 'locationLog/getResultData';
                            break;
                        case 'ResultBrowsingLog':
                        case 'ResultHistoricalBrowsingLog':
                        case 'TargetDetailBrowsingLog':
                            this.$store.dispatch('browsingLog/setLoading');
                            action = 'browsingLog/getResultData';
                            break;
                        case 'ResultAerialLog':
                        case 'ResultHistoricalAerialLog':
                        case 'TargetDetailAerialLog':
                            this.$store.dispatch('aerialLog/setLoading');
                            action = 'aerialLog/getResultData';
                            break;
                        case 'ResultProfile':
                        case 'ResultHistoricalProfile':
                        case 'TargetDetailProfile':
                            this.$store.dispatch('profile/setLoading');
                            action = 'profile/getResultData';
                            break;
                        // case 'ResultDeviceLog':
                        //     this.$store.dispatch('deviceLog/setLoading');
                        //     action = 'deviceLog/getResultData';
                        //     break;
                        case 'ResultHistoryDeviceRoles':
                        case 'ResultHistoricalHistoryDeviceRoles':
                        case 'TargetDetailHistoryDeviceRoles':
                            this.$store.dispatch('historyDeviceRoles/setLoading');
                            action = 'historyDeviceRoles/getResultData';
                            break;
                        case 'ResultDeviceHistory':
                        case 'ResultHistoricalDeviceHistory':
                        case 'TargetDetailDeviceHistory':
                            this.$store.dispatch('deviceHistory/setLoading');
                            action = 'deviceHistory/getResultData';
                            break;
                        case 'ResultTetheredDevice':
                        case 'ResultHistoricalTetheredDevice':
                        case 'TargetDetailTetheredDevice':
                            this.$store.dispatch('tetheredDevice/setLoading');
                            action = 'tetheredDevice/getResultData';
                            break;
                        case 'ResultPredictionName':
                        case 'ResultHistoricalPredictionName':
                        case 'TargetDetailPredictionName':
                            this.$store.dispatch('predictionName/setLoading');
                            action = 'predictionName/getResultData';
                            break;
                        default:
                            // 
                    }

                    // get subheader
                    // if (this.route.matched[0].name !== 'pageTargetDetail' && this.status_subheader === false)
                    // console.log(this.status_subheader)
                    // if (this.status_subheader === false)
                    //     // console.log('this.status_subheader false')
                    //     // console.log(this.status_subheader)
                    //     var service_type = service 
                    //     if (this.route.matched[0].name !== 'pageTargetDetail')
                    //         service_type = 'default'

                    //     await this.$store.dispatch('subheader/getResultData', [key, type, service_type]);

                    // if (action.length > 0)
                    //     // console.log('=====service')
                    //     // console.log(service)
                    //     await this.$store.dispatch(action, [key, type, service]);

                    var test = this.status_subheader
                    var testRoute = this.route
                    // console.log({test, testRoute})
                    console.log({test, testRoute})
                    
                    switch(this.route.matched[1].name) {
                        case 'ResultRecentLocation':
                        case 'ResultRealtimeRecentLocation':
                        // case 'TargetDetailRecentLocation':
                            if (this.status_subheader === false) {
                                if(!checkFeature('subheader|without_name')){
                                    await this.$store.dispatch('subheader/setLoading',['onlyName', true]);
                                }
                                await this.$store.dispatch('subheader/setLoading',['withoutName', true]);
                            }

                            if (action.length > 0) {
                                if(!checkFeature('subheader|without_name')){
                                    // let add_params = {
                                    //     function_sub_name: "realtime"
                                    // }
                                    let add_params = {}
                                    this.$store.dispatch('subheader/getResultData', [key, type, service_type, add_params, 'onlyName']);
                                }
                                await this.$store.dispatch(action, [key, type, service]);
                            }
                            
                            if (this.status_subheader === 'loading') {
                                var service_type = service 
                                if (this.route.matched[0].name !== 'pageTargetDetail')
                                    service_type = 'default'
                                
                                if(action =='recentLocation/getResultData') {
                                    var params = null
                                    // console.log('this.status_recent_location')
                                    // console.log(this.status_recent_location)
                                    if(this.status_recent_location == true) {
                                        // console.log('this.recent_location')
                                        // console.log(this.recent_location)
                                        try {
                                            if(this.recent_location.features) {
                                                params = {
                                                    msisdn: this.recent_location.features[0].properties.msisdn && this.recent_location.features[0].properties.msisdn.length > 0 ? this.recent_location.features[0].properties.msisdn : '-',
                                                    imsi: this.recent_location.features[0].properties.imsi && this.recent_location.features[0].properties.imsi.length > 0 ? this.recent_location.features[0].properties.imsi : '-',
                                                    imei: this.recent_location.features[0].properties.imei && this.recent_location.features[0].properties.imei.length > 0 ? this.recent_location.features[0].properties.imei : '-',
                                                    device_name: this.recent_location.features[0].properties.ttype && this.recent_location.features[0].properties.ttype.length > 0 ? this.recent_location.features[0].properties.ttype : '-',
                                                    aol: this.recent_location.features[0].properties.aol && this.recent_location.features[0].properties.aol.length > 0 ? this.recent_location.features[0].properties.aol : '-',
                                                    state: this.recent_location.features[0].properties.state && this.recent_location.features[0].properties.state.length > 0 ? this.recent_location.features[0].properties.state : '-',
                                                    source: this.recent_location.features[0].properties.source && this.recent_location.features[0].properties.source.length > 0 ? this.recent_location.features[0].properties.source : '-',
                                                }

                                            }
                                        } catch (error) {
                                            console.log(error)
                                        }
                                    }
                                    // await this.$store.dispatch('subheader/getResultData', [key, type, service_type, params, 'withoutName']);
                                    await this.$store.dispatch('subheader/getResultData', [key, type, service_type, params, 'withoutNameLbs']);
                                } else {
                                    // await this.$store.dispatch('subheader/getResultData', [key, type, service_type, null, 'withoutName']);
                                    await this.$store.dispatch('subheader/getResultData', [key, type, service_type, null, 'withoutNameLbs']);
                                }

                            }
                            break;
                        default:
                            if (this.status_subheader === false) {
                                // console.log('this.status_subheader false')
                                // console.log(this.status_subheader)
                                service_type = service 
                                if (this.route.matched[0].name !== 'pageTargetDetail')
                                    service_type = 'default'

                                if (service_type == 'internal') {
                                    await this.$store.dispatch('subheader/setLoading',['full', true]);
                                    await this.$store.dispatch('subheader/getResultData', [key, type, service_type, null]);
                                } else {

                                    var setLoadingName = null;
                                    var getDataName = null;
                                    if(!checkFeature('subheader|without_name')){
                                        setLoadingName = await this.$store.dispatch('subheader/setLoading',['onlyName', true]);
                                        // let add_params = {
                                        //     function_sub_name: "historical"
                                        // }
                                        let add_params = {}
                                        getDataName = this.$store.dispatch('subheader/getResultData', [key, type, service_type, add_params, 'onlyName']);
                                    }
                                    // setLoadingName
                                    await this.$store.dispatch('subheader/setLoading',['withoutNameSubintel', true]);
                                    
                                    // await this.$store.dispatch('subheader/getResultData', [key, type, service_type]);
                                    if (action == 'profile/getResultData') {
                                        await Promise.all([
                                            getDataName, 
                                            this.$store.dispatch('subheader/getResultData', [key, type, service_type, null, 'withoutNameSubintel'])
                                        ]);

                                    } else {
                                        // getDataName
                                        await this.$store.dispatch('subheader/getResultData', [key, type, service_type, null, 'withoutNameSubintel']);
                                    }
                                }
                                
                            }

                            if (action.length > 0) {
                                // console.log('=====service')
                                // console.log(service)
                                // console.log(action)
                                // profile/getResultData
                                await this.$store.dispatch(action, [key, type, service]);
                            }
                    }

                } else {
                    await this.setEmptyResultData();
                }
            }
        },
        setDefaultDateRange() {
            let mod = [
                'recentLocation',
                'overall',
                'locationMovement',
                'locationLog',
                'browsingLog',
                'aerialLog',
                // 'profile',
                'historyDeviceRoles',
                'deviceHistory',
                'tetheredDevice'
                // 'predictionName'                
            ]
            
            const default_record_size = this.$store.getters['config/getDefaultRecordSize'];

            for (let i = 0; i < mod.length; i++) {
                let def = this.$store.getters['config/getDateRangeByRoute'](mod[i])
                let date_format = this.$store.getters['config/getDateFormatFilterByRoute'](mod[i])
                const default_date = this.$store.getters['config/getDefaultDate'](def, date_format);

                this.$store.commit(mod[i]+'/setRecordSize', default_record_size);
                this.$store.commit(mod[i]+'/setFromDate', default_date.from_date);
                this.$store.commit(mod[i]+'/setToDate', default_date.to_date);                
            }
            // let def = this.$store.getters['config/getDateRangeByRoute'](this.route.matched[1].name)
            // const default_date = this.$store.getters['config/getDefaultDate'](def);

            // this.$store.commit('recentLocation/setFromDate', default_date.from_date);
            // this.$store.commit('recentLocation/setToDate', default_date.to_date);
        
            // this.$store.commit('overall/setFromDate', default_date.from_date);
            // this.$store.commit('overall/setToDate', default_date.to_date);
        
            // this.$store.commit('locationLog/setFromDate', default_date.from_date);
            // this.$store.commit('locationLog/setToDate', default_date.to_date);
        
            // this.$store.commit('browsingLog/setFromDate', default_date.from_date);
            // this.$store.commit('browsingLog/setToDate', default_date.to_date);
        
            // this.$store.commit('aerialLog/setFromDate', default_date.from_date);
            // this.$store.commit('aerialLog/setToDate', default_date.to_date);
        
            // // this.$store.commit('deviceLog/setFromDate', default_date.from_date);
            // // this.$store.commit('deviceLog/setToDate', default_date.to_date);

            // this.$store.commit('deviceHistory/setFromDate', default_date.from_date);
            // this.$store.commit('deviceHistory/setToDate', default_date.to_date);
        
            // this.$store.commit('tetheredDevice/setFromDate', default_date.from_date);
            // this.$store.commit('tetheredDevice/setToDate', default_date.to_date);
        },
        clickPopupShow: function() {
            // console.log('heho')
        }
    },
    async mounted() {
        await this.setEmptyResultData();
        this.getData();
        if(this.route.matched[0].name === 'pageTargetDetail'){
            this.isTargetDetail = true
        } else {
            this.isTargetDetail = false
        }

        EventEmit.$on('targetOrMultitrackingActiveAgain', () => {
            this.getData();
        });
    },
    // created() {
    //     console.log('on created() ResultShell')
    // },
    // destroyed() {
    //     this.setDefaultDateRange();
    // },
    beforeRouteEnter(to, from, next) {
        next(vm => { 
            if ((to.matched[0] && to.matched[0].name === 'ResultShell' && from.matched[0] && from.matched[0].name !== 'ResultShell') || (to.matched[0] && to.matched[0].name  === 'ResultRealtimeShell' && from.matched[0] && from.matched[0].name !== 'ResultRealtimeShell') || (to.matched[0] && to.matched[0].name  === 'ResultHistoricalShell' && from.matched[0] && from.matched[0].name !== 'ResultHistoricalShell') || to.matched[0] && to.matched[0].name === 'pageTargetDetail' && from.matched[0] && from.matched[0].name !== 'pageTargetDetail') {
                vm.setDefaultDateRange();
            }
            next();
        });
    },
}
</script>

<style lang="sass">
    @import '@/assets/sass/components/_result.sass'
</style>
