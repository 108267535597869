import axios from "@/axios-main";
import axiosOrigin from "axios";
import { getField, updateField } from "vuex-map-fields";

let cancelList;
var cancelToken = axiosOrigin.CancelToken;

const dcd = {
  namespaced: true,
  state: {
    response_ultimo_api: {},
    type_selected: {},
    type_keyword: "",
    value_data: "",

    status_ultimo_api: {
      status: false,
      cancel: null,
      code: null,
    },

    response_ultimo_async_api: {},
    id: "",

    status_ultimo_async_api: {
      status: false,
      cancel: null,
      code: null,
    },

    is_loading_next: false,
  },
  mutations: {
    setResponseUltimoApi: (state, data) => {
      state.response_ultimo_api = data;
    },
    setSelectedType: (state, data) => {
      state.type_selected = data;
    },
    setTypeKeyword: (state, data) => {
      state.type_keyword = data;
    },
    setValueData: (state, data) => {
      state.value_data = data;
    },
    setStatusUltimoApi: (state, status) => {
      state.status_ultimo_api = status;
    },

    setResponseUltimoAsyncApi: (state, data) => {
      state.response_ultimo_async_api = data;
    },
    setID: (state, data) => {
      state.id = data;
    },
    setStatusUltimoAsyncApi: (state, status) => {
      state.status_ultimo_async_api = status;
    },

    updateField,
  },
  getters: {
    getField,
  },
  actions: {
    async getUltimoAPI(
      { state, commit, dispatch },
      // [limit = null, page = null, keyword = null, order_by = null, from_date = null, to_date = null, user_id = null]) {
      [feature = null, value = null]
    ) {
      if (
        state.status_ultimo_api.cancel !== null &&
        state.status_ultimo_api.status === "loading"
      )
        await state.status_ultimo_api.cancel("Operation canceled by the user.");
      EventEmit.$emit("showLoading", true);

      let params = new FormData();
      params.append("feature", feature);
      params.append("value", value);
      // params.append('order_by', order_by); // error if null (data empty)
      // params.append('from_date', from_date);
      // params.append('to_date', to_date);
      axios
        .post("v1/ultimo/fetch-feature", params, {
          cancelToken: new cancelToken(function executor(c) {
            cancelList = c;
            commit("setStatusUltimoApi", {
              status: "loading",
              cancel: c,
              code: null,
            });
          }),
        })
        .then((resp) => {
          // console.log(resp.data)
          let data = resp.data;
          let result = {
            status: true,
            cancel: null,
            code:
              resp.response && resp.response.status
                ? resp.response.status
                : null,
          };

          commit("setResponseUltimoApi", data);

          if (Object.keys(data).length === 0) result.status = "empty";

          commit("setStatusUltimoApi", result);
        })
        .catch((resp) => {
          // console.log(resp)
          let result = {
            status: "failed",
            cancel: null,
            code:
              resp.response && resp.response.status
                ? resp.response.status
                : null,
          };

          if (axiosOrigin.isCancel(resp)) {
            result.status = "loading";
            result.cancel = cancelList;
          }

          commit("setStatusUltimoApi", result);
          if (!axiosOrigin.isCancel(resp))
            dispatch(
              "errors/handleApiResponse",
              [
                result.code,
                resp.response &&
                resp.response.data &&
                resp.response.data.message
                  ? resp.response.data.message
                  : null,
              ],
              {
                root: true,
              }
            );
        })
        .finally(() => {
          EventEmit.$emit("showLoading", false);
        });
    },
    async getUltimoAsyncAPI(
      { state, commit, dispatch },
      // [limit = null, page = null, keyword = null, order_by = null, from_date = null, to_date = null, user_id = null]) {
      [id = null]
    ) {
      if (
        state.status_ultimo_async_api.cancel !== null &&
        state.status_ultimo_async_api.status === "loading"
      )
        await state.status_ultimo_async_api.cancel(
          "Operation canceled by the user."
        );
      EventEmit.$emit("showLoading", true);

      let params = new FormData();
      params.append("id", id);
      axios
        .post("v1/ultimo/fetch-async-data", params, {
          cancelToken: new cancelToken(function executor(c) {
            cancelList = c;
            commit("setStatusUltimoAsyncApi", {
              status: "loading",
              cancel: c,
              code: null,
            });
          }),
        })
        .then((resp) => {
          // console.log(resp.data)
          let data = resp.data;
          let result = {
            status: true,
            cancel: null,
            code:
              resp.response && resp.response.status
                ? resp.response.status
                : null,
          };

          commit("setResponseUltimoAsyncApi", data);

          if (Object.keys(data).length === 0) result.status = "empty";

          commit("setStatusUltimoAsyncApi", result);
        })
        .catch((resp) => {
          // console.log(resp)
          let result = {
            status: "failed",
            cancel: null,
            code:
              resp.response && resp.response.status
                ? resp.response.status
                : null,
          };

          if (axiosOrigin.isCancel(resp)) {
            result.status = "loading";
            result.cancel = cancelList;
          }

          commit("setStatusUltimoAsyncApi", result);
          if (!axiosOrigin.isCancel(resp))
            dispatch(
              "errors/handleApiResponse",
              [
                result.code,
                resp.response &&
                resp.response.data &&
                resp.response.data.message
                  ? resp.response.data.message
                  : null,
              ],
              {
                root: true,
              }
            );
        })
        .finally(() => {
          EventEmit.$emit("showLoading", false);
        });
    },

    async resetAll({ commit }) {
      commit("setResponseUltimoApi", {});
      commit("setSelectedType", {});
      commit("setTypeKeyword", "");
      commit("setValueData", "")
      commit("setStatusUltimoApi", {
        status: false,
        cancel: null,
        code: null,
      });

      commit("setResponseUltimoAsyncApi", {});
      commit("setID", "");
      commit("setStatusUltimoAsyncApi", {
        status: false,
        cancel: null,
        code: null,
      });
    }
  },
};

export default dcd;
