<template lang="pug">
.title-result.flex.items-center.justify-between
    h1.text-2xl.text-light {{ title }}
    .overlay-act(
        @click="showmenu()"
    )
        button.btn-icon-40
            iconSet(type="more")
</template>

<script>
import IconSet from '@/components/IconSet.vue'
export default {
    name: 'TitleReult',
    props: {
        title : String
    },
    components: {
        IconSet
    },
    methods: {
        showmenu() {
            EventEmit.$emit('triggerShowMenu')
        }
    },
}
</script>

<style lang="sass" scoped>
    .title-result
        @apply .relative .mb-4
        .overlay-act
            @apply .absolute .items-center .justify-end .w-full .h-full .inset-0 .z-10
            background-color: transparent
            display: none

    @media screen and (max-width: 480px)
        .title-result
            margin-bottom: 0px
            padding: 1rem
            border-bottom-width: 1px
            .overlay-act
                display: flex
                button
                    margin-right: 1rem
</style>