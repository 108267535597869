import { render, staticRenderFns } from "./LoadingAnimation.vue?vue&type=template&id=2c2a0108&lang=pug"
import script from "./LoadingAnimation.vue?vue&type=script&lang=js"
export * from "./LoadingAnimation.vue?vue&type=script&lang=js"
import style0 from "./LoadingAnimation.vue?vue&type=style&index=0&id=2c2a0108&prod&lang=sass"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports