import { env } from '@/config/env';

const features = env.VUE_APP_FEATURES ? env.VUE_APP_FEATURES.split(',') : [];
const featuresObject = env.VUE_APP_FEATURES_OBJECT ? 
    Object.fromEntries(env.VUE_APP_FEATURES_OBJECT.split(',').map(i => i.split(':')))
    : {};

export default features;

export function checkFeature(feature) {
    return features.includes(feature);
}

export function checkFeatureObject(feature) {
    let check = featuresObject[feature] || '1'
    return check == '1';
}
