<template lang="pug">
.card
    .card__header(v-if="!isWatchlistQuota")
        h2.text-xl Photos
    .card__body
        .flex.flex-wrap(
            class="lg:-mx-2"
            v-if="status_detail.status == 'loading'"
        )
            Loading(show="true")

        template(v-else)
            silent-box(v-if="detail.photos && detail.photos.length > 0 && gallery.length > 0" :gallery="gallery")

            NoDataAvailable(v-else)

</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Loading from '@/components/Loading.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';

export default {
    name: 'Photos',
    components: {
        Loading,
        NoDataAvailable,
    },
    data() {
        return {
            gallery: [],
        }
    },
    computed: {
        ...mapState('target', [
            'detail',
            'status_detail',
        ]),
        ...mapGetters({
            isWatchlistQuota: 'config/getIsWatchlistQuota',
        }),
    },
    methods: {
        setGallery() {
            let gallery = [];
            if (this.detail && this.detail.photos && this.detail.photos.length > 0) {
                for (var i = 0; i < this.detail.photos.length; i++) {
                    gallery.push({
                        src: this.detail.photos[i].file,
                        description: '',
                        alt: '',
                    });
                }
            }

            this.gallery = gallery;
        },
    },
    watch: {
        detail() {
            this.setGallery();
        }
    },
    mounted() {
        this.setGallery();
    }
}
</script>

<style lang="sass">
    #silentbox-gallery
        @apply .grid .grid-cols-4 .gap-8
        .silentbox-item
            aspect-ratio: 1/1
            overflow: hidden
            display: block
            img
                width: 100%
                height: 100%
                object-fit: cover
</style>