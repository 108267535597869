import { decrypt, encrypt } from '@/util/crypto';
import { env } from '@/config/env';

// const api = {
//   API_URL: decrypt(env.VUE_APP_API_URL, false, true),
//   APP_KEY: sessionStorage.getItem('x-app-key') || decrypt(env.VUE_APP_APP_KEY, false, true),
//   SECRET_KEY: sessionStorage.getItem('x-secret-key') || decrypt(env.VUE_APP_SECRET_KEY, false, true),
//   AUTH_HEADERS: {
// 	  'Content-Type': 'application/x-www-form-urlencoded',
// 	  'Accept': 'application/json',
// 	  'Authorization': 'Basic ' + btoa(decrypt(env.VUE_APP_SERVER_AUTH_USERNAME, false, true) +':'+ decrypt(env.VUE_APP_SERVER_AUTH_PASSWORD, false, true)),
// 	  'x-app-key': sessionStorage.getItem('x-app-key') || decrypt(env.VUE_APP_APP_KEY, false, true),
// 	  'x-secret-key': sessionStorage.getItem('x-secret-key') || decrypt(env.VUE_APP_SECRET_KEY, false, true),
// 	  'x-authorization': null,
// 	},
// };
const api = {
  API_URL: decrypt(env.VUE_APP_API_URL, false, true),
  APP_KEY: sessionStorage.getItem('x-app-key') || decrypt(env.VUE_APP_APP_KEY, false, true),
  SECRET_KEY: sessionStorage.getItem('x-secret-key') || decrypt(env.VUE_APP_SECRET_KEY, false, true),
  AUTH_HEADERS: {
	  'Content-Type': 'application/x-www-form-urlencoded',
	  'Accept': 'application/json',
	  'Authorization': 'Basic ' + btoa(decrypt(env.VUE_APP_SERVER_AUTH_USERNAME, false, true) +':'+ decrypt(env.VUE_APP_SERVER_AUTH_PASSWORD, false, true)),
	  'x-app-key': sessionStorage.getItem('x-app-key') || decrypt(env.VUE_APP_APP_KEY, false, true),
	  'x-secret-key': sessionStorage.getItem('x-secret-key') || decrypt(env.VUE_APP_SECRET_KEY, false, true),
	  'x-authorization': null,
	},
};

export default api;
