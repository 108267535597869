<template lang="pug">
.box
    .box__header.py-4
        h1.text-2xl.text-light Dashboard
    .box__body.p-4
        //- .grid.grid-cols-3.gap-6.mb-8(v-if="isRemainingQuota")
        .grid.grid-cols-3.gap-6.mb-8(v-if="showQuota")
            .cols-span-1
                .call-out.p-6.bg-green-200.text-green-800.rounded
                    strong.font-bold.text-xs DAILY MAXIMUM REQUEST
                    template(v-if="status_remaining_quota.status === 'loading'")
                        .pt-2
                            .loading-request
                    template(v-else)
                        h2.text-4xl {{ remaining_quota.total || remaining_quota.total == 0 ? remaining_quota.total : '-' }}

            .cols-span-1
                .call-out.p-6.bg-green-200.text-green-800.rounded
                    strong.font-bold.text-xs TODAY USAGE REQUEST
                    template(v-if="status_remaining_quota.status === 'loading'")
                        .pt-2
                            .loading-request
                    template(v-else)
                        h2.text-4xl {{ remaining_quota.today_usage || remaining_quota.today_usage == 0 ? remaining_quota.today_usage : '-' }}

            .cols-span-1
                .call-out.p-6.bg-green-200.text-green-800.rounded
                    strong.font-bold.text-xs REMAINING REQUEST
                    template(v-if="status_remaining_quota.status === 'loading'")
                        .pt-2
                            .loading-request
                    template(v-else)
                        h2.text-4xl {{ remaining_quota.remaining_usage || remaining_quota.remaining_usage == 0 ? remaining_quota.remaining_usage : '-' }}

        //- .grid.grid-cols-2.gap-6
            .card
                .card__header
                    h2.text-xl Latest Search
                .card__body
                    .boxed-lists.pt-4
                        //- .list-item(v-for="item in latestSearch" :key="item.id")
                            .flex.items-center.flex-grow.pr-8
                                router-link.link(:to="item.url") {{ item.number }}
                                p {{ item.type }}
                            span.text-xs {{ item.date }}

                        .flex.flex-wrap.items-center.justify-center(v-if="status_log_activity === 'loading'")
                            Spinner(show="true" size="48")

                        template(v-else-if="log_activity.data && log_activity.data.length > 0")
                            .list-item(v-for="(search,index) in log_activity.data" :key="index")
                                .flex.items-center.flex-grow.pr-8
                                    router-link.link(:to="'/result/'+ encrypt(search.key +'&&'+ search.type) +'/recent-location'") {{ search.key }}
                                    p.text-xs.uppercase {{ search.type }}
                                span.text-xs {{ datetimeFormat(search.created_at) }}

                        .text-center(v-else) No Data Available
            //- .card
                .card__header
                    h2.text-xl Latest Added as Target
                    .header-cta
                        .block
                            router-link.btn-default.btn-small(to="/target") View All Target
                .card__body
                    .boxed-lists.pt-4
                        //- .list-item(v-for="item in latestAdded" :key="item.id")
                            .flex.items-center.flex-grow.pr-8
                                router-link.link(:to="item.url") {{ item.number }}
                                p {{ item.type }}
                            span.text-xs {{ item.date }}

                        .flex.flex-wrap.items-center.justify-center(v-if="status_list_cache.status === 'loading'")
                            Spinner(show="true" size="48")

                        template(v-else-if="addedTarget.length > 0")
                            .list-item(v-for="(added,index) in addedTarget" :key="index")
                                .flex.items-center.flex-grow.pr-8
                                    router-link.link(:to="added.type === 'msisdn' ? '/target/'+ added.id +'/detail/'+ encrypt(added.value +'&&msisdn') +'/home' : '/target/'+ added.id +'/detail/home'") {{ added.value }}
                                    p.text-xs {{ added.target_name }}
                                span.text-xs {{ added.created_at }}

                        .text-center(v-else) No Data Available

        //- new view of dashboard
        .grid.grid-cols-4.gap-6
            .card.card--table.col-span-2
                .card__header.card--table__header
                    h2.text-xl Latest Search
                .card--table__body
                    .box-table.box-table--target
                            table.table
                                thead
                                    tr
                                        th(v-for="(th,index) in tableTheadTracking" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}
                                tbody
                                    tr(v-if="status_log_activity.status === 'loading'")
                                        td.text-center(:colspan="tableTheadTracking.length")
                                            .flex.flex-wrap.items-center.justify-center
                                                Spinner(show="true" size="48")
                                    template(v-else-if="log_activity.data && log_activity.data.length > 0")
                                        tr(v-for="(search,index) in log_activity.data" :key="index")
                                            td 
                                                .flex-grow.flex.items-center
                                                    .w-3.h-3.rounded-full.mr-3(:style="'background-color: '+ (search.target_label && search.target_label !== '-' ? search.target_label:'white') +';'")
                                                    span {{ search.target_name && search.target_name !== '-' ? search.target_name : 'No name' }}
                                                //- .flex-grow.flex.items-center
                                                    .w-3.h-3.rounded-full.mr-3(:style="'background-color: '+ ( search.label ? search.label:'white') +';'")
                                                    span {{ search.target && search.target.target_name? search.target.target_name : 'No name' }}
                                                //- .flex.items-center 
                                            td
                                                span.block {{ search.key }} 
                                            td
                                                .uppercase.label-dark {{ search.type }}
                                            td
                                                span.text-xs {{ datetimeFormat(search.created_at) }}
                                            //- td
                                                button(@click="processSearch('data','realtime')").btn-primary-o.btn-small.mr-4 Realtime
                                                button(@click="processSearch('data','historical')").btn-primary-o.btn-small Historical
                                    tr(v-else)
                                        td.text-center(:colspan="tableTheadTracking.length") No Data Available   
            .card.card--table.col-span-2
                .card__header.card--table__header
                    h2.text-xl Latest Added as Target
                .card--table__body
                    .box-table.box-table--target
                            table.table
                                thead
                                    tr
                                        th(v-for="(th,index) in tableTheadTarget" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}
                                tbody
                                    tr(v-if="status_list_cache.status === 'loading'")
                                        td.text-center(:colspan="tableTheadTarget.length")
                                            .flex.flex-wrap.items-center.justify-center
                                                Spinner(show="true" size="48")
                                    template(v-else-if="addedTarget.length > 0")
                                        tr(v-for="(added,index) in addedTarget" :key="index")
                                            td 
                                                .flex-grow.flex.items-center
                                                    .w-3.h-3.rounded-full.mr-3(:style="'background-color: '+ ( added.label ? added.label:'white') +';'")
                                                    span {{ added.target_name }}
                                                //- .flex.items-center 
                                                //- router-link.link(:to="added.type === 'msisdn' ? '/target/'+ added.id +'/detail/'+ encrypt(added.value +'&&msisdn') +'/home' : '/target/'+ added.id +'/detail/home'") {{ added.value }} 
                                                    //- .uppercase.label-dark {{ added.type }}
                                            td
                                                router-link.link(:to="added.type === 'msisdn' ? '/target/'+ added.id +'/detail/'+ encrypt(added.value +'&&msisdn') +'/home' : '/target/'+ added.id +'/detail/home'") {{ added.value }} 
                                            td
                                                .uppercase.label-dark {{ added.type }}
                                            td
                                                span.text-xs {{ added.created_at }}
                                    tr(v-else)
                                        td.text-center(:colspan="tableTheadTarget.length") No Data Available   
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import IconSet from '@/components/IconSet.vue';
import Spinner from '@/components/Spinner.vue';
import { encrypt } from '@/util/crypto';
import moment from 'moment';
import config from "@/config/api";

export default {
    name: 'DashboardPage',
    components: {
        IconSet,
        Spinner,
    },
    data() {
        return {
            addedTarget: [],
            tableTheadTracking: [
                {
                    name: 'Target Name',
                },{
                    name: 'Keyword',
                },{
                    name: 'Type',
                },{
                    name: 'Datetime',
                },
                // {
                //     name: 'Re-search Target by',
                //     action: true,
                // },
            ],
            tableTheadTarget: [
                {
                    name: 'Target Name',
                },{
                    name: 'Keyword',
                },{
                    name: 'Type',
                },{
                    name: 'Datetime',
                }
            ],
        }
    },
    computed: {
        ...mapState('auth', ['user']),
        ...mapState('dashboard', [
            'status_remaining_quota',
            'remaining_quota',
        ]),
        ...mapState('clients', [
            'status_log_activity',
            'log_activity',
        ]),
        ...mapState('target', [
            'status_list_cache',
            'list_cache',
        ]),
        ...mapGetters({
            isRemainingQuota: 'config/getIsRemainingQuota',
        }),
        route_name() {
            return this.$route.name;
        },
        showQuota(){
            return this.checkFeatureByAcl('quota_info', 'dashboard')
        },
    },
    watch: {
        route_name(newData) {
            if (newData === 'DashboardPage') {
                this.$store.commit('target/setStatusListCache', { cancel: null, status: false });
                this.getData();
            }
        },
        list_cache(newData) {
            if (Object.keys(newData).length > 0 && newData.data && newData.data.length > 0) {
                let target_list = [];

                for (var i = 0; i < newData.data.length; i++) {
                    for (var j = 0; j < newData.data[i].clues.length; j++) {

                        if (target_list.length >= 10)
                            break;

                        target_list.push({
                            id: newData.data[i].id,
                            target_name: newData.data[i].target_name,
                            label: newData.data[i].label,
                            type: newData.data[i].clues[j].name,
                            value: newData.data[i].clues[j].value,
                            created_at: newData.data[i].created_at,
                        });
                    }
                }

                this.addedTarget = target_list;
            }
        },
    },
    methods: {
        getData() {
            // for dispatch get data
            if (this.route_name === 'DashboardPage') {
                // if (this.isRemainingQuota)
                if (this.showQuota)
                    this.$store.dispatch('dashboard/getRemainingQuota');

                this.$store.dispatch('clients/getLogActivity', [10, 1]);
                this.$store.dispatch('target/getList', [10, 1, null, null, null, null, 'dashboard']);
            }
        },
        encrypt(string) {
            return encrypt(string);
        },
        datetimeFormat(datetime) {
            return datetime

            // console.log(moment(datetime, 'YYYY-MM-DD HH:mm:ss+0700').zone('+0700').format('DD MMM YYYY HH:mm:ss'));
            // return moment(datetime, 'YYYY-MM-DD HH:mm:ss+0700').format('DD MMM YYYY HH:mm:ssZ');

            // return moment(datetime, 'YYYY-MM-DD HH:mm:ss+0700').utcOffset('+0700').format('DD MMMM YYYY HH:mm:ss') + ' WIB';
            // return moment(datetime, 'YYYY-MM-DD HH:mm:ssZ').format('DD MMMM YYYY HH:mm:ss') + ' WIB';
        },
        async processSearch(data, tracking_type) {
            // let target_id = null
            // let key = null
            // let type = null
            // let route_name = '';
            
            // if (type === 'msisdn' || type === 'nik' || type === 'imsi' || type === 'imei-sv'){
            //     if (tracking_type == 'realtime') {
            //         route_name = 'ResultRealtimeRecentLocation';
            //     } else {
            //         route_name = 'ResultHistoricalOverall';
            //     }
            // }
            // if (route_name.length > 0) {
            //     this.$router.push({ name: route_name, params: { id: target_id, key: encrypt(key + '&&' + type) } });
            // }
        },
    },
    mounted: function() {
        // this.$store.commit('setLoadDataStatustargetList', { cancel: null, status: false });
        this.getData();
    },
}
</script>

<style lang="sass" scoped>
    .boxed-lists
        .list-item
            @apply .flex .items-center .justify-between .mb-4 .pb-4 .border-b
            .link
                min-width: 300px
            &:last-child
                border-bottom: 0px
                margin-bottom: 0px

    .loading-request
        width: 40px
        height: 40px
        border-radius: 9999px
        border-width: 3px
        border-style: solid
        border-color: $color-main-400 $color-main-700 $color-main-400 $color-main-400
        transform: rotate(0deg)
        animation: rotate 1s infinite

    @keyframes rotate
        from
            transform: rotate(0deg)
        to
            transform: rotate(360deg)

</style>