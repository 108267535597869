const max_check = 10
const default_colors = [ "#2caffe", "#544fc5", "#00e272", "#fe6a35", "#6b8abc", "#d568fb", "#2ee0ca", "#fa4b42", "#feb56a", "#91e8e1" ]

function getRandomColor() {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgb(${r}, ${g}, ${b})`;
}

function generateJustOneColor(colors){
    let hexColorRep = ""
    let loop_check = 0
    while (colors.includes(hexColorRep) && loop_check < max_check) {
        hexColorRep = getRandomColor()
        loop_check++
    }
    return hexColorRep
}

function generateColor(colors, i){
    if (i < default_colors.length)
        return default_colors[i]

    return generateJustOneColor(colors)
}

function generateColorList(list_date) {
    let colors = [""]
    let return_colors = {}
    for (let i = 0; i < list_date.length; i++) {
        let obj = list_date[i]
        let tmp_color = generateColor(colors, i)
        return_colors[obj.name] = tmp_color
        colors.push(tmp_color)
    }
    return return_colors
}

export default function addColorInSeries(bar_series, pie_series) {
    let color_list = {}
    if (bar_series.length >= pie_series.length)
        color_list = generateColorList(bar_series)
    else
        color_list = generateColorList(pie_series)

    pie_series = pie_series.map((obj) => {
        obj.color = color_list[obj.name]
        return obj
    })

    bar_series = bar_series.map((obj) => {
        obj.color = color_list[obj.name]
        return obj
    })

    return [bar_series, pie_series]
}