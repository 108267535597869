<template lang="pug">
.monitoring-log-chart
    .box__body
        .card.card--no-bg
            .card__header
                h2.text-xl Monitoring Log Chart
                .header-cta
                    .field(class="lg:mr-3 lg:mb-0 lg:w-64 mr-0 w-full mb-4")
                        .relative.field-style.w-full
                            date-range-picker(
                                v-if="dateRange.startDate && dateRange.endDate" 
                                ref="picker" 
                                v-model="dateRange" 
                                @update="pickerUpdate($event)" 
                                :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }" 
                                :date-format="dateFormat" 
                                opens="left"
                                :ranges="default_ranges"
                                autoApply=true
                            )
                                div(
                                    slot="input" 
                                    slot-scope="picker") 
                                        | {{ picker.startDate | date}} - {{ picker.endDate  | date}}

                            .field-icon
                                IconSet(type="calendar")
            .card__body
                .box-charts
                    Loading(v-if="status_log_activity_chart === 'loading'" show="true")
                    NoDataAvailable(v-else-if="filterChartNull")
                    .chart.p-4(v-show="status_log_activity_chart !== 'loading' && filterChartNull == false")
                        highcharts(:options="chartOptions")

</template>

<script>
import moment from 'moment';
import IconSet from '@/components/IconSet.vue';
import DateRangePicker from 'vue2-daterange-picker';
import {Chart} from 'highcharts-vue'
import { mapState } from 'vuex';
import Loading from '@/components/Loading.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';

export default {
    name: 'pageMonitoringLogChart',
    components: {
        IconSet,
        DateRangePicker,
        highcharts: Chart, 
        Loading,
        NoDataAvailable,
    },
    data() {
        return {
            showFilter: false,
            from_date: '',
            to_date: '',
            dateRange: {
                startDate: '',
                endDate: '',
            },
            filterChartNull: true,
            default_ranges: {
                'Today': [moment().toDate(), moment().toDate()],
                'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                'Last 7 days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                'Last week': [moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(), moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate()],
            },
            chartOptions: {
                chart: {
                    type: 'column',
                },
                title: {
                    text: null
                },
                xAxis: {
                    categories: [],
                    title: {
                        text: null
                    }
                },
                yAxis: {
                    title: {
                        text: null
                    },
                },
                series: [],
                legend:{ enabled:false },
            },
        }
    },
    computed: {
        ...mapState('users', [
            'status_log_activity_chart',
            'log_activity_chart',
            'status_list',
            'list',
        ]),
        route() {
            return this.$route;
        },
        route_name() {
            return this.$route.name;
        },
    },
    watch: {
        route_name(newData, oldData) {
            if (newData === 'pageTrackingHistory') {
                this.getData();
            }

            if (newData === 'pageTrackingHistory') {
                if (oldData !== 'pageTrackingHistory') {
                    this.items_per_page = 10;
                    this.page = 0;
                    this.keyword = '';
                    this.$store.commit('trackingHistory/setList', {});
                }

                this.getData();
            }
        },
        total_data() {
            // this.pagination();
        },
        items_per_page() {
            this.getData();
            // this.pagination();
        },
        page() {
            this.getData();
        },
        keyword() {},
        log_activity_chart(newData) {
            if (newData.categories && newData.categories .length>0) {                
                this.chartOptions.xAxis.categories = newData.categories
                this.chartOptions.series = newData.series
                this.filterChartNull=false
            } else {
                // this.chartOptions.xAxis.categories = []
                // this.chartOptions.series = []
                this.filterChartNull=true
            }
        },
    },
    methods: {
        async getData() {
            // for dispatch get data
            await this.$store.dispatch('users/getLogActivityChart', [this.from_date, this.to_date]);
            if (this.log_activity_chart.categories && this.log_activity_chart.categories .length>0) {                
                this.chartOptions.xAxis.categories = this.log_activity_chart.categories
                this.chartOptions.series = this.log_activity_chart.series
                this.filterChartNull=false
            } else {
                // this.chartOptions.xAxis.categories = []
                // this.chartOptions.series = []
                this.filterChartNull=true
            }
        },
        sizeChange() {
            this.page = 0;
        },
        pagination() {
            // update fontawesome to iconfont
            var paginationButtonPreview = document.getElementsByClassName("fa-angle-left");

            if (paginationButtonPreview[0]) {
                var classListPrev = paginationButtonPreview[0].classList;

                if (Object.values(classListPrev).filter(key => ['iconfont'].includes(key)).length === 0) {
                    paginationButtonPreview[0].classList.add('iconfont');
                    paginationButtonPreview[0].classList.add('iconleft');
                    paginationButtonPreview[0].style.fontSize = '12px';
                }
            }

            var paginationButtonNext = document.getElementsByClassName("fa-angle-right");

            if (paginationButtonNext[0]) {
                var classListNext = paginationButtonNext[0].classList;

                if (Object.values(classListNext).filter(key => ['iconfont'].includes(key)).length === 0) {
                    paginationButtonNext[0].classList.add('iconfont');
                    paginationButtonNext[0].classList.add('iconright');
                    paginationButtonNext[0].style.fontSize = '12px';
                }
            }
        },
        datetimeFormat(datetime) {
            return moment(datetime, 'YYYY-MM-DDTHH:mm:ssZ').format('DD MMM YYYY HH:mm:ssZ');
        },
        /* eslint-disable no-unused-vars */
        dateFormat (classes, date) {
            if (!classes.disabled) {
                classes.disabled = moment(date.getTime()).subtract({ hours: 12})._d > new Date();
            }
            return classes;
        },
        
        filterList() {
            this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');
            
            this.getData();
        },
        defaultFilter(){
            const default_date = this.$store.getters['config/getDefaultDate']();

            this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
            this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();

            this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');
        },
        async pickerUpdate(e, type) {
            // console.log('========= DateRangePicker on update')
            // console.log(e)

            let ranges = moment(e.endDate.getTime()).diff(moment(e.startDate.getTime()), 'days') + 1;

            if (ranges > this.$store.getters['config/getValidateRange']) {
                const default_date = this.$store.getters['config/getDefaultDate']();
                this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
                this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();

                this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');    
            

                this.$swal.fire({
                    icon: 'error',
                    title: 'Date range is not more than ' + this.$store.getters['config/getValidateRange'] + ' days!',
                    text: 'Your date range is ' + ranges + ' days.',
                })

                // return;
            } else {
                this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');

                this.getData();
            }
        },
        searchUser() {
            // clear timeout variable
            clearTimeout(this.timeout);

            var self = this;
            this.timeout = setTimeout(function () {
                if (self.user_keyword.length > 2) {
                    self.$store.commit('users/setList', {});
                    self.$store.dispatch('users/getList', [30, 1, self.user_keyword]);
                }
            }, 650);
        },
        selectUser(user){
            this.user_keyword = user.name
            this.user_id = user.id
            this.$store.commit('users/setList', {});

        },   
    },
    async created() {
        // this.$store.commit('users/target/setShowNavbar', true);
        // this.getData();
        await this.defaultFilter()
        await this.getData()
    },
    mounted() {
    //     if (this.route_name === 'pageMonitoringLog') {
    //         // reset date filter
    //         this.defaultFilter()
    //         this.getData()
    //     }
    },
    filters: {
        date: function (date) {
            return moment(date).format('YYYY-MM-DD');
        },
    },
}
</script>

<style lang="sass" scoped>
    .box-table--monitoring
        tr
            th, td
                @apply .whitespace-no-wrap

    .box-find
        width: 100%
        max-width: 400px
        background-color: $color-secondary-500
        border-radius: 4px
        position: relative

    .container-box-users
        top: 100%
        z-index: 100
        background-color: $color-secondary-700
        width: 100%

    .box-users
        .item-user
            @apply .flex .items-center .p-2 .border-b .cursor-pointer
            &:hover 
                background-color: $color-secondary-600
            &:last-child
                border-bottom: 0px
    .box-find-selected
        background-color: $color-secondary-600
        padding-top: 2px
        @apply .absolute .inset-0 .rounded .px-2 .border
        border-color: #2d3061

</style>