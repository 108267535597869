<template lang="pug">
.target-index
    .box__body
        .card.card--table.card--no-bg
            .card__header.card--table__header
                h2.text-xl Table of Target
                .header-cta
                    .flex.items-center
                        .block(v-if="isWatchlistQuota && checkFeatureByAcl('single_target', 'watchlist_quota')")
                            button.btn-default(@click="watchlistModal(true)" ) Watchlist: {{ status_watchlist_quota.status === 'loading' ? 'loading...' : (watchlist_quota.usage || watchlist_quota.usage == 0 ? numberWithDot(watchlist_quota.usage) : '-') +'/'+ (watchlist_quota.quota_watchlist || watchlist_quota.quota_watchlist == 0 ? numberWithDot(watchlist_quota.quota_watchlist) : '-') }}
                                IconSet(type="chevron-down")
                        //- .block(v-if="isRemainingQuota")
                        .block(v-if="canMonitoring && checkFeatureByAcl('single_target', 'daily_quota')")
                            span.btn-default Daily quota used: {{ status_remaining_quota_target.status === 'loading' ? 'loading...' : (remaining_quota_target.today_usage || remaining_quota_target.today_usage == 0 ? remaining_quota_target.today_usage : '-') +'/'+ (remaining_quota_target.total || remaining_quota_target.total == 0 ? remaining_quota_target.total : '-') }}
                        .block(v-if="checkFeatureByAcl('single_target', 'export')")
                            .mx-4.relative.box-more
                                button.btn-primary.m-btn-icon
                                    template(v-if="is_exporting")
                                        Spinner(show="true" size="24")
                                        span.m-hide.ml-3 Exporting ...
                                    template(v-else)
                                        IconSet(type="download")
                                        span.m-hide.ml-3 Export
                                .box-popup-more
                                    ul
                                        li
                                            .btn-primary-o.btn-small(
                                                :disabled="{'disabled':is_exporting}"
                                                :class="{'btn-disabled':is_exporting}"
                                                @click="!is_exporting?getDataExport('dlCsv'):null"
                                            )
                                                | Download CSV
                                            JsonCSV.btn-primary-o.btn-small(
                                                :data="export_data"
                                                :name="csv_name"
                                                ref="dlCsv"
                                                style="display:none"
                                            )
                                                | Download CSV
                                        li
                                            .btn-primary-o.btn-small(
                                                :disabled="{'disabled':is_exporting}"
                                                :class="{'btn-disabled':is_exporting}"
                                                @click="!is_exporting?getDataExport('dlXls'):null"
                                            )
                                                | Download XLS
                                            JsonCSV.btn-primary-o.btn-small(
                                                :data="export_data"
                                                :name="xls_name"
                                                delimiter=";"
                                                ref="dlXls"
                                                style="display:none"
                                            )
                                                | Download XLS
                        .block(v-if="checkFeatureByAcl('single_target', 'create')")
                            router-link.btn-primary.m-btn-icon(
                                :to="{ name: 'pageTargetCreate', params: {} }"
                            )
                                IconSet(type="add")
                                span.m-hide.ml-3 Add Target
            .card--table__body
                .box-filter-table.p-4.pt-0
                    .field(class="lg:mr-3 lg:mb-0 lg:w-64 mr-0 w-full mb-4")
                        input.field-style(
                            v-model="keyword"
                            type="text"
                            placeholder="Search here..."
                            @keyup="searchKeyword"
                        )
                        .field-icon
                            IconSet(
                                type="search"
                            )
                    .field(class="lg:mr-3 lg:mb-0 lg:w-32 mr-0 w-full mb-4")
                        select.field-style(@change="sizeChange($event)" v-model="items_per_page")
                            option(v-for="(s,index) in size" :key="index" :value="s") {{ s }}
                        .field-icon
                            IconSet(
                                type="chevron-down"
                            )

                    //- filter
                    .field(
                        v-if="canMonitoring"
                    )
                        .ui-checkbox
                            input(type="checkbox" @change="monitoringActiveChange($event)" v-model="monitoring_active" value="true")
                            .style-checkbox
                                .checkbox
                                    IconSet(type="check")
                                span(v-if="status_list.status === 'loading'") Loading...
                                template(v-else)
                                    span(v-if="list.total_monitoring_active && list.total_monitoring_active != 0") Show <strong class="text-red-500"> {{ list.total_monitoring_active }} </strong> Ongoing Monitoring Target
                                    span(v-else) No active monitoring

                .box-table.box-table--target
                    table.table
                        thead.m-hide
                            tr
                                th(v-for="(th,index) in tableThead" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}
                        tbody
                            tr(v-if="status_list.status === 'loading'")
                                td.text-center(:colspan="tableThead.length")
                                    .flex.flex-wrap.items-center.justify-center
                                        Spinner(show="true" size="48")
                            template(v-else-if="list && list.data && list.data.length > 0")
                                tr(v-for="(data,index) in list.data" :key="data.id")
                                    td
                                        button.link.flex.items-center.text-sm.text-left(@click="detailTarget(data)")
                                            .mr-2
                                                figure.w-8.h-8.rounded-full.overflow-hidden
                                                    img.w-full.h-full(:src="data.photos && data.photos[0] ? data.photos[0].file : getDefaultImage" @error="getDefaultImage")
                                            span {{ data.target_name }}
                                    td.col-target-label
                                        .target-label(:style="'background-color: '+ ( data.label ? data.label:'white') +';'")
                                    td.m-hide
                                        .pb-2(v-for="clue in countClues(data.clues)")
                                            .label-dark(:class="data.clues.length > 1 ? 'mr-2' : ''") {{ clue.count }} {{ clue.name }}
                                            //- .label-dark {{ clue.count }} {{ clue.name }}
                                    td 
                                        span.text-xs {{ toString(data.cases) }}
                                    td 
                                        .w-64
                                            template(v-if="canTakeNotes")
                                                button.link.text-sm(@click="getNotes(true, data.id, data.target_name)") {{data.notes?data.notes.notes:'Add a note'}}
                                            template(v-else)
                                                .text-sm.note {{data.notes?data.notes.notes:'No notes'}}
                                    td.m-hide
                                        span.text-xs {{ data.created_by && data.created_by.name ? data.created_by.name : '-' }}
                                    td.m-hide
                                        span.text-xs {{ data.updated_at?data.updated_at:'-' }}
                                    td.m-action-target
                                        .flex.items-center.justify-end
                                            button.btn-icon-40(
                                                v-if="canTakeNotes"
                                                @click="getNotes(true, data.id, data.target_name)"
                                            )
                                                IconSet(type="add note")
                                            template(v-if="data.status.toLowerCase()==='stop'")
                                                button.btn-icon-40(
                                                    v-if="canMonitoring"
                                                    @click="monitoringModal(true, data)" 
                                                    v-tooltip="'Start Monitoring'"
                                                )
                                                    IconSet(type="play")
                                                router-link.btn-icon-40.m-hide(
                                                    v-if="checkFeatureByAcl('single_target', 'edit')"
                                                    :to="'/target/'+data.id+'/edit'"
                                                    v-tooltip="'Edit'"
                                                )
                                                    IconSet(type="edit")
                                                button.btn-icon-40.m-hide(
                                                    v-if="checkFeatureByAcl('single_target', 'delete')"
                                                    @click="requestDelete(data.id, data.target_name)"
                                                    v-tooltip="'Delete'"
                                                )
                                                    IconSet(type="delete")
                                            template(v-else)
                                                button.btn-icon-40.text-red-500(
                                                    v-if="canMonitoring"
                                                    @click="monitoringAction(0,data)"
                                                    v-tooltip="'Stop Monitoring'"
                                                )
                                                    IconSet(type="stop")
                                                button.btn-icon-40.btn-disabled.m-hide(
                                                    v-if="checkFeatureByAcl('single_target', 'edit')"
                                                )
                                                    IconSet(type="edit")
                                                button.btn-icon-40.btn-disabled.m-hide(
                                                    v-if="checkFeatureByAcl('single_target', 'delete')"
                                                )
                                                    IconSet(type="delete")

                            tr(v-else)
                                td.text-center(:colspan="tableThead.length") No Data Available                    

                .box-table-footer(v-if="status_list.status !== 'loading'")
                    .pagination
                        vue-ads-pagination(
                            :total-items="total_data"
                            :items-per-page="items_per_page"
                            :page="page"
                            :max-visible-pages="5"
                        )
                            template(slot="buttons" slot-scope="props")
                                vue-ads-page-button(
                                    v-for="(button, key) in props.buttons"
                                    :key="key"
                                    v-bind="button"
                                    :class="{'bg-yellow-dark': button.active}"
                                    @page-change="page = button.page"
                                    @range-change="start = button.start; end = button.end" 
                                )

    .popup-layer(
        v-bind:class="{'is-show' : openNote}"
    )
        .popup-container
            .popup-container__header
                h2.text-xl {{tmpTargetName}}'s Notes
                button.btn-icon-40(
                    @click="getNotes(false)"
                )
                    IconSet( type="close")

            .popup-container__body
                .mb-4
                    .field.w-full.mb-2
                        textarea.field-style(
                            v-model="tmpNote"
                            placeholder="Write new note here..."
                            style="min-height:60px"
                        )
                    .flex.justify-end
                        template(v-if="tmpNote && tmpNote.length>0")
                            button.btn-primary.btn.small(@click="addNote") Add note
                        template(v-else)
                            button.btn-primary.btn.small.btn-disabled(disabled) Add note
                .mb-4
                    .small-heading History Notes
                    template(v-if="status_notes_list.status == 'loading'")
                        .flex.flex-wrap.items-center.justify-center
                            Spinner(show="true" size="48")

                    .mb-4.pb-4.border-b(v-else-if="notes_list && notes_list.length > 0" v-for="note in notes_list")
                        p.mb-1 {{note.notes}}
                        small.text-xs.opacity-75 {{note.created_at?datetimeFormat(note.created_at,'DD MMM YYYY HH:mm:ss [WIB]'):'-'}} • added by {{note.created_by?note.created_by:'-'}}

                    .flex.flex-wrap.items-center.justify-center(v-else)
                        strong.block.text-xs.uppercase.text-light.mb-4 No History Available
                    .block                            
                        template(v-if="status_notes_list.status != 'loading' && (notes_list && notes_list.length > 0)")
                            small.text-xs.opacity-75(v-if="!is_loading_next") {{notes_list.length}} of {{data_pagination.total_data}} items
                            .flex.flex-wrap.items-center.justify-center
                                template(v-if="is_loading_next")
                                    Spinner(show="true" size="48")
                                template(v-else)
                                    template(v-if="data_pagination.current_page<data_pagination.total_page")
                                        button.btn-small.btn-primary(@click="handleNextNotes") Load More
    
    .popup-layer.is-show(
        v-if="monitoringData"
    )
        .popup-container
            .popup-container__header
                .flex.items-center
                    figure.mr-4
                        img.w-8.h-8.rounded-full.object-fit(:src="monitoringData.photo ? monitoringData.photo : getDefaultImage" @error="getDefaultImage")
                    h2.text-xl {{ monitoringData.target_name }}

                button.btn-icon-40(
                    @click="monitoringModal(false)"
                )
                    IconSet( type="close")

            .popup-container__body
                .mb-6.pb-6.border-b
                    .mb-4
                        strong.small-heading Select MSISDN
                    .field.mr-8(v-for="data in monitoringData.clues" v-if="data.name === 'msisdn'")
                        .ui-checkbox
                            input(type="checkbox" v-model="monitoringMsisdn" :value="data.value")
                            .style-checkbox
                                .checkbox
                                    IconSet(type="check")
                                span {{data.value}}

                .pb-6(
                    v-if="isMonitoringPeriod"
                    :class="monitoringTime != '' ? 'mb-6 border-b' : ''"
                )
                    .mb-4
                        strong.small-heading MONITORING TIME
                    .grid.grid-cols-3.gap-6
                        .field(v-for="data, key in monitoringPeriod")
                            .ui-radio
                                input(type="radio" name="period_time" v-model="monitoringTime" :value="key")
                                .style-radio
                                    .radio
                                    span {{data.text}}
                .pb-6(v-if="monitoringTime != ''")
                    .mb-4
                        strong.small-heading FREQUENCY MONITORING
                    .grid.grid-cols-3.gap-6
                        .field(v-for="data in freqOpt")
                            .ui-radio
                                input(type="radio" name="frequency" v-model="monitoringFreq" :value="data.value")
                                .style-radio
                                    .radio
                                    span {{data.name}}
                .pb-6(v-if="!isMonitoringPeriod")
                    .mb-4
                        strong.small-heading FREQUENCY MONITORING
                    .grid.grid-cols-3.gap-6
                        .field(v-for="data in freqOpt")
                            .ui-radio
                                input(type="radio" name="frequency" v-model="monitoringFreq" :value="data.value")
                                .style-radio
                                    .radio
                                    span {{data.name}}

            .popup-container__footer
                .flex.justify-end.w-full
                    //- button.btn-primary(type='submit') Start Monitoring
                    //- msisdn sudah dipilih
                    template(v-if="monitoringMsisdn.length>0 && monitoringFreq!=''")
                        button.btn-primary(type='submit' @click="monitoringAction(1)") Start Monitoring
                    //- msisdn belum dipilih
                    template(v-else)
                        button.btn-primary.btn-disabled(type='submit' disabled) Start Monitoring
                    //- proses loading 
                    //- button.btn-primary.btn-disabled(type='submit' disabled)  Starting Monitoring...

    .popup-layer.is-show(
        v-if="popupShowWatchlist"
    )
        .popup-container
            .popup-container__header
                .flex.items-center
                    h2.text-xl Watchlist Detail

                button.btn-icon-40(
                    @click="watchlistModal(false)"
                )
                    IconSet( type="close")

            .popup-container__body
                .mb-2.pb-2
                    table.table
                        tbody
                            tr(v-if="status_watchlist_quota.status === 'loading'")
                                td.text-center(colspan=3)
                                    .flex.flex-wrap.items-center.justify-center
                                        Spinner(show="true" size="48")
                            template(v-else)
                                tr
                                    td Watchlist usage
                                    td.px-4 :
                                    td {{ watchlist_quota.usage || watchlist_quota.usage == 0 ? numberWithDot(watchlist_quota.usage) : '-'  }}
                                tr
                                    td Remaining watchlist
                                    td.px-4 :
                                    td {{ watchlist_quota.remaining || watchlist_quota.remaining == 0 ? numberWithDot(watchlist_quota.remaining) : '-'  }}
                                tr
                                    td Quota watchlist
                                    td.px-4 :
                                    td  {{ watchlist_quota.quota_watchlist || watchlist_quota.quota_watchlist == 0 ? numberWithDot(watchlist_quota.quota_watchlist) : '-'  }}

</template>

<script>
import { mapState, mapGetters } from 'vuex';
import IconSet from '@/components/IconSet.vue';
import Spinner from '@/components/Spinner.vue';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
import { encrypt } from '@/util/crypto';
import moment from 'moment';
import JsonCSV from 'vue-json-csv'

export default {
    name: 'pageTargetIndex',
    components: {
        IconSet,
        Spinner,
        VueAdsPagination,
        VueAdsPageButton,
        JsonCSV,
    },
    data() {
        return {
            TargetMonitoring: false,
            monitoringData: null,
            monitoringMsisdn: [],
            monitoringFreq: 'every30minutes',
            freqOpt: this.$store.getters['config/getFreqMonitoring'],

            monitoringOnly: false,
            popupShowMonitoring: false,
            popupShowWatchlist: false,

            monitoringTime: '',
            
            // tableThead: [
            //     {
            //         name: 'Target Name',
            //     },{
            //         name: '',
            //     },{
            //         name: 'Keyword',
            //     },{
            //         name: 'Case Name',
            //     },{
            //         name: 'Latest Note',
            //     },{
            //         name: 'Created By',
            //     },{
            //         name: 'Last Updated',
            //     },{
            //         name: 'Action',
            //         action: true,
            //     },
            // ],
            total_data: 0,
            items_per_page: 10,

            size: ['All',10, 25, 50, 100],

            page: 0,
            start: 0,
            end: 0,

            keyword: '',
            timeout: null,
            monitoring_active: '',

            defaultImage: this.$store.getters['config/getDefaultImageProfile'],

            openNote: false,

            tmpTargetId: null,
            tmpTargetName: null,
            tmpNote: null,

            xls_name: 'list_target_'+moment().format('YYYY-MM-DD_HH:mm:ss')+'.xls',
            csv_name: 'list_target_'+moment().format('YYYY-MM-DD_HH:mm:ss')+'.csv',
            export_data: [],
            export_data_raw: [],
            is_exporting: false,
            limit_export: 1000000,
            btn_ref: null,

            // dataNotes: [],
            // pageNotes: 0,
            // totalPageNotes: 1,
            // limitNotes: 10,
            // totalNotes: 0,
            
            // isLoadingNext: false,
        }
    },
    computed: {
        ...mapState('auth', ['user']),
        ...mapState('dashboard', [
            'status_remaining_quota_target',
            'remaining_quota_target',
        ]),
        ...mapState('watchlist', [
            'status_watchlist_quota',
            'watchlist_quota',
        ]),
        ...mapState('target', [
            'status_list',
            'list',
            'status_notes_list',
            'notes_list',
            'is_loading_next',
            'data_pagination',
        ]),
        ...mapState('auth', [
            'user',
        ]),        
        ...mapGetters({
            isRemainingQuota: 'config/getIsRemainingQuota',
            isWatchlistQuota: 'config/getIsWatchlistQuota',
            isMonitoringPeriod: 'config/getIsMonitoringPeriod',
            monitoringPeriod: 'config/getMonitoringPeriod',
        }),
        route_name() {
            return this.$route.name;
        },
        tableThead() {
            let tableThead = [
                {
                    name: 'Target Name',
                },{
                    name: '',
                },{
                    name: 'Keyword',
                },{
                    name: 'Case Name',
                },{
                    name: 'Latest Note',
                },{
                    name: 'Created By',
                },{
                    name: 'Last Updated',
                },
            ];
            if (this.canMonitoring || this.checkFeatureByAcl('single_target', 'note-target-create') || this.checkFeatureByAcl('notes_single_target', 'post') || this.checkFeatureByAcl('single_target', 'edit') || this.checkFeatureByAcl('single_target', 'delete') ) {
                tableThead.push({
                    name: 'Action',
                    action: true,
                });
            }

            return tableThead;
        },
        canMonitoring() {
            return this.checkFeatureByAcl('single_target', 'monitoring') || this.checkFeatureByAcl('monitoring', 'post')
        },
        canTakeNotes() {
            return this.checkFeatureByAcl('single_target', 'note-target-create') || this.checkFeatureByAcl('notes_single_target', 'post')
        }
    },
    watch: {
        route_name(newData, oldData) {
            if (newData === 'pageTargetIndex') {
                if (oldData !== 'pageTargetIndex') {
                    this.items_per_page = 10;
                    this.page = 0;
                    this.keyword = '';
                    this.$store.commit('target/setList', {});
                }

                this.getData();
            }
        },
        tableThead() {},
        list(newData) {
            if (Object.keys(newData).length > 0) {
                const tmp_total_data = parseInt(newData.total_data);
                this.total_data = tmp_total_data;
                if (this.keyword == '') {
                    this.limit_export = tmp_total_data;
                    if (this.btn_ref != null) {
                        this.getDataExport(this.btn_ref)
                    } 
                }                
                // this.page = (parseInt(newData.current_page) - 1);
            } else {
                this.total_data = 0;
            }  
        },
        items_per_page() {
            this.getData();
        },
        page() {
            this.getData();
        },
        monitoring_active() {
            this.getData();
        },
        // notes_list(newData){
        //     if (Object.keys(newData).length > 0) {
        //         this.dataNotes = this.dataNotes.concat(newData.data);
        //         this.totalNotes = parseInt(newData.total_data);
        //         this.limitNotes = parseInt(newData.per_page);
        //         this.pageNotes = parseInt(newData.current_page);
        //         this.totalPageNotes = parseInt(newData.total_page);
        //     } else {
        //         this.setDefaulDataNotes()
        //     }
        //     this.isLoadingNext=false
        // },
        monitoringTime(newData) {
            this.monitoringFreq = ''
            this.freqOpt = this.monitoringPeriod[newData].options
        },
        // monitoringFreq(newData, oldData){
        //     console.log('newData')
        //     console.log(newData)
        //     console.log('oldData')
        //     console.log(oldData)
        // }

    },
    methods: {
        async getDataExport(btn_ref=null) {
            this.btn_ref = btn_ref
            this.is_exporting = true
            if (![0,1000000].includes(this.limit_export)) {
                let data_export = this.export_data_raw
                if (data_export.length == 0) {
                    data_export = await this.$store.dispatch('target/getExport', [this.limit_export]);
                    this.export_data_raw = data_export 
                }
                this.export_data = await data_export.map((item) => {
                    const keyword = item.clues.map(clue => {
                        let detailInfo = clue.detail.filter(detail => detail.value).map(detail => `${detail.name}: ${detail.value}`).join(', ');
                        if (detailInfo) {
                            return `${clue.name}: ${clue.value}, ${detailInfo}`;
                        } else {
                            return `${clue.name}: ${clue.value}`;
                        }
                    }).join(', ');                  
                    // const keyword = item.clues.map(clue => `${clue.name}: ${clue.value}`).join(', ');
                    const cases = item.cases.map(caseItem => `${caseItem.operation_name}`).join(', ');
                    return {
                        target_name: (item?.target_name || '-'),
                        label: (item?.label || '-'),
                        keyword,
                        cases,
                        notes: (item?.notes?.notes || '-'),
                        created_by: (item?.created_by.name || '-'),
                        last_updated: (item?.updated_at || '-'),
                    };
                }) 
                this.is_exporting = false
                this.btn_ref = null
                setTimeout(() => this.$refs[btn_ref].$el.click(), 1000);
            }  
        },      
        checkActionButton() {
            return (Object.keys(this.user).length > 0 && this.user.role && this.user.role.label != 'analyst')
        },
        getData() {
            // for dispatch get data
            if (this.route_name === 'pageTargetIndex') {
                this.$store.commit('target/setRedirect', 'index');
                let limit = this.items_per_page
                if (this.items_per_page == 'All') {
                    limit = this.list.total_data
                }
                this.$store.dispatch('target/getList', [limit, (this.page + 1), this.keyword, null, null, null, null, null, this.monitoring_active]);
                
                // if (this.isRemainingQuota) {
                if (this.canMonitoring) {
                    this.$store.dispatch('dashboard/getRemainingQuota', 'target');
                }
                if (this.isWatchlistQuota) {
                    this.$store.dispatch('watchlist/getQuota');
                }

                // if (this.list.total_page && this.list.total_page > 1)
                    // this.pagination();
            }
        },
        requestDelete(id, name) {
            // response have conclusion
            EventEmit.$emit('showLoading', true);
            
            this.$store.dispatch('target/delete', [id, true])
            .then((resp) => {
                if (resp === 'success') {
                    this.page = 0;

                    this.getData();

                    this.$swal.fire({
                        icon: 'success',
                        title: 'Data successfully deleted!',
                        timer: 3000,
                    });
                } else {
                    if (resp.data && resp.data.message && resp.data.message === 'next-delete') {
                        this.deleteData(resp.data.data.id, resp.data.data.target_name)

                    } else if (resp.data.conclusion) {
                        if (typeof resp.data.force != 'undefined' && resp.data.force === true) {
                            // console.log('force = true')
                            let errors = '';
                            if (resp.data.messages && resp.data.messages.length > 0) {
                                errors = errors + '<section class="container-alert"><div class="container-alert__list">' + 'Target : ' + name + '</div>';

                                Object.keys(resp.data.messages).forEach(function (item) {
                                    // errors += resp.data.messages[item] + '<br><br>';
                                    // errors += '<div class="swal2-html-container">' + resp.data.messages[item] + '<br><div>';
                                    errors = errors + '<div class="container-alert__list">' + resp.data.messages[item] + '</div>';
                                });
                                errors = errors + '</section>';
                            }

                            this.$swal.fire({
                                icon: 'warning',
                                title: resp.data.conclusion,
                                text: 'Target : ' + name,
                                html: errors,
                                confirmButtonText: 'Yes!',
                                confirmButtonColor: '#DD6B55',
                                showCancelButton: true,
                                allowOutsideClick: false,
                            })
                            .then((result2) => {
                                // console.log('on result2');
                                if (result2.isConfirmed) {
                                    EventEmit.$emit('showLoading', true);

                                    this.$store.dispatch('target/delete', [id, false, true])
                                    .then((resp2) => {
                                        if (resp2 === 'success') {
                                            this.page = 0;

                                            this.getData();

                                            this.$swal.fire({
                                                icon: 'success',
                                                title: 'Data successfully deleted!',
                                                timer: 3000,
                                            });
                                        } else {
                                            console.log(resp2)
                                            // error(message=null, data=null) {

                                            if (resp2.data.message) {
                                                // this.error(resp2.data.message);
                                                EventEmit.$emit('error', resp2.data.message);
                                            } else {
                                                // this.error();
                                                EventEmit.$emit('error');
                                            }
                                        }
                                    });
                                }
                            });

                        } else {
                            // this.error(null, resp.data.conclusion, resp.data.messages);
                            // resp.data.messages.unshift('resp.data.conclusion')

                            resp.data.messages.unshift('Target : ' + name);
                            EventEmit.$emit('error', null, resp.data.messages, resp.data.conclusion);
                        }

                    } else {
                        if (resp.data && resp.data.message) {
                            EventEmit.$emit('error', resp.data.message);
                        } else {
                            EventEmit.$emit('error');
                        }
                    }
                }

            });

        },
        deleteData(id, name) {
            this.$swal.fire({
                icon: 'warning',
                title: 'Are you sure you want to delete?',
                text: 'Target : ' + name,
                confirmButtonText: 'Yes!',
                confirmButtonColor: '#DD6B55',
                showCancelButton: true,
                allowOutsideClick: false,
            })
            .then((result) => {

                if (result.isConfirmed) {
                    EventEmit.$emit('showLoading', true);

                    this.$store.dispatch('target/delete', [id])
                    .then((resp) => {
                        if (resp === 'success') {
                            this.page = 0;

                            this.getData();

                            this.$swal.fire({
                                icon: 'success',
                                title: 'Data successfully deleted!',
                                timer: 3000,
                            });
                        } else {
                            if (resp.data && resp.data.message) {
                                EventEmit.$emit('error', resp.data.message);
                            } else {
                                EventEmit.$emit('error');
                            }
                        }
                    });
                }
            });
        },
        pageChange(page) {
            this.page = page;
        },
        rangeChange(start, end) {
            this.start = start;
            this.end = end;
        },
        sizeChange() {
            this.page = 0;
        },
        monitoringActiveChange() {
            this.page = 0;
        },
        searchKeyword() {
            clearTimeout(this.timeout);            
            var self = this;
            this.timeout = setTimeout(function () {
                self.page = 0;
                self.$store.commit('target/setList', {});
                self.getData();
            }, 650);
        },
        getNotes(open=true, target_id=null,target_name=null) {
            this.$store.commit('target/setNotesList', {});
            if(open){
                this.tmpTargetId = target_id
                this.tmpTargetName = target_name
                this.$store.dispatch('target/getNotesList', [this.tmpTargetId]);
                this.openNote = true
                EventEmit.$emit('overflowHidden', true);
            } else {
                this.setEmptyNotes();
                this.setDefaulDataNotes();
                EventEmit.$emit('overflowHidden', false);
            }
        },
        handleNextNotes() {
            // console.log(target_id)
            this.$store.commit('target/setIsLoadingNext', true);        
            this.$store.dispatch('target/getNotesList', [this.tmpTargetId, true]);
        },
        addNote(){
            EventEmit.$emit('showLoading', true);
            this.$store.dispatch('target/createNotes', [this.tmpTargetId, this.tmpNote])
                .then((resp) => {
                    if (resp.result === 'success') {
                        // this.getNotes(false)
                        this.tmpNote = null
                        this.setDefaulDataNotes()

                        this.getNotes(true, this.tmpTargetId, this.tmpTargetName);
                        this.getData();

                        this.$swal.fire({
                            icon: 'success',
                            title: 'Note successfully added!',
                            timer: 3000,
                        });
                    } else {
                        if (resp.data.message) {
                            EventEmit.$emit('error', resp.data.message);
                        } else {
                            EventEmit.$emit('error');
                        }
                    }
                });
        },
        setEmptyNotes(){
            this.tmpTargetId = null
            this.tmpTargetName = null
            this.tmpNote = null
            this.openNote = false
        },
        setDefaulDataNotes(){
            // console.log('else')
            // this.dataNotes = [];
            // this.totalNotes = 0;
            // this.limitNotes = 10;
            // this.pageNotes=0;
            // this.totalPageNotes=1;
            const dataPagination = { 
                current_page: 1,
                has_next: false,
                has_previous: false,
                next_page_number: 1,
                per_page: 10,
                previous_page_number: null,
                total_data: 0,
                total_page: 1,
            }
            this.$store.commit('target/setDataPagination', dataPagination);
        },
        getDefaultImage(e) {
            e.target.src = this.defaultImage;
        },
        checkMsisdn(data) {
            var msisdn = data.clues.filter(function (clue) {
                return clue.name === 'msisdn'
            })
            return msisdn
        },
        detailTarget(data) {
            // console.log('click')
            var msisdn = this.checkMsisdn(data)
            if (msisdn.length > 0) {
                this.$router.push({ name: 'pageTargetDetailHome', params: { id: data.id ,key: encrypt(msisdn[0].value + '&&msisdn') }  })
            } else {
                let clues = data.clues
                this.$router.push({ name: 'pageTargetDetailHome', params: { id: data.id ,key: encrypt(clues[0].value + '&&'+clues[0].name) }  })
                // EventEmit.$emit('error', 'Target does not have a MSISDN clue')
            }
        },
        monitoringAction(status=1,data=null) {
            if(data==null){
                data=this.monitoringData
            }
            var title = 'Are you sure you want to start monitoring ?'
            if (status === 0) {
                title = 'Are you sure you want to stop monitoring?'
            }
            this.$swal.fire({
                icon: 'warning',
                title: title,
                text: 'Target : ' + data.target_name,
                confirmButtonText: 'Yes!',
                confirmButtonColor: '#DD6B55',
                showCancelButton: true,
                allowOutsideClick: false,
            })
            .then((result) => {
                // console.log('on result');

                if (result.isConfirmed) {
                    // console.log(result.value);

                    EventEmit.$emit('showLoading', true);
                    if(status === 1) {
                        EventEmit.$emit('overflowHidden', false);
                    }

                    this.$store.dispatch('target/postMonitoring', [data.id, status, [JSON.stringify(this.monitoringMsisdn)], this.monitoringFreq, this.monitoringTime])
                    .then((resp) => {
                        // console.log('postMonitoring');
                        // console.log(resp);
                        if (resp.result === 'success') {
                            this.page = 0;
                            this.monitoringModal(false);

                            this.getData();

                            this.$swal.fire({
                                icon: 'success',
                                title: resp.data.message,
                                timer: 3000,
                            });
                        } else {
                            if (resp.data.message) {
                                EventEmit.$emit('error', resp.data.message);
                            } else {
                                EventEmit.$emit('error');
                            }
                        }
                    });
                }
            });
        },
        monitoringModal(open = true, item=null) {
            // hide scroll bar if true
            if(open) {
                EventEmit.$emit('overflowHidden', true);
            } else {
                EventEmit.$emit('overflowHidden', false);
            }
            this.monitoringMsisdn= []
            if (this.isMonitoringPeriod) {
                this.monitoringFreq= ''
            } else {
                this.monitoringFreq= 'every30minutes'
            }
            if (item) {            
                var msisdn = this.checkMsisdn(item)
                if (msisdn.length > 0) {
                    this.monitoringData = item
                    this.popupShowMonitoring = !this.popupShowMonitoring
                } else {
                    EventEmit.$emit('error', 'Target does not have a MSISDN clue')
                    EventEmit.$emit('overflowHidden', false);
                }
            } else {            
                this.monitoringData = item
                this.popupShowMonitoring = !this.popupShowMonitoring
            }
        },
        watchlistModal(open = true) {
            // hide scroll bar if true
            if(open) {
                EventEmit.$emit('overflowHidden', true);
            } else {
                EventEmit.$emit('overflowHidden', false);
            }
            this.popupShowWatchlist = !this.popupShowWatchlist
        },

        countClues(array) {
            var ret = {}
            if (!(this.list.data && this.list.data.length > 0))
                return Object.values(ret)

            // for (let i in array) {
            for (const [i, value] of Object.entries(array)) {
                let detail = value.detail || {}
                if (value.value){
                    let key = value.name
                    
                    ret[key] = {
                        name: key,
                        count: ret[key]?.count ? ret[key].count + 1 : 1
                    }
                }

                for (const [j, value_detail] of Object.entries(detail)) {
                    if (!value_detail.value)
                        continue

                    let key_detail = value_detail.name
                
                    ret[key_detail] = {
                        name: key_detail,
                        count: ret[key_detail]?.count ? ret[key_detail].count + 1 : 1
                    }

                }
            }

            return Object.values(ret)
        },
        datetimeFormat(datetime, format="DD MMM YYYY HH:mm:ss") {
            return moment(datetime, 'YYYY-MM-DDTHH:mm:ssZ').format(format);
        },
    },
    created() {
        this.$store.commit('target/setShowNavbar', true);
        this.getData();
    },
    mounted() {
        if (this.isMonitoringPeriod) {
            this.monitoringFreq=''
        }
    },
}
</script>

<style lang="sass">
    // .container-alert
    //     border: 1px solid #a0aec0
    //     border-radius: 4px
    //     background-color: #f7fafc
    //     &__list
    //         border-bottom: 1px solid #a0aec0
    //         border-radius: 4px
    //         text-align: left
    //         font-size: 16px
    //         line-height: 24px
    //         padding: 1rem
    //         &:last-child
    //             margin-bottom: 0px
    //             border-bottom: 0px
    .box-more
        &:hover
            .box-popup-more
                display: block


    .box-popup-more
        @apply .absolute
        top: 100%
        right: 0px
        z-index: 999
        font-size: 14px
        width: 180px
        display: none
        // transform: translateY(16px)
        padding-top: 16px
        ul
            background-color: $color-secondary-700
            @apply .grid .p-6 .gap-5
            grid-template-rows: repeat(2, minmax(0, 1fr))
            grid-auto-flow: column
            li
                cursor: pointer
                a
                    &:hover
                        color: $color-main-500

    .target-label
        @apply .w-3 .h-3 .rounded-full

    @media screen and (max-width: 480px)
        .box-table--target
            table.table
                tbody
                    tr
                        display: block
                        border-width: 1px
                        border-style: solid
                        position: relative
                        padding: 1rem
                        &:hover
                            td
                                background-color: transparent
                        td
                            display: block
                            width: 100%
                            border-bottom: 0!important
                            padding: 0.5rem 0rem
                            &.m-hide
                                display: none
                            &.col-target-label
                                position: absolute
                                left: 36px
                                top: 36px
                        .m-action-target
                            position: absolute
                            right: 1rem
                            top: 0.5rem
                            .btn-icon-40
                                width: 48px
                                height: 48px
                                svg,
                                i.icon
                                    width: 24px
                                    height: 24px
                            .m-hide
                                display: none
.note
    &:hover
        text-decoration: underline
        cursor: pointer

</style>