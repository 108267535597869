import axios from "@/axios-main";
import axiosOrigin from "axios";
import { decryptAPICP } from '@/util/crypto';
import { env } from '@/config/env';

let cancelRecentLocation;
var cancelToken = axiosOrigin.CancelToken;

const recentLocation = {
  namespaced: true,
  state: {
    module:'Recent Location',

    record_size: null,
    from_date: null,
    to_date: null,

    status: false,
    status_recent_location: {
      status: false,
      cancel: null,
      code: null
    },

    recent_location: {},
  },
  mutations: {
    setRecordSize: (state, data) => {
      state.record_size = data;
    },
    setFromDate: (state, data) => {
      state.from_date = data;
    },
    setToDate: (state, data) => {
      state.to_date = data;
    },

    setStatus: (state, status) => {
      state.status = status;
    },
    setStatusRecentLocation: (state, status) => {
      state.status_recent_location = status;
    },

    setRecentLocation: (state, data) => {
      state.recent_location = data;
    },
  },
  getters: {},
  actions: {
    async getResultData({ state, rootGetters, commit, dispatch }, [key, type, service_source=null]) {

      // const default_date = rootGetters['config/getDefaultDate']();

      // let record_size = state.record_size!==null ? state.record_size : rootGetters['config/getDefaultRecordSize'];
      // let from_date = state.from_date!==null ? state.from_date : default_date.from_date;
      // let to_date = state.to_date!==null ? state.to_date : default_date.to_date;
      let from_date = state.from_date;
      let to_date = state.to_date;

      await dispatch('setLoading');

      await dispatch('getRecentLocation', [key, type, service_source, from_date, to_date]);

      commit('setStatus', true);
    },

    async setLoading({ commit, state }) {
      await commit('setStatus', 'loading');

      await commit('setStatusRecentLocation', {
        status: 'loading',
        cancel: state.status_recent_location.cancel,
        code: null
      });
    },

    async setEmpty({ state, commit }) {
      commit('setStatus', false);

      if (state.status_recent_location.cancel !== null && state.status_recent_location.status === 'loading')
        await state.status_recent_location.cancel('Operation canceled by the user.');
      commit('setStatusRecentLocation', {
        status: false,
        cancel: null,
        code: null
      });

      commit('setRecentLocation', {});
    },

    async getRecentLocation({ commit, dispatch, state, rootGetters }, [key, type, service_source, from_date, to_date]) {
      
      if (state.status_recent_location.cancel !== null && state.status_recent_location.status === 'loading')
        await state.status_recent_location.cancel('Operation canceled by the user.');

      let service = 'service';
      let cache = false;

      var pathUrlEnc = 'api/v1/service/overall/recent-location/hybrid/encrypt'
      
      var isEncryptCP = env.VUE_APP_ENCRYPT_RECENT_LOCATION || false
      
      if (service_source === 'internal') {
        pathUrlEnc = 'api/v1/service/overall/recent-location/internal/encrypt'
        service = 'service-int'
      } else if (service_source === 'external') {
        pathUrlEnc = 'api/v1/service/overall/recent-location/external/encrypt'
        service = 'service-ext'
        cache = true
      }
      var pathUrl = 'api/v1/'+service+'/overall/recent-location'
      if (isEncryptCP)
        pathUrl = pathUrlEnc
      var lsKey = key + '_' + service_source + '_recent-location';
      try {
        let resp = rootGetters['config/getApiResp'](lsKey)
        if (resp) {
          dispatch('setAllData', JSON.parse(resp));
        } else {
          throw "Empty"        
        }
      } catch (error) {
        let params = new FormData();
        params.append('key', key);
        params.append('type', type);
        // params.append('size', record_size);
        if (service_source === 'internal' && from_date && to_date) {
          params.append('from_date', from_date);
          params.append('to_date', to_date);
        }

        // await axios.post('api/v1/'+service+'/overall/recent-location', params, {
        await axios.post(pathUrl, params, {
          cancelToken: new cancelToken(function executor(c) {
            cancelRecentLocation = c;
            commit('setStatusRecentLocation', {
              status: 'loading',
              cancel: c,
              code: null,
            });
          }),
        })
        .then((resp) => {

          if (isEncryptCP && resp.data.data)
            resp.data = JSON.parse(decryptAPICP(resp.data.data))
          // console.log(resp.data)
          dispatch('setAllData', resp);
          if (cache) {
            let dt = {
              resp: resp, 
              lsKey: lsKey
            }
            dispatch('config/addApiResp', dt, {root: true});
          }
        })
        .catch((resp) => {
          let result = {
            status: 'failed',
            cancel: null,
            code: resp.response && resp.response.status ? resp.response.status : null,
          };

          if (axiosOrigin.isCancel(resp)) {
            result.status = 'loading';
            result.cancel = cancelRecentLocation;
          }

          commit('setStatusRecentLocation', result);
          if (!axiosOrigin.isCancel(resp)){
            // dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null),state.module], { root: true });
            let respMsg = resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null
            if(respMsg!=null && result.code != 500) {
              dispatch('errors/handleApiResponse', [result.code, respMsg, state.module], { root: true });
            }
          } 
        });
      }
    },
    async setAllData({ commit }, resp) {
      let data = resp.data;
      let result = {
        status: true,
        cancel: null,
        code: resp.response && resp.response.status ? resp.response.status : null,
      };

      commit('setRecentLocation', data);

      try {
        if (data.features[0].geometry.coordinates && (data.features[0].geometry.coordinates[0]==="" || data.features[0].geometry.coordinates[1]==="")) {
          result.status = 'empty';
        } else if (data.features[0].geometry.coordinates && (data.features[0].geometry.coordinates[0]==="" || data.features[0].geometry.coordinates[1]==="")) {
          result.status = 'partially-empty';
        }
      } catch (error) {
        result.status = 'empty';        
      }
      
      commit('setStatusRecentLocation', result);
    },  
  },
};

export default recentLocation;
