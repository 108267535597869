<template lang="pug">
.global-header
    router-link(to="/").global-header__logo
        img(:src="logo", alt="Logo Helix KJ" :style="[logo == '/images/demosystem.svg'? {'height':'24px'}:{}]")
    .global-header__menu
        HeaderMenu

    //- .global-header__search(v-if="checkFeature('search_target')")
    .global-header__search
        //- HeaderSearch(v-if="user && user.role && (user.role.label != 'admin' && user.role.label != 'auditor')")
        //- HeaderSearch(v-if="user && user.role && (!excludeRoleSearch.includes(user.role.label))")
        HeaderSearch(v-if="checkFeatureByAcl('search_target', 'show')")

    .global-header__account
        .flex.items-center
            .mr-2(v-if="checkFeature('face_recognition') && checkFeatureByAcl('face_recognition', null)")
                router-link.btn-primary-o.btn-small(to="/face-recognition") Face Recognition

            .mx-2.relative.box-more(v-if="burgerMenus.length > 0")
                button.w-6.h-6.flex.items-center.justify-center: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                .box-popup-more
                    ul(:style="{ 'grid-template-rows': 'repeat('+ (burgerMenus.length/2).toFixed() +', minmax(0, 1fr))' }")
                        li(v-for="menu in burgerMenus" :key="menu.name")
                            router-link(:to='menu.path') {{ menu.name }}                        

            .mr-2(v-if="is_use_notification.is_active === true && show_notification === true")
                router-link(to="/notifications").btn-icon-40.relative.menu-notification
                    .bubble-notif.rounded.absolute.bg-red-600.text-white(v-if="notification_count_all > 0")
                        | {{ notification_count_all }}
                    iconSet(type="notification")

            .box-account
                .box-account__avatar
                    router-link(to="/profile").w-8.h-8.block
                        img.w-full.h-full.rounded-full(:src="imgAvatar", alt="")
                    iconSet(type="chevron-down")
                .box-account__info
                    .info-profile.border-b.p-6
                        router-link(to="/profile").block.link-text {{ user && user.name ? user.name : '-' }}
                        small.opacity-75 {{ user && user.role && user.role.name ? user.role.name : '-' }}
                    .info-menu(v-if="accountMenus.length > 0")
                        ul
                            li(v-for="menu in accountMenus" :key="menu.name")
                                router-link.opacity-75(:to='menu.path')
                                    | {{ menu.name }}
                            //- li(
                            //-     v-if="user && user.role && (user.role.label == 'superadmin' || user.role.label == 'admin' || user.role.label == 'auditor')"
                            //- )
                                router-link.opacity-75(to="/license") License

                    .info-footer.px-6.py-3.border-t
                        button.opacity-75(@click="logout()") Logout

</template>

<script>
import { mapState } from 'vuex';
// import HeaderSearch from './header/Search.vue'
import HeaderSearch from './header/SearchAdvance.vue'
import HeaderMenu from './header/Menu.vue'
import IconSet from '@/components/IconSet.vue'
import { checkFeature } from '@/config/features';

export default {
    name: 'GlobalHeaderSearch',
    components: {
        HeaderSearch,
        HeaderMenu,
        IconSet
    },
    data: function () {
        return {
            // toggleActive: false,
            // moreIsActive: false,
            // moreChildActive: false,
            // accountChildActive: false,
            // // defaultImage: this.$store.getters.getDefaultImage,
            // defaultImage: require('../../../assets/images/default-avatar.png'),
            logo: this.$store.getters['config/getLogoGlobalHeader'],
            operatorHideUsers: this.$store.getters['config/getOperatorHideUsers'],
            
            is_use_notification: this.$store.getters['config/getIsUseNotification'],
            notification_count_all: 0,
            show_notification: false,

            // routePath: null,

            // show_upload_media: false,
            imgAvatar: '/images/default-avatar.png',
        };
    },
    computed: {
        ...mapState('auth', ['user']),
        ...mapState('notification', ['count']),
        // excludeRoleSearch(){
        //     let roles = ['auditor', 'admin', 'analyst'];
        //     if (checkFeature('search_target|admin')){
        //         // roles.push("admin");
        //         roles = roles.filter(function(e) { return e !== 'admin' })

        //     }
        //     return roles;
        // },
        route_name(){
            return this.$route.name;
        },
        burgerMenus() {
            let burgerMenus = [];
                // imei_sv_to_imsi
            
            let isSuper = this.user && this.user.role ? this.user.role.label === 'superadmin' : false
            let isAnalyst = this.user && this.user.role ? this.user.role.label === 'analyst' && this.$store.getters['config/getHamburgerAnalystHardcode'] : false
            if ((checkFeature('imei_sv_to_imsi') && this.checkFeatureByAcl('imei_sv', null)) || isSuper) {
                burgerMenus.push({
                    name: 'Device Track',
                    path: '/device-track',
                });
            }
            if ((checkFeature('me_id_track') && this.checkFeatureByAcl('me_id_track', null) && !isAnalyst) || isSuper) {
                burgerMenus.push({
                    name: 'ME-ID Track',
                    path: '/me-id-track',
                });
            }
            if ((checkFeature('territory_virtual') && this.checkFeatureByAcl('territory_virtual', null)) || isSuper) {
                burgerMenus.push({
                    name: 'Territory Virtual',
                    path: '/global-territory-virtual',
                });
            }
            if ((checkFeature('compare_target_loc') && this.checkFeatureByAcl('compare_target', null) && !isAnalyst) || isSuper) {
                burgerMenus.push({
                    name: 'Compare Target Loc',
                    path: '/compare-target-loc',
                });
            }
            if ((checkFeature('query_by_nik') && this.checkFeatureByAcl('query_by_nik', null) && !isAnalyst) || isSuper) {
                burgerMenus.push({
                    name: 'Query by NIK ',
                    path: '/query-by-nik',
                });
            }
            if ((checkFeature('target_vehicle') && this.checkFeatureByAcl('vehicle', null) && !isAnalyst) || isSuper) {
                burgerMenus.push({
                    name: 'Target Vehicle',
                    path: '/vehicle',
                });
            }
            if ((checkFeature('deep_void') && (this.checkFeatureByAcl('deep_void', null) || this.checkFeatureByAcl('dcd', null)) && !isAnalyst) || isSuper) {
                burgerMenus.push({
                    name: 'Digital Cyber Data',
                    path: '/digital-cyber',
                });
            }
            if ((checkFeature('profiling_target')  && this.checkFeatureByAcl('profiling_target', null) && !isAnalyst) || isSuper) {
                burgerMenus.push({
                    name: 'Profiling Target',
                    path: '/profiling',
                });
            }
            if ((checkFeature('prediction_msisdn') && this.checkFeatureByAcl('prediction_msisdn', null) && !isAnalyst) || isSuper) {
                burgerMenus.push({
                    name: 'Prediction MSISDN',
                    path: '/prediction-msisdn',
                });
            }
            // if ((checkFeature('search_by_bpjs') && this.checkFeatureByAcl('imei_sv', null)) || isSuper) {
            //     burgerMenus.push({
            //         name: 'Search By BPJS',
            //         path: '/bpjs',
            //     });
            // }
            // if ((checkFeature('search_by_npwp') && this.checkFeatureByAcl('imei_sv', null)) || isSuper) {
            //     burgerMenus.push({
            //         name: 'Search By NPWP',
            //         path: '/npwp',
            //     });
            // }
            if ((checkFeature('social_media_target') && this.checkFeatureByAcl('target_tags', null)) || isSuper) {
                burgerMenus.push({
                    name: 'Digital Trace',
                    path: '/target-tags',
                });
            }
            if ((checkFeature('ultimo') && this.checkFeatureByAcl('ultimo', null)) || isSuper) {
                burgerMenus.push({
                    name: 'Ultimo',
                    path: '/ultimo',
                });
            }

            // console.log({isSuper, isAnalyst, burgerMenus})
        
            return burgerMenus
        },
        accountMenus() {

            let accountMenus = [];

            let userRole = this.user && this.user.role ? this.user.role : null
            let roleLabel = userRole && userRole.label ? userRole.label : ''
            let roleModule = userRole && userRole.module && userRole.module.length > 0 ? userRole.module : []
            let isSuper = roleLabel ? roleLabel === 'superadmin' : false

            if (roleLabel && roleLabel === 'analyst')
                return accountMenus;

            // if (this.user && this.user.role && (this.user.role.label === 'superadmin' || (this.user.role.module && this.user.role.module.length > 0))) {
            if (roleModule || isSuper) {

                // clients
                // if (checkFeature('clients')) {
                    // let check_clients = roleModule.filter(function(item) {
                    //     return item.label === 'client';
                    // });
                    // if (check_clients.length > 0 || isSuper)
                    //     accountMenus.push({
                    //         name: 'Clients',
                    //         path: '/clients',
                    //     });
                // }

                // modules
                if (checkFeature('modules')) {
                    let check_modules = roleModule.filter(function(item) {
                        return item.label === 'module';
                    });
                    if (check_modules.length > 0 || isSuper)
                        accountMenus.push({
                            name: 'Modules',
                            path: '/modules',
                        });
                }

                // functions
                if (checkFeature('functions')) {
                    let check_functions = roleModule.filter(function(item) {
                        return item.label === 'function';
                    });
                    if (check_functions.length > 0 || isSuper)
                        accountMenus.push({
                            name: 'Functions',
                            path: '/functions',
                        });
                }

                // acl
                if (checkFeature('acl')) {
                    let check_acl = roleModule.filter(function(item) {
                        return item.label === 'acl';
                    });
                    if (check_acl.length > 0 || isSuper)
                        accountMenus.push({
                            name: 'ACL',
                            path: '/acl',
                        });
                }

                // roles
                if (checkFeature('roles')) {
                    let check_roles = roleModule.filter(function(item) {
                        return item.label === 'role';
                    });
                    if (check_roles.length > 0 || isSuper)
                        accountMenus.push({
                            name: 'Roles',
                            path: '/roles',
                        });
                }

                // users
                if (checkFeature('users')) {
                    const roles = roleLabel
                    let check_users = roleModule.filter(function(item) {
                        return item.label === 'user';
                    });
                    if ((check_users.length > 0 && (roles!='operator' || (roles=='operator' && !this.operatorHideUsers))) || roles === 'superadmin')
                        accountMenus.push({
                            name: 'Users',
                            path: '/users',
                        });
                }

                // license
                if (checkFeature('license')) {
                    // let check_license = roleModule.filter(function(item) {
                    //     return item.label === 'check_licens';
                    // });
                    // if (check_license.length > 0 || isSuper)
                    if (roleLabel == 'superadmin' || roleLabel == 'admin')
                        accountMenus.push({
                            name: 'License',
                            path: '/license',
                        });
                }

                // versions
                if (checkFeature('versions')) {
                    let check_versions = roleModule.filter(function(item) {
                        return item.label === 'version';
                    });
                    if (check_versions.length > 0 || isSuper)
                        accountMenus.push({
                            name: 'Versions',
                            path: '/versions',
                        });
                }
            }

            return accountMenus;
        },
    },
    watch: {
        count(newData) {
            this.setNotificationCount(newData);
        },
        route_name() {},
    },
    methods: {
        // checkRole() {
        //     let roles = this.user && this.user.role && this.user.role.label ? this.user.role.label : ''
        //     return (roles == 'superadmin' || roles == 'analyst')
        // },
        checkFeature(feature) {
            // return features.includes(feature);
            return checkFeature(feature);
        },
        checkCredential() {
            let userRole = this.user && this.user.role ? this.user.role : null
            let roleLabel = userRole && userRole.label ? userRole.label : ''
            let roleModule = userRole && userRole.module && userRole.module.length > 0 ? userRole.module : []
            let isSuper = roleLabel ? roleLabel === 'superadmin' : false
            
            if (roleModule || isSuper) {
            // if (Object.keys(this.user).length > 0) {

                // notification
                if (this.is_use_notification.is_active === true) {
                // if (checkFeature('notification')) {
                    let check_notification = roleModule.filter(function(item) {
                        return item.label === 'notification';
                    });
                    if (check_notification.length > 0 || isSuper)
                        this.show_notification = true; // eslint-disable-line
                }

            }
        },
        setNotificationCount(data=[]) {
            if (data.length === 0)
                data = this.count;

            if (data.length > 0) {
                data = data.filter(function(item) {
                    return item.type === 'all';
                });

                // console.log(data[0].count);
                if (data.length > 0) {
                    this.notification_count_all = data[0].count;
                } else {
                    this.notification_count_all = 0;
                }
            } else {
                this.notification_count_all = 0;
            }
        },
        logout() {
            EventEmit.$emit('showLoading');

            if (this.user !== null) {
                this.$store.dispatch('auth/logout').then((resp) => {

                    if (resp.result && resp.result === 'success') {

                        // this.$router.push({ name: 'pageLogin' });
                        this.$router.push('/login');

                        EventEmit.$emit('showLoading', false);
                        // this.$swal.close();
                    } else {
                        this.$swal.fire({
                            title: 'Failed logout!',
                            icon: 'error',
                            text: (resp.data && resp.data.message ? resp.data.message : 'Something wrong.'),
                            // timer: 3000,
                        });
                    }
                });
            
            } else {
                this.$router.push('/login');
                EventEmit.$emit('showLoading', false);
            }
        },
    },
    async mounted() {
        // console.log(this.auth)
        // console.log(this.user)
        this.checkCredential();
        // temporary_comment
        let isAnalyst = this.user ? this.user.role.label === 'analyst' : false
        if (this.is_use_notification.is_active === true && this.route_name !== 'pageNotifications' && this.checkFeatureByAcl('imei_sv', null)) {
        // if (checkFeature('notification') === true && this.route_name !== 'pageNotifications') {
            await this.$store.dispatch('notification/getCount');
            this.setNotificationCount();
        }

        EventEmit.$on('logout', () => {
            this.logout();
        });
    },
}
</script>

<style lang="sass">
    .global-header
        @apply .flex .items-center .justify-between .px-4
        &__logo
            img
                height: 32px
        &__search
            @apply .flex-grow .flex .justify-center
            // padding-right: 272px
        &__account
            .box-account
                position: relative
                z-index: 4
                &__avatar
                    @apply .flex .items-center .justify-center .py-6 .px-3
                    i.icon
                        @apply .ml-2
                        transition: all 0.3s ease-in-out
                        transform: rotate(0deg)
                        svg
                            width: 16px
                            height: 16px

                &__info
                    font-size: 13px
                    position: absolute
                    width: 200px
                    top: 100%
                    right: 0px
                    background-color: $color-secondary-700
                    display: none
                    z-index: 9
                    .info-menu
                        li
                            a
                                @apply .block .py-4 .px-6
                                &:hover
                                    background-color: $color-main-500
                                    color: $color-secondary-600

                &:hover
                    background-color: $color-secondary-700
                    .box-account__avatar
                        i
                            transform: rotate(180deg)
                    .box-account__info
                        display: block
                        animation: FadeIn 1s ease

        .bubble-notif
            font-size: 9px
            font-weight: 900
            padding: 2px 4px
            top: 4px
            right: -4px
            z-index: 5

        .box-more
            &:hover
                .box-popup-more
                    display: block


        .box-popup-more
            @apply .absolute
            top: 100%
            right: 0px
            z-index: 999
            font-size: 14px
            width: 360px
            display: none
            // transform: translateY(16px)
            padding-top: 16px
            ul
                background-color: $color-secondary-700
                @apply .grid .p-6 .gap-5
                // grid-template-rows: repeat(5, minmax(0, 1fr))
                grid-auto-flow: column
                li
                    a
                        &:hover
                            color: $color-main-500

    @media screen and (max-width: 480px )
        .global-header
            padding: 0.5rem
            &__logo
                flex-grow: 1
            &__search
                flex-grow: 0
            &__account
                .account
                    &__info
                        display: none

</style>