<template lang="pug">
.target-shell
    .box
        .box__header
            TitlePage(
                title="Multi Tracking"
                :breadcrumb="breadcrumb"
                )
        .box__body
            router-view

</template>

<script>
import { mapState } from 'vuex';
import TitlePage from '@/components/shell/TitlePage.vue'
export default {
    name: 'multiTrackingShell',
    components: {
        TitlePage,
    },
    data() {
        return {
        }
    },
    computed: {
        ...mapState('multiTracking',[
            'meeting_point_detail_from_notification',
        ]),
        route() {
            return this.$route;
        },
        id() {
            return this.$route.params.id;
        },
        breadcrumb() {
            let breadcrumb = [
                {
                    name: 'Multi Tracking',
                    url: '/multi-tracking'
                }
            ];

            if (this.meeting_point_detail_from_notification === true) {
                breadcrumb = [
                    {
                        name: 'Notifications',
                        url: '/notifications',
                    },
                    {
                        name: 'Notification Meeting Point',
                        url: '/multi-tracking/'+this.id+'/detail/meeting-point',
                    }
                ];
            } else {

                if (this.route.matched[0] && this.route.matched[0].components.default.name === 'multiTrackingShell' && this.route.matched[1]) {
                    switch(this.route.matched[1].name) {
                        case 'pageMultiTrackingCreate':
                            breadcrumb.push({
                                name: 'Add Multi Tracking',
                                url: '/multi-tracking/create',
                            });
                            break;

                        case 'pageMultiTrackingEdit':
                            breadcrumb.push({
                                name: 'Edit Multi Tracking',
                                url: '/multi-tracking/'+this.id+'/edit',
                            });
                            break;
                        case 'pageMultiTrackingDetail':
                            breadcrumb.push({
                                name: 'Detail Multi Tracking',
                                url: '/multi-tracking/'+this.id+'/detail/meeting-point',
                            });
                            break;
                        case 'pageMultiTrackingMeetingPointDetail':
                            breadcrumb.push({
                                name: 'Detail Multi Tracking',
                                url: '/multi-tracking/'+this.id+'/detail/meeting-point',
                            },{
                                name: 'Detail Meeting Point',
                                url: '',
                            });
                            break;
                            
                        default:
                            // code block
                    }
                }
            }

            return breadcrumb;
        },
    },
    watch: {
        route(newData) {
            if (newData.matched[0] && newData.matched[0].components.default.name === 'multiTrackingShell')
                window.scrollTo(0,0);
        },
        id() {},
        breadcrumb() {},
    },
    created() {
        if (this.$route.params.id && this.$route.params.id === 'create')
            this.$router.push({ name: 'pageMultiTrackingCreate', params: {} });
    },
}
</script>