<template lang="pug">
.box__body
    .card.card--table.card--no-bg
        .card__header.card--table__header
            h2.text-xl 
        .card__body
            .container-form
                .group-fields.border-b
                    .field
                        .field__label
                            label.block.text-sm.font-bold ID <sup>*</sup>
                        .field__form
                            input.field-style(type="text" placeholder="Write ID here" v-model="id")
                            .pt-3
                                .message.text-xs.text-red-500(v-if="$v.id.$error") This ID is required
                    .flex.items-center.mb-6
                        // .block(v-if="isWatchlistQuota")
                        .block
                            // button.btn-default Watchlist: loading ...


                .flex.items-center.justify-end.mb-8
                    button.btn.btn-default(type="button" @click="reset()")
                        | Reset
                    button.btn.btn-primary.ml-4(
                        v-if="checkFeatureByAcl('ultimo', 'show')"
                        type="submit" @click="submit($event)"
                    )
                        | Fetch
            // .container-form
            .container-form(style="max-width:100%;")
                .group-fields.border-b
                    .field
                        .field__label
                            label.block.text-sm.font-bold Response [{{ response_ultimo_async_api && response_ultimo_async_api?.data?.code ? response_ultimo_async_api?.data?.code : '' }}]
                        .field__form(v-if="response_ultimo_async_api && response_ultimo_async_api.data")
                            // label.block.text-sm.font-bold {{ resp_deep_void && resp_deep_void.data ? resp_deep_void.data : '' }}
                            // vue-json-pretty(:data="resp_deep_void_data")
                            json-viewer(:value="response_ultimo_async_api.data.respond" copyable boxed sort expanded :expand-depth="7")
</template>

<script>
import IconSet from '@/components/IconSet.vue';
import Spinner from '@/components/Spinner.vue';
import { required } from "vuelidate/lib/validators";
import JsonViewer from 'vue-json-viewer/ssr'
import 'vue-json-viewer/style.css'
import { mapFields } from 'vuex-map-fields';

export default {
    name: 'pageUltimoForm',
    components: {
        IconSet,
        Spinner,
        JsonViewer,
        // VueJsonPretty,
        // Multiselect,
    },
    data() {
        return {
            // error message
            error_value_data: '',

            timeout: null,
        }
    },
    validations: {
        id: {
            required,
            // maxLength: maxLength(255)
        },
    },
    computed: {
        ...mapFields('ultimo', {
            id: 'id',
            response_ultimo_async_api: 'response_ultimo_async_api',
        }),
        route_name() {
            return this.$route.name;
        },
        page_title() {
            let title = 'Ultimo';
            return title;
        },
    },
    watch: {
        route_name() {
        },
        id() { },
        page_title() { },
    },
    methods: {
        setEmptyModel() {
            this.id = ''
            this.response_ultimo_async_api = {}
        },
        setErrors(empty = true, errors = null) {
            if (empty === true) {
                this.error_value_data = '';
            } else if (empty !== true && errors !== null) {
                this.error_value_data = errors;
            }
        },
        reset() {
            this.setEmptyModel();
            // reset vuelidate
            this.$nextTick(() => { this.$v.$reset(); });
            this.setErrors(true);
            window.scrollTo(0, 0);
        },
        submit(e) {
            e.preventDefault();
            this.$v.$touch();

            if (this.$v.id.$invalid) {
                return;
            }

            this.$store.dispatch('ultimo/getUltimoAsyncAPI', [this.id]);
        },
    },
    created() {
    },
}
</script>
