<template lang="pug">
.card
    .card__header
        h2.text-xl Activity Location
    .card__body
        .flex.flex-wrap(
            v-if="status_activity_location.status === 'loading'"
            class="lg:-mx-2"
        )
            template(v-if="route_name === 'TargetDetailLocationMovement' || route_name === 'TargetDetailLocationLog'")
                Loading(show="true")
            template(v-else)
                LoadingAnimation(show="true" title="activity-location")
        .flex.flex-wrap(v-else)
            .block(class="w-full mb-4 lg:w-4/12 lg:mb-0")
                .rollout.pb-4
                    span.text-xs Operator
                    p.text-light
                        | {{ activity_location.data_location && activity_location.data_location.operator ? activity_location.data_location.operator : '-' }}
                //- .rollout.pb-4
                    span.text-xs Signal
                    p.text-light 
                        | {{ activity_location.data_location && activity_location.data_location.net_type ? activity_location.data_location.net_type : '-' }}
                .rollout.pb-4
                    span.text-xs BTS
                    p.text-light 
                        | {{ activity_location.data_location && activity_location.data_location.bts ? activity_location.data_location.bts : '-' }}
                .rollout.pb-4
                    span.text-xs Country
                    p.text-light 
                        | {{ activity_location.data_location && activity_location.data_location.country ? activity_location.data_location.country : '-' }}
                .rollout.pb-4
                    span.text-xs Date time
                    p.text-light 
                        | {{ activity_location.data_location && activity_location.data_location.datetime ? activity_location.data_location.datetime : '-'}}
                //- .rollout.pb-4
                    span.text-xs Information
                    p.text-light
                        | {{ status_activity_location.status ? ((activity_location.data_location && Object.keys(activity_location.heatmap).length > 0 && Object.keys(activity_location.recent_location).length > 0 && activity_location.recent_location.features && (activity_location.recent_location.features[0].geometry.coordinates[0] !== '' && activity_location.recent_location.features[0].geometry.coordinates[0] !== null) && (activity_location.recent_location.features[0].geometry.coordinates[1] !== '' && activity_location.recent_location.features[0].geometry.coordinates[1] !== null)) ? 'Success load data.' : 'Failed load data, some data is missing or something wrong.') : '-' }}
                .rollout.pb-4
                    span.text-xs Address
                    p.text-light
                        | {{ activity_location.recent_location && activity_location.recent_location.features && activity_location.recent_location.features[0].properties.alamat ? activity_location.recent_location.features[0].properties.alamat : '-' }}

            //- .block(class="w-full lg:w-8/12")
                //- BoxMap
                //- .box-map

            template(v-if="(activity_location.data_location && (Object.keys(activity_location.heatmap).length > 0 || Object.keys(activity_location.recent_location).length > 0)) && mapLoaded")
                .block(class="w-full lg:w-8/12")
                    template(v-if="mapVendor === 'osm'")
                        l-map(:id="id" :zoom="10" :center="center" :options="options" style="height: 100%; width: 100%;")
                            l-tile-layer(:url="url" :attribution="attribution")
                            l-marker(v-for="(location, index) in locations" :lat-lng="location.position" v-bind:key="index")
                                l-popup(:content="location.name")
                            LeafletHeatmap(v-if="latLngHeat.length > 0" :lat-lng="latLngHeat" :max="maxHeat" :radius="radHeat" :maxZoom="10")

                    template(v-else)
                        //- .box-map
                        gmap-map(ref="mapRef" :center="center" :zoom="10" :options="options" style="width:100%; height: 100%;")

            .block(v-else class="w-full lg:w-8/12")
                //- .box-map
                NoDataAvailable

</template>

<script>
import { env } from '@/config/env';
// import BoxMap from '@/components/Map.vue'
import { mapState } from 'vuex';
import { gmapApi } from 'vue2-google-maps';
import moment from 'moment';
import Loading from '@/components/Loading.vue';
import LoadingAnimation from '@/components/LoadingAnimation.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';

// open street map
import {LMap, LTileLayer, LMarker, LPopup} from 'vue2-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { GestureHandling } from "leaflet-gesture-handling";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import LeafletHeatmap from '@/components/LeafletHeatmap.vue';
// import 'leaflet.heat';
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.imagePath = '';
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export default {
    name: 'LocationLogActivityLocation',
    components: {
        // BoxMap,
        Loading,
        NoDataAvailable,
        LoadingAnimation,

        // open street map
        LMap,
        // GestureHandling,
        LTileLayer,
        LMarker,
        LPopup,
        LeafletHeatmap,
    },
    props: ['isKey'],
    data() {
        return {
            infoWindows: [],
            mapVendor: this.$store.getters['config/getMapVendor'],
            mapLoaded: false,
            
            // google map
            options: {
                // zoomControl: true,
                // mapTypeControl: true,
                // scaleControl: true,
                // streetViewControl: true,
                // rotateControl: true,
                // fullscreenControl: false,
                // disableDefaultUi: false
                // scrollwheel: false,
                gestureHandling: 'cooperative',
                styles: [
                    {elementType: 'geometry', stylers: [{color: '#242f3e'}]},
                    {elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}]},
                    {elementType: 'labels.text.fill', stylers: [{color: '#746855'}]},
                    {
                      featureType: 'administrative.locality',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#d59563'}]
                    },
                    {
                      featureType: 'poi',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#d59563'}]
                    },
                    {
                      featureType: 'poi.park',
                      elementType: 'geometry',
                      stylers: [{color: '#263c3f'}]
                    },
                    {
                      featureType: 'poi.park',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#6b9a76'}]
                    },
                    {
                      featureType: 'road',
                      elementType: 'geometry',
                      stylers: [{color: '#38414e'}]
                    },
                    {
                      featureType: 'road',
                      elementType: 'geometry.stroke',
                      stylers: [{color: '#212a37'}]
                    },
                    {
                      featureType: 'road',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#9ca5b3'}]
                    },
                    {
                      featureType: 'road.highway',
                      elementType: 'geometry',
                      stylers: [{color: '#746855'}]
                    },
                    {
                      featureType: 'road.highway',
                      elementType: 'geometry.stroke',
                      stylers: [{color: '#1f2835'}]
                    },
                    {
                      featureType: 'road.highway',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#f3d19c'}]
                    },
                    {
                      featureType: 'transit',
                      elementType: 'geometry',
                      stylers: [{color: '#2f3948'}]
                    },
                    {
                      featureType: 'transit.station',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#d59563'}]
                    },
                    {
                      featureType: 'water',
                      elementType: 'geometry',
                      stylers: [{color: '#17263c'}]
                    },
                    {
                      featureType: 'water',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#515c6d'}]
                    },
                    {
                      featureType: 'water',
                      elementType: 'labels.text.stroke',
                      stylers: [{color: '#17263c'}]
                    }
                ],
            },
            center: {},
            markers: [],
            places: [],
            // myLatlng: null,
            
            // openstreetmap
            // url: 'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
            url: `${env.VUE_APP_OSM_CDN_URL}{z}/{x}/{y}.png`,
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            locations: [],
            id: 'openstreetmap-activity_location',
            newLoc: '',
            newLt : 0, 
            newLng : 0,
            icon: L.icon({iconUrl: "null",}),
            latLngHeat: [],
            maxHeat: 1.0,
            radHeat: 30,
        };
    },
    computed: {
        ...mapState('locationLog', [
          'status_activity_location',
          'activity_location',
        ]),
        google: gmapApi,
        route_name() {
            return this.$route.name;
        },
    },
    watch: {
        // activity_location (newData, oldData) {
        activity_location(newData) {
            // Our fancy notification (2).
            // console.log('====== watch on RecentLocation.vue')
            console.log({newData})
            this.startMap();
        },
        google() {},
    },
    methods: {
        hideAllInfoWindows() {
            for (var i=0; i < this.infoWindows.length; i++) {
                this.infoWindows[i].close();
            }
        },
        moment: function (string, string_format, format) {
            return moment(string, string_format).format(format);
        },
        startMap() {
            // console.log('start Map')
            var self = this;
            var location = [];
            // const google = self.google;

            // gmap
            self.infoWindows = [];
            self.markers = [];

            // osm
            self.locations = [];
            self.latLngHeat = [];

            if(typeof this.activity_location.recent_location !== undefined && typeof this.activity_location.recent_location !== 'undefined' && this.activity_location.recent_location !== null){
                location = this.activity_location.recent_location
            } else {
                return;
            }

            if (Object.keys(location).length > 0 || Object.keys(this.activity_location.heatmap).length > 0) {
                

                var result = Object.keys(location).length > 0 ? location.features[0] : null;

                var lng = parseFloat(result ? result.geometry.coordinates[0] : this.activity_location.heatmap[0][1]);

                var lat = parseFloat(result ? result.geometry.coordinates[1] : this.activity_location.heatmap[0][0]);

                // for marker
                // var dtm = moment().format('DD MMMM YYYY, hh:mm');
                // var dtm = (result && result.properties.dtm ? moment(result.properties.dtm, 'YYYY-MM-DDThh:mm:ss').format('DD MMMM YYYY, hh:mm') : '-');
                // var dtm = (result && result.properties.dtm ?result.properties.dtm: '-');
                var dtm = (this.activity_location.data_location && this.activity_location.data_location.datetime ?this.activity_location.data_location.datetime: '-');
                // var bts = (this.activity_location.data_location && this.activity_location.data_location.bts ? this.activity_location.data_location.bts : '-');
                var bts = (this.activity_location.data_location && this.activity_location.data_location.bts ? this.activity_location.data_location.bts : (result && result.properties.bts ? result.properties.bts : '-'));
                // var state = (result && result.properties.state ? result.properties.state : '-');

                // console.log('lan, lng on recent location : ');
                // console.log({lat, lng});

                var name = result ? result.properties.name : '-';
                // set_map(name, lat, lng, results.heatmap)

                if (Number.isNaN(lat) || Number.isNaN(lng)) {
                    return;
                }

                this.center = {
                    lat: lat,
                    lng: lng,
                };

                this.mapLoaded = true;

                var contentString = '<div id="content">'+
                  '<div id="bodyContent">'+
                    '<table>'+
                      '<tr>'+
                        '<td class="pr-1">Last Updated</td>'+
                        '<td class="pr-1">:</td>'+
                        '<td>'+dtm+'</td>'+
                      '</tr>'+
                      '<tr>'+
                        '<td>BTS</td>'+
                        '<td>:</td>'+
                        '<td>'+bts+'</td>'+
                      '</tr>'+
                    //   '<tr>'+
                    //     '<td>State</td>'+
                    //     '<td>:</td>'+
                    //     '<td>'+state+'</td>'+
                    //   '</tr>'+
                      '<tr>'+
                        '<td>Link Location</td>'+
                        '<td>:</td>'+
                        '<td><a target="_blank" href="https://www.google.com/maps?q='+self.center.lat+','+self.center.lng+'" style="color: #43ea92;">'+'Google Maps</a> '+
                        '</td>'+
                      '</tr>'+
                    '</table>'+
                  '</div>'+
                '</div>';

                switch(self.mapVendor) {
                    case 'osm':
                        let centerTmp = [self.center['lat'], self.center['lng']];
                        self.locations.push({name:contentString,position:centerTmp});

                        if (self.activity_location.heatmap != null && typeof self.activity_location.heatmap[0] !== 'undefined' && typeof self.activity_location.heatmap[0] !== undefined ) {
                            var heatmapData = {};
                            var maxValue = 0;
                            var heatmapDataFix = []
                            self.activity_location.heatmap.forEach(function(coord) {
                                // var arr = [coord[0], coord[1], 0.1];
                                // heatmapDataFix.push(arr)
                                if(heatmapData[coord[0]+','+coord[1]]){
                                    heatmapData[coord[0]+','+coord[1]][2] += 1;
                                } else {
                                    heatmapData[coord[0]+','+coord[1]]= [coord[0], coord[1], 1];
                                }
                                if (maxValue<heatmapData[coord[0]+','+coord[1]][2]){
                                    maxValue = heatmapData[coord[0]+','+coord[1]][2]
                                }
                            });
                            // console.log({heatmapData})
                            // if (heatmapData.length){
                            if (maxValue>0){
                                Object.values(heatmapData).forEach(function(arr) {
                                    arr[2] = arr[2]/maxValue
                                    heatmapDataFix.push(arr);
                                });
                                // console.log({heatmapDataFix})
                                self.latLngHeat = heatmapDataFix;
                            }
                        }
                        break;

                    default:
                        self.$nextTick(() => {
                            self.$refs.mapRef.$mapPromise.then((map) => {
                                const google = self.google;

                                map.center = new google.maps.LatLng(self.center);
                                map.zoom = 10;

                                if (self.activity_location.heatmap != null && typeof self.activity_location.heatmap[0] !== 'undefined' && typeof self.activity_location.heatmap[0] !== undefined ) {
                                    // eslint-disable next-line
                                    let marker = new google.maps.Marker({
                                        position: new google.maps.LatLng(self.center),
                                        map: map,
                                        // title: name,
                                    });
                                    // console.log(marker)
                                    self.markers.push(marker);

                                    const infowindow = new google.maps.InfoWindow({
                                        content: contentString,
                                        maxWidth: 300
                                    });
                                    
                                    self.infoWindows.push(infowindow);

                                    google.maps.event.addListener(marker, 'click', function() {
                                      // self.hideAllInfoWindows(map);
                                      self.hideAllInfoWindows();
                                      infowindow.open(map, marker);
                                    });

                                    var heatmapData = [];
                                    self.activity_location.heatmap.forEach(function(coord) {
                                        heatmapData.push(new google.maps.LatLng(coord[0], coord[1]));
                                    });

                                    var setHeatmap = new google.maps.visualization.HeatmapLayer({
                                      data: heatmapData,
                                      map: map
                                    });

                                    var bounds = new google.maps.LatLngBounds();

                                    // Extend bounds with each point
                                    for (var i = 0; i < heatmapData.length; i++) {
                                        bounds.extend(heatmapData[i]);
                                    }

                                    // add marker to bounds
                                    bounds.extend(new google.maps.LatLng(self.center.lat, self.center.lng));

                                    // Apply fitBounds
                                    map.fitBounds(bounds);

                                    setHeatmap.setMap(map);
                                    setHeatmap.set('radius', 30);

                                } else {

                                    let marker = new google.maps.Marker({
                                        position: new google.maps.LatLng(self.center),
                                        // position: self.myLatlng,
                                        map: map,
                                        title: name,
                                    });

                                    // console.log(name)

                                    let infowindow = new google.maps.InfoWindow({
                                        content: contentString,
                                        maxWidth: 300,
                                    });

                                    self.infoWindows.push(infowindow);
                                    
                                    // marker.addListener('click', function() {
                                    google.maps.event.addListener(marker, 'click', function() {
                                        infowindow.open(map, marker);
                                    });
                                }
                                
                            });
                        });
                        break;
                }
            }
        },
    },
    mounted() {
        if (this.isKey)
            this.startMap();
    },
}
</script>

<style lang="sass">
    .box-map
        @apply .flex .items-center .justify-center .w-full
        min-height: 450px
        background-color: $color-secondary-800
</style>
