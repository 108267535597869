<template lang="pug">
.tactical-device-shell
    .box
        .box__header
            TitlePage(
                title="Tactical Device"
                :breadcrumb="breadcrumb"
                )
        .box__body
            nav.menu(v-if="show_navbar")
                ul
                    li
                        router-link(
                            :to="{ name: 'pageTacticalDeviceIndex', params: {} }"
                        ) Lists of Tactical Device
                    li
                        router-link(
                            :to="{ name: 'pageTacticalDeviceMaps', params: {} }"
                        ) Maps of Tactical Device

            router-view
    
</template>

<script>
import TitlePage from '@/components/shell/TitlePage.vue'
export default {
    name: 'TacticalDeviceShell',
    components: {
        TitlePage,
    },
    data() {
        return {
            show_navbar: false,
        };
    },
    computed: {
        route() {
            return this.$route;
        },
        id() {
            return this.$route.params.id;
        },
        breadcrumb() {
            let breadcrumb = [
                {
                    name: 'Tactical Device',
                    url: '/tactical-device'
                }
            ];

            if (this.route.matched[0] && this.route.matched[0].components.default.name === 'TacticalDeviceShell' && this.route.matched[1]) {
                switch(this.route.matched[1].name) {
                    case 'pageTacticalDeviceCreate':
                        breadcrumb.push({
                            name: 'Add Tactical Device',
                            url: '/tactical-device/create',
                        });
                        break;

                    case 'pageTacticalDeviceEdit':
                        breadcrumb.push({
                            name: 'Edit Tactical Device',
                            url: '/tactical-device/'+this.id+'/edit',
                        });
                        break;

                    case 'pageTacticalDeviceMaps':
                        breadcrumb.push({
                            name: 'Maps Tactical Device',
                            url: '/tactical-device/maps',
                        });
                        break;
                    default:
                        // code block
                }
            }

            return breadcrumb;
        },
    },
    watch: {
        route(newData) {
            if (newData.matched[0] && newData.matched[0].components.default.name === 'TacticalDeviceShell') {
                window.scrollTo(0,0);

                if (newData.matched[1] && (newData.matched[1].name === 'pageTacticalDeviceIndex' || newData.matched[1].name === 'pageTacticalDeviceMaps')) {
                    this.show_navbar = true;
                } else {
                    this.show_navbar = false;
                }
            }
        },
        id() {},
        breadcrumb() {},
    },
    created() {
        if (this.$route.params.id && this.$route.params.id === 'create')
            this.$router.push({ name: 'pageTacticalDeviceCreate', params: {} });
        else if (this.$route.params.id && this.$route.params.id === 'maps')
            this.$router.push({ name: 'pageTacticalDeviceMaps', params: {} });
    },
    mounted() {
        if (this.route.matched[1] && (this.route.matched[1].name === 'pageTacticalDeviceIndex' || this.route.matched[1].name === 'pageTacticalDeviceMaps')) {
            this.show_navbar = true;
        } else {
            this.show_navbar = false;
        }
    },
}
</script>

<style lang="sass">
    nav.menu
        ul
            @apply .flex .items-center .border-b
            li
                display: block
                a
                    @apply .block .py-4 .px-6 .relative
                    transition: all 0.3s ease-in-out
                    &::before
                        content: ''
                        height: 2px
                        width: 100%
                        position: absolute
                        bottom: -1px
                        left: 0px
                        background-color: transparent
                        transition: all 0.3s ease-in-out
                    &:hover
                        color: $color-main-500
                    &.router-link-exact-active
                        color: $color-main-500
                        &::before
                            background-color: $color-main-500
</style>