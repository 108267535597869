import ls from 'local-storage';
import SecureLS from "secure-ls";

const secureLs = new SecureLS({ isCompression: false });

export const localStorageMixin = {
  methods: {
    // ls_pushMessage (msg) {
    //   ls.set('new-message', msg)
    // },
    // ls_pullMessage () {
    //   return ls.get('new-message')
    // },
    // ls_attachListener (callback) {
    //   ls.on('new-message', callback)
    // }

    ls_getData () {
      // return ls.get('vuex');
      return JSON.parse(secureLs.get('vuex'));
    },
    ls_attachListener (callback) {
      ls.on('vuex', callback);
    }
  }
}