import axios from "@/axios-main";

const acl = {
  namespaced: true,
  state: {
    module: 'ACL',
    // load data acl
    status_list: false,

    // acl
    list: {},
    detail: {},
  },
  mutations: {
    // load data acl
    setStatusList: (state, status) => {
      state.status_list = status;
    },

    // acl
    setList: (state, data) => {
      state.list = data;
    },
  },
  getters: {},
  actions: {
    getList({ commit, dispatch, state }) {

      commit('setStatusList', 'loading');

      axios.get('api/v1/acl/list')
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        commit('setList', data);

        if (Object.keys(data).length===0) {
          commit('setStatusList', 'empty');
        } else {
          commit('setStatusList', true);
        }
      })
      .catch((resp) => {
        // console.log(resp)
        commit('setStatusList', 'failed');
        dispatch('errors/handleApiResponse', [(resp.response && resp.response.status ? resp.response.status : null), (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null), state.module+' List'], { root: true });
      });
    },


    update({ state, dispatch }, input) {

      let params = new FormData();
      params.append('input', input);
    
      return axios.post('api/v1/acl/update', params)
      .then((resp) => {
        // console.log(resp.data)
        if (resp.status === 200 && resp.data.message === 'success') {
          return {result: 'success', data: resp.data};
        } else {
          return {result: 'failed', data: resp.data};
        }
      })
      .catch((resp) => {
        // console.log(resp)
        dispatch('errors/handleApiResponse', [(resp.response && resp.response.status ? resp.response.status : null), (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null), state.module+' Update'], { root: true });
        return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
      });
    },
  }
};

export default acl;
