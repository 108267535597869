import axios from "@/axios-main";
import axiosOrigin from "axios";
import { env } from '@/config/env';

const urlPath = env.VUE_APP_API_VERSION_LOG_AUTH == 'v2' ? 'v2/data-log/log-auth' : 'api/v1/log-auth';
let cancelUsersLog;
var cancelToken = axiosOrigin.CancelToken;

const usersLog = {
	namespaced: true,
	state: {
		status_users_log: {
			status: false,
			cancel: null,
			code: null,
		},

		users_log: {},
	},
	mutations: {
		setStatusUsersLog: (state, status) => {
			state.status_users_log = status;
		},

		setUsersLogData: (state, data) => {
			state.users_log = data;
		},
	},
	getters: {},
	actions: {
		async getList({state, commit, dispatch}, [user_id=null, limit='', page='', keyword='',from_date='', to_date='' , order_by='' ]) {
			if (state.status_users_log.cancel != null && state.status_users_log.status === 'loading')
				await state.status_users_log.cancel('Operation canceled by the user.');
	
			let params = new FormData();
			if(user_id)
				params.append('users_id', user_id);
			
			params.append('limit', limit);
			params.append('page', page);
			params.append('keyword', keyword);
			params.append('order_by', order_by);
			params.append('from_date', from_date);
			params.append('to_date', to_date);

			axios.post(urlPath + '/list', params, {
					cancelToken: new cancelToken(function executor(c) {
						cancelUsersLog = c;
						commit('setStatusUsersLog', {
							status: 'loading',
							cancel: c,
							code: null,
						});				
					}),
				})
				.then((resp) => {
					let data = resp.data;
					let result = {
						status: true,
						cancel: null,
						code: resp.response && resp.response.status ? resp.response.status : null,
					};

					if (Object.keys(data).length === 0)
						result.status = 'empty';

					commit('setUsersLogData', data);
					commit('setStatusUsersLog', result);
				})
				.catch((resp) => {
					// console.log(resp)
					let result = {
						status: 'failed',
						cancel: null,
						code: resp.response && resp.response.status ? resp.response.status : null,
					};

					if (axiosOrigin.isCancel(resp)) {
						result.status = 'loading';
						result.cancel = cancelUsersLog;
						
					}

					commit('setStatusUsersLog', result);
					if (!axiosOrigin.isCancel(resp))
						dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null)], {
							root: true
						});
				});
		},
		async storeLogCopy({commit, dispatch}, [activity='', path='', data='']) {
			let params = new FormData();
			params.append('activity', activity);
			params.append('path', path);
			params.append('data', data);

			axios.post(urlPath + '/log-copy', params)
				.then((resp) => {
					console.log("Copy target logged", resp)
				})
				.catch((resp) => {
					console.log("Copy target failed to log", resp)
				});
		},
	}
};

export default usersLog;