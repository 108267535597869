import axios from "@/axios-main";
import axiosOrigin from "axios";

let cancelList, cancelSearchList, cancelNotesList;
var cancelToken = axiosOrigin.CancelToken;

const caseManagement = {
  namespaced: true,
  state: {
    module: 'Case Management',
    // load data caseManagement
    status_list: {
      status: false,
      cancel: null,
      code: null
    },
    status_search_list: {
      status: false,
      cancel: null,
      code: null,
    },
    status_detail: false,

    // caseManagement
    list: {},
    search_list: {},
    detail: {},

    // notes
    notes_list: {},
    status_notes_list: {
      status: false,
      cancel: null,
      code: null
    },
  },
  mutations: {
    // load data caseManagement
    setStatusList: (state, status) => {
      state.status_list = status;
    },
    setStatusSearchList: (state, status) => {
      state.status_search_list = status;
    },
    setStatusDetail: (state, status) => {
      state.status_detail = status;
    },

    // caseManagement
    setList: (state, data) => {
      state.list = data;
    },
    setSearchList: (state, data) => {
      state.search_list = data;
    },
    setDetail: (state, data) => {
      state.detail = data;
    },

    // notes
    setNotesList: (state, data) => {
      state.notes_list = data;
    },
    setStatusNotesList: (state, status) => {
      state.status_notes_list = status;
    },
  },
  getters: {},
  actions: {
    async getList({ state, commit, dispatch }, [limit=null, page=null, keyword=null, status=null, order_by=null, from_date=null, to_date=null]) {

      if (state.status_list.cancel !== null && state.status_list.status === 'loading')
        await state.status_list.cancel('Operation canceled by the user.');
      
      let params = {
        limit: limit,
        page: page,
        keyword: keyword,
        status: status,
        order_by: order_by,
        from_date: from_date,
        to_date: to_date,
      };
    
      axios.get('api/v1/case-management/list', {
        params: params,
        cancelToken: new cancelToken(function executor(c) {
          cancelList = c;
          commit('setStatusList', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setList', data);

        if (Object.keys(data).length===0)
          result.status = 'empty';

        commit('setStatusList', result);
      })
      .catch((resp) => {
        // console.log(resp)
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelList;
        }

        commit('setStatusList', result);
        if (!axiosOrigin.isCancel(resp)) 
          dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null), state.module+' List'], { root: true });
      });
    },

    async getExport({ dispatch },[limit=null]) {
      let params = {
        limit: limit,
      };
    
      return axios.get('api/v1/case-management/list', {
        params: params,
      })
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        return data.data
      })
      .catch((resp) => {
        console.log(resp)
        return []
      });
    },

    async getSearchList({ state, commit, dispatch }, [limit=null, page=null, keyword=null, order_by=null, from_date=null, to_date=null, is_cached=null, status=null]) {

      if (state.status_search_list.cancel !== null && state.status_search_list.status === 'loading')
        await state.status_search_list.cancel('Operation canceled by the user.');

      let params = {
        limit: limit,
        page: page,
        keyword: keyword,
        order_by: order_by,
        from_date: from_date,
        to_date: to_date,
        is_cached: is_cached,
        status: status,
      };
    
      axios.get('api/v1/case-management/list', {
        params: params,
        cancelToken: new cancelToken(function executor(c) {
          cancelSearchList = c;
          commit('setStatusSearchList', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setSearchList', data);

        if (Object.keys(data).length===0)
          result.status = 'empty';

        commit('setStatusSearchList', result);
      })
      .catch((resp) => {
        // console.log(resp)
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelSearchList;
        }

        commit('setStatusSearchList', result);
        if (!axiosOrigin.isCancel(resp)) 
          dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null), state.module + ' List'], { root: true });
      });
    },

    getDetail({ commit, dispatch }, id) {

      let params = new FormData();
      params.append('id', id);
    
      return axios.post('api/v1/case-management', params)
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        commit('setDetail', data);
        // return 'success';
        let code = resp.response && resp.response.status ? resp.response.status : null
        return {result: 'success', code: code, data: (resp.response && resp.response.data ? resp.response.data : null)};
      })
      .catch((resp) => {
        // console.log('{resp}:begin')
        // console.log({resp})
        // console.log('{resp}:end')
        let code = resp.response && resp.response.status ? resp.response.status : null
        let message = resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null
        dispatch('errors/handleApiResponse', [code, message], { root: true });
        return {result: 'failed', code: code, data: (resp.response && resp.response.data ? resp.response.data : null)};
      });
    },

    create({ dispatch }, [operation_name=null, location=null, assigns=null, status=null, description=null]) {
      
      let params = new FormData();
      
      if (assigns == '[]' || assigns == '') {
        assigns = null
      }

      params.append('operation_name', operation_name);
      params.append('location', location);
      params.append('assigns', assigns && assigns.length > 0 ? assigns : '');
      params.append('status', status);
      params.append('description', description);

      return axios.post('api/v1/case-management/create', params)
      .then((resp) => {

        if (resp.status === 200 && resp.data.message === 'Data Successfully Created') {
          return {result: 'success', data: resp.data};
        } else {
          return {result: 'failed', data: resp.data};
        }
      })
      .catch((resp) => {
        // console.log(resp)
        let code = resp.response && resp.response.status ? resp.response.status : null
        let message = resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null
        dispatch('errors/handleApiResponse', [code, message], { root: true });
        return {result: 'failed', code: code, data: (resp.response && resp.response.data ? resp.response.data : null)};
      });
    },

    update({ dispatch }, [id, operation_name=null, location=null, assigns=null, status=null, description=null]) {
      let params = new FormData();
      
      if (assigns == '[]' || assigns == '') {
        assigns = null
      }
      
      params.append('id', id);
      params.append('operation_name', operation_name);
      params.append('location', location);
      params.append('assigns', assigns);
      params.append('status', status);
      params.append('description', description);
    
      return axios.post('api/v1/case-management/update', params)
      .then((resp) => {
        // console.log(resp.data)
        
        if (resp.status === 200 && resp.data.message === 'Data Successfully Updated') {
          return {result: 'success', data: resp.data};
        } else {
          return {result: 'failed', data: resp.data};
        }
      })
      .catch((resp) => {
        // console.log(resp)
        let code = resp.response && resp.response.status ? resp.response.status : null
        let message = resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null
        dispatch('errors/handleApiResponse', [code, message], { root: true });
        return {result: 'failed', code: code, data: (resp.response && resp.response.data ? resp.response.data : null)};
      });
    },

    delete({ commit, dispatch }, id) {

      let params = new FormData();
      params.append('id', id);

      return axios.post('api/v1/case-management/delete', params)
      .then((resp) => {
        // console.log(resp)
        
        if (resp.status === 200 && resp.data.message === 'Data Successfully Deleted') {
          return 'success';
        } else {
          return 'failed';
        }
      })
      .catch((resp) => {
        // console.log(resp)
        let code = resp.response && resp.response.status ? resp.response.status : null
        let message = resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null
        dispatch('errors/handleApiResponse', [code, message], { root: true });
        return {result: 'failed', code: code, data: (resp.response && resp.response.data ? resp.response.data : null)};
      });

    },

    async getNotesList({ state, commit, dispatch }, [case_id='', limit='', page='', keyword='', from_date='', to_date='']) {
      if (state.status_notes_list.cancel !== null && state.status_notes_list.status === 'loading')
        await state.status_notes_list.cancel('Operation canceled by the user.');

      let params = new FormData();

      params.append('case_id', case_id);
      params.append('limit', limit);
      params.append('page', page);
      params.append('keyword', keyword);
      params.append('from_date', from_date);
      params.append('to_date', to_date);
    
      axios.post('api/v1/note-case/list', params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelNotesList = c;
          commit('setStatusNotesList', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setNotesList', data);

        if (Object.keys(data).length===0)
          result.status = 'empty';

        commit('setStatusNotesList', result);
      })
      .catch((resp) => {
        // console.log(resp)
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelNotesList;
        }

        commit('setStatusNotesList', result);
        if (!axiosOrigin.isCancel(resp)) 
          dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null), 'Note' + state.module], { root: true });
      });
    },

    createNotes({ commit, dispatch }, [case_id = null, content = null]) {

      let params = new FormData();
      params.append('case_id', case_id);
      params.append('content', content);

      return axios.post('api/v1/note-case/create', params)
      .then((resp) => {

        if (resp.status === 200 && resp.data.message === 'Data Successfully Created') {
          return {result: 'success', data: resp.data};
        } else {
          return {result: 'failed', data: resp.data};
        }
      })
      .catch((resp) => {
        // console.log(resp)
        dispatch('errors/handleApiResponse', [(resp.response && resp.response.status ? resp.response.status : null)], { root: true });
        return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
      });
    },
  }
};

export default caseManagement;
