<template lang="pug">
.multi-tracking-detail-shell
    .box
        .box__body
            .p-4
                h2.text-xl {{detail.group_name}}
            nav.menu
                ul
                    //- li
                        router-link(:to="'/multi-tracking/'+id+'/detail/meeting-point'")  Meeting Point
                    li
                        router-link(:to="'/multi-tracking/'+id+'/detail/location-history'")  Location History
                    li(v-if="checkFeatureByAcl('multi_monitoring', 'post')")
                        router-link(:to="'/multi-tracking/'+id+'/detail/monitoring-history'")  Monitoring
            router-view
    
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'MultiTrackingDetailShell',
    components: {
    },
    data() {
        return {

        }
    },
    computed: {
        ...mapState('multiTracking',[
            'detail',
        ]),
        ...mapState('auth', ['user']),
        id() {
            return this.$route.params.id;
        },
        route() {
            return this.$route;
        },
    },
    methods: {
        getData() {
            // console.log(this.route)
            if (this.route.matched[1].name === 'pageMultiTrackingDetail') {
                // for dispatch get data
                EventEmit.$emit('showLoading', true);
                
                this.$store.dispatch('multiTracking/getDetail', [this.id])
                .then((resp) => {
                    if (resp.result === 'success') {
                        EventEmit.$emit('showLoading', false);
                    } else {
                        var code = resp.code || null 
                        var message = null;

                        let statusCodeCheck = ["403", 403]
                        if (statusCodeCheck.includes(code))
                            message = resp && resp.data && resp.data.message? resp.data.message : null;
                        EventEmit.$emit('error', [message]);
                    }
                });
            }
        }
    },
    mounted() {
        this.getData();
    },
}
</script>

<style lang="sass">
    nav.menu
        ul
            @apply .flex .items-center .border-b
            li
                display: block
                a
                    @apply .block .py-4 .px-6 .relative
                    transition: all 0.3s ease-in-out
                    &::before
                        content: ''
                        height: 2px
                        width: 100%
                        position: absolute
                        bottom: -1px
                        left: 0px
                        background-color: transparent
                        transition: all 0.3s ease-in-out
                    &:hover
                        color: $color-main-500
                    &.router-link-exact-active
                        color: $color-main-500
                        &::before
                            background-color: $color-main-500
</style>