import axios from "@/axios-main";
import axiosOrigin from "axios";

let cancelList, cancelListHistory, cancelListCache, cancelSearchList, cancelNotesList, cancelMonitoringList, cancelMonitoringActiveList, cancelSummary;
var cancelToken = axiosOrigin.CancelToken;

const target = {
  namespaced: true,
  state: {
    // module
    status: false,

    //target
    status_list: {
      status: false,
      cancel: null,
      code: null,
    },

    // watchlist purpose
    status_list_history: {
      status: false,
      cancel: null,
      code: null,
    },

    // dashboard
    status_list_cache: {
      status: false,
      cancel: null,
      code: null,
    },

    status_search_list: {
      status: false,
      cancel: null,
      code: null,
    },

    status_detail: {
      status: false,
      cancel: null,
      code: null,
    },

    status_deleted_photos: {
      status: false,
      cancel: null,
      code: null,
    },
    
    status_summary: {
      status: false,
      cancel: null,
      code: null,
    },

    list: {},
    list_history: {},
    list_cache: {}, // dashboard
    search_list: {},
    detail: {},
    deleted_photos: {},
    summary: {},
    
    // notes
    notes_list: [],
    status_notes_list: {
      status: false,
      cancel: null,
      code: null
    },
    data_pagination: { 
        current_page: 1,
        has_next: false,
        has_previous: false,
        next_page_number: 1,
        per_page: 10,
        previous_page_number: null,
        total_data: 0,
        total_page: 1,
    },
    is_loading_next: false,

    // monitoring
    monitoring_list: {},
    status_monitoring_list: {
      status: false,
      cancel: null,
      code: null,
    },

    // monitoring active
    monitoring_active_list: {},
    status_monitoring_active_list: {
      status: false,
      cancel: null,
      code: null,
    },

    // global
    redirect: "index", // index / detail
    show_navbar: true, // index / detail
  },
  mutations: {
    // module
    setStatus: (state, status) => {
      state.status = status;
    },
    // target
    setStatusList: (state, status) => {
      state.status_list = status;
    },
    setStatusListHistory: (state, status) => {
      state.status_list_history = status;
    },
    setStatusListCache: (state, status) => {
      state.status_list_cache = status;
    },
    setStatusSearchList: (state, status) => {
      state.status_search_list = status;
    },
    setStatusDetail: (state, status) => {
      state.status_detail = status;
    },
    setStatusDeletedPhotos: (state, status) => {
      state.status_deleted_photos = status;
    },
    setStatusSummary: (state, status) => {
      state.status_summary = status;
    },

    setList: (state, data) => {
      state.list = data;
    },
    setListHistory: (state, data) => {
      state.list_history = data;
    },
    setListCache: (state, data) => {
      state.list_cache = data;
    },
    setSearchList: (state, data) => {
      state.search_list = data;
    },
    setDetail: (state, data) => {
      state.detail = data;
    },
    setDeletedPhotos: (state, data) => {
      state.deleted_photos = data;
    },

    setSummary: (state, data) => {
      state.summary = data;
    },
    
    // notes
    setNotesList: (state, data) => {
      state.notes_list = data;
    },
    setStatusNotesList: (state, status) => {
      state.status_notes_list = status;
    },
    setDataPagination: (state, data) => {
      state.data_pagination = data;
    },
    setIsLoadingNext: (state, status) => {
      state.is_loading_next = status;
    },

    // monitoring
    setStatusMonitoringList: (state, status) => {
      state.status_monitoring_list = status;
    },
    setMonitoringList: (state, data) => {
      state.monitoring_list = data;
    },

    // monitoring active
    setStatusMonitoringActiveList: (state, status) => {
      state.status_monitoring_active_list = status;
    },
    setMonitoringActiveList: (state, data) => {
      state.monitoring_active_list = data;
    },

    // global
    setShowNavbar: (state, boolean) => {
      state.show_navbar = boolean;
    },
    setRedirect: (state, value) => {
      state.redirect = value;
    },
  },
  getters: {},
  actions: {
    async getList({ commit, dispatch, state }, [limit=null, page=null, keyword=null, order_by=null, from_date=null, to_date=null, param='', no_case_or_cases=null, monitoring_active=null]) {

      if(state.status_list.cancel != null && state.status_list.status == 'loading')
        await state.status_list.cancel('Operation canceled by the user.');

      let params = {
        limit: limit,
        page: page,
        keyword: keyword,
        order_by: order_by,
        from_date: from_date,
        to_date: to_date,
        no_case_or_cases: no_case_or_cases,
      };

      if (monitoring_active==true)
        params.monitoring_active = 'true';

      let urlApi = 'api/v1/single-target/list' 
      if (param === 'dashboard')
        urlApi = 'api/v1/single-target/list-cache';

      axios.get(urlApi, {
        params: params,
        cancelToken: new cancelToken(function executor(c) {
          // cancelList = c;
          // commit('setStatusList', {
          //   status: 'loading',
          //   cancel: c,
          //   code: null,
          // });

          let loading = {
            status: 'loading',
            cancel: c,
            code: null,
          };

          switch(param) {
            case 'dashboard':
              cancelListCache = c;
              commit('setStatusListCache', loading);
              break;

            default:
              cancelList = c;
              commit('setStatusList', loading);
          }
        }),
      })
      .then((resp) => {
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (Object.keys(data).length===0)
          result.status = 'empty';

        switch(param) {
          case 'dashboard':
            commit('setListCache', data);
            commit('setStatusListCache', result);
            break;

          default:
            commit('setList', data);
            commit('setStatusList', result);
        }
      })
      .catch((resp) => {
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        // if (axiosOrigin.isCancel(resp)) {
        //   result.status = 'loading';
        //   result.cancel = cancelList;
        // }

        // if (param === 'dashboard')
        //   result.status = 'empty';

        // commit('setStatusList', result);

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          switch(param) {
            case 'dashboard':
              result.cancel = cancelListCache;
              break;

            default:
              result.cancel = cancelList;
          }
        }

        switch(param) {
          case 'dashboard':
            if (result.status !== 'loading')
              result.status = 'empty';
            commit('setStatusListCache', result);
            break;

          default:
            commit('setStatusList', result);
        }

        if (param !== 'dashboard'){
          if (!axiosOrigin.isCancel(resp)){
            let code = resp.response && resp.response.status ? resp.response.status : null
            let message = resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null
            dispatch('errors/handleApiResponse', [code, message], { root: true });
          }
        }
      });
    },

    async getListHistory({ commit, dispatch, state }, [limit=null, page=null, keyword=null, order_by=null, from_date=null, to_date=null, param='', no_case_or_cases=null, monitoring_active=null]) {

      if(state.status_list.cancel != null && state.status_list.status == 'loading')
        await state.status_list.cancel('Operation canceled by the user.');

      let params = {
        limit: limit,
        page: page,
        keyword: keyword,
        order_by: order_by,
        from_date: from_date,
        to_date: to_date,
        no_case_or_cases: no_case_or_cases,
        is_soft_delete: true,
        has_photo: true,
      };

      if (monitoring_active==true)
        params.monitoring_active = 'true';

      let urlApi = 'api/v1/single-target/list' 

      axios.get(urlApi, {
        params: params,
        cancelToken: new cancelToken(function executor(c) {

          let loading = {
            status: 'loading',
            cancel: c,
            code: null,
          };

          cancelListHistory = c;
          commit('setStatusListHistory', loading);
        }),
      })
      .then((resp) => {
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (Object.keys(data).length===0){
          result.status = 'empty';
        }
        commit('setListHistory', data);
        commit('setStatusListHistory', result);
      })
      .catch((resp) => {
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        // if (axiosOrigin.isCancel(resp)) {
        //   result.status = 'loading';
        //   result.cancel = cancelListHistory;
        // }

        // if (param === 'dashboard')
        //   result.status = 'empty';

        // commit('setStatusListHistory', result);

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelListHistory;
        }

        
        commit('setStatusListHistory', result);
        if (!axiosOrigin.isCancel(resp)){
          let code = resp.response && resp.response.status ? resp.response.status : null
          let message = resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null
          dispatch('errors/handleApiResponse', [code, message], { root: true });
        }
      });
    },

    async getSearchList({ commit, dispatch, state }, [limit=null, page=null, keyword=null, order_by=null, from_date=null, to_date=null, param='', no_case_or_cases=null]) {

      if (state.status_search_list.cancel !== null && state.status_search_list.status === 'loading')
        await state.status_search_list.cancel('Operation canceled by the user.');

      let params = {
        limit: limit,
        page: page,
        keyword: keyword,
        order_by: order_by,
        from_date: from_date,
        to_date: to_date,
        no_case_or_cases: no_case_or_cases,
      };

      let urlApi = 'api/v1/single-target/list';
      if (param === 'dashboard')
        urlApi = 'api/v1/single-target/list-cache';

      axios.get(urlApi, {
        params: params,
        cancelToken: new cancelToken(function executor(c) {
          cancelSearchList = c;
          commit('setStatusSearchList', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setSearchList', data);

        if (Object.keys(data).length===0)
          result.status = 'empty';

        commit('setStatusSearchList', result);
      })
      .catch((resp) => {
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelSearchList;
        }

        if (param === 'dashboard')
          result.status = 'empty';

        commit('setStatusSearchList', result);

        if (param !== 'dashboard'){
          if (!axiosOrigin.isCancel(resp)) {
            let code = resp.response && resp.response.status ? resp.response.status : null
            let message = resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null
            dispatch('errors/handleApiResponse', [code, message], { root: true });
          }
        }
      });
    },

    // ========================================== Detail Target 
    async getResultData({ commit, dispatch }, [id]) {
      // console.log('===== target getResultData')
      // console.log(id)
      dispatch('setLoading');
      dispatch('getDetail', [id]);
      // dispatch('getNotesList',[id]);
      // dispatch('getMonitoringList',[id]);
    },

    setLoading({ commit }) {
      commit('setStatus', true);
      commit('setStatusDetail', {
        status: 'loading',
        cancel: null,
        code: null
      });
    },
    // ==========================================
    getDetail({ commit, dispatch }, id) {
      commit('setStatusDetail', {
        status: 'loading',
        cancel: null,
        code: null
      });
      let params = new FormData();
      params.append('id', id);
    
      return axios.post('api/v1/single-target', params)
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        let code = resp.response && resp.response.status ? resp.response.status : null
        if (Object.keys(data).length===0) {
          commit('setStatusDetail', {
            status: 'empty',
            cancel: null,
            code: code
          });
        } else {
          commit('setStatusDetail', {
            status: true,
            cancel: null,
            code: code
          });
        }
        commit('setDetail', data);
        return {result: 'success', code: code, data: (resp.response && resp.response.data ? resp.response.data : null)};
      })
      .catch((resp) => {
        // console.log(resp)
        let code = resp.response && resp.response.status ? resp.response.status : null
        commit('setStatusDetail', {
          status: 'failed',
          cancel: null,
          code: code
        });
        let message = resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null
        dispatch('errors/handleApiResponse', [code, message], { root: true });
        
        return {result: 'failed', code: code, data: (resp.response && resp.response.data ? resp.response.data : null)};
      });
    },
    // ==========================================
    getDeletedPhotos({ commit, dispatch }, [id, page=1, limit=8]) {
      commit('setStatusDeletedPhotos', {
        status: 'loading',
        cancel: null,
        code: null
      });
      let params = new FormData();
      params.append('id', id);
      params.append('limit', limit);
      params.append('page', page);
    
      return axios.post('api/v1/single-target/deleted-photos', params)
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        let code = resp.response && resp.response.status ? resp.response.status : null
        if (Object.keys(data).length===0) {
          commit('setStatusDeletedPhotos', {
            status: 'empty',
            cancel: null,
            code: code
          });
        } else {
          commit('setStatusDeletedPhotos', {
            status: true,
            cancel: null,
            code: code
          });
        }
        commit('setDeletedPhotos', data);
        return 'success';
      })
      .catch((resp) => {
        // console.log(resp)
        let code = resp.response && resp.response.status ? resp.response.status : null
        commit('setStatusDeletedPhotos', {
          status: 'failed',
          cancel: null,
          code: code
        });
        let message = resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null
        dispatch('errors/handleApiResponse', [code, message], { root: true });
        return 'failed';
      });
    },

    create({ dispatch }, [
      target_name = null,
      clues = null,
      label = null,
      photo = null,
      cases = null,
      description = null,
      additional_info = null,
      gender = null,
      address = null,
      birth_date = null,
      birth_place = null,
      jobs = null,
      nationality = null,
      religion = null,
      last_education = null,
    ]) {
      let params = new FormData();
      params.append('target_name', target_name);
      params.append('clues', clues);
      params.append('label', label);
      params.append('photo', photo);
      params.append('cases', cases);
      params.append('description', description);
      params.append('additional_info', additional_info);
      params.append('gender', gender);
      params.append('address', address);
      params.append('birth_date', birth_date);
      params.append('birth_place', birth_place);
      params.append('jobs', jobs);
      params.append('nationality', nationality);
      params.append('religion', religion);
      params.append('last_education', last_education);

      return axios.post('api/v1/single-target/create', params)
      .then((resp) => {

        if (resp.status === 200 && resp.data.message === 'Data Successfully Created') {
          return {result: 'success', data: resp.data};
        } else {
          return {result: 'failed', data: resp.data};
        }
      })
      .catch((resp) => {
        // console.log(resp)
        let code = resp.response && resp.response.status ? resp.response.status : null
        let message = resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null
        dispatch('errors/handleApiResponse', [code, message], { root: true });
        return {result: 'failed', code: code, data: (resp.response && resp.response.data ? resp.response.data : null)};
      });
    },

    // multiple photos
    createNew({ dispatch }, [
      target_name = null,
      clues = null,
      label = null,
      photos = null,
      cases = null,
      description = null,
      additional_info = null,
      gender = null,
      address = null,
      birth_date = null,
      birth_place = null,
      jobs = null,
      nationality = null,
      religion = null,
      last_education = null,
    ]) {
      let params = new FormData();
      params.append('target_name', target_name);
      params.append('clues', clues);
      params.append('label', label);
      // params.append('photos', photos);
      params.append('cases', cases);
      params.append('description', description);
      params.append('additional_info', additional_info);
      params.append('gender', gender);
      params.append('address', address);
      params.append('birth_date', birth_date);
      params.append('birth_place', birth_place);
      params.append('jobs', jobs);
      params.append('nationality', nationality);
      params.append('religion', religion);
      params.append('last_education', last_education);
      
      for (let index = 0; index < photos.length; index++) {
        // const element = photos[index];
        // console.log('element')
        // console.log(element)
        params.append('photos', photos[index]);
        
      }
      // console.log('params')
      // console.log(params)
      // console.log(params.get('photos'))
      // return {result: 'failed', data: null};

      return axios.post('api/v1/single-target/create-new', params)
      .then((resp) => {

        if (resp.status === 200 && resp.data.message === 'Data Successfully Created') {
          return {result: 'success', data: resp.data};
        } else {
          return {result: 'failed', data: resp.data};
        }
      })
      .catch((resp) => {
        // console.log(resp)
        let code = resp.response && resp.response.status ? resp.response.status : null
        let message = resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null
        dispatch('errors/handleApiResponse', [code, message], { root: true });
        return {result: 'failed', code: code, data: (resp.response && resp.response.data ? resp.response.data : null)};
      });
    },

    update({ dispatch }, [
      id,
      target_name = null,
      clues = null,
      label = null,
      photo = null,
      cases = null,
      description = null,
      additional_info = null,
      gender = null,
      address = null,
      birth_date = null,
      birth_place = null,
      jobs = null,
      nationality = null,
      religion = null,
      last_education = null,
    ]) {

      let params = new FormData();
      params.append('id', id);
      params.append('target_name', target_name);
      params.append('clues', clues);
      params.append('label', label);
      // params.append('status', status);
      params.append('photo', photo);
      params.append('cases', cases);
      params.append('description', description);
      params.append('additional_info', additional_info);
      params.append('gender', gender);
      params.append('address', address);
      params.append('birth_date', birth_date);
      params.append('birth_place', birth_place);
      params.append('jobs', jobs);
      params.append('nationality', nationality);
      params.append('religion', religion);
      params.append('last_education', last_education);
    
      return axios.post('api/v1/single-target/update', params)
      .then((resp) => {
        // console.log(resp.data)
        
        if (resp.status === 200 && resp.data.message === 'Data Successfully Updated') {
          return {result: 'success', data: resp.data};
        } else {
          return {result: 'failed', data: resp.data};
        }
      })
      .catch((resp) => {
        // console.log(resp)
        let code = resp.response && resp.response.status ? resp.response.status : null
        let message = resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null
        dispatch('errors/handleApiResponse', [code, message], { root: true });
        return {result: 'failed', code: code, data: (resp.response && resp.response.data ? resp.response.data : null)};
      });
    },

    // multiple photos
    updateNew({ dispatch }, [
      id,
      target_name = null,
      clues = null,
      label = null,
      photos = null,
      cases = null,
      description = null,
      additional_info = null,
      gender = null,
      address = null,
      birth_date = null,
      birth_place = null,
      jobs = null,
      nationality = null,
      religion = null,
      last_education = null,
    ]) {

      let params = new FormData();
      params.append('id', id);
      params.append('target_name', target_name);
      params.append('clues', clues);
      params.append('label', label);
      // params.append('status', status);
      // params.append('photos', photos);
      params.append('cases', cases);
      params.append('description', description);
      params.append('additional_info', additional_info);
      params.append('gender', gender);
      params.append('address', address);
      params.append('birth_date', birth_date);
      params.append('birth_place', birth_place);
      params.append('jobs', jobs);
      params.append('nationality', nationality);
      params.append('religion', religion);
      params.append('last_education', last_education);
    
      for (let index = 0; index < photos.length; index++) {
        params.append('photos', photos[index]);        
      }

      return axios.post('api/v1/single-target/update-new', params)
      .then((resp) => {
        // console.log(resp.data)
        
        if (resp.status === 200 && resp.data.message === 'Data Successfully Updated') {
          return {result: 'success', data: resp.data};
        } else {
          return {result: 'failed', data: resp.data};
        }
      })
      .catch((resp) => {
        // console.log(resp)
        let code = resp.response && resp.response.status ? resp.response.status : null
        let message = resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null
        dispatch('errors/handleApiResponse', [code, message], { root: true });
        return {result: 'failed', code: code, data: (resp.response && resp.response.data ? resp.response.data : null)};
      });
    },

    delete({ dispatch }, [id, first=false, force=false]) {

      let params = new FormData();
      params.append('id', id);

      if (first)
        params.append('is_first', 'true');

      if (force)
        params.append('is_force', 'true');

      return axios.post('api/v1/single-target/delete', params)
      .then((resp) => {
        // console.log(resp)
        
        if (resp.status === 200 && resp.data.message === 'Data Successfully Deleted') {
          return 'success';
        } else {
          return 'failed';
        }
      })
      .catch((resp) => {
        // console.log(resp)
        let code = resp.response && resp.response.status ? resp.response.status : null
        let message = resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null
        dispatch('errors/handleApiResponse', [code, message], { root: true });
        return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
      });

    },
    
    // Notes
    async getNotesList({ state, commit, dispatch }, [target_id='', loadMore=false]) {
    // async getNotesList({ state, commit, dispatch }, [target_id='', limit='', page='']) {
      if (state.status_notes_list.cancel !== null && state.status_notes_list.status === 'loading')
        await state.status_notes_list.cancel('Operation canceled by the user.');

      let params = new FormData();

      params.append('target_id', target_id);
      params.append('limit', state.data_pagination.per_page);
      params.append('page', state.data_pagination.next_page_number?state.data_pagination.next_page_number:1);
      // params.append('limit', limit);
      // params.append('page', page);
      // params.append('keyword', keyword);
      // params.append('from_date', from_date);
      // params.append('to_date', to_date);
    
      axios.post('api/v1/target-notes/list', params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelNotesList = c;
          commit('setStatusNotesList', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (Object.keys(data).length===0) {
          result.status = 'empty';
        } else {
          const dataPagination = {
              current_page: parseInt(data.current_page),
              has_next: data.has_next,
              has_previous: data.has_previous,
              next_page_number: parseInt(data.next_page_number),
              per_page: parseInt(data.per_page),
              previous_page_number: parseInt(data.previous_page_number),
              total_data: parseInt(data.total_data),
              total_page: parseInt(data.total_page),
          }
          commit('setDataPagination', dataPagination);

          if(loadMore) {
            data = state.notes_list.concat(data.data);
          } else {
            data = data.data;
          }
        }
        
        commit('setNotesList', data);
        commit('setStatusNotesList', result);        
        commit('setIsLoadingNext', false);
      })
      .catch((resp) => {
        // console.log(resp)
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelNotesList;
        } else {
          commit('setIsLoadingNext', false)
        }

        commit('setStatusNotesList', result);
        if (!axiosOrigin.isCancel(resp)){
          let code = resp.response && resp.response.status ? resp.response.status : null
          let message = resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null
          dispatch('errors/handleApiResponse', [code, message], { root: true });
        }
      });
    },

    createNotes({ dispatch }, [target_id = null, notes = null]) {
      let params = new FormData();
      params.append('target_id', target_id);
      params.append('notes', notes);

      return axios.post('api/v1/target-notes/create', params)
      .then((resp) => {

        if (resp.status === 200) {
          return {result: 'success', data: resp.data};
        } else {
          return {result: 'failed', data: resp.data};
        }
      })
      .catch((resp) => {
        // console.log(resp)
        let code = resp.response && resp.response.status ? resp.response.status : null
        let message = resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null
        dispatch('errors/handleApiResponse', [code, message], { root: true });
        return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
      });
    },

    // Monitoring
    postMonitoring({ dispatch }, [
      target_id = null,
      status    = null,
      msisdn    = null,
      frequency = null,
      time_period = null,
    ]) {
      // console.log('================================= in postMonitoring()')
      let params = new FormData();

      params.append('target_id', target_id);
      params.append('status', status);
      params.append('msisdn', msisdn);
      params.append('frequency', frequency);
      params.append('time_period', time_period);
      
      return axios.post('api/v1/monitoring/single', params)
      .then((resp) => {

        if (resp.status === 200) {
          return {result: 'success', data: resp.data};
        } else {
          return {result: 'failed', data: resp.data};
        }
      })
      .catch((resp) => {
        // console.log(resp)
        let code = resp.response && resp.response.status ? resp.response.status : null
        let message = resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null
        dispatch('errors/handleApiResponse', [code, message], { root: true });
        return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
      });
    },

    async getMonitoringList({ commit, dispatch, state }, [target_id, msisdn='', limit='', page='', keyword='', from_date='', to_date='']) {

      if (state.status_monitoring_list.cancel !== null && state.status_monitoring_list.status === 'loading')
        await state.status_monitoring_list.cancel('Operation canceled by the user.');
  
      let params = new FormData();
  
      params.append('target_id', target_id);
      params.append('msisdn', msisdn);
      params.append('limit', limit);
      params.append('page', page);
      params.append('keyword', keyword);
      params.append('from_date', from_date);
      params.append('to_date', to_date);
      
      axios.post('api/v1/monitoring/single/list', params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelMonitoringList = c;
          commit('setStatusMonitoringList', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setMonitoringList', data);

        if (Object.keys(data).length===0)
          result.status = 'empty';

        commit('setStatusMonitoringList', result);
      })
      .catch((resp) => {
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelMonitoringList;
        }

        commit('setStatusMonitoringList', result);
        if (!axiosOrigin.isCancel(resp)){
          let code = resp.response && resp.response.status ? resp.response.status : null
          let message = resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null
          dispatch('errors/handleApiResponse', [code, message], { root: true });
        }
      });
    },

    async getMonitoringActiveList({ commit, dispatch, state }, [target_id, msisdn='', limit='', page='', keyword='', from_date='', to_date='']) {

      if (state.status_monitoring_active_list.cancel !== null && state.status_monitoring_active_list.status === 'loading')
        await state.status_monitoring_active_list.cancel('Operation canceled by the user.');

      let params = new FormData();
  
      params.append('target_id', target_id);
      params.append('msisdn', msisdn);
      params.append('limit', limit);
      params.append('page', page);
      params.append('keyword', keyword);
      params.append('from_date', from_date);
      params.append('to_date', to_date);
      
      axios.post('api/v1/monitoring/single/list-active', params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelMonitoringActiveList = c;
          commit('setStatusMonitoringActiveList', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setMonitoringActiveList', data);

        if (Object.keys(data).length===0)
          result.status = 'empty';

        commit('setStatusMonitoringActiveList', result);
      })
      .catch((resp) => {
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelMonitoringActiveList;
        }

        commit('setStatusMonitoringActiveList', result);
        if (!axiosOrigin.isCancel(resp)){
        let code = resp.response && resp.response.status ? resp.response.status : null
        let message = resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null
        dispatch('errors/handleApiResponse', [code, message], { root: true });
        }
        
      });
    },
    async getSummary({ commit, dispatch, state }) {

      if(state.status_summary.cancel != null && state.status_summary.status == 'loading')
        await state.status_summary.cancel('Operation canceled by the user.');
      let urlApi = 'api/v1/single-target/summary' 

      axios.get(urlApi, {
        cancelToken: new cancelToken(function executor(c) {

          let loading = {
            status: 'loading',
            cancel: c,
            code: null,
          };

          cancelSummary = c;
          commit('setStatusSummary', loading);
        }),
      })
      .then((resp) => {
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (Object.keys(data).length===0){
          result.status = 'empty';
        }
        commit('setSummary', data);
        commit('setStatusSummary', result);
      })
      .catch((resp) => {
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelSummary;
        }

        
        commit('setStatusSummary', result);
        if (!axiosOrigin.isCancel(resp)){
        let code = resp.response && resp.response.status ? resp.response.status : null
        let message = resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null
        dispatch('errors/handleApiResponse', [code, message], { root: true });
        }

      });
    },
  
    async getExport({ dispatch },[limit=null]) {
      let params = {
        limit: limit,
      };
    
      return axios.get('api/v1/single-target/list', {
        params: params,
      })
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        return data.data
      })
      .catch((resp) => {
        console.log(resp)
        return []
      });
    },
  }
};

export default target;
