import axios from "@/axios-main";
import axiosOrigin from "axios";

let cancelList, cancelSearchList;
var cancelToken = axiosOrigin.CancelToken;

const vehicle = {
  namespaced: true,
  state: {
    module: 'Vehicle',
    // load data vehicle
    status_list: {
      status: false,
      cancel: null,
      code: null
    },
    status_search_list: {
      status: false,
      cancel: null,
      code: null,
    },
    status_detail: false,

    // vehicle
    list: {},
    search_list: {},
    detail: {},

  },
  mutations: {
    // load data vehicle
    setStatusList: (state, status) => {
      state.status_list = status;
    },
    setStatusSearchList: (state, status) => {
      state.status_search_list = status;
    },
    setStatusDetail: (state, status) => {
      state.status_detail = status;
    },

    // vehicle
    setList: (state, data) => {
      state.list = data;
    },
    setSearchList: (state, data) => {
      state.search_list = data;
    },
    setDetail: (state, data) => {
      state.detail = data;
    },
  },
  getters: {},
  actions: {
    async getList({ state, commit, dispatch }, [limit=null, page=null, keyword=null, order_by=null, from_date=null, to_date=null]) {

      if (state.status_list.cancel !== null && state.status_list.status === 'loading')
        await state.status_list.cancel('Operation canceled by the user.');
      
      let params = {
        limit: limit,
        page: page,
        keyword: keyword,
        order_by: order_by,
        from_date: from_date,
        to_date: to_date,
      };
    
      axios.get('api/v1/vehicle/list', {
        params: params,
        cancelToken: new cancelToken(function executor(c) {
          cancelList = c;
          commit('setStatusList', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setList', data);

        if (Object.keys(data).length===0)
          result.status = 'empty';

        commit('setStatusList', result);
      })
      .catch((resp) => {
        // console.log(resp)
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelList;
        }

        commit('setStatusList', result);
        if (!axiosOrigin.isCancel(resp)) 
          dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null), state.module+' List'], { root: true });
      });
    },

    async getSearchList({ state, commit, dispatch }, [limit=null, page=null, keyword=null, order_by=null, from_date=null, to_date=null, is_cached=null, status=null]) {

      if (state.status_search_list.cancel !== null && state.status_search_list.status === 'loading')
        await state.status_search_list.cancel('Operation canceled by the user.');

      let params = {
        limit: limit,
        page: page,
        keyword: keyword,
        order_by: order_by,
        from_date: from_date,
        to_date: to_date,
        is_cached: is_cached,
      };
    
      axios.get('api/v1/vehicle/list', {
        params: params,
        cancelToken: new cancelToken(function executor(c) {
          cancelSearchList = c;
          commit('setStatusSearchList', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setSearchList', data);

        if (Object.keys(data).length===0)
          result.status = 'empty';

        commit('setStatusSearchList', result);
      })
      .catch((resp) => {
        // console.log(resp)
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelSearchList;
        }

        commit('setStatusSearchList', result);
        if (!axiosOrigin.isCancel(resp)) 
          dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null), state.module + ' List'], { root: true });
      });
    },

    getDetail({ commit, dispatch }, id) {

      let params = new FormData();
      params.append('id', id);
    
      return axios.post('api/v1/vehicle', params)
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        commit('setDetail', data);
        return {result: 'success', data: data};
      })
      .catch((resp) => {
        // console.log(resp)
        let code = resp.response && resp.response.status ? resp.response.status : null
        dispatch('errors/handleApiResponse', [code], { root: true });
        return 'failed';
      });
    },

    create({ dispatch }, [plate_number=null]) {
      
      let params = new FormData();

      params.append('plate_number', plate_number);

      return axios.post('api/v1/vehicle/create', params)
      .then((resp) => {

        if (resp.status === 200 && resp.data.message === 'Data Successfully Created') {
          return {result: 'success', data: resp.data};
        } else {
          return {result: 'failed', data: resp.data};
        }
      })
      .catch((resp) => {
        // console.log(resp)
        let code = resp.response && resp.response.status ? resp.response.status : null
        dispatch('errors/handleApiResponse', [code], { root: true });
        return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
      });
    },

    update({ dispatch }, [id, plate_number=null]) {
      let params = new FormData();
      
      params.append('id', id);
      params.append('plate_number', plate_number);
    
      return axios.post('api/v1/vehicle/update', params)
      .then((resp) => {
        // console.log(resp.data)
        
        if (resp.status === 200 && resp.data.message === 'Data Successfully Updated') {
          return {result: 'success', data: resp.data};
        } else {
          return {result: 'failed', data: resp.data};
        }
      })
      .catch((resp) => {
        // console.log(resp)
        let code = resp.response && resp.response.status ? resp.response.status : null
        dispatch('errors/handleApiResponse', [code], { root: true });
        return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
      });
    },

    delete({ commit, dispatch }, id) {

      let params = new FormData();
      params.append('id', id);

      return axios.post('api/v1/vehicle/delete', params)
      .then((resp) => {
        // console.log(resp)
        
        if (resp.status === 200 && resp.data.message === 'Data Successfully Deleted') {
          return 'success';
        } else {
          return 'failed';
        }
      })
      .catch((resp) => {
        // console.log(resp)
        let code = resp.response && resp.response.status ? resp.response.status : null
        dispatch('errors/handleApiResponse', [code], { root: true });
        return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
      });

    },
  }
};

export default vehicle;
