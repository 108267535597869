<template lang="pug">
.multi-tracking-index
    .box__body
        .card.card--table.card--no-bg
            .card__header.card--table__header
                h2.text-xl Lists of Tracking
                .header-cta(v-if="checkActionButton()")
                    .flex.items-center
                        //- .block(v-if="isRemainingQuota")
                        .block(v-if="canMultiMonitoring && checkFeatureByAcl('multi_target', 'daily_quota')")
                            span.btn-default Daily quota used: {{ status_remaining_quota_multitracking.status === 'loading' ? 'loading...' : (remaining_quota_multitracking.today_usage || remaining_quota_multitracking.today_usage == 0 ? remaining_quota_multitracking.today_usage : '-') +'/'+ (remaining_quota_multitracking.total || remaining_quota_multitracking.total == 0 ? remaining_quota_multitracking.total : '-') }}
                        .block(v-if="checkFeatureByAcl('multi_target', 'create')")
                            router-link.btn-primary.m-btn-icon(
                                :to="{ name: 'pageMultiTrackingCreate', params: {} }"
                            )
                                IconSet(type="add")
                                span.m-hide.ml-3 Add Multi Tracking
            .card--table__body
                .box-filter-table.p-4.pt-0
                    .field(class="lg:mr-3 lg:mb-0 lg:w-64 mr-0 w-full mb-4")
                        input.field-style(
                            v-model="keyword"
                            type="text"
                            placeholder="Search here..."
                            @keyup="searchKeyword"
                        )
                        .field-icon
                            IconSet(
                                type="search"
                            )
                    .field(class="lg:mr-3 lg:mb-0 lg:w-32 mr-0 w-full mb-4")
                        select.field-style(@change="sizeChange($event)" v-model="items_per_page")
                            option(v-for="(s,index) in size" :key="index" :value="s") {{ s }}
                        .field-icon
                            IconSet(
                                type="chevron-down"
                            )
                    //- filter
                    .field(v-if="canMultiMonitoring")
                        .ui-checkbox
                            input(type="checkbox" @change="monitoringActiveChange($event)" v-model="monitoring_active" value="true")
                            .style-checkbox
                                .checkbox
                                    IconSet(type="check")
                                span(v-if="status_list.status === 'loading'") Loading...
                                template(v-else)
                                    span(v-if="list.total_monitoring_active && list.total_monitoring_active != 0") Show <strong class="text-red-500"> {{ list.total_monitoring_active }} </strong> Ongoing Monitoring Target
                                    span(v-else) No active monitoring                        

                .box-table.box-table--target
                    table.table
                        thead.m-hide
                            tr
                                th(v-for="(th,index) in tableThead" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}
                        tbody
                            tr(v-if="status_list.status === 'loading'")
                                td.text-center(:colspan="tableThead.length")
                                    .flex.flex-wrap.items-center.justify-center
                                        Spinner(show="true" size="48")                                    
                            template(v-else-if="list.data && list.data.length > 0")
                                tr(v-for="(data,index) in list.data" :key="data.id")
                                    td
                                        router-link.link(:to="'/multi-tracking/'+data.id+'/detail/location-history'") {{ data.group_name ? data.group_name : '-' }}
                                    
                                    td(v-if="data.target && data.target.length > 0")
                                        .list-target
                                            .list-target__avatar(v-for="target in data.target.slice(0, 9)")
                                                figure.w-10.h-10.rounded-full.overflow-hidden(v-tooltip="target.target_name")
                                                    img.w-full.h-full(:src="target.photo ? target.photo : getDefaultImage"
                                                        @error="getDefaultImage")
                                    td(v-else) -
                                    td(v-if="data.cases.length > 0") 
                                        template(v-for="item in data.cases")
                                            .label-dark.whitespace-no-wrap.mr-1 {{data.cases.length > 1? '' : ''}} {{ item.operation_name }}
                                    td(v-else) -
                                    td 
                                        span.text-sm {{ data.description ? (data.description.length > 50 ? data.description.substring(0, 50)+'...' : data.description) : '-' }}
                                    td 
                                        span.text-sm {{ data.updated_at }}
                                    td.m-action-target(v-if="checkActionButton()")
                                        .flex.items-center.justify-end
                                            template(v-if="data.status.toLowerCase()==='stop'")
                                                button.btn-icon-40(
                                                    v-if="canMultiMonitoring"
                                                    @click="monitoringModal(data)" 
                                                    v-tooltip="'Start Monitoring'"
                                                )
                                                    IconSet(type="play")
                                                router-link.btn-icon-40.m-hide(
                                                    v-if="checkFeatureByAcl('multi_target', 'edit')"
                                                    :to="'/multi-tracking/'+data.id+'/edit'"
                                                    v-tooltip="'Edit'"
                                                )
                                                    IconSet(type="edit")
                                                button.btn-icon-40.m-hide(
                                                    v-if="checkFeatureByAcl('multi_target', 'delete')"
                                                    @click="deleteData(data.id, data.group_name)"
                                                    v-tooltip="'Delete'"
                                                )
                                                    IconSet(type="delete")
                                            template(v-else)
                                                button.btn-icon-40.text-red-500(
                                                    v-if="canMultiMonitoring"
                                                    @click="monitoringAction(0,data)"
                                                    v-tooltip="'Stop Monitoring'"
                                                )
                                                    IconSet(type="stop")
                                                button.btn-icon-40.btn-disabled.m-hide(
                                                    v-if="checkFeatureByAcl('multi_target', 'edit')"
                                                )
                                                    IconSet(type="edit")
                                                button.btn-icon-40.btn-disabled.m-hide(
                                                    v-if="checkFeatureByAcl('multi_target', 'delete')"
                                                )
                                                    IconSet(type="delete")
                            tr(v-else)
                                td.text-center(:colspan="tableThead.length") No Data Available

                .box-table-footer(v-if="status_list.status !== 'loading'")
                    .pagination
                        vue-ads-pagination(
                            :total-items="total_data"
                            :items-per-page="items_per_page"
                            :page="page"
                            :max-visible-pages="5"
                        )
                            template(slot="buttons" slot-scope="props")
                                vue-ads-page-button(
                                    v-for="(button, key) in props.buttons"
                                    :key="key"
                                    v-bind="button"
                                    :class="{'bg-yellow-dark': button.active}"
                                    @page-change="page = button.page"
                                    @range-change="start = button.start; end = button.end" 
                                )
    .popup-layer.is-show(
        v-if="monitoringData"
    )
        .popup-container
            .popup-container__header
                .flex.items-center
                    h2.text-xl {{ monitoringData.group_name }}

                button.btn-icon-40(
                    @click="monitoringModal()"
                )
                    IconSet( type="close")

            .popup-container__body
                .mb-6.pb-6.border-b
                    //- .mb-4
                        strong.small-heading Select Target
                    //- .field.mr-8(v-for="data in monitoringData.clues" v-if="data.name === 'msisdn'")
                        .ui-checkbox
                            input(type="checkbox" v-model="monitoringMsisdn" :value="data.value")
                            .style-checkbox
                                .checkbox
                                    IconSet(type="check")
                                span {{data.value}}
                    .field.w-full
                        .small-heading Select Target
                        .fields-box(v-if="monitoringData.target.length > 0")
                            .fields-box__item(
                                v-for="(target, index) in monitoringData.target"
                                :keys="index"
                            )
                                .w-full
                                    .flex.justify-between.w-full
                                        .flex-grow.flex.items-center
                                            .mr-4
                                                .w-3.h-3.rounded-full(:style="'background-color: '+ (target.label ? target.label:'white') +';'")
                                            .mr-4
                                                figure.w-8.h-8.rounded-full.overflow-hidden
                                                    img(:src="target.photo ? target.photo : getDefaultImage" @error="getDefaultImage")
                                            span {{ target.target_name }}
                                    .mt-4
                                        .ui-checkbox.ui-checkbox--small.mr-8(
                                            v-for="(target_msisdn, index) in target.msisdn" 
                                            :key="index"
                                        )
                                            input(
                                                type="checkbox"
                                                v-model="monitoringTarget"
                                                :key="target_msisdn"
                                                :value="'id:'+target.id + ',msisdn:' + target_msisdn"
                                            )
                                            .style-checkbox
                                                .checkbox
                                                    IconSet(type="check")
                                                span {{ target_msisdn }}
                .pb-6(
                    v-if="isMonitoringPeriod"
                    :class="monitoringTime != '' ? 'mb-6 border-b' : ''"
                )
                    .mb-4
                        strong.small-heading MONITORING TIME
                    .grid.grid-cols-3.gap-6
                        .field(v-for="data, key in monitoringPeriod")
                            .ui-radio
                                input(type="radio" name="period_time" v-model="monitoringTime" :value="key")
                                .style-radio
                                    .radio
                                    span {{data.text}}
                .pb-6(v-if="monitoringTime != ''")
                    .mb-4
                        strong.small-heading FREQUENCY MONITORING
                    .grid.grid-cols-3.gap-6
                        .field(v-for="data in freqOpt")
                            .ui-radio
                                input(type="radio" name="frequency" v-model="monitoringFreq" :value="data.value")
                                .style-radio
                                    .radio
                                    span {{data.name}}
                .pb-6(v-if="!isMonitoringPeriod")
                    .mb-4
                        strong.small-heading FREQUENCY MONITORING
                    .grid.grid-cols-3.gap-6
                        .field(v-for="data in freqOpt")
                            .ui-radio
                                input(type="radio" name="frequency" v-model="monitoringFreq" :value="data.value")
                                .style-radio
                                    .radio
                                    span {{data.name}}

            .popup-container__footer
                .flex.justify-end.w-full
                    //- button.btn-primary(type='submit') Start Monitoring
                    //- msisdn sudah dipilih
                    template(v-if="monitoringTarget.length>0 && monitoringFreq!=''")
                        button.btn-primary(type='submit' @click="monitoringAction(1)") Start Monitoring
                    //- msisdn belum dipilih
                    template(v-else)
                        button.btn-primary.btn-disabled(type='submit' disabled) Start Monitoring
                    //- proses loading 
                    //- button.btn-primary.btn-disabled(type='submit' disabled)  Starting Monitoring...
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import IconSet from '@/components/IconSet.vue';
import Spinner from '@/components/Spinner.vue';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
import { encrypt } from '@/util/crypto';
import moment from 'moment';

export default {
    name: 'pageMultiTrackingIndex',
    components: {
        IconSet,
        Spinner,
        VueAdsPagination,
        VueAdsPageButton,
    },
    data() {
        return {
            monitoringData: null,
            monitoringTarget: [],
            monitoringFreq: 'every30minutes',
            freqOpt: this.$store.getters['config/getFreqMonitoring'],
            monitoringOnly: false,
            popupShowMonitoring: false,
            // tableThead: [
            //     {
            //         name: 'Target Group',
            //     },{
            //         name: 'Lists of Target Group',
            //     },{
            //         name: 'Case Name',
            //     },{
            //         name: 'Description',
            //     },{
            //         name: 'Last Updated',
            //     },{
            //         name: '',
            //         action: true,
            //     },
            // ],

            total_data: 0,
            items_per_page: 10,

            size: [10, 25, 50, 100],

            page: 0,
            start: 0,
            end: 0,

            keyword: '',
            timeout: null,
            monitoring_active: '',
            monitoringTime:'',

            defaultImage: this.$store.getters['config/getDefaultImageProfile'],
        }
    },
    computed: {
        ...mapState('dashboard', [
            'status_remaining_quota_multitracking',
            'remaining_quota_multitracking',
        ]),
        ...mapState('multiTracking', [
            'status_list',
            'list',
        ]),
        ...mapState('auth', ['user']),
        ...mapGetters({
            isRemainingQuota: 'config/getIsRemainingQuota',
            isMonitoringPeriod: 'config/getIsMultiMonitoringPeriod',
            monitoringPeriod: 'config/getMonitoringPeriod',
        }),
        route_name() {
            return this.$route.name;
        },
        tableThead() {
            let tableThead = [
                {
                    name: 'Target Group',
                },{
                    name: 'Lists of Target Group',
                },{
                    name: 'Case Name',
                },{
                    name: 'Description',
                },{
                    name: 'Last Updated',
                },
            ];
            if (this.checkActionButton()) {
                tableThead.push({
                    name: 'Action',
                    action: true,
                });
            }

            return tableThead;
        },
        canMultiMonitoring(){
            return this.checkFeatureByAcl('multi_monitoring', 'post')
        },
    },
    watch: {
        route_name(newData, oldData) {
            if (newData === '') {
                this.getData();
            }

            if (newData === 'pageMultiTrackingIndex') {
                if (oldData !== 'pageMultiTrackingIndex') {
                    this.items_per_page = 10;
                    this.page = 0;
                    this.keyword = '';
                    this.$store.commit('multiTracking/setList', {});
                }

                this.getData();
            }
        },
        tableThead() {},
        list(newData) {
            if (Object.keys(newData).length > 0) {
                this.total_data = parseInt(newData.total_data);
                // this.page = (parseInt(newData.current_page) - 1);
            } else {
                this.total_data = 0;
            }
        },
        total_data() {
            // this.pagination();
        },
        items_per_page() {
            this.getData();
            // this.pagination();
        },
        page() {
            this.getData();
        },
        monitoring_active() {
            this.getData();
        },
        keyword() {},
        monitoringTime(newData) {
            this.monitoringFreq = ''
            this.freqOpt = this.monitoringPeriod[newData].options
        },
    },
    methods: {
        checkActionButton() {
            return (Object.keys(this.user).length > 0 && this.user.role && this.user.role.label != 'analyst')
        },
        checkMsisdn(data) {
            var msisdn = data.clues.filter(function (clue) {
                return clue.name === 'msisdn'
            })
            return msisdn
        },
        monitoringAction(status=1,data=null) {
            if(data==null){
                data=this.monitoringData
            }
            // console.log(this.monitoringTarget.length)
            var confirm = true
            let target_checked = this.monitoringTarget
            var title = 'Are you sure you want to start monitoring ?'
            if (status === 1) {
                // console.log('masuk start')
                if (target_checked.length === 0) {
                    // console.log('masuk 0')
                    confirm = false
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'Please select min 1 MSISDN!',
                    });
                } else {
                    target_checked = Object.keys(target_checked).map((key) => JSON.parse('{' + target_checked[key].replace(/(\w+):(\w+)/g, `"$1":"$2"`) + '}'));
                }
            } else {
                title = 'Are you sure you want to stop monitoring?'
            }
            // console.log('target_checked')
            // console.log(target_checked)
            if(confirm) {
                this.$swal.fire({
                    icon: 'warning',
                    title: title,
                    text: 'Multi Tracking : ' + data.group_name,
                    confirmButtonText: 'Yes!',
                    confirmButtonColor: '#DD6B55',
                    showCancelButton: true,
                    allowOutsideClick: false,
                })
                .then((result) => {
                    // console.log('on result');

                    if (result.isConfirmed) {
                        // console.log(result.value);

                        EventEmit.$emit('showLoading', true);

                        this.$store.dispatch('multiTracking/postMonitoring', [data.id, status, [JSON.stringify(target_checked)], this.monitoringFreq, this.monitoringTime])
                        .then((resp) => {
                            // console.log('postMonitoring');
                            // console.log(resp);
                            if (resp.result === 'success') {
                                this.page = 0;
                                this.monitoringModal();

                                this.getData();

                                this.$swal.fire({
                                    icon: 'success',
                                    title: resp.data.message,
                                    timer: 3000,
                                });
                            } else {
                                if (resp.data.message) {
                                    EventEmit.$emit('error', resp.data.message);
                                } else {
                                    EventEmit.$emit('error');
                                }
                            }
                        });
                    }
                });
            }
        },
        monitoringModal(item=null) {
            this.monitoringTarget= []
            
            if (this.isMonitoringPeriod) {
                this.monitoringFreq= ''
            } else {
                this.monitoringFreq= 'every30minutes'
            }

            if (item) {            
                // hide scroll bar if true
                EventEmit.$emit('overflowHidden', true);
                this.monitoringData = item
                this.popupShowMonitoring = !this.popupShowMonitoring
            } else {            
                EventEmit.$emit('overflowHidden', false);
                this.monitoringData = item
                this.popupShowMonitoring = !this.popupShowMonitoring
            }
        },

        toString(array) {
            if (array && array.length > 0) {            
                return Object.keys(array).map(function(k){
                    return array[k].operation_name
                }).join(", ")
            } else {
                return '-'
            }
        },
        countClues(array) {
            var ret = {}
            if (this.list.data && this.list.data.length > 0) {
                for (let i in array) {
                    let key = array[i].name
                    ret[key] = {
                        name: key,
                        count: ret[key] && ret[key].count ? ret[key].count + 1 : 1
                    }
                }
                return Object.values(ret)
            }
            return Object.values(ret)
        },
        getData() {
            this.$store.commit('multiTracking/setRedirect', 'index');
            this.$store.dispatch('multiTracking/getList', [this.items_per_page, (this.page + 1), this.keyword, null, null, null, this.monitoring_active]);

            // if (this.isRemainingQuota)
            if (this.canMultiMonitoring) {
                this.$store.dispatch('dashboard/getRemainingQuota', 'multitracking');
            }
        },
        deleteData(id, name) {
            // console.log(id)
            this.$swal.fire({
                icon: 'warning',
                // title: 'Are you sure you want to delete data?',
                title: 'Are you sure you want to delete?',
                // text: id,
                // text: 'Multi Tracking : ' + name,
                text: 'Group Name : ' + name,
                confirmButtonText: 'Yes!',
                confirmButtonColor: '#DD6B55',
                showCancelButton: true,
                allowOutsideClick: false,
            })
            .then((result) => {
                // console.log('on result');

                if (result.isConfirmed) {
                    // console.log(result.value);

                    EventEmit.$emit('showLoading', true);

                    this.$store.dispatch('multiTracking/delete', [id])
                    .then((resp) => {
                        if (resp === 'success') {
                            this.page = 0;

                            this.getData();

                            this.$swal.fire({
                                icon: 'success',
                                title: 'Data successfully deleted!',
                                timer: 3000,
                            });
                        } else {
                            if (resp.data.message) {
                                EventEmit.$emit('error', resp.data.message);
                            } else {
                                EventEmit.$emit('error');
                            }
                        }
                    });
                }
            });
        },
        pageChange(page) {
            this.page = page;
        },
        rangeChange(start, end) {
            this.start = start;
            this.end = end;
        },
        sizeChange() {
            this.page = 0;
        },
        pagination() {
            // update fontawesome to iconfont
            var paginationButtonPreview = document.getElementsByClassName("fa-angle-left");

            if (paginationButtonPreview[0]) {
                var classListPrev = paginationButtonPreview[0].classList;

                if (Object.values(classListPrev).filter(key => ['iconfont'].includes(key)).length === 0) {
                    paginationButtonPreview[0].classList.add('iconfont');
                    paginationButtonPreview[0].classList.add('iconleft');
                    paginationButtonPreview[0].style.fontSize = '12px';
                }
            }

            var paginationButtonNext = document.getElementsByClassName("fa-angle-right");

            if (paginationButtonNext[0]) {
                var classListNext = paginationButtonNext[0].classList;

                if (Object.values(classListNext).filter(key => ['iconfont'].includes(key)).length === 0) {
                    paginationButtonNext[0].classList.add('iconfont');
                    paginationButtonNext[0].classList.add('iconright');
                    paginationButtonNext[0].style.fontSize = '12px';
                }
            }
        },
        searchKeyword() {
            // clear timeout variable
            clearTimeout(this.timeout);
            
            var self = this;
            this.timeout = setTimeout(function () {
                // enter this block of code after 1 second
                // handle stuff, call search API etc.
                self.page = 0;
                self.$store.commit('multiTracking/setList', {});

                // if (self.keyword.length > 0) {
                    self.getData();
                // }
            }, 650);
        },
        getDefaultImage(e) {
            e.target.src = this.defaultImage;
        },
    },
    mounted() {
        // console.log('=================== mounted on case-management index')
        if (this.route_name === 'pageMultiTrackingIndex') {
            this.$store.commit('multiTracking/setDetail', {});
            this.getData();
            // this.pagination();
            if (this.isMonitoringPeriod) {
                this.monitoringFreq=''
            }
        }

    },
}
</script>

<style lang="sass" scoped>
    .list-target
        @apply .flex .items-center
        &__avatar
            margin-left: -8px
            figure
                border: 4px solid $color-secondary-500
            &::first-child
                margin-left: 0px
    .fields-box
        @apply .rounded
        background-color: $color-secondary-600
        &__item
            @apply .p-4 .rounded .flex .border-b
            &:last-child
                border-bottom: 0px
            .field-options
                input
                    margin-top: 1rem
                    &:first-child
                        margin-top: 0px
    .targets-case
        @apply .w-full
        .target-item
            @apply .flex .items-center .p-3 .w-full .rounded .cursor-pointer
            transition: all 0.3s ease-in-out
            &:hover
                background-color: $color-secondary-800

</style>
