<template lang="pug">
.target-index
    .box__body
        .card.card--table.card--no-bg
            .card__header.card--table__header
                h2.text-xl Summary Targets &amp; MSISDN
            template(v-if="status_summary.status === 'loading'")
                Loading(show="true")
            template(v-else)
                template(v-if="summary && summary.metadata && summary.metadata.length > 0")
                    .card--table__body(v-if="user.role.label == 'operator'")
                        .grid.grid-cols-4.gap-10.p-4
                            .card
                                h2.text-3xl.block.mb-1 {{summary.metadata[0].grand_total_target || 0}}
                                span Targets
                            
                            .card
                                h2.text-3xl.block.mb-1 {{summary.metadata[0].grand_total_msisdn || 0}}
                                span MSISDN  

                    .card--table__body(v-else)
                        .grid.grid-cols-4.gap-10.p-4
                            .card
                                h2.text-3xl.block.mb-1 {{summary.metadata[0].grand_total_target || 0}}
                                span Targets
                            
                            .card
                                h2.text-3xl.block.mb-1 {{summary.metadata[0].grand_total_msisdn || 0}}
                                span MSISDN  
                        .box-table.box-table--target
                            table.table
                                thead.m-hide
                                    tr
                                        th User
                                        th Total Targets
                                        th Total MSISDN
                                tbody
                                    tr(v-for="item in summary.data")
                                        td {{ item.user && item.user.name?item.user.name:'-' }}
                                        td {{ item.total_target}}
                                        td {{ item.total_msisdn}}
                .block(v-else class="w-full lg:w-12/12")
                    NoDataAvailable
</template>

<script>
import { mapState } from 'vuex';
import Loading from '@/components/Loading.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';

export default {
    name: 'pageTargetSummary',
    components: {
        Loading,
        NoDataAvailable,    
    },
    data() {
        return {
        }
    },
    computed: {
        ...mapState('target', [
            'status_summary',
            'summary',
        ]),
        ...mapState('auth', ['user']),
        route_name() {
            return this.$route.name;
        },
    },
    watch: {
        route_name(newData, oldData) {
            if (newData === 'pageTargetSummary') {
                if (oldData !== 'pageTargetSummary') {
                    this.$store.commit('target/setSummary', {});
                }

                this.getData();
            }
        }
    },
    methods: {
        getData() {
            // for dispatch get data
            console.log(this.route_name)
            if (this.route_name === 'pageTargetSummary') {
                this.$store.dispatch('target/getSummary');
            }
        },
    },
    mounted() {
        this.getData();
    },
}
</script>