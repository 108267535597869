<template lang="pug">
.case-management-shell
    .box
        .box__header
            TitlePage(
                title="Digital Cyber Data"
                :breadcrumb="breadcrumb"
                )
        .box__body
            router-view

</template>

<script>
import TitlePage from '@/components/shell/TitlePage.vue'
export default {
    name: 'DcdShell',
    components: {
        TitlePage,
    },
    data() {
        return {
        }
    },
    computed: {
        route() {
            return this.$route;
        },
        id() {
            return this.$route.params.id;
        },
        source() {
            return this.$route.params.source;
        },
        breadcrumb() {
            let breadcrumb = [
                {
                    name: 'Digital Cyber Data',
                    url: '/dcd'
                }
            ];
        
            if (this.route.matched[0] && this.route.matched[0].components.default.name === 'DcdShell' && this.route.matched[1]) {
                switch(this.route.matched[1].name) {
                    case 'pageDcdCreate':
                        breadcrumb.push({
                            name: 'Add DCD',
                            url: '/dcd/create',
                        });
                        break;

                    case 'pageDcdEdit':
                        breadcrumb.push({
                            name: 'Edit DCD',
                            url: '/dcd/'+this.id+'/edit',
                        });
                        break;

                    case 'pageDcdDetail':
                        breadcrumb.push({
                            name: 'Detail DCD',
                            url: '/dcd/'+this.id+'/detail/'+this.source,
                        });
                        break;

                    default:
                        // code block
                }
            }

            return breadcrumb;
        },
    },
    watch: {
        route(newData) {
            if (newData.matched[0] && newData.matched[0].components.default.name === 'DcdShell')
                window.scrollTo(0,0);
        },
        id() {},
        breadcrumb() {},
    },
    created() {
        if (this.$route.params.id && this.$route.params.id === 'create')
            this.$router.push({ name: 'pageDcdCreate', params: {} });
    },
    mounted() {
        // 
    },
}
</script>