import axiosOrigin from "axios";
import { env } from '@/config/env';
var springbootUrl = env.VUE_APP_CONF_SERVICE_URL?env.VUE_APP_CONF_SERVICE_URL:"https://spring-boot-dev.notitiaopportuna.xyz/";
var confName = env.VUE_APP_CONF_SERVICE_NAME?env.VUE_APP_CONF_SERVICE_NAME:"toronto";
var env_data = env.VUE_APP_ENV?env.VUE_APP_ENV:'develop';

var defaultHeaders = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Accept': 'application/json',
};
// let cancelList;
// var cancelToken = axiosOrigin.CancelToken;

const springbootConf = {
    namespaced: true,
    state: {
        // module
        status: false,
        max_msisdn: 8,
    },
    mutations: {
        // module
        setStatus: (state, status) => {
            state.status = status;
        },
        setMaxMSISDN: (state, data) => {
            state.max_msisdn = data;
        },
    },
    getters: {},
    actions: {
        getMaxMSISDN({commit,state, rootState}) {
            let clientLabel = ''
            try {
                clientLabel = rootState.auth.user.client.label
            } catch (e) {
                console.log('error get client label')
                console.log(e)
            }
            return axiosOrigin.get(springbootUrl + confName + '/bff/' + env_data, {
                headers: defaultHeaders,
            })
            .then((resp) => {
                let data = state.max_msisdn
                // console.log(resp.data.propertySources[0].source)
                try {
                    data = resp.data.propertySources[0].source['tracking_'+clientLabel+'_multi.max_msisdn'];
                } catch (error) {
                    console.log('error conf client')
                    try {
                        data = resp.data.propertySources[0].source['tracking_multi.max_msisdn'];
                    } catch (error) {
                        console.log('error conf global')
                    }
                }
                commit('setMaxMSISDN', data);
            })
            .catch((resp) => {
                console.log('error springboot')
                console.log(resp)
            });
        }
    }
};

export default springbootConf;
