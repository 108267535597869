import { decrypt } from '@/util/crypto';
import { env } from '@/config/env';

const config = {
	// apiKey: env.VUE_APP_FCM_API_KEY,
	// authDomain: env.VUE_APP_FCM_AUTH_DOMAIN,
	// databaseURL: env.VUE_APP_FCM_DATABASE_URL,
	// projectId: env.VUE_APP_FCM_PROJECT_ID,
	// storageBucket: env.VUE_APP_FCM_STORAGE_BUCKET,
	// messagingSenderId: env.VUE_APP_FCM_SENDER_ID,
	// appId: env.VUE_APP_FCM_APP_ID,
	// measurementId: env.VUE_APP_FCM_MEASUREMENT_ID,

	TOPIC: env.VUE_APP_FCM_TOPIC ? decrypt(env.VUE_APP_FCM_TOPIC, false, true) : '',
	SERVER_KEY: env.VUE_APP_FCM_SERVER_KEY ? decrypt(env.VUE_APP_FCM_SERVER_KEY, false, true) : '',
	SENDER_ID: env.VUE_APP_FCM_SENDER_ID ? decrypt(env.VUE_APP_FCM_SENDER_ID, false, true) : '',
	PUBLIC_VAPID_KEY: env.VUE_APP_FCM_PUBLIC_VAPID_KEY ? decrypt(env.VUE_APP_FCM_PUBLIC_VAPID_KEY, false, true) : '',
	API_KEY: env.VUE_APP_FCM_API_KEY ? decrypt(env.VUE_APP_FCM_API_KEY, false, true) : '',
	AUTH_DOMAIN: env.VUE_APP_FCM_AUTH_DOMAIN ? decrypt(env.VUE_APP_FCM_AUTH_DOMAIN, false, true) : '',
	DATABASE_URL: env.VUE_APP_FCM_DATABASE_URL ? decrypt(env.VUE_APP_FCM_DATABASE_URL, false, true) : '',
	PROJECT_ID: env.VUE_APP_FCM_PROJECT_ID ? decrypt(env.VUE_APP_FCM_PROJECT_ID, false, true) : '',
	STORAGE_BUCKET: env.VUE_APP_FCM_STORAGE_BUCKET ? decrypt(env.VUE_APP_FCM_STORAGE_BUCKET, false, true) : '',
	APP_ID: env.VUE_APP_FCM_APP_ID ? decrypt(env.VUE_APP_FCM_APP_ID, false, true) : '',
	MEASUREMENT_ID: env.VUE_APP_FCM_MEASUREMENT_ID ? decrypt(env.VUE_APP_FCM_MEASUREMENT_ID, false, true) : '',
	SDK_LIBRARY: env.VUE_APP_FCM_SDK_LIBRARY ? decrypt(env.VUE_APP_FCM_SDK_LIBRARY, false, true) : '',
	FCM_LIBRARY: env.VUE_APP_FCM_FCM_LIBRARY ? decrypt(env.VUE_APP_FCM_FCM_LIBRARY, false, true) : '',
};

export {
  config
}

export default {
  install (Vue) {
    Vue.fcmConfig = config
    Vue.prototype.$fcmConfig = config
  }
}