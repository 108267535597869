<template lang="pug">
.box__body
    .card.card--table.card--no-bg
        .card__header.card--table__header
            h2.text-xl {{ page_title }}
            .header-cta
                .block
                    router-link.btn-default.m-btn-icon(to="/users")
                        IconSet(type="chevron-left")
                        span.m-hide.block.ml-3 Back
        .card__body
            form.container-form
                .group-fields.border-b
                    .field
                        .field__label
                            label.block.text-sm.font-bold Name <sup>*</sup>
                        .field__form
                            input.field-style(type="text" placeholder="Write name here" v-model="model.name" name="name")
                            .pt-3
                                .message.text-xs.text-red-500(v-if="$v.model.name.$error") This name is required &amp; max length is 255 char.
                                .message.text-xs.text-red-500(v-if="error_name !== ''") {{ error_name }}

                    .field
                        .field__label
                            label.block.text-sm.font-bold Email <sup>*</sup>
                        .field__form
                            input.field-style(type="email" placeholder="Write email here" v-model="model.email" name="email")
                            .pt-3
                                .message.text-xs.text-red-500(v-if="$v.model.email.$error") This email is required, max length is 255 char, &amp; must an email format.
                                .message.text-xs.text-red-500(v-if="error_email !== ''") {{ error_email }}

                    .field
                        .field__label
                            label.block.text-sm.font-bold Phone
                        .field__form
                            input.field-style(type="number" placeholder="Write phone here" v-model="model.phone" name="phone")
                            .pt-3
                                .message.text-xs.text-red-500(v-if="$v.model.phone.$error") This phone max length is 255 char.
                                .message.text-xs.text-red-500(v-if="error_phone !== ''") {{ error_phone }}
                    .field(v-if="isSuperadmin || show_web_device_id")
                        .field__label
                            label.block.text-sm.font-bold Web Device ID
                        .field__form
                            input.field-style(type="text" placeholder="Write web device id here" v-model="model.web_device_id" name="web_device_id")
                            .pt-3
                                .message.text-xs.text-red-500(v-if="$v.model.web_device_id.$error") This web device id max length is 255 char.
                                .message.text-xs.text-red-500(v-if="error_web_device_id !== ''") {{ error_web_device_id }}
                    .field
                        .field__label
                            label.block.text-sm.font-bold Role <sup>*</sup>
                        .field__form
                            .relative
                                .field-style.field-style--mirroring.field-style--loading(v-if="status_list.status === 'loading'")
                                    Spinner(show="true" size="32")

                                template(v-if="isSuperadmin || route_name === 'pageUsersCreate'")
                                    select.select-role.field-style(
                                        v-model="model.role_id"
                                    )
                                        option(value="") - Select Role -
                                        template(v-if="list.data && list.data.length > 0")
                                            option(v-for="(role,index) in filteredList" :key="role.id" :value="role.id") {{ role.name }}
                                    .field-icon
                                        IconSet(type="chevron-down")
                                template(v-else)
                                    input.field-style(disabled type="text" :value="detail.role.label" name="label")

                            .pt-3
                                .message.text-xs.text-red-500(v-if="$v.model.role_id.$error") This role is required.
                                .message.text-xs.text-red-500(v-if="error_role_id !== ''") {{ error_role_id }}

                    .field.field--radio.mb-4
                        .field__label
                            label.block.text-sm.font-bold Status <sup>*</sup>
                        .field__form
                            .ui-radio.mr-4
                                input(type="radio" name="status" value="1" v-model="model.status")
                                .style-radio
                                    .radio
                                    strong Active
                            .ui-radio
                                input(type="radio" name="status" value="0" v-model="model.status")
                                .style-radio
                                    .radio
                                    strong Inactive
                            .pt-3
                                .message.text-xs.text-red-500(v-if="$v.model.status.$error") This status is required.
                                .message.text-xs.text-red-500(v-if="error_status !== ''") {{ error_status }}

                .group-fields.border-b
                    .field
                        .field__label
                            label.block.text-sm.font-bold Password <sup>{{ route_name === 'pageUsersCreate' ? '*' : '' }}</sup>
                        .field__form
                            input.field-style(type="password" placeholder="Write password here" v-model="model.password" name="password")
                            .pt-3
                                .message.text-xs.text-red-500(v-if="$v.model.password.$error") This password minimum 6 characters{{ route_name === 'pageUsersCreate' ? ', required, &amp; ' : ' &amp;'}} max length is 255 char.
                                .message.text-xs.text-red-500(v-if="error_password !== ''") {{ error_password }}

                    .field
                        .field__label
                            label.block.text-sm.font-bold Password Confirmation <sup>{{ route_name === 'pageUsersCreate' ? '*' : '' }}</sup>
                        .field__form
                            input.field-style(type="password" placeholder="Write password confirmation here" v-model="model.password_confirmation" name="password_confirmation")
                            .pt-3
                                .message.text-xs.text-red-500(v-if="$v.model.password_confirmation.$error") This password confirmation must be same as password.
                                .message.text-xs.text-red-500(v-if="error_password_confirmation !== ''") {{ error_password_confirmation }}

                .flex.items-center.justify-end.mb-8
                    button.btn.btn-default(type="button" @click="reset()")
                        | Reset
                    button.btn-primary-o.ml-2(type="submit" @click="submit($event, true)")
                        | Save &amp; Continue
                    button.btn.btn-primary.ml-4(type="submit" @click="submit($event)")
                        | Save

</template>

<script>
import { mapState } from 'vuex';
import IconSet from '@/components/IconSet.vue';
import Spinner from '@/components/Spinner.vue';
import { required, requiredIf, minLength, maxLength, email, sameAs } from "vuelidate/lib/validators";
import { env } from '@/config/env';

export default {
    name: 'UsersPageForm',
    components: {
        IconSet,
        Spinner
    },
    data() {
        return {
            model: {
                // photo: '',
                name: '',
                email: '',
                phone: '',
                web_device_id: '',
                role_id: '',
                status: '',
                password: '',
                password_confirmation: '',
            },


            // // error field
            // error_photo: '',
            error_name: '',
            error_email: '',
            error_phone: '',
            error_web_device_id: '',
            error_role_id: '',
            error_status: '',
            error_password: '',
            error_password_confirmation: '',

            // ImgAvatar: this.$store.getters.getDefaultImage,
            show_web_device_id: env.USERS__FORM__SHOW_WEB_DEVICE_ID || false
        }
    },
    validations: {
        model: {
            name: {
                required,
                maxLength: maxLength(255)
            },
            email: {
                required,
                maxLength: maxLength(255),
                email
            },
            phone: {
                maxLength: maxLength(255)
            },
            web_device_id: {
                maxLength: maxLength(255)
            },
            role_id: {
                required,
            },
            status: {
                required,
            },
            password: {
                required: requiredIf(function() {
                    return this.route_name === 'pageUsersCreate';
                }),
                minLength: minLength(6),
                // goodPassword: (password) => { //a custom validator!
                //     return password.length >= 6
                //     // /[a-z]/.test(password) &&
                //     // /[A-Z]/.test(password) &&
                //     // /[0-9]/.test(password)
                // },
            },
            password_confirmation: {
                required: requiredIf(function() {
                    return this.route_name === 'pageUsersCreate';
                }),
                minLength: minLength(6),
                sameAsPassword: sameAs('password')
            },
        },
    },
    computed: {
        ...mapState('auth', ['user']),
        ...mapState('users', ['detail']),
        ...mapState('roles', [
            'status_list',
            'list',
        ]),
        route_name() {
            return this.$route.name;
        },
        id() {
            return this.$route.params.id;
        },
        page_title() {
            let title = 'Edit User';
            if (this.route_name === 'pageUsersCreate')
                title = 'Add User';
            return title;
        },
        filteredList() {
            if (this.list.data && this.list.data.length > 0) {
                return this.list.data.filter(role => {
                    return ('superadmin' != role.label)
                });
            }
            return []
        },
        isSuperadmin(){
            return this.user && this.user.role ? this.user.role.label === 'superadmin' : false
        }
    },
    watch: {
        route_name() {
            this.getData();
        },
        id() {},
        page_title() {
            let title = 'Edit User';
            if (this.route_name === 'pageUsersCreate')
                title = 'Add User';
            return title;
        },
        // form data
        detail(newData) {
            // this.model.photo = (newData.photo ? newData.photo : '');
            this.model.name = (newData.name ? newData.name : '');
            this.model.email = (newData.email ? newData.email : '');
            this.model.phone = (newData.phone ? newData.phone : '');
            this.model.web_device_id = (newData.web_device_id ? newData.web_device_id : '');
            this.model.role_id = (newData.role && newData.role.id ? newData.role.id : '');
            this.model.status = (newData.status ? (newData.status.toLowerCase() === 'active' ? '1' : '0') : '');
            // this.password = (newData.password ? newData.password : '');
            this.model.password = '';
            this.model.password_confirmation = '';
        },
        // status_list(newData) {
        //     if (newData.status === 'loading') {
        //         EventEmit.$emit('showLoading', true);
        //     } else {
        //         EventEmit.$emit('showLoading', false);
        //     }

        // },
    },
    methods: {
        setEmptyModel(all=true) {
            for (let key in Object.keys(this.model)) {
                if (all === true)
                    this.model[Object.keys(this.model)[key]] = '';
            }
        },
        setErrors(empty=true, errors=null) {
            if (empty === true) {
                this.error_name = '';
                this.error_email = '';
                this.error_phone = '';
                this.error_web_device_id = '';
                this.error_role_id = '';
                this.error_status = '';
                this.error_password = '';
                this.error_password_confirmation = '';
            } else if (empty !== true && errors !== null) {
                this.error_name = (errors.name ? errors.name : '');
                this.error_email = (errors.email ? errors.email : '');
                this.error_phone = (errors.phone ? errors.phone : '');
                this.error_web_device_id = (errors.web_device_id ? errors.web_device_id : '');
                this.error_role_id = (errors.role_id ? errors.role_id : '');
                this.error_status = (errors.status ? errors.status : '');
                this.error_password = (errors.password ? errors.password : '');
                // this.error_password_confirmation = (errors.password_confirmation ? errors.password_confirmation : '');
            }
        },
        getData() {
            this.reset(true);

            if (this.route_name === 'pageUsersEdit' || this.route_name === 'pageUsersCreate')
                this.$store.dispatch('roles/getList', [100, 1, '', '', '', '', this.user.client.id]);

            if (this.route_name === 'pageUsersEdit') {
                EventEmit.$emit('showLoading', true);

                this.$store.dispatch('users/getDetail', this.id)
                .then((resp) => {
                    if (resp === 'success') {
                        EventEmit.$emit('showLoading', false);
                    } else {
                        EventEmit.$emit('error');
                    }
                });
            } else {
                if (this.route_name === 'pageUsersCreate')
                    this.$store.commit('users/setDetail', {});
            }
        },
        reset(direct=false) {
            if (direct === true) {
                this.setEmptyModel(false);
                // reset vuelidate
                this.$nextTick(() => { this.$v.$reset(); });
                this.setErrors(true);
                window.scrollTo(0,0);
            } else {
                this.$swal.fire({
                    icon: 'warning',
                    title: 'Are you sure you want to reset form?',
                    // text: id,
                    confirmButtonText: 'Yes!',
                    confirmButtonColor: '#DD6B55',
                    showCancelButton: true,
                    allowOutsideClick: false,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        if (this.route_name === 'pageUsersCreate') {
                            this.setEmptyModel(false);
                            // reset vuelidate
                            this.$nextTick(() => { this.$v.$reset(); });
                            this.setErrors(true);
                            window.scrollTo(0,0);
                        } else {
                            this.getData();
                        }
                    }
                });
            }
        },
        submit(e, save_continue=false) {
            e.preventDefault();
            this.$v.$touch();

            if (this.$v.model.$invalid) {
                for (let key in Object.keys(this.$v.model)) {
                    const input = Object.keys(this.$v.model)[key];
                    if (input.includes("$")) return false;
                    if (this.$v.model[input].$error) {
                        break;
                    }
                }
                // EventEmit.$emit('error', 'Please fill the form correctly.');
                // EventEmit.$emit('showLoading', false);
                window.scrollTo(0,0);
                return;
            }

            EventEmit.$emit('showLoading', true);
            let model_data = Object.keys(this.model).filter((item) => { return item !== 'password_confirmation'; }).map((key) => this.model[key]);

            if (this.route_name === 'pageUsersCreate') {

                this.$store.dispatch('users/create', model_data)
                .then((resp) => {
                    if (resp.result === 'success') {
                        if (save_continue === false) {
                            this.$router.push('/users');
                        } else {
                            this.$router.push('/users/'+ resp.data.data.id +'/edit');
                        }

                        this.$swal.fire({
                            icon: 'success',
                            title: 'Data successfully created!',
                            timer: 3000,
                        });
                    } else {
                        if (resp.data.message && resp.data.message.errors) {
                            this.setErrors(false, resp.data.message.errors);
                            // EventEmit.$emit('error', null, resp.data.message.errors);
                            EventEmit.$emit('showLoading', false);
                            window.scrollTo(0,0);
                        } else {
                            EventEmit.$emit('error');
                        }
                    }
                });

            // pageUsersEdit
            } else {

                model_data = [this.id].concat(model_data);

                this.$store.dispatch('users/update', model_data)
                .then((resp) => {
                    if (resp.result === 'success') {                        
                        if (save_continue === false) {
                            this.$router.push('/users');
                        } else {
                            this.model.password = '';
                            this.model.password_confirmation = '';
                        }

                        this.$swal.fire({
                            icon: 'success',
                            title: 'Data successfully updated!',
                            timer: 3000,
                        });
                    } else {
                        if (resp.data.message && resp.data.message.errors) {
                            this.setErrors(false, resp.data.message.errors);
                            // EventEmit.$emit('error', null, resp.data.message.errors);
                            EventEmit.$emit('showLoading', false);
                            window.scrollTo(0,0);
                        } else {
                            EventEmit.$emit('error');
                        }
                    }
                });
            }
        },
    },
    created() {
        // 
    },
    mounted() {
        this.getData();
    },
}
</script>

<style lang="sass">

// input[readonly]
input.readonly
    opacity: 0.4
    background: #e2e8f0

</style>
