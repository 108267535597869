<template lang="pug">
.target-shell
    .box
        .box__header
            TitlePage(
                title="Tracking History"
                :breadcrumb="breadcrumb"
                )
        .box__body
            .card.card--table.card--no-bg
                .card__header.card--table__header
                    h2.text-xl Tracking History List
                    .header-cta
                        .block
                            button.btn-primary.m-btn-icon
                                IconSet(type="download")
                                span.m-hide.ml-3
                                JsonCSV(
                                    :data="csvData"
                                    :name="csvName"
                                )
                                    | Download CSV
                .card--table__body
                    .box-filter.px-4.pt-6
                        //- showing data information mobile
                        .m-only
                            .filter-info
                                .flex.items-center.justify-between.mb-3
                                    Strong.uppercase.text-xs.font-bold Filtered by
                                    button.btn-default-o.btn-small(
                                        type='button'
                                        v-on:click="showFilter = !showFilter"
                                        ) Change Filter
                                p {{ dateRange.startDate }} -  {{ dateRange.endDate }}, 10 entries

                        //- end showing data information mobile
                        .mobile-filter(
                            v-bind:class="{ 'is-show' : showFilter}"
                        )
                            .m-only
                                .flex.items-center.justify-between.p-4.border-b
                                    h2.text-xl Filter
                                    button.btn-icon-40(
                                        v-on:click="showFilter = !showFilter"
                                    )
                                        IconSet(type="close")
                            .items-filter
                                .field(class="lg:mr-3 lg:mb-0 lg:w-64 mr-0 w-full mb-4")
                                    .relative.field-style.w-full
                                        date-range-picker(
                                            v-if="dateRange.startDate && dateRange.endDate" 
                                            ref="picker" 
                                            v-model="dateRange" 
                                            @update="pickerUpdate($event)" 
                                            :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }" 
                                            :date-format="dateFormat" 
                                            opens="right"
                                            :ranges="default_ranges"
                                            autoApply=true
                                        )
                                            div(
                                                slot="input" 
                                                slot-scope="picker") 
                                                    | {{ picker.startDate | date}} - {{ picker.endDate  | date}}

                                        .field-icon
                                            IconSet(type="calendar")

                                .field(class="lg:mr-3 lg:mb-0 lg:w-32 mr-0 w-full mb-4")
                                    select.field-style#filter-record-size(@change="sizeChange($event)" v-model="items_per_page" style="min-width:80px")
                                        option(v-for="(s,index) in size" :key="index" :value="s") {{ s }}
                                    .field-icon
                                        IconSet(
                                            type="chevron-down"
                                        )

                        //- end filter

                    .box-table
                        table.table
                            thead.m-hide
                                tr
                                    th(v-for="(th,index) in tableThead" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}
                            tbody
                                tr(v-if="status_list.status === 'loading'")
                                    td.text-center(:colspan="tableThead.length")
                                        .flex.flex-wrap.items-center.justify-center
                                            Spinner(show="true" size="48")
                                        
                                template(v-else-if="list.data && list.data.length > 0")
                                    tr(v-for="(data,index) in list.data" :key="data.id")
                                        td.text-ms {{ !index? datetimeFormat(data.created_at, 'DD MMMM YYYY'):datetimeFormat(list.data[index-1].created_at, 'DD MMMM YYYY')==datetimeFormat(list.data[index].created_at, 'DD MMMM YYYY')? '':datetimeFormat(data.created_at, 'DD MMMM YYYY') }}
                                        td.text-ms {{ data.target? data.target.name:'-' }}
                                        td.text-ms {{ data.msisdn }}
                                        td.text-ms(v-if="!checkFeature('tracking_history|hide_use_for')") {{ data.use_for | capitalize}}
                                        td.text-ms {{ data.user?data.user.name:'-'}}
                                        td.text-ms {{ data.created_at }}
                                        //- td.text-ms {{ datetimeFormat(data.created_at, 'DD MMMM YYYY HH:mm:ss [WIB]') }}
                                tr(v-else)
                                    td.text-center(:colspan="tableThead.length") No Data Available

                .card__footer(v-if="status_list.status !== 'loading'")
                    .box-table-footer
                        .pagination
                            vue-ads-pagination(
                                :total-items="total_data"
                                :items-per-page="items_per_page"
                                :page="page"
                                :max-visible-pages="5"
                            )
                                template(slot="buttons" slot-scope="props")
                                    vue-ads-page-button(
                                        v-for="(button, key) in props.buttons"
                                        :key="key"
                                        v-bind="button"
                                        :class="{'bg-yellow-dark': button.active}"
                                        @page-change="page = button.page"
                                        @range-change="start = button.start; end = button.end"
                                    )

</template>

<script>
import TitlePage from '@/components/shell/TitlePage.vue'
import moment from 'moment';
import IconSet from '@/components/IconSet.vue';
import DateRangePicker from 'vue2-daterange-picker';
import JsonCSV from 'vue-json-csv'
import { mapState } from 'vuex';
import Spinner from '@/components/Spinner.vue';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
import { checkFeature } from '@/config/features';

export default {
    name: 'pageMonitoringLogIndex',
    components: {
        IconSet,
        DateRangePicker,
        TitlePage,
        JsonCSV,
        Spinner,
        VueAdsPagination,
        VueAdsPageButton,
    },
    data() {
        return {
            breadcrumb: [
                {
                    name: 'Tracking History',
                    // url: 'target'
                }
            ],
            showFilter: false,

            total_data: 0,
            items_per_page: 10,

            size: [10, 25, 50, 100],

            page: 0,
            start: 0,
            end: 0,

            keyword: '',
            timeout: null,

            from_date: '',
            to_date: '',

            dateRange: {
                startDate: '',
                endDate: '',
            },
            default_ranges: {
                'Today': [moment().toDate(), moment().toDate()],
                'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                'Last 7 days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                'Last week': [moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(), moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate()],
            },
            csvName: 'tracking_history_list_'+moment().format('YYYY-MM-DD_HH:mm:ss')+'.csv',
            csvData: [],
        }
    },
    computed: {
        ...mapState('trackingHistory', [
            'status_list',
            'list',
        ]),
        route_name() {
            return this.$route.name;
        },
        tableThead() {
        let data = [
            {
                name: 'Datetime',
            },{
                name: 'Target Name',
            },{
                name: 'MSISDN',
            },{
                name: 'Use For',
            },{
                name: 'User',
            },{
                name: 'Latest Timestamp'
            }
        ]
        if (this.checkFeature('tracking_history|hide_use_for')) {
            data = data.filter(function( obj ) {
                return obj.name !== 'Use For';
            });
        }

        return data
        }
    },
    watch: {
        route_name(newData, oldData) {
            if (newData === 'pageTrackingHistory') {
                this.getData();
            }

            if (newData === 'pageTrackingHistory') {
                if (oldData !== 'pageTrackingHistory') {
                    this.items_per_page = 10;
                    this.page = 0;
                    this.keyword = '';
                    this.$store.commit('trackingHistory/setList', {});
                }

                this.getData();
            }
        },
        list(newData) {
            if (Object.keys(newData).length > 0) {
                this.total_data = parseInt(newData.total_data);
                // this.page = (parseInt(newData.current_page) - 1);
            } else {
                this.total_data = 0;
            }
            this.getDataCsv(this.list.data)

        },
        total_data() {
            // this.pagination();
        },
        items_per_page() {
            this.getData();
            // this.pagination();
        },
        page() {
            this.getData();
        },
        keyword() {},
    },
    methods: {
        async pickerUpdate(e) {
            // console.log('========= DateRangePicker on update')
            // console.log(e)

            let ranges = moment(e.endDate.getTime()).diff(moment(e.startDate.getTime()), 'days') + 1;

            if (ranges > this.$store.getters['config/getValidateRange']) {
                let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
                const default_date = this.$store.getters['config/getDefaultDate'](def);

                this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
                this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();

                this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');

                this.$swal.fire({
                    icon: 'error',
                    title: 'Date range is not more than ' + this.$store.getters['config/getValidateRange'] + ' days!',
                    text: 'Your date range is ' + ranges + ' days.',
                })

            } else {
                this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');
                this.page = 0;

                this.getData();
            }
        },
        getData() {

            this.$store.dispatch('trackingHistory/getList', [this.items_per_page, (this.page + 1), this.keyword, this.from_date, this.to_date]);

            this.pagination();
            if (this.list.data && this.list.data.length > 0) {
                this.getDataCsv(this.list.data)
            }
        },
        getDataCsv(data) {
            if (data.length > 0) {
                this.csvData = data.map((item) => {
                    return {
                        datetime: item.created_at ? this.datetimeFormat(item.created_at, 'DD MMMM YYYY') : '-',
                        target_name: item.target ? item.target.name : '-',
                        msisdn: item.msisdn ? item.msisdn : '-',
                        use_for: this.$options.filters.capitalize(item.use_for) ? item.use_for : '-',
                        user: item.user ? item.user.name : '-',
                        latest_timestamp: item.created_at ? this.datetimeFormat(item.created_at, 'DD MMMM YYYY HH:mm:ss [WIB]') : '-',
                    };
                });
            } else {
                this.csvData = [{
                    datetime: '-',
                    target_name: '-',
                    msisdn: '-',
                    use_for: '-',
                    user: '-',
                    latest_timestamp: '-'
                }];
            }
        },

        pageChange(page) {
            this.page = page;
        },
        rangeChange(start, end) {
            this.start = start;
            this.end = end;
        },
        sizeChange() {
            this.page = 0;
        },
        pagination() {
            // update fontawesome to iconfont
            var paginationButtonPreview = document.getElementsByClassName("fa-angle-left");

            if (paginationButtonPreview[0]) {
                var classListPrev = paginationButtonPreview[0].classList;

                if (Object.values(classListPrev).filter(key => ['iconfont'].includes(key)).length === 0) {
                    paginationButtonPreview[0].classList.add('iconfont');
                    paginationButtonPreview[0].classList.add('iconleft');
                    paginationButtonPreview[0].style.fontSize = '12px';
                }
            }

            var paginationButtonNext = document.getElementsByClassName("fa-angle-right");

            if (paginationButtonNext[0]) {
                var classListNext = paginationButtonNext[0].classList;

                if (Object.values(classListNext).filter(key => ['iconfont'].includes(key)).length === 0) {
                    paginationButtonNext[0].classList.add('iconfont');
                    paginationButtonNext[0].classList.add('iconright');
                    paginationButtonNext[0].style.fontSize = '12px';
                }
            }
        },
        searchKeyword() {
            // clear timeout variable
            clearTimeout(this.timeout);

            var self = this;
            this.timeout = setTimeout(function () {
                // enter this block of code after 1 second
                // handle stuff, call search API etc.
                self.page = 0;
                self.$store.commit('trackingHistory/setList', {});

                // if (self.keyword.length > 0) {
                    self.getData();
                // }
            }, 650);
        },
        datetimeFormat(datetime, format="DD MMMM YYYY hh:mm") {
            // return datetime
            // 26 Oct 2020 14:56:31 WITA
            return moment(datetime, 'DD MMM YYYY HH:mm:ss z').format(format);
        },
        dateFormat (classes, date) {
            if (!classes.disabled) {
                classes.disabled = moment(date.getTime()).subtract({ hours: 12})._d > new Date();
            }
            return classes;
        },
        checkFeature(feature) {
            return checkFeature(feature);
        },
    },
    mounted() {
        // console.log('=================== mounted on case-management index')
        if (this.route_name === 'pageTrackingHistory') {
            // reset date filter

            let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
            const default_date = this.$store.getters['config/getDefaultDate'](def);

            this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
            this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();

            this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');

            this.getData();
            // this.pagination();
        }
    },
    filters: {
        date: function (date) {
            return moment(date).format('YYYY-MM-DD');
        },
        capitalize: function (s) {
            if (typeof s !== 'string') return ''
            return s.charAt(0).toUpperCase() + s.slice(1)
        },

    }
}
</script>
