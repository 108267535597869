<template lang="pug">
//- Device History
.card
	.card__header
		h2.text-xl Devices Change History
	.card__body
		template(v-if="status_device_history.status === 'loading'")
			template(v-if="route_name === 'TargetDetailHistoryDeviceRoles' || route_name === 'TargetDetailDeviceHistory'")
				Loading(show="true")
			template(v-else)
				LoadingAnimation(show="true" title="device-history")
		template(v-else)
			.grid.gap-4(
				class="grid-cols-1 lg:grid-cols-3"
				v-if="device_history && Object.keys(device_history).length > 0"
			)
				.block.border.p-3.rounded(v-for="(device,index) in device_history" :key="index")
					.block.mb-2.pb-2.border-b.border-dashed
						.flex.items-center.justify-between
							.block
								.text-xs.block.mb-2
									| NEW DEVICE • {{ device.datetime }}
								small.inline-block.font-bold.bg-green-200.text-green-800.rounded.py-1.px-2.mb-2(style="font-size:65%") {{ device.event }}
								span.block {{ device.new_device_name }} ({{ device.new_device_vendor }})
								small.font-bold IMEI {{ device.new_imei }}
							.block
								button(v-tooltip="device.new_device_tags.length > 0 ? device.new_device_tags.join(', ') : '---'")
									IconSet(type="info" )
					.block
						.flex.items-center.justify-between
							.block
								.text-xs PREV DEVICE
								span.block {{ device.prev_device_name }} ({{ device.prev_device_vendor }})
								small.font-bold IMEI {{ device.prev_imei }}
							.block
								button(v-tooltip="device.prev_device_tags.length > 0 ? device.prev_device_tags.join(', ') : '---'")
									IconSet(type="info")

			NoDataAvailable(v-else)
</template>

<script>
import IconSet from '@/components/IconSet.vue'
import { mapState } from 'vuex';
import Loading from '@/components/Loading.vue';
import LoadingAnimation from '@/components/LoadingAnimation.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';

export default {
    name: 'ResultDeviceHistoryResult',
    components: {
        IconSet,
        Loading,
        LoadingAnimation,
        NoDataAvailable,
    },
    data(){
        return {
        };
    },
    computed: {
        ...mapState('deviceHistory', [
            'status',
            'from_date',
            'to_date',
            'status_device_history',
            'device_history',
        ]),
        route() {
            return this.$route;
        },
        route_name() {
            return this.$route.name;
        },
    },
    watch: {
    },
    methods: {
    },
    filters: {
    },
    created() {
    },
    mounted() {
    },
}
</script>

<style lang="sass" scoped>
    .border,
    .border-b
        border-color: lighten($color-secondary-500, 5% )
</style>
