<template lang="pug">
.target-shell
    .box
        .box__header
            TitlePage(
                title="Target"
                :breadcrumb="breadcrumb"
                )
        .box__body
            nav.menu(v-if="show_navbar")
                ul
                    li
                        router-link(
                            :to="{ name: 'pageTargetIndex', params: {} }"
                        ) Lists of Target
                    li
                        router-link(
                            :to="{ name: 'pageTargetMaps', params: {} }"
                        ) Maps of Target
                    li(v-if="checkFeature('target|summary')")
                        router-link(
                            :to="{ name: 'pageTargetSummary', params: {} }"
                        ) Summary
                    li(v-if="isWatchlistQuota && checkFeatureByAcl('target_history', 'index')")
                        router-link(
                            :to="{ name: 'pageTargetHistory', params: {} }"
                        ) Target History (Watchlist)
            router-view
    
</template>

<script>
import TitlePage from '@/components/shell/TitlePage.vue'
import { mapState, mapGetters } from 'vuex';
import { checkFeature } from '@/config/features';

export default {
    name: 'targetShell',
    components: {
        TitlePage,
    },
    data() {
        return {
            // 
        }
    },
    methods: {
        checkFeature(feature) {
            return checkFeature(feature);
        },
    },
    computed: {
        ...mapState('target', [
            'show_navbar',
        ]),
        ...mapGetters({
            isWatchlistQuota: 'config/getIsWatchlistQuota',
        }),
        route() {
            return this.$route;
        },
        id() {
            return this.$route.params.id;
        },
        breadcrumb() {
            let breadcrumb = [
                {
                    name: 'Target',
                    url: '/target'
                }
            ];

            if (this.route.matched[0] && this.route.matched[0].components.default.name === 'targetShell' && this.route.matched[1]) {
                switch(this.route.matched[1].name) {
                    case 'pageTargetCreate':
                        breadcrumb.push({
                            name: 'Add Target',
                            url: '/target/create',
                        });
                        break;

                    case 'pageTargetEdit':
                        breadcrumb.push({
                            name: 'Edit Target',
                            url: '/target/'+this.id+'/edit',
                        });
                        break;
                    case 'pageTargetMaps':
                        breadcrumb.push({
                            name: 'Maps Target',
                            url: '/target/maps',
                        });
                        break;
                    case 'pageTargetSummary':
                        breadcrumb.push({
                            name: 'Target Summary',
                            url: '/target/summary',
                        });
                        break;
                    case 'pageTargetHistory':
                        breadcrumb.push({
                            name: 'Target History',
                            url: '/target/history',
                        });
                        break;
                    default:
                        // code block
                }
            }

            return breadcrumb;
        },
    },
    watch: {
        route(newData) {
            if (newData.matched[0] && newData.matched[0].components.default.name === 'targetShell')
                window.scrollTo(0,0);
        },
        id() {},
        breadcrumb() {},
    },
    created() {
        if (this.$route.params.id && this.$route.params.id === 'create')
            this.$router.push({ name: 'pageTargetCreate', params: {} });
        else if (this.$route.params.id && this.$route.params.id === 'maps')
            this.$router.push({ name: 'pageTargetMaps', params: {} });
        else if (this.$route.params.id && this.$route.params.id === 'summary')
            this.$router.push({ name: 'pageTargetSummary', params: {} });
        else if (this.$route.params.id && this.$route.params.id === 'history')
            this.$router.push({ name: 'pageTargetHistory', params: {} });
    },
    mounted() {
        // 
    },
}
</script>

<style lang="sass">
    nav.menu
        ul
            @apply .flex .items-center .border-b
            li
                display: block
                a
                    @apply .block .py-4 .px-6 .relative
                    transition: all 0.3s ease-in-out
                    &::before
                        content: ''
                        height: 2px
                        width: 100%
                        position: absolute
                        bottom: -1px
                        left: 0px
                        background-color: transparent
                        transition: all 0.3s ease-in-out
                    &:hover
                        color: $color-main-500
                    &.router-link-exact-active
                        color: $color-main-500
                        &::before
                            background-color: $color-main-500
</style>