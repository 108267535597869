<template lang="pug">
.card.card--no-bg
    .card__header
        h2.text-xl {{ page_title }}
        .header-cta
            .block
                //- button.btn-default.m-btn-icon()
                router-link.btn-default.m-btn-icon(to="/license")
                    IconSet(type="chevron-left")
                    span.m-hide.block.ml-3 Back
    .card__body
        form.container-form
            .group-fields.border-b

                .field
                    .field__label
                        label.block.text-sm.font-bold License Name <sup>*</sup>
                    .field__form
                        input.field-style(type="text" placeholder="Write license name here" v-model="model.license_name")
                        .pt-3
                            .message.text-xs.text-red-500(v-if="$v.model.license_name.$error") This license name is required &amp; max length is 255 char.
                            .message.text-xs.text-red-500(v-if="error_license_name !== ''") {{ error_license_name }}

                .field
                    .field__label
                        label.block.text-sm.font-bold License Key (Serial Number) <sup>*</sup>
                    .field__form
                        input.field-style(type="text" placeholder="Write license key here" v-model="model.license_key")
                        .pt-3
                            .message.text-xs.text-red-500(v-if="$v.model.license_key.$error") This license name is required &amp; max length is 255 char.
                            .message.text-xs.text-red-500(v-if="error_license_key !== ''") {{ error_license_key }}

                .grid.grid-cols-3.gap-8
                    .field
                        .field__label
                            label.block.text-sm.font-bold Quantity <sup>*</sup>
                        .field__form
                            input.field-style(type="number" placeholder="Write quantity here" v-model="model.quantity")
                            .py-3
                                .message.text-xs.text-red-500(v-if="$v.model.quantity.$error") This quantity is required &amp; numeric.
                                .message.text-xs.text-red-500(v-if="error_quantity !== ''") {{ error_quantity }}
                    .field
                        .field__label
                            label.block.text-sm.font-bold Installed In <sup>*</sup>
                        .field__form
                            input.field-style(type="text" placeholder="Write Installed here" v-model="model.installed_in")
                            .py-3
                                .message.text-xs.text-red-500(v-if="$v.model.installed_in.$error") This installed in is required &amp; max length is 255 char.
                                .message.text-xs.text-red-500(v-if="error_installed_in !== ''") {{ error_installed_in }}
                    .field
                        .field__label
                            label.block.text-sm.font-bold Binded In <sup>*</sup>
                        .field__form
                            input.field-style(type="text" placeholder="Write Binded here" v-model="model.binded_in")
                            .py-3
                                .message.text-xs.text-red-500(v-if="$v.model.binded_in.$error") This binded in is required &amp; max length is 255 char.
                                .message.text-xs.text-red-500(v-if="error_binded_in !== ''") {{ error_binded_in }}

                .grid.grid-cols-2.gap-8
                    .field.field--radio.mb-4
                        .field__label
                            label.block.text-sm.font-bold Status <sup>*</sup>
                        .field__form
                            .ui-radio.mr-4
                                input(type="radio" name="status" value="active" v-model="model.status")
                                .style-radio
                                    .radio
                                    strong Active
                            .ui-radio
                                input(type="radio" name="status" value="inactive" v-model="model.status")
                                .style-radio
                                    .radio
                                    strong Inactive
                            .pt-3
                                .message.text-xs.text-red-500(v-if="$v.model.status.$error") This status is required.
                                .message.text-xs.text-red-500(v-if="error_status !== ''") {{ error_status }}

                    .field
                        .field__label
                            label.block.text-sm.font-bold Source
                        .field__form
                            .relative
                                .field-style.field-style--mirroring.field-style--loading(v-if="status_source_list.status === 'loading'")
                                    Spinner(show="true" size="32")

                                select.field-style(v-model="model.source_label")
                                    option(value="") - No Source -
                                    template(v-if="checkSource()")
                                        option(v-for="(source,index) in sourceListData" :key="index" :value="index") {{ source }}
                                .field-icon
                                    IconSet(type="chevron-down")
                            .pt-3
                                .message.text-xs.text-red-500(v-if="error_source_label !== ''") {{ error_source_label }}

                .grid.grid-cols-2.gap-8
                    .field
                        .field__label
                            label.block.text-sm.font-bold Activated Date
                        .field__form
                            .mr-3
                                .relative.mr-3.field-style.field-style--license
                                    date-range-picker.field-style__date(
                                        ref="picker" 
                                        v-model="dateRangeActivatedDate" 
                                        @update="pickerUpdate($event)" 
                                        :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }" 
                                        :date-format="dateFormat" 
                                        :singleDatePicker="true"
                                        opens="right"
                                        :ranges="default_ranges"
                                        :autoApply="true"
                                    )
                                        div(
                                            slot="input" 
                                            slot-scope="picker")
                                                | {{ picker.startDate ? singleDateFormat(picker.startDate) : '-' }}

                                    .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2
                                        IconSet(type="calendar")

                                button.link.text-xs.mt-3.text-red-100(v-if="model.activated_date !== ''" type="button" v-tooltip="'Clear activated date'" @click="setEmpyDate('activated')")
                                    | Clear Activated Date

                            .py-3
                                //- .message.text-xs.text-red-500(v-if="$v.model.activated_date.$error") This activated date in is required.
                                .message.text-xs.text-red-500(v-if="error_activated_date !== ''") {{ error_activated_date }}

                    .field
                        .field__label
                            label.block.text-sm.font-bold Expired Date
                        .field__form
                            .mr-3
                                .relative.mr-3.field-style.field-style--license
                                    date-range-picker.field-style__date(
                                        ref="picker" 
                                        v-model="dateRangeExpiredDate" 
                                        @update="pickerUpdate($event)" 
                                        :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }" 
                                        :date-format="dateFormat" 
                                        :singleDatePicker="true"
                                        opens="right"
                                        :ranges="default_ranges"
                                        :autoApply="true"
                                    )
                                        div(
                                            slot="input" 
                                            slot-scope="picker")
                                                | {{ picker.startDate ? singleDateFormat(picker.startDate) : '-' }}

                                    .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2
                                        IconSet(type="calendar")

                                button.link.text-xs.mt-3.text-red-100(v-if="model.expired_date !== ''" type="button" v-tooltip="'Clear expired date'" @click="setEmpyDate('expired')")
                                    | Clear Expired Date

                            .py-3
                                //- .message.text-xs.text-red-500(v-if="$v.model.expired_date.$error") This expired date in is required.
                                .message.text-xs.text-red-500(v-if="error_expired_date !== ''") {{ error_expired_date }}

            .flex.items-center.justify-end.mb-8
                button.btn.btn-default(type="button" @click="reset()")
                    | Reset
                button.btn-primary-o.ml-2(type="submit" @click="submit($event, true)")
                    | Save &amp; Continue
                button.btn.btn-primary.ml-4(type="submit" @click="submit($event)")
                    | Save

</template>

<script>
import { mapState } from 'vuex';
import IconSet from '@/components/IconSet.vue'
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import moment from 'moment';
import { required, maxLength, numeric } from "vuelidate/lib/validators";
import Spinner from '@/components/Spinner.vue';

export default {
    name: 'PageLicenseForm',
    components: {
        IconSet,
        DateRangePicker,
        Spinner
    },
    data: function() {
        return {
            model: {
                license_name: '',
                license_key: '',
                quantity: '',
                installed_in: '',
                binded_in: '',
                status: '',
                activated_date: '',
                expired_date: '',
                source_label: '',
            },

            // error field
            error_license_name: '',
            error_license_key: '',
            error_quantity: '',
            error_installed_in: '',
            error_binded_in: '',
            error_status: '',
            error_activated_date: '',
            error_expired_date: '',
            error_source_label: '',

            dateRangeActivatedDate: {
                startDate: '',
                endDate: '',
            },
            dateRangeExpiredDate: {
                startDate: '',
                endDate: '',
            },
            default_ranges: {
                'Today': [moment().toDate(), moment().toDate()],
                'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                // 'Last 7 days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                // 'Last week': [moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(), moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate()],
            },
        };
    },
    validations: {
        model: {
            license_name: {
                required,
                maxLength: maxLength(255)
            },
            license_key: {
                required,
                maxLength: maxLength(255)
            },
            quantity:  {
                required,
                numeric,
            },
            installed_in:  {
                required,
                maxLength: maxLength(255)
            },
            binded_in:  {
                required,
                maxLength: maxLength(255)
            },
            status:  {
                required,
            },
            activated_date:  {
                // required,
            },
            expired_date:  {
                // required,
            },
            source_label:  {
                // required,
            },
        },
    },
    computed: {
        // ...mapState('auth', [
        //     'user',
        // ]),
        ...mapState('license', [
            'detail',
            'status_source_list',
            'source_list',
        ]),
        route_name() {
            return this.$route.name;
        },
        id() {
            return this.$route.params.id;
        },
        page_title() {
            let title = 'Edit License';
            if (this.route_name === 'pageLicenseCreate')
                title = 'Add License';
            return title;
        },
        sourceListData() {
            if (this.source_list.data && Object.keys(this.source_list.data).length > 0){
                let data = this.source_list.data
                console.log({data})
                return data;
            }
            return {};
        },
    },
    watch: {
        route_name() {
            this.getData();
        },
        id() {},
        page_title() {},
        source_list(newData) {
            // console.log({newData})
        },
        dateRangeActivatedDate(newData) {
            let date = '';
            const check_date = moment(newData.startDate).format('YYYY-MM-DD');

            if (check_date !== 'Invalid date')
                date = check_date;

            this.model.activated_date = date;
        },
        dateRangeExpiredDate(newData) {
            let date = '';
            const check_date = moment(newData.startDate).format('YYYY-MM-DD');

            if (check_date !== 'Invalid date')
                date = check_date;

            this.model.expired_date = date;
        },
        detail(newData) {
            this.model.license_name = '';
            this.model.license_key = '';
            this.model.quantity = '';
            this.model.installed_in = '';
            this.model.binded_in = '';
            this.model.source_label = '';
            this.model.status = '';
            this.setEmpyDate('activated');
            this.setEmpyDate('expired');
            if (newData.data){
                let dataNewData = newData.data
                // console.log({dataNewData})
                this.model.license_name = dataNewData.licenseName || '';
                this.model.license_key = dataNewData.licenseKey || '';
                this.model.quantity = dataNewData.qty || '';
                this.model.installed_in = dataNewData.installedIn || '';
                this.model.binded_in = dataNewData.bindedIn || '';
                this.model.source_label = dataNewData.sourceLabel || '';
                this.model.status = dataNewData.status?(dataNewData.status.toLowerCase() === 'active' ? 'active' : 'inactive') : '';
                // this.model.activated_date = (dataNewData.activatedDate ? dataNewData.activatedDate || '');
                // this.model.expired_date = (dataNewData.expiredDate ? dataNewData.expiredDate || '');

                if (dataNewData.activatedAt && dataNewData.activatedAt.toLowerCase() !== 'permanent') {
                    const date = moment(dataNewData.activatedAt, 'YYYY-MM-DD').toDate();
                    this.dateRangeActivatedDate = {
                        startDate: date,
                        endDate: date,
                    };
                }
                //  else {
                //     this.setEmpyDate('activated');
                // }

                if (dataNewData.expiredAt && dataNewData.expiredAt.toLowerCase() !== 'permanent') {
                    const date = moment(dataNewData.expiredAt, 'YYYY-MM-DD').toDate();
                    this.dateRangeExpiredDate = {
                        startDate: date,
                        endDate: date,
                    };
                }
                //  else {
                //     this.setEmpyDate('expired');
                // }
            }
        },
        'model.license_key': function(newData) {
            if (newData !== '' && newData !== null){
                // console.log('model.license_key - newData')
                // console.log({newData})
                this.model.license_key = newData.replace(/\s+/g, '-');
            }
        },
    },
    methods: {
        checkSource() {
            return (this.source_list.data && Object.keys(this.source_list.data).length > 0)
        },
        setEmptyModel(all=true) {
            for (let key in Object.keys(this.model)) {
                if (all === true)
                    // if(["clues","case_id",'additional_info'].indexOf(key) > -1)
                    //     this.model[Object.keys(this.model)[key]] = [];
                    // else
                        this.model[Object.keys(this.model)[key]] = '';
            }
        },
        setErrors(empty=true, errors=null) {
            if (empty === true) {
                this.error_license_name = '';
                this.error_quantity = '';
                this.error_installed_in = '';
                this.error_binded_in = '';
                this.error_status = '';
                this.error_activated_date = '';
                this.error_expired_date = '';
                this.error_source_label = '';

            } else if (empty !== true && errors !== null) {
                // this.error_license_name = (errors.license_name ? errors.license_name : '');
                // this.error_quantity = (errors.quantity ? errors.quantity : '');
                // this.error_installed_in = (errors.installed_in ? errors.installed_in : '');
                // this.error_binded_in = (errors.binded_in ? errors.binded_in : '');
                // this.error_status = (errors.status ? errors.status : '');
                // this.error_activated_date = (errors.activated_date ? errors.activated_date : '');
                // this.error_expired_date = (errors.expired_date ? errors.expired_date : '');

                this.error_license_name = errors.license_name || '';
                this.error_quantity = errors.quantity || '';
                this.error_installed_in = errors.installed_in || '';
                this.error_binded_in = errors.binded_in || '';
                this.error_status = errors.status || '';
                this.error_activated_date = errors.activated_date || '';
                this.error_expired_date = errors.expired_date || '';
                this.error_source_label = errors.source_label || '';
            }
        },
        /* eslint-disable no-unused-vars */
        dateFormat(classes, date) {
            // if (!classes.disabled) {
            //     classes.disabled = moment(date.getTime()).subtract({ hours: 12})._d > new Date();
            // }
            return classes;
        },
        singleDateFormat(date) {
            return moment(date).format('YYYY-MM-DD');
        },
        setEmpyDate(type=null) {
            if (type !== null) {
                const default_date = {
                    // startDate: '',
                    // endDate: '',
                    startDate: null,
                    endDate: null,
                };

                switch(type) {
                    case 'activated':
                        this.dateRangeActivatedDate = default_date;
                        break;

                    case 'expired':
                        this.dateRangeExpiredDate = default_date;
                        break;

                    default:
                        // code block
                }
            }
        },
        async pickerUpdate(e) {
            // let ranges = moment(e.endDate.getTime()).diff(moment(e.startDate.getTime()), 'days') + 1;
            // let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)


            // // if (ranges > def) {
            // if (ranges > this.$store.getters['config/getValidateRange']) {  
            //     const default_date = this.$store.getters['config/getDefaultDate'](def);
            //     this.updateDateRange(default_date.from_date, default_date.to_date);
            //     await this.dateChange();

            //     // EventEmit.$emit('error', 'Date range is not more than ' + this.$store.getters['config/getValidateRange'] + ' days!');
            //     this.$swal.fire({
            //         icon: 'error',
            //         title: 'Date range is not more than ' + this.$store.getters['config/getValidateRange'] + ' days!',
            //         text: 'Your date range is ' + ranges + ' days.',
            //     });
            //     return;
            // }

            // this.updateDateRange(moment(this.dateRange.startDate).format('YYYY-MM-DD'), moment(this.dateRange.endDate).format('YYYY-MM-DD'));
        },
        // defaultFilter() {
        //     // let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
        //     // const default_date = this.$store.getters['config/getDefaultDate'](def);

        //     // this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
        //     // this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();

        //     const today = moment().toDate();
        //     // this.dateRange.startDate = moment().subtract(6, 'days').toDate()
        //     this.dateRange.startDate = today;
        //     this.dateRange.endDate = today;

        //     this.from_date_input = moment(this.dateRange.startDate).format('YYYY-MM-DD');
        //     this.to_date_input = moment(this.dateRange.endDate).format('YYYY-MM-DD');
        // },
        getData() {
            this.reset(true);
            let self = this
            this.$store.dispatch('license/getSourceList')
            .then(() => {
                if (self.route_name === 'pageLicenseEdit') {

                    // for dispatch get data
                    EventEmit.$emit('showLoading', true);

                    self.$store.dispatch('license/getDetail', self.id)
                    .then((resp) => {
                        if (resp === 'success') {
                            EventEmit.$emit('showLoading', false);
                        } else {
                            EventEmit.$emit('error');
                        }
                    });

                } else {
                    if (self.route_name === 'pageLicenseCreate')
                        self.$store.commit('license/setDetail', {});
                }
            });
        },
        reset(direct=false) {
            if (direct === true) {
                this.setEmptyModel(false);
                // reset vuelidate
                this.$nextTick(() => { this.$v.$reset(); });
                this.setErrors(true);
                window.scrollTo(0,0);
            } else {
                this.$swal.fire({
                    icon: 'warning',
                    title: 'Are you sure you want to reset form?',
                    // text: id,
                    confirmButtonText: 'Yes!',
                    confirmButtonColor: '#DD6B55',
                    showCancelButton: true,
                    allowOutsideClick: false,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        if (this.route_name === 'pageLicenseCreate') {
                            // this.setEmptyModel(false);
                            this.setEmptyModel();
                            // reset vuelidate
                            this.$nextTick(() => { this.$v.$reset(); });
                            this.setErrors(true);
                            window.scrollTo(0,0);
                        } else {
                            this.getData();
                        }
                    }
                });
            }
        },
        submit(e, save_continue=false) {
            e.preventDefault();
            this.$v.$touch();

            if (this.$v.model.$invalid) {
                for (let key in Object.keys(this.$v.model)) {
                    const input = Object.keys(this.$v.model)[key];
                    if (input.includes("$")) return false;
                    if (this.$v.model[input].$error) {
                        break;
                    }
                }
                // EventEmit.$emit('error', 'Please fill the form correctly.');
                // EventEmit.$emit('showLoading', false);
                window.scrollTo(0,0);
                return;
            }

            EventEmit.$emit('showLoading', true);

            let model_data = Object.keys(this.model).map((key) => this.model[key]);
            // model_data.unshift(this.user.client.id, this.user.client.name);

            if (this.route_name === 'pageLicenseCreate') {

                this.$store.dispatch('license/create', model_data)
                .then((resp) => {
                    if (resp.result === 'success') {
                        if (save_continue === false) {
                            this.$router.push('/license');

                        } else {
                            if (resp.data && resp.data.data && resp.data.data.id) {
                                this.$router.push('/license/'+ resp.data.data.id +'/edit');
                            } else {
                                this.$router.push('/license');
                            }
                        }

                        this.$swal.fire({
                            icon: 'success',
                            title: 'Data successfully created!',
                            timer: 3000,
                        });
                    } else {
                        if (resp.data.message && resp.data.message.errors) {
                            this.setErrors(false, resp.data.message.errors);
                            // EventEmit.$emit('error', null, resp.data.message.errors);
                            EventEmit.$emit('showLoading', false);
                            window.scrollTo(0,0);
                        } else {
                            EventEmit.$emit('error');
                        }
                    }
                });

            // pageLicenseEdit
            } else {

                model_data = [this.id].concat(model_data);

                this.$store.dispatch('license/update', model_data)
                .then((resp) => {
                    if (resp.result === 'success') {                        
                        if (save_continue === false)
                            this.$router.push('/license');

                        this.$swal.fire({
                            icon: 'success',
                            title: 'Data successfully updated!',
                            timer: 3000,
                        });
                    } else {
                        if (resp.data.message && resp.data.message.errors) {
                            this.setErrors(false, resp.data.message.errors);
                            // EventEmit.$emit('error', null, resp.data.message.errors);
                            EventEmit.$emit('showLoading', false);
                            window.scrollTo(0,0);
                        } else {
                            EventEmit.$emit('error');
                        }
                    }
                });
            }
        },
    },
    filters: {
        date: function (date) {
            return moment(date).format('YYYY-MM-DD');
        }
    },
    mounted() {
        // this.defaultFilter();
        this.getData();
    },
}
</script>

<style lang="sass">
    .field-style--license
        height: 46px
        .field-style__date
            @apply .w-full .inset-0
            position: absolute !important
            height: 46px
            .reportrange-text
                line-height: 46px
                width: 100%
                height: 46px
                display: flex
                align-items: center
</style>
