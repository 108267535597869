<template lang="pug">
//- Tethered Device
.card
	.card__header
		h2.text-xl List Devices Tethered History
	.card__body
		template(v-if="status_tethered_device.status === 'loading'")
			template(v-if="route_name === 'TargetDetailHistoryDeviceRoles'")
				Loading(show="true")
			template(v-else)
				LoadingAnimation(show="true" title="tethered-device")

		template(v-else)
			.grid.gap-4(
				class="grid-cols-1 lg:grid-cols-3"
				v-if="tethered_device && Object.keys(tethered_device).length > 0"
			)
				.tethered-item(v-for="data in tethered_device" :key="data.tethered_device")
					.flex.items-center.mb-4
						figure.w-12.h-12.rounded.bg-gray-100.mr-3.overflow-hidden
							img.objcet-cover.w-full.h-full(:src="defaultImage")
						.block.flex-grow
							span.block {{ data.tethered_device_name }}
							small {{ data.tethered_device_vendor }}
						.block
							button.btn-icon(v-tooltip="data.tethered_device_tags.length > 0 ? data.tethered_device_tags.join(', ') : '---'")
								IconSet(type="info")
					.block(v-for="period in data.periods.slice(0, 1).reverse()")
						.font-bold {{ period.start.datetime }} - {{ period.end.datetime }}
						button.text-xs.more-date(
							v-if="data.periods.length > 1"
							type="button"
							@click="handleHistoryTimestamp(data.tethered_device_name, data.tethered_device_vendor, data.periods)"
						) See more periods
							//- v-on:click="seeMore = !seeMore"

			NoDataAvailable(v-else)

	.popup-layer(
		v-bind:class="{'is-show' : seeMore}"
	)
		.popup-container
			.popup-container__header
				h2.text-xl Tethered Detail
				button.btn-icon-40(
					v-on:click="seeMore = !seeMore"
				)
					IconSet(type="close")

			.popup-container__body
				.flex.items-center.mb-4
					figure.w-12.h-12.rounded.bg-gray-100.mr-3.overflow-hidden
						img.objcet-cover.w-full.h-full(:src="defaultImage")
					.block.flex-grow
						span.block {{ tmpDevice }}
						small {{ tmpVendor }}

				template(v-for="period in tmpPeriods")
					.mb-4 {{ period.start.datetime }} - {{ period.end.datetime }}

			.popup-container__footer

</template>

<script>
import IconSet from "@/components/IconSet.vue";
import { mapState } from "vuex";
import Loading from "@/components/Loading.vue";
import LoadingAnimation from "@/components/LoadingAnimation.vue";
import NoDataAvailable from "@/components/NoDataAvailable.vue";

export default {
  name: "ResultTetheredDevice",
  components: {
    IconSet,
    Loading,
    NoDataAvailable,
    LoadingAnimation,
  },
  data() {
    return {
      seeMore: false,
      tmpDevice: null,
      tmpVendor: null,
      tmpPeriods: [],
      defaultImage: "/images/no-image-device.jpg",
    };
  },
  computed: {
    ...mapState("tetheredDevice", [
      "status",
      "from_date",
      "to_date",
      "status_tethered_device",
      "tethered_device",
    ]),
    route() {
      return this.$route;
    },
    route_name() {
      return this.$route.name;
    },
  },
  watch: {},
  methods: {
    handleHistoryTimestamp(device, vendor, periods) {
      this.tmpDevice = device;
      this.tmpVendor = vendor;
      this.tmpPeriods = periods;
      this.seeMore = true;
    },
  },
  filters: {},
  created() {
    //
  },
  mounted() {},
};
</script>

<style lang="sass" scoped>
.tethered-item
    @apply .block .rounded .p-4
    border: 1px solid lighten($color-secondary-500, 5% )
    .more-date
        color: $color-main-500
        &:hover
            text-decoration: underline
</style>
