<template lang="pug">
.users-shell
    .box
        .box__header
            TitlePage(
                title="Users"
                :breadcrumb="breadcrumb"
                )
        .box__body
            router-view
</template>

<script>
import TitlePage from '@/components/shell/TitlePage.vue'
export default {
    name: 'UsersShell',
    components: {
        TitlePage,
    },
    data() {
        return {
            // 
        }
    },
    computed: {
        route() {
            return this.$route;
        },
        id() {
            return this.$route.params.id;
        },
        breadcrumb() {
            let breadcrumb = [
                {
                    name: 'Users',
                    url: '/users',
                },
            ];

            if (this.route.matched[0] && this.route.matched[0].components.default.name === 'UsersShell' && this.route.matched[1]) {
                switch(this.route.matched[1].name) {
                    case 'pageUsersCreate':
                        breadcrumb.push({
                            name: 'Add User',
                            url: '/users/create',
                        });
                        break;

                    case 'pageUsersEdit':
                        breadcrumb.push({
                            name: 'Edit User',
                            url: '/users/'+this.id+'/edit',
                        });
                        break;

                    case 'pageUsersLogActivity':
                        breadcrumb.push({
                            name: 'Log Activity',
                            url: '/users/'+this.id+'/log-activity',
                        });
                        break;

                    default:
                        // code block
                }
            }

            return breadcrumb;
        },
    },
    watch: {
        route(newData) {
            if (newData.matched[0] && newData.matched[0].components.default.name === 'UsersShell')
                window.scrollTo(0,0);
        },
        id() {},
        breadcrumb() {},
    },
    created() {
        if (this.$route.params.id && this.$route.params.id === 'create')
            this.$router.push({ name: 'pageUsersCreate', params: {} });
    },
    mounted() {
        // 
    },
}
</script>