<template lang="pug">
.main-app
    .main-app__header
        GlobalHeader
        //- GlobalHeaderSimplify
    .main-app__content
        .box
            router-view
</template>

<script>
import GlobalHeader from '@/components/shell/Header.vue'
// import GlobalHeaderSimplify from '@/components/shell/HeaderSimplify.vue'
export default {
    name: 'AppLayout',
    components: {
        GlobalHeader,
        // GlobalHeaderSimplify
    },
    methods: {
        overflowHidden(modalOpen){
            let mainbody = document.getElementsByTagName('body')
            let HeaderShell = document.getElementsByClassName('main-app__header')
            if(modalOpen) {
                mainbody[0].classList.add('overflow-hidden');
                HeaderShell[0].classList.add('is-hide');
            } else {
                mainbody[0].classList.remove('overflow-hidden');
                HeaderShell[0].classList.remove('is-hide');
            }
        }
    },
    mounted() {
        EventEmit.$on('overflowHidden', (modalOpen) => {
            this.overflowHidden(modalOpen)
        });
    },
}
</script>

<style lang="sass">
    @import '@/assets/sass/shell/main.sass'
</style>