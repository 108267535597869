import axios from "@/axios-main";
import axiosOrigin from "axios";

let cancelFaceRecognition;
var cancelToken = axiosOrigin.CancelToken;

const faceRecognition = {
  namespaced: true,
  state: {
    status_face_recognition: {
      status: false,
      cancel: null,
      code: null,
    },
    
    face_recognition: {},
  },
  mutations: {
    setStatusFaceRecognition: (state, status) => {
      state.status_face_recognition = status;
    },

    setFaceRecognition: (state, data) => {
      state.face_recognition = data;
    },
  },
  getters: {},
  actions: {

    setEmpty({ commit }) {
      commit('setStatusFaceRecognition', {
        status: false,
        cancel: null,
        code: null,
      });
      commit('setFaceRecognition', {});
    },

    async getFaceRecognition({ state, commit, dispatch }, image) {

      if(state.status_face_recognition.cancel != null && state.status_face_recognition.status === 'loading')
        await state.status_face_recognition.cancel('Operation canceled by the user.');

      let params = new FormData();
      params.append('upload', image);

      await axios.post('api/v1/service/face-reidentification/search', params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelFaceRecognition = c;
          commit('setStatusFaceRecognition', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setFaceRecognition', data);

        if (Object.keys(data).length===0)
          result.status = 'empty';

        commit('setStatusFaceRecognition', result);
      })
      .catch((resp) => {
        console.log(resp)
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelFaceRecognition;
        }

        commit('setStatusFaceRecognition', result);
        if (!axiosOrigin.isCancel(resp)) 
          dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
      });
    },
  }
};

export default faceRecognition;
