<template lang="pug">
ResultDcd(
    source-value="internal"
    :key-value="decryptedKey[0]"
    :type-value="decryptedKey[1]"
)
</template>
<script>
import ResultDcd from '@/pages/dcd/Result.vue'
import { decrypt } from '@/util/crypto';
export default {
    name: 'ResultDigitalFootprint',
    components: {
        ResultDcd
    },
    computed: {
        key() {
            return this.$route.params.key;
        },
        isKey() {
            return this.key ? true : false;
        },
        decryptedKey() {
            let decryptData = decrypt(this.key);
            decryptData = decryptData.split('&&');

            return decryptData
        },
    },
}
</script>