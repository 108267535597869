<template lang="pug">
.box__body
    .card.card--table.card--no-bg
        .card__header.card--table__header
            h2.text-xl {{ page_title }}
            .header-cta
                .block
                    button.btn-default.m-btn-icon(@click="handleBack()")
                        IconSet(type="chevron-left")
                        span.m-hide.block.ml-3 Back
        .card__body
            .container-form
                .group-fields.border-b
                    .field
                        .field__label
                            label.block.text-sm.font-bold Type
                        .field__form
                            .relative
                                select.field-style(v-model="model.type" :disabled="route_name == 'pageDcdEdit'")
                                    option(value="msisdn") MSISDN
                                    option(value="email") Email
                                    option(value="nik") National ID
                                    option(value="name") Name
                                    option(value="id_convert") ID Converter
                                .field-icon
                                    IconSet(
                                        type="chevron-down"
                                    )  
                    .field
                        .field__label
                            label.block.text-sm.font-bold Keyword <sup>*</sup>
                        .field__form
                            input.field-style(
                                :disabled="route_name == 'pageDcdEdit'"
                                type="text"
                                placeholder="Write keyword here"
                                v-model="model.key"
                                :type="['email','name'].includes(model.type)?'text':'number'"
                            )
                            .py-3
                                .message.text-xs.text-red-500(v-if="$v.model.key.$error") {{ error_key_custom || 'This key is required &amp; max length is 255 char.' }}
                                .message.text-xs.text-red-500(v-if="error_key !== ''") {{ error_key }}

                    .field
                        .field__label
                            label.block.text-sm.font-bold Case Name <sup>*</sup>
                        .field__form
                            .relative(v-if="status_case_list.status === 'loading'")
                                .field-style
                                    .field-style--mirroring.field-style--loading
                                        Spinner(show="true" size="32")
                            .field-multiple-select
                                multiselect(
                                    v-if="case_management_list.data && case_management_list.data.length > 0"
                                    :value="casesValue",
                                    :options="case_management_list.data ? case_management_list.data : []",
                                    :multiple="true",
                                    placeholder="Select Case",
                                    label="operation_name",
                                    track-by="id",
                                    @input="onChange"
                                    :disabled="route_name == 'pageDcdEdit'"
                                )
                                .py-3
                                    .message.text-xs.text-red-500(v-if="$v.model.case_id.$error") This case name is required.
                                    .message.text-xs.text-red-500(v-if="error_case_id !== ''") {{ error_case_id }}

                .group-fields.border-b
                    .field
                        .field__label
                            label.block.text-sm.font-bold Description (optional)
                        .field__form
                            textarea.field-style(
                                placeholder="Write Description here..."
                                v-model="model.description"
                            )
                            .py-3
                                .message.text-xs.text-red-500(v-if="$v.model.description.$error") Max length is 255 char.
                                .message.text-xs.text-red-500(v-if="error_description !== ''") {{ error_description }}
                        
                .group-fields.border-b(v-if="route_name === 'pageDcdCreate'")
                    .field
                        .field__label
                            label.block.text-sm.font-bold Note (optional)
                        .field__form
                            textarea.field-style(
                                placeholder="Write note here..."
                                v-model="model.notes"
                            )
                            .py-3
                                .message.text-xs.text-red-500(v-if="$v.model.notes.$error") Max length is 255 char.
                                .message.text-xs.text-red-500(v-if="error_notes !== ''") {{ error_notes }}
                
                .flex.items-center.justify-end.mb-8
                    button.btn.btn-default(type="button" @click="reset()")
                        | Reset
                    //- button.btn.btn-primary.ml-4(type="submit" @click="submit($event, true)")
                        | Save &amp; Search
                    button.btn.btn-primary.ml-4(type="submit" @click="submit($event)")
                        | Save
</template>

<script>
import IconSet from '@/components/IconSet.vue';
import Spinner from '@/components/Spinner.vue';
import Multiselect from 'vue-multiselect';
import { mapState } from 'vuex';
import { required, maxLength } from "vuelidate/lib/validators";

function checkFormatKey () {
    const value = this.model.key
    let status = true
    this.error_key_custom = ""

    const regexMail = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    let isNumber = value.match(/^-?\d+$/)
    
    let { is_msisdn, wording_msisdn } = this.msisdnValidation(value)
    let isEmail = value.match(regexMail)
    
    if (this.model.type == 'msisdn') {
        if (!is_msisdn) {
            this.error_key_custom = wording_msisdn
            status = false
        } 
    } else if (this.model.type == 'email') {
        if (!isEmail) {
            this.error_key_custom = "Please fill with valid email"
            status = false
        } 
    } else if (['nik', 'id_convert'].includes(this.model.type)) {
        if (!isNumber) {
            this.error_key_custom = "Please fill with valid number"
            status = false
        } 
    }
    return status
}

export default {
    name: 'pageDcdForm',
    components: {
        IconSet,
        Spinner,
        Multiselect,
    },
    data() {
        return {
            model: {
                key: '',
                type: 'msisdn',
                case_id: [],
                description: '',
                notes: '',
            },

            // error message
            error_key_custom: '',
            error_key: '',
            error_type: '',
            error_case_id: '',
            error_description: '',
            error_notes: '',
            
        }
    },
    validations: {
        model: {
            key: {
                required,
                maxLength: maxLength(255),
                formatKey: checkFormatKey
            },
            type: {
                required,
                maxLength: maxLength(255)
            },
            case_id: {
                required,
            },
            description: {
                maxLength: maxLength(255)
            },
            notes: {
                maxLength: maxLength(255)
            },
        },
    },
    computed: {
        page_title() {
            let title = 'Edit Digital Cyber Data';
            if (this.route_name === 'pageDcdCreate')
                title = 'Search New Digital Cyber Data';
            return title;
        },
        ...mapState({
            status_detail_dcd: (state) => state.dcd.status_detail,
            detail_dcd: (state) => state.dcd.detail,
            status_case_list: (state) => state.caseManagement.status_list,
            case_management_list: (state) => state.caseManagement.list,
        }),
        route_name() {
            return this.$route.name;
        },
        id() {
            return this.$route.params.id;
        },
        casesValue: {
            get() {
                if (!this.model.case_id) return null
                try {
                    return this.model.case_id.map(value => this.findOption(value.id)).filter(value => value.id)
                } catch (error) {
                    return null
                }
            },
            set(v) {
                this.model.case_id=v.map(value => {
                    let rObj = {}
                    rObj['id'] = value.id
                    return rObj
                })
            }
        },
    },
    watch: {
        route_name() {
            this.getData();
        },
        detail_dcd(newData) {
            this.model.key = (newData.key ? newData.key : '');
            this.model.type = (newData.type ? newData.type : 'msisdn');
            this.model.case_id = (newData.case_id ? newData.case_id : []);
            this.model.description = (newData.description ? newData.description : '');
        },

    },
    methods: {
        handleBack(){
            this.$router.push('/dcd');
        },
        setEmptyModel(all=true) {
            for (let key in Object.keys(this.model)) {
                if (all === true)
                    if (key == 'type') {
                        this.model[Object.keys(this.model)[key]] = 'msisdn';
                    } else {
                        this.model[Object.keys(this.model)[key]] = '';
                    }
            }
        },
        setErrors(empty=true, errors=null) {
            if (empty === true) {
                this.error_key_custom = '';
                this.error_key = '';
                this.error_type = '';
                this.error_case_id = '';
                this.error_description = '';
                this.error_notes = '';
            } else if (empty !== true && errors !== null) {
                this.error_key = (errors.key ? errors.key : '');
                this.error_type = (errors.type ? errors.type : '');
                this.error_case_id = (errors.case_id ? errors.case_id : '');
                this.error_description = (errors.description ? errors.description : '');
                this.error_notes = (errors.notes ? errors.notes : '');
            }
        },
        getData() {
            this.reset(true);

            if (this.route_name === 'pageDcdEdit' || this.route_name === 'pageDcdCreate') {
                this.$store.dispatch('caseManagement/getList', [100, 1, null, 'active']);
            }
            if (this.route_name === 'pageDcdEdit') {
                EventEmit.$emit('showLoading', true);

                this.$store.dispatch('dcd/getDetail', this.id)
                .then((resp) => {
                    // console.log({resp})
                    if (resp.result === 'success') {
                        EventEmit.$emit('showLoading', false);
                    } else {
                        var code = resp.code || null 
                        var message = null;

                        let statusCodeCheck = ["403", 403]
                        if (statusCodeCheck.includes(code))
                            message = resp && resp.data && resp.data.message? resp.data.message : null;
                        EventEmit.$emit('error', [message]);
                    }
                });
            } else {
                if (this.route_name === 'pageDcdCreate')
                    this.$store.commit('dcd/setDetail', {});
            }
        },
        onChange(value) {
            this.casesValue = value
        },
        findOption(value) {
            const opt = this.case_management_list.data
            try {
                return opt.find(option => option['id'] === value)
            } catch (error) {
                return opt
            }
        },

        reset(direct=false) {
            if (direct === true) {
                this.setEmptyModel(false);
                // reset vuelidate
                this.$nextTick(() => { this.$v.$reset(); });
                this.setErrors(true);
                window.scrollTo(0,0);
            } else {
                this.$swal.fire({
                    icon: 'warning',
                    title: 'Are you sure you want to reset form?',
                    // text: id,
                    confirmButtonText: 'Yes!',
                    confirmButtonColor: '#DD6B55',
                    showCancelButton: true,
                    allowOutsideClick: false,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        if (this.route_name === 'pageDcdCreate') {
                            this.setEmptyModel(false);
                            // reset vuelidate
                            this.$nextTick(() => { this.$v.$reset(); });
                            this.setErrors(true);
                            window.scrollTo(0,0);
                        } else {
                            this.getData();
                        }
                    }
                });
            }
        },
        submit(e, save_search=false) {
            e.preventDefault();
            this.$v.$touch();

            if (this.$v.model.$invalid) {
                for (let key in Object.keys(this.$v.model)) {
                    const input = Object.keys(this.$v.model)[key];
                    if (input.includes("$")) return false;
                    if (this.$v.model[input].$error) {
                        break;
                    }
                }
                // EventEmit.$emit('error', 'Please fill the form correctly.');
                // EventEmit.$emit('showLoading', false);
                window.scrollTo(0,0);
                return;
            }
            
            EventEmit.$emit('showLoading', true);

            let model_data = Object.keys(this.model).map(
                (key) => (key !== 'case_id')?this.model[key]:[JSON.stringify(this.model[key])]
            );

            if (this.route_name === 'pageDcdCreate') {

                this.$store.dispatch('dcd/create', model_data)
                .then((resp) => {
                    if (resp.result === 'success') {
                        if (save_search === false) {
                            this.$router.push('/dcd');
                        } else {
                            this.$router.push('/dcd/'+ resp.data.data.id +'/detail/external');
                        }

                        this.$swal.fire({
                            icon: 'success',
                            title: 'Data successfully created!',
                            timer: 3000,
                        });
                    } else {
                        if (resp.data.message && resp.data.message.errors) {
                            this.setErrors(false, resp.data.message.errors);
                            // EventEmit.$emit('error', null, resp.data.message.errors);
                            EventEmit.$emit('showLoading', false);
                            window.scrollTo(0,0);
                        } else {
                            var code = resp.code || null 
                            var message = null;

                            let statusCodeCheck = ["403", 403]
                            if (statusCodeCheck.includes(code))
                                message = resp && resp.data && resp.data.message? resp.data.message : null;
                            EventEmit.$emit('error', [message]);
                        }
                    }
                });

            // pageDcdEdit
            } else {

                model_data = [this.id].concat(model_data);

                this.$store.dispatch('dcd/update', model_data)
                .then((resp) => {
                    if (resp.result === 'success') {                        
                        if (save_search === false)
                            this.$router.push('/dcd');
                        else {
                            this.$router.push('/dcd/'+ this.id +'/detail/external');
                        }

                        this.$swal.fire({
                            icon: 'success',
                            title: 'Data successfully updated!',
                            timer: 3000,
                        });
                    } else {
                        if (resp.data.message && resp.data.message.errors) {
                            this.setErrors(false, resp.data.message.errors);
                            // EventEmit.$emit('error', null, resp.data.message.errors);
                            EventEmit.$emit('showLoading', false);
                            window.scrollTo(0,0);
                        } else {
                            var code = resp.code || null 
                            var message = null;

                            let statusCodeCheck = ["403", 403, "430", 430]
                            if (statusCodeCheck.includes(code))
                                message = resp && resp.data && resp.data.message? resp.data.message : null;
                            EventEmit.$emit('error', [message]);
                        }
                    }
                });
            }
        },
    },
    mounted() {
        this.getData();
    },
}
</script>