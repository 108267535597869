import axios from "@/axios-main";
// import config from "@/config/api";
// import router from "@/router";
import moment from 'moment';
import { encryptAPI, decryptAPI } from '@/util/crypto';

const auth = {
  namespaced: true,
  state: {
    module: 'Auth',
    token: null,
    not_trace_log: null,
    user: null,
    profile: {},
  },
  mutations: {
    authUser(state, userData) {
      state.user = userData.user;
      let bearer_token = null
      if (userData.token) {
        state.token = userData.token;
        bearer_token = "Bearer " + userData.token
        axios.defaults.headers.common["x-authorization"] = bearer_token;
      }
      
      if (userData.not_trace_log && bearer_token) {
        state.not_trace_log = userData.not_trace_log
        axios.defaults.headers.common["x-authorization"] = "1"+bearer_token;
      }
    },
    // storeUser(state, user) {
    //   state.userInfo = user;
    // },
    clearAuth(state) {
      state.token = null;
      state.user = null;
      state.not_trace_log = null;
      
      axios.defaults.headers.common["x-authorization"] = null;
    },
    setProfile(state, data) {
      state.profile = data;
    },
  },
  getters: {
    isAuthenticated(state) {
      if (!state.token) return false;
      return true;
    },
    getUser(state) {
      return state.user;
    },
    getToken(state) {
      return state.token;
    }
  },
  actions: {
    // setLogoutTimer({ dispatch }, expiresIn) {
    //   setTimeout(() => {
    //     dispatch("logout");
    //   }, expiresIn * 1000);
    // },
    // tryAutoLogin({ commit, dispatch }) {
    //   if (!localStorage.getItem("token")) {
    //     return false;
    //   }
    //   if (new Date() >= localStorage.getItem("expiresDate")) {
    //     return false;
    //   }

    //   commit("authUser", {
    //     token: localStorage.getItem("token")
    //   });
    //   return !!dispatch("fetchUser");
    // },

    login({ commit, dispatch }, [username, password, url=null, web_device_id=null]) {
      url = url || 'api/v1/auth/login'

      let params = new FormData();

      params.append('email', username);
      params.append('password', password);
      if (web_device_id) {
        const now = moment().unix();
        params.append('web_device_id', encryptAPI(`${web_device_id}:${now}`));
      }

      return axios.post(url, params)
      .then((resp) => {
        try {
          const dataApi = JSON.parse(decryptAPI(resp.data.data))
          if (dataApi?.token) {
            commit('authUser', dataApi);
            return {result: 'success', data: dataApi.user};
          } else {
            return {result: 'failed', data: dataApi};
          }
        } catch (error) {
          console.log('error login')
          console.log(error)
          return {result: 'failed', data: null};
        }
      })
      .catch((resp) => {
        // console.log(resp)
        return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
      });
    },

    getKey({ commit, dispatch }, [username, password]) {
      let params = new FormData();

      params.append('email', username);
      params.append('password', password);

      return axios.post('api/v1/auth/get-key', params)
      .then((resp) => {
        let app_key = resp?.data?.app_key || ''
        let secret_key = resp?.data?.secret_key || ''
        
        if (app_key && secret_key) {
          sessionStorage.setItem('x-app-key', app_key)
          sessionStorage.setItem('x-secret-key', secret_key)
          sessionStorage.setItem('dev_mode_label', "DEV MODE")

          axios.defaults.headers.common["x-app-key"] = app_key
          axios.defaults.headers.common["x-secret-key"] = secret_key
          
          return {result: 'success'}
        }
      })
      .catch((resp) => {
        return {result: 'failed'}
      });
    },

    logout({ commit, dispatch }) {

      return axios.delete('api/v1/auth/logout')
      .then((resp) => {
        // console.log(resp)
        if (resp.status === 200) {
          
          // commit('setDownloadApp', {});
          commit("clearAuth");

          return {result: 'success', data: resp.data};
        } else {
          return {result: 'failed', data: resp.data};
        }
      })
      .catch((resp) => {
        // console.log(resp)
        dispatch('errors/handleApiResponse', [(resp.response && resp.response.status ? resp.response.status : null), (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null), 'Logout'], { root: true });

        return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
      });

      // router.push({ name: 'LoginPage' });
    },

    // getProfile({ commit, dispatch }, [id]) {
    getProfile({ commit, dispatch, getters }) {

      let params = new FormData();

      // params.append('id', id);
      params.append('id', getters.getUser.id);
      let userModule = getters.getUser.role?.module
    
      return axios.post('api/v1/profile', params)
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        // update local storage
        commit('setProfile', data);
        if (data.role){
          data.role.module = userModule
          commit('authUser', { user: data });
        }
        return 'success';
      })
      .catch((resp) => {
        // console.log(resp)
        dispatch('errors/handleApiResponse', [(resp.response && resp.response.status ? resp.response.status : null), (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null), 'Get Profile'], { root: true });

        return 'failed';
      });
    },

    // updateProfile({ commit, dispatch, getters }, [name=null, email=null, phone=null, role_id=null, status=null, password=null]) {
    updateProfile({ commit, dispatch, getters }, [name=null, email=null, phone=null, password=null]) {

      let params = new FormData();
      // params.append('id', id);
      params.append('id', getters.getUser.id);
      params.append('name', name);
      params.append('email', email);
      params.append('phone', phone);
      // params.append('role_id', role_id);
      params.append('role_id', getters.getUser.role.id);
      // params.append('client_id', client_id);
      params.append('client_id', getters.getUser.client.id);
      // params.append('status', status);
      params.append('status', getters.getUser.status);
      params.append('password', password);

      return axios.post('api/v1/profile/update', params)
      .then((resp) => {
        // console.log(resp.data)
        
        if (resp.status === 200 && resp.data.message === 'Data Successfully Updated') {
          return {result: 'success', data: resp.data};
        } else {
          return {result: 'failed', data: resp.data};
        }
      })
      .catch((resp) => {
        // console.log(resp)
        dispatch('errors/handleApiResponse', [(resp.response && resp.response.status ? resp.response.status : null), (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null),'Update Profile'], { root: true });

        return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
      });
    },
  },
};

export default auth;
