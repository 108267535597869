import axios from "@/axios-main";
import axiosOrigin from "axios";

let cancelProfileIdentity, cancelProfileFamily;
var cancelToken = axiosOrigin.CancelToken;

const profile = {
  namespaced: true,
  state: {
    module: 'Profile',
    status: false,
    status_profile_identity: {
      status:false,
      cancel:null,
      code:null
    },
    status_profile_family: {
      status:false,
      cancel:null,
      code:null
    },

    profile_identity: {},
    profile_family: {},
    fetchProfile: false,
    dataRequest: [],
  },
  mutations: {
    setStatus: (state, status) => {
      state.status = status;
    },
    setStatusProfileIdentity: (state, status) => {
      state.status_profile_identity = status;
    },
    setStatusProfileFamily: (state, status) => {
      state.status_profile_family = status;
    },

    setProfileIdentity: (state, data) => {
      state.profile_identity = data;
    },
    setProfileFamily: (state, data) => {
      state.profile_family = data;
    },
    setFetchProfile: (state, data) => {
      state.fetchProfile = data;
    },
    setDataRequest: (state, data) => {
      state.dataRequest = data;
    },
  },
  getters: {},
  actions: {
    async getResultData({ dispatch, commit }, [key, type, service_source=null]) {

      await dispatch('setLoading');
      
      await dispatch('getProfileIdentity', [key, type, service_source]);
      await dispatch('getProfileFamily', [key, type, service_source]);

      commit('setStatus', true);
    },

    async setLoading({ commit, state }) {
      await commit('setStatus', 'loading');

      var loading = function(sub_mod) {
        return {
          status: 'loading',
          cancel: state[sub_mod].cancel,
          code: null
        };
      };

      await commit('setStatusProfileIdentity', loading('status_profile_identity'));
      await commit('setStatusProfileFamily', loading('status_profile_family'));
    },

    async setEmpty({ state, commit }) {
      commit('setStatus', false);

      let default_status = {
        status: false,
        cancel: null,
        code: null
      };

      if (state.status_profile_identity.cancel !== null && state.status_profile_identity.status === 'loading')
        await state.status_profile_identity.cancel('Operation canceled by the user.');
      commit('setStatusProfileIdentity', default_status);

      if (state.status_profile_family.cancel !== null && state.status_profile_family.status === 'loading')
        await state.status_profile_family.cancel('Operation canceled by the user.');
      commit('setStatusProfileFamily', default_status);

      commit('setProfileIdentity', {});
      commit('setProfileFamily', {});
    },

    async getProfileIdentity({ commit, dispatch, state }, [key, type, service_source]) {

      if (state.status_profile_identity.cancel !== null && state.status_profile_identity.status === 'loading')
        await state.status_profile_identity.cancel('Operation canceled by the user.');

      let service = 'service';
      if (service_source === 'internal')
        service = 'service-int';
      // } else if (service_source === 'external') {
      //   service = 'service-ext'
      // }

      let params = new FormData();
      params.append('key', key);
      params.append('type', type);

      return await axios.post('api/v1/'+service+'/rig/ktp', params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelProfileIdentity = c;
          commit('setStatusProfileIdentity', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        // console.log(resp)
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setProfileIdentity', data);

        if (Object.keys(data).length===0)
          result.status = 'empty';
        
        commit('setStatusProfileIdentity', result);

        return {
          result: 'success',
          data: data
        };
      })
      .catch((resp) => {
        // console.log(resp.response)
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelProfileIdentity;
        }

        commit('setStatusProfileIdentity', result);
        if (!axiosOrigin.isCancel(resp))
        dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null), 'Personal Information'], { root: true });

        return {
          // result: 'failed',
          result: result.status,
        };
      });
    },

    async getProfileFamily({ commit, dispatch, state }, [key, type, service_source]) {

      if (state.status_profile_family.cancel !== null && state.status_profile_family.status === 'loading')
        await state.status_profile_family.cancel('Operation canceled by the user.');

      let service = 'service';
      if (service_source === 'internal')
        service = 'service-int';
      // } else if (service_source === 'external') {
      //   service = 'service-ext'
      // }

      let params = new FormData();
      params.append('key', key);
      params.append('type', type);

      await axios.post('api/v1/'+service+'/rig/kk', params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelProfileFamily = c;
          commit('setStatusProfileFamily', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        // console.log(resp)
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if(data.data !== null)
          commit('setProfileFamily', data);
        else
          commit('setProfileFamily', {});

        if (Object.keys(data).length===0 || data.data === null)
          result.status = 'empty';
        
        commit('setStatusProfileFamily', result);
        return {
          result: 'success',
          data: data
        };

      })
      .catch((resp) => {
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelProfileFamily;
        }

        commit('setStatusProfileFamily', result);
        if (!axiosOrigin.isCancel(resp))
        dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null), 'Family Information'], { root: true });

        return {
          // result: 'failed',
          result: result.status,
        };
      });
    },
  },
};

export default profile;
