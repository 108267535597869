<template lang="pug">
.page-prediction-name
    ActionSearchResult(
        v-if="status === false && $route.matched[0].name !== 'pageTargetDetail'"
        :title="title"
    )

    template(v-else)
        //- title
        TitleResult(:title="title")

        .box
            //- .box__header
                TitlePage(
                    title="Prediction Name"
                    :breadcrumb="breadcrumb"
                    )

            .box__body
                .flex.gap-6.w-full
                    .card(class="w-7/12")
                        .card__header
                            h2.text-xl List of name
                            
                        .card__body.p-6
                            template(v-if="status_prediction_name.status === 'loading'")
                                template(v-if="route_name === 'TargetDetailPredictionName'")
                                    Loading(show="true")
                                template(v-else)
                                    LoadingAnimation(show="true" title="prediction-name")
                            template(v-else)
                                ul.grid.grid-cols-2.gap-x-4.gap-y-6
                                    li(v-for="(value, key) in prediction_name.tags")
                                        span {{ key }}({{value}})
                    
                    .card(class="w-5/12")
                        .card__header
                            //- h2.text-xl Word Cloud
                        .card__body
                            .p-10
                                template(v-if="status_prediction_name.status === 'loading'")
                                    template(v-if="route_name === 'TargetDetailPredictionName'")
                                        Loading(show="true")
                                    template(v-else)
                                        LoadingAnimation(show="true" title="prediction-name")
                                template(v-else)
                                    //- https://github.com/SeregPie/VueWordCloud
                                    vue-word-cloud(
                                        style=" height: 480px; width: 100%;"
                                        :words="resultWords"
                                        :showTooltip="true"
                                        :color="([, weight]) => weight > 50 ? '#FFF13A' : weight > 40 ? '#DBCD2A' : weight > 30 ? '#B7AA1D' : weight > 20 ? '#938712' : '#7A6F0B'"
                                        font-family="Roboto"
                                    )

            //- Old design ===
            //- .box__body
                .card
                    .card__header
                        h2.text-xl Popular Name
                        
                    .card__body
                        template(v-if="status_prediction_name.status === 'loading'")
                            Loading(show="true")
                        
                        template(v-else)
                            template(v-if="prediction_name && Object.keys(prediction_name).length > 0")
                                ul.prediction-name-lists.single-column
                                    template(v-if="prediction_name.prediction_name !== undefined && prediction_name.prediction_name.length > 0")
                                        li(v-for="name in prediction_name.prediction_name" v-tooltip="name && name.length > 30 ? name : ''")
                                            p {{ name ? (name.length > 30 ? name.substr(0, 30)+'...' : name) : '-' }}

                                    template(v-else-if="possibleNickname !== undefined && possibleNickname.length > 0")
                                        li(v-for="name in possibleNickname" v-tooltip="name && name.length > 30 ? name : ''")
                                            p {{ name ? (name.length > 30 ? name.substr(0, 30)+'...' : name) : '-' }}

                                    li(v-else) {{ prediction_name.displayName ? prediction_name.displayName : '-' }}

                            NoDataAvailable(v-else)

                .card
                    .card__header
                        h2.text-xl Lists Name
                        .header-cta
                            span.mr-4.text-xs Current View
                            button.btn-primary-o.btn-small(
                                v-on:click="currentViewLists = !currentViewLists"
                                v-tooltip="'Click for change view to '+ (currentViewLists === true ? 'columns' : 'lists') +'.'"
                            )
                                template(v-if="currentViewLists === true")
                                    span Lists
                                template(v-else)
                                    span Columns
                    .card__body
                        template(v-if="status_prediction_name.status === 'loading'")
                            Loading(show="true")

                        template(v-else)
                            ul.prediction-name-lists(
                                v-if="prediction_name && Object.keys(prediction_name).length > 0 && prediction_name.tags && prediction_name.tags.length > 0"
                                v-bind:class="{'single-column': currentViewLists}"
                            )
                                li(v-for="name in prediction_name.tags" v-tooltip="name && name.length > 30 ? name : ''")
                                    p {{ name ? (name.length > 30 ? name.substr(0, 30)+'...' : name) : '-' }}

                            NoDataAvailable(v-else)

</template>

<script>
import TitleResult from '@/components/result/Title.vue';
import ActionSearchResult from '@/components/result/ActionSearchResult.vue'
import IconSet from '@/components/IconSet.vue'
import { mapState } from 'vuex';
// import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import moment from 'moment';
import { decrypt } from '@/util/crypto';
import Loading from '@/components/Loading.vue';
import LoadingAnimation from '@/components/LoadingAnimation.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';


import VueWordCloud from 'vuewordcloud';


// import TitlePage from '@/components/shell/TitlePage.vue'
// import IconSet from '@/components/IconSet.vue';

export default {
    name: 'pageMonitoringLogIndex',
    components: {
        // TitlePage,
        TitleResult,
        ActionSearchResult,
        IconSet,
        // DateRangePicker,
        Loading,
        NoDataAvailable,
        LoadingAnimation,
        [VueWordCloud.name]: VueWordCloud,
    },
    data() {
        return {
            title:"Prediction Name",
            // showFilter: false,
            // phonebookGrid : true,
            currentViewLists: false,
            resultWords: [],

            // ----------------------------

            breadcrumb: [
                {
                    name: 'Prediction Name',
                    // url: 'target'
                }
            ],
        }
    },
    computed: {
        ...mapState('predictionName', [
            'status',
            'status_prediction_name',
            'prediction_name',
        ]),
        route() {
            return this.$route;
        },
        route_name() {
            return this.$route.name;
        },           
        letters() {
            let letters = [];
            for(let i = "A".charCodeAt(0); i <= "Z".charCodeAt(0); i++) {letters.push(String.fromCharCode([i]));
            }
            return letters;
        },
        possibleNickname(){
            if ((this.prediction_name && this.prediction_name.tags != undefined && this.prediction_name.tags.length > 0) && this.prediction_name.prediction_name == undefined && this.prediction_name.prediction_name.length == 0) {

                var inputNickname = this.inputNickname(this.prediction_name.tags);
                var sortByFrequency = this.sortByFrequency(inputNickname);
                var getTop3Nickname = this.getTop3Nickname(sortByFrequency[0], this.prediction_name.tags);

                return getTop3Nickname;
            } else {
                return [];
            }
        },
        groupedContacts: function() {
            if (Object.keys(this.prediction_name).length > 0) {
                var groups = {};
                // Loops every contact
                var tags = this.prediction_name.tags
                tags.forEach(function(contact) {
                    //Gets the contact's first char in the contact's name
                    var firstLetter = contact.charAt(0);
                    //Checks if the 'groups' object contains the key for the contact's first letter
                    if (!groups[firstLetter]) {
                        //If it doesn't, creates it as an empty array.
                        groups[firstLetter] = [];
                    }

                    var entry = groups[firstLetter];
                    //Adds the contact to the group
                    entry.push(contact);
                });
                // this.dataPredictionNickname = groups;
                return groups;
            } else {
                return {};
            }
        },
    },
    watch: {
        letters() {},
        possibleNickname() {
            var tags = this.prediction_name.tags
            this.resultWords = Object.keys(tags).map((key) => [key, Number(tags[key])]);            
        },
        groupedContacts() {},
    },
    methods: {
        toggleSidebar() {
            EventEmit.$emit('toggleSidebar')
        },
        inputNickname(array) {
            var tmpArray = [];
            for (var i = 0; i < array.length; i++) {
                var split = array[i].split(' ');
                tmpArray = tmpArray.concat(split);
            }

            return tmpArray;
        },
        sortByFrequency(array) {
            var frequency = {}, value;

            // compute frequencies of each value
            for(var i = 0; i < array.length; i++) {
                value = array[i];
                if(value in frequency) {
                    frequency[value]++;
                }
                else {
                    frequency[value] = 1;
                }
            }

            // make array from the frequency object to de-duplicate
            var uniques = [];
            for(value in frequency) {
                uniques.push(value);
            }

            // sort the uniques array in descending order by frequency
            function compareFrequency(a, b) {
                return frequency[b] - frequency[a];
            }

            return uniques.sort(compareFrequency);
        },
        getTop3Nickname(key, array){
            var tmpArray = []
            for (var i = 0; i < array.length; i++) {
                if (tmpArray.length === 3) {
                    break;
                }
                if(array[i].includes(key)){
                    tmpArray.push(array[i])
                }
            }
            return tmpArray
        },
    },
    mounted() {
        // here
    },
}
</script>

<style lang="sass" scoped>
    .prediction-name-lists
        columns: 4
        &.single-column
            columns: 1

        li 
            @apply .py-2
            p
                font-size:  18px

</style>