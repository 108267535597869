<template lang="pug">
.box__body
    .card.card--table.card--no-bg
        .card__header.card--table__header
            h2.text-xl 
        .card__body
            .container-form
                .group-fields.border-b
                    .field.w-full.mb-8
                        label.block.text-sm.font-bold Type <sup>*</sup> 
                        .relative
                            input.field-style(
                                placeholder="Search and choose a type"
                                type="text"
                                v-model="type_keyword" 
                                @focus="changeIsTypingType(true)"
                                @blur="changeIsTypingType(false)"
                            )
                            .field-icon
                                IconSet(type="search")

                            //- show while case was choosen
                            .field-style.field-style--mirroring(v-if="Object.keys(type_selected).length > 0")
                                .flex.items-center.w-full.justify-between
                                    span {{ type_selected.name }}
                                    button.btn-icon-24(@click="setEmptyType" v-tooltip="'Remove type'")
                                        IconSet(type="close")
                        //- show while typing the field - autocomplete
                        .block.box-searching(v-if="is_typing")
                            .block
                                label.small-heading.text-gray-500 Choose a Type
                                ul(v-for="(data,index) in list_type_feature_filtered")
                                    li(@click="selectType(data)") {{data.name}}
                            //- if the case not found, show this information
                        .pt-3
                            .message.text-xs.text-red-500(v-if="$v.type_selected.$error") This type is required
                    .field
                        .field__label
                            label.block.text-sm.font-bold Value <sup>*</sup>
                        .field__form
                            textarea.field-style(placeholder="Write Value here (string or json)" v-model="value_data")
                            .pt-3
                                .message.text-xs.text-red-500(v-if="$v.value_data.$error") This value is required
                                .message.text-xs.text-red-500(v-if="error_value_data !== ''") {{ error_value_data }}
                    .flex.items-center.mb-6
                        // .block(v-if="isWatchlistQuota")
                        .block
                            // button.btn-default Watchlist: loading ...


                .flex.items-center.justify-end.mb-8
                    button.btn.btn-default(type="button" @click="reset()")
                        | Reset
                    button.btn.btn-primary.ml-4(
                        v-if="checkFeatureByAcl('ultimo', 'show')"
                        type="submit" @click="submit($event)"
                    )
                        | Fetch

            // .container-form
            .container-form(style="max-width:100%;")
                .group-fields.border-b
                    .field
                        .field__label
                            label.block.text-sm.font-bold Response [{{ response_ultimo_api && response_ultimo_api?.data?.code ? response_ultimo_api?.data?.code : '' }}]
                        .field__form(v-if="response_ultimo_api && response_ultimo_api.data")
                            // label.block.text-sm.font-bold {{ resp_deep_void && resp_deep_void.data ? resp_deep_void.data : '' }}
                            // vue-json-pretty(:data="resp_deep_void_data")
                            json-viewer(:value="response_ultimo_api.data.respond" copyable boxed sort expanded :expand-depth="7")

</template>

<script>
import IconSet from '@/components/IconSet.vue';
import Spinner from '@/components/Spinner.vue';
import { mapState } from 'vuex';
import { required } from "vuelidate/lib/validators";
import JsonViewer from 'vue-json-viewer/ssr'
import 'vue-json-viewer/style.css'
import { mapFields } from 'vuex-map-fields';

export default {
    name: 'pageUltimoForm',
    components: {
        IconSet,
        Spinner,
        JsonViewer,
        // VueJsonPretty,
        // Multiselect,
    },
    data() {
        return {
            // error message
            error_value_data: '',

            timeout: null,

            is_typing: false,
            list_type_feature: [
                {
                    "name": "Phone Lookup",
                    "value": "phone_lookup"
                },
                {
                    "name": "Luna Phone Lookup",
                    "value": "luna_phone_lookup"
                },
                {
                    "name": "Compromised S Nid Lookup",
                    "value": "compromised_s_nid_lookup"
                },
                {
                    "name": "Leads Search",
                    "value": "leads_search"
                },
                {
                    "name": "Email Lookup",
                    "value": "email_lookup"
                },
                {
                    "name": "Luna Email Lookup",
                    "value": "luna_email_lookup"
                },
                {
                    "name": "Name Lookup",
                    "value": "name_lookup"
                },
                {
                    "name": "Telescome",
                    "value": "telescome"
                },
                {
                    "name": "Facial Recognition Sniper",
                    "value": "facial_recognition_sniper"
                },
                {
                    "name": "Ai Sentiment Analysis",
                    "value": "ai_sentiment_analysis"
                },
                {
                    "name": "Web Collection",
                    "value": "web_collection"
                },
                {
                    "name": "Ip Address Geolocation",
                    "value": "ip_address_geolocation"
                },
                {
                    "name": "Psychologycal Portret",
                    "value": "psychologycal_portret"
                },
                {
                    "name": "Psychologycal Portret Psycho Profile Summary",
                    "value": "psychologycal_portret_psycho_profile_summary"
                },
                {
                    "name": "Kyc Validation And Enrichment",
                    "value": "kyc_validation_and_enrichment"
                },
                {
                    "name": "Validate Ip Vs Phone",
                    "value": "validate_ip_vs_phone"
                },
                {
                    "name": "Email Validator",
                    "value": "email_validator"
                },
                {
                    "name": "Validate Phone Lookup",
                    "value": "validate_phone_lookup"
                },
                {
                    "name": "Search People Twitter",
                    "value": "search_people_twitter"
                },
                {
                    "name": "Search Top Twitter",
                    "value": "search_top_twitter"
                },
                {
                    "name": "Search Latest Twitter",
                    "value": "search_latest_twitter"
                },
                {
                    "name": "Search Photos Twitter",
                    "value": "search_photos_twitter"
                },
                {
                    "name": "Search Videos Twitter",
                    "value": "search_videos_twitter"
                },
                {
                    "name": "Profile Tweets Twitter",
                    "value": "profile_tweets_twitter"
                },
                {
                    "name": "Profile Followers Twitter",
                    "value": "profile_followers_twitter"
                },
                {
                    "name": "Profile Following Twitter",
                    "value": "profile_following_twitter"
                },
                {
                    "name": "Profile Highlights Twitter",
                    "value": "profile_highlights_twitter"
                },
                {
                    "name": "Profile Likes Twitter",
                    "value": "profile_likes_twitter"
                },
                {
                    "name": "Profile Memberships Twitter",
                    "value": "profile_memberships_twitter"
                },
                {
                    "name": "Profile Details Twitter",
                    "value": "profile_details_twitter"
                },
                {
                    "name": "Profile Feed Twitter",
                    "value": "profile_feed_twitter"
                },
                {
                    "name": "Profile Media Twitter",
                    "value": "profile_media_twitter"
                },
                {
                    "name": "Profile Replies Twitter",
                    "value": "profile_replies_twitter"
                },
                {
                    "name": "Tweet Tweet Details Twitter",
                    "value": "tweet_tweet_details_twitter"
                },
                {
                    "name": "Tweet Likers Twitter",
                    "value": "tweet_likers_twitter"
                },
                {
                    "name": "Tweet Retweets Twitter",
                    "value": "tweet_retweets_twitter"
                },
                {
                    "name": "Tweet Quotes Twitter",
                    "value": "tweet_quotes_twitter"
                },
                {
                    "name": "Tweet Tweet Replies Twitter",
                    "value": "tweet_tweet_replies_twitter"
                },
                {
                    "name": "Explore Searchlocation Twitter",
                    "value": "explore_searchlocation_twitter"
                },
                {
                    "name": "Search Users Instagram",
                    "value": "search_users_instagram"
                },
                {
                    "name": "Search Hashtags Instagram",
                    "value": "search_hashtags_instagram"
                },
                {
                    "name": "Search Location Instagram",
                    "value": "search_location_instagram"
                },
                {
                    "name": "Search Top Instagram",
                    "value": "search_top_instagram"
                },
                {
                    "name": "Search Accounts Instagram",
                    "value": "search_accounts_instagram"
                },
                {
                    "name": "Search Places Instagram",
                    "value": "search_places_instagram"
                },
                {
                    "name": "Profiles Info Instagram",
                    "value": "profiles_info_instagram"
                },
                {
                    "name": "Profiles Followers Instagram",
                    "value": "profiles_followers_instagram"
                },
                {
                    "name": "Profiles Feed Instagram",
                    "value": "profiles_feed_instagram"
                },
                {
                    "name": "Profiles Tagsfeed Instagram",
                    "value": "profiles_tagsfeed_instagram"
                },
                {
                    "name": "Profiles Usernameinfo Instagram",
                    "value": "profiles_usernameinfo_instagram"
                },
                {
                    "name": "Profiles Following Instagram",
                    "value": "profiles_following_instagram"
                },
                {
                    "name": "Profiles Story Instagram",
                    "value": "profiles_story_instagram"
                },
                {
                    "name": "Profiles Highlights Tray Instagram",
                    "value": "profiles_highlights_tray_instagram"
                },
                {
                    "name": "Profiles Reels Instagram",
                    "value": "profiles_reels_instagram"
                },
                {
                    "name": "Profiles Reels Media Stream Instagram",
                    "value": "profiles_reels_media_stream_instagram"
                },
                {
                    "name": "Profiles Reels Media Stream Linkedin",
                    "value": "profiles_reels_media_stream_linkedin"
                },
                {
                    "name": "Search Groups Linkedin",
                    "value": "search_groups_linkedin"
                },
                {
                    "name": "Search Companies Linkedin",
                    "value": "search_companies_linkedin"
                },
                {
                    "name": "Search Jobs Linkedin",
                    "value": "search_jobs_linkedin"
                },
                {
                    "name": "Search Posts Linkedin",
                    "value": "search_posts_linkedin"
                },
                {
                    "name": "Search Locations Linkedin",
                    "value": "search_locations_linkedin"
                },
                {
                    "name": "Profiles Overview Linkedin",
                    "value": "profiles_overview_linkedin"
                },
                {
                    "name": "Profiles About Linkedin",
                    "value": "profiles_about_linkedin"
                },
                {
                    "name": "Profiles Experience Linkedin",
                    "value": "profiles_experience_linkedin"
                },
                {
                    "name": "Profiles Skills Linkedin",
                    "value": "profiles_skills_linkedin"
                },
                {
                    "name": "Profiles Skill Endorsers Linkedin",
                    "value": "profiles_skill_endorsers_linkedin"
                },
                {
                    "name": "Profiles Education Linkedin",
                    "value": "profiles_education_linkedin"
                },
                {
                    "name": "Profiles Recommendations Linkedin",
                    "value": "profiles_recommendations_linkedin"
                },
                {
                    "name": "Profiles Contact Info Linkedin",
                    "value": "profiles_contact_info_linkedin"
                },
                {
                    "name": "Profiles Url Resolver Linkedin",
                    "value": "profiles_url_resolver_linkedin"
                },
                {
                    "name": "Profiles Activity Linkedin",
                    "value": "profiles_activity_linkedin"
                },
                {
                    "name": "Profiles People Also Viewed Linkedin",
                    "value": "profiles_people_also_viewed_linkedin"
                },
                {
                    "name": "Profiles Interests Linkedin",
                    "value": "profiles_interests_linkedin"
                },
                {
                    "name": "Profiles Courses Linkedin",
                    "value": "profiles_courses_linkedin"
                },
                {
                    "name": "Profiles Events Linkedin",
                    "value": "profiles_events_linkedin"
                },
                {
                    "name": "Profiles Honors And Awards Linkedin",
                    "value": "profiles_honors_and_awards_linkedin"
                },
                {
                    "name": "Profiles Images Linkedin",
                    "value": "profiles_images_linkedin"
                },
                {
                    "name": "Profiles Languages Linkedin",
                    "value": "profiles_languages_linkedin"
                },
                {
                    "name": "Profiles Test Scores Linkedin",
                    "value": "profiles_test_scores_linkedin"
                },
                {
                    "name": "Profiles Publications Linkedin",
                    "value": "profiles_publications_linkedin"
                },
                {
                    "name": "Profiles Projects Linkedin",
                    "value": "profiles_projects_linkedin"
                },
                {
                    "name": "Profiles Patents Linkedin",
                    "value": "profiles_patents_linkedin"
                },
                {
                    "name": "Profiles Volunteering Experience Linkedin",
                    "value": "profiles_volunteering_experience_linkedin"
                },
                {
                    "name": "Profiles Organizations Linkedin",
                    "value": "profiles_organizations_linkedin"
                },
                {
                    "name": "Profiles Licenses And Certifications Linkedin",
                    "value": "profiles_licenses_and_certifications_linkedin"
                },
                {
                    "name": "Company Details Linkedin",
                    "value": "company_details_linkedin"
                },
                {
                    "name": "Company Jobs Linkedin",
                    "value": "company_jobs_linkedin"
                },
                {
                    "name": "Company People Linkedin",
                    "value": "company_people_linkedin"
                },
                {
                    "name": "Company Posts Linkedin",
                    "value": "company_posts_linkedin"
                },
                {
                    "name": "Company Products Linkedin",
                    "value": "company_products_linkedin"
                },
                {
                    "name": "Group Info Linkedin",
                    "value": "group_info_linkedin"
                },
                {
                    "name": "Post Comments Linkedin",
                    "value": "post_comments_linkedin"
                },
                {
                    "name": "Post Reactions Linkedin",
                    "value": "post_reactions_linkedin"
                },
                {
                    "name": "Post Shares Linkedin",
                    "value": "post_shares_linkedin"
                },
                {
                    "name": "Product Featured Customers Linkedin",
                    "value": "product_featured_customers_linkedin"
                },
                {
                    "name": "Search Keyword Posts Facebook",
                    "value": "search_keyword_posts_facebook"
                },
                {
                    "name": "Search Keyword Users Facebook",
                    "value": "search_keyword_users_facebook"
                },
                {
                    "name": "Person Profile Id Resolving Facebook",
                    "value": "person_profile_id_resolving_facebook"
                },
                {
                    "name": "Person Profile Intro Facebook",
                    "value": "person_profile_intro_facebook"
                },
                {
                    "name": "Person Profile About Facebook",
                    "value": "person_profile_about_facebook"
                },
                {
                    "name": "Person Profile Friends Facebook",
                    "value": "person_profile_friends_facebook"
                },
                {
                    "name": "Person Profile Wall Facebook",
                    "value": "person_profile_wall_facebook"
                },
                {
                    "name": "Person Profile Wall Keyword Facebook",
                    "value": "person_profile_wall_keyword_facebook"
                },
                {
                    "name": "Person Profile Post And Feedbacks Facebook",
                    "value": "person_profile_post_and_feedbacks_facebook"
                },
                {
                    "name": "Person Profile Reactions Facebook",
                    "value": "person_profile_reactions_facebook"
                },
                {
                    "name": "Person Profile Feedbacks Facebook",
                    "value": "person_profile_feedbacks_facebook"
                },
                {
                    "name": "Person Profile Comment Reactions Facebook",
                    "value": "person_profile_comment_reactions_facebook"
                },
                {
                    "name": "Person Profile Photo Albums Facebook",
                    "value": "person_profile_photo_albums_facebook"
                },
                {
                    "name": "Person Profile Content Data Facebook",
                    "value": "person_profile_content_data_facebook"
                },
                {
                    "name": "Person Profile Content Facebook",
                    "value": "person_profile_content_facebook"
                },
                {
                    "name": "Person Profile Video Albums Facebook",
                    "value": "person_profile_video_albums_facebook"
                },
                {
                    "name": "Group Profile Id Resolving Facebook",
                    "value": "group_profile_id_resolving_facebook"
                },
                {
                    "name": "Group Profile Wall Facebook",
                    "value": "group_profile_wall_facebook"
                },
                {
                    "name": "Group Profile Intro Facebook",
                    "value": "group_profile_intro_facebook"
                },
                {
                    "name": "Group Profile Post And Feedbacks Facebook",
                    "value": "group_profile_post_and_feedbacks_facebook"
                },
                {
                    "name": "Group Profile Feedbacks Facebook",
                    "value": "group_profile_feedbacks_facebook"
                },
                {
                    "name": "Group Profile Reactions Facebook",
                    "value": "group_profile_reactions_facebook"
                },
                {
                    "name": "Group Profile Photos Facebook",
                    "value": "group_profile_photos_facebook"
                },
                {
                    "name": "Group Profile Members Facebook",
                    "value": "group_profile_members_facebook"
                },
                {
                    "name": "Group Profile Admins Facebook",
                    "value": "group_profile_admins_facebook"
                },
                {
                    "name": "Group Profile About Facebook",
                    "value": "group_profile_about_facebook"
                },
                {
                    "name": "Group Profile Content Data Facebook",
                    "value": "group_profile_content_data_facebook"
                },
                {
                    "name": "Group Profile Comment Reactions Facebook",
                    "value": "group_profile_comment_reactions_facebook"
                },
                {
                    "name": "Page Profile Id Resolving Facebook",
                    "value": "page_profile_id_resolving_facebook"
                },
                {
                    "name": "Page Profile Wall Facebook",
                    "value": "page_profile_wall_facebook"
                },
                {
                    "name": "Page Profile Community Wall Facebook",
                    "value": "page_profile_community_wall_facebook"
                },
                {
                    "name": "Page Profile Post And Feedbacks Facebook",
                    "value": "page_profile_post_and_feedbacks_facebook"
                },
                {
                    "name": "Page Profile Reactions Facebook",
                    "value": "page_profile_reactions_facebook"
                },
                {
                    "name": "Page Profile Feedbacks Facebook",
                    "value": "page_profile_feedbacks_facebook"
                },
                {
                    "name": "Page Profile Photo Albums Facebook",
                    "value": "page_profile_photo_albums_facebook"
                },
                {
                    "name": "Page Profile Content Data Facebook",
                    "value": "page_profile_content_data_facebook"
                },
                {
                    "name": "Page Profile Content Facebook",
                    "value": "page_profile_content_facebook"
                },
                {
                    "name": "Page Profile Intro Facebook",
                    "value": "page_profile_intro_facebook"
                },
                {
                    "name": "Page Profile About Facebook",
                    "value": "page_profile_about_facebook"
                },
                {
                    "name": "Page Profile Transparency Facebook",
                    "value": "page_profile_transparency_facebook"
                },
                {
                    "name": "Page Profile Linked Profile Facebook",
                    "value": "page_profile_linked_profile_facebook"
                },
                {
                    "name": "Page Profile Video Albums Facebook",
                    "value": "page_profile_video_albums_facebook"
                },
                {
                    "name": "Page Profile Comment Reactions Facebook",
                    "value": "page_profile_comment_reactions_facebook"
                }
            ]

        }
    },
    validations: {
        value_data: {
            required,
            // maxLength: maxLength(255)
        },
        type_selected: {
            required,
        },
        model: {
            value_data: {
                required,
                // maxLength: maxLength(255)
            },
        },
    },
    computed: {
        ...mapFields('ultimo', {
            type_selected: 'type_selected',
            type_keyword: 'type_keyword',
            value_data: 'value_data',
            response_ultimo_api: 'response_ultimo_api',
        }),
        ...mapState({
            // response_ultimo_api: (state) => state.ultimo.response_ultimo_api,
            detail_dcd: (state) => state.dcd.detail,
            status_case_list: (state) => state.caseManagement.status_list,
            case_management_list: (state) => state.caseManagement.list,
        }),
        route_name() {
            return this.$route.name;
        },
        page_title() {
            let title = 'Ultimo';
            return title;
        },
        list_type_feature_filtered() {
            return this.list_type_feature.filter((item) => item.name.toLowerCase().includes(this.type_keyword.toLowerCase()) || item.value.toLowerCase().includes(this.type_keyword.toLowerCase()));
        },
    },
    watch: {
        route_name() {
        },
        id() { },
        page_title() { },
    },
    methods: {
        setEmptyModel() {
            this.type_keyword = ''
            this.type_selected = {}
            this.value_data = ''
            this.response_ultimo_api = {}
        },
        setErrors(empty = true, errors = null) {
            if (empty === true) {
                this.error_value_data = '';
            } else if (empty !== true && errors !== null) {
                this.error_value_data = errors;
            }
        },
        reset() {
            this.setEmptyModel();
            // reset vuelidate
            this.$nextTick(() => { this.$v.$reset(); });
            this.setErrors(true);
            window.scrollTo(0, 0);
        },
        submit(e) {
            e.preventDefault();
            this.$v.$touch();

            if (this.$v.value_data.$invalid || this.$v.type_selected.$invalid) {
                return;
            }

            this.$store.dispatch('ultimo/getUltimoAPI', [this.type_selected.value, this.value_data]);

        },
        selectType(data) {
            this.type_selected = data;
            this.type_keyword = '';
            this.is_typing = false;
        },
        setEmptyType() {
            this.type_selected = {};
        },
        changeIsTypingType(status) {
            // console.log(status)
            if (status === true) {
                this.is_typing = status;
                return
            }

            let self = this
            this.timeout = setTimeout(function () {
                self.is_typing = false;
            }, 150);
        },
    },
    created() {
    },
}
</script>


<style lang="sass" scoped>
    .search-advance
        .btn-search
            @apply .flex .items-center .rounded-full .h-8 .px-4
            border: 1px solid $color-main-100
            color: $color-main-100
            transition: all 0.3s
            span
                @apply .pr-4
            &:focus
                opacity: 0.7

    .box-searching
        @apply .py-4 .absolute .w-full
        z-index: 10
        background-color: $color-secondary-600
        border: 1px solid lighten($color-secondary-500, 10%)
        transform: translateY(-4px)
        border-radius: 0px 0px 4px 4px
        max-height: 300px
        overflow: auto
        .small-heading
            @apply .px-4
        ul
            li
                @apply .py-2 .px-4 .cursor-pointer
                transition: all 0.3s
                &:hover
                    background-color: $color-secondary-700
</style>