<template lang="pug">
.meeting-point-index
    .box__body
        .card.card--no-bg.border-b
            .card__header
                h2.text-xl Detail Meeting Point
                .header-cta
                    .block
                        router-link.btn-default.m-btn-icon.mr-3(:to="meeting_point_detail_from_notification === true ? '/notifications' :'/multi-tracking/'+idMulti+'/detail/meeting-point'")
                            IconSet(type="chevron-left")
                            span.m-hide.ml-3 Back
            .card__body
                .grid.grid-cols-5.gap-8
                    .col-span-2
                        .rollout
                            span Meeting area
                        .w-full.box-map(style="height:400px")
                            gmap-map(v-if="meeting_point_detail.point || meeting_point_detail.target" ref="mapRef" :center="center" :zoom="11" :options="options" style="width:100%; height: 100%;")
                            
                            NoDataAvailable(v-else)
                    .col-span-3                            
                        .rollout
                            span Target Data
                        .block
                            .fields-box(v-if="meeting_point_detail.target && meeting_point_detail.target.length > 0")
                                .fields-box__item(v-for="(target, index) in meeting_point_detail.target" :key="index")
                                    .w-full
                                        .flex.justify-between.w-full
                                            .flex-grow.flex.items-center
                                                .mr-4
                                                    .w-3.h-3.rounded-full(:style="'background-color: '+ (target.label ? target.label:'white') +';'")
                                                .mr-4
                                                    figure.w-8.h-8.rounded-full.overflow-hidden
                                                        img(:src="target.photo ? target.photo : imgAvatar" @error="getDefaultImage")
                                                span.inline-block.w-32 {{ target.target_name ? target.target_name : '-' }}
                                                .mr-4
                                                span.text-right {{ target.msisdn }}
                                        .mt-4
                                            strong {{ target.location != ''? target.location : '-' }}

</template>

<script>
import IconSet from '@/components/IconSet.vue';
import { gmapApi } from 'vue2-google-maps';
import { mapState } from 'vuex';
import Spinner from '@/components/Spinner.vue';                        
import moment from 'moment';
import Loading from '@/components/Loading.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
// import '../../../node_modules/vue-ads-pagination/dist/vue-ads-pagination.css';

export default {
    name: 'pageMultiTrackingMeetingPointDetail',
    components: {
        IconSet,
        Spinner,
        Loading,
        NoDataAvailable,
        DateRangePicker,
        VueAdsPagination,
        VueAdsPageButton,
    },
    data() {
        return {
        // map
            options: {
                gestureHandling: 'cooperative',
                styles: [
                    {elementType: 'geometry', stylers: [{color: '#242f3e'}]},
                    {elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}]},
                    {elementType: 'labels.text.fill', stylers: [{color: '#746855'}]},
                    {
                        featureType: 'administrative.locality',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#d59563'}]
                    },
                    {
                        featureType: 'poi',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#d59563'}]
                    },
                    {
                        featureType: 'poi.park',
                        elementType: 'geometry',
                        stylers: [{color: '#263c3f'}]
                    },
                    {
                        featureType: 'poi.park',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#6b9a76'}]
                    },
                    {
                        featureType: 'road',
                        elementType: 'geometry',
                        stylers: [{color: '#38414e'}]
                    },
                    {
                        featureType: 'road',
                        elementType: 'geometry.stroke',
                        stylers: [{color: '#212a37'}]
                    },
                    {
                        featureType: 'road',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#9ca5b3'}]
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'geometry',
                        stylers: [{color: '#746855'}]
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'geometry.stroke',
                        stylers: [{color: '#1f2835'}]
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#f3d19c'}]
                    },
                    {
                        featureType: 'transit',
                        elementType: 'geometry',
                        stylers: [{color: '#2f3948'}]
                    },
                    {
                        featureType: 'transit.station',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#d59563'}]
                    },
                    {
                        featureType: 'water',
                        elementType: 'geometry',
                        stylers: [{color: '#17263c'}]
                    },
                    {
                        featureType: 'water',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#515c6d'}]
                    },
                    {
                        featureType: 'water',
                        elementType: 'labels.text.stroke',
                        stylers: [{color: '#17263c'}]
                    }
                ],
            },
            infoWindows: [],
            mapLoaded: false,
            center: {},
            markers: [],
            mapsLatLng: [],

            imgAvatar: this.$store.getters['config/getDefaultImageProfile'],

            target_selected: {},
            msisdn_selected: '',
            from_date: '',
            to_date: '',

            marker_color: {
                green: '#48bb78',
                red: '#f56565',
                purple: '#9f7aea',
                orange: '#ed8936',
                blue: '#4299e1',
            },
        };
    },
    computed: {
        ...mapState('multiTracking',[
            'status_meeting_point_detail',
            'meeting_point_detail',
            'meeting_point_detail_from_notification',
        ]),
        route_name() {
            return this.$route.name;
        },
        id() {
            return this.$route.params.idMeet;
        },
        idMulti() {
            return this.$route.params.id;
        },
        google: gmapApi,
    },
    watch: {
        google() {},
        route_name() {
            this.getData();
        },
        id() {},
        idMulti() {},
        meeting_point_detail(newData) {
            if ((newData.target && newData.target.length > 0) || (newData.point && newData.point[0]))
                this.startMap();
        },
    },
    methods: {
        changeImgAvatar(e) {
            const file = e.target.files[0];
            this.ImgAvatar = URL.createObjectURL(file);
        },
        getDefaultImage(e) {
            e.target.src = this.ImgAvatar;
        },
        datetimeFormat(datetime) {
            return moment(datetime, 'YYYY-MM-DDTHH:mm:ssZ').format('DD MMM YYYY HH:mm:ssZ');
        },
        hideAllInfoWindows() {    
            for (var j=0; j < this.infoWindows.length; j++) {
                this.infoWindows[j].close();
            }
        },
        
        async startMap() {
            var self = this;
            // var mapsLatLng = [];
            // monas, jakarta

            // gmap
            self.infowindows = [];
            self.markers = [];

            this.center = {
                lat: -6.1763562,
                lng: 106.8227796,
            };

            this.mapLoaded = true;

            await self.$nextTick(() => {
                self.$refs.mapRef.$mapPromise.then((map) => {
                    const google = self.google;

                    // map.center = new google.maps.LatLng(self.center);

                    if (self.meeting_point_detail.target && self.meeting_point_detail.target.length > 0) {
                        var bounds = new google.maps.LatLngBounds();
                        for (var i = 0; i < self.meeting_point_detail.target.length; i++) {
                            if (Object.keys(self.meeting_point_detail.target[i]).length > 0) {
                                var icon = {
                                    path: 'M31.5766 15.7225C35.366 15.7225 38.4379 12.6506 38.4379 8.86127C38.4379 5.07189 35.366 2 31.5766 2C27.7872 2 24.7153 5.07189 24.7153 8.86127C24.7153 12.6506 27.7872 15.7225 31.5766 15.7225Z M18 26.8175V41.4161C18 43.1679 19.4599 44.4817 21.0657 44.4817H23.9854L25.5911 59.5183C25.7372 60.9782 26.905 62 28.3649 62H34.6423C36.1022 62 37.2701 60.978 37.4161 59.5183L39.0219 44.4817H41.9416C43.6934 44.4817 45.0073 43.1679 45.0073 41.4161V26.8175C45.1533 14.9926 18 14.9926 18 26.8175Z',
                                    fillColor: self.meeting_point_detail.target[i].label,
                                    fillOpacity: 1,
                                    // strokeWeight: 1.2,
                                    strokeWeight: 1.2,
                                    strokeColor: '#ffffff',
                                    // scale: 1.25,
                                    scale: .65,
                                    anchor: new google.maps.Point(30,65),
                                }

                                let marker = new google.maps.Marker({
                                    position: new google.maps.LatLng(parseFloat(self.meeting_point_detail.target[i].coordinates[0].lat), parseFloat(self.meeting_point_detail.target[i].coordinates[0].lng)),
                                    map: map,
                                    icon: icon,
                                    // draggable: true,
                                });

                                self.markers.push(marker);

                                var circle = new google.maps.Circle({
                                    map: map,
                                    // radius: 500, // in metres
                                    radius: self.$store.getters.getMeetingPointMarkerRadius,
                                    // fillColor: '#AA0000'
                                    fillColor: self.meeting_point_detail.target[i].label,
                                    fillOpacity: 0.25,
                                    strokeWeight: 1.25,
                                    strokeColor: '#ffffff',
                                    // strokeColor: '#242f3e',
                                });

                                circle.bindTo('center', marker, 'position');

                                var dtm = (self.meeting_point_detail.target[i].datetime_location ? self.datetimeFormat(self.meeting_point_detail.target[i].datetime_location) : '-');
                                
                                var contentString = '<div id="content">'+
                                    '<div id="bodyContent">'+
                                        '<table>'+
                                            '<tr>'+
                                                '<td class="pr-1 cell-info-window">Target</td>'+
                                                '<td class="pr-1 cell-info-window">:</td>'+
                                                '<td class="cell-info-window">'+self.meeting_point_detail.target[i].target_name+'</td>'+
                                            '</tr>'+
                                            '<tr>'+
                                                '<td class="cell-info-window">MSISDN</td>'+
                                                '<td class="cell-info-window">:</td>'+
                                                '<td class="cell-info-window">'+self.meeting_point_detail.target[i].msisdn+'</td>'+
                                            '</tr>'+
                                            '<tr>'+
                                                '<td class="cell-info-window">Datetime</td>'+
                                                '<td class="cell-info-window">:</td>'+
                                                '<td class="cell-info-window">'+dtm+'</td>'+
                                            '</tr>'+
                                            '<tr>'+
                                                '<td class="cell-info-window">Link Location</td>'+
                                                '<td class="cell-info-window">:</td>'+
                                                '<td class="cell-info-window"><a target="_blank" href="https://www.google.com/maps?q='+self.meeting_point_detail.target[i].coordinates[0].lat+','+self.meeting_point_detail.target[i].coordinates[0].lng+'" style="color: #43ea92;">'+'Google Maps</a> '+
                                                '</td>'+
                                            '</tr>'+
                                        '</table>'+
                                    '</div>'+
                                '</div>';

                                let infowindow = new google.maps.InfoWindow({
                                    content: contentString,
                                    maxWidth: 400
                                });
                                
                                self.infoWindows.push(infowindow); 
                                
                                google.maps.event.addListener(marker, 'click', function() {
                                  // hideAllInfoWindows(map);
                                    self.hideAllInfoWindows();
                                    infowindow.open(map, marker);
                                });

                                // add marker to bounds
                                bounds.extend(new google.maps.LatLng(parseFloat(self.meeting_point_detail.target[i].coordinates[0].lat), parseFloat(self.meeting_point_detail.target[i].coordinates[0].lng)));
                            }
                        }
                        // Apply fitBounds
                        map.fitBounds(bounds);

                    }
                });
            });
        },
    
        async getData() {
            await this.$store.commit('multiTracking/setMeetingPointDetail', {});
            if (this.route_name === 'pageMultiTrackingMeetingPointDetail') {
                EventEmit.$emit('showLoading', true);

                this.$store.dispatch('multiTracking/getMeetingPointDetail', [this.id])
                .then((resp) => {
                    if (resp === 'success') {
                        EventEmit.$emit('showLoading', false);
                    } else {
                        EventEmit.$emit('error');
                    }
                });
            }
        },
    },
    async mounted() {
        // await this.startMap();
        await this.getData();
        EventEmit.$on('meetingPointNotification', () => {
            this.getData();
        });
    },
    beforeRouteEnter(to, from, next) {
        next(vm => { 
            if (from.name === 'pageNotifications') {
                vm.$store.commit('multiTracking/setMeetingPointFromNotification', true);
            } else if (from.name === 'pageMultiTrackingMeetingPoint') {
                vm.$store.commit('multiTracking/setMeetingPointFromNotification', false);
            }
            next();
        });
    },
}
</script>

<style lang="sass" scoped>
    .fields-box
        @apply .rounded
        background-color: $color-secondary-600
        &__item
            @apply .p-4 .rounded .flex .border-b
            &:last-child
                border-bottom: 0px
            .field-options
                input
                    margin-top: 1rem
                    &:first-child
                        margin-top: 0px
    .targets-case
        @apply .w-full
        .target-item
            @apply .flex .items-center .p-3 .w-full .rounded .cursor-pointer
            transition: all 0.3s ease-in-out
            &:hover
                background-color: $color-secondary-800

</style>