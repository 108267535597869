<template lang="pug">
.search-advance
    button.btn-search(
        @click="searchTargetAdvance(true)"
    )
        span Search Target
        IconSet(type="search" size="s")

    //- popup
    .popup-layer(
        v-bind:class="{'is-show' : modalSearchTarget}"
    )
        .popup-container
            .popup-container__header
                h2.text-xl Search Target
                button.btn-icon-40(
                    @click="searchTargetAdvance(false)"
                )
                    IconSet( type="close")

            .popup-container__body
                .field.w-full.mb-8
                    label.small-heading.text-gray-500 Case
                    .relative
                        input.field-style(
                            placeholder="Search and choose a case (write min 3 characters)"
                            type="text"
                            v-model="case_keyword" 
                            @keyup="searchCase"
                        )
                        .field-icon
                            IconSet(type="search")

                        //- show while case was choosen
                        .field-style.field-style--mirroring(v-if="Object.keys(case_selected).length > 0")
                            .flex.items-center.w-full.justify-between
                                span {{ case_selected.operation_name }}
                                button.btn-icon-24(@click="setEmptyCase" v-tooltip="'Remove case'")
                                    IconSet(type="close")
                    //- show while typing the field - autocomplete
                    .block.box-searching(v-if="status_case_list.status === 'loading'")
                        .flex.items-center.justify-center.w-full
                            Spinner(show="true" size="24")
                        //- case's list
                    .block.box-searching(v-else-if="case_list_data.length > 0")    
                        .block
                            label.small-heading.text-gray-500 Choose a Case
                            ul(v-for="(data,index) in case_list_data")
                                li(@click="selectCase(data)") {{data.operation_name}}
                        //- if the case not found, show this information
                    .block.box-searching(v-else-if="case_management_list.data && case_management_list.data.length == 0 && case_keyword.length > 2")
                        .px-4
                            .flex.items-center.justify-between
                                span.block "{{ case_keyword }}" not found.
                                router-link.link(
                                    @click.native="searchTargetAdvance(false)"
                                    :to="{ name: 'pageCaseManagementCreate', params: {} }"
                                ) Create case
                            .mt-8.text-center
                                small.text-xs.text-blue-500 This process will discontinue if you want to create a new case                            

                .field.w-full.mb-8
                    label.small-heading.text-gray-500 Target
                    .relative
                        input.field-style.field-style--disabled(
                            :disabled="Object.keys(case_selected).length > 0?false:'disabled'"
                            type="text"
                            v-bind:placeholder="Object.keys(case_selected).length > 0 ?'Search and choose a target (write min 3 characters)' : 'Please select case first'"
                            v-model="target_keyword" 
                            @keyup="searchTarget()"
                        )
                        .field-icon
                            IconSet(type="search")
                        
                        //- Jika target sudah terpilih muncul ini
                        .field-style.field-style--mirroring(v-if="Object.keys(target_selected).length > 0")
                            .flex.items-center.w-full.justify-between
                                .flex-grow.flex.items-center
                                    .mr-4
                                        .w-3.h-3.rounded-full(:style="'background-color: '+ ( target_selected.label ? target_selected.label:'white') +';'")
                                    .mr-4
                                        figure.w-8.h-8.rounded-full.overflow-hidden
                                            img(:src="target_selected.photo ? target_selected.photo : (defaultImage?defaultImage:getDefaultImage)" @error="getDefaultImage")
                                    span {{target_selected.target_name}}
                                button.btn-icon-24(@click="setEmptyTarget" v-tooltip="'Remove target'")
                                    IconSet(type="close")
                    //- show while typing the field - autocomplete
                    .block.box-searching(v-if="status_target_list.status === 'loading'")
                        .flex.items-center.justify-center.w-full
                            Spinner(show="true" size="24")
                    .block.box-searching(v-else-if="target_list_data && target_list_data.length > 0")
                        //- target's list
                        .block
                            label.small-heading.text-gray-500 Choose a Target
                            ul(v-for="(data,index) in target_list_data")
                                li(@click="selectTarget(data)")
                                    .flex-grow.flex.items-center
                                        .mr-4
                                            .w-3.h-3.rounded-full(:style="'background-color: '+ ( data.label ? data.label:'white') +';'")
                                        .mr-4
                                            figure.w-8.h-8.rounded-full.overflow-hidden
                                                img(:src="data.photo ? data.photo : (defaultImage?defaultImage:getDefaultImage)" @error="getDefaultImage")
                                        span {{data.target_name}} 
                    //- if the target not found, show this information
                    .block.box-searching(v-else-if="target_list.data && target_list.data.length == 0 && target_keyword.length > 2")
                        .px-4
                            .flex.items-center.justify-between
                                span.block "{{ target_keyword }}" not found.
                                router-link.link(
                                    @click.native="searchTargetAdvance(false)"
                                    :to="{ name: 'pageTargetCreate', params: {} }"
                                ) Create Target
                            .mt-8.text-center
                                small.text-xs.text-blue-500 This process will discontinue if you want to create a new target
                    
                //- show when target was choosen
                .field.w-full.mb-8(v-if="Object.keys(target_selected).length > 0")
                    label.small-heading.text-gray-500 Choose a keyword
                    .grid.grid-cols-2.gap-4
                        template(v-for="(data,index) in target_selected.clues")
                            .block.w-full.rounded.border.px-2.pt-2.pb-1(v-if="clues_allowed.includes(data.name)")
                                .ui-radio.w-full
                                    // :disabled="!clues_allowed.includes(data.name)"
                                    input(
                                        type="radio"
                                        name="clue"
                                        :value="data.value+'&&'+data.name"
                                        v-model="clues_selected"
                                    )
                                    .style-radio
                                        .block
                                            .radio
                                        .flex.items-center.justify-between.w-full
                                            span {{ data.value }}
                                            .label-dark.uppercase {{ data.name }}
                            template(
                                v-for="detail in data.detail" 
                                v-if="data.detail && data.detail.length > 0"
                            )
                                .block.w-full.rounded.border.px-2.pt-2.pb-1(
                                    v-if="detail.value !== null && detail.value !== '' && clues_allowed.includes(detail.name)"
                                )   
                                    .ui-radio.w-full
                                        // :disabled="!clues_allowed.includes(detail.name)"
                                        input(
                                            type="radio"
                                            name="clue"
                                            :value="detail.value+'&&'+detail.name"
                                            v-model="clues_selected"
                                        )
                                        .style-radio
                                            .block
                                                .radio
                                            .flex.items-center.justify-between.w-full
                                                span {{ detail.value }}
                                                .label-dark.uppercase {{detail.name}}


                    .flex.items-center.justify-between.mt-8
                        small.text-xs.text-blue-500 This process will discontinue if you want to create a new keyword
                        router-link.link(
                            @click.native="searchTargetAdvance(false)"
                            :to="{ name: 'pageTargetEdit', params: {id:target_selected.id } }"
                        ) Add new keyword

                .field.w-full.mb-8
                    label.small-heading.text-gray-500 Tracking type
                    .grid.grid-cols-2.gap-4
                        .block
                            .ui-radio                                    
                                input(type="radio" name="tracking" value="realtime" v-model="tracking_type")
                                .style-radio
                                    .radio
                                    span Realtime

                        .block
                            .ui-radio
                                input(type="radio" name="tracking" value="historical" v-model="tracking_type")
                                .style-radio
                                    .radio
                                    span Historical
            .popup-container__footer
                .flex.justify-end.w-full
                    template(v-if="clues_selected && clues_selected.length>0")
                        button.btn-primary(type='submit' @click="search()") Search
                    template(v-else)
                        button.btn-primary.btn-disabled(type='submit' disabled) Search
                    //- button.btn-primary.btn-disabled(type='submit' disabled) Search
                    //- button.btn-primary(type='submit') Search
                    //- button.btn-primary.btn-disabled(type='submit' disabled)  Searching...
</template>

<script>
import IconSet from '@/components/IconSet.vue'
import { mapState } from 'vuex';
import { encrypt } from '@/util/crypto'
import Spinner from '@/components/Spinner.vue';

export default {
    name:'GlobalSearchAdvance',
    components: {
        IconSet,
        Spinner,
    },
    data(){
        return {
            modalSearchTarget: false,
            defaultImage: this.$store.getters['config/getDefaultImageProfile'],
            tracking_type: 'realtime',

            timeout: null,
            
            case_keyword: '',
            case_selected: {},
            case_list_data: [],

            target_keyword: '',
            target_selected: {},
            target_list_data: [],
            
            clues_selected: null,
        };
    },
    computed: {
        ...mapState({
            status_case_list: (state) => state.caseManagement.status_search_list,
            case_management_list: (state) => state.caseManagement.search_list,
            
            status_target_list: (state) => state.target.status_search_list,
            target_list: (state) => state.target.search_list,
        }),
        clues_allowed() {
            let result = []
            if (this.tracking_type == 'realtime') {
                if (this.checkFeatureByAcl('search_target', 'realtime_msisdn')) {
                    result.push('msisdn')
                }
                if (this.checkFeatureByAcl('search_target', 'realtime_imsi')) {
                    result.push('imsi')
                }
                if (this.checkFeatureByAcl('search_target', 'realtime_imei-sv')) {
                    result.push('imei-sv')
                }
                if (this.checkFeatureByAcl('search_target', 'realtime_imei')) {
                    result.push('imei')
                }
            } else {
                if (this.checkFeatureByAcl('search_target', 'historical_msisdn')) {
                    result.push('msisdn')
                }
                if (this.checkFeatureByAcl('search_target', 'historical_imsi')) {
                    result.push('imsi')
                }
                if (this.checkFeatureByAcl('search_target', 'historical_imei-sv')) {
                    result.push('imei-sv')
                }
                if (this.checkFeatureByAcl('search_target', 'historical_imei')) {
                    result.push('imei')
                }
            }
            return result;
        },
        first_historical() {
            let route_historical = {
                name: "ResultHistoricalOverall",
                path: "overall",
                snake: "overall"
            }
            try {
                let tmp_first = this.$router.options.routes.find((item) => item.name == "ResultHistoricalShell").children[0];
                route_historical = {
                    name: tmp_first.name,
                    path: tmp_first.path,
                    snake: tmp_first.name.replace("-", "_")
                }
            } catch (error) {
                console.log('=====error route_historical', error)
            }
            return route_historical
        },
    },
    watch: {
        case_management_list(newData) {
            if (newData.data && newData.data.length > 0) {
                this.case_list_data = newData.data;
            } else {
                this.case_list_data = []
            }
        },
        target_list(newData) {
            if (newData.data && newData.data.length > 0) {
                this.target_list_data = newData.data;
            } else {
                this.target_list_data = []
            }
        },
        tracking_type() {
            let clues = []
            try {
                clues = this.clues_selected ? this.clues_selected.split('&&') : clues;
            } catch (error) {
                console.log('=====tracking_type', error)
            }
            if (clues.length > 0) {                
                let type = clues[1];
                if(!this.clues_allowed.includes(type)) {
                    this.clues_selected = null
                }
            }
        },
    },
    methods: {
        setEmpty(){
            this.case_keyword = ''
            this.case_selected = {}
            this.case_list_data = []

            this.target_keyword = ''
            this.target_selected = {}
            this.target_list_data = []

            this.clues_selected = null

            this.tracking_type = 'realtime'
        },
        searchTargetAdvance(open = true) {
            this.setEmpty();
            if(open) {
                this.modalSearchTarget = true;
                EventEmit.$emit('overflowHidden', true);
            } else {
                this.modalSearchTarget = false;
                EventEmit.$emit('overflowHidden', false);
            }
        },
        getDefaultImage(e) {
            e.target.src = this.defaultImage;
        },
        searchCase() {
            // clear timeout variable
            clearTimeout(this.timeout);
            
            var self = this;
            this.timeout = setTimeout(function () {
                // enter this block of code after 1 second
                // handle stuff, call search API etc.
                self.$store.commit('caseManagement/setSearchList', {});

                if (self.case_keyword.length > 2) {
                    self.$store.dispatch('caseManagement/getSearchList', [30, 1, self.case_keyword, null, null, null, true, 'active']);
                }
            }, 650);
        },
        selectCase(data) {
            this.case_selected = data;
            this.case_keyword = '';
            this.$store.commit('caseManagement/setSearchList', {});
        },
        setEmptyCase() {
            this.case_selected = {};
        },
        searchTarget() {
            // clear timeout variable
            clearTimeout(this.timeout);
            
            var self = this;
            this.timeout = setTimeout(function () {
                // enter this block of code after 1 second
                // handle stuff, call search API etc.
                self.$store.commit('target/setSearchList', {});

                if (self.target_keyword.length > 2) {
                    self.$store.dispatch('target/getSearchList', [30, 1, self.target_keyword, null, null, null, null, JSON.stringify([self.case_selected.id])]);
                }
            }, 650);
        },
        selectTarget(data) {
            this.target_selected = data;
            this.target_keyword = '';
            this.$store.commit('target/setSearchList', {});
        },
        setEmptyTarget() {
            this.target_selected = {};
        },
        search() {
            let cluesSelectedValue = this.clues_selected.split('&&')[1]
    
            if (cluesSelectedValue != 'msisdn' && this.tracking_type == 'realtime') {
                this.$swal.fire({
                    icon: 'error',
                    title: 'Wrong keyword, please choose MSISDN',
                });
            } else if (this.clues_selected !== null && this.clues_selected.length > 0) {    
                let clues = this.clues_selected.split('&&');
                let key = clues[0];
                let type = clues[1];

                this.processSearch(key, type);
            } else {
                this.$swal.fire({
                    icon: 'error',
                    title: 'Please select clue first',
                });
            }
        },
        async processSearch(key, type) {
            // clear LS result
            await this.$store.dispatch('config/clearApiResp')
            
            if (type === 'imei') {
                type='imei-sv'
            }
            let target_id = this.target_selected.id
            let route_name = '';
            

            if (type === 'msisdn' || type === 'nik' || type === 'imsi' || type === 'imei-sv'){
                if (this.tracking_type == 'realtime') {
                    route_name = 'ResultRealtimeRecentLocation';
                } else {
                    route_name = this.first_historical.name;
                }
            }
            if (route_name.length > 0) {
                this.$router.push({ name: route_name, params: { id: target_id, key: encrypt(key + '&&' + type) } });
            }
            this.searchTargetAdvance(false)
        },
    },
    mounted() {
        EventEmit.$on('openSearchTargetAdvance', () => {
            this.searchTargetAdvance(true)
        });
    },
}
</script>


<style lang="sass" scoped>
    .search-advance
        .btn-search
            @apply .flex .items-center .rounded-full .h-8 .px-4
            border: 1px solid $color-main-100
            color: $color-main-100
            transition: all 0.3s
            span
                @apply .pr-4
            &:focus
                opacity: 0.7

    .box-searching
        @apply .py-4 .absolute .w-full
        z-index: 10
        background-color: $color-secondary-600
        border: 1px solid lighten($color-secondary-500, 10%)
        transform: translateY(-4px)
        border-radius: 0px 0px 4px 4px
        max-height: 300px
        overflow: auto
        .small-heading
            @apply .px-4
        ul
            li
                @apply .py-2 .px-4 .cursor-pointer
                transition: all 0.3s
                &:hover
                    background-color: $color-secondary-700
</style>