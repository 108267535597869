import moment from 'moment'
import { env } from "@/config/env";
export default {
    methods: {
        numberWithDot(number) {
            if ((number===null) || (number==='')) {
                return number;
            }
            const parts = number.toString().split('.');
            parts[0]=parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            return parts.join(',');
        },
        checkFeatureByAcl(module, func) {
            let user = this.$store.getters['auth/getUser']
            // console.log('user', user)
            // console.log('module', module)
            // console.log('func', func)
            if (user && user.role && user.role.label === 'superadmin') {
                // console.log('masuk superadmin')
                return true
            }

            if (!user?.role?.module)
                return false
            
            // console.log('masuk !superadmin')
            let funcArr = []
            let moduleACL = user.role.module.filter(function(item) {
                return item.label.toUpperCase() === module.toUpperCase();
            });

            // console.log('moduleACL')
            // console.log(moduleACL)
            funcArr = moduleACL
            if (func && funcArr.length>0) {
                funcArr = funcArr[0].function.filter(function(item) {
                    return item.label.toUpperCase() === func.toUpperCase();
                });
            }
            
            return funcArr.length? true : false
        },
        dateFormatGlob(date, format="DD MMMM YYYY HH:mm") {
			// console.log('=====format')
			// console.log(format)
			try {
				return moment(date).format(format);
			} catch (error) {
				return date
			}
		},
        toString(array, key='operation_name') {
            if (array && array.length > 0) {  
                if (key) {
                    return Object.keys(array).map(function(k){
                        return array[k][key]
                    }).join(", ")
                } else {
                    return array.join(", ");
                }          
            } else {
                return '-'
            }
        },
        getProvider(msisdn=null, imsi=null){
            let key = ''
            const provider = {
                "phone": {
                    "t_id": {
                        "62811": {
                            "t_prodvier": "TELKOMSEL"
                        },
                        "62812": {
                            "t_prodvier": "TELKOMSEL"
                        },
                        "62813": {
                            "t_prodvier": "TELKOMSEL"
                        },
                        "62821": {
                            "t_prodvier": "TELKOMSEL"
                        },
                        "62822": {
                            "t_prodvier": "TELKOMSEL"
                        },
                        "62823": {
                            "t_prodvier": "TELKOMSEL"
                        },
                        "62852": {
                            "t_prodvier": "TELKOMSEL"
                        },
                        "62853": {
                            "t_prodvier": "TELKOMSEL"
                        },
                        "62851": {
                            "t_prodvier": "TELKOMSEL"
                        },
                        "62814": {
                            "t_prodvier": "INDOSAT"
                        },
                        "62815": {
                            "t_prodvier": "INDOSAT"
                        },
                        "62816": {
                            "t_prodvier": "INDOSAT"
                        },
                        "62855": {
                            "t_prodvier": "INDOSAT"
                        },
                        "62856": {
                            "t_prodvier": "INDOSAT"
                        },
                        "62857": {
                            "t_prodvier": "INDOSAT"
                        },
                        "62858": {
                            "t_prodvier": "INDOSAT"
                        },
                        "62817": {
                            "t_prodvier": "XL"
                        },
                        "62818": {
                            "t_prodvier": "XL"
                        },
                        "62819": {
                            "t_prodvier": "XL"
                        },
                        "62859": {
                            "t_prodvier": "XL"
                        },
                        "62877": {
                            "t_prodvier": "XL"
                        },
                        "62878": {
                            "t_prodvier": "XL"
                        },
                        "62838": {
                            "t_prodvier": "AXIS"
                        },
                        "62831": {
                            "t_prodvier": "AXIS"
                        },
                        "62832": {
                            "t_prodvier": "AXIS"
                        },
                        "62833": {
                            "t_prodvier": "AXIS"
                        },
                        "62895": {
                            "t_prodvier": "H3I"
                        },
                        "62896": {
                            "t_prodvier": "H3I"
                        },
                        "62897": {
                            "t_prodvier": "H3I"
                        },
                        "62898": {
                            "t_prodvier": "H3I"
                        },
                        "62899": {
                            "t_prodvier": "H3I"
                        },
                        "62881": {
                            "t_prodvier": "SMART"
                        },
                        "62882": {
                            "t_prodvier": "SMART"
                        },
                        "62883": {
                            "t_prodvier": "SMART"
                        },
                        "62884": {
                            "t_prodvier": "SMART"
                        },
                        "62885": {
                            "t_prodvier": "SMART"
                        },
                        "62886": {
                            "t_prodvier": "SMART"
                        },
                        "62887": {
                            "t_prodvier": "SMART"
                        },
                        "62888": {
                            "t_prodvier": "SMART"
                        },
                        "62889": {
                            "t_prodvier": "SMART"
                        }
                    }
                },
                "imsi": {
                    "t_id": {
                        "51008": {
                            "t_prodvier": "AXIS"
                        },
                        "51099": {
                            "t_prodvier": "ESIA"
                        },
                        "51007": {
                            "t_prodvier": "FLEX"
                        },
                        "51089": {
                            "t_prodvier": "H3I"
                        },
                        "51021": {
                            "t_prodvier": "INDOSAT"
                        },
                        "51001": {
                            "t_prodvier": "INDOSAT"
                        },
                        "51000": {
                            "t_prodvier": "PT Pasifik Satelit Nusantara (PSN)"
                        },
                        "51027": {
                            "t_prodvier": "CERIA"
                        },
                        "51009": {
                            "t_prodvier": "SMART"
                        },
                        "51028": {
                            "t_prodvier": "SMART"
                        },
                        "51011": {
                            "t_prodvier": "XL"
                        },
                        "51010": {
                            "t_prodvier": "TELKOMSEL"
                        }
                    }
                }
            }
            let idProvider = null
            if (msisdn) {
                idProvider = provider['phone']
                key = msisdn
            } else if(imsi) {
                idProvider = provider['imsi']
                key = imsi
            }
    
            const get5Digit = String(key).slice(0, 5)
            let result = '-'
            try {
                result = idProvider["t_id"][get5Digit]["t_prodvier"]
            } catch (error) {
                console.log('===== error getProvider')
                console.log(error)
            }
            
            return result
        },
        removeNonNumeric(string){
            return string.replace(/\D/g,'');
        },
        msisdnValidation(msisdn) {
            let is_msisdn = false
            let wording_msisdn = ''
            try {
                let is_number = msisdn.match(/^-?\d+$/)
                if (env.VUE_APP_MSISDN_INDONESIAN_NUMBER) {            
                    // is_msisdn = (is_number && ((msisdn).substring(0, 3) == '628' || (msisdn).substring(0, 2) == '08'))
                    // wording_msisdn = 'MSISDN must be number and start with 628 or 08'
                    is_msisdn = (is_number && ((msisdn).substring(0, 3) == '628'))
                    wording_msisdn = 'MSISDN must be number and start with 628'
                } else {
                    is_msisdn = is_number
                    wording_msisdn = 'MSISDN must be number'
                }
                
            } catch (error) {
                console.log(error)
            }
            return {
                is_msisdn: is_msisdn,
                wording_msisdn: wording_msisdn,
            };
        },
    },
    filters: {
    },
};
