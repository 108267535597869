<template lang="pug">
.card.card--table
    .card__header.card--table__header
        h2.text-xl List Location Log Data
        .header-cta
            //- Export to Excel
            JsonExcel.btn-primary-o.btn-small.cursor-pointer.mr-3(
                v-if="tableData.length > 0"
                :title="[title, from_date + ' - ' + to_date]"
                :name="excelName"
                :data="exportData"
                stringifyLongNum=true
            )
                IconSet(type="download" size="s")
                span.m-hide.ml-3
                | Download Excel

            //- Export to CSV
            JsonExcel.btn-primary-o.btn-small.cursor-pointer.mr-3(
                v-if="tableData.length > 0"
                type = "csv"
                :title="[title, from_date + ' - ' + to_date]"
                :name="csvName"
                :data="exportData"
                stringifyLongNum=true
            )
                IconSet(type="download" size="s")
                span.m-hide.ml-3
                | Download CSV

    .card__body
        template(v-if="status_location_history.status === 'loading'")
            template(v-if="route_name === 'TargetDetailLocationMovement' || route_name === 'TargetDetailLocationLog'")
                Loading(show="true")
            template(v-else)
                LoadingAnimation(show="true" title="location-log-data")
        template(v-else) 
            NoDataAvailable(v-if='filterNull')
            template(v-else-if="location_history && location_history.length > 0")
                .box__body.p-4
                    <v-client-table ref='myTable' :data="tableData" :columns="columns" :options="options" @pagination="pageChange($event)" @limit="limitChange($event)" @loaded="loaded()">
                        <span slot="no" slot-scope="props">{{props.index}}.</span>
                    </v-client-table>  
            NoDataAvailable(v-else)

        //- .box-filter-table.p-4.pt-0
            .field(
                class="mr-0 lg:mr-4"
            )
                input.field-style(
                    placeholder="Search data here..."
                )
                .field-icon
                    IconSet(
                        type="search"
                    )
            .field
                select.field-style
                    option 10 record
                    option 20 record
                .field-icon
                    IconSet(
                        type="chevron-down"
                    )
        //- .box-table.box-table--overflow
            table.table
                thead
                    tr
                        th Datetime
                        th Time
                        th Provider
                        th Rat Name
                        th Country
                        th Province
                        th Village
                        th MCC
                        th MNC
                        th region
                        th column a
                        th column b
                        th column c
                        th column d
                        th column e
                        th column f
                        th column g
                        th column h
                        th column i
                        th column j
                        th column k
                tbody
                    tr(v-for="item in LogData" :key="item.id")
                        td {{ item.datetime }}
                        td {{ item.time }}
                        td {{ item.provider }}
                        td {{ item.rat }}
                        td {{ item.country }}
                        td {{ item.province }}
                        td {{ item.village }}
                        td {{ item.mcc }}
                        td {{ item.mnc }}
                        td {{ item.city }}
                        td {{ item.region }}
                        td {{ item.random }}
                        td {{ item.random }}
                        td {{ item.random }}
                        td {{ item.random }}
                        td {{ item.random }}
                        td {{ item.random }}
                        td {{ item.random }}
                        td {{ item.random }}
                        td {{ item.random }}
                        td {{ item.random }}
    //- .card__footer
        .box-table-footer
            span.text-xs Showing 10 from 100
            .pagination
                ul
                    li
                        span 1
                    li
                        router-link(to="")  2
                    li
                        router-link(to="")  3

</template>

<script>
import IconSet from '@/components/IconSet.vue'
import { mapState } from 'vuex';
import Loading from '@/components/Loading.vue';
import LoadingAnimation from '@/components/LoadingAnimation.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';
import JsonCSV from 'vue-json-csv';
import JsonExcel from "vue-json-excel";
import moment from 'moment';

export default {
    name: 'LocationLogLogData',
    components: {
        IconSet,
        Loading,
        NoDataAvailable,
        JsonCSV,
        JsonExcel,
        LoadingAnimation
    },
    data() {
        return {
            // LogData: [
            //     {
            //         datetime: '2020-10-05',
            //         time: '15:05:00',
            //         provider: 'Telkomsel',
            //         rat: '4G',
            //         country: 'Indonesia',
            //         province: 'JAWA BARAT',
            //         village: 'Medan Satria - Medan Satria',
            //         mcc: '510',
            //         mnc: '10',
            //         city: 'KOTA BEKASI',
            //         region: 'JABODETABEK',
            //         random: 'column random value'
            //     },
            // ],

            // --------------------------------------------

            filterNull: false,
            columns: ['datetime','time','provider','rat_name','country','province','village','mcc','mnc','city','region','lac','ci','msisdn','imsi','bts','geoloc'],
            options: {
                headings: {
                    'datetime': 'Datetime',
                    'time': 'Time',
                    'provider': 'Provider',
                    'rat_name': 'Rat Name',
                    'country': 'Country',
                    'province': 'Province',
                    'village': 'Village',
                    'mcc': 'MCC',
                    'mnc': 'MNC',
                    'city': 'City',
                    'region': 'Region',
                    'lac': 'LAC',
                    'ci': 'CI',
                    'msisdn': 'MSISDN',
                    'imsi': 'IMSI',
                    'bts': 'BTS',
                    'geoloc': 'GEOLOC',
                },
                // filterable: false,
                // perPage: 10,
                pagination: { 
                    chunk: 5 
                    // dropdown: false 
                },
                // perPageValues: [10,25,50,100],
                skin: 'table table-overflow',
                // skin: 'table-static w-full p-4',
                sortIcon: { 
                    base:'iconfont',
                    is:'iconsort',
                    up: 'iconup',
                    down: 'icondown'
                },
                columnsClasses: {
                    'datetime': 'font-mini',
                },
                customSorting: {
                    datetime: function (ascending) {
                        return function (a, b) {
                            const parseDate = (dateStr) => {
                                if (typeof dateStr !== 'string') return 0;
                                const months = {
                                    Jan: 0, Feb: 1, Mar: 2, Apr: 3, May: 4, Jun: 5,
                                    Jul: 6, Aug: 7, Sep: 8, Oct: 9, Nov: 10, Dec: 11
                                };
                                const [day, month, year, time, offset] = dateStr.split(' ');
                                const [hours, minutes, seconds] = time.split(':').map(Number);
                                const date = new Date(year, months[month], day, hours, minutes, seconds);
                                return date;
                            };
                            const dateA = parseDate(a.datetime);
                            const dateB = parseDate(b.datetime);

                            if (ascending)
                                return dateA >= dateB ? 1 : -1;

                            return dateB >= dateA ? 1 : -1;
                        }
                    }
                }
            },
            tableData:[],

            page: 1,
            limit: 10,

            csvName: 'result_location_log_log_data'+moment().format('YYYY-MM-DD_HH:mm:ss')+'.csv',
            exportData: [],

            excelName: 'result_location_log_log_data'+moment().format('YYYY-MM-DD_HH:mm:ss')+'.xls',

            title: 'Location Log - Log Data',
        };
    },
    computed: {
        ...mapState('locationLog', [
            'status_location_history',
            'location_history',
            'from_date',
            'to_date',
            'default_export',
        ]),
        route_name() {
            return this.$route.name;
        },
    },
    watch:{
        location_history() {
            this.getData();
        }
    },
    methods: {
        getData() {
            if (this.location_history && this.location_history.length > 0) {
                var location_history = this.location_history;
                location_history = location_history.filter(function(item) {
                    // if (item.geoloc && item.geoloc.length > 0 ) {
                    if (typeof item.geoloc === 'object' && item.geoloc && item.geoloc.length > 1 ) {
                        let geoloc = item.geoloc;
                        item.geoloc = (geoloc[0] && geoloc[1] ? geoloc[0]+', '+geoloc[1] : '-');
                    }
                    
                    return item;
                });
                this.tableData = location_history;

                this.filterNull = false;
            } else {
                this.filterNull = true;

                this.exportData = [{
                    datetime: '-',
                    time: '-',
                    provider: '-',
                    rat_name: '-',
                    country: '-',
                    province: '-',
                    village: '-',
                    mcc: '-',
                    mnc: '-',
                    city: '-',
                    region: '-',
                    lac: '-',
                    ci: '-',
                    msisdn: '-',
                    imsi: '-',
                    bts: '-',
                    geoloc: '-',
                }];
            }
        },
        pageChange(data) {
            this.page = data;
        },
        limitChange(data) {
            this.limit = data;
        },
        loaded() {
            let data = [];

            if (this.tableData.length > 0) {
                if (this.default_export == 'all') {
                    data = this.tableData;
                } else {
                    data = this.tableData.slice((this.page - 1) * this.limit, this.page * this.limit);
                }
            }

            this.exportData = data.map((item) => {
                return {
                    datetime: item.datetime ? item.datetime : '-' ,
                    time: item.time ? item.time : '-' ,
                    provider: item.provider ? item.provider : '-' ,
                    rat_name: item.rat_name ? item.rat_name : '-' ,
                    country: item.country ? item.country : '-' ,
                    province: item.province ? item.province : '-' ,
                    village: item.village ? item.village : '-' ,
                    mcc: item.mcc ? item.mcc : '-' ,
                    mnc: item.mnc ? item.mnc : '-' ,
                    city: item.city ? item.city : '-' ,
                    region: item.region ? item.region : '-' ,
                    lac: item.lac ? item.lac : '-' ,
                    ci: item.ci ? item.ci : '-' ,
                    msisdn: item.msisdn ? item.msisdn : '-' ,
                    imsi: item.imsi ? item.imsi : '-' ,
                    bts: item.bts ? item.bts : '-' ,
                    geoloc: item.geoloc ? item.geoloc : '-' ,
                };
            });
        },
    },
    mounted () {
        this.getData();
    },
}
</script>

<style lang="sass">
@import "@/assets/sass/components/table-overflow.sass"
</style>
