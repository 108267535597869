import axios from "@/axios-main";
import axiosOrigin from "axios";
import { env } from '@/config/env';

let cancelDeviceHistory;
var cancelToken = axiosOrigin.CancelToken;

const deviceHistory = {
  namespaced: true,
  state: {
    module: 'Device History',
    default_range: env.VUE_APP_DEVICE_RANGE?env.VUE_APP_DEVICE_RANGE:89,

    record_size: null,
    from_date: null,
    to_date: null,

    status: false,
    status_device_history: {
      status: false,
      cancel: null,
      code: null
    },

    device_history: {},
  },
  mutations: {
    setRecordSize: (state, data) => {
      state.record_size = data;
    },
    setFromDate: (state, data) => {
      state.from_date = data;
    },
    setToDate: (state, data) => {
      state.to_date = data;
    },

    setStatus: (state, status) => {
      state.status = status;
    },
    setStatusDeviceHistory: (state, status) => {
      state.status_device_history = status;
    },

    setDeviceHistory: (state, data) => {
      state.device_history = data;
    },
  },
  getters: {},
  actions: {
    async getResultData({ state, rootGetters, dispatch, commit }, [key, type, service_source=null]) {

      const default_date = rootGetters['config/getDefaultDate'](state.default_range);

      let record_size = state.record_size!==null ? state.record_size : rootGetters['config/getDefaultRecordSize'];
      let from_date = state.from_date!==null ? state.from_date : default_date.from_date;
      let to_date = state.to_date!==null ? state.to_date : default_date.to_date;

      await dispatch('setLoading');
      
      await dispatch('getDeviceHistory', [key, type, record_size, from_date, to_date, service_source]);

      commit('setStatus', true);
    },

    async setLoading({ commit, state }) {
      await commit('setStatus', 'loading');

      await commit('setStatusDeviceHistory', {
        status: 'loading',
        cancel: state.status_device_history.cancel,
        code: null
      });
    },

    async setEmpty({ state, commit }) {
      commit('setStatus', false);

      if (state.status_device_history.cancel !== null && state.status_device_history.status === 'loading')
        await state.status_device_history.cancel('Operation canceled by the user.');

      commit('setStatusDeviceHistory', {
        status: false, 
        cancel: null,
        code: null
      });

      commit('setDeviceHistory', {});
    },

    async getDeviceHistory({ getters, commit, dispatch, state }, [key, type, record_size, from_date, to_date, service_source]) {

      if (state.status_device_history.cancel !== null && state.status_device_history.status === 'loading')
        await state.status_device_history.cancel('Operation canceled by the user.');

      let service = 'service';
      if (service_source === 'internal') {
        service = 'service-int'
      } else if (service_source === 'external') {
        service = 'service-ext'
      }

      let params = new FormData();
      params.append('key', key);
      params.append('type', type);
      params.append('size', record_size);
      params.append('from_date', from_date);
      params.append('to_date', to_date);

      await axios.post('api/v1/'+service+'/overall/device-history', params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelDeviceHistory = c;
          commit('setStatusDeviceHistory', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if(data.data !== null)
          commit('setDeviceHistory', data);
        else
          commit('setDeviceHistory', {});

        if (Object.keys(data).length===0 || data.data === null)
          result.status = 'empty';

        commit('setStatusDeviceHistory', result);
      })
      .catch((resp) => {
        // console.log(resp)
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelDeviceHistory;
        }

        commit('setStatusDeviceHistory', result);
        if (!axiosOrigin.isCancel(resp)){
          // dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null),state.module], { root: true });
          let respMsg = resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null
          if(respMsg!=null && result.code != 500) {
            dispatch('errors/handleApiResponse', [result.code, respMsg,state.module], { root: true });
          }
        } 
      });
    },
  },
};

export default deviceHistory;
