import axios from "@/axios-main";
import axiosOrigin from "axios";
import { env } from '@/config/env';

const urlPath = env.VUE_APP_API_VERSION_LOG_MANAGEMENT == 'v2' ? 'v2/data-log/log-management' : 'api/v1/log-management';
let cancelLogManagement;
let cancelLogManagementTable;
var cancelToken = axiosOrigin.CancelToken;

const logManagement = {
	namespaced: true,
	state: {
		status_log_management: {
			status: false,
			cancel: null,
			code: null,
		},
		status_log_management_table: {
			status: false,
			cancel: null,
			code: null,
		},

		log_management: {},
		log_management_table: {},
	},
	mutations: {
		setStatusLogManagement: (state, status) => {
			state.status_log_management = status;
		},

		setLogManagementData: (state, data) => {
			state.log_management = data;
		},
		setStatusLogManagementTable: (state, status) => {
			state.status_log_management_table = status;
		},

		setLogManagementDataTable: (state, data) => {
			state.log_management_table = data;
		},
	},
	getters: {},
	actions: {
		async getList({state, commit, dispatch}, [limit='', page='', keyword='',from_date='', to_date='' , order_by='' , table=null, user_id=null]) {
			if (state.status_log_management.cancel != null && state.status_log_management.status === 'loading')
				await state.status_log_management.cancel('Operation canceled by the user.');
	
			let params = new FormData();
			if(user_id)
				params.append('users_id', user_id);
			if(table)
				params.append('tables', table);
			
			params.append('limit', limit);
			params.append('page', page);
			params.append('keyword', keyword);
			params.append('order_by', order_by);
			params.append('from_date', from_date);
			params.append('to_date', to_date);

			axios.post(urlPath + '/list', params, {
					cancelToken: new cancelToken(function executor(c) {
						cancelLogManagement = c;
						commit('setStatusLogManagement', {
							status: 'loading',
							cancel: c,
							code: null,
						});				
					}),
				})
				.then((resp) => {
					let data = resp.data;
					let result = {
						status: true,
						cancel: null,
						code: resp.response && resp.response.status ? resp.response.status : null,
					};

					if (Object.keys(data).length === 0)
						result.status = 'empty';

					commit('setLogManagementData', data);
					commit('setStatusLogManagement', result);
				})
				.catch((resp) => {
					// console.log(resp)
					let result = {
						status: 'failed',
						cancel: null,
						code: resp.response && resp.response.status ? resp.response.status : null,
					};

					if (axiosOrigin.isCancel(resp)) {
						result.status = 'loading';
						result.cancel = cancelLogManagement;
						
					}

					commit('setStatusLogManagement', result);
					if (!axiosOrigin.isCancel(resp))
						dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null)], {
							root: true
						});
				});
		},
		async getListTable({state, commit, dispatch}) {
			if (state.status_log_management_table.cancel != null && state.status_log_management_table.status === 'loading')
				await state.status_log_management_table.cancel('Operation canceled by the user.');

			axios.get(urlPath + '/list-table/label', {
					cancelToken: new cancelToken(function executor(c) {
						cancelLogManagementTable = c;
						commit('setStatusLogManagementTable', {
							status: 'loading',
							cancel: c,
							code: null,
						});				
					}),
				})
				.then((resp) => {
					let data = resp.data;
					let result = {
						status: true,
						cancel: null,
						code: resp.response && resp.response.status ? resp.response.status : null,
					};

					if (Object.keys(data).length === 0)
						result.status = 'empty';

					commit('setLogManagementDataTable', data);
					commit('setStatusLogManagementTable', result);
				})
				.catch((resp) => {
					// console.log(resp)
					let result = {
						status: 'failed',
						cancel: null,
						code: resp.response && resp.response.status ? resp.response.status : null,
					};

					if (axiosOrigin.isCancel(resp)) {
						result.status = 'loading';
						result.cancel = cancelLogManagementTable;
						
					}

					commit('setStatusLogManagementTable', result);
					if (!axiosOrigin.isCancel(resp))
						dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null)], {
							root: true
						});
				});
		},
	}
};

export default logManagement;