import axios from "@/axios-main";
import axiosOrigin from "axios";
import { env } from '@/config/env';

let cancelList, cancelLogActivity, cancelTacticalList;
var cancelToken = axiosOrigin.CancelToken;

const users = {
  namespaced: true,
  state: {
    // load data users
    status_list: {
      status: false,
      cancel: null,
      code: null,
    },
    status_detail: false,
    status_log_activity: {
      status: false,
      cancel: null,
      code: null,
    },
    status_log_activity_chart: false,

    // load data users tactical
    status_tactical_list: {
      status: false,
      cancel: null,
      code: null,
    },

    // users
    list: {},
    detail: {},
    log_activity: {},
    log_activity_chart: {},

    // users tactical
    tactical_list: {},
  },
  mutations: {
    // load data users
    setStatusList: (state, status) => {
      state.status_list = status;
    },
    setStatusDetail: (state, status) => {
      state.status_detail = status;
    },
    setStatusLogActivity: (state, status) => {
      state.status_log_activity = status;
    },
    
    setStatusLogActivityChart: (state, status) => {
      state.status_log_activity_chart = status;
    },

    // load data users tactical
    setStatusTacticalList: (state, status) => {
      state.status_tactical_list = status;
    },

    // users
    setList: (state, data) => {
      state.list = data;
    },
    setDetail: (state, data) => {
      state.detail = data;
    },
    setLogActivity: (state, data) => {
      state.log_activity = data;
    },
    setLogActivityChart: (state, data) => {
      state.log_activity_chart = data;
    },

    // users tactical
    setTacticalList: (state, data) => {
      state.tactical_list = data;
    },
  },
  getters: {},
  actions: {
    async getList({ state, commit, dispatch }, [limit=null, page=null, keyword=null, order_by=null, from_date=null, to_date=null]) {

      if (state.status_list.cancel !== null && state.status_list.status === 'loading')
        await state.status_list.cancel('Operation canceled by the user.');

      let params = {
        limit: limit,
        page: page,
        keyword: keyword,
        order_by: order_by,
        from_date: from_date,
        to_date: to_date,
      };
    
      axios.get('api/v1/user/list', {
        params: params,
        cancelToken: new cancelToken(function executor(c) {
          cancelList = c;
          commit('setStatusList', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setList', data);

        if (Object.keys(data).length===0)
          result.status = 'empty';
            
        commit('setStatusList', result);
      })
      .catch((resp) => {
        // console.log(resp)
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelList;
        }

        commit('setStatusList', result);
        if (!axiosOrigin.isCancel(resp))
        dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
      });
    },

    getDetail({ commit, dispatch }, id) {

      let params = new FormData();
      params.append('id', id);
    
      return axios.post('api/v1/user', params)
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        commit('setDetail', data);
        return 'success';
      })
      .catch((resp) => {
        // console.log(resp)
        dispatch('errors/handleApiResponse', [(resp.response && resp.response.status ? resp.response.status : null)], { root: true });
        return 'failed';
      });
    },

    async getLogActivity({ state, commit, dispatch }, [user_id=null, limit='', page='', keyword='',from_date='', to_date='' , order_by='']) {

      if (state.status_log_activity.cancel !== null && state.status_log_activity.status === 'loading')
        await state.status_log_activity.cancel('Operation canceled by the user.');

      let params = new FormData();
      if(user_id){
        params.append('user_id', user_id);
      }
      params.append('limit', limit);
      params.append('page', page);
      params.append('keyword', keyword);
      params.append('order_by', order_by);
      params.append('from_date', from_date);
      params.append('to_date', to_date);
    
      const urlPath = env.VUE_APP_API_VERSION_LOG_ACTIVITY == 'v2' ? 'v2/data-log/log-activity/list' : 'api/v1/log-activity/list';
      axios.post(urlPath, params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelLogActivity = c;
          commit('setStatusLogActivity', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setLogActivity', data);

        if (Object.keys(data).length===0)
          result.status = 'empty';

        commit('setStatusLogActivity', result);
      })
      .catch((resp) => {
        // console.log(resp)
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelLogActivity;
        }

        commit('setStatusLogActivity', result);
        if (!axiosOrigin.isCancel(resp))
        dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
      });
    },

    getLogActivityChart({ commit, dispatch }, [from_date='', to_date='']) {
      commit('setStatusLogActivityChart', 'loading');

      let params = new FormData();
      params.append('from_date', from_date);
      params.append('to_date', to_date);
    
      const urlPath = env.VUE_APP_API_VERSION_LOG_ACTIVITY == 'v2' ? 'v2/data-log/log-activity/chart' : 'api/v1/log-activity/chart';
      axios.post(urlPath, params)
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        commit('setLogActivityChart', data);

        if (Object.keys(data).length===0) {
          commit('setStatusLogActivityChart', 'empty');
        } else {
          commit('setStatusLogActivityChart', true);
        }
      })
      .catch((resp) => {
        // console.log(resp)
        commit('setStatusLogActivityChart', 'failed');
        dispatch('errors/handleApiResponse', [(resp.response && resp.response.status ? resp.response.status : null)], { root: true });
      });
    },

    create({ commit, dispatch }, [name=null, email=null, phone=null, web_device_id=null, role_id=null, status=null, password=null]) {

      let params = new FormData();
      params.append('name', name);
      params.append('email', email);
      params.append('phone', phone);
      params.append('web_device_id', web_device_id);
      // params.append('client_id', client_id);
      params.append('role_id', role_id);
      params.append('status', status);
      params.append('password', password);
        // temporary ???
      // params.append('label', name);

      return axios.post('api/v1/user/create', params)
      .then((resp) => {

        if (resp.status === 200 && resp.data.message === 'Data Successfully Created') {
          return {result: 'success', data: resp.data};
        } else {
          return {result: 'failed', data: resp.data};
        }
      })
      .catch((resp) => {
        // console.log(resp)
        dispatch('errors/handleApiResponse', [(resp.response && resp.response.status ? resp.response.status : null)], { root: true });
        return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
      });
    },

    update({ commit, dispatch }, [id, name=null, email=null, phone=null, web_device_id=null, role_id=null, status=null, password=null]) {

      let params = new FormData();
      params.append('id', id);
      params.append('name', name);
      params.append('email', email);
      params.append('phone', phone);
      params.append('web_device_id', web_device_id);
      // params.append('client_id', client_id);
      params.append('role_id', role_id);
      params.append('status', status);
      params.append('password', password);
        // temporary ???
      // params.append('label', name);
    
      return axios.post('api/v1/user/update', params)
      .then((resp) => {
        // console.log(resp.data)
        
        if (resp.status === 200 && resp.data.message === 'Data Successfully Updated') {
          return {result: 'success', data: resp.data};
        } else {
          return {result: 'failed', data: resp.data};
        }
      })
      .catch((resp) => {
        // console.log(resp)
        dispatch('errors/handleApiResponse', [(resp.response && resp.response.status ? resp.response.status : null)], { root: true });
        return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
      });
    },

    delete({ commit, dispatch }, id) {

      let params = new FormData();
      params.append('id', id);

      return axios.post('api/v1/user/delete', params)
      .then((resp) => {
        // console.log(resp)
        
        if (resp.status === 200 && resp.data.message === 'Data Successfully Deleted') {
          return 'success';
        } else {
          return 'failed';
        }
      })
      .catch((resp) => {
        // console.log(resp)
        dispatch('errors/handleApiResponse', [(resp.response && resp.response.status ? resp.response.status : null)], { root: true });
        return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
      });

    },

    async getTacticalList({ state, commit, dispatch }, [limit=null, page=null, keyword=null, order_by=null, from_date=null, to_date=null]) {

      if (state.status_tactical_list.cancel !== null && state.status_tactical_list.status === 'loading')
        await state.status_tactical_list.cancel('Operation canceled by the user.');

      let params = new FormData();
      params.append('limit', limit);
      params.append('page', page);
      params.append('keyword', keyword);
      // params.append('order_by', order_by);
      params.append('from_date', from_date);
      params.append('to_date', to_date);

      axios.post('api/v1/user/tactical', params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelTacticalList = c;
          commit('setStatusTacticalList', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setTacticalList', data);

        if (Object.keys(data).length===0)
          result.status = 'empty';

        commit('setStatusTacticalList', result);
      })
      .catch((resp) => {
        // console.log(resp)
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelTacticalList;
        }

        commit('setStatusTacticalList', result);
        if (!axiosOrigin.isCancel(resp))
        dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
      });
    },
  }
};

export default users;
