<template lang="pug">
.card.card--table
    .card__header.card--table__header
        h2.text-xl Log Recent Location
    .card--table__body.p-4.pt-0
        //- filter
        .box-filter
            //- showing data information mobile
            .m-only
                .filter-info
                    .flex.items-center.justify-between.mb-3
                        Strong.uppercase.text-xs.font-bold Filtered by
                        button.btn-default-o.btn-small(
                            type='button'
                            v-on:click="showFilter = !showFilter"
                            ) Change Filter
                    p {{ dateRange.startDate }} -  {{ dateRange.endDate }}

            //- end showing data information mobile
            .mobile-filter(
                v-bind:class="{ 'is-show' : showFilter}"
            )
                .m-only
                    .flex.items-center.justify-between.p-4.border-b
                        h2.text-xl Filter
                        button.btn-icon-40(
                            v-on:click="showFilter = !showFilter"
                        )
                            IconSet(type="close")
                .items-filter
                    .field.mr-3
                        .relative.mr-3.field-style
                            date-range-picker(
                                v-if="dateRange.startDate && dateRange.endDate" 
                                ref="picker" 
                                v-model="dateRange" 
                                @update="pickerUpdate($event)" 
                                :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }" 
                                :date-format="dateFormat" 
                                opens="right"
                                :ranges="default_ranges"
                                autoApply=true
                            )
                                div(
                                    slot="input" 
                                    slot-scope="picker") 
                                        | {{ picker.startDate | date}} - {{ picker.endDate  | date}}

                            .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2
                                IconSet(type="calendar")

                    .field.mr-3
                        select.field-style#filter-record-size(@change="sizeChange($event)" v-model="items_per_page" style="min-width:80px")
                            option(v-for="(s,index) in size" :key="index" :value="s") {{ s }}
                        .field-icon
                            IconSet(
                                type="chevron-down"
                            )

                    .field(v-if="list_recent_location.data && list_recent_location.data.length > 0 && status_list_recent_location.status !== 'loading'")
                        .flex.flex-wrap.items-center.justify-between
                            .field.mr-3.relative
                            JsonCSV(
                                :data="csvData"
                                :name="csvName"
                                class="btn btn-primary"
                            )
                                | Download CSV
        //- end filter
        .box-table.box-table--target
            table.table
                thead.m-hide
                    tr
                        th(v-for="(th,index) in listActiveTableThead" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}
                tbody

                    tr(v-if="status_list_recent_location.status === 'loading'")
                        td.text-center(:colspan="listActiveTableThead.length")
                            .flex.flex-wrap.items-center.justify-center
                                Spinner(show="true" size="48")

                    template(v-else-if="list_recent_location.data && list_recent_location.data.length > 0")
                        tr(v-for="(data,index) in list_recent_location.data" :key="data.id")
                            //- td {{getTargetName(data.features[0].properties.msisdn)}}
                            td {{ data.features && data.features[0].properties && data.features[0].properties.msisdn ? data.features[0].properties.msisdn : '-' }}
                            td {{ data.features[0]?.properties?.imei || '-' }}
                            td 
                                small {{ data.features && data.features[0].properties && data.features[0].properties.alamat ? data.features[0].properties.alamat : '-' }}
                            td 
                                small {{ data.features && data.features[0].properties && data.features[0].properties.bts ? data.features[0].properties.bts : '-' }}
                            td 
                                small {{ data.features && data.features[0].properties && data.features[0].properties.tac ? data.features[0].properties.tac : '-' }}
                            td 
                                small {{ data.features && data.features[0].properties && data.features[0].properties.lac ? data.features[0].properties.lac : '-' }}
                            td 
                                small {{ data.features && data.features[0].properties && data.features[0].properties.enb ? data.features[0].properties.enb : '-' }}
                            td 
                                small {{ data.features && data.features[0].properties && data.features[0].properties.ci ? data.features[0].properties.ci : '-' }}

                            //- td 
                                small {{ data.features && data.features[0].properties && data.features[0].properties.imei ? data.features[0].properties.imei : '-' }}
                            td 
                                small {{ data.features && data.features[0].properties && data.features[0].properties.state ? data.features[0].properties.state : '-' }}
                            td 
                                small {{ data.features && data.features[0].properties && data.features[0].properties.dtm ? datetimeFormat(data.features[0].properties.dtm) : '-' }}
                            td 
                                small {{ data.features && data.features[0].properties && data.features[0].properties.requested_at ? data.features[0].properties.requested_at : '-' }}
                            td.text-center
                                div.flex.items-center.gap-2
                                    button.btn-small.btn-default(v-tooltip="'View'" @click="setActiveRecentLocation(data)")
                                        IconSet(type="maps")
                            td(v-if="showCopyTarget")
                                CopyRecentLocationTarget.btn-small.btn-default(v-tooltip="'Copy Data'" :recent_location="data" log_path="History Realtime CP")
                                //- button.btn-small.btn-default(v-tooltip="'Copy Data'" @click="copyTargetToClipboard(data)")
                                //-     IconSet(type="copy")

                    tr(v-else)
                        td.text-center(:colspan="listActiveTableThead.length") No Data Available
        .box-table-footer(v-if="status_list_recent_location.status !== 'loading'")
            .pagination
                vue-ads-pagination(
                    :total-items="total_data"
                    :items-per-page="items_per_page"
                    :page="page"
                    :max-visible-pages="5"
                )
                    template(slot="buttons" slot-scope="props")
                        vue-ads-page-button(
                            v-for="(button, key) in props.buttons"
                            :key="key"
                            v-bind="button"
                            :class="{'bg-yellow-dark': button.active}"
                            @page-change="page = button.page"
                            @range-change="start = button.start; end = button.end"
                        )

</template>

<script>
import IconSet from '@/components/IconSet.vue';
import { mapState } from 'vuex';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
// import '../../../../node_modules/vue-ads-pagination/dist/vue-ads-pagination.css';
import Spinner from '@/components/Spinner.vue';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import moment from 'moment';
import { encrypt, decrypt } from '@/util/crypto';
import JsonCSV from 'vue-json-csv';
import NoDataAvailable from '@/components/NoDataAvailable.vue';
import Loading from '@/components/Loading.vue';
import { env } from '@/config/env';
import CopyRecentLocationTarget from '@/pages/result/recent-location/components/CopyRecentLocationTarget.vue'

export default {
    name: 'LogRecentLocation',
    components: {
        IconSet,
        Spinner,
        VueAdsPagination,
        VueAdsPageButton,
        DateRangePicker,
        JsonCSV,
        NoDataAvailable,
        Loading,
        CopyRecentLocationTarget,
    },
    data() {
        return {
            showFilter: false,

            paramMsisdn: [],

            total_data: 0,
            items_per_page: 10,

            size: [10, 25, 50, 100],

            page: 0,
            start: 0,
            end: 0,

            keyword: '',
            timeout: null,

            from_date: '',
            to_date: '',

            dateRange: {
                startDate: '',
                endDate: '',
            },
            default_ranges: {
                'Today': [moment().toDate(), moment().toDate()],
                'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                'Last 7 days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                'Last week': [moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(), moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate()],
            },
            csvName: 'log_recent_location_' + moment().format('YYYY-MM-DD_HH:mm:ss') + '.csv',
            csvData: [],
        }
    },
    computed: {
        ...mapState({
            list_recent_location: (state) => state.locationEs.list_recent_location,
            status_list_recent_location: (state) => state.locationEs.status_list_recent_location,
        }),
        ...mapState('target', [
            'detail',
        ]),
        ...mapState('auth', ['user']),
        route_name() {
            return this.$route.name;
        },
        id() {
            return this.$route.params.id;
        },
        key() {
            return decrypt(this.$route.params.key).split('&&');
        },
        listActiveTableThead() {
            return this.tableThead.filter((value) => value?.active !== false)
        },
        showCopyTarget() {
            return this.checkFeatureByAcl('recent_location', 'copy_cp')
        },
        tableThead() {
            return [
                {
                    name: 'MSISDN',
                }, {
                    name: 'IMEI',
                }, {
                    name: 'Location',
                }, {
                    name: 'BTS ID',
                }, {
                    name: 'TAC',
                }, {
                    name: 'LAC',
                }, {
                    name: 'ENB',
                }, {
                    name: 'CI',
                    // },{
                    //     name: 'IMEI',
                }, {
                    name: 'State',
                }, {
                    name: 'Datetime',
                }, {
                    name: 'Requested At',
                }, {
                    name: 'Maps',
                    action: true,
                },
                {
                    name: 'Action',
                    action: true,
                    active: this.checkFeatureByAcl('recent_location', 'copy_cp'),
                },
            ]
        }
    },
    methods: {
        datetimeFormat(datetime) {
            // return moment(datetime, 'YYYY-MM-DDTHH:mm:ssZ').format('DD MMM YYYY HH:mm:ssZ');
            return datetime;
        },
        /* eslint-disable no-unused-vars */
        dateFormat(classes, date) {
            if (!classes.disabled) {
                // console.log('on dateFormat')
                // console.log(moment(date.getTime())._d)

                // classes.disabled = date.getTime() > new Date();
                classes.disabled = moment(date.getTime()).subtract({ hours: 12 })._d > new Date();
            }
            return classes;
        },
        getData() {
            var key = this.key[0];
            var type = this.key[1];
            this.$store.dispatch('locationEs/getListRecentLocation', [key, type, this.items_per_page, (this.page + 1), this.from_date, this.to_date])
        },
        getDataCsv(data) {
            this.csvData = data.map((item) => {
                return {
                    // target_name  : this.getTargetName(item.features[0].properties.msisdn),
                    msisdn: item.features[0].properties.msisdn ? item.features[0].properties.msisdn : '-',
                    imei: item.features[0]?.properties?.imei || '-',
                    location_name: item.features[0].properties.alamat ? item.features[0].properties.alamat : '-',
                    coordinates: item.features[0].geometry.coordinates[0] ? item.features[0].geometry.coordinates[1] + ',' + item.features[0].geometry.coordinates[0] : '-',
                    bts_id: item.features[0].properties.bts ? item.features[0].properties.bts : '-',
                    enb: item.features[0].properties.enb ? item.features[0].properties.enb : '-',
                    tac: item.features[0].properties.tac ? item.features[0].properties.tac : '-',
                    ci: item.features[0].properties.ci ? item.features[0].properties.ci : '-',
                    state: item.features[0].properties.state ? item.features[0].properties.state : '-',
                    datetime: item.features[0].properties.dtm ? item.features[0].properties.dtm : '-',
                    requested_at: item.features[0].properties.requested_at ? item.features[0].properties.requested_at : '-',
                };
            })
        },
        pageChange(page) {
            this.page = page;
        },
        rangeChange(start, end) {
            this.start = start;
            this.end = end;
        },
        sizeChange() {
            this.page = 0;
        },
        pagination() {
            // update fontawesome to iconfont
            var paginationButtonPreview = document.getElementsByClassName("fa-angle-left");

            if (paginationButtonPreview[0]) {
                var classListPrev = paginationButtonPreview[0].classList;

                if (Object.values(classListPrev).filter(key => ['iconfont'].includes(key)).length === 0) {
                    paginationButtonPreview[0].classList.add('iconfont');
                    paginationButtonPreview[0].classList.add('iconleft');
                    paginationButtonPreview[0].style.fontSize = '12px';
                }
            }

            var paginationButtonNext = document.getElementsByClassName("fa-angle-right");

            if (paginationButtonNext[0]) {
                var classListNext = paginationButtonNext[0].classList;

                if (Object.values(classListNext).filter(key => ['iconfont'].includes(key)).length === 0) {
                    paginationButtonNext[0].classList.add('iconfont');
                    paginationButtonNext[0].classList.add('iconright');
                    paginationButtonNext[0].style.fontSize = '12px';
                }
            }
        },
        searchKeyword() {
            // clear timeout variable
            clearTimeout(this.timeout);

            var self = this;
            this.timeout = setTimeout(function () {
                // enter this block of code after 1 second
                // handle stuff, call search API etc.
                self.page = 0;
                self.$store.commit('locationEs/setList', {});

                // if (self.keyword.length > 0) {
                self.getData();
                // }
            }, 650);
        },
        getDefaultImage(e) {
            e.target.src = this.defaultImage;
        },
        async pickerUpdate(e) {
            // console.log('========= DateRangePicker on update')

            let ranges = moment(e.endDate.getTime()).diff(moment(e.startDate.getTime()), 'days') + 1;

            if (ranges > this.$store.getters['config/getValidateRangeQueryByRoute'](this.route_name)) {
                const default_date = this.$store.getters['config/getDefaultDate']();


                this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
                this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();

                this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');

                this.$swal.fire({
                    icon: 'error',
                    title: 'Date range is not more than ' + this.$store.getters['config/getValidateRangeQueryByRoute'](this.route_name) + ' days!',
                    text: 'Your date range is ' + ranges + ' days.',
                })

            } else {
                this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');

                this.getData();
            }
        },
        setActiveRecentLocation(data) {
            const copy_target_data = {
                ...data,
                detail: this.detail
            }
            this.$store.commit('recentLocation/setRecentLocation', copy_target_data);
            document.getElementById('recent_location').scrollIntoView({
                behavior: 'smooth'
            });
        },
    },
    watch: {
        list_recent_location(newData) {
            if (Object.keys(newData).length > 0) {
                this.total_data = parseInt(newData.total_data);
                // this.page = (parseInt(newData.current_page) - 1);

                if (newData.data && newData.data.length > 0) {
                    this.getDataCsv(newData.data);
                }
            } else {
                this.total_data = 0;
            }
        },
        total_data() {
        },
        items_per_page() {
            this.getData();
        },
        page() {
            this.getData();
        },
        keyword() { },
        route_name(newData, oldData) {
            if (newData === 'TargetDetailRecentLocation') {
                if (oldData !== 'TargetDetailRecentLocation') {
                    const default_date = this.$store.getters['config/getDefaultDate']();

                    this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
                    this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();

                    this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                    this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');

                    // this.optFilterTarget= []
                    // this.filterTarget= ''
                    this.paramMsisdn = []
                    this.items_per_page = 10
                    this.page = 0
                    this.keyword = ''
                    this.$store.commit('target/setMonitoringList', {})
                    this.$store.commit('target/setStatusMonitoringList', {
                        status: 'loading',
                        cancel: null,
                        code: null
                    });
                }
            }
        },
        id() { },
        key() { },
    },
    filters: {
        date: function (date) {
            return moment(date).format('YYYY-MM-DD');
        }
    },
    mounted() {
        if (this.route_name === 'TargetDetailRecentLocation') {
            const default_date = this.$store.getters['config/getDefaultDate']();

            this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
            this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();

            this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');

            this.getData();
        }
    },
}
</script>

<style lang="sass">
    .btn-primary
        cursor: pointer !important
</style>