<template lang="pug">
.box__body
    .card.card--table.card--no-bg
        .card__header.card--table__header
            h2.text-xl {{ page_title }}
            .header-cta
                .block
                    button.btn-default.m-btn-icon(@click="handleBack()")
                        IconSet(type="chevron-left")
                        span.m-hide.block.ml-3 Back
        .card__body
            .container-form
                .group-fields.border-b
                    .field
                        .box-photo-container
                            .relative(v-if="single_target_status.status === 'loading'")
                                .field-style
                                    .field-style--loading
                                        Spinner(show="true" size="64")
                            //- template(v-if="imagesTarget.length > 0")
                            template(v-else-if="model.photos.length > 0")
                                label.block.text-sm.font-bold Target's Photos Selected
                                ul.grid.grid-cols-3.gap-8.box-photo
                                    //- li.box-photo__item(v-for="(image, index) in imagesTarget")
                                    li.box-photo__item(v-for="(image, index) in model.photos")
                                        .border.item-photo
                                            img.w-full.h-full.object-cover(:src="image.file?image.file:image" @error="getDefaultImage")
                                            button.btn-icon-40.btn-delete.bg-white( @click="removeImage(index)")
                                                IconSet(type="delete")
                                    li.box-photo__item
                                        .border.item-photo
                                            .added-photo
                                                .added-photo__cta
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="84" height="84" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                                                    span Add Photo
                                                .added-photo__input 
                                                    input( multiple type="file"  @change="onFileChange" accept="image/png, image/jpeg")
                            template(v-else)
                                .cta-select.border.p-8
                                    .cta-select__ui.text-center
                                        .inline-block
                                            <svg xmlns="http://www.w3.org/2000/svg" width="84" height="84" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>
                                        p Please selects target's photos
                                    .cta-select__input
                                        input( multiple type="file"  @change="onFileChange" accept="image/png, image/jpeg")
                    .flex.items-center.mb-6
                        .block(v-if="isWatchlistQuota")
                            button.btn-default(@click="watchlistModal(true)" ) Watchlist: {{ status_watchlist_quota.status === 'loading' ? 'loading...' : (watchlist_quota.usage || watchlist_quota.usage == 0 ? numberWithDot(watchlist_quota.usage) : '-') +'/'+ (watchlist_quota.quota_watchlist || watchlist_quota.quota_watchlist == 0 ? numberWithDot(watchlist_quota.quota_watchlist) : '-') }}
                                IconSet(type="chevron-down")
                            .message.text-xs.text-gray-600 * Maximum upload is {{ maxPhotos }} photos
                            .message.text-xs.text-red-500(v-if="$v.model.photos.$error") Maximum upload is {{ maxPhotos }} photos
                            .message.text-xs.text-red-500(v-if="error_photo !== ''") {{ error_photo }}

                .group-fields.border-b
                    //- .field
                        .field__label
                        .field__form
                            .preview-avatar.relative.w-32.m-auto.mb-2
                                img(:src="model.photo" @error="getDefaultImage").w-32.h-32.object-cover.rounded-full.border.mx-auto
                                button.flex.items-center.justify-center.change-image.absolute.w-12.h-12.rounded-full.bg-gray-200.bottom-0.right-0.pointer
                                    IconSet(type="camera")
                                    input(type="file" @change="changeImgAvatar" accept="image/png, image/jpeg")
                            .flex.items-center.justify-center.mb-2
                                .message.text-xs.text-red-500(v-if="error_photo !== null") {{ error_photo }}
                    .field
                        .field__label
                            label.block.text-sm.font-bold Target Name <sup>*</sup>
                        .field__form
                            input.field-style(type="text" placeholder="Write name here" v-model="model.target_name")
                            .py-3
                                .message.text-xs.text-red-500(v-if="$v.model.target_name.$error") This target name is required &amp; max length is 255 char.
                                .message.text-xs.text-red-500(v-if="error_target_name !== ''") {{ error_target_name }}

                    .field
                        .field__label
                            label.block.text-sm.font-bold Target's Color Label <sup>*</sup>
                        .field__form
                            .box-slider-color
                                slider-picker(
                                    v-model="labelValue" 
                                    @input="onColorPicker"
                                    :max="320"
                                )
                            .py-3
                                .message.text-xs.text-red-500(v-if="$v.model.label.$error") This label is required.
                                .message.text-xs.text-red-500(v-if="error_label !== ''") {{ error_label }}

                    .field
                        .field__label
                            label.block.text-sm.font-bold Case Name <sup>*</sup>
                        .field__form

                            .relative(v-if="status_case_list.status === 'loading'")
                                .field-style
                                    .field-style--mirroring.field-style--loading
                                        Spinner(show="true" size="32")

                            .field-multiple-select
                                multiselect(
                                    v-if="case_management_list.data && case_management_list.data.length > 0"
                                    :value="casesValue",
                                    :options="case_management_list.data ? case_management_list.data : []",
                                    :multiple="true",
                                    placeholder="Select Case",
                                    label="operation_name",
                                    track-by="id",
                                    @input="onChange"
                                )
                                .py-3
                                    .message.text-xs.text-red-500(v-if="$v.model.case_id.$error") This case name is required.
                                    .message.text-xs.text-red-500(v-if="error_case_id !== ''") {{ error_case_id }}

                .group-fields.border-b
                    .field
                        .field__label
                            label.block.text-sm.font-bold Target Keywords <sup>*</sup>
                        .field__form
                            .flex(class="lg:flex-row flex-col")
                                .flex-grow(class="lg:mr-3 lg:mb-0 mr-0 mb-3")
                                    .flex.flex-col
                                        .relative
                                            select.field-style(name="clue-name-tmp" v-model="clueNameTmp" @change="onChangeClues()")
                                                option(selected disabled value='') - Select Type - 
                                                option(v-for="opt in cluesOpt" :key="opt.key" :value="opt.key") {{ opt.name }}
                                            .field-icon
                                                IconSet(
                                                    type="chevron-down"
                                                )
                                        .py-3
                                            .message.text-xs.text-red-500(v-if="$v.model.clues.$error") This keywords is required.
                                            .message.text-xs.text-red-500(v-if="error_clues !== ''") {{ error_clues }}
                                            .message.text-xs.text-gray-600 * MSISDN Max: 3, Others Max: 1
                                template(v-if="clueNameTmp == ''")
                                    .flex-grow(class="lg:mr-3 lg:mb-0 mr-0 mb-3")
                                        .flex.flex-col
                                            input.field-style.field-style--disabled(
                                                id="clues-tmp"
                                                placeholder="Please select type first"
                                                disabled=true
                                            )
                                template(v-else)
                                    .flex-grow(class="lg:mr-3 lg:mb-0 mr-0 mb-3")
                                        .flex.flex-col
                                            input.field-style(
                                                id="clues-tmp"
                                                placeholder="Write Target's keywords"
                                                v-model="clueValTmp"
                                                v-on:keyup.enter="addClue()"
                                                @input="inputClue"
                                                :type="clueNameTmp!='nopol'?'number':'text'"
                                            )
                                        .py-3.mr-2.message.text-xs.text-red-500(v-if="error_tmp_clues !== ''") {{ error_tmp_clues }}
                                    .flex.justify-end
                                        button.btn-icon-40(
                                            type='submit' 
                                            @click="addClue()"
                                        )
                                            IconSet(type="add")
                                    
                            .pt-4(v-if="model.clues.length > 0")
                                .small-heading ADDED KEYWORDS OLD
                                .fields-box
                                    .fields-box__item(
                                        v-for="(clue, index) in model.clues"
                                        :key="index"
                                        class="lg:flex-row"
                                    )
                                        .flex.flex-grow(class="lg:flex-row flex-col")
                                            .flex-grow.mr-3
                                                .relative
                                                    select.field-style(v-model="clue.name" :key="index" :disabled="true")
                                                        option(v-for="opt in cluesOpt" :value="opt.key" :selected="opt.key === clue.name ? 'selected' : ''") {{ opt.name }}
                                                    .field-icon
                                                        IconSet(
                                                            type="chevron-down"
                                                        )
                                            .flex-grow.mr-3.field-options(class="lg:pt-0 pt-4")
                                                input.field-style(
                                                    :type="clue.name!='nopol'?'number':'text'"
                                                    placeholder="Write Target's keywords"
                                                    v-model="clue.value"
                                                    :key="index"
                                                    @input="inputParentClue($event, clue.name, index)"
                                                    :disabled="!checkClueIsEditable(clue.name)"
                                                )
                                                .py-3.mr-2.message.text-xs.text-red-500(
                                                    v-if="error_parent_clues.length>0 && checkErrorParentClue(index) && ['msisdn', 'imei-sv'].includes(clue.name)"
                                                ) {{ getErrorDetail(index) }}
                                                template(
                                                    v-if="clue.name == 'msisdn'"
                                                    v-for="(detail,key) in clue.detail"
                                                )
                                                    .flex.items-center.mt-1
                                                        .flex.flex-col
                                                            input.field-style(
                                                                type='number' 
                                                                :placeholder="(detail.name).toUpperCase()" v-model="detail.value"
                                                                :key="key"
                                                                @input="inputDetailClue($event, detail.name, index)"
                                                                :disabled="!checkClueIsEditable(clue.name)"
                                                            )
                                                            .py-3.mr-2.message.text-xs.text-red-500(v-if="error_detail_clues.length>0 && detail.name == 'imei'") {{ getErrorDetail(index, 'detail') }}
                                        button.btn-icon-40(
                                            @click="deleteClue(index)"
                                            v-if="checkClueIsEditable(clue.name)"
                                        )
                                            IconSet(
                                                type="delete"
                                            )

                .group-fields.border-b(v-if="checkFeature('target|biodata')")
                    .grid.grid-cols-3.gap-4
                        .field
                            .field__label
                                label.block.text-sm.font-bold Gender
                            .field__form
                                .flex.mt-2
                                    .ui-radio.mr-8
                                        input(type="radio" name="gender" value="Male" v-model="model.gender")
                                        .style-radio
                                            .radio
                                            span Male

                                    .ui-radio
                                        input(type="radio" name="gender" value="Female" v-model="model.gender")
                                        .style-radio
                                            .radio
                                            span Female
                                //- .relative
                                    select.field-style(v-model="model.gender")
                                        option(selected disabled value='') - Select Gender - 
                                        option(v-for="opt in genderOpt" :key="opt.key" :value="opt.key") {{ opt.name }}
                                    .field-icon
                                        IconSet(
                                            type="chevron-down"
                                        )
                                .py-3
                                    .message.text-xs.text-red-500(v-if="error_gender !== ''") {{ error_gender }}
                        
                        //- .grid.grid-cols-2.gap-4
                        .field
                            .field__label
                                label.block.text-sm.font-bold Birth Place
                            .field__form
                                input.field-style(type="text" placeholder="Write birth place here" v-model="model.birth_place")
                                .py-3
                                    .message.text-xs.text-red-500(v-if="$v.model.birth_place.$error") Max length is 255 char.
                                    .message.text-xs.text-red-500(v-if="error_birth_place !== ''") {{ error_birth_place }}
                                //- .flex(class="lg:flex-row flex-col")
                                    .flex-grow(class="lg:mr-3 lg:mb-0 mr-0 mb-3")
                                        input.field-style(type="text" placeholder="Write birth place here" v-model="model.birth_place")
                                    .flex-grow(class="lg:mr-3 lg:mb-0 mr-0 mb-3")
                                        
                        .field
                            .field__label
                                label.block.text-sm.font-bold Birth Date
                            .field__form
                                .relative.field-style.field-style--license
                                    //- @update="pickerUpdate($event)" 
                                    date-range-picker.field-style__date(
                                        ref="picker" 
                                        v-model="dateRangeBirthDate" 
                                        :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }" 
                                        :date-format="dateFormat" 
                                        :singleDatePicker="true"
                                        opens="right"
                                        :autoApply="true"
                                        :showDropdowns="true"
                                        :ranges="false"
                                    )
                                        div(
                                            slot="input" 
                                            slot-scope="picker")
                                                | {{ picker.startDate ? singleDateFormat(picker.startDate) : '-' }}

                                    .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2
                                        IconSet(type="calendar")
                                .py-3
                                    .message.text-xs.text-red-500(v-if="$v.model.birth_date.$error") Max length is 255 char.
                                    .message.text-xs.text-red-500(v-if="error_birth_date !== ''") {{ error_birth_date }}
                    .field
                        .field__label
                            label.block.text-sm.font-bold Address
                        .field__form
                            textarea.field-style.textarea--small(type="text" placeholder="Write address here" v-model="model.address")
                            .py-3
                                .message.text-xs.text-red-500(v-if="$v.model.address.$error") Max length is 255 char.
                                .message.text-xs.text-red-500(v-if="error_address !== ''") {{ error_address }}
                    .grid.grid-cols-2.gap-4
                        .field
                            .field__label
                                label.block.text-sm.font-bold Nationality
                            .field__form
                                input.field-style(type="text" placeholder="Write nationality here" v-model="model.nationality")
                                .py-3
                                    .message.text-xs.text-red-500(v-if="$v.model.nationality.$error") Max length is 255 char.
                                    .message.text-xs.text-red-500(v-if="error_nationality !== ''") {{ error_nationality }}
                        .field
                            .field__label
                                label.block.text-sm.font-bold Religion
                            .field__form
                                .relative
                                    select.field-style(v-model="model.religion")
                                        option(selected disabled value='') - Select Religion - 
                                        option(v-for="opt in religionOpt" :key="opt.key" :value="opt.key") {{ opt.name }}
                                    .field-icon
                                        IconSet(
                                            type="chevron-down"
                                        )
                                .py-3
                                    .message.text-xs.text-red-500(v-if="$v.model.religion.$error") Max length is 255 char.
                                    .message.text-xs.text-red-500(v-if="error_religion !== ''") {{ error_religion }}
                    .grid.grid-cols-2.gap-4
                        .field
                            .field__label
                                label.block.text-sm.font-bold Jobs
                            .field__form
                                input.field-style(type="text" placeholder="Write jobs here" v-model="model.jobs")
                                .py-3
                                    .message.text-xs.text-red-500(v-if="$v.model.jobs.$error") Max length is 255 char.
                                    .message.text-xs.text-red-500(v-if="error_jobs !== ''") {{ error_jobs }}
                        .field
                            .field__label
                                label.block.text-sm.font-bold Last Education
                            .field__form
                                .relative
                                    select.field-style(v-model="model.last_education")
                                        option(selected disabled value='') - Select Last Education - 
                                        option(v-for="opt in eduOpt" :key="opt.key" :value="opt.key") {{ opt.name }}
                                    .field-icon
                                        IconSet(
                                            type="chevron-down"
                                        )
                                .py-3
                                    .message.text-xs.text-red-500(v-if="$v.model.last_education.$error") Max length is 255 char.
                                    .message.text-xs.text-red-500(v-if="error_last_education !== ''") {{ error_last_education }}

                .group-fields.border-b
                    .field
                        .field__label
                            label.block.text-sm.font-bold Additional Info
                        .field__form
                            .flex(class="lg:flex-row flex-col")
                                .flex-grow(class="lg:mr-3 lg:mb-0 mr-0 mb-3")
                                    input.field-style(
                                        id="info-name-tmp"
                                        type="text"
                                        placeholder="Write a Key"
                                        name="info-name-tmp"
                                        v-model="infoNameTmp"
                                        v-on:keyup.enter="addInfo()"
                                    )
                                .flex-grow(class="lg:mr-3 lg:mb-0 mr-0 mb-3")
                                    input.field-style(
                                        id="info-val-name"
                                        type="text"
                                        placeholder="Write a Key's value"
                                        v-model="infoValTmp"
                                        v-on:keyup.enter="addInfo()"
                                    )
                                .flex.justify-end
                                    button.btn-icon-40(
                                        type='submit'
                                        @click="addInfo()"
                                    )
                                        IconSet(type="add")
                            .py-3
                                .message.text-xs.text-red-500(v-if="$v.model.additional_info.$error") This additional_info is required.
                                .message.text-xs.text-red-500(v-if="error_additional_info !== ''") {{ error_additional_info }}

                            .pt-4(v-if="model.additional_info.length > 0")
                                .small-heading ADDED ADDITIONAL INFO
                                .fields-box
                                    .fields-box__item(
                                        v-for="(info, index) in model.additional_info"
                                        :key="index"
                                    )
                                        .flex.flex-grow(class="lg:flex-row flex-col")
                                            .flex-grow.mr-3
                                                input.field-style(
                                                    v-model="info.name"
                                                    :key="index"
                                                    type="text"
                                                    placeholder="Write a Key"
                                                )
                                            .flex-grow.mr-3.field-options(class="lg:pt-0 pt-4")
                                                input.field-style(
                                                    v-model="info.value"
                                                    :key="index"
                                                    type="text"
                                                    placeholder="Write a Key's value"
                                                )
                                        button.btn-icon-40(
                                            @click="deleteInfo(index)"
                                        )
                                            IconSet(
                                                type="delete"
                                            )
                .group-fields.border-b
                    .field
                        .field__label
                            label.block.text-sm.font-bold Description
                        .field__form
                            textarea.field-style(
                                v-model="model.description" 
                                placeholder="Write Description here..."
                            )
                            .py-3
                                .message.text-xs.text-red-500(v-if="$v.model.description.$error") This description is required.
                                .message.text-xs.text-red-500(v-if="error_description !== ''") {{ error_description }}


                .flex.items-center.justify-end.mb-8
                    button.btn.btn-default(type="button" @click="reset()")
                        | Reset
                    button.btn-primary-o.ml-2(type="submit" @click="submit($event, true)")
                        | Save &amp; Continue
                    button.btn.btn-primary.ml-4(type="submit" @click="submit($event)")
                        | Save

    .popup-layer.is-show(
        v-if="popupShowWatchlist"
    )
        .popup-container
            .popup-container__header
                .flex.items-center
                    h2.text-xl Watchlist Detail

                button.btn-icon-40(
                    @click="watchlistModal(false)"
                )
                    IconSet( type="close")

            .popup-container__body
                .mb-2.pb-2
                    table.table
                        tbody
                            tr(v-if="status_watchlist_quota.status === 'loading'")
                                td.text-center(colspan=3)
                                    .flex.flex-wrap.items-center.justify-center
                                        Spinner(show="true" size="48")
                            template(v-else)
                                tr
                                    td Watchlist usage
                                    td.px-4 :
                                    td {{ watchlist_quota.usage || watchlist_quota.usage == 0 ? numberWithDot(watchlist_quota.usage) : '-'  }}
                                tr
                                    td Remaining watchlist
                                    td.px-4 :
                                    td {{ watchlist_quota.remaining || watchlist_quota.remaining == 0 ? numberWithDot(watchlist_quota.remaining) : '-'  }}
                                tr
                                    td Quota watchlist
                                    td.px-4 :
                                    td  {{ watchlist_quota.quota_watchlist || watchlist_quota.quota_watchlist == 0 ? numberWithDot(watchlist_quota.quota_watchlist) : '-'  }}

</template>

<script>
import IconSet from '@/components/IconSet.vue'
import Spinner from '@/components/Spinner.vue';
import Multiselect from 'vue-multiselect'
import { mapState, mapGetters } from 'vuex';
// import { Slider } from 'vue-color'
import Slider from '@/components/custom-library/vue-color/Slider.vue'
import { encrypt } from '@/util/crypto'
import { required, maxLength } from "vuelidate/lib/validators";
import { checkFeature } from '@/config/features';
import moment from 'moment';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
    name: 'pageTargetCreate',
    components: {
        IconSet,
        Spinner,
        'slider-picker': Slider,
        Multiselect,
        DateRangePicker,
    },
    data() {
        return {
            value: null,
            imagesTarget: [],
            options: ['list', 'of', 'options'],
            model:{
                target_name: '',
                clues: [],
                label: '',
                // status: '',
                // photo: '',
                photos: [],
                case_id: [],
                description: '',
                additional_info: [],
                gender:'',
				address:'',
				birth_date:'',
				birth_place:'',
				jobs:'',
				nationality:'',
				religion:'',
				last_education:'',
            },
            //tmp
            // photoFile: '',
            photosFile: [],
            clueNameTmp: '',
            clueValTmp: '',
            infoNameTmp: '',
            infoValTmp: '',

            // error field
            error_target_name: '',
            error_clues: '',
            error_tmp_clues: '',
            error_parent_clues: [],
            error_detail_clues: [],
            error_label: '',
            error_photo: '',
            error_case_id: '',
            error_description: '',
            error_additional_info: '',
            error_gender:'',
            error_address:'',
            error_birth_date:'',
            error_birth_place:'',
            error_jobs:'',
            error_nationality:'',
            error_religion:'',
            error_last_education:'',
            
            dateRangeBirthDate: {
                startDate: '',
                endDate: '',
            },

            imgAvatar: this.$store.getters['config/getDefaultImageProfile'],

            labelOpt: [
                {key: 'green'},
                {key: 'red'},
                {key: 'purple'},
                {key: 'orange'},
                {key: 'blue'},
            ],
            maxFile: this.$store.getters['config/getMaxImageSize'],
            maxPhotos: this.$store.getters['config/getMaxPhotos'],
            popupShowWatchlist: false,
        }
    },
    validations() {
        return {
            model: {
                target_name: {
                    required,
                    maxLength: maxLength(255)
                },
                clues: {
                    required,
                },
                label: {
                    required,
                },
                photos: {
                    maxLength: maxLength(this.maxPhotos)
                },
                case_id: {
                    required,
                },
                description: {
                    maxLength: maxLength(255)
                },
                additional_info: {},
                gender: {},
                address: {
                    maxLength: maxLength(255)
                },
                birth_date: {},
                birth_place: {
                    maxLength: maxLength(255)
                },
                jobs: {
                    maxLength: maxLength(255)
                },
                nationality: {
                    maxLength: maxLength(255)
                },
                religion: {
                    maxLength: maxLength(255)
                },
                last_education: {},
            },
        }
    },
    computed: {
        ...mapState({
            single_target_detail: (state) => state.target.detail,
            single_target_status: (state) => state.target.status_detail,
            status_case_list: (state) => state.caseManagement.status_list,
            case_management_list: (state) => state.caseManagement.list,
            target_redirect: (state) => state.target.redirect,
        }),
        ...mapState('watchlist', [
            'status_watchlist_quota',
            'watchlist_quota',
        ]),
        ...mapState('auth', ['user']),
        ...mapGetters({
            isWatchlistQuota: 'config/getIsWatchlistQuota',
            validationImei: 'config/getValidationImei',
            validationImeiSv: 'config/getValidationImeiSv',
        }),
        route_name() {
            return this.$route.name;
        },
        id() {
            return this.$route.params.id;
        },
        page_title() {
            let title = 'Edit Target';
            if (this.route_name === 'pageTargetCreate')
                title = 'Add Target';
            return title;
        },
        casesValue: {
            get() {
                if (!this.model.case_id) return null
                // console.log('=====onChange:get')
                // console.log(this.model.case_id)
                try {
                    return this.model.case_id.map(value => this.findOption(value.id)).filter(value => value.id)
                } catch (error) {
                    // console.log('error casesvalue')
                    // console.log(error)
                    return null
                }
            },
            set(v) {
                // console.log('=====casesValue:set')
                // console.log(v)
                this.model.case_id=v.map(value => {
                    let rObj = {}
                    rObj['id'] = value.id
                    return rObj
                })
            }
        },
        labelValue: {
            get() {
                if (!this.model.label) return ''
                return {
                    hex:this.model.label
                }
            },
            set(v) {
                this.model.label=v.hex
            }
        },
        cluesOpt() {
            let cluesOpt = [
                    {key: 'msisdn',name: 'MSISDN'},
                    // {key: 'nik',name: 'NIK'},
                    {key: 'imsi',name: 'IMSI'},
                    {key: 'imei-sv',name: 'IMEI-SV'},
                    // {key: 'nopol',name: 'Plat Nomor'},
                ];

            return cluesOpt;
        },
        genderOpt() {
            let genderOpt = [
                    {key: 'Male',name: 'Male'},
                    {key: 'Female',name: 'Female'},
                ];

            return genderOpt;
        },
        religionOpt() {
            let religionOpt = [
                    {key: 'Islam',name: 'Islam'},
                    {key: 'Kristen',name: 'Christianity'},
                    {key: 'Katolik',name: 'Catholicism'},
                    {key: 'Hindu',name: 'Hinduism'},
                    {key: 'Buddha',name: 'Buddhism'},
                    {key: 'Konghucu',name: 'Confucianism'},
                ];

            return religionOpt;
        },
        eduOpt() {
            let eduOpt = [
                    {key: 'NoEdcucation',name: 'No/Not yet School'},
                    {key: 'notCompleted',name: 'Did not finish Elementary School/Equivalent'},
                    {key: 'SD',name: 'Elementary School/Equivalent'},
                    {key: 'SMP',name: 'Junior High School/Equivalent'},
                    {key: 'SMA',name: 'Senior High School/Equivalent'},
                    {key: 'D1/D2',name: 'Diploma I/II'},
                    {key: 'D3',name: 'Diploma III/Equivalent'},
                    {key: 'D4/S1',name: 'Diploma IV/Bachelor'},
                    {key: 'S2',name: 'Master'},
                    {key: 'S3',name: 'Doctor'},
                ];

            return eduOpt;
        },
    },
    watch: {
        route_name() {
            this.getData();
        },
        id() {},
        breadcrumb() {},
        page_title() {},
        // form data
        single_target_detail(newData) {
            this.model.target_name = (newData.target_name ? newData.target_name : '');
            this.model.clues = (newData.clues ? newData.clues : []);
            this.model.label = (newData.label ? newData.label : '');
            // this.model.status = (newData.status ? (newData.status.toLowerCase() === 'start' ? '1' : '0') : '');
            // this.model.photo = (newData.photo ? newData.photo : '');
            // this.model.photos = (newData.photos ? newData.photos : []);
            this.model.case_id = (newData.case_id && newData.case_id ? newData.case_id : []);
            this.model.description = (newData.description ? newData.description : '');
            this.model.additional_info = (newData.additional_info ? newData.additional_info : []);
            this.model.gender = (newData.gender ? newData.gender : '');
            this.model.address = (newData.address ? newData.address : '');
            this.model.birth_place = (newData.birth_place ? newData.birth_place : '');
            this.model.jobs = (newData.jobs ? newData.jobs : '');
            this.model.nationality = (newData.nationality ? newData.nationality : '');
            this.model.religion = (newData.religion ? newData.religion : '');
            this.model.last_education = (newData.last_education ? newData.last_education : '');

            if (newData.photos && newData.photos.length > 0) {
                this.model.photos = newData.photos;
                for (let i = 0; i < newData.photos.length; i++) {
                    const url = newData.photos[i].file
                    const ext = url.split('.').pop();
                    const fileName = 'image'+i+'.'+ext
                    fetch(url)
                        .then(async response => {
                            const contentType = response.headers.get('content-type')
                            const blob = await response.blob()
                            const file = new File([blob], fileName, {
                                contentType
                            })
                            this.photosFile.push(file)
                            // access file here
                        })
                    
                }
            } else {
                this.model.photos = [];
            }

            if (newData && newData.birth_date) {
                const date = moment(newData.birth_date, 'YYYY-MM-DD').toDate();
                this.dateRangeBirthDate = {
                    startDate: date,
                    endDate: date,
                };
            } else {
                this.dateRangeBirthDate = {
                    startDate: null,
                    endDate: null,
                };
            }
        },
        dateRangeBirthDate(newData) {
            let date = '';
            const check_date = moment(newData.startDate).format('YYYY-MM-DD');

            if (check_date !== 'Invalid date')
                date = check_date;

            this.model.birth_date = date;
        },

        target_name() {},
        clues() {},
        // status_case_list(newData) {
        //     if (newData.status === 'loading') {
        //         EventEmit.$emit('showLoading', true);
        //     } else {
        //         EventEmit.$emit('showLoading', false);
        //     }
        // },
        case_id() {},
        // status() {},
        additional_info() {},
        cluesOpt() {},
    },
    methods: {
        onChangeClues() {
            this.clueValTmp=''
            this.error_tmp_clues=''
            this.error_clues=''
        },
        checkFeature(feature) {
            return checkFeature(feature);
        },
        /* eslint-disable no-unused-vars */
        dateFormat(classes, date) {
            // if (!classes.disabled) {
            //     classes.disabled = moment(date.getTime()).subtract({ hours: 12})._d > new Date();
            // }
            return classes;
        },
        singleDateFormat(date) {
            return moment(date).format('DD MMMM YYYY');
            // return moment(date).format('YYYY-MM-DD');
        },
        // FILE UPLOAD HERE
        onFileChange(e) {
            if(this.isWatchlistQuota) {
                if(e.target.files.length > this.maxPhotos || (this.model.photos && (this.model.photos.length + e.target.files.length) > this.maxPhotos)) {
                        this.$swal.fire({
                            icon: 'error',
                        title: 'Failed!',
                        text: 'Maximum upload is '+this.maxPhotos+' photos',
                    });
                    return;
                }
            }
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.createImage(files);
        },
        createImage(images) {
            // var vm = this;
            // for (var index = 0; index < images.length; index++) {
            //     var reader = new FileReader();
            //     reader.onload = function(event) {
            //         const imageUrl = event.target.result;
            //         vm.imagesTarget.push(imageUrl);
            //     }
            //     reader.readAsDataURL(images[index]);
            // }

            // var tmp = this.model.photos;
            for (var index = 0; index < images.length; index++) {
                const file = images[index];
                // const file = images[index].target.files[0];
                if (file) {
                    if (file.type === 'image/jpeg'||file.type === 'image/png') {
                        if (file.size > (this.maxFile * 1024 * 1024)) {
                            this.error_photo = 'File size not more than '+this.maxFile+' MB!';

                            var size = ((file.size/1024)/1024).toFixed(2);

                            if (document.getElementById('file'))
                                document.getElementById('file').value = '';

                            this.$swal.fire({
                                icon: 'error',
                                title: 'Failed!',
                                text: 'File size not more than '+this.maxFile+' MB! Your file size is '+ size +' MB.',
                            });

                        } else {
                            this.photosFile.push(file)
                            this.model.photos.push(URL.createObjectURL(file))
                        }

                    } else {
                        if (document.getElementById('file'))
                            document.getElementById('file').value = '';

                        this.error_photo = 'Only JPG/PNG is allowed!';

                        this.$swal.fire({
                            icon: 'error',
                            title: 'Failed!',
                            text: 'Only JPG/PNG is allowed!',
                        });
                    }
                }
            }
            // this.model.photos = tmp
        },
        removeImage(index) {
            this.model.photos.splice(index, 1)
            this.photosFile.splice(index, 1)
            // this.imagesTarget.splice(index, 1)
        },
        // END FILE UPLOAD
        
        setEmptyModel(all=true) {
            for (let key in Object.keys(this.model)) {
                if (all === true)
                    if(["clues","case_id",'additional_info'].indexOf(key) > -1)
                        this.model[Object.keys(this.model)[key]] = [];
                    else
                        this.model[Object.keys(this.model)[key]] = '';
            }
        },
        setErrors(empty=true, errors=null) {
            if (empty === true) {
                this.error_target_name= '';
                this.error_clues= '';
                this.error_tmp_clues= '';
                this.error_parent_clues= [];
                this.error_detail_clues= [];
                this.error_label= '';
                this.error_photo= '';
                this.error_case_id= '';
                this.error_description= '';
                this.error_additional_info= '';
                this.error_gender= '';
                this.error_address= '';
                this.error_birth_date= '';
                this.error_birth_place= '';
                this.error_jobs= '';
                this.error_nationality= '';
                this.error_religion= '';
                this.error_last_education= '';
            } else if (empty !== true && errors !== null) {
                this.error_target_name = (errors.target_name ? errors.target_name : '');
                this.error_clues = (errors.clues ? errors.clues : '');
                this.error_label = (errors.label ? errors.label : '');
                this.error_photo = (errors.photo ? errors.photo : '');
                this.error_case_id = (errors.case_id ? errors.case_id : '');
                this.error_description = (errors.description ? errors.description : '');
                this.error_additional_info = (errors.additional_info ? errors.additional_info : '');
                this.error_gender=  (errors.gender? errors.gender : '');
                this.error_address=  (errors.address? errors.address : '');
                this.error_birth_date=  (errors.birth_date? errors.birth_date : '');
                this.error_birth_place=  (errors.birth_place? errors.birth_place : '');
                this.error_jobs=  (errors.jobs? errors.jobs : '');
                this.error_nationality=  (errors.nationality? errors.nationality : '');
                this.error_religion=  (errors.religion? errors.religion : '');
                this.error_last_education=  (errors.last_education? errors.last_education : '');
            }
        },
        handleBack(){
            // console.log(this.target_redirect)
            if (this.target_redirect !== 'index') {
                var msisdn = this.checkMsisdn(this.model.clues)
                if (msisdn.length > 0) {
                    this.$router.push({ name: 'pageTargetDetailHome', params: { id: this.id ,key: encrypt(msisdn[0].value + '&&msisdn') }  })
                } else {
                    this.$router.push('/target');
                }
            } else {
                this.$router.push('/target');
            }
        },
        checkMsisdn(data) {
            var msisdn = data.filter(function (clue) {
                return clue.name === 'msisdn'
            })
            return msisdn
        },
        onChange (value) {
            this.casesValue = value
        },
        onColorPicker(value) {
            this.model.labelValue = value
        },
        findOption(value) {
            // console.log('=====findOption')
            // console.log(value)
            const opt = this.case_management_list.data
            try {
                return opt.find(option => option['id'] === value)
            } catch (error) {
                // console.log('error')
                // console.log(error)
                return opt
            }
        },
        getDefaultImage(e) {
            e.target.src = this.imgAvatar;
        },
        getData() {
            this.reset(true);
            // console.log('=================== getData() route on roles form')
            
            // get client list
            if (this.route_name === 'pageTargetEdit' || this.route_name === 'pageTargetCreate') {

                // temporary
                // this.$store.dispatch('target/getClientsList', [100, 1]);

                // after client_id is not used
                this.$store.dispatch('caseManagement/getList', [100, 1, null, 'active']);
                if (this.isWatchlistQuota) {
                    this.$store.dispatch('watchlist/getQuota');
                }
            }

            if (this.route_name === 'pageTargetEdit') {
                // console.log(this.id);

                // for dispatch get data

                EventEmit.$emit('showLoading', true);

                this.$store.dispatch('target/getDetail', [this.id])
                .then((resp) => {
                    if (resp.result === 'success') {

                        EventEmit.$emit('showLoading', false);

                    } else {
                        var code = resp.code || null 
                        var message = null;

                        let statusCodeCheck = ["403", 403]
                        if (statusCodeCheck.includes(code))
                            message = resp && resp.data && resp.data.message? resp.data.message : null;
                        EventEmit.$emit('error', [message]);
                    }
                });

            } else {
                if (this.route_name === 'pageTargetCreate')
                    this.$store.commit('target/setDetail', {});
            }
        },
        changeImgAvatar(e) {
            const file = e.target.files[0];
            if (file) {
                if (file.type === 'image/jpeg'||file.type === 'image/png') {
                    if (file.size > (this.maxFile * 1024 * 1024)) {
                        this.error_photo = 'File size not more than '+this.maxFile+' MB!';

                        var size = ((file.size/1024)/1024).toFixed(2);

                        if (document.getElementById('file'))
                            document.getElementById('file').value = '';

                        this.$swal.fire({
                            icon: 'error',
                            title: 'Failed!',
                            text: 'File size not more than '+this.maxFile+' MB! Your file size is '+ size +' MB.',
                        });

                    } else {
                        this.photoFile = file
                        this.model.photo = URL.createObjectURL(file);
                    }

                } else {
                    if (document.getElementById('file'))
                        document.getElementById('file').value = '';

                    this.error_photo = 'Only JPG/PNG is allowed!';

                    this.$swal.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: 'Only JPG/PNG is allowed!',
                    });
                }
            } else {
                this.file = null;
            }
        },
        reset(direct=false) {
            if (direct === true) {
                this.setEmptyModel(false);
                // reset vuelidate
                this.$nextTick(() => { this.$v.$reset(); });
                this.setErrors(true);
                window.scrollTo(0,0);
            } else {
                this.$swal.fire({
                    icon: 'warning',
                    title: 'Are you sure you want to reset form?',
                    // text: id,
                    confirmButtonText: 'Yes!',
                    confirmButtonColor: '#DD6B55',
                    showCancelButton: true,
                    allowOutsideClick: false,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        if (this.route_name === 'pageTargetCreate') {
                            this.setEmptyModel(false);
                            // reset vuelidate
                            this.$nextTick(() => { this.$v.$reset(); });
                            this.setErrors(true);
                            window.scrollTo(0,0);
                        } else {
                            this.getData();
                        }
                    }
                });
            }
        },
        checkSubmitCluesNotAdd() {
            if (this.clueValTmp.length > 0){
                this.error_clues = "Clue data has not been added"
                return false;
            }
            return true;
        },
        submit(e, save_continue=false) {
            e.preventDefault();
            this.$v.$touch();

            if (this.$v.model.$invalid) {
                for (let key in Object.keys(this.$v.model)) {
                    const input = Object.keys(this.$v.model)[key];
                    if (input.includes("$")) return false;
                    if (this.$v.model[input].$error) {
                        break;
                    }
                }
                // EventEmit.$emit('error', 'Please fill the form correctly.');
                // EventEmit.$emit('showLoading', false);
                window.scrollTo(0,0);
                return;
            }
            if (this.error_parent_clues.length > 0) {
                window.scrollTo(0,0);
                return;
            }
            if (this.error_detail_clues.length > 0) {
                window.scrollTo(0,0);
                return;
            }

            if (! this.checkSubmitCluesNotAdd()) {
                window.scrollTo(0,0);
                return;
            }

            EventEmit.$emit('showLoading', true, true);
            // let tmpPhoto = this.model.photo
            let tmpPhotos = this.model.photos
            // this.model.photo = this.photoFile
            this.model.photos = this.photosFile
            let model_data = Object.keys(this.model).map(
                (key) => (["clues","case_id",'additional_info'].indexOf(key) > -1)?[JSON.stringify(this.model[key])]:this.model[key]
            );
            // this.model.photo = tmpPhoto
            this.model.photos = tmpPhotos

            if (this.route_name === 'pageTargetCreate') {

                this.$store.dispatch('target/createNew', model_data)
                // this.$store.dispatch('target/create', model_data)
                .then((resp) => {
                    if (resp.result === 'success') {
                        if (save_continue === false) {
                            this.$router.push('/target');
                        } else {
                            this.$router.push('/target/'+ resp.data.data.id +'/edit');
                        }

                        this.$swal.fire({
                            icon: 'success',
                            title: 'Data successfully created!',
                            timer: 3000,
                        });
                    } else {
                        if (resp.data.message && resp.data.message.errors) {
                            this.setErrors(false, resp.data.message.errors);
                            // EventEmit.$emit('error', null, resp.data.message.errors);
                            EventEmit.$emit('showLoading', false);
                            window.scrollTo(0,0);
                        } else {
                            var code = resp.code || null 
                            var message = null;

                            let statusCodeCheck = ["403", 403]
                            if (statusCodeCheck.includes(code))
                                message = resp && resp.data && resp.data.message? resp.data.message : null;
                            EventEmit.$emit('error', [message]);
                        }
                    }
                });

            // pageTargetEdit
            } else {

                model_data = [this.id].concat(model_data);

                this.$store.dispatch('target/updateNew', model_data)
                // this.$store.dispatch('target/update', model_data)
                .then((resp) => {
                    if (resp.result === 'success') {                        
                        if (save_continue === false)
                            this.$router.push('/target');

                        this.$swal.fire({
                            icon: 'success',
                            title: 'Data successfully updated!',
                            timer: 3000,
                        });
                    } else {
                        if (resp.data.message && resp.data.message.errors) {
                            this.setErrors(false, resp.data.message.errors);
                            // EventEmit.$emit('error', null, resp.data.message.errors);
                            EventEmit.$emit('showLoading', false);
                            window.scrollTo(0,0);
                        } else {
                            var code = resp.code || null 
                            var message = null;

                            let statusCodeCheck = ["403", "430", 430, 403]
                            if (statusCodeCheck.includes(code))
                                message = resp && resp.data && resp.data.message? resp.data.message : null;
                            EventEmit.$emit('error', [message]);
                        }
                    }
                });
            }
        },

        selectLabel(key) {
            this.model.label = key
        },
        inputClue(event){
            if (this.clueNameTmp == 'imei-sv') {
                const value = event.target.value
                if (String(value).length < this.validationImeiSv.min) {
                    // this.clueValTmp = value
                    this.error_tmp_clues = "IMEI-SV minimum length: " + this.validationImeiSv.min + " characters"
                } else if(String(value).length > this.validationImeiSv.max) {
                    this.error_tmp_clues = "IMEI-SV maximum length: " + this.validationImeiSv.max + " characters"
                } else {
                    this.error_tmp_clues = ""
                }
            }
            if (this.clueNameTmp == 'msisdn') {
                let { is_msisdn, wording_msisdn } = this.msisdnValidation(this.clueValTmp)
                if (is_msisdn) {
                    this.error_tmp_clues = ""
                } else {
                    this.error_tmp_clues = wording_msisdn
                }
            }
        },
        inputParentClue(event, type, key){            
            const value = event.target.value
            let isError = false
            let msg = ""
            if (String(value).length == 0) {
                isError = true
                msg = "Clue cannot be empty"
            } else if (type == 'imei-sv') {
                if (String(value).length < this.validationImeiSv.min) {
                    isError = true
                    msg = "IMEI-SV minimum length: " + this.validationImeiSv.min + " characters"
                } else if(String(value).length > this.validationImeiSv.max) {
                    isError = true
                    msg = "IMEI-SV maximum length: " + this.validationImeiSv.max + " characters"
                }
                // } else (
                //     this.error_parent_clues = this.error_parent_clues.filter(function(item) {
                //         return item.index !== key;
                //     })
                // )
            } else if (type == 'msisdn') {
                let { is_msisdn, wording_msisdn } = this.msisdnValidation(value)
                if (is_msisdn) {
                    isError = false
                } else {
                    isError = true
                    msg = wording_msisdn
                }
            }
            if (isError) {
                if(!this.error_parent_clues.find( ({ index }) => index === key )) {    
                    this.error_parent_clues.push({
                        index: key,
                        message: msg
                    })
                } else {
                    this.error_parent_clues[key]['message'] = msg
                }
            } else {
                this.error_parent_clues = this.error_parent_clues.filter(function(item) {
                    return item.index !== key;
                })
            }
        },
        inputDetailClue(event, type, key){
            if (type == 'imei') {
                const value = event.target.value
                if (String(value).length > 0 && String(value).length < this.validationImei.min) {
                    if(!this.error_detail_clues.find( ({ index }) => index === key )) {    
                        this.error_detail_clues.push({
                            index: key,
                            message: "IMEI minimum length: " + this.validationImei.min + " characters"
                        })
                    }
                } else if(String(value).length > 0 && String(value).length > this.validationImei.max) {
                    if(!this.error_detail_clues.find( ({ index }) => index === key )) {    
                        this.error_detail_clues.push({
                            index: key,
                            message: "IMEI maximum length: " + this.validationImei.max + " characters"
                        })
                    }
                } else (
                    this.error_detail_clues = this.error_detail_clues.filter(function(item) {
                        return item.index !== key;
                    })
                )
            }
        },
        checkErrorParentClue(key){
            const arr = this.error_parent_clues.filter(item => item.index == key)
            if (arr.length > 0) {
                return true
            }
            return false
        },
        getErrorDetail(key, type){
            let array = this.error_detail_clues
            if (type != 'detail') {
                array = this.error_parent_clues
            }

            const arr = array.filter(function(item) {
                return item.index == key;
            })
            return arr[0]['message']
        },
        addClue() {
            if (this.clueValTmp.length==0 || this.clueNameTmp.length==0) {
                this.$swal.fire({
                    icon: 'warning',
                    title: 'Data is required!',
                    text: 'Please fill in completely!',
                });
                return;
            }
            
            if (this.error_tmp_clues != '') {
                this.$swal.fire({
                    icon: 'warning',
                    title: 'Data not valid!',
                    text: this.error_tmp_clues,
                });
                return;
            // } else {
            }

            if (this.checkDuplicateClues(this.clueValTmp)) {
                this.$swal.fire({
                    icon: 'warning',
                    title: 'Keyword already exist',
                    text: 'Please fill in different keyword!',
                });
                return;
            // } else {
            }

            var resetTmp = true
            if (this.clueNameTmp == 'msisdn') {
                // console.log((this.clueValTmp).substring(0, 3))
                let { is_msisdn, wording_msisdn } = this.msisdnValidation(this.clueValTmp)
                if (is_msisdn) {
                
                    this.model.clues.push({
                        name: this.clueNameTmp,
                        value: this.removeNonNumeric(this.clueValTmp), 
                        detail: [
                            {
                                name: 'imsi',
                                value: ''
                            },
                            {
                                name: 'imei',
                                value: ''
                            },
                        ]
                    })
                } else {                    
                    resetTmp = false
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'Incorrect MSISDN format!',
                        text: wording_msisdn,
                    });
                }
            } else {
                this.model.clues.push({
                    name: this.clueNameTmp,
                    value: this.clueValTmp,
                    detail: []
                })
            }

            if(resetTmp){                
                this.clueNameTmp = ''
                this.clueValTmp = ''
            }
        },
        deleteClue(index) {
            this.model.clues.splice(index, 1);
        },
        checkDuplicateClues(key) {
            var found = false;
            for(var i = 0; i < this.model.clues.length; i++) {
                if (this.model.clues[i].value == key) {
                    found = true;
                    break;
                }
                if (this.model.clues[i].detail != undefined) {
                    for(var j = 0; j < this.model.clues[i].detail.length; j++) {
                        if (this.model.clues[i].detail[j].value == key) {
                            found = true;
                            break;
                        }
                    }
                }
            }
            return found
        },
        addInfo() {
            if (this.infoValTmp.length==0 || this.infoNameTmp.length==0) {
                this.$swal.fire({
                    icon: 'warning',
                    title: 'Data is required!',
                    text: 'Please fill in completely!',
                });

                return;
            }
            else {            
                this.model.additional_info.push({
                    name: this.infoNameTmp,
                    value: this.infoValTmp
                })
                this.infoNameTmp = ''
                this.infoValTmp = ''
            }
        },
        deleteInfo(index) {
            this.model.additional_info.splice(index, 1);
        },
        watchlistModal(open = true) {
            // hide scroll bar if true
            if(open) {
                EventEmit.$emit('overflowHidden', true);
            } else {
                EventEmit.$emit('overflowHidden', false);
            }
            this.popupShowWatchlist = !this.popupShowWatchlist
        },
        checkClueIsEditable(clueName) {
            // prevent operator edit or delete keyword msisdn or imsi
            return !(this.route_name === 'pageTargetEdit' && ['msisdn', 'imsi'].includes(clueName) && this.user.role.label === 'operator')
        },
    },
    mounted() {
        this.$store.commit('target/setShowNavbar', false);
        // console.log('=================== mounted on single target form')
        this.getData();
    },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>


<style lang="sass" scoped>
    .box-slider-color
        .vc-slider
            width: 100%

    .fields-box
        @apply .rounded
        background-color: $color-secondary-600
        &__item
            @apply .p-4 .rounded .flex .border-b
            &:last-child
                border-bottom: 0px
            .field-options
                input
                    margin-top: 1rem
                    &:first-child
                        margin-top: 0px

    .box-photo-container
        .box-photo
            &__item
                position: relative
                display: block
                width: 100%
                padding-bottom: 100%

                .item-photo
                    @apply .absolute .inset-0 .w-full .h-full
                    &:hover
                        .btn-delete
                            @apply .opacity-100
                    
                    .btn-delete
                        @apply .absolute .right-0 .top-0 .mr-1 .mt-1 .opacity-0
                    .added-photo
                        &:hover
                            @apply .bg-black
                        @apply .absolute .inset-0 .w-full .h-full .flex .items-center .justify-center
                        &__input
                            input
                                @apply .absolute .inset-0 .w-full .h-full .opacity-0 .cursor-pointer .z-10

        .cta-select
            &:hover
                @apply .bg-black
            &__input
                input
                    @apply .absolute .inset-0 .w-full .h-full .opacity-0 .cursor-pointer .z-10
        


</style>