<template lang="pug">
.box__body
    .card.card--table.card--no-bg
        .card__header.card--table__header
            h2.text-xl {{ page_title }}
            .header-cta
                .block
                    router-link.btn-default.m-btn-icon(to="/territory-virtual")
                        IconSet(type="chevron-left")
                        span.m-hide.block.ml-3 Back
        .card__body
            .w-full.flex.items-center.justify-center.bg-black.mb-8(style="height:480px")
                gmap-map#map(ref="mapRef" :center="center" :zoom="11" :options="options" style="width:100%; height: 100%;")
            form.container-form(@submit="submit($event)")
                .group-fields.border-b
                    .field
                        .field__label
                            label.block.text-sm.font-bold Name <sup>*</sup>
                        .field__form
                            input.field-style(type="text" placeholder="Write name here" v-model="model.name")
                            .pt-3
                                .message.text-xs.text-red-500(v-if="$v.model.name.$error") This name is required &amp; max length is 255 char.
                                .message.text-xs.text-red-500(v-if="error_name !== ''") {{ error_name }}

                .group-fields.border-b
                    .field
                        .field__label
                            label.block.text-sm.font-bold Select Target <sup></sup>
                        .field__form
                            //- jika operation name belum dipilih
                            //- p.pt-2.hidden Please select the operation name first to find targets.
                            //- menampilkan pilihan target
                            .pt-3
                                .small-heading Select target
                                .fields-box
                                    .fields-box__item
                                        .relative.w-full
                                            input.field-style.w-full#find-target-input(
                                                placeholder="Search target here"
                                                v-model="target_keyword"
                                                @keyup="searchTarget()"
                                            )
                                            .field-icon
                                                IconSet(
                                                    type="search"
                                                )

                                    .flex.items-center.justify-center.w-full.mb-4.mt-4(v-if="status_list.status === 'loading' && next_page === false")
                                        Spinner(show="true" size="48")
                                    template(v-else-if="target_list_data.length > 0")
                                        .fields-box__item
                                            .targets-case
                                                .target-item(
                                                    v-tooltip="'Choose this target'"
                                                    v-for="(target,index) in target_list_data"
                                                    :key="target.id"
                                                    @click="addTarget(target)"
                                                )
                                                    .mr-4
                                                        .w-3.h-3.rounded-full(:style="'background-color: '+ target.label +';'")
                                                    .mr-4
                                                        figure.w-8.h-8.rounded-full.overflow-hidden
                                                            img(:src="target.photo ? target.photo : imgAvatar" @error="getDefaultImage")
                                                    span {{ target.target_name }}

                                        .fields-box__item.flex.items-center.justify-center.w-full
                                            span(v-if="total_page === page") No more data avaliable.
                                            
                                            template(v-else)
                                                Spinner(v-if="status_list.status === 'loading' && next_page === true" show="true" size="32")
                                                button.btn-default.btn-small(v-else @click="searchTarget(true)" type="button") Click to load more target

                                    .fields-box__item.flex.items-center.justify-center.w-full.mb-4.mt-2(v-else-if="list.data && list.data.length == 0")
                                        span.text-md
                                            | No data available.

                .group-fields.border-b
                    .field
                        .field__label
                            label.block.text-sm.font-bold Target Choosen <sup>*</sup>
                        .field__form
                            //- jika operation name belum dipilih
                            //- p.pt-2.hidden Please select the operation name and select targets.

                            //- menampilkan pilihan target
                            .pt-3(v-if="target_choosen.length > 0")
                                //- .small-heading Selected target
                                .fields-box
                                    .fields-box__item(
                                        v-for="(target, index) in target_choosen"
                                        :keys="index"
                                    )
                                        .w-full
                                            .flex.justify-between.w-full
                                                .flex-grow.flex.items-center
                                                    .mr-4
                                                        .w-3.h-3.rounded-full(:style="'background-color: '+ target.label +';'")
                                                    .mr-4
                                                        figure.w-8.h-8.rounded-full.overflow-hidden
                                                            img(:src="target.photo ? target.photo : imgAvatar" @error="getDefaultImage")
                                                    //- span Target's name

                                                    //- span.text-light(v-if="target.id.length > 0") {{ target.target_name }}
                                                    span(v-if="target.id.length > 0") {{ target.target_name }}
                                                    input.field-style(v-else type="text" placeholder="Target Name, Ex: 'Harry Parker'" :value="target.target_name" @keyup="updateCreateTarget($event, index, true)")

                                                button.btn-icon-40(type="button" v-tooltip="'Remove Target'" @click="removeTarget(index)")
                                                    IconSet(type="delete")

                                            .mt-4(v-if="target.id.length > 0")
                                                .ui-checkbox.ui-checkbox--small.mr-8(v-for="(target_msisdn, index) in target.msisdn" :key="index")
                                                    input(type="checkbox" v-model="target_checked" :value="target.id + '_' + target_msisdn" @change="checkTargetMsisdn($event, target.id, target_msisdn)")
                                                    .style-checkbox
                                                        .checkbox
                                                            IconSet(type="check")
                                                        span {{ target_msisdn }}

                            p.pt-2(v-else) No target yet

                            .pt-3
                                .message.text-xs.text-red-500(v-if="$v.model.target.$error") This name is required.
                                .message.text-xs.text-red-500(v-if="error_target !== ''") {{ error_target }}

                .group-fields.border-b
                    .field
                        .field__label
                            label.block.text-sm.font-bold Description
                        .field__form
                            textarea.field-style(placeholder="Write description here" v-model="model.description" style="min-height: 200px;")
                            .pt-3
                                //- .message.text-xs.text-red-500(v-if="$v.model.description.$error") This description is required &amp; max length is 255 char.
                                .message.text-xs.text-red-500(v-if="error_description !== ''") {{ error_description }}

                .flex.items-center.justify-end.mb-8
                    button.btn.btn-default(type="button" @click="reset()")
                        | Reset
                    button.btn-primary-o.ml-2(type="submit" @click="submit($event, true)")
                        | Save &amp; Continue
                    button.btn.btn-primary.ml-4(type="submit" @click="submit($event)")
                        | Save

</template>

<script>
import { mapState } from 'vuex';
import IconSet from '@/components/IconSet.vue'
import { required, maxLength } from "vuelidate/lib/validators";
import { gmapApi } from 'vue2-google-maps';
import Spinner from '@/components/Spinner.vue';

export default {
    name: 'pageTerritoryVirtualCreate',
    components: {
        IconSet,
        Spinner,
    },
    data() {
        return {
            // gmap
            options: {
                // zoomControl: true,
                // mapTypeControl: true,
                // scaleControl: true,
                // streetViewControl: true,
                // rotateControl: true,
                // fullscreenControl: false,
                // disableDefaultUi: false
                // scrollwheel: false,
                gestureHandling: 'cooperative',
                styles: [
                    {elementType: 'geometry', stylers: [{color: '#242f3e'}]},
                    {elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}]},
                    {elementType: 'labels.text.fill', stylers: [{color: '#746855'}]},
                    {
                        featureType: 'administrative.locality',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#d59563'}]
                    },
                    {
                        featureType: 'poi',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#d59563'}]
                    },
                    {
                        featureType: 'poi.park',
                        elementType: 'geometry',
                        stylers: [{color: '#263c3f'}]
                    },
                    {
                        featureType: 'poi.park',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#6b9a76'}]
                    },
                    {
                        featureType: 'road',
                        elementType: 'geometry',
                        stylers: [{color: '#38414e'}]
                    },
                    {
                        featureType: 'road',
                        elementType: 'geometry.stroke',
                        stylers: [{color: '#212a37'}]
                    },
                    {
                        featureType: 'road',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#9ca5b3'}]
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'geometry',
                        stylers: [{color: '#746855'}]
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'geometry.stroke',
                        stylers: [{color: '#1f2835'}]
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#f3d19c'}]
                    },
                    {
                        featureType: 'transit',
                        elementType: 'geometry',
                        stylers: [{color: '#2f3948'}]
                    },
                    {
                        featureType: 'transit.station',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#d59563'}]
                    },
                    {
                        featureType: 'water',
                        elementType: 'geometry',
                        stylers: [{color: '#17263c'}]
                    },
                    {
                        featureType: 'water',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#515c6d'}]
                    },
                    {
                        featureType: 'water',
                        elementType: 'labels.text.stroke',
                        stylers: [{color: '#17263c'}]
                    }
                ],
            },
            infoWindows: [],
            mapLoaded: false,
            // mapLoaded: true,
            center: {},
            markers: [],

            rectangle_data: {},
            rectangle_bounds: [],

            // default rectangle bounds -> monas, jakarta
            bounds: {
                north: -6.170255000000001,
                east: 106.83286432275388,
                south: -6.18188099999998,
                west: 106.8211273227539,
            },

            // form data
            model: {
                name: '',
                point: '',
                target: '',
                // target: [],
                // target_checked: [],
                description: '',
            },

            target: [],
            target_checked: [],

            // error message
            error_name: '',
            error_point: '',
            error_target: '',
            error_description: '',

            target_keyword: '',
            target_choosen: [],
            timeout: null,

            imgAvatar: this.$store.getters['config/getDefaultImageProfile'],

            places_list: [],
            place_index: '',
            place_input: '',
            object_id: '',

            // pagination for target list
            target_list_data: [],
            page: 1,
            total_page: null,
            next_page: false,
        }
    },
    validations: {
        model: {
            name: {
                required,
                maxLength: maxLength(255)
            },
            point: {
                required,
            },
            target: {
                required,
            },
            description: {
                // 
            },
        },
    },
    computed: {
        google: gmapApi,
        ...mapState('territoryVirtual', [
            // 'status_detail',
            'detail',
        ]),
        ...mapState('target', [
            'status_list',
            'list',
        ]),
        route_name() {
            return this.$route.name;
        },
        id() {
            return this.$route.params.id;
        },
        page_title() {
            let title = 'Edit Territory Virtual';
            if (this.route_name === 'pageTerritoryVirtualCreate')
                title = 'Add Territory Virtual';
            return title;
        },
        rectangle: {
            get() {
                // console.log(Object.keys(this.rectangle_data).length);
                if (!this.rectangle_data)
                    return this.google.maps.Rectangle;

                return this.rectangle_data;
            },
            set(v) {
                this.rectangle_data = v;
            },
        },
    },
    watch: {
        google() {},
        route_name() {
            this.getData();
        },
        id() {},
        page_title() {},
        rectangle() {},

        // pagination for target list
        list(newData) {
            if (newData.data && newData.data.length > 0) {
                // console.log(newData.data.length)
                this.page = parseInt(newData.current_page);
                this.total_page = parseInt(newData.total_page);

                for (var i = 0; i < newData.data.length; i++) {
                    this.target_list_data.push(newData.data[i]);
                }
            }

        },
        target_keyword(newData) {
            if (newData.length < 3)
                this.$store.commit('target/setList', {});
        },
        target_choosen(newData) {
            let target = newData;
            if (target.length > 0) {
                target = target.map(function(item) {
                    let target = {
                        id: item.id,
                        msisdn: item.msisdn,
                        label: item.label,
                    };
                    return target;
                });
            }
            // this.target = JSON.stringify(target);
            this.model.target = JSON.stringify(target);
            // this.target = target;
        },
        // territory virtual detail
        detail(newData) {
            this.model.name = (newData.name ? newData.name : '');
            this.model.description = (newData.description ? newData.description : '');

            if (newData.point && newData.point[0]) {
                let bounds = {
                    north: parseFloat(newData.point[0].north_east.lat),
                    east: parseFloat(newData.point[0].north_east.lng),
                    south: parseFloat(newData.point[0].south_west.lat),
                    west: parseFloat(newData.point[0].south_west.lng),
                };

                if (this.mapLoaded && Object.keys(this.rectangle).length > 0) {
                    this.rectangle.setBounds(bounds);
                    this.mapBounds(newData.point[0]);
                }
            }

            // target
            if (newData.target && newData.target.length > 0) {
                this.target_choosen = newData.target;
                let target_checked = [];

                for (var i = 0; i < newData.target.length; i++) {
                    for (var j = 0; j < newData.target[i].msisdn.length; j++) {
                        target_checked.push(newData.target[i].id + '_' + newData.target[i].msisdn[j])
                    }
                }
                this.target_checked = target_checked;
            }
        },
    },
    methods: {
        getDefaultImage(e) {
            e.target.src = this.imgAvatar;
        },
        hideAllInfoWindows() {
            for (var i=0; i < this.infoWindows.length; i++) {
                this.infoWindows[i].close();
            }
        },
        mapBounds(point=null) {
            var self = this;
            self.$nextTick(() => {
                self.$refs.mapRef.$mapPromise.then((map) => {
                    const google = self.google;
                    var bounds = new google.maps.LatLngBounds();

                    if (point !== null) {
                        bounds.extend(new google.maps.LatLng(parseFloat(point.north_east.lat), parseFloat(point.north_east.lng)));
                        bounds.extend(new google.maps.LatLng(parseFloat(point.south_west.lat), parseFloat(point.south_west.lng)));
                    } else {
                        bounds.extend(new google.maps.LatLng(self.bounds.north, self.bounds.east));
                        bounds.extend(new google.maps.LatLng(self.bounds.south, self.bounds.west));
                    }

                    // Apply fitBounds
                    map.fitBounds(bounds); 
                });
            });
        },
        typePlace(keyCode, value) {
            var autocomplete_list = document.getElementById('autocomplete-list');
            autocomplete_list.innerHTML = '';

            if (keyCode === 13) {
                if (value.length < 3) {
                    EventEmit.$emit('error', "Please write place keyword!");
                    return;
                }
                EventEmit.$emit('error', "Please select place from keyword result!");

            } else {
                if (this.place_input !== value) {
                    this.places_list = [];
                    this.place_index = '';
                    this.object_id = '';

                    // clear timeout variable
                    clearTimeout(this.timeout);

                    var self = this;
                    this.timeout = setTimeout(function () {

                        if (value.length > 2) {
                            var htmlString = 
                                '<transition>'+
                                    '<svg class="spinner show m-auto" width="44" height="44" viewBox="0 0 44 44">'+
                                        '<circle class="path" fill="none" stroke-width="4" stroke-linecap="round" cx="22" cy="22" r="20"></circle>'+
                                    '</svg>'+
                                '<transition>';
                            var div = document.createElement('div');
                            div.classList.add('text-center');
                            div.innerHTML = htmlString.trim();
                            let node = document.createElement("LI");
                            node.classList.add('text-center');
                            node.appendChild(div);
                            autocomplete_list.appendChild(node);

                            self.$store.dispatch('territoryVirtual/getSearchMapPlaceList', value)
                            .then((resp) => {
                                autocomplete_list.innerHTML = '';

                                if (resp.result === 'success' && resp.data.length > 0) {

                                    var data = resp.data;
                                    self.places_list = data;

                                    for (var i = 0; i < data.length; i++) {
                                        let node = document.createElement("LI");
                                        let textnode = document.createTextNode(data[i].locale_names + (data[i].administrative.length > 0 ? ', '+ data[i].administrative.reverse().join(', ') : ''));
                                        node.appendChild(textnode);
                                        // document.getElementById("autocomplete-list").appendChild(node);
                                        // console.log(node)
                                        let index = i;
                                        node.addEventListener('click', function() {
                                            self.place_index = index;
                                            self.object_id = self.places_list[index].objectID;

                                            var place_name = self.places_list[index].locale_names + (self.places_list[index].administrative.length > 0 ? ', '+ self.places_list[index].administrative.reverse().join(', ') : '');
                                            self.place_input = place_name;
                                            document.getElementById('search-box-map-pac-input').value = place_name;
                                            autocomplete_list.innerHTML = '';

                                            self.searchPlace();
                                        });
                                        autocomplete_list.appendChild(node);
                                    }

                                } else {
                                    node = document.createElement("LI");
                                    node.classList.add('text-center');
                                    let textnode = document.createTextNode("No Data Available");
                                    node.appendChild(textnode);
                                    autocomplete_list.appendChild(node);
                                }
                            });
                        }

                    }, 3000); // 3 second
                }
            }
        },
        searchPlace() {
            EventEmit.$emit('showLoading', true);
            this.$store.dispatch('territoryVirtual/getSearchMapPlace', this.object_id)
            .then((resp) => {
                if (resp.result === 'success' && Object.keys(resp.data).length > 0) {
                    if (resp.data._geoloc && (resp.data._geoloc[0] || (resp.data._geoloc.lat && resp.data._geoloc.lng))) {
                        EventEmit.$emit('showLoading', false);
                        if (Array.isArray(resp.data._geoloc)) {
                            this.changePlace(resp.data._geoloc[0]);
                        } else {
                            this.changePlace({
                                lat: resp.data._geoloc.lat,
                                lng: resp.data._geoloc.lng,
                            });
                        }
                    } else {
                        EventEmit.$emit('error', 'Coordinate is empty!');
                    }
                } else {
                    EventEmit.$emit('error', 'Place not found!');
                }

            });
        },
        changePlace(coordinate) {
            var self = this;
            self.$nextTick(() => {
                self.$refs.mapRef.$mapPromise.then((map) => {
                    const google = self.google;

                    map.setCenter(new google.maps.LatLng(coordinate));
                    // map.setCenter(new google.maps.LatLng(coordinate.lat, coordinate.lng));

                    map.setZoom(20);

                    var distance = 500; // in meter
                    var bounds = new google.maps.LatLngBounds();

                    var north = google.maps.geometry.spherical.computeOffset(map.getCenter(), distance, 0);
                    bounds.extend(north);
                    var east = google.maps.geometry.spherical.computeOffset(map.getCenter(), distance, 90);
                    bounds.extend(east);
                    var south = google.maps.geometry.spherical.computeOffset(map.getCenter(), distance, 180);
                    bounds.extend(south);
                    var west = google.maps.geometry.spherical.computeOffset(map.getCenter(), distance, 270);
                    bounds.extend(west);

                    let bounds_rectangle = {
                        north: north.lat(),
                        east: east.lng(),
                        south: south.lat(),
                        west: west.lng(),
                    };

                    let point = {
                        "north_east": {
                            "lat": bounds_rectangle.north,
                            "lng": bounds_rectangle.east
                        },
                        "south_west": {
                            "lat": bounds_rectangle.south,
                            "lng": bounds_rectangle.west
                        }
                    };

                    if (this.mapLoaded && Object.keys(this.rectangle).length > 0) {
                        this.rectangle.setBounds(bounds_rectangle);
                        this.mapBounds(point);
                    }

                });
            });
        },
        async startMap() {
            var self = this;
            // var mapsLatLng = [];

            // monas, jakarta
            this.center = {
                lat: -6.1763562,
                lng: 106.8227796,
            };
            this.mapLoaded = true;

            await self.$nextTick(() => {
                self.$refs.mapRef.$mapPromise.then((map) => {
                    const google = self.google;
                    map.center = new google.maps.LatLng(self.center);

                    // Define a rectangle and set its editable property to true.
                    self.rectangle = new google.maps.Rectangle({
                        strokeColor: "#FF0000",
                        strokeOpacity: 0.8,
                        strokeWeight: 3,
                        fillColor: "#FFF",
                        fillOpacity: 0.35,
                        // bounds: bounds,
                        bounds: self.bounds,
                        editable: true,
                        draggable: true,
                    });

                    self.rectangle.setMap(map);

                    // Add an event listener on the rectangle.
                    self.rectangle.addListener("bounds_changed", this.showNewRect);

                    // // Apply fitBounds
                    self.mapBounds();

                    // set map rectangle bounds
                    self.showNewRect();

                    var search_box = 
                        '<div class="search-box-map">'+
                            '<div class="field">'+
                                '<input id="search-box-map-pac-input" type="text" placeholder="Search place here (type min 3 characters)..." class="field-style">'+
                                '<div class="field-icon">'+
                                    '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>'+
                                '</div>'+
                            '</div>'+
                            '<div class="search-result">'+
                                '<ul id="autocomplete-list"></ul>'+
                            '</div>'+
                        '</div>';

                    var get_map = document.getElementById('map');

                    var el = document.createElement('div');
                    el.innerHTML = search_box;
                    get_map.after(el);

                    var searchBoxDiv = document.querySelector('.search-box-map');
                    var searchBoxPacInput = document.querySelector('#search-box-map-pac-input');

                    searchBoxPacInput.addEventListener('keyup', function(e) {
                        self.typePlace(e.keyCode, e.target.value)
                        // if (e.keyCode === 13) {
                        //     // action to get api here
                        //     console.log(e.target.value);
                        //     // self.clickNotification(type, data);
                        //     self.changePlace();
                        // }
                    });

                    map.controls[google.maps.ControlPosition.TOP].push(searchBoxDiv);

                    setTimeout(function(){
                        searchBoxDiv.style.display = 'inline';
                    }, 1050);
                });
            });
        },
        showNewRect() {
            const ne = this.rectangle.getBounds().getNorthEast();
            const sw = this.rectangle.getBounds().getSouthWest();

            let data_area = {
                north_east: {
                    lat: ne.lat(),
                    lng: ne.lng(),
                },
                south_west: {
                    lat: sw.lat(),
                    lng: sw.lng(),
                },
            };

            if (this.rectangle_bounds.length === 0) {
                this.rectangle_bounds.push(data_area);
            } else {
                this.rectangle_bounds[0] = data_area;
            }

            this.model.point = JSON.stringify(this.rectangle_bounds);
        },
        searchTarget(nextPage=false) {
            // clear timeout variable
            clearTimeout(this.timeout);
            var self = this;
            this.timeout = setTimeout(function () {
                // if (self.target_keyword.length > 2) {
                    self.$store.commit('target/setList', {});
                    if (nextPage === false) {
                        self.target_list_data = [];
                        self.page = 1;
                        self.total_page = null;
                        self.next_page = false;
                    } else {
                        self.next_page = true;
                    }
                    self.$store.dispatch('target/getList', [5, (nextPage === false ? self.page : (self.page + 1)), self.target_keyword]);
                // }
            }, 650);
        },
        addTarget(target) {
            target = {
                id: target.id,
                target_name: target.target_name,
                photo: target.photo,
                msisdn: target.clues, // for map msisdn[]
                label: target.label,
            };

            if (target.msisdn.length > 0) {
                let search_msisdn = target.msisdn;
                search_msisdn = search_msisdn.filter(function(item) {
                    if (item.name === 'msisdn')
                        return item;
                });
                search_msisdn = Object.keys(search_msisdn).map((key) => search_msisdn[key].value);

                if (search_msisdn.length > 0) {

                    target.msisdn = search_msisdn;

                    if (this.target_choosen.length === 0) {
                        // add checked new msisdn target
                        for (var i = 0; i < target.msisdn.length; i++) {
                            this.target_checked.push(target.id + '_' + target.msisdn[i])
                        }
                        this.target_choosen.push(target);

                    } else {

                        let index_data;
                        let search_target =  this.target_choosen.filter(function(item, index) {
                            if (item.id == target.id) {
                                index_data = index;
                                return item;
                            }
                        });

                        if (search_target.length > 0) {
                            // add checkbox if any data msisdn not saved in target
                            for (var j = 0; j < target.msisdn.length; j++) {

                                let index_checked = null;
                                let search_checked = this.target_checked.filter(function(item, index) {
                                    if (item === target.id + '_' + target.msisdn[j]) {
                                        index_checked = index;
                                        return item;
                                    }
                                });

                                if (search_checked.length > 0 && index_checked !== null) {
                                    this.target_checked[index_checked] = target.id + '_' + target.msisdn[j];
                                }
                            }

                            // this.target_choosen[index_data] = target;
                            this.$set(this.target_choosen, index_data, target);

                            this.$swal.fire({
                                // icon: 'warning',
                                // title: 'Target '+ target.target_name +' already exist!',
                                icon: 'success',
                                title: 'Target '+ target.target_name +' updated!',
                            });
                        } else {
                            // add checked new msisdn target
                            for (var k = 0; k < target.msisdn.length; k++) {
                                this.target_checked.push(target.id + '_' + target.msisdn[k])
                            }

                            this.target_choosen.push(target);
                        }
                    }
                } else {
                    EventEmit.$emit('error', "Target don't have MSISDN!");
                }
            } else {
                EventEmit.$emit('error', "Target don't have MSISDN!");
            }

            this.target_keyword = '';
            if (document.getElementById('find-target-input'))
                document.getElementById('find-target-input').focus();
        },
        removeTarget(index) {
            for (var i = 0; i < this.target_choosen.length; i++) {
                if (index === i) {
                    // remove target checked if target removed
                    let target_remove = this.target_choosen[index];
                    for (var j = 0; j < target_remove.msisdn.length; j++) {
                        let index_checked = null;
                        let search_checked = this.target_checked.filter(function(item, index) {
                            if (item === target_remove.id + '_' + target_remove.msisdn[j]) {
                                index_checked = index;
                                return item;
                            }
                        });

                        if (search_checked.length > 0 && index_checked !== null) {
                            this.target_checked.splice(index_checked, 1);
                        }
                    }
                    this.target_choosen.splice(index, 1);
                }
            }
        },
        checkTargetMsisdn(e, target_id, msisdn) {
            let check_target = this.target_checked;
            check_target = check_target.filter(function(item) {
                let split_item = item.split('_');
                if (split_item[0] === target_id)
                    return item;
            });

            if (check_target.length === 0) {
                this.target_checked.push(target_id + '_' + msisdn);
                e.target.checked = true;

                this.$swal.fire({
                    icon: 'warning',
                    title: 'Target need min 1 MSISDN!',
                    // allowOutsideClick: false,
                });
                // return;
            }
        },

        setEmptyModel(all=true) {
            for (let key in Object.keys(this.model)) {
                if (all === true) {
                    this.model[Object.keys(this.model)[key]] = '';
                } else {
                    if (Object.keys(this.model)[key] !== 'point')
                        this.model[Object.keys(this.model)[key]] = '';
                }
            }
        },
        setErrors(empty=true, errors=null) {

            if (empty === true) {
                this.error_name = '';
                this.error_point = '';
                this.error_target = '';
                this.error_description = '';
            } else if (empty !== true && errors !== null) {
                this.error_name = (errors.name ? errors.name : '');
                this.error_point = (errors.point ? errors.point : '');
                this.error_target = (errors.target ? errors.target : '');
                this.error_description = (errors.description ? errors.description : '');
            }
        },
        getData() {
            this.reset(true);

            if (this.route_name === 'pageTerritoryVirtualEdit') {
                EventEmit.$emit('showLoading', true);

                // get first list target
                this.searchTarget();

                this.$store.dispatch('territoryVirtual/getDetail', this.id)
                .then((resp) => {
                    if (resp === 'success') {
                        EventEmit.$emit('showLoading', false);
                    } else {
                        EventEmit.$emit('error');
                    }
                });
            } else {
                if (this.route_name === 'pageTerritoryVirtualCreate') {
                    this.$store.commit('territoryVirtual/setDetail', {});

                    // get first list target
                    this.searchTarget();

                    // set map to default
                    if (this.mapLoaded && Object.keys(this.rectangle).length > 0) {
                        this.rectangle.setBounds(this.bounds);
                        this.mapBounds();
                    }
                }
            }
        },
        reset(direct=false) {
            if (direct === true) {
                this.setEmptyModel(false);
                // reset vuelidate
                this.$nextTick(() => { this.$v.$reset(); });
                this.setErrors(true);
                window.scrollTo(0,0);

                this.target_keyword = '';
                this.target_choosen = [];
                this.$store.commit('target/setList', {});

                // pagination target list
                this.target_list_data = [];
                this.page = 1;
                this.total_page = null;
                this.next_page = false;
                
                // console.log('reset true');
                // console.log(this.rectangle);
                // this.rectangle.setBounds(this.bounds);

            } else {
                this.$swal.fire({
                    icon: 'warning',
                    title: 'Are you sure you want to reset form?',
                    // text: id,
                    confirmButtonText: 'Yes!',
                    confirmButtonColor: '#DD6B55',
                    showCancelButton: true,
                    allowOutsideClick: false,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        if (this.route_name === 'pageTerritoryVirtualCreate') {
                            this.setEmptyModel(false);
                            // reset vuelidate
                            this.$nextTick(() => { this.$v.$reset(); });
                            this.setErrors(true);
                            window.scrollTo(0,0);

                            this.target_keyword = '';
                            this.target_choosen = [];
                            this.$store.commit('target/setList', {});

                            // pagination target list
                            this.target_list_data = [];
                            this.page = 1;
                            this.total_page = null;
                            this.next_page = false;

                            this.rectangle.setBounds(this.bounds);

                        } else {
                            this.getData();
                        }
                    }
                });
            }
        },
        submit(e, save_continue=false) {
            e.preventDefault();
            this.$v.$touch();

            if (this.$v.model.$invalid) {
                for (let key in Object.keys(this.$v.model)) {
                    const input = Object.keys(this.$v.model)[key];
                    if (input.includes("$")) return false;
                    if (this.$v.model[input].$error) {
                        break;
                    }
                }
                // EventEmit.$emit('error', 'Please fill the form correctly.');
                // EventEmit.$emit('showLoading', false);
                window.scrollTo(0,0);
                return;
            }

            // custom validation for target
            if (this.target_choosen.length==0 || this.target_checked.length==0) {
                this.error_target = (this.target_choosen.length==0 || this.target_checked.length==0 ? 'Field target is required.' : '');
                window.scrollTo(0,0);
                return;
            }

            EventEmit.$emit('showLoading', true);

            // let target = JSON.parse(this.target);
            let target = JSON.parse(this.model.target);
            let target_choosen = this.target_choosen;

            for (var i = 0; i < target_choosen.length; i++) {
                let target_choosen_id = target_choosen[i].id;
                let target_checked = this.target_checked;
                target_checked = target_checked.filter(function(item) {
                    let split_item_msisdn = item.split('_');
                    if (split_item_msisdn[0] === target_choosen_id)
                        return item;
                });

                if (target_checked.length > 0)
                    target_checked = Object.keys(target_checked).map((key) => target_checked[key].split('_')[1]);

                target[i].msisdn = target_checked
            }

            // this.target = JSON.stringify(target);
            this.model.target = JSON.stringify(target);

            let model_data = Object.keys(this.model).map((key) => this.model[key]);

            // console.log(model_data);
            // EventEmit.$emit('showLoading', false);
            // return;

            if (this.route_name === 'pageTerritoryVirtualCreate') {

                this.$store.dispatch('territoryVirtual/create', model_data)
                .then((resp) => {
                    if (resp.result === 'success') {
                        if (save_continue === false) {
                            this.$router.push('/territory-virtual');
                        } else {
                            this.$router.push('/territory-virtual/'+ resp.data.data.id +'/edit');
                        }

                        this.$swal.fire({
                            icon: 'success',
                            title: 'Data successfully created!',
                            timer: 3000,
                        });
                    } else {
                        if (resp.data.message && resp.data.message.errors) {
                            this.setErrors(false, resp.data.message.errors);
                            // EventEmit.$emit('error', null, resp.data.message.errors);
                            EventEmit.$emit('showLoading', false);
                            window.scrollTo(0,0);
                        } else {
                            EventEmit.$emit('error');
                        }
                    }
                });

            // pageTerritoryVirtualEdit
            } else {

                model_data = [this.id].concat(model_data);

                this.$store.dispatch('territoryVirtual/update', model_data)
                .then((resp) => {
                    if (resp.result === 'success') {                        
                        if (save_continue === false)
                            this.$router.push('/territory-virtual');

                        this.$swal.fire({
                            icon: 'success',
                            title: 'Data successfully updated!',
                            timer: 3000,
                        });
                    } else {
                        if (resp.data.message && resp.data.message.errors) {
                            this.setErrors(false, resp.data.message.errors);
                            // EventEmit.$emit('error', null, resp.data.message.errors);
                            EventEmit.$emit('showLoading', false);
                            window.scrollTo(0,0);
                        } else {
                            EventEmit.$emit('error');
                        }
                    }
                });
            }
        },
    },
    created() {
        // 
    },
    async mounted() {
        await this.$store.commit('target/setStatusList', { 
            cancel: null,
            status: false,
            code: null
        });
        await this.startMap();
        await this.getData();
    },
}
</script>

<style lang="sass">
    .search-box-map
        padding-top: 0.5rem
        width: 100%
        display: flex !important
        align-items: center
        justify-content: center
        padding-right: 40px
        .field
            max-width: 450px
            width: 100%
            position: relative
            .field-style
                width: 100%
                height: 48px
                padding-top: 0px
                padding-bottom: 0px
                font-size: 1rem
        .search-result
            position: absolute
            top: 100%
            width: 100%
            max-width: 450px
            transform: translateY(-0.25rem)
            background-color: $color-secondary-700
            max-height: 300px
            overflow: auto
            ul
                li
                    padding: 1em
                    font-size: 1rem
                    cursor: pointer
                    &:hover
                        color: $color-main-500
                        background-color: $color-secondary-900


    .fields-box
        @apply .rounded
        background-color: $color-secondary-600
        &__item
            @apply .p-4 .rounded .flex .border-b
            &:last-child
                border-bottom: 0px
            .field-options
                input
                    margin-top: 1rem
                    &:first-child
                        margin-top: 0px
    .targets-case
        @apply .w-full
        .target-item
            @apply .flex .items-center .p-3 .w-full .rounded .cursor-pointer
            transition: all 0.3s ease-in-out
            &:hover
                background-color: $color-secondary-800
    $offset: 126
    $duration: 1.4s

    .spinner
        transition: opacity .15s ease
        animation: rotator $duration linear infinite
        animation-play-state: paused
        &.show
            animation-play-state: running
        &.v-enter, &.v-leave-active
            opacity: 0
        &.v-enter-active, &.v-leave
            opacity: 1

    @keyframes rotator
        0%
            transform: scale(0.5) rotate(0deg)
        100%
            transform: scale(0.5) rotate(270deg)

    .spinner .path
        // stroke: #3498DB
        stroke: #ff6600
        // stroke: #40bb5b
        stroke-dasharray: $offset
        stroke-dashoffset: 0
        transform-origin: center
        animation: dash $duration ease-in-out infinite

    @keyframes dash
        0%
            stroke-dashoffset: $offset
        50%
            stroke-dashoffset: ($offset/2)
            transform: rotate(135deg)
        100%
            stroke-dashoffset: $offset
            transform: rotate(450deg)


</style>
