<template lang="pug">
.result-page__menu(
    v-bind:class="{'is-show' : ShowMenuResultMobile}"
    v-if="$route.matched[0].name !== 'ResultRealtimeShell'"
)
    nav.menu
        .overlay-act.m-only(
            @click="overlayAction()"
        )
        ul
            li(
                v-for="item in menus"
                :key="item.id"
                @click="menuChoosen()"
            )
                router-link(:to="{ path: item.url }") {{ item.name }}
</template>

<script>
import { checkFeature } from '@/config/features';
// import { mapGetters } from 'vuex';

export default {
    name: 'ResultComponentsMenu',
    data(){
        return {
            ShowMenuResultMobile: false, 
        }
    },
    computed: {
        // ...mapGetters({
        //     hidePredictionName: 'config/getHidePredictionName',
        // }),
        menus() {
            let menu = null
            const recentLocationMenu = {
                url: 'recent-location',
                name: 'Recent Location',
            }
            const overallMenu = {
                url: 'overall',
                name: 'Overall',
            }
            const locationMovementMenu = {
                url: 'location-movement',
                name: 'Location Movement',
            }
            const locationLogMenu = {
                url: 'location-log',
                name: 'Location Log',
            }
            const browsingLogMenu = {
                url: 'browsing-log',
                name: 'Browsing Log',
            }
            const aerialLogMenu = {
                url: 'aerial-log',
                name: 'Aerial Log',
            }
            const profileMenu = {
                url: 'profile',
                name: 'Digital ID',
            }
            const historyDeviceRolesMenu = {
                url: 'history-device-roles',
                name: 'History Device Roles',
            }
            const deviceHistoryMenu = {
                url: 'device-history',
                name: 'Device History',
            }
            const tetheredDeviceMenu = {
                url: 'tethered-device',
                name: 'Tethered Device',
            }
            const predictionNameMenu = {
                url: 'prediction-name',
                name: 'Prediction Name',
            }

            const digitalFootprintMenu = {
                url: 'digital-footprint',
                name: 'Digital Footprint',
            }
            
            let realtimeMenu = []
            let historicalMenu = []
            let targetDetailMenu = []

            // check Features ------------------------------------------
            realtimeMenu.push(recentLocationMenu)
            if (this.checkFeatureByAcl('single_target','recent_location')) {
                targetDetailMenu.push(recentLocationMenu)
            }
            if (this.checkFeatureByAcl('query_historical','overall')) {
                historicalMenu.push(overallMenu)
            }
            if (this.checkFeatureByAcl('single_target','overall')) {
                targetDetailMenu.push(overallMenu)
            }

            if (this.checkFeatureByAcl('query_historical','location_movement')) {
                historicalMenu.push(locationMovementMenu)
            }
            if (this.checkFeatureByAcl('single_target','location_movement')) {
                targetDetailMenu.push(locationMovementMenu)
            }

            if (this.checkFeatureByAcl('query_historical','location_log')) {
                historicalMenu.push(locationLogMenu)
            }
            if (this.checkFeatureByAcl('single_target','location_log')) {
                targetDetailMenu.push(locationLogMenu)
            }

            if (this.checkFeatureByAcl('query_historical','browsing_log')) {
                historicalMenu.push(browsingLogMenu)
            }
            if (this.checkFeatureByAcl('single_target','browsing_log')) {
                targetDetailMenu.push(browsingLogMenu)
            }

            if (this.checkFeatureByAcl('query_historical','aerial_log')) {
                historicalMenu.push(aerialLogMenu)
            }
            if (this.checkFeatureByAcl('single_target','aerial_log')) {
                targetDetailMenu.push(aerialLogMenu)
            }

            if (this.checkFeatureByAcl('query_historical','profile')) {
                historicalMenu.push(profileMenu)
            }
            if (this.checkFeatureByAcl('single_target','profile')) {
                targetDetailMenu.push(profileMenu)
            }

            if (this.checkFeatureByAcl('query_historical','history_device_roles')) {
                historicalMenu.push(historyDeviceRolesMenu)
            }
            if (this.checkFeatureByAcl('single_target','history_device_roles')) {
                targetDetailMenu.push(historyDeviceRolesMenu)
            }

            if (this.checkFeatureByAcl('query_historical','device_history')) {
                historicalMenu.push(deviceHistoryMenu)
            }
            if (this.checkFeatureByAcl('single_target','device_history')) {
                targetDetailMenu.push(deviceHistoryMenu)
            }

            if (this.checkFeatureByAcl('query_historical','tethered_device')) {
                historicalMenu.push(tetheredDeviceMenu)
            }
            if (this.checkFeatureByAcl('single_target','tethered_device')) {
                targetDetailMenu.push(tetheredDeviceMenu)
            }

            if (this.checkFeatureByAcl('query_historical','prediction_name')) {
                historicalMenu.push(predictionNameMenu)
            }
            if (this.checkFeatureByAcl('single_target','prediction_name')) {
                targetDetailMenu.push(predictionNameMenu)
            }

            if (this.checkFeatureByAcl('single_target','digital_footprint')) {
                targetDetailMenu.push(digitalFootprintMenu)
            }

            if(this.$route.matched[0].name === "ResultRealtimeShell"){
                menu = realtimeMenu
            } else if(this.$route.matched[0].name === "ResultHistoricalShell"){
                menu = historicalMenu
            } else if(this.$route.matched[0].name === "pageTargetDetail"){
                targetDetailMenu.unshift({
                    url: 'home',
                    name: 'Target Information'
                });
                menu = targetDetailMenu
            }
            // console.log('=====this.$route.matched[0].name', this.$route.matched[0].name)
            // console.log('=====realtimeMenu', realtimeMenu)
            // console.log('=====historicalMenu', historicalMenu)
            // console.log('=====targetDetailMenu', targetDetailMenu)

            return menu;

        }
    },
    watch: {
        menus() {},
    },
    methods: {
        overlayAction() {
            EventEmit.$emit('triggerHideMenu')
        },
        menuChoosen() {
            EventEmit.$emit('triggerHideMenu')
        },
        // checkFeature(feature) {
        //     // return features.includes(feature);
        //     return checkFeature(feature);
        // },
    },
    mounted() {
        EventEmit.$on('triggerShowMenu', () => {
            this.ShowMenuResultMobile = true
            let mainBody = document.getElementsByTagName("body")
            if (mainBody && mainBody[0])
                mainBody[0].classList.add("body-hidden");
        });
        EventEmit.$on('triggerHideMenu', () => {
            this.ShowMenuResultMobile = false
            let mainBody = document.getElementsByTagName("body")
            if (mainBody && mainBody[0])
                mainBody[0].classList.remove("body-hidden");
        });
    },
}
</script>
