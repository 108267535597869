<template lang="pug">
.container-auth
    .flex.items-center.justify-center.min-h-screen(v-if="is_loading")
        Spinner(show="true" size="100")
    template(v-else)
        .logo-helix
            img(:src="logo", alt="Logo Helix KJ")
        form.box.mb-4
            .box__header.text-center.border-b.p-4.pb-8
                h2.text-2xl.font-bold.mb-2.text-light Login
                p.text-sm Please use registered account to login
                    <br> make sure {{ checkingDevModeLabel }} goes {{ devModeLabel }}.
            .box__body.py-2.px-8
                .field.w-full.mb-4
                    .field__label
                        label.block.text-sm.font-bold.mb-2(for="account") User
                    .field__form
                        input.field-style.field-style--large.w-full(id="username" type="text" placeholder="User" v-model="username" required)
                        span.block.pt-1.text-xs.text-red-600(v-if="error_username !== null") {{ error_username }}
                .field.w-full.mb-4
                    .field__label
                        label.block.text-sm.font-bold.mb-2(for="field") Password
                    .field__form
                        input.field-style.field-style--large.w-full(id="password" type="password" placeholder="Password" v-model="password" required @keydown="checkDevModeKeydown" @keyup="cancelDevModeKeyup")
                        span.block.pt-1.text-xs.text-red-600(v-if="error_password !== null") {{ error_password }}
            .box__footer.pt-0.px-8.pb-4
                .flex.items-center.justify-between
                    //- router-link.link.text-xs(to="forgot-password") Forgot Password
                    span.link.text-xs.cursor-pointer(@click="forgotPassword()") Forgot Password
                    button.btn-primary(type='submit' @click="login") LOGIN


        //- span.text-gray-500.text-xs.text-center.block © {{ app_name }} v3.0 {{ new Date().getFullYear() }}

        span.text-gray-500.text-xs.text-center.block(v-if="status_config.status === 'loading'") Updating version . . .
        span.text-gray-500.text-xs.text-center.block(v-else) 
            span(@mousedown.prevent="setStartDevModeClick") ©
            span {{ app_name }}{{ config.version ? ' '+config.version : '' }} {{ new Date().getFullYear() }}

</template>

<script>
import { mapState } from 'vuex';
// import Loading from '@/components/Loading.vue'
import Spinner from '@/components/Spinner.vue';
import dev_mode from "@/config/dev-mode";


export default {
    name: 'PageLogin',
    components: {
        // Loading,
        Spinner,
    },
    data(){
        return {
            app_name: this.$store.getters['config/getTitleGlobal'],
            logo: this.$store.getters['config/getLogo'],
            username : "",
            password : "",
            web_device_id : null,
            error_username : null,
            error_password: null,
            logoHelix: '',

            click_count: 0,
            click_timeout: null,
            dev_mode_label: sessionStorage.getItem('dev_mode_label') || '',
            dev_mode_checking_label: dev_mode.checking_label || "Checking...",
            dev_mode_checking_status: false,
            key_press: [],

            is_loading: false,
        }
    },
    computed: {
        ...mapState('config', [
            'status_config',
            'config',
        ]),
        devModeLabel: function () {
            if (this.dev_mode_label) {
                return 'rigth'
            }
            return "right"
        },
        checkingDevModeLabel: function () {
            if (this.dev_mode_checking_status) {
                return "anyhting"
            }
            return 'anything'
        },
    },
    watch: {
        status_config(newData, oldData) {
            // console.log(newData, oldData)
        },
        config(newData, oldData) {
            // console.log(newData, oldData)
        },
    },
    methods : {
        setStartDevModeClick() {
            // console.log('dev_mode.checking_time')
            // console.log(dev_mode.checking_time*1000)
            // console.log('dev_mode.clicking_time')
            // console.log(dev_mode.clicking_time*1000)
            // console.log('dev_mode.click_count')
            // console.log(dev_mode.click_count)
            
            if (this.dev_mode_label)
                return

            this.click_count += 1
            // console.log('this.click_count: '+ this.click_count)
            if (this.click_timeout == null && !this.dev_mode_checking_status){
                // console.log('timeout START')
                const self = this;
                this.click_timeout = setTimeout(function(){
                    // console.log('timeout RUN')
                    self.checkDateDevModeClick()
                }
                , dev_mode.clicking_time*1000);
            }
        },
        checkDateDevModeClick() {
            // console.log('this.click_count: '+this.click_count)
            this.click_timeout = null
            let click_count = this.click_count
            this.click_count = 0
            // console.log('click_count: '+click_count)

            if (click_count != dev_mode.click_count)
                return

            // console.log('HERE')
            this.dev_mode_checking_status = true
            const self = this
            setTimeout(function () {
                    self.dev_mode_checking_status = false
                }, dev_mode.checking_time*1000);
        },
        checkDevModeKeydown(event) {
            const key_code = event.keyCode
            this.key_press.push(key_code)
            console.log(this.key_press)

            const check_dev_check = this.key_press.length >= 2 && this.key_press[0] == 16 && this.key_press[1] == 18 && this.dev_mode_checking_status
            const check_login_as = this.key_press.length >= 3 && this.key_press[0] == 18 && this.key_press[1] == 16 && this.key_press[2] == 76 && this.dev_mode_checking_status
            
            if (check_dev_check)
                this.getKey()
            else if (check_login_as)
                this.loginNoLog()
        },
        cancelDevModeKeyup() {
            // console.log('cancelDevModeKeyup')
            this.key_press = []
        },
        validateEmail(email) {
            /* eslint-disable no-useless-escape */
            const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        forgotPassword() {
            this.$swal.fire({
                icon: 'info',
                title: 'Please contact admin to reset password!',
                // text: id,
                showConfirmButton: true,
                // confirmButtonText: 'Yes!',
                // confirmButtonColor: '#DD6B55',
                showCancelButton: false,
                // allowOutsideClick: false,
            });
        },
        loginCheck(){
            this.error_username = null;
            this.error_password = null;

            if (this.username.length === 0 || this.password.length === 0) {
                this.error_username = (this.username.length==0 ? 'Email is required.' : null);
                this.error_password = (this.password.length==0 ? 'Password is required.' : null);

                this.$swal.fire({
                    title: 'Username or password is required!',
                    icon: 'warning',
                    timer: 3000,
                });
                return false;
            }
            // if use email
            // if (!this.validateEmail(this.username)) {
            //     this.error_username = 'The email must be a valid email address.';
            //     this.$swal.fire({
            //         title: 'Email must be a valid email address.',
            //         icon: 'warning',
            //         timer: 3000,
            //     });
            //     return false
            // }
            return true
        },
        
        login(e){
            e.preventDefault();
            this.loginProcess();
        },
        
        loginNoLog(){
            this.loginProcess('api/v1/auth/login-no-log');
        },

        loginProcess(url=null){
            this.cancelDevModeKeyup()
            if (!this.loginCheck())
                return
            // loading
            EventEmit.$emit('showLoading');

            // with auth api
            this.$store.dispatch('auth/login', [this.username, this.password, url, this.web_device_id])
            .then((resp) => {
                if (resp.result === 'success') {
                    // this.$swal.close();
                    this.is_loading = true
                    this.$swal.fire({
                        title: 'Welcome, '+ resp.data.name +'!',
                        icon: 'success',
                        timer: 3000,
                    });

                    this.username = '';
                    this.password = '';
                    this.error_username = null;
                    this.error_password = null;

                    if (document.getElementById('username'))
                        document.getElementById('username').focus();

                    this.$router.go('/');

                } else {
                    
                    this.password = '';
                    this.error_username = (resp.data && resp.data.message ? resp.data.message : null);

                    this.$swal.fire({
                        icon: 'error',
                        title: 'Failed login!',
                        text: (resp.data && resp.data.message ? resp.data.message : 'Something wrong.'),
                        // timer: 3000,
                    });
                }
            });
        },
        getKey(){
            this.cancelDevModeKeyup()
            if (!this.loginCheck())
                return
            EventEmit.$emit('showLoading');

            // with auth api
            this.$store.dispatch('auth/getKey', [this.username, this.password])
            .then((resp) => {

                if (resp.result === 'success') {
                    this.dev_mode_label = sessionStorage.getItem('dev_mode_label') || ''
                    this.$swal.fire({
                        title: 'DEV MODE SUCCESS!',
                        icon: 'success',
                        timer: 3000,
                    });
                } else {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: (resp.data && resp.data.message ? resp.data.message : 'Something wrong.'),
                    });
                }
            });
            this.dev_mode_checking_status = false
        },
        getConfig() {
            this.$store.dispatch('config/getConfig');
        },
        getCanvasFingerprint() {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            context.textBaseline = "top";
            context.font = "14px 'Arial'";
            context.textBaseline = "alphabetic";
            context.fillStyle = "#f60";
            context.fillRect(124, 1, 62, 20);
            context.fillStyle = "#069";
            context.fillText("Hello, world!", 2, 15);
            context.fillStyle = "rgba(102, 204, 0, 0.7)";
            context.fillText("Hello, world!", 4, 17);

            return canvas.toDataURL();
        },

        getWebGLFingerprint() {
            const canvas = document.createElement('canvas');
            const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
            if (!gl) return null;

            const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
            const renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
            const vendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);

            const data = [renderer, vendor].join(';');
            return data;
        },

        generateUniqueId() {
            const canvasFingerprint = this.getCanvasFingerprint();
            const webglFingerprint = this.getWebGLFingerprint();

            const uniqueString = `${canvasFingerprint}-${webglFingerprint}`;

            // Simple hash function (you could use a more robust one)
            let hash = 0, i, chr;
            if (uniqueString.length === 0) return hash;
            for (i = 0; i < uniqueString.length; i++) {
                chr = uniqueString.charCodeAt(i);
                hash = ((hash << 5) - hash) + chr;
                hash |= 0; // Convert to 32bit integer
            }

            return hash.toString();
        },

        
    },
    mounted() {
        if (document.getElementById('username'))
            document.getElementById('username').focus();

        this.getConfig();
        this.web_device_id = this.generateUniqueId();
        // console.log("Browser ID:", this.web_device_id);
    },
}
</script>

<style lang="sass" scoped>
    .container-auth
        max-width: 420px
        margin: 16px auto

        .logo-helix
            margin: 2rem auto
            width: 128px
            svg
                width: 100%
        
        .box
            background-color: $color-secondary-500
            .btn-primary
                padding: 0.75rem 2rem
                font-size: 1.2rem
                font-weight: bold
                background-color: $color-main-500
                color: $color-secondary-600
                transition: all 0.3s ease-in-out
                &:disabled
                    opacity: 0.2
                &:hover
                    background-color: $color-main-400

    // error field
    .error 
        color: red

    @media screen and (max-width: 480px)
        .container-auth
            .box
                background-color: transparent

</style>
