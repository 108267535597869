<template>
  <div :class="['vc-hue', directionClass]" :style="hueLinear">
    <div class="vc-hue-container"
      role="slider"
      :aria-valuenow="colors.hsl.h"
      aria-valuemin="0"
      :aria-valuemax="max"
      ref="container"
      @mousedown="handleMouseDown"
      @touchmove="handleChange"
      @touchstart="handleChange">
      <div class="vc-hue-pointer" :style="{top: pointerTop, left: pointerLeft}" role="presentation">
        <div class="vc-hue-picker"></div>
      </div>
    </div>
  </div>
</template>

<script>
//
export default {
  name: 'Hue',
  props: {
    max: {
      type: Number,
      default: 360
    },
    value: Object,
    direction: {
      type: String,
      // [horizontal | vertical]
      default: 'horizontal'
    }
  },
  data () {
    return {
      oldHue: 0,
      pullDirection: '',
      hue_list: [0, 60, 120, 180, 240, 300, 360]
    }
  },
  computed: {
    colors () {
      const h = this.value.hsl.h
      if (h !== 0 && h - this.oldHue > 0) this.pullDirection = 'right'
      if (h !== 0 && h - this.oldHue < 0) this.pullDirection = 'left'
      this.oldHue = h

      return this.value
    },
    directionClass () {
      return {
        'vc-hue--horizontal': this.direction === 'horizontal',
        'vc-hue--vertical': this.direction === 'vertical'
      }
    },
    pointerTop () {
      if (this.direction === 'vertical') {
        if (this.colors.hsl.h === 0 && this.pullDirection === 'right') return 0
        return -((this.colors.hsl.h * 100) / this.max) + 100 + '%'
      } else {
        return 0
      }
    },
    pointerLeft () {
      if (this.direction === 'vertical') {
        return 0
      } else {
        if (this.colors.hsl.h === 0 && this.pullDirection === 'right') return '100%'
        return (this.colors.hsl.h * 100) / this.max + '%'
      }
    },
    hueLinear () {
      if (!this.max) return

      let max = this.max
      let max_hue = 0
      let linear = ''
      
      this.hue_list.forEach((v)=>{
        if (v > max) return
        if (linear)
          linear += ", "
        linear += 'hsl('+v+', 100%, 50%) '+(v * 100) / max + '%'
        max_hue = v
      })
      
      if (max_hue!=max)
        linear += ', hsl('+max+', 100%, 50%) 100%'
      
      return {
        background: "linear-gradient(to right, " + linear + ")"
      }
    }
  },
  methods: {
    handleChange (e, skip) {
      !skip && e.preventDefault()

      var container = this.$refs.container
      if (!container) {
        // for some edge cases, container may not exist. see #220
        return
      }
      var containerWidth = container.clientWidth
      var containerHeight = container.clientHeight

      var xOffset = container.getBoundingClientRect().left + window.pageXOffset
      var yOffset = container.getBoundingClientRect().top + window.pageYOffset
      var pageX = e.pageX || (e.touches ? e.touches[0].pageX : 0)
      var pageY = e.pageY || (e.touches ? e.touches[0].pageY : 0)
      var left = pageX - xOffset
      var top = pageY - yOffset

      var h
      var percent

      if (this.direction === 'vertical') {
        if (top < 0) {
          h = this.max
        } else if (top > containerHeight) {
          h = 0
        } else {
          percent = -(top * 100 / containerHeight) + 100
          h = (this.max * percent / 100)
        }

        if (this.colors.hsl.h !== h) {
          this.$emit('change', {
            h: h,
            s: this.colors.hsl.s,
            l: this.colors.hsl.l,
            a: this.colors.hsl.a,
            source: 'hsl'
          })
        }
      } else {
        if (left < 0) {
          h = 0
        } else if (left > containerWidth) {
          h = this.max
        } else {
          percent = left * 100 / containerWidth
          h = (this.max * percent / 100)
        }

        if (this.colors.hsl.h !== h) {
          this.$emit('change', {
            h: h,
            s: this.colors.hsl.s,
            l: this.colors.hsl.l,
            a: this.colors.hsl.a,
            source: 'hsl'
          })
        }
      }
    },
    handleMouseDown (e) {
      this.handleChange(e, true)
      window.addEventListener('mousemove', this.handleChange)
      window.addEventListener('mouseup', this.handleMouseUp)
    },
    handleMouseUp (e) {
      this.unbindEventListeners()
    },
    unbindEventListeners () {
      window.removeEventListener('mousemove', this.handleChange)
      window.removeEventListener('mouseup', this.handleMouseUp)
    }
  }
}
</script>

<style>
.vc-hue {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  border-radius: 2px;
}
.vc-hue--horizontal {
  background: linear-gradient(to right, #f00 0%, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, #f00 100%); 
}
.vc-hue--vertical {
  background: linear-gradient(to top, #f00 0%, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, #f00 100%);
}
.vc-hue-container {
  cursor: pointer;
  margin: 0 2px;
  position: relative;
  height: 100%;
}
.vc-hue-pointer {
  z-index: 2;
  position: absolute;
}
.vc-hue-picker {
  cursor: pointer;
  margin-top: 1px;
  width: 4px;
  border-radius: 1px;
  height: 8px;
  box-shadow: 0 0 2px rgba(0, 0, 0, .6);
  background: #fff;
  transform: translateX(-2px) ;
}
.vc-hue--horizontal {
  background: linear-gradient(to right, hsl(0, 100%, 50%) 0%, hsl(60, 100%, 50%) 18.181818181818183%, hsl(120, 100%, 50%) 36.36363636363637%, hsl(180, 100%, 50%) 54.54545454545455%, hsl(240, 100%, 50%) 72.72727272727273%, hsl(300, 100%, 50%) 90.9090909090909%, hsl(330, 100%, 50%) 100%)
}
 
</style>
