<template lang="pug">
.card-content
    .flex.flex-wrap(
        class="flex-col-reverse lg:flex-row"
    )
        template(v-if="status_log.status === 'loading'")
            template(v-if="route_name === 'TargetDetailBrowsingLog'")
                Loading(show="true")
            template(v-else)
                LoadingAnimation(show="true" title="browser-log")
        template(v-else) 
            NoDataAvailable(v-if='filterNull')
            template(v-else-if="log.chart_browser && log.chart_browser.categories !== undefined && Object.keys(log.chart_browser.series).length > 0 && Object.keys(log.summary_browser).length > 0")
                .block(class="w-full mb-4 lg:w-8/12 lg:mb-0")
                    .box-charts
                        highcharts(
                            :options="chartOptions"
                        )

                .block(class="w-full lg:w-4/12")
                    .flex(
                        class="pl-0 lg:pl-10 flex-row lg:flex-col"
                    )
                        .rollout.flex-grow(
                            class="pb-0 lg:pb-4"
                            v-for="(summary, index) in log.summary_browser"
                        )
                            span.text-sm {{summary.name}}
                            p.text-light.text-3xl {{summary.total}}

            NoDataAvailable(v-else)

</template>

<script>
import { mapState } from 'vuex';
import Loading from '@/components/Loading.vue';
import LoadingAnimation from '@/components/LoadingAnimation.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';

export default {
    name: 'BrowserLogBrowser',
    components: {
        Loading,
        NoDataAvailable,
        LoadingAnimation
    },
    data() {
        return {
            title: '',
            chartType: 'bar',
            seriesColor: '#6fcd98',
            colorInputIsSupported: null,
            filterNull: false,
            chartOptions: {
                chart: {
                    type: 'bar'
                },
                title: {
                    text: null
                },
                xAxis: {
                    categories: [],
                    title: {
                        text: null
                    },
                    labels: {
                        step: 1
                    }
                },
                yAxis: {
                    title: {
                        text: null
                    },
                },
                series: [],
                credits: {
                    enabled: false
                },
            },
            dataCat: null,
            dataSeries: null
        }
    },
    computed: {
        ...mapState('browsingLog', [
            'status_log',
            'log',
        ]),
        route_name() {
            return this.$route.name;
        },        
    },
    watch: {
        log() {
            if (this.log.chart_browser && Object.prototype.hasOwnProperty.call(this.log.chart_browser, "categories") && Object.keys(this.log.chart_browser).length > 0 && Object.keys(this.log.chart_browser.series).length > 0) {
                this.filterChart()
            }
        },
        // filterDateRange() {},
    },
    methods: {
        filterChart(){
            this.dataCat = Object.assign([], JSON.parse(JSON.stringify(this.log.chart_browser.categories)));
            this.dataSeries = Object.assign([], JSON.parse(JSON.stringify(this.log.chart_browser.series)));

            var categoriesBrowser = []
            var seriesDataBrowser = []

            if (this.dataCat.length == 0) {
                this.filterNull=true
            } else {
                this.filterNull=false            

                categoriesBrowser = this.dataCat
                seriesDataBrowser = this.dataSeries
                
                this.chartOptions.xAxis.categories = categoriesBrowser;
                this.chartOptions.series = seriesDataBrowser
            }
        }
    },
    mounted() {
        if (this.log.chart_browser && Object.prototype.hasOwnProperty.call(this.log.chart_browser, "categories") && Object.keys(this.log.chart_browser).length > 0 && Object.keys(this.log.chart_browser.series).length > 0) {
            this.filterChart()
        }
    },
}
</script>