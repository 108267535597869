<template lang="pug">
.license-log
    .card.card--table.card--no-bg
        .card__header.card--table__header
            h2.text-xl List History License used
            .header-cta
                .block
                    router-link.btn-primary.m-btn-icon(
                        to="/license/create"
                    )
                        IconSet(type="add")
                        span.m-hide.ml-3 Add License
        .card--table__body
            .box-table
                table.table
                    thead
                        tr
                            th License Name
                            th Serial Number
                            th QTY
                            th Installed In
                            th Binded In
                            th Status
                            th Activated Date
                            th Expired Date
                    tbody
                        tr(v-for="item in KeyData" :key="item.id")
                            td
                                .text-sm {{ item.name }}
                            td
                                .text-xs.label-dark {{ item.serial}}
                            td.text-center {{ item.qty }}
                            td
                                .label-dark {{ item.installed }}
                            td
                                .label-dark {{ item.binded }}
                            td
                                .w-24
                                    .label-gray.uppercase expired
                            td
                                .text-sm {{ item.activated }}
                            td
                                .text-sm {{ item.expired }}
</template>

<script>
import IconSet from '@/components/IconSet.vue';

export default {
    name: 'PagelicenseLog',
    components: {
        IconSet
    },
    data(){
        return {
            nameKey: null,
            detailPublicKey: false,
            detailPrivateKey: false,
            KeyData: [
                {
                    name: 'Proprietary Basic Decoding & Collection System Licenses',
                    serial: 'KA01-KXTF-SRSI-EYKA-HXEZ-EGHJ-PELE-GHJ',
                    qty: 1,
                    installed: '56HDH73',
                    binded: '56HDH73',
                    activated: 'Permanent',
                    expired: 'Permanent'
                },
                {
                    name: 'Basic Historical Targeted Device Activities License',
                    serial: 'KA02-CMIT-MGWA-TGRG-USFO-APLD-TELA-PLD',
                    qty: 1,
                    installed: '56HDH73',
                    binded: '56HDH73',
                    activated: '-',
                    expired: '-'
                },
                {
                    name: 'Basic Location Tracking & Monitoring License',
                    serial: 'KA03-GTNS-XKFI-BMEU-JPJJ-JEFT-TELJ-EFT',
                    qty: 1,
                    installed: '56HDH73',
                    binded: '56HDH73',
                    activated: '-',
                    expired: '-'
                },
                {
                    name: 'Basic Mobile Device Historical Usage License',
                    serial: 'KA04-HXOX-AJPG-OJSX-NGER-JPOM-NELJ-POM',
                    qty: 1,
                    installed: '56HDH73',
                    binded: '56HDH73',
                    activated: '-',
                    expired: '-'
                },
                {
                    name: 'Proprietary Basic Analysis & Monitoring System Licenses',
                    serial: 'KA05-CFES-HMRG-DDEF-NIGK-VAUX-PELV-AUX',
                    qty: 1,
                    installed: '51JDH73',
                    binded: '51JDH73',
                    activated: 'Permanent',
                    expired: 'Permanent'
                },
                {
                    name: 'Proprietary Basic Matcher Engine Licenses',
                    serial: 'KA06-CEOY-JKFL-DRTO-AHJP-NYDV-HELN-YDV',
                    qty: 1,
                    installed: '10JDH73',
                    binded: '10JDH73',
                    activated: 'Permanent',
                    expired: 'Permanent'
                },
                {
                    name: 'Proprietary Basic Matcher Engine Licenses',
                    serial: 'KA07-BXOA-PHJB-PSKW-TNMO-TSGQ-NELT-SGQ',
                    qty: 1,
                    installed: 'FZHDH73',
                    binded: 'FZHDH73',
                    activated: 'Permanent',
                    expired: 'Permanent'
                },
                {
                    name: 'Proprietary Basic Matcher Engine Licenses',
                    serial: 'KA08-AIJT-JXKB-QTAT-AHJM-ADTN-PELA-DTN',
                    qty: 1,
                    installed: 'GZHDH73',
                    binded: 'GZHDH73',
                    activated: 'Permanent',
                    expired: 'Permanent'
                },
                {
                    name: 'Proprietary Basic Matcher Engine Licenses',
                    serial: 'KA09-AVNJ-QCLD-IJST-PQBO-BZSC-RELB-ZSC',
                    qty: 1,
                    installed: 'HZHDH73',
                    binded: 'HZHDH73',
                    activated: 'Permanent',
                    expired: 'Permanent'
                },
                {
                    name: 'Proprietary Basic Database Management System',
                    serial: 'KA10-GYLL-TRGE-WPOW-SHIA-SGLL-LELS-GLL',
                    qty: 1,
                    installed: 'GMNDH73',
                    binded: 'GMNDH73',
                    activated: 'Permanent',
                    expired: 'Permanent'
                },
                {
                    name: 'Basic Real-Life Data Analytics License',
                    serial: 'KA11-HRDT-FDXH-TPTR-INKE-YMMC-BELY-MMC',
                    qty: 1,
                    installed: 'GMNDH73',
                    binded: 'GMNDH73',
                    activated: '-',
                    expired: '-'
                },
                {
                    name: 'Unlimited Target Licenses',
                    serial: 'KA12-CSQE-QGLE-TUKB-CPGZ-KMZG-FELK-MZG',
                    qty: 1,
                    installed: 'GMNDH73',
                    binded: 'GMNDH73',
                    activated: '-',
                    expired: '-'
                },
                {
                    name: 'User Access Licenses',
                    serial: 'KA13-HGLW-VHZM-QXBE-SRJX-HHGR-LELH-HG01',
                    qty: 1,
                    installed: 'GMNDH73',
                    binded: 'GMNDH73',
                    activated: '-',
                    expired: '-'
                },
                {
                    name: 'User Access Licenses',
                    serial: 'KA13-HGLW-VHZM-QXBE-SRJX-HHGR-LELH-HG02',
                    qty: 1,
                    installed: 'GMNDH73',
                    binded: 'GMNDH73',
                    activated: '-',
                    expired: '-'
                },
                {
                    name: 'User Access Licenses',
                    serial: 'KA13-HGLW-VHZM-QXBE-SRJX-HHGR-LELH-HG03',
                    qty: 1,
                    installed: 'GMNDH73',
                    binded: 'GMNDH73',
                    activated: '-',
                    expired: '-'
                },
                {
                    name: 'User Access Licenses',
                    serial: 'KA13-HGLW-VHZM-QXBE-SRJX-HHGR-LELH-HG04',
                    qty: 1,
                    installed: 'GMNDH73',
                    binded: 'GMNDH73',
                    activated: '-',
                    expired: '-'
                },
                {
                    name: 'User Access Licenses',
                    serial: 'KA13-HGLW-VHZM-QXBE-SRJX-HHGR-LELH-HG05',
                    qty: 1,
                    installed: 'GMNDH73',
                    binded: 'GMNDH73',
                    activated: '-',
                    expired: '-'
                },
                {
                    name: 'User Access Licenses',
                    serial: 'KA13-HGLW-VHZM-QXBE-SRJX-HHGR-LELH-HG06',
                    qty: 1,
                    installed: 'GMNDH73',
                    binded: 'GMNDH73',
                    activated: '-',
                    expired: '-'
                },
                {
                    name: 'User Access Licenses',
                    serial: 'KA13-HGLW-VHZM-QXBE-SRJX-HHGR-LELH-HG07',
                    qty: 1,
                    installed: 'GMNDH73',
                    binded: 'GMNDH73',
                    activated: '-',
                    expired: '-'
                },
                {
                    name: 'User Access Licenses',
                    serial: 'KA13-HGLW-VHZM-QXBE-SRJX-HHGR-LELH-HG08',
                    qty: 1,
                    installed: 'GMNDH73',
                    binded: 'GMNDH73',
                    activated: '-',
                    expired: '-'
                },
                {
                    name: 'User Access Licenses',
                    serial: 'KA13-HGLW-VHZM-QXBE-SRJX-HHGR-LELH-HG09',
                    qty: 1,
                    installed: 'GMNDH73',
                    binded: 'GMNDH73',
                    activated: '-',
                    expired: '-'
                },
                {
                    name: 'User Access Licenses',
                    serial: 'KA13-HGLW-VHZM-QXBE-SRJX-HHGR-LELH-HG10',
                    qty: 1,
                    installed: 'GMNDH73',
                    binded: 'GMNDH73',
                    activated: '-',
                    expired: '-'
                },
                {
                    name: 'User Data Encryptions License',
                    serial: 'KA14-NWID-MBEH-KHZT-JADK-OHSC-RELO-HSC',
                    qty: 1,
                    installed: 'GMNDH73',
                    binded: 'GMNDH73',
                    activated: '-',
                    expired: '-'
                },
                {
                    name: 'Proprietary Basic Location Mapper System Licenses',
                    serial: 'KA14-DZFE-GYQB-MCSY-RWMF-ITPF-PELI-TPF',
                    qty: 1,
                    installed: 'C0JDH73',
                    binded: 'C0JDH73',
                    activated: 'Permanent',
                    expired: 'Permanent'
                },
                {
                    name: 'Basic Real-Time Geolocation Tracking License',
                    serial: 'KA15-EYES-BPXH-OAKC-TLDH-LZPO-FELL-ZPO',
                    qty: 1,
                    installed: 'C0JDH73',
                    binded: 'C0JDH73',
                    activated: '-',
                    expired: '-'
                },
                {
                    name: 'Basic Multi-Target Geolocation Tracking License',
                    serial: 'KA16-LKKY-UAHJ-PRWP-PKMS-CMDK-VELC-MDK',
                    qty: 1,
                    installed: 'C0JDH73',
                    binded: 'C0JDH73',
                    activated: '-',
                    expired: '-'
                },
                
            ]
        }
    },
    computed: {
        route() {
            return this.$route;
        },
        // id() {
        //     return this.$route.params.id;
        // },
        breadcrumb() {
            let breadcrumb = [
                {
                    name: 'License',
                    url: '/license',
                },
            ];

            return breadcrumb;
        },
    },
    methods: {
        openPublicKey(open = true, name = null) {
            if(open) {
                this.detailPublicKey = true;
                EventEmit.$emit('overflowHidden', true);
                this.nameKey = name
            } else {
                this.detailPublicKey = false;
                EventEmit.$emit('overflowHidden', false);
                this.nameKey = null 
            }
        },

        openPrivateKey(open = true, name = null) {
            if(open) {
                this.detailPrivateKey = true;
                EventEmit.$emit('overflowHidden', true);
                this.nameKey = name
            } else {
                this.detailPrivateKey = false;
                EventEmit.$emit('overflowHidden', false);
                this.nameKey = null 
            }
        },
    }
}
</script>