<template lang="pug">
.card
    .card__header
        h2.text-xl Notes
    .card__body
        .flex.mb-2
            .field.flex-grow.mr-4
                textarea.field-style(
                    v-model="tmpNote"
                    placeholder="Write new note here..."
                    style="min-height:60px"
                )
            //- .flex.justify-end
        .flex.mb-8
            .field.flex-grow.mr-4
                .flex.items-center.justify-end
                    template(v-if="tmpNote && tmpNote.length>0")
                        button.btn-primary.btn.small(@click="addNote") Add note
                    template(v-else)
                        button.btn-primary.btn.small.btn-disabled(disabled) Add note
        .mb-4
            .small-heading History Notes
            template(v-if="status_notes_list.status == 'loading'")
                .flex.flex-wrap.items-center.justify-center
                    Spinner(show="true" size="48")
            .mb-4.pb-4.border-b(v-else-if="notes_list && notes_list.length > 0" v-for="note in notes_list")
                p.mb-1 {{note.notes}}
                small.text-xs.opacity-75 {{note.created_at?datetimeFormat(note.created_at, 'DD MMMM YYYY HH:mm:ss [WIB]'):'-'}} • added by {{note.created_by?note.created_by:'-'}}
            .flex.flex-wrap.items-center.justify-center(v-else)
                strong.block.text-xs.uppercase.mb-4 No History Available
        .mb-4                            
            template(v-if="status_notes_list.status != 'loading' && (notes_list && notes_list.length > 0)")
                small(v-if="!is_loading_next") {{notes_list.length}} of {{data_pagination.total_data}} items
                .flex.flex-wrap.items-center.justify-center
                    template(v-if="is_loading_next")
                        Spinner(show="true" size="48")
                    template(v-else)
                        template(v-if="data_pagination.current_page<data_pagination.total_page")
                            button.btn-small.btn-primary(@click="handleNextNotes") Load More  
            //- small.text-xs.opacity-75 2 of 2 items
</template>

<script>
import { mapState } from 'vuex';
import Spinner from '@/components/Spinner.vue';
import Loading from '@/components/Loading.vue';
import moment from 'moment';

export default {
    name: 'TargetInformationNotes',
    components: {
        Spinner,
        Loading,
    },
    data: function() {   
        return {
            openNote: false,
            tmpNote: null,

            // dataNotes: [],
            // pageNotes: 0,
            // totalPageNotes: 1,
            // limitNotes: 10,
            // totalNotes: 0,
            // isLoadingNext: false,
        };
    },
    computed: {
        ...mapState('target', [
            'detail',
            'notes_list',
            'status_notes_list',
            'is_loading_next',
            'data_pagination',
        ]),
        route_name() {
            return this.$route.name;
        },
        id() {
            return this.$route.params.id;
        },
    },
    methods: {
        getNotes() {
            this.$store.commit('target/setNotesList', {});
            this.$store.dispatch('target/getNotesList', [this.id]);
        },
        handleNextNotes() {
            this.$store.commit('target/setIsLoadingNext', true);        
            this.$store.dispatch('target/getNotesList', [this.id, true]);
        },
        addNote(){
            EventEmit.$emit('showLoading', true);
            this.$store.dispatch('target/createNotes', [this.id, this.tmpNote])
                .then((resp) => {
                    if (resp.result === 'success') {
                        // this.getNotes(false)
                        this.tmpNote = null
                        this.setDefaulDataNotes()

                        this.$store.dispatch('target/getNotesList', [this.id]);
                        // this.getNotes(true, this.id);

                        this.$swal.fire({
                            icon: 'success',
                            title: 'Note successfully added!',
                            timer: 3000,
                        });
                    } else {
                        if (resp.data.message) {
                            this.error(resp.data.message);
                        } else {
                            this.error();
                        }
                    }
                });
        },
        setEmptyNotes(){
            this.id = null
            this.tmpNote = null
            this.openNote = false
        },
        setDefaulDataNotes(){
        //     // console.log('masuk set null')
        //     this.dataNotes = [];
        //     this.totalNotes = 0;
        //     this.limitNotes = 10;
        //     this.pageNotes=0;
        //     this.totalPageNotes=1;
            const dataPagination = { 
                current_page: 1,
                has_next: false,
                has_previous: false,
                next_page_number: 1,
                per_page: 10,
                previous_page_number: null,
                total_data: 0,
                total_page: 1,
            }
            this.$store.commit('target/setDataPagination', dataPagination);        

        },
        datetimeFormat(datetime, format="DD MMM YYYY HH:mm:ss") {
            return moment(datetime, 'YYYY-MM-DDTHH:mm:ssZ').format(format);
        },
    },
    watch: {
        // route_name(newData, oldData) {
        //     console.log('newData')
        //     console.log(newData)
        //     console.log('oldData')
        //     console.log(oldData)
        //     if (newData === 'pageTargetDetailHome') {
        //         if (oldData !== 'pageTargetDetailHome') {
        //             this.getNotes();
        //         }
        //     }
        // },
        // detail(newData){
        //     console.log('newData detail')
        //     console.log(newData)
        //     if (newData && Object.keys(newData).length > 0) {
        //         console.log('Masuuuk')
        //         this.getNotes()
        //     }
        // },
        // notes_list(newData){
        //     // console.log('newData')
        //     // console.log(newData)
        //     if (newData && Object.keys(newData).length > 0) {
        //         // console.log('masuk')
        //         this.dataNotes = this.dataNotes.concat(newData.data);
        //         this.totalNotes = parseInt(newData.total_data);
        //         this.limitNotes = parseInt(newData.per_page);
        //         this.pageNotes = parseInt(newData.current_page);
        //         this.totalPageNotes = parseInt(newData.total_page);
        //     } else {
        //         this.setDefaulDataNotes()
        //     }
        //     this.isLoadingNext=false
        // },
    },
    mounted() {
        if (this.route_name === 'pageTargetDetailHome') {
            this.getNotes();
        }
    },
}
</script>