import axios from "@/axios-main";
import axiosOrigin from "axios";
import { env } from '@/config/env';
// import { generateRandomString } from '@/util/crypto';

let cancelActivityLocation, cancelAppUsage;
var cancelToken = axiosOrigin.CancelToken;

const overall = {
  namespaced: true,
  state: {
    // request_id: null,
    default_range: env.VUE_APP_OVERALL_RANGE?env.VUE_APP_OVERALL_RANGE:13,

    record_size: null,
    app_size: null,
    from_date: null,
    to_date: null,

    status: false,
    // stop: false,
    // status_recent_location: false,
    status_activity_location: {
      status: false,
      cancel: null,
      code: null
    },
    status_app_usage: {
      status: false,
      cancel: null,
      code: null
    },

    activity_location: {},
    app_usage: {},
  },
  mutations: {
    // setRequestId: (state, id) => {
    //   state.request_id = id;
    // },

    setRecordSize: (state, data) => {
      state.record_size = data;
    },
    setAppSize: (state, data) => {
      state.app_size = data;
    },
    setFromDate: (state, data) => {
      state.from_date = data;
    },
    setToDate: (state, data) => {
      state.to_date = data;
    },

    setStatus: (state, status) => {
      state.status = status;
    },
    // setStop: (state, status) => {
    //   state.stop = status;
    // },
    setStatusActivityLocation: (state, status) => {
      state.status_activity_location = status;
    },
    setStatusAppUsage: (state, status) => {
      state.status_app_usage = status;
    },

    setActivityLocation: (state, data) => {
      state.activity_location = data;
    },
    setAppUsage: (state, data) => {
      state.app_usage = data;
    },
  },
  getters: {},
  actions: {
    async getResultData({ state, rootGetters, commit, dispatch }, [key, type, service_source=null]) {

      // let request_id = await generateRandomString(50);
      // await commit('setRequestId', request_id);
      // if (request_id !== state.request_id)
      //   return;

      const default_date = rootGetters['config/getDefaultDate'](state.default_range);

      let record_size = state.record_size!==null ? state.record_size : rootGetters['config/getDefaultRecordSize'];
      let from_date = state.from_date!==null ? state.from_date : default_date.from_date;
      let to_date = state.to_date!==null ? state.to_date : default_date.to_date;

      await dispatch('setLoading');

      // if (state.stop === true) {
      //   dispatch('setEmpty');
      //   commit('setStop', false);
      //   return;
      // }
      
      // await dispatch('getActivityLocation', [key, type, record_size, from_date, to_date]);

      // if (request_id !== state.request_id)
      //   return;

      await dispatch('getAppUsage', [key, type, record_size, from_date, to_date, service_source]);

      commit('setStatus', true);
    },

    async setLoading({ commit, state }) {
      await commit('setStatus', 'loading');

      var loading = function(sub_mod) {
        return {
          status: 'loading',
          cancel: state[sub_mod].cancel,
          code: null
        };
      };

      // await commit('setStatusActivityLocation', loading('status_activity_location'));
      await commit('setStatusAppUsage', loading('status_app_usage'));
    },

    async setEmpty({ state, commit }) {
      await commit('setStatus', false);

      let default_status = {
        status: false,
        cancel: null,
        code: null
      };

      // if (state.status_activity_location.cancel !== null && state.status_activity_location.status === 'loading')
      //   await state.status_activity_location.cancel('Operation canceled by the user.');
      // commit('setStatusActivityLocation', default_status);

      if (state.status_app_usage.cancel !== null && state.status_app_usage.status === 'loading')
        await state.status_app_usage.cancel('Operation canceled by the user.');
      commit('setStatusAppUsage', default_status);

      // commit('setActivityLocation', {});
      commit('setAppUsage', {});
    },

    async getActivityLocation({ commit, dispatch, state }, [key, type, record_size, from_date, to_date]) {

      if (state.status_activity_location.cancel !== null && state.status_activity_location.status === 'loading')
        await state.status_activity_location.cancel('Operation canceled by the user.');

      let params = new FormData();
      params.append('key', key);
      params.append('type', type);
      params.append('size', record_size);
      params.append('from_date', from_date);
      params.append('to_date', to_date);

      await axios.post('api/v1/service/overall/history-location', params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelActivityLocation = c;
          commit('setStatusActivityLocation', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        // let data = resp.data;
        let data = {
          data_location: {},
          heatmap: [],
          recent_location: {},
        };
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        data.data_location = resp.data.data_location.data_location;
        data.heatmap = resp.data.data_location.heatmap;
        data.recent_location = resp.data.recent_location;

        commit('setActivityLocation', data);
        // console.log(data);
        // return;

        if (Object.keys(data.data_location).length===0 && Object.keys(data.heatmap).length===0 && (data.recent_location.features[0].geometry.coordinates && (data.recent_location.features[0].geometry.coordinates[0]==="" || data.recent_location.features[0].geometry.coordinates[1]===""))) {

          result.status = 'empty';
          
        } else if (Object.keys(data.data_location).length===0 || Object.keys(data.heatmap).length===0 || (data.recent_location.features[0].geometry.coordinates && (data.recent_location.features[0].geometry.coordinates[0]==="" || data.recent_location.features[0].geometry.coordinates[1]===""))) {

          result.status = 'partially-empty';
        }

        commit('setStatusActivityLocation', result);
      })
      .catch((resp) => {
        // console.log(resp)
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelActivityLocation;
        }

        commit('setStatusActivityLocation', result);
        if (!axiosOrigin.isCancel(resp))
        dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
      });
    },

    async getAppUsage({ commit, dispatch, state }, [key, type, record_size, from_date, to_date, system_type, service_source]) {

      if (state.status_app_usage.cancel !== null && state.status_app_usage.status === 'loading')
        await state.status_app_usage.cancel('Operation canceled by the user.');

      let service = 'service';
      if (service_source === 'internal') {
        service = 'service-int'
      } else if (service_source === 'external') {
        service = 'service-ext'
      }

      let params = new FormData();
      params.append('key', key);
      params.append('type', type);
      params.append('size', record_size);
      params.append('from_date', from_date);
      params.append('to_date', to_date);
      // params.append('system_type', system_type);

      await axios.post('api/v1/'+service+'/overall/app-usage', params, {
      // await axios.post('/v2/app-usage-log/log', params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelAppUsage = c;
          commit('setStatusAppUsage', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setAppUsage', data);

        if (Object.keys(data.app_usage).length===0 && Object.keys(data.data_usage_app.categories).length===0 && Object.keys(data.data_usage_cat.categories).length===0) {

          result.status = 'empty';

        } else if (Object.keys(data.app_usage).length===0 || Object.keys(data.data_usage_app.categories).length===0 || Object.keys(data.data_usage_cat.categories).length===0) {

          result.status = 'partially-empty';
        }

        commit('setStatusAppUsage', result);
      })
      .catch((resp) => {
        // console.log(resp)
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelAppUsage;
        }

        commit('setStatusAppUsage', result);
        if (!axiosOrigin.isCancel(resp)){
          // dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null),state.module], { root: true });
          let respMsg = resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null
          if(respMsg!=null && result.code != 500) {
            dispatch('errors/handleApiResponse', [result.code, respMsg, 'Overall'], { root: true });
          }
        } 
      });
    },
  },
};

export default overall;
