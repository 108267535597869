import { render, staticRenderFns } from "./License.vue?vue&type=template&id=57b1dec7&scoped=true&lang=pug"
import script from "./License.vue?vue&type=script&lang=js"
export * from "./License.vue?vue&type=script&lang=js"
import style0 from "./License.vue?vue&type=style&index=0&id=57b1dec7&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57b1dec7",
  null
  
)

export default component.exports