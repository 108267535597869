<template lang="pug">
.result-page__head.pt-4.flex.items-center.justify-between
    .flex.items-center
        .mr-8
            figure.w-20.h-20.rounded-full.overflow-hidden
                img(:src="status_detail.status !== 'loading' && detail.photos && detail.photos.length ? detail.photos[0].file : (detail.photo ? detail.photo : defaultImageProfile)" @error="getDefaultImageProfile")
        .block.mr-8
            .flex.items-center.mb-2
                .mr-2
                    .w-2.h-2.rounded-full(:style="'background-color: '+ detail.label +';'")
                strong(v-if="status_detail.status == 'loading'") loading...
                strong(v-else) {{detail.target_name}}
            .field.w-64
                .field-style(v-if="status_detail.status == 'loading'")
                    .field-style--loading
                        Spinner(show="true" size="20")
                .relative(v-else)
                    select.field-style(v-model="selectedClues" @change="changeClues()")
                        //- all clues
                        //- option(v-for="data in detail.clues") {{ data.value }}
                        
                        //- only MSISDN
                        option(v-for="data in detail.clues" v-if="data.name === 'msisdn'" :value="data.value") {{ data.value }}
                        option(v-else-if="!detail.clues.some(e => e.name === 'msisdn')" :value="selectedClues") -
                    .field-icon
                        IconSet(
                            type="chevron-down"
                        )
        .block
            .flex.items-center.mb-2
                strong Device Name
            .field.w-64(v-if="status_subheader.status=='loading'")
                .field-style
                    .field-style--loading
                        Spinner(show="true" size="20")
            .field(v-else)
                .field-style
                    strong {{ subheader && subheader.phone?subheader.phone:'-' }}

    .block
        router-link.btn-primary-o(
            v-if="checkFeatureByAcl('single_target', 'edit')"
            :to="'/target/'+id+'/edit'"
            type='submit'
        ) Edit Information
        //- button.btn-primary-o(type='submit') Edit Information

</template>


<script>
import IconSet from '@/components/IconSet.vue'
import { mapState } from 'vuex';
import { encrypt, decrypt } from '@/util/crypto';
import Spinner from '@/components/Spinner.vue';

export default {
    name: 'ResultHeadProfile',
    components: {
        IconSet,
        Spinner
    },
    data() {
        return {
            showHead : false,
            selectedClues: null,
            defaultImageProfile: this.$store.getters['config/getDefaultImageProfile'],
        }
    },
    // props: ['route_name','isKey'],
    computed: {
        ...mapState('target', [
            'detail',
            'status_detail',
        ]),
        ...mapState('subheader', [
            'subheader',
            'status_subheader',
        ]),
        route(){
            return this.$route;
        },
        id() {
            return this.$route.params.id;
        },
    },
    methods: {
        getDefaultImageProfile(e) {
            e.target.src = this.defaultImageProfile;
        },
        decryptData() {
            let data = null;

            if (this.$route.params.key) {
                let decrypt_data = decrypt(this.$route.params.key);
                decrypt_data = decrypt_data.split('&&');

                var key = decrypt_data[0];
                var type = decrypt_data[1];

                data = { key: key, type: type };
            }
            return data;
        },
        changeClues(){
            var msisdn = this.selectedClues    
            this.$router.push({
                name: 'pageTargetDetailHome',
                params: { 
                    id: this.id,
                    key: encrypt(msisdn + '&&msisdn') 
                }
            })
        },
        getData(){
            this.$store.commit('target/setStatusDetail', {
                status: 'loading',
                cancel: null,
                code: null
            })
            this.$store.dispatch('target/getDetail', [this.id])
        }
    },
    watch: {
        detail(newData){
            if (newData.target_name === undefined) {
                this.getData()
            }
        }
    },
    mounted() {
        if (this.route.matched[0].name == "pageTargetDetail") { 
            
            if (this.detail.target_name === undefined) {
                this.getData()
            }

            var decrypt_data = this.decryptData();
            this.selectedClues = decrypt_data.key 
        }
    }
}
</script>

<style lang="sass" scoped>
    .rollout
        @apply .mr-10 .mb-0

    @media screen and (max-width: 480px)
        .result-page__head
            padding: 1rem
        .m-head-menu
            display: none
            padding: 1rem 0rem
            &.is-show
                display: block
                
</style>