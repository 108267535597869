<template lang="pug">
.box__body
    .card.card--table.card--no-bg
        .card__header.card--table__header
            h2.text-xl Add New Tracking
            .header-cta
                .block
                    router-link.btn-default.m-btn-icon(to="/multi-tracking")
                        IconSet(type="chevron-left")
                        span.m-hide.block.ml-3 Back
        .card__body
            .container-form
                .group-fields.border-b
                    .field
                        .field__label
                            label.block.text-sm.font-bold Target Group <sup>*</sup>
                        .field__form
                            input.field-style(type="text" placeholder="Write name here" v-model="model.group_name")
                            .py-3
                                .message.text-xs.text-red-500(v-if="$v.model.group_name.$error") This group name is required &amp; max length is 255 char.
                                .message.text-xs.text-red-500(v-if="error_group_name !== ''") {{ error_group_name }}

                    .field
                        .field__label
                            label.block.text-sm.font-bold Case Name <sup>*</sup>
                        .field__form
                            
                            .relative(v-if="status_case_list.status === 'loading'")
                                .field-style
                                    .field-style--mirroring.field-style--loading
                                        Spinner(show="true" size="32")

                            .field-multiple-select
                                multiselect(
                                    v-if="case_management_list.data && case_management_list.data.length > 0"
                                    :value="completeValue",
                                    :options="case_management_list.data ? case_management_list.data : []",
                                    :multiple="true",
                                    placeholder="Select Case",
                                    label="operation_name",
                                    track-by="id",
                                    @input="onChange"
                                )
                            .py-3
                                .message.text-xs.text-red-500(v-if="$v.model.case_id.$error") This operation name is required.
                                .message.text-xs.text-red-500(v-if="error_case_id !== ''") {{ error_case_id }}

                .group-fields.border-b
                    .field
                        .field__label
                            label.block.text-sm.font-bold Select Target
                        .field__form
                            //- jika operation name belum dipilih
                            p.pt-2(v-if="model.case_id.length<1") Please select the operation name first to find target.
                            //- menampilkan pilihan target
                            .pt-3(v-else)
                                .small-heading Select target
                                .fields-box
                                    .fields-box__item
                                        .relative.w-full
                                            input.field-style.w-full(
                                                :disabled="model.case_id.length > 0?false:'disabled'"
                                                type="text"
                                                :placeholder="model.case_id.length > 0 ?'Search target here (write min 3 characters)' : 'Please select Operation Name first'" v-model="target_keyword" 
                                                @keyup="searchTarget()"
                                            )
                                            .field-icon
                                                IconSet(
                                                    type="search"
                                                )
                                    .fields-box__item.flex.items-center.justify-center.w-full(
                                        v-if="status_target_list.status === 'loading' && next_page === false"
                                    )
                                        Spinner(show="true" size="48")
                                    .fields-box__item(v-else-if="target_list_data.length > 0")
                                        .target-case
                                            .target-item(
                                                v-for="(target,index) in target_list_data"
                                                :key="target.id"
                                                @click="addTarget(target)"
                                            )
                                                .mr-4
                                                    .w-3.h-3.rounded-full(:style="'background-color: '+ (target.label ? target.label:'white') +';'")
                                                .mr-4
                                                    figure.w-8.h-8.rounded-full.overflow-hidden
                                                        img(:src="target.photo ? target.photo : getDefaultImage" @error="getDefaultImage")
                                                span {{ target.target_name }}
                                        
                                            .flex.items-center.justify-center.w-full(v-if="total_page === page")
                                                | No more data avaliable.
                                            .flex.items-center.justify-center.w-full(v-else)
                                                template(v-if="status_target_list.status === 'loading' && next_page === true")
                                                    Spinner(show="true" size="32")
                                                button.btn-default.btn-small(v-else type="button" @click="searchTarget(true)") 
                                                    | Click to load more Target
                                    .fields-box__item.flex.items-center.justify-center.w-full(v-else-if="target_list.data && target_list.data.length == 0")
                                        | No data available.

                .group-fields.border-b
                    .field
                        .field__label
                            label.block.text-sm.font-bold Target Choosen <sup>*</sup>
                        .field__form
                            //- jika operation name belum dipilih
                            p.pt-2(v-if="target_choosen.length < 1") Please select the operation name and select target.
                            //- menampilkan pilihan target
                            .pt-3(v-else)
                                .small-heading Selected target
                                .fields-box
                                    .fields-box__item(
                                        v-for="(target, index) in target_choosen"
                                        :keys="index"
                                    )
                                        .w-full
                                            .flex.justify-between.w-full
                                                .flex-grow.flex.items-center
                                                    .mr-4
                                                        .w-3.h-3.rounded-full(:style="'background-color: '+ (target.label ? target.label:'white') +';'")
                                                    .mr-4
                                                        figure.w-8.h-8.rounded-full.overflow-hidden
                                                            img(:src="target.photo ? target.photo : getDefaultImage" @error="getDefaultImage")
                                                    span(v-if="target.id.length > 0") {{ target.target_name }}
                                                    input.field-style(
                                                        v-else
                                                        type="text"
                                                        placeholder="Target Name, Ex: 'Harry Parker'"
                                                        :value="target.target_name"
                                                        @keyup="updateCreateTarget($event, index, true)"
                                                    )
                                                button.btn-icon-40(@click="removeTarget(index)")
                                                    IconSet(
                                                        type="delete"
                                                    )

                                            .mt-4
                                                template(v-if="target.id.length > 0")
                                                    .ui-checkbox.ui-checkbox--small.mr-8(
                                                        v-for="(target_msisdn, index) in target.msisdn"
                                                        :key="index"
                                                    )
                                                        input(
                                                            type="checkbox"
                                                            v-model="target_checked" 
                                                            :value="target.id + '_' + target_msisdn" @change="checkTargetMsisdn($event, target.id, target_msisdn)"
                                                        )
                                                        .style-checkbox
                                                            .checkbox
                                                                IconSet(type="check")
                                                            span {{ target_msisdn }}

                                                .flex.items-center.justify-between.mb-1(v-else)
                                                    input.field-style(type="number" placeholder="MSISDN 3, Ex: '081234567890'" :value="target_choosen[index].msisdn[0]" @keyup="updateCreateTarget($event, index, null, true, 0)" min="0")
                                                    input.field-style.ml-2(type="number" placeholder="MSISDN 3, Ex: '081234567890'" :value="target_choosen[index].msisdn[1]" @keyup="updateCreateTarget($event, index, null, true, 1)" min="0")
                                                    input.field-style.ml-2(type="number" placeholder="MSISDN 3, Ex: '081234567890'" :value="target_choosen[index].msisdn[2]" @keyup="updateCreateTarget($event, index, null, true, 2)" min="0")

                            .py-3
                                .message.text-xs.text-red-500(v-if="$v.target_choosen.$error") This target is required.
                                .message.text-xs.text-red-500(v-if="error_target !== ''") {{ error_target }}

                .group-fields.border-b
                    .field
                        .field__label
                            label.block.text-sm.font-bold Additional Information
                        .field__form
                            .flex(class="lg:flex-row flex-col")
                                .flex-grow(class="lg:mr-3 lg:mb-0 mr-0 mb-3")
                                    input.field-style(
                                        id="info-name-tmp"
                                        type="text"
                                        placeholder="Write a Key"
                                        name="info-name-tmp"
                                        v-model="infoNameTmp"
                                        v-on:keyup.enter="addInfo()"
                                    )
                                .flex-grow(class="lg:mr-3 lg:mb-0 mr-0 mb-3")
                                    input.field-style(
                                        id="info-val-name"
                                        type="text"
                                        placeholder="Write a Key's value"
                                        v-model="infoValTmp"
                                        v-on:keyup.enter="addInfo()"
                                    )
                                .flex.justify-end
                                    button.btn-icon-40(
                                        type='submit'
                                        @click="addInfo()"
                                    )
                                        IconSet(type="add")
                            .py-3
                                .message.text-xs.text-red-500(v-if="$v.model.additional_info.$error") This additional info is required.
                                .message.text-xs.text-red-500(v-if="error_additional_info !== ''") {{ error_additional_info }}

                            .pt-4(v-if="model.additional_info.length > 0")
                                .small-heading ADDED ADDITIONAL INFO
                                .fields-box
                                    .fields-box__item(
                                        v-for="(info, index) in model.additional_info"
                                        :key="index"
                                    )
                                        .flex.flex-grow(class="lg:flex-row flex-col")
                                            .flex-grow.mr-3
                                                input.field-style(
                                                    v-model="info.name"
                                                    :key="index"
                                                    type="text"
                                                    placeholder="Write a Key"
                                                )
                                            .flex-grow.mr-3.field-options(class="lg:pt-0 pt-4")
                                                input.field-style(
                                                    v-model="info.value"
                                                    :key="index"
                                                    type="text"
                                                    placeholder="Write a Key's value"
                                                )
                                        button.btn-icon-40(
                                            @click="deleteInfo(index)"
                                        )
                                            IconSet(
                                                type="delete"
                                            )

                .group-fields.border-b
                    .field
                        .field__label
                            label.block.text-sm.font-bold Description
                        .field__form
                            textarea.field-style(
                                v-model="model.description" 
                                placeholder="Write Description here..."
                            )
                            .py-3
                                .message.text-xs.text-red-500(v-if="$v.model.description.$error") This description is required.
                                .message.text-xs.text-red-500(v-if="error_description !== ''") {{ error_description }}



                .flex.items-center.justify-end.mb-8
                    button.btn.btn-default(type="button" @click="reset()")
                        | Reset
                    button.btn-primary-o.ml-2(type="submit" @click="submit($event, true)")
                        | Save &amp; Continue
                    button.btn.btn-primary.ml-4(type="submit" @click="submit($event)")
                        | Save


</template>

<script>
import IconSet from '@/components/IconSet.vue'
import Multiselect from 'vue-multiselect'
import { mapState } from 'vuex';
import { encrypt } from '@/util/crypto'
import { env } from '@/config/env';
import { required, maxLength } from "vuelidate/lib/validators";
import Spinner from '@/components/Spinner.vue';

export default {
    name: 'pageMultiTrackingForm',
    components: {
        IconSet,
        Multiselect,
        Spinner,
    },
    data: function() {
        return {
            value: null,
            options: ['list', 'of', 'options'],
            model:{
                group_name: '',
                case_id: [],
                target: '',
                additional_info: [],
                description: '',
            },
            //tmp
            clueNameTmp: '',
            clueValTmp: '',
            infoNameTmp: '',
            infoValTmp: '',

            // error field
            error_group_name: '',
            error_case_id: '',
            error_target: '',
            error_additional_info: '',
            error_description: '',

            imgAvatar: this.$store.getters['config/getDefaultImageProfile'],

            // labelOpt: [
            //     {key: 'green'},
            //     {key: 'red'},
            //     {key: 'purple'},
            //     {key: 'orange'},
            //     {key: 'blue'},
            // ],

            target_checked: [],

            target_keyword: '',
            target_choosen: [],
            timeout: null,

            create_target_name: '',
            create_target_msisdn_1: '',
            create_target_msisdn_2: '',
            create_target_msisdn_3: '',

            // pagination for target from case management / operation name
            target_list_data: [],
            page: 1,
            total_page: null,
            next_page: false,
        }
    },
    validations: {
        model: {
            group_name: {
                required,
                maxLength: maxLength(255)
            },
            target: {
                required,
            },
            case_id: {
                required,
            },
            description: {
            },
            additional_info: {
            },
        },
        target_choosen:{
            required,
        }
    },
    computed: {
        ...mapState({
            multi_tracking_detail: (state) => state.multiTracking.detail,
            multi_tracking_redirect: (state) => state.multiTracking.redirect,
            
            status_case_list: (state) => state.caseManagement.status_list,
            case_management_list: (state) => state.caseManagement.list,
            
            status_target_list: (state) => state.target.status_list,
            target_list: (state) => state.target.list,
            max_msisdn: (state) => state.springbootConfig.max_msisdn,
        }),
        route_name() {
            return this.$route.name;
        },
        id() {
            return this.$route.params.id;
        },

        page_title() {
            let title = 'Edit Multi Tracking';
            if (this.route_name === 'pageMultiTrackingCreate')
                title = 'Add Multi Tracking';
            return title;
        },
        completeValue: {
            get() {
                if (!this.model.case_id) return null
                try {
                    return this.model.case_id.map(value => this.findOption(value.id)).filter(value => value.id)
                } catch (error) {
                    return null
                }
            },
            set(v) {
                this.model.case_id=v.map(value => {
                    let rObj = {}
                    rObj['id'] = value.id
                    return rObj
                })
            }
        },
    },
    watch: {
        route_name() {
            this.$store.commit('target/setStatusList', { cancel: null, status: false });
            this.getData();
        },
        id() {},
        breadcrumb() {},
        page_title() {},
        // form data
        multi_tracking_detail(newData) {
            // console.log(newData)
            this.model.group_name = (newData.group_name ? newData.group_name : '');
            this.model.case_id = (newData.cases && newData.cases ? newData.cases : []);

            if (newData.target && newData.target.length > 0) {
                this.target_choosen = newData.target;

                let target_checked = [];
                for (var i = 0; i < newData.target.length; i++) {
                    // console.log(newData.target[i]);
                    for (var j = 0; j < newData.target[i].msisdn.length; j++) {
                        // console.log(newData.target[i][j]);
                        target_checked.push(newData.target[i].id + '_' + newData.target[i].msisdn[j])
                    }
                }

                // console.log(target_checked);
                this.target_checked = target_checked;
            }

            this.model.additional_info = (newData.additional_info ? newData.additional_info : []);
            this.model.description = (newData.description ? newData.description : '');
            // console.log(newData.cases.length)
            if(newData.cases && newData.cases.length>0){
                // console.log('hulalalala')
                this.searchTarget();
            }
        },

        group_name() {},
        // clues() {},
        // status_case_list(newData) {
        //     if (newData.status === 'loading') {
        //         EventEmit.$emit('showLoading', true);
        //     } else {
        //         EventEmit.$emit('showLoading', false);
        //     }
        // },
        case_id() {},
        // status() {},
        additional_info() {},


        // ------------------------------------------------
        // get target list from case
        target_list(newData) {
            if (newData.data && newData.data.length > 0) {
                // console.log(newData.data.length)
                this.page = parseInt(newData.current_page);
                this.total_page = parseInt(newData.total_page);

                for (var i = 0; i < newData.data.length; i++) {
                    this.target_list_data.push(newData.data[i]);
                }
            }

        },
        target_keyword(newData) {
            if (newData.length < 3)
                this.$store.commit('target/setList', {});
        },
        target_choosen(newData) {
            // console.log('watch target_choosen');
            // console.log(newData);
            let target = newData;

            if (Object.keys(target).length > 0) {
                target = target.map(function(item) {
                    let target = {
                        id: item.id,
                        name: item.target_name,
                        label: item.label,
                        msisdn: item.msisdn,
                    };
                    // return item;
                    return target;
                });
                // console.log(target);
            }
            
            this.model.target = JSON.stringify(target);
            // this.model.target = target;
            // console.log(this.model.target);
        },
    },
    methods: {
        setEmptyModel(all=true) {
            for (let key in Object.keys(this.model)) {
                if (all === true)
                    if(["case_id",'additional_info'].indexOf(key) > -1)
                        this.model[Object.keys(this.model)[key]] = [];
                    else
                        this.model[Object.keys(this.model)[key]] = '';
            }
        },
        setErrors(empty=true, errors=null) {
            if (empty === true) {
                this.error_group_name = '';
                this.error_case_id = '';
                this.error_description = '';
                this.error_additional_info = '';
            } else if (empty !== true && errors !== null) {
                this.error_group_name = (errors.group_name ? errors.group_name : '');
                this.error_case_id = (errors.case_id ? errors.case_id : '');
                this.error_description = (errors.description ? errors.description : '');
                this.error_additional_info = (errors.additional_info ? errors.additional_info : '');
            }
        },
        setEmptyOthers(){
            this.target_keyword = '';
            this.target_choosen = [];
            this.$store.commit('target/setList', {});

            // pagination target list
            this.target_list_data = [];
            this.page = 1;
            this.total_page = null;
            this.next_page = false;

            // this.photoFile= '';
            this.clueNameTmp= '';
            this.clueValTmp= '';
            this.infoNameTmp= '';
            this.infoValTmp= '';
        },
        onChange(value) {
            if (this.completeValue.length > value.length) {           
                var delTarget = []
                for (var i = 0; i < this.target_choosen.length; i++) {
                    var isDelete = true
                    var cases = this.target_choosen[i].cases
                    if (typeof(cases) != 'undefined' && cases.length > 0) {                    
                        for (var j = 0; j < cases.length; j++) {
                            for (var k = 0; k < value.length; k++) {
                                if(cases[j].id == value[k].id)
                                    isDelete = false
                            }
                        }
                    } else {
                        isDelete = false            
                    }
                    if (isDelete) {
                        delTarget.push(i)
                        let target_remove = this.target_choosen[i];
                        for (var l = 0; l < target_remove.msisdn.length; l++) {
                            let index_checked = null;
                            let search_checked = this.target_checked.filter(function(item, i) {
                                if (item === target_remove.id + '_' + target_remove.msisdn[l]) {
                                    index_checked = i;
                                    return item;
                                }
                            });

                            if (search_checked.length > 0 && index_checked !== null) {
                                this.target_checked.splice(index_checked, 1);
                            }
                        }
                    }
                }
                for (var m = delTarget.length -1; m >= 0; m--)
                    this.target_choosen.splice(delTarget[m],1);
            }
            this.completeValue = value
            if (this.completeValue.length > 0) {
                this.searchTarget();
            } else {
                this.$store.commit('target/setList', {});
                this.target_list_data = [];
                this.page = 1;
                this.total_page = null;
                this.next_page = false;
            }
        },
        findOption(value) {
            const opt = this.case_management_list.data
            return opt.find(option => option['id'] === value)
        },
        getDefaultImage(e) {
            e.target.src = this.imgAvatar;
        },

        searchTarget(nextPage=false) {
                // clear timeout variable
                clearTimeout(this.timeout);

                var self = this;
                this.timeout = setTimeout(function () {
                        self.$store.commit('target/setList', {});
                        if (nextPage === false) {
                            self.target_list_data = [];
                            self.page = 1;
                            self.total_page = null;
                            self.next_page = false;
                        } else {
                            self.next_page = true;
                        }
                        // no_case_or_cases
                        var no_case_or_cases = []
                        for (var i = 0; i < Object.keys(self.model.case_id).length; i++) {
                            no_case_or_cases.push(self.model.case_id[i].id)
                        }
                        self.$store.dispatch('target/getList', [10, (nextPage === false ? self.page : (self.page + 1)), self.target_keyword, null, null, null, null, JSON.stringify(no_case_or_cases)]);
                    // }
                }, 650);
        },
        createTarget() {
            if (this.target_checked.length >= parseInt(this.max_msisdn)) {
                EventEmit.$emit('error', 'Max MSISDN allowed is '+ this.max_msisdn + '!');
                return;
            }
        

            if (env.VUE_APP_MULTI_TRACKING_TARGET_LIMIT && Number.isInteger(parseInt(env.VUE_APP_MULTI_TRACKING_TARGET_LIMIT))) {
                if (this.target_choosen.length >= parseInt(env.VUE_APP_MULTI_TRACKING_TARGET_LIMIT)) {
                    EventEmit.$emit('error', 'Max target allowed is '+ env.VUE_APP_MULTI_TRACKING_TARGET_LIMIT + '!');
                    return;
                }
            }

            if (this.create_target_name.length === 0 || (this.create_target_msisdn_1.length === 0 && this.create_target_msisdn_2.length === 0 && this.create_target_msisdn_3.length === 0)) {
                // EventEmit.$emit('error', "Target Name & MSISDN is required!");
                return;
            }

            let target = {
                id: '',
                target_name: this.create_target_name,
                // label: this.labelOpt[Math.floor(Math.random() * 5)].key,
                photo: '',
                // msisdn: [this.create_target_msisdn_1], // for map msisdn[]
                msisdn: [], // for map msisdn[]
                cases: [], // for map msisdn[]
            };

            if (this.create_target_msisdn_1 && this.create_target_msisdn_1.length > 0)
                target.msisdn.push(this.create_target_msisdn_1);

            if (this.create_target_msisdn_2 && this.create_target_msisdn_2.length > 0)
                target.msisdn.push(this.create_target_msisdn_2);

            if (this.create_target_msisdn_3 && this.create_target_msisdn_3.length > 0)
                target.msisdn.push(this.create_target_msisdn_3);

            this.target_choosen.push(target);

            this.target_keyword = '';
            this.create_target_name = '';
            this.create_target_msisdn_1 = '';
            this.create_target_msisdn_2 = '';
            this.create_target_msisdn_3 = '';
        },
        updateCreateTarget(e, index, name=false, msisdn=false, index_msisdn=null) {

            clearTimeout(this.timeout);

            var self = this;
            this.timeout = setTimeout(function () {
                // console.log(e.target.value)

                if (name === true) {
                    self.target_choosen[index].target_name = e.target.value;
                    // console.log(self.target_choosen[index])
                }

                if (msisdn === true && index_msisdn !== null) {

                    if (e.target.value === '') {
                        if (index_msisdn === 0 || index_msisdn === '0') {
                            if ((!self.target_choosen[index].msisdn[1] || self.target_choosen[index].msisdn[1] === '') && (!self.target_choosen[index].msisdn[2] || self.target_choosen[index].msisdn[2] === '')) {
                                e.target.value = self.target_choosen[index].msisdn[index_msisdn];
                                self.error("MSISDN is required for target "+ self.target_choosen[index].target_name +"!");
                                return;
                            }
                        } else if (index_msisdn === 1 || index_msisdn === '1') {
                            if ((!self.target_choosen[index].msisdn[0] || self.target_choosen[index].msisdn[0] === '') && (!self.target_choosen[index].msisdn[2] || self.target_choosen[index].msisdn[2] === '')) {
                                e.target.value = self.target_choosen[index].msisdn[index_msisdn];
                                self.error("MSISDN is required for target "+ self.target_choosen[index].target_name +"!");
                                return;
                            }
                        } else if (index_msisdn === 2 || index_msisdn === '2') {
                            if ((!self.target_choosen[index].msisdn[0] || self.target_choosen[index].msisdn[0] === '') && (!self.target_choosen[index].msisdn[1] || self.target_choosen[index].msisdn[1] === '')) {
                                e.target.value = self.target_choosen[index].msisdn[index_msisdn];
                                self.error("MSISDN is required for target "+ self.target_choosen[index].target_name +"!");
                                return;
                            }
                        }
                    }

                    self.target_choosen[index].msisdn[index_msisdn] = e.target.value;
                    // console.log(self.target_choosen[index])
                }

                // self.target_choosen = target_choosen;

            }, 10);
        },
        addTarget(target) {
            if (this.max_msisdn && Number.isInteger(parseInt(this.max_msisdn))) {
                let search_msisdn = target.clues;
                search_msisdn = search_msisdn.filter(function(item) {
                    if (item.name === 'msisdn')
                        return item;
                });
                let addMSISDN = search_msisdn.length
                if ((this.target_checked.length + addMSISDN) > parseInt(this.max_msisdn)) {
                    EventEmit.$emit('error', 'Max MSISDN allowed is '+ this.max_msisdn + '!');
                    return;
                }
            }

            if (env.VUE_APP_MULTI_TRACKING_TARGET_LIMIT && Number.isInteger(parseInt(env.VUE_APP_MULTI_TRACKING_TARGET_LIMIT))) {
                if (this.target_choosen.length >= parseInt(env.VUE_APP_MULTI_TRACKING_TARGET_LIMIT)) {
                    EventEmit.$emit('error', 'Max target allowed is '+ env.VUE_APP_MULTI_TRACKING_TARGET_LIMIT + '!');
                    return;
                }
            }

            target = {
                id: target.id,
                target_name: target.target_name,
                label: target.label,
                photo: target.photo,
                msisdn: target.clues, // for map msisdn[]
                cases: target.cases,
            };
            // console.log(target);

            if (target.msisdn.length > 0) {
                // console.log(target.msisdn);

                let search_msisdn = target.msisdn;
                search_msisdn = search_msisdn.filter(function(item) {
                    if (item.name === 'msisdn')
                        return item;
                });
                search_msisdn = Object.keys(search_msisdn).map((key) => search_msisdn[key].value);

                if (search_msisdn.length > 0) {

                    target.msisdn = search_msisdn;

                    if (this.target_choosen.length === 0) {
                        // add checked new msisdn target
                        for (var i = 0; i < target.msisdn.length; i++) {
                            this.target_checked.push(target.id + '_' + target.msisdn[i])
                        }

                        this.target_choosen.push(target);

                    } else {
                        let index_data;
                        let search_target =  this.target_choosen.filter(function(item, index) {
                            if (item.id == target.id) {
                                index_data = index;
                                return item;
                            }
                        });

                        if (search_target.length > 0) {

                            // add checkbox if any data msisdn not saved in target
                            for (var j = 0; j < target.msisdn.length; j++) {

                                let index_checked = null;
                                let search_checked = this.target_checked.filter(function(item, index) {
                                    if (item === target.id + '_' + target.msisdn[j]) {
                                        index_checked = index;
                                        return item;
                                    }
                                });

                                if (search_checked.length > 0 && index_checked !== null) {
                                    this.target_checked[index_checked] = target.id + '_' + target.msisdn[j];
                                }
                            }

                            this.target_choosen[index_data] = target;

                            this.$swal.fire({
                                // icon: 'warning',
                                // title: 'Target '+ target.target_name +' already exist!',
                                icon: 'success',
                                title: 'Target '+ target.target_name +' updated!',
                            });
                        } else {
                            // add checked new msisdn target
                            for (var k = 0; k < target.msisdn.length; k++) {
                                this.target_checked.push(target.id + '_' + target.msisdn[k])
                            }

                            this.target_choosen.push(target);
                        }
                    }
                } else {
                    // EventEmit.$emit('error, "Target don't have MSISDN!");
                }
            } else {
                // EventEmit.$emit('error, "Target don't have MSISDN!");
            }

            this.target_keyword = '';
            if (document.getElementById('find-target-input'))
                document.getElementById('find-target-input').focus();
        },
        removeTarget(index) {
            for (var i = 0; i < this.target_choosen.length; i++) {
                if (index === i) {

                    // remove target checked if target removed
                    let target_remove = this.target_choosen[index];
                    for (var j = 0; j < target_remove.msisdn.length; j++) {
                        let index_checked = null;
                        let search_checked = this.target_checked.filter(function(item, index) {
                            if (item === target_remove.id + '_' + target_remove.msisdn[j]) {
                                index_checked = index;
                                return item;
                            }
                        });

                        if (search_checked.length > 0 && index_checked !== null) {
                            this.target_checked.splice(index_checked, 1);
                        }
                    }

                    this.target_choosen.splice(index, 1);
                }
            }
        },
        checkTargetMsisdn(e, target_id, msisdn) {
            if (this.target_checked.length >= parseInt(this.max_msisdn)) {
                EventEmit.$emit('error', 'Max MSISDN allowed is '+ this.max_msisdn + '!');
                let index_checked = null;
                let search_checked = this.target_checked.filter(function(item, i) {
                    if (item === target_id + '_' + msisdn) {
                        index_checked = i;
                        return item;
                    }
                });

                if (search_checked.length > 0 && index_checked !== null) {
                    this.target_checked.splice(index_checked, 1);
                    e.target.checked = false;
                }
                return;
            }
                    
            let check_target = this.target_checked;
            check_target = check_target.filter(function(item) {
                let split_item = item.split('_');
                if (split_item[0] === target_id)
                    return item;
            });

            if (check_target.length === 0) {
                this.target_checked.push(target_id + '_' + msisdn);
                e.target.checked = true;

                this.$swal.fire({
                    icon: 'warning',
                    title: 'Target need min 1 MSISDN!',
                    // allowOutsideClick: false,
                });
                // return;
            }
        },

        getData() {
            // console.log('=================== getData() route on roles form')
            this.reset(true); 
            this.setEmptyOthers(); 

            
            // get client list
            if (this.route_name === 'pageMultiTrackingEdit' || this.route_name === 'pageMultiTrackingCreate') {
                this.$store.dispatch('springbootConfig/getMaxMSISDN');
                this.$store.dispatch('caseManagement/getList', [100, 1, null, 'active']);
            }

            if (this.route_name === 'pageMultiTrackingEdit') {
                // for dispatch get data
                EventEmit.$emit('showLoading', true);
                
                this.$store.dispatch('multiTracking/getDetail', [this.id])
                .then((resp) => {
                    if (resp.result === 'success') {
                        EventEmit.$emit('showLoading', false);
                    } else {
                        var code = resp.code || null 
                        var message = null;

                        let statusCodeCheck = ["403", 403]
                        if (statusCodeCheck.includes(code))
                            message = resp && resp.data && resp.data.message? resp.data.message : null;
                        EventEmit.$emit('error', [message]);
                    }
                });

            } else {
                if (this.route_name === 'pageMultiTrackingCreate')
                    this.$store.commit('multiTracking/setDetail', {});
            }
        },
        changeImgAvatar(e) {
            const file = e.target.files[0];
            this.photoFile = file
            this.photo = URL.createObjectURL(file);
        },
        reset(direct=false) {
            if (direct === true) {
                this.setEmptyModel(false);
                // reset vuelidate
                this.$nextTick(() => { this.$v.$reset(); });
                this.setErrors(true);
                window.scrollTo(0,0);
            } else {
                this.$swal.fire({
                    icon: 'warning',
                    title: 'Are you sure you want to reset form?',
                    // text: id,
                    confirmButtonText: 'Yes!',
                    confirmButtonColor: '#DD6B55',
                    showCancelButton: true,
                    allowOutsideClick: false,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        if (this.route_name === 'pageMultiTrackingCreate') {
                            this.setEmptyModel(false);
                            // reset vuelidate
                            this.$nextTick(() => { this.$v.$reset(); });
                            this.setErrors(true);
                            window.scrollTo(0,0);
                        } else {
                            this.getData();
                        }
                    }
                });
            }
        },
        submit(e, save_continue=false){
            e.preventDefault();
            this.$v.$touch();
                        if (this.$v.model.$invalid) {
                for (let key in Object.keys(this.$v.model)) {
                    const input = Object.keys(this.$v.model)[key];
                    if (input.includes("$")) return false;
                    if (this.$v.model[input].$error) {
                        break;
                    }
                }
                window.scrollTo(0,0);
                return;
            }
            EventEmit.$emit('showLoading', true);

            let target = JSON.parse(this.model.target);
            let target_choosen = this.target_choosen;
            let update_target_choosen = [];

            for (var i = 0; i < target_choosen.length; i++) {

                update_target_choosen[i] = target_choosen[i];

                if (target_choosen[i].id.length >  0) {
                    let target_choosen_id = target_choosen[i].id;
                    let target_checked = this.target_checked;
                    target_checked = target_checked.filter(function(item) {
                        let split_item_msisdn = item.split('_');
                        if (split_item_msisdn[0] === target_choosen_id)
                            return item;
                    });

                    if (target_checked.length > 0)
                        target_checked = Object.keys(target_checked).map((key) => target_checked[key].split('_')[1]);

                    target[i].msisdn = target_checked
                } else {
                    target[i].name = target_choosen[i].target_name;

                    let msisdn = [];
                    for (var j = 0; j < target_choosen[i].msisdn.length; j++) {
                        if (target_choosen[i].msisdn[j] && target_choosen[i].msisdn[j].length > 0)
                            msisdn.push(target_choosen[i].msisdn[j]);
                    }

                    target[i].msisdn = msisdn;
                    update_target_choosen[i].msisdn = msisdn;
                }
            }

            this.model.target = JSON.stringify(target);
            this.target_choosen = update_target_choosen;

            if (this.route_name === 'pageMultiTrackingCreate') {

                this.$store.dispatch('multiTracking/create', [this.model.group_name, [JSON.stringify(this.model.case_id)], this.model.target, [JSON.stringify(this.model.additional_info)], this.model.description])//this.status,
                .then((resp) => {
                    if (resp.result === 'success') {
                        if (save_continue === false) {
                            this.$router.push('/multi-tracking');
                        } else {
                            this.$router.push('/multi-tracking/'+ resp.data.data.id +'/edit');
                        }

                        this.$swal.fire({
                            icon: 'success',
                            title: 'Data successfully created!',
                            timer: 3000,
                        });
                    } else {
                        
                        if (resp.data.message && resp.data.message.errors) {
                            this.error_group_name = (resp.data.message.errors.group_name ? resp.data.message.errors.group_name : '');
                            this.error_case_id = (resp.data.message.errors.cases ? resp.data.message.errors.cases : '');
                            this.error_target = (resp.data.message.errors.target ? resp.data.message.errors.target : '');
                            this.error_additional_info = (resp.data.message.errors.additional_info ? resp.data.message.errors.additional_info : '');
                            this.error_description = (resp.data.message.errors.description ? resp.data.message.errors.description : '');

                            // EventEmit.$emit(null, 'resp.data.message.errors);
                        } else {
                            var code = resp.code || null 
                            var message = null;

                            let statusCodeCheck = ["403", 403]
                            if (statusCodeCheck.includes(code))
                                message = resp && resp.data && resp.data.message? resp.data.message : null;
                            EventEmit.$emit('error', [message]);
                        }
                    }
                });

            // pageMultiTrackingEdit
            } else {

                this.$store.dispatch('multiTracking/update', [this.id, this.model.group_name, [JSON.stringify(this.model.case_id)], this.model.target, [JSON.stringify(this.model.additional_info)], this.model.description])// this.status,
                .then((resp) => {
                    if (resp.result === 'success') {
                        if (save_continue === false)
                            this.$router.push('/multi-tracking');                            

                        this.$swal.fire({
                            icon: 'success',
                            title: 'Data successfully updated!',
                            timer: 3000,
                        });
                    } else {
                        if (resp.data.message && resp.data.message.errors) {
                            this.error_group_name = (resp.data.message.errors.group_name ? resp.data.message.errors.group_name : '');
                            this.error_case_id = (resp.data.message.errors.cases ? resp.data.message.errors.cases : '');
                            this.error_target = (resp.data.message.errors.target ? resp.data.message.errors.target : '');
                            this.error_additional_info = (resp.data.message.errors.additional_info ? resp.data.message.errors.additional_info : '');
                            this.error_description = (resp.data.message.errors.description ? resp.data.message.errors.description : '');

                            // EventEmit.$emit('error, null, resp.data.message.errors);
                        } else {
                            var code = resp.code || null 
                            var message = null;

                            let statusCodeCheck = ["403", 403, "430", 430]
                            if (statusCodeCheck.includes(code))
                                message = resp && resp.data && resp.data.message? resp.data.message : null;
                            EventEmit.$emit('error', [message]);
                        }
                    }
                });
            }
        },

        selectLabel(key) {
            this.label = key
        },

        addClue() {
            if (this.clueValTmp.length==0 || this.clueNameTmp.length==0) {
                this.$swal.fire({
                    icon: 'warning',
                    title: 'Data is required!',
                    text: 'Please fill in completely!',
                });

                return;
            }
            else {            
                this.clues.push({
                    name: this.clueNameTmp,
                    value: this.clueValTmp
                })
                this.clueNameTmp = ''
                this.clueValTmp = ''
            }
        },
        deleteClue(index) {
            this.clues.splice(index, 1);
        },
        addInfo() {
            if (this.infoValTmp.length==0 || this.infoNameTmp.length==0) {
                this.$swal.fire({
                    icon: 'warning',
                    title: 'Data is required!',
                    text: 'Please fill in completely!',
                });

                return;
            }
            else {            
                this.model.additional_info.push({
                    name: this.infoNameTmp,
                    value: this.infoValTmp
                })
                this.infoNameTmp = ''
                this.infoValTmp = ''
            }
        },
        deleteInfo(index) {
            this.model.additional_info.splice(index, 1);
        }
    },
    mounted() {
        this.getData();
    },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="sass" scoped>
    .fields-box
        @apply .rounded
        background-color: $color-secondary-600
        &__item
            @apply .p-4 .rounded .flex .border-b
            &:last-child
                border-bottom: 0px
            .field-options
                input
                    margin-top: 1rem
                    &:first-child
                        margin-top: 0px
    .target-case
        @apply .w-full
        .target-item
            @apply .flex .items-center .p-3 .w-full .rounded .cursor-pointer
            transition: all 0.3s ease-in-out
            &:hover
                background-color: $color-secondary-800

</style>
