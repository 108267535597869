<template lang="pug">
.page-tethered-device
    ActionSearchResult(
        v-if="status === false && $route.matched[0].name !== 'pageTargetDetail'"
        :title="title"
    )

    template(v-else)
        //- title
        TitleResult(:title="title")
        
        //- filter
        FilterResult

        //- Tethered Device
        .card
            .card__header
                h2.text-xl List Devices Tethered History
            .card__body
                template(v-if="status_tethered_device.status === 'loading'")
                    template(v-if="route_name === 'TargetDetailTetheredDevice'")
                        Loading(show="true")
                    template(v-else)
                        LoadingAnimation(show="true" title="tethered-device")

                template(v-else)
                    .grid.gap-4(
                        class="grid-cols-1 lg:grid-cols-3"
                        v-if="tethered_device && Object.keys(tethered_device).length > 0"
                    )
                        .tethered-item(v-for="data in tethered_device" :key="data.tethered_device")
                            .flex.items-center.mb-4
                                figure.w-12.h-12.rounded.bg-gray-100.mr-3.overflow-hidden
                                    img.objcet-cover.w-full.h-full(:src="defaultImage")
                                .block.flex-grow
                                    span.block {{ data.tethered_device_name }}
                                    small {{ data.tethered_device_vendor }}
                                .block
                                    button.btn-icon(v-tooltip="data.tethered_device_tags.length > 0 ? data.tethered_device_tags.join(', ') : '---'")
                                        IconSet(type="info")
                            .block(v-for="period in data.periods.slice(0, 1).reverse()")
                                .font-bold {{ period.start.datetime }} - {{ period.end.datetime }}
                                button.text-xs.more-date(
                                    v-if="data.periods.length > 1"
                                    type="button"
                                    @click="handleHistoryTimestamp(data.tethered_device_name, data.tethered_device_vendor, data.periods)"
                                ) See more periods
                                    //- v-on:click="seeMore = !seeMore"

                    NoDataAvailable(v-else)

            .popup-layer(
                v-bind:class="{'is-show' : seeMore}"
            )
                .popup-container
                    .popup-container__header
                        h2.text-xl Tethered Detail
                        button.btn-icon-40(
                            v-on:click="seeMore = !seeMore"
                        )
                            IconSet(type="close")

                    .popup-container__body
                        .flex.items-center.mb-4
                            figure.w-12.h-12.rounded.bg-gray-100.mr-3.overflow-hidden
                                img.objcet-cover.w-full.h-full(:src="defaultImage")
                            .block.flex-grow
                                span.block {{ tmpDevice }}
                                small {{ tmpVendor }}

                        template(v-for="period in tmpPeriods")
                            .mb-4 {{ period.start.datetime }} - {{ period.end.datetime }}

                    .popup-container__footer

</template>

<script>
import TitleResult from '@/components/result/Title.vue';
import ActionSearchResult from '@/components/result/ActionSearchResult.vue'
import FilterResult from '@/components/result/Filter.vue';
import IconSet from '@/components/IconSet.vue'
import { mapState } from 'vuex';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import moment from 'moment';
import { decrypt } from '@/util/crypto';
import Loading from '@/components/Loading.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';

export default {
    name: 'ResultTetheredDevice',
    components: {
        TitleResult,
        ActionSearchResult,
        FilterResult,
        IconSet,
        DateRangePicker,
        Loading,
        NoDataAvailable,
    },
    data(){
        return {
            title:"Tethered Device",
            showFilter: false,

            dateRange: {
                startDate: '',
                endDate: '',
            },
            default_ranges: {
                'Today': [moment().toDate(), moment().toDate()],
                'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                'Last 7 days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                'Last week': [moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(), moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate()],
            },

            // -------------------------
            seeMore: false,
            tmpDevice: null,
            tmpVendor: null,
            tmpPeriods: [],
            defaultImage: '/images/no-image-device.jpg'
        };
    },
    computed: {
        ...mapState('tetheredDevice', [
            'status',
            'from_date',
            'to_date',
            'status_tethered_device',
            'tethered_device',
        ]),
        route() {
            return this.$route;
        },
        route_name() {
            return this.$route.name;
        },
    },
    watch: {
        from_date(newData, oldData) {
            if (newData !== oldData)
                this.getFilter();
        },
        to_date(newData, oldData) {
            if (newData !== oldData)
                this.getFilter();
        },
    },
    methods: {
        getFilter() {
            let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
            const default_date = this.$store.getters['config/getDefaultDate'](def);
            this.dateRange.startDate = this.from_date ?? default_date.from_date;
            this.dateRange.endDate = this.to_date ?? default_date.to_date;
        },
        decryptData() {
            let data = null;

            if (this.$route.params.key) {
                let decrypt_data = decrypt(this.$route.params.key);
                decrypt_data = decrypt_data.split('&&');
                // console.log(decrypt_data);

                var key = decrypt_data[0];
                var type = decrypt_data[1];

                data = { key: key, type: type };
            }
            return data;
        },
        filter() {
            const decrypt_data = this.decryptData();
            if (decrypt_data !== null)
                this.$store.dispatch('tetheredDevice/getResultData', [decrypt_data.key, decrypt_data.type]);
        },
        reset() {
            this.$swal.fire({
                icon: 'warning',
                title: 'Are you sure you want to reset?',
                // text: 'Role : ' + name,
                confirmButtonText: 'Yes!',
                confirmButtonColor: '#DD6B55',
                showCancelButton: true,
                allowOutsideClick: false,
            })
            .then((result) => {
                if (result.isConfirmed) {

                    let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
                    const default_date = this.$store.getters['config/getDefaultDate'](def);

                    this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
                    this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();

                    this.$store.commit('tetheredDevice/setFromDate', default_date.from_date);
                    this.$store.commit('tetheredDevice/setToDate', default_date.to_date);

                    this.filter();
                }
            });
        },
        defaultFilter() {

            let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
            const default_date = this.$store.getters['config/getDefaultDate'](def);

            this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
            this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();
            // this.dateRange.startDate = moment().subtract(6, 'days').toDate()
            // this.dateRange.endDate = moment().toDate();

            // this.from_date_input = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            // this.to_date_input = moment(this.dateRange.endDate).format('YYYY-MM-DD');

            this.$store.commit('tetheredDevice/setFromDate', default_date.from_date);
            this.$store.commit('tetheredDevice/setToDate', default_date.to_date);
        },
        /* eslint-disable no-unused-vars */
        dateFormat (classes, date) {
            if (!classes.disabled) {
                classes.disabled = moment(date.getTime()).subtract({ hours: 12})._d > new Date();
            }
            return classes;
        },
        async pickerUpdate(e) {
            // let ranges = moment(e.endDate.getTime()).diff(moment(e.startDate.getTime()), 'days') + 1;

            // this.from_date_input = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            // this.to_date_input = moment(this.dateRange.endDate).format('YYYY-MM-DD');

            this.$store.commit('tetheredDevice/setFromDate', moment(this.dateRange.startDate).format('YYYY-MM-DD'));
            this.$store.commit('tetheredDevice/setToDate', moment(this.dateRange.endDate).format('YYYY-MM-DD'));

        },

        handleHistoryTimestamp(device, vendor, periods) {
            this.tmpDevice = device;
            this.tmpVendor = vendor;
            this.tmpPeriods = periods;
            this.seeMore = true;
        },
    },
    filters: {
      date: function (date) {
        return moment(date).format('YYYY-MM-DD');
      }
    },
    created() {
        // 
    },
    mounted() {
        this.getFilter();
    },
}
</script>

<style lang="sass" scoped>
    .tethered-item
        @apply .block .rounded .p-4
        border: 1px solid lighten($color-secondary-500, 5% )
        .more-date
            color: $color-main-500
            &:hover
                text-decoration: underline
</style>
