<template lang="pug">
  .card-content
      .text-orange-400.mb-4.border-b.pb-4.border-dashed
          p Possible Location of the Home ({{ infoWeekend.begin }} to {{ infoWeekend.end }}) at Weekend &amp; ({{ infoWeekday.begin }} to {{ infoWeekday.end }}) at Weekday

      template(v-if="status_possible_location.status === 'loading'")
        template(v-if="route_name === 'TargetDetailLocationMovement' || route_name === 'TargetDetailLocationLog'")
          Loading(show="true")
        template(v-else)
          LoadingAnimation(show="true" title="possible-location")
      template(v-else)
          .grid.mb-8(
              class="grid-cols-1 gap-0 lg:grid-cols-3 lg:gap-4"
          )
              .rollout
                  span.text-xs Counter
                  p.text-light {{ possible_location.possible_home && possible_location.possible_home.counter ? possible_location.possible_home.counter : '-' }}
              .rollout
                  span.text-xs BTS
                  p.text-light {{ possible_location.possible_home && possible_location.possible_home.bts ? possible_location.possible_home.bts : '-' }}
              .rollout
                  span.text-xs Region
                  p.text-light {{ possible_location.possible_home && possible_location.possible_home.region ? possible_location.possible_home.region : '-' }}
              .rollout
                  span.text-xs Operator
                  p.text-light {{ possible_location.possible_home && possible_location.possible_home.operator ? possible_location.possible_home.operator : '-' }}
              .rollout
                  span.text-xs Country
                  p.text-light {{ possible_location.possible_home && possible_location.possible_home.country ? possible_location.possible_home.country : '-' }}
              .rollout
                  span.text-xs Coordinate
                  p.text-light {{ possible_location.possible_home && possible_location.possible_home.geoloc_simple ? possible_location.possible_home.geoloc_simple.join(', ') : '-' }}
              .rollout
                  span.text-xs Signal
                  p.text-light {{ possible_location.possible_home && possible_location.possible_home.signal ? possible_location.possible_home.signal : '-' }}
              .rollout
                  span.text-xs Province
                  p.text-light {{ possible_location.possible_home && possible_location.possible_home.province ? possible_location.possible_home.province : '-' }}

              .rollout
                  span.text-xs Date Time
                  p.text-light {{ possible_location.possible_home && possible_location.possible_home.datetime ? possible_location.possible_home.datetime : '-' }}

          //- BoxMap
          .box-map
              .block(class="w-full lg:w-12/12" style="height: 450px;" v-if="possible_location.possible_home && possible_location.possible_home.geoloc_simple && mapLoaded")

                  l-map(v-if="mapVendor === 'osm'" :id="idOsm" :zoom="10" :center="center" :options="options" style="height: 100%; width: 100%;")
                      l-tile-layer(:url="url" :attribution="attribution")
                      l-marker(v-for="(location, index) in locations" :lat-lng="location.position" v-bind:key="index")
                          l-popup(:content="location.name")

                  gmap-map(v-else ref="mapRef" :center="center" :zoom="10" :options="options" style="width:100%; height: 100%;")

              NoDataAvailable(v-else)

</template>

<script>
// import BoxMap from '@/components/Map.vue'
import { mapState } from 'vuex';
import { env } from '@/config/env';
import Loading from '@/components/Loading.vue';
import LoadingAnimation from '@/components/LoadingAnimation.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';
import { gmapApi } from 'vue2-google-maps';

// open street map
import {LMap, LTileLayer, LMarker, LPopup} from 'vue2-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { GestureHandling } from "leaflet-gesture-handling";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
// import LeafletHeatmap from '@/components/LeafletHeatmap.vue';
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.imagePath = '';
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export default {
    name: 'PossibleLocationHome',
    components: {
        // BoxMap
        Loading,
        NoDataAvailable,
        LoadingAnimation,

        // open street map
        LMap,
        // GestureHandling,
        LTileLayer,
        LMarker,
        LPopup,
    },
    data() {
        return {
            mapVendor: this.$store.getters['config/getMapVendor'],
            mapLoaded: false,
            infoWindows: [],
            options: {
                gestureHandling: 'cooperative',
                styles: [
                    {elementType: 'geometry', stylers: [{color: '#242f3e'}]},
                    {elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}]},
                    {elementType: 'labels.text.fill', stylers: [{color: '#746855'}]},
                    {
                      featureType: 'administrative.locality',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#d59563'}]
                    },
                    {
                      featureType: 'poi',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#d59563'}]
                    },
                    {
                      featureType: 'poi.park',
                      elementType: 'geometry',
                      stylers: [{color: '#263c3f'}]
                    },
                    {
                      featureType: 'poi.park',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#6b9a76'}]
                    },
                    {
                      featureType: 'road',
                      elementType: 'geometry',
                      stylers: [{color: '#38414e'}]
                    },
                    {
                      featureType: 'road',
                      elementType: 'geometry.stroke',
                      stylers: [{color: '#212a37'}]
                    },
                    {
                      featureType: 'road',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#9ca5b3'}]
                    },
                    {
                      featureType: 'road.highway',
                      elementType: 'geometry',
                      stylers: [{color: '#746855'}]
                    },
                    {
                      featureType: 'road.highway',
                      elementType: 'geometry.stroke',
                      stylers: [{color: '#1f2835'}]
                    },
                    {
                      featureType: 'road.highway',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#f3d19c'}]
                    },
                    {
                      featureType: 'transit',
                      elementType: 'geometry',
                      stylers: [{color: '#2f3948'}]
                    },
                    {
                      featureType: 'transit.station',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#d59563'}]
                    },
                    {
                      featureType: 'water',
                      elementType: 'geometry',
                      stylers: [{color: '#17263c'}]
                    },
                    {
                      featureType: 'water',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#515c6d'}]
                    },
                    {
                      featureType: 'water',
                      elementType: 'labels.text.stroke',
                      stylers: [{color: '#17263c'}]
                    }
                ],
            },
            center: {},
            markers: [],
            places: [],

            // openstreetmap
            // url: 'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
            url: `${env.VUE_APP_OSM_CDN_URL}{z}/{x}/{y}.png`,
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            locations: [],
            idOsm: 'openstreetmap-possible_location_home',
            newLoc: '',
            newLt : 0, 
            newLng : 0,
            icon: L.icon({iconUrl: "null",}),

            infoWeekend:{
              begin:env.VUE_APP_WEEKEND_HOME_BEGIN?env.VUE_APP_WEEKEND_HOME_BEGIN:'10:00 PM',
              end:env.VUE_APP_WEEKEND_HOME_END?env.VUE_APP_WEEKEND_HOME_END:'06:00 AM'
            },

            infoWeekday:{
              begin:env.VUE_APP_WEEKDAY_HOME_BEGIN?env.VUE_APP_WEEKDAY_HOME_BEGIN:'10:00 PM',
              end:env.VUE_APP_WEEKDAY_HOME_END?env.VUE_APP_WEEKDAY_HOME_END:'04:00 AM'
            },
        };
    },
    computed: {
        ...mapState('locationLog', [
          'status_possible_location',
          'possible_location',
        ]),
        google: gmapApi,
        route_name() {
            return this.$route.name;
        },
    },
    watch: {
        status_possible_location(newData) {
            if (newData.status !== 'loading') {
              this.startMap();
            }
        },
        google() {},
    },
    methods: {
        hideAllInfoWindows() {
            for (var i=0; i < this.infoWindows.length; i++) {
                this.infoWindows[i].close();
            }
        },
        async openTab(){
            var tmpStatus = this.status_possible_location
            await this.$store.commit('locationLog/setStatusPossibleLocation', {
                status: 'loading',
                cancel: null,
                code: null
            });
            // if (this.status_possible_location.status == 'loading') {
            this.$store.commit('locationLog/setStatusPossibleLocation', tmpStatus);
            // }
        },
        startMap() {
            var self = this;

            // gmap
            self.infoWindows = [];
            self.markers = [];

            // osm
            self.locations = [];

            if (this.possible_location.possible_home && this.possible_location.possible_home.geoloc_simple && this.possible_location.possible_home.geoloc_simple.length > 0) {

                var lat = parseFloat(this.possible_location.possible_home.geoloc_simple[0]);

                var lng = parseFloat(this.possible_location.possible_home.geoloc_simple[1]);

                if (Number.isNaN(lat) || Number.isNaN(lng)) {
                    return;
                }

                this.center = {
                    lat: lat,
                    lng: lng,
                };

                this.mapLoaded = true;

                var contentString = '<div id="content">'+
                  '<div id="bodyContent">'+
                    '<table>'+
                      '<tr>'+
                        '<td class="pr-1">Link Location</td>'+
                        '<td class="pr-1">:</td>'+
                        '<td><a target="_blank" href="https://www.google.com/maps?q='+lat+','+lng+'" style="color: #43ea92;">'+'Google Maps</a> '+
                        '<td>'+
                      '</tr>'+
                    '</table>'+
                  '</div>'+
                '</div>';

                switch(self.mapVendor) {
                    case 'osm':
                        let centerTmp = [self.center['lat'], self.center['lng']];
                        self.locations.push({
                            name: contentString,
                            position: centerTmp
                        });
                        break;

                    default:
                        self.$nextTick(() => {
                            self.$refs.mapRef.$mapPromise.then((map) => {
                                const google = self.google;

                                map.center = new google.maps.LatLng(self.center);
                                map.zoom = 10;

                                let marker = new google.maps.Marker({
                                    position: new google.maps.LatLng(self.center),
                                    map: map,
                                    // title: name,
                                });
                                
                                // marker.setMap(map);

                                let infowindow = new google.maps.InfoWindow({
                                    content: contentString,
                                    maxWidth: 300,
                                });

                                self.infoWindows.push(infowindow);

                                google.maps.event.addListener(marker, 'click', function() {
                                    infowindow.open(map, marker);
                                });
                            });
                        });
                    }
            }
        },
    },
    mounted() {
        this.startMap();
    },
    created() {
        EventEmit.$on('startMapHome', () => {
            if (this.status_possible_location.status !== 'loading') {
                this.openTab()
            }
        });
    },
}
</script>

<style lang="sass">
    .box-map
        @apply .flex .items-center .justify-center .w-full
        min-height: 450px
        background-color: $color-secondary-800
</style>
