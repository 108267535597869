<template lang="pug">
//- filter
.box-filter
    //- showing data information mobile
    .m-only
        .filter-info
            .flex.items-center.justify-between.mb-3
                Strong.uppercase.text-xs.font-bold Filtered by
                button.btn-default-o.btn-small(
                    type='button'
                    v-on:click="showFilter = !showFilter"
                    ) Change Filter
            //- p 10/10/2020 - 17/10/2020
            p {{ dateRange.startDate }} -  {{ dateRange.endDate }}

    //- end showing data information mobile
    .mobile-filter(
        v-bind:class="{ 'is-show' : showFilter}"
    )
        .m-only
            .flex.items-center.justify-between.p-4.border-b
                h2.text-xl Filter
                button.btn-icon-40(
                    v-on:click="showFilter = !showFilter"
                )
                    IconSet(type="close")
        .items-filter.mb-2
            .field.mr-3
                .relative.mr-3.field-style
                    date-range-picker(
                        v-if="dateRange.startDate && dateRange.endDate" 
                        ref="picker" 
                        v-model="dateRange" 
                        @update="pickerUpdate($event)" 
                        :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }" 
                        :date-format="dateFormat" 
                        opens="right"
                        :ranges="default_ranges"
                        autoApply=true
                    )
                        div(
                            slot="input" 
                            slot-scope="picker") 
                                | {{ picker.startDate | date}} - {{ picker.endDate  | date}}

                    .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2
                        IconSet(type="calendar")
            button.btn-primary-o.mr-3(@click="toggleAdvanceFilter()") Advance Datetime Filter
            button.btn-primary.mr-3(
                v-if="isKey && moduleLoading === false"
                @click="filter()"
                v-on:click="showFilter = !showFilter"
            ) Filter
            button.btn-primary.mr-3.btn-primary--disabled(
                v-else
                disabled="disabled"
                style="cursor: not-allowed !important;"
            ) Filter

            button.btn-default(
                v-if="isKey && moduleLoading === false"
                @click="reset()"
                v-on:click="showFilter = !showFilter"
            ) Reset
            button.btn-default.btn-default--disabled(
                v-else
                disabled="disabled"
                style="cursor: not-allowed;"
            ) Reset

            template(v-if="retryShow")
                button.btn-primary.ml-3(
                    v-if="isKey && moduleLoading === false"
                    @click="filter()"
                    v-on:click="showFilter = !showFilter"
                ) Retry
                button.btn-primary.ml-3.btn-primary--disabled(
                    v-else
                    disabled="disabled"
                    style="cursor: not-allowed;"
                ) Retry
        //-===== summary filter
        .flex.flex-wrap.items-center.p-4.border.border-black.mb-2.gap-4.bg-black
            .flex.flex-wrap.gap-2.items-center
                p.text-sm Filter date
                template(v-if="Object.keys(selectedTimeByDate).length > 0")
                    span(v-for="(value, key) in selectedTimeByDate" :key="key").bg-gray-800.text-xs.p-1.block {{dateFormatGlob(key,'DD MMMM YYYY')}} {{toString(value,null)}}
                span(v-else).bg-gray-800.text-xs.p-1.block {{dateFormatGlob(from_date,'DD MMMM YYYY')}} - {{dateFormatGlob(to_date,'DD MMMM YYYY')}}
    //- end
    //-=== advance filter template ====-//
    .section-advance-filter(v-if="openAdvanceFilter")
        .section-advance-filter__header
            .flex-grow.flex.flex-col.gap-2
                .flex.items-center.gap-2
                    .flex.text-sm Showing Data from {{dateFormatGlob(from_date,'DD MMMM YYYY')}} - {{dateFormatGlob(to_date,'DD MMMM YYYY')}}
                    button.btn-primary-o.btn-small(@click="toggleTimeFilter('all')") Filter Time

                .text-xs Please select specific date time bellow:
                .flex.items-center.gap-2
                    p.text-xs Selected date
                    .flex.flex-wrap.gap-2.items-center.font-bold
                        template(v-if="Object.keys(selectedTimeByDate).length > 0")
                            span(v-for="(value, key) in selectedTimeByDate" :key="key").bg-gray-800.text-xs.p-1.block {{dateFormatGlob(key,'DD MMMM YYYY')}} {{toString(value,null)}}
                        span(v-else).bg-gray-800.text-xs.p-1.block {{dateFormatGlob(from_date,'DD MMMM YYYY')}} - {{dateFormatGlob(to_date,'DD MMMM YYYY')}}
                
            
            .flex.items-center.gap-2
                button.btn-default(@click="toggleAdvanceFilter('reset')") Reset
                button.btn-primary(@click="toggleAdvanceFilter()") Save Filter
        
        .section-advance-filter__body
            .box-month(v-for="month,idx in months" :key="idx")
                .block.mb-2 {{month.name_long}} {{month.year}}
                .grid.grid-cols-10.gap-2
                    button.btn-item(
                        v-for="i in month.days" :key="i" 
                        v-if="i>=month.start_day && i <= month.end_day"
                        @click="toggleTimeFilter(month.year+'-'+month.month+'-'+String(i).padStart(2, '0'))" 
                        :class="checkDateStyle(month.year+'-'+month.month+'-'+String(i).padStart(2, '0'))"
                    )
                        //- :class="{'btn-item--selected': btnSelectedMonth.includes(i)}" 
                        span {{i}} {{month.name_short}}
                        span.text-xs {{ getTimeDesc(month.year+'-'+month.month+'-'+String(i).padStart(2, '0')) }}
        //- select date time
        .modal-date-time(v-if="filterTime")
            .section-date-time
                .section-date-time__header
                    h2.text-white.text-xl {{ filterTimeBy == 'all'? (dateFormatGlob(from_date,'DD MMMM YYYY')+' - '+ dateFormatGlob(to_date,'DD MMMM YYYY')): dateFormatGlob(filterTimeBy,'DD MMMM YYYY')}}
                    button.btn-icon-40(@click="toggleTimeFilter()")
                        IconSet(type="close")
                .section-date-time__body
                    button.btn-item(
                        v-for="time in timeOpt"
                        @click="selectTime(time.value)"
                        :class="checkTimeStyle(time.value)"
                    ) {{time.label}}
                .section-date-time__footer
                    button.btn-primary-o(@click="selectTime('all')") Reset to 00:00 - 24:00
                    button.btn-primary(@click="toggleTimeFilter('apply')") Apply

</template>

<script>
import IconSet from '@/components/IconSet.vue'
import { mapState } from 'vuex';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import moment from 'moment';
import { decrypt } from '@/util/crypto';

export default {
    name: 'ResultFilter',
    components: {
        IconSet,
        DateRangePicker,
    },
    // props: ['isKey'],
    data(){
        return {
            showFilter: false,
            retryShow: false,

            moduleLoading: true,
            module: '',

            dateRange: {
                startDate: '',
                endDate: '',
            },
            default_ranges: {
                'Today': [moment().toDate(), moment().toDate()],
                'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                'Last 7 days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                'Last week': [moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(), moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate()],
            },
            // from_date: '',
            // to_date: '',
            openAdvanceFilter: false,
            filterTime: false,
            filterTimeBy: '',
            tmpSelectedTime:[],
            selectedTimeByDate:{},
            selectedTimeAll:[],
            months: [],
            btnSelectedMonth: [],
            timeOpt:[
                {
                    label:'00:00 - 01:00',
                    value:'00:00-01:00'
                },
                {
                    label:'01:00 - 02:00',
                    value:'01:00-02:00'
                },
                {
                    label:'02:00 - 03:00',
                    value:'02:00-03:00'
                },
                {
                    label:'03:00 - 04:00',
                    value:'03:00-04:00'
                },
                {
                    label:'04:00 - 05:00',
                    value:'04:00-05:00'
                },
                {
                    label:'05:00 - 06:00',
                    value:'05:00-06:00'
                },
                {
                    label:'06:00 - 07:00',
                    value:'06:00-07:00'
                },
                {
                    label:'07:00 - 08:00',
                    value:'07:00-08:00'
                },
                {
                    label:'08:00 - 09:00',
                    value:'08:00-09:00'
                },
                {
                    label:'09:00 - 10:00',
                    value:'09:00-10:00'
                },
                {
                    label:'10:00 - 11:00',
                    value:'10:00-11:00'
                },
                {
                    label:'11:00 - 12:00',
                    value:'11:00-12:00'
                },
                {
                    label:'12:00 - 13:00',
                    value:'12:00-13:00'
                },
                {
                    label:'13:00 - 14:00',
                    value:'13:00-14:00'
                },
                {
                    label:'14:00 - 15:00',
                    value:'14:00-15:00'
                },
                {
                    label:'15:00 - 16:00',
                    value:'15:00-16:00'
                },
                {
                    label:'16:00 - 17:00',
                    value:'16:00-17:00'
                },
                {
                    label:'17:00 - 18:00',
                    value:'17:00-18:00'
                },
                {
                    label:'18:00 - 19:00',
                    value:'18:00-19:00'
                },
                {
                    label:'19:00 - 20:00',
                    value:'19:00-20:00'
                },
                {
                    label:'20:00 - 21:00',
                    value:'20:00-21:00'
                },
                {
                    label:'21:00 - 22:00',
                    value:'21:00-22:00'
                },
                {
                    label:'22:00 - 23:00',
                    value:'22:00-23:00'
                },
                {
                    label:'23:00 - 24:00',
                    value:'23:00-23:59'
                },
            ],
        };
    },
    computed: {
        ...mapState({
            // from_date_subheader: (state) => state.subheader.from_date,
            // to_date_subheader: (state) => state.subheader.to_date,

            from_date_recent_location: (state) => state.recentLocation.from_date,
            to_date_recent_location: (state) => state.recentLocation.to_date,

            from_date_overall: (state) => state.overall.from_date,
            to_date_overall: (state) => state.overall.to_date,
            default_range_overall: (state) => state.overall.default_range,

            from_date_location_movement: (state) => state.locationMovement.from_date,
            to_date_location_movement: (state) => state.locationMovement.to_date,
            default_range_location_movement: (state) => state.locationMovement.default_range,

            from_date_location_log: (state) => state.locationLog.from_date,
            to_date_location_log: (state) => state.locationLog.to_date,
            default_range_location_log: (state) => state.locationLog.default_range,

            from_date_browsing_log: (state) => state.browsingLog.from_date,
            to_date_browsing_log: (state) => state.browsingLog.to_date,
            default_range_browsing_log: (state) => state.browsingLog.default_range,

            from_date_aerial_log: (state) => state.aerialLog.from_date,
            to_date_aerial_log: (state) => state.aerialLog.to_date,
            default_range_aerial_log: (state) => state.aerialLog.default_range,

            // from_date_profile: (state) => state.profile.from_date,
            // to_date_profile: (state) => state.profile.to_date,

            // from_date_device_log: (state) => state.deviceLog.from_date,
            // to_date_device_log: (state) => state.deviceLog.to_date,

            from_date_history_device_roles: (state) => state.historyDeviceRoles.from_date,
            to_date_history_device_roles: (state) => state.historyDeviceRoles.to_date,
            default_range_history_device_roles: (state) => state.historyDeviceRoles.default_range,

            from_date_device_history: (state) => state.deviceHistory.from_date,
            to_date_device_history: (state) => state.deviceHistory.to_date,
            default_range_device_history: (state) => state.deviceHistory.default_range,

            from_date_tethered_device: (state) => state.tetheredDevice.from_date,
            to_date_tethered_device: (state) => state.tetheredDevice.to_date,
            default_range_tethered_device: (state) => state.tetheredDevice.default_range,

            // --------------------------------------------------------------

            // status_subheader: (state) => state.subheader.status,
            status_recent_location: (state) => state.recentLocation.status,
            status_recent_location_recent_location: (state) => state.recentLocation.status_recent_location,

            status_overall: (state) => state.overall.status,
            status_overall_app_usage: (state) => state.overall.status_app_usage,

            status_location_movement: (state) => state.locationMovement.status,
            // status_location_movement_location_log: (state) => state.locationMovement.status_location_log,

            status_location_log: (state) => state.locationLog.status,
            status_location_log_location_log: (state) => state.locationLog.status_location_log,

            status_browsing_log: (state) => state.browsingLog.status,
            status_browsing_log_browsing_log: (state) => state.browsingLog.status_browsing_log,

            status_aerial_log: (state) => state.aerialLog.status,
            status_aerial_log_aerial_log: (state) => state.aerialLog.status_aerial_log,

            // status_profile: (state) => state.profile.status,
            // status_profile_profile_identity: (state) => state.profile.status_profile_identity,
            // status_profile_profile_family: (state) => state.profile.status_profile_family,

            // status_device_log: (state) => state.deviceLog.status,
            status_history_device_roles: (state) => state.historyDeviceRoles.status,

            status_device_history: (state) => state.deviceHistory.status,
            status_device_history_device_history: (state) => state.deviceHistory.status_device_history,

            status_tethered_device: (state) => state.tetheredDevice.status,
            status_tethered_device_tethered_device: (state) => state.tetheredDevice.status_tethered_device,

            from_date(state) {  
                return state[this.module].from_date
            },
            to_date(state) {  
                return state[this.module].to_date
            },

        }),
        route() {
            return this.$route;
        },
        route_name() {
            return this.$route.name;
        },
        isKey() {
            return this.$route.params.key ? true : false;
        },
    },
    watch: {
        async route_name(newData) {
            await this.setModule();
            this.dateChange();
            this.retryChange();
        },
        isKey() {},
        // ------------------------------------------------

        from_date_recent_location() {
            this.dateChange();
        },
        to_date_recent_location() {
            this.dateChange();
        },

        from_date_overall() {
            this.dateChange();
        },
        to_date_overall() {
            this.dateChange();
        },

        from_date_location_movement() {
            this.dateChange();
        },
        to_date_location_movement() {
            this.dateChange();
        },
        
        from_date_location_log() {
            this.dateChange();
        },
        to_date_location_log() {
            this.dateChange();
        },

        from_date_browsing_log() {
            this.dateChange();
        },
        to_date_browsing_log() {
            this.dateChange();
        },

        from_date_aerial_log() {
            this.dateChange();
        },
        to_date_aerial_log() {
            this.dateChange();
        },

        // from_date_profile() {
        //     this.dateChange();
        // },
        // to_date_profile() {
        //     this.dateChange();
        // },

        // from_date_device_log() {
        //     this.dateChange();
        // },
        // to_date_device_log() {
        //     this.dateChange();
        // },

        from_date_history_device_roles() {
            this.dateChange();
        },
        to_date_history_device_roles() {
            this.dateChange();
        },

        from_date_device_history() {
            this.dateChange();
        },
        to_date_device_history() {
            this.dateChange();
        },

        from_date_tethered_device() {
            this.dateChange();
        },
        to_date_tethered_device() {
            this.dateChange();
        },

        // --------------------------------------------------

        async status_recent_location(newData) {
            if (this.module === 'recentLocation')
                await this.setLoading(newData);
            this.retryChange();
        },
        async status_overall(newData) {
            if (this.module === 'overall')
                await this.setLoading(newData);
            this.retryChange();
        },
        async status_location_movement(newData) {
            if (this.module === 'locationMovement')
                await this.setLoading(newData);
            this.retryChange();
        },
        async status_location_log(newData) {
            if (this.module === 'locationLog')
                await this.setLoading(newData);
            this.retryChange();
        },
        async status_browsing_log(newData) {
            if (this.module === 'browsingLog')
                await this.setLoading(newData);
            this.retryChange();
        },
        async status_aerial_log(newData) {
            if (this.module === 'aerialLog')
                await this.setLoading(newData);
            this.retryChange();
        },
        // async status_profile(newData) {
        //     if (this.module === 'profile')
        //         await this.setLoading(newData);
        //     this.retryChange();
        // },
        // async status_device_log(newData) {
        //     if (this.module === 'deviceLog')
        //         await this.setLoading(newData);
        //     this.retryChange();
        // },
        async status_history_device_roles(newData) {
            if (this.module === 'historyDeviceRoles')
                await this.setLoading(newData);
            this.retryChange();
        },
        async status_device_history(newData) {
            if (this.module === 'deviceHistory')
                await this.setLoading(newData);
            this.retryChange();
        },
        async status_tethered_device(newData) {
            if (this.module === 'tetheredDevice')
                await this.setLoading(newData);
            this.retryChange();
        },
    },
    methods: {
        setModule() {
            let mod = '';

            switch(this.route_name) {
                case 'ResultRecentLocation':
                case 'ResultRealtimeRecentLocation':
                case 'TargetDetailRecentLocation':
                    mod = 'recentLocation';
                    break;

                case 'ResultOverall':
                case 'ResultHistoricalOverall':
                case 'TargetDetailOverall':
                    mod = 'overall';
                    break;

                case 'ResultLocationMovement':
                case 'ResultHistoricalLocationMovement':
                case 'TargetDetailLocationMovement':
                    mod = 'locationMovement';
                    break;

                case 'ResultLocationLog':
                case 'ResultHistoricalLocationLog':
                case 'TargetDetailLocationLog':
                    mod = 'locationLog';
                    break;

                case 'ResultBrowsingLog':
                case 'ResultHistoricalBrowsingLog':
                case 'TargetDetailBrowsingLog':
                    mod = 'browsingLog';
                    break;

                case 'ResultAerialLog':
                case 'ResultHistoricalAerialLog':
                case 'TargetDetailAerialLog':
                    mod = 'aerialLog';
                    break;

                // case 'ResultProfile':
                // case 'TargetDetailProfile':
                //     mod = 'profile';
                //     break;

                // case 'ResultDeviceLog':
                // case 'TargetDetailDeviceLog':
                //     mod = 'deviceLog';
                //     break;
                case 'ResultHistoryDeviceRoles':
                case 'ResultHistoricalHistoryDeviceRoles':
                case 'TargetDetailHistoryDeviceRoles':
                    mod = 'historyDeviceRoles';
                    break;

                case 'ResultDeviceHistory':
                case 'ResultHistoricalDeviceHistory':
                case 'TargetDetailDeviceHistory':
                    mod = 'deviceHistory';
                    break;

                case 'ResultTetheredDevice':
                case 'ResultHistoricalTetheredDevice':
                case 'TargetDetailTetheredDevice':
                    mod = 'tetheredDevice';
                    break;

                // case 'ResultPredictionName':
                // case 'ResultHistoricalPredictionName':
                // case 'TargetDetailPredictionName':
                //     mod = 'predictionName';
                //     break;
            }

            this.module = mod;

            const module_status = {
                recentLocation: 'status_recent_location',
                overall: 'status_overall',
                locationMovement: 'status_location_movement',
                locationLog: 'status_location_log',
                browsingLog: 'status_browsing_log',
                aerialLog: 'status_aerial_log',
                historyDeviceRoles: 'status_history_device_roles',
                deviceHistory: 'status_device_history',
                tetheredDevice: 'status_tethered_device',
            };

            if (this[module_status[mod]])
                this.setLoading(this[module_status[mod]]);
        },
        setLoading(is_loading) {
            if (is_loading === 'loading')
                is_loading = true;
            else
                is_loading = false;

            this.moduleLoading = is_loading;
        },
        dateChange() {
            // console.log(this.route_name)
            if (this.route_name == 'ResultRecentLocation' || this.route_name == 'ResultRealtimeRecentLocation' || this.route_name == 'TargetDetailRecentLocation') {
                this.dateRange.startDate = this.from_date_recent_location;
                this.dateRange.endDate = this.to_date_recent_location;

            } else if (this.route_name == 'ResultOverall' || this.route_name == 'ResultHistoricalOverall' || this.route_name == 'TargetDetailOverall') {
                this.dateRange.startDate = this.from_date_overall;
                this.dateRange.endDate = this.to_date_overall;

            } else if (this.route_name == 'ResultLocationMovement' || this.route_name == 'ResultHistoricalLocationMovement' || this.route_name == 'TargetDetailLocationMovement') {
                this.dateRange.startDate = this.from_date_location_movement;
                this.dateRange.endDate = this.to_date_location_movement;

            } else if (this.route_name == 'ResultLocationLog' || this.route_name == 'ResultHistoricalLocationLog' || this.route_name == 'TargetDetailLocationLog') {
                this.dateRange.startDate = this.from_date_location_log;
                this.dateRange.endDate = this.to_date_location_log;

            } else if (this.route_name == 'ResultBrowsingLog' || this.route_name == 'ResultHistoricalBrowsingLog' || this.route_name == 'TargetDetailBrowsingLog') {
                this.dateRange.startDate = this.from_date_browsing_log;
                this.dateRange.endDate = this.to_date_browsing_log;

            } else if (this.route_name == 'ResultAerialLog' || this.route_name == 'ResultHistoricalAerialLog' || this.route_name == 'TargetDetailAerialLog') {
                this.dateRange.startDate = this.from_date_aerial_log;
                this.dateRange.endDate = this.to_date_aerial_log;

            // } else if (this.route_name == 'ResultProfile' || this.route_name == 'TargetDetailProfile') {
            //     this.dateRange.startDate = this.from_date_profile;
            //     this.dateRange.endDate = this.to_date_profile;

            // } else if (this.route_name == 'ResultDeviceLog' || this.route_name == 'TargetDetailDeviceLog') {
            //     this.dateRange.startDate = this.from_date_device_log;
            //     this.dateRange.endDate = this.to_date_device_log;

            } else if (this.route_name == 'ResultHistoryDeviceRoles' || this.route_name == 'ResultHistoricalHistoryDeviceRoles' || this.route_name == 'TargetDetailHistoryDeviceRoles') {
                this.dateRange.startDate = this.from_date_history_device_roles;
                this.dateRange.endDate = this.to_date_history_device_roles;

            } else if (this.route_name == 'ResultDeviceHistory' || this.route_name == 'ResultHistoricalDeviceHistory' || this.route_name == 'TargetDetailDeviceHistory') {
                this.dateRange.startDate = this.from_date_device_history;
                this.dateRange.endDate = this.to_date_device_history;

            } else if (this.route_name == 'ResultTetheredDevice' || this.route_name == 'ResultHistoricalTetheredDevice' || this.route_name == 'TargetDetailTetheredDevice') {
                this.dateRange.startDate = this.from_date_tethered_device;
                this.dateRange.endDate = this.to_date_tethered_device;
            }

            // console.log(this.dateRange)
            if (this.dateRange.startDate === null || this.dateRange.endDate === null)
                this.defaultFilter();

            this.getMonthInfo()
        },
        retryChange() {
            if (this.route_name == 'ResultRecentLocation' || this.route_name == 'ResultRealtimeRecentLocation' || this.route_name == 'TargetDetailRecentLocation') {
                // if (this.status_recent_location_recent_location.code === 429)
                //     this.retryShow = true;

            } else if (this.route_name == 'ResultOverall' || this.route_name == 'ResultHistoricalOverall' || this.route_name == 'TargetDetailOverall') {
                if (this.status_overall_app_usage.code === 429)
                    this.retryShow = true;

            } else if (this.route_name == 'ResultLocationMovement' || this.route_name == 'ResultHistoricalLocationMovement' || this.route_name == 'TargetDetailLocationMovement') {
                // if (this.status_location_log_location_log.code === 429)
                //     this.retryShow = true;

            } else if (this.route_name == 'ResultLocationLog' || this.route_name == 'ResultHistoricalLocationLog' || this.route_name == 'TargetDetailLocationLog') {
                // if (this.status_location_log_location_log.code === 429)
                //     this.retryShow = true;

            } else if (this.route_name == 'ResultBrowsingLog' || this.route_name == 'ResultHistoricalBrowsingLog' || this.route_name == 'TargetDetailBrowsingLog') {
                // if (this.status_browsing_log_browsing_log.code === 429)
                //     this.retryShow = true;

            } else if (this.route_name == 'ResultAerialLog' || this.route_name == 'ResultHistoricalAerialLog' || this.route_name == 'TargetDetailAerialLog') {
                if (this.status_aerial_log_aerial_log.code === 429)
                    this.retryShow = true;

            // } else if (this.route_name == 'ResultProfile' || this.route_name == 'TargetDetailProfile') {
                // if (this.status_profile_profile_identity.code === 429 || this.status_profile_profile_family.code === 429)
                //     this.retryShow = true;

            // } else if(this.route_name == 'ResultDeviceLog' || this.route_name == 'TargetDetailDeviceLog') {
            } else if(this.route_name == 'ResultHistoryDeviceRoles' || this.route_name == 'ResultHistoricalHistoryDeviceRoles' || this.route_name == 'TargetDetailHistoryDeviceRoles') {
                // if (this.status_device_history_device_history.code === 429)
                //     this.retryShow = true;
            } else if(this.route_name == 'ResultDeviceHistory' || this.route_name == 'ResultHistoricalDeviceHistory' || this.route_name == 'TargetDetailDeviceHistory') {
                if (this.status_device_history_device_history.code === 429)
                    this.retryShow = true;

            } else if(this.route_name == 'ResultTetheredDevice' || this.route_name == 'ResultHistoricalTetheredDevice' || this.route_name == 'TargetDetailTetheredDevice') {
                if (this.status_tethered_device_tethered_device.code === 429)
                    this.retryShow = true;
            }
        },
        decryptData() {
            let data = null;

            // if (this.$route.params.key) {
            if (this.$route.params.key) {
                let decrypt_data = decrypt(this.$route.params.key);
                decrypt_data = decrypt_data.split('&&');
                // console.log(decrypt_data);

                var key = decrypt_data[0];
                var type = decrypt_data[1];

                data = { key: key, type: type };
            }
            return data;
        },
        filter() {
            const decrypt_data = this.decryptData();
            if (decrypt_data !== null && this.module.length > 0) {
                let service = '';

                switch(this.route.matched[0].name) {
                    case 'ResultShell':
                        service = 'default';
                        break;

                    case 'ResultRealtimeShell':
                    case 'ResultHistoricalShell':
                        service = 'external';
                        break;
                    
                    case 'pageTargetDetail':
                        service = 'internal';
                        break;
                }

                let fromDate = this.from_date
                let toDate = this.to_date
                if (Object.keys(this.selectedTimeByDate).length > 0) {
                    let tmpFromDate = []
                    let tmpToDate = []
                    for (var key of Object.keys(this.selectedTimeByDate)) {
                        // console.log(key + " -> " + this.selectedTimeByDate[key])
                        this.selectedTimeByDate[key].forEach(element => {
                            const tmpTime = element.split("-")
                            // console.log(element + " -> " + element.split("-"))
                            tmpFromDate.push(key +' '+tmpTime[0])
                            tmpToDate.push(key +' '+tmpTime[1])
                        });
                    }
                    fromDate = JSON.stringify(tmpFromDate)
                    toDate = JSON.stringify(tmpToDate)
                } 
                console.log('=====this.module', this.module)
                if (this.module=='aerialLog') {
                    this.$store.dispatch(this.module + '/getResultData', [decrypt_data.key, decrypt_data.type, service, fromDate, toDate]);
                } else {
                    this.$store.dispatch(this.module + '/getResultData', [decrypt_data.key, decrypt_data.type, service]);
                }
            }
        },
        reset() {
            this.$swal.fire({
                icon: 'warning',
                title: 'Are you sure you want to reset?',
                // text: 'Role : ' + name,
                confirmButtonText: 'Yes!',
                confirmButtonColor: '#DD6B55',
                showCancelButton: true,
                allowOutsideClick: false,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
                    const default_date = this.$store.getters['config/getDefaultDate'](def);

                    this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
                    this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();

                    this.updateDateRange(default_date.from_date, default_date.to_date);

                    this.filter();
                }
            });
        },
        defaultFilter() {
            let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
            const default_date = this.$store.getters['config/getDefaultDate'](def);

            this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
            this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();
            // this.dateRange.startDate = moment().subtract(6, 'days').toDate()
            // this.dateRange.endDate = moment().toDate();

            // this.from_date_input = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            // this.to_date_input = moment(this.dateRange.endDate).format('YYYY-MM-DD');

            // this.$store.commit('tetheredDevice/setFromDate', default_date.from_date);
            // this.$store.commit('tetheredDevice/setToDate', default_date.to_date);

            this.updateDateRange(default_date.from_date, default_date.to_date);

        },
        /* eslint-disable no-unused-vars */
        dateFormat (classes, date) {
            if (!classes.disabled) {
                classes.disabled = moment(date.getTime()).subtract({ hours: 12})._d > new Date();
            }
            return classes;
        },
        updateDateRange(from_date, to_date) {
            if (this.module.length > 0) {
                this.$store.commit(this.module + '/setFromDate', from_date);
                this.$store.commit(this.module + '/setToDate', to_date);
            }
        },
        async pickerUpdate(e) {
            let ranges = moment(e.endDate.getTime()).diff(moment(e.startDate.getTime()), 'days') + 1;
            let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)


            // if (ranges > def) {
            // if (ranges > this.$store.getters['config/getValidateRange']) {  
            if (ranges > this.$store.getters['config/getValidateRangeQueryByRoute'](this.route_name)) {  
                const default_date = this.$store.getters['config/getDefaultDate'](def);
                this.updateDateRange(default_date.from_date, default_date.to_date);
                await this.dateChange();

                // EventEmit.$emit('error', 'Date range is not more than ' + this.$store.getters['config/getValidateRange'] + ' days!');
                this.$swal.fire({
                    icon: 'error',
                    title: 'Date range is not more than ' + this.$store.getters['config/getValidateRangeQueryByRoute'](this.route_name) + ' days!',
                    text: 'Your date range is ' + ranges + ' days.',
                });
                return;
            }

            this.updateDateRange(moment(this.dateRange.startDate).format('YYYY-MM-DD'), moment(this.dateRange.endDate).format('YYYY-MM-DD'));
        },
        toggleAdvanceFilter(action=null) {
            this.openAdvanceFilter = !this.openAdvanceFilter
            if (action =='reset') {
                this.selectedTimeAll = []
                this.selectedTimeByDate = {}
            }
            const el = document.body

            if(this.openAdvanceFilter === true) {
                // console.log('body freezee')
                el.classList.add('body-freeze')
            }else {
                // console.log('body oveflow')
                el.classList.remove('body-freeze')
            }
        },
        toggleTimeFilter(data) {
            this.filterTime = !this.filterTime
            if (this.filterTime) {
                this.filterTimeBy = data
                if (data == 'all') {
                    this.tmpSelectedTime=this.selectedTimeAll;
                } else {
                    try {
                        // console.log('===data:',data)
                        this.tmpSelectedTime = this.selectedTimeByDate[data] || []
                    } catch (error) {
                        console.log('=====error get ByDate', error)
                        this.tmpSelectedTime = []
                    }
                }
            } else {
                if (data=='apply') {
                    // console.log('=====toggleTimeFilter apply: this.filterTimeBy', this.filterTimeBy)
                    if (this.filterTimeBy == 'all') {
                        this.selectedTimeAll = this.tmpSelectedTime;
                        this.selectedTimeByDate = {};
                        if (this.tmpSelectedTime.length > 0) {
                            for (let i = 0; i < this.months.length; i++) {
                                // const element = this.months[i];
                                for (let j = this.months[i]['start_day']; j <= this.months[i]['end_day']; j++) {                   
                                    // example format '2022-12-31'
                                    const key =  String(this.months[i]['year'])+'-'+ String(this.months[i]['month'])+'-'+ String(j).padStart(2, '0') 
                                    this.selectedTimeByDate[key] = this.tmpSelectedTime;
                                }
                            }
                        }
                    } else {
                        if (this.tmpSelectedTime.length == 0) {
                            delete this.selectedTimeByDate[this.filterTimeBy]; 
                        } else {
                            this.selectedTimeByDate[this.filterTimeBy] = this.tmpSelectedTime;
                        }
                    }
                }
                this.filterTimeBy = '';
                this.tmpSelectedTime=[];
            }
        },
        selectTime(value){
            if (value == 'all') {                
                this.tmpSelectedTime=[];
                for (let index = 0; index < this.timeOpt.length; index++) {
                    const element = this.timeOpt[index].value;
                    this.tmpSelectedTime.push(element);
                }
            } else {
                if(!this.tmpSelectedTime.includes(value)){
                    this.tmpSelectedTime.push(value);
                }else{
                    this.tmpSelectedTime.splice(this.tmpSelectedTime.indexOf(value), 1);  //deleting
                }            
            }
        },
        checkTimeStyle(value){
            let btnDisabled = false
            let btnSelected = false
            try {                
                if(this.tmpSelectedTime.includes(value)){
                    btnSelected = true
                }
                if (this.filterTimeBy != 'all' && this.selectedTimeAll.length !=0) {
                    if(!this.selectedTimeAll.includes(value)){
                        btnDisabled = true
                    }
                }
            } catch (error) {
                // console.log('error checkTimeStyle')
                // console.log(error)
            }
            return {
                'btn-disabled': btnDisabled,  
                'btn-item--selected': btnSelected,  
            }
        },
        checkDateStyle(value){
            let btnSelected = false
            try {
                let check = this.selectedTimeByDate[value]
                if (check.length) {
                    btnSelected = true
                }
            } catch (error) {
                // console.log('error checkDateStyle')
                // console.log(error)
            }
            return { 
                'btn-item--selected': btnSelected,  
            }
        },
        getTimeDesc(value){
            let desc = '00:00 - 24:00'
            try {
                let check = this.selectedTimeByDate[value]
                if (check.length > 1) {
                    desc = 'Multiple times'
                } else {
                    desc = check.toString()
                }
            } catch (error) {
                // console.log('error checkDateStyle')
                // console.log(error)
            }
            return desc
        },
        getMonthInfo() {
            this.selectedTimeAll = []
            this.selectedTimeByDate = {}
            this.months = []
            var from = this.from_date.split(/-| /)
            var to = this.to_date.split(/-| /)

            var year = from[0]
            var month = from[1]
            while ((month <= parseInt(to[1]) && year == parseInt(to[0])) || (month != parseInt(to[1])+1 && year < parseInt(to[0]))) {
                const date = new Date();
                date.setMonth(month - 1);
                var getMonthLong = date.toLocaleString('default', { month: 'long' });
                var getMonthShort = date.toLocaleString('default', { month: 'short' });

                var getDays = new Date(year,month,0).getDate();

                this.months.push({
                    name_long: getMonthLong,
                    name_short: getMonthShort,
                    days: getDays,
                    year: year,
                    month: month,
                    start_day: (month == from[1] && year == from[0])? parseInt(from[2]):1,
                    end_day: (month == to[1] && year == to[0])? parseInt(to[2]):getDays,
                });
                
                if (month == 12){
                    month = 0
                    year++
                }
                month++
            }
            // this.showLoading(false);
        },
    },
    filters: {
        date: function (date) {
            return moment(date).format('YYYY-MM-DD');
        }
    },
    async created() {
        await this.setModule();
    },
    async mounted() {
        this.dateChange();
        this.retryChange(); 
    },
}
</script>