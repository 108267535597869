<template lang="pug">
.page-information
    //- title
    TitleResult( title="Target Information")
    template(v-if="status_detail.status !== 'loading'")
        .grid.grid-cols-3.gap-8.auto-rows-auto.mb-2
            .card
                .card__header
                    h2.text-xl General Information
                .card__body
                    .rollout
                        span.text-xs Target Name
                        p.text-light {{ detail.target_name }}
                    .rollout
                        span.text-xs Operation Name
                        p.text-light {{ toString(detail.cases) }}
                    .rollout
                        span.text-xs Description
                        p.text-light {{ detail.description?detail.description:'-' }}
            .card
                .card__header
                    h2.text-xl Clues
                .card__body(v-for="clue in detail.clues")
                    .rollout
                        span.text-xs {{ clue.name.toUpperCase() }}
                        p.text-light {{ clue.value }}
                    .rollout(v-if="clue.detail && clue.detail.length > 0" v-for="detail in clue.detail")
                        span.text-xs(v-if="detail.value") {{ detail.name.toUpperCase() }}
                        p.text-light(v-if="detail.value") {{ detail.value? detail.value : '-' }}
                    
            .card
                .card__header
                    h2.text-xl Additional Information
                .card__body(v-if="detail.additional_info && detail.additional_info.length > 0")
                    .rollout(v-for="info in detail.additional_info")
                        span.text-xs {{ info.name }}
                        p.text-light {{ info.value }}
                .text-center(v-else)  No Data Available
        .card(v-if="checkFeature('target|biodata')")
            .card__header
                h2.text-xl Personal Information
            .card__body
                .grid.grid-cols-4.gap-4
                    .rollout
                        span.text-xs Gender
                        p.text-light {{ detail.gender || '-' }}
                    .rollout
                        span.text-xs Birth Place
                        p.text-light {{ detail.birth_place || '-' }}
                    .rollout
                        span.text-xs Birth Date
                        p.text-light {{ detail.birth_date_show || '-' }}
                    .rollout
                        span.text-xs Address
                        p.text-light {{ detail.address?detail.address:'-' }}
                    .rollout
                        span.text-xs Nationality
                        p.text-light {{ detail.nationality || '-' }}
                    .rollout
                        span.text-xs Religion
                        //- p.text-light {{ detail.religion || '-' }}
                        p.text-light {{ religionOpt(detail.religion) || '-' }}
                    .rollout
                        span.text-xs Jobs
                        p.text-light {{ detail.jobs || '-' }}
                    .rollout
                        span.text-xs Last Education
                        p.text-light {{ eduOpt(detail.last_education) || '-' }}

    .card(v-else)
        Loading(show="true")

    TargetInformationNotes

    //- photos
    TargetInformationDetailPhotos(v-if="isWatchlistQuota")
    TargetInformationPhotos(v-else)
    TargetInformationMonitoringActive(v-if="canMonitoring")
    TargetInformationHistory(v-if="canMonitoring")

</template>

<script>
import TitleResult from '@/components/result/Title.vue';
import ActionSearchResult from '@/components/result/ActionSearchResult.vue'
import TargetInformationNotes from './Notes.vue'
import TargetInformationMonitoringActive from './MonitoringActive.vue'
import TargetInformationHistory from './Detail.vue'
import TargetInformationPhotos from './Photos.vue'
import TargetInformationDetailPhotos from './DetailPhotos.vue'
import { mapState, mapGetters } from 'vuex';
import Loading from '@/components/Loading.vue';
import { checkFeature } from '@/config/features';

export default {
    name: 'ResultTargetInformation',
    components: {
        TitleResult,
        ActionSearchResult,
        TargetInformationNotes,
        TargetInformationMonitoringActive,
        TargetInformationHistory,
        TargetInformationPhotos,
        TargetInformationDetailPhotos,
        Loading,
    },
    computed: {
        ...mapState('auth', ['user']),
        ...mapState('target', [
            'detail',
            'status_detail',
        ]),
        ...mapGetters({
            isWatchlistQuota: 'config/getIsWatchlistQuota',
        }),
        route_name() {
            return this.$route.name;
        },
        id() {
            return this.$route.params.id;
        },
        canMonitoring() {
            return this.checkFeatureByAcl('single_target', 'monitoring') || this.checkFeatureByAcl('monitoring', 'post')
        }
    },
    methods: {
        checkFeature(feature) {
            return checkFeature(feature);
        },
        toString(array) {
            if (array && array.length > 0) {            
                return Object.keys(array).map(function(k){
                    return array[k].operation_name
                }).join(", ")
            } else {
                return '-'
            }
        },
        religionOpt(key) {
            let name = '-'
            let religionOpt = [
                    {key: 'Islam',name: 'Islam'},
                    {key: 'Kristen',name: 'Christianity'},
                    {key: 'Katolik',name: 'Catholicism'},
                    {key: 'Hindu',name: 'Hinduism'},
                    {key: 'Buddha',name: 'Buddhism'},
                    {key: 'Konghucu',name: 'Confucianism'},
                ];
            try {                
                let obj = religionOpt.find(o => o.key === key);
                name = obj.name
            } catch (error) {
                // console.log(error)
            }
            return name;
        },
        eduOpt(key) {
            let name = '-'
            let eduOpt = [
                    {key: 'NoEdcucation',name: 'No/Not yet School'},
                    {key: 'notCompleted',name: 'Did not finish Elementary School/Equivalent'},
                    {key: 'SD',name: 'Elementary School/Equivalent'},
                    {key: 'SMP',name: 'Junior High School/Equivalent'},
                    {key: 'SMA',name: 'Senior High School/Equivalent'},
                    {key: 'D1/D2',name: 'Diploma I/II'},
                    {key: 'D3',name: 'Diploma III/Equivalent'},
                    {key: 'D4/S1',name: 'Diploma IV/Bachelor'},
                    {key: 'S2',name: 'Master'},
                    {key: 'S3',name: 'Doctor'},
                ];
            try {                
                let obj = eduOpt.find(o => o.key === key);
                name = obj.name
            } catch (error) {
                // console.log(error)
            }
            return name;
        },
    },
}
</script>