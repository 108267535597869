<template lang="pug">
.license-shell
    .box
        .box__header
            TitlePage(
                title="License"
                    :breadcrumb="breadcrumb"
                )
        .box__body
            nav.menu(v-if="route.matched[1] && route.matched[1].name === 'pageLicenseIndex'")
                ul
                    li
                        router-link(to="/license") Activated License
                    //- li
                        router-link(to="/license/history") History License

            router-view
    
</template>

<script>
import TitlePage from '@/components/shell/TitlePage.vue'
import { mapState } from 'vuex';

export default {
    name: 'LicenseShell',
    components: {
        TitlePage,
    },
    data() {
        return {
            // 
        }
    },
    computed: {
        route() {
            return this.$route;
        },
        id() {
            return this.$route.params.id;
        },
        breadcrumb() {
            let breadcrumb = [
                {
                    name: 'License',
                    url: '/license'
                }
            ];

            if (this.route.matched[0] && this.route.matched[0].components.default.name === 'LicenseShell' && this.route.matched[1]) {
                switch(this.route.matched[1].name) {
                    case 'pageLicenseCreate':
                        breadcrumb.push({
                            name: 'Add License',
                            url: '/license/create',
                        });
                        break;

                    case 'pageLicenseEdit':
                        breadcrumb.push({
                            name: 'Edit License',
                            url: '/license/'+this.id+'/edit',
                        });
                        break;

                    default:
                        // code block
                }
            }

            return breadcrumb;
        },
    },
    watch: {
        route(newData) {
            if (newData.matched[0] && newData.matched[0].components.default.name === 'LicenseShell')
                window.scrollTo(0,0);
        },
        id() {},
        breadcrumb() {},
    },
    created() {
        if (this.$route.params.id && this.$route.params.id === 'create')
            this.$router.push({ name: 'pageLicenseCreate', params: {} });
    },
    mounted() {
        // 
    },
}
</script>

<style lang="sass" scoped>
    nav.menu
        ul
            @apply .flex .items-center .border-b
            li
                display: block
                a
                    @apply .block .py-4 .px-6 .relative
                    transition: all 0.3s ease-in-out
                    &::before
                        content: ''
                        height: 2px
                        width: 100%
                        position: absolute
                        bottom: -1px
                        left: 0px
                        background-color: transparent
                        transition: all 0.3s ease-in-out
                    &:hover
                        color: $color-main-500
                    &.router-link-exact-active
                        color: $color-main-500
                        &::before
                            background-color: $color-main-500
</style>