<template lang="pug">
.target-maps
    .box__body
        .card.card--no-bg
            .card__header
                h2.text-xl Spread Target by Location
                //- .header-cta
                //-     .flex.items-center
                        //- .block(v-if="isRemainingQuota")
                        //- .block(v-if="canMonitoring")
                        //-     span.btn-default Daily quota used: {{ status_remaining_quota_target.status === 'loading' ? 'loading...' : (remaining_quota_target.today_usage || remaining_quota_target.today_usage == 0 ? remaining_quota_target.today_usage : '-') +'/'+ (remaining_quota_target.total || remaining_quota_target.total == 0 ? remaining_quota_target.total : '-') }}
                        //- .block
                        //- router-link.btn-primary.m-btn-icon(
                        //-     :to="{ name: 'pageTargetCreate', params: {} }"
                        //- )
                        //-     IconSet(type="add")
                        //-     span.m-hide.block.ml-3 Add Target

            //-  Design here for the maps
            //- .flex.items-center.py-12
                //- copy here
                .map-point.map-point--map-avatar
                    img(src="https://randomuser.me/api/portraits/men/84.jpg", alt="alt")

                //- copy here
                .map-point.map-point--map-name(
                    style="background-color: #edf79a"
                )
                    span AB

                //- copy here
                .map-point.map-point--map-name(
                    style="background-color: #8c1111"
                )
                    span AB

            .card__body
                //- BoxMap
                template(v-if="status_list.status === 'loading'")
                    Loading(show="true")
                template(v-else)
                    template(v-if="mapLoaded")
                        .block(class="w-full lg:w-12/12" style="height: 450px;")
                            l-map(v-if="mapVendor === 'osm'" ref="mapRefOSM" :id="id" :zoom="10" :center="center" :options="options" style="height: 100%; width: 100%;")
                                l-tile-layer(:url="url" :attribution="attribution")
                                l-marker-cluster
                                    l-marker(v-for="(location, index) in locations" :lat-lng="location.position" v-bind:key="index" :icon="getMarkerOsmIcon(location)")
                                        l-popup(:content="location.name")
                            gmap-map(v-else ref="mapRef" :center="center" :zoom="11" :options="options" style="width:100%; height: 100%;")

                    .block(v-else class="w-full lg:w-12/12")
                        NoDataAvailable
</template>

<script>
import { env } from '@/config/env';
// import BoxMap from '@/components/Map.vue'
import { mapState, mapGetters } from 'vuex';
import IconSet from '@/components/IconSet.vue'
import { gmapApi } from 'vue2-google-maps';
import Loading from '@/components/Loading.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';

// open street map
import {LMap, LTileLayer, LMarker, LPopup} from 'vue2-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { GestureHandling } from "leaflet-gesture-handling";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";

// import LeafletHeatmap from '@/components/LeafletHeatmap.vue';
// import 'leaflet.heat';
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.imagePath = '';
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export default {
    name: 'pageTargetMaps',
    components: {
        // BoxMap
        IconSet,
        Loading,
        NoDataAvailable,

        // open street map
        LMap,
        // GestureHandling,
        LTileLayer,
        LMarker,
        LPopup,
        // LeafletHeatmap,
        'l-marker-cluster': Vue2LeafletMarkerCluster,
    },
    data() {
        return {
            // map
            mapVendor: this.$store.getters['config/getMapVendor'],
            options: {
                gestureHandling: 'cooperative',
                styles: [
                    {elementType: 'geometry', stylers: [{color: '#242f3e'}]},
                    {elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}]},
                    {elementType: 'labels.text.fill', stylers: [{color: '#746855'}]},
                    {
                        featureType: 'administrative.locality',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#d59563'}]
                    },
                    {
                        featureType: 'poi',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#d59563'}]
                    },
                    {
                        featureType: 'poi.park',
                        elementType: 'geometry',
                        stylers: [{color: '#263c3f'}]
                    },
                    {
                        featureType: 'poi.park',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#6b9a76'}]
                    },
                    {
                        featureType: 'road',
                        elementType: 'geometry',
                        stylers: [{color: '#38414e'}]
                    },
                    {
                        featureType: 'road',
                        elementType: 'geometry.stroke',
                        stylers: [{color: '#212a37'}]
                    },
                    {
                        featureType: 'road',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#9ca5b3'}]
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'geometry',
                        stylers: [{color: '#746855'}]
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'geometry.stroke',
                        stylers: [{color: '#1f2835'}]
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#f3d19c'}]
                    },
                    {
                        featureType: 'transit',
                        elementType: 'geometry',
                        stylers: [{color: '#2f3948'}]
                    },
                    {
                        featureType: 'transit.station',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#d59563'}]
                    },
                    {
                        featureType: 'water',
                        elementType: 'geometry',
                        stylers: [{color: '#17263c'}]
                    },
                    {
                        featureType: 'water',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#515c6d'}]
                    },
                    {
                        featureType: 'water',
                        elementType: 'labels.text.stroke',
                        stylers: [{color: '#17263c'}]
                    }
                ],
            },
            infoWindows: [],
            mapLoaded: false,
            center: {},
            markers: [],
            mapsLatLng: [],
            polyline: null,

            marker_color: {
                green: '#48bb78',
                red: '#f56565',
                purple: '#9f7aea',
                orange: '#ed8936',
                blue: '#4299e1',
            },

            // openstreetmap
            // url: 'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
            url: `${env.VUE_APP_OSM_CDN_URL}{z}/{x}/{y}.png`,
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            locations: [],
            id: 'openstreetmap-target',
            newLoc: '',
            newLt : 0, 
            newLng : 0,
            icon: L.icon({iconUrl: "null",}),
            latLngHeat: [],
            maxHeat: 1.0,
            radHeat: 30,
        };
    },
    computed: {
        ...mapState('locationEs', [
            'status_list',
            'list',
        ]),
        ...mapState('dashboard', [
            'status_remaining_quota_target',
            'remaining_quota_target',
        ]),
        ...mapGetters({
            isRemainingQuota: 'config/getIsRemainingQuota',
        }),
        route_name() {
            return this.$route.name;
        },
        google: gmapApi,
        canMonitoring() {
            return this.checkFeatureByAcl('single_target', 'monitoring') || this.checkFeatureByAcl('monitoring', 'post')
        }
    },
    watch: {
        route_name(newData, oldData) {
            if (newData === 'pageTargetMaps') {
                this.getData();
            }
        },
        list(newData) {
            if (newData.length > 0)
                this.startMap();
        },
        google() {},
    },
    methods: {
        getData() {
            // for dispatch get data
            if (this.route_name === 'pageTargetMaps') {
                this.$store.dispatch('locationEs/getList',['last']);
                // if (this.isRemainingQuota)
                if (this.canMonitoring) {
                    this.$store.dispatch('dashboard/getRemainingQuota', 'target');
                }
            }
        },
        hideAllInfoWindows() {
            for (var j=0; j < this.infoWindows.length; j++) {
                this.infoWindows[j].close();
            }
        },
        createInfoWindow(name='-', msisdn='-', address='-', lat_lng='') {
            var contentString = '<div id="content">'+
                    '<div id="bodyContent">'+
                        '<table>'+
                            '<tr>'+
                                '<td class="pr-1 cell-info-window whitespace-no-wrap">Target Name</td>'+
                                '<td class="pr-1 cell-info-window">:</td>'+
                                '<td class="cell-info-window">'+ name +'</td>'+
                            '</tr>'+
                            '<tr>'+
                                '<td class="cell-info-window whitespace-no-wrap">MSISDN</td>'+
                                '<td class="cell-info-window">:</td>'+
                                '<td class="cell-info-window">'+ msisdn +'</td>'+
                            '</tr>'+
                            '<tr>'+
                                '<td class="cell-info-window whitespace-no-wrap">Address</td>'+
                                '<td class="cell-info-window">:</td>'+
                                '<td class="cell-info-window">'+ address +'</td>'+
                            '</tr>'+
                            // '<tr>'+
                            //   '<td class="cell-info-window">Datetime</td>'+
                            //   '<td class="cell-info-window">:</td>'+
                            //   '<td class="cell-info-window">'+dtm+'</td>'+
                            // '</tr>'+
                            '<tr>'+
                                '<td class="cell-info-window whitespace-no-wrap">Link Location</td>'+
                                '<td class="cell-info-window">:</td>'+
                                '<td class="cell-info-window"><a target="_blank" href="https://www.google.com/maps?q='+ lat_lng +'" style="color: #43ea92;">'+'Google Maps</a> '+
                                '</td>'+
                            '</tr>'+
                        '</table>'+
                    '</div>'+
                '</div>';

            return contentString;
        },
        getMarkerOsmIcon(location){
            // let iconColor = '#ffffff'
            // if (location.iconColor){
            //     iconColor = location.iconColor
            // }
            // let stroke_width = '1.8';
            // let stroke = 'white';
            // let mySvgString = '<svg width="64" height="64" viewBox="0 0 64 64" fill="<color-icon>" xmlns="http://www.w3.org/2000/svg"><path d="M31.5766 15.7225C35.366 15.7225 38.4379 12.6506 38.4379 8.86127C38.4379 5.07189 35.366 2 31.5766 2C27.7872 2 24.7153 5.07189 24.7153 8.86127C24.7153 12.6506 27.7872 15.7225 31.5766 15.7225Z" fill="<color-icon>" stroke="'+ stroke +'" stroke-width="'+ stroke_width +'"/><path d="M18 26.8175V41.4161C18 43.1679 19.4599 44.4817 21.0657 44.4817H23.9854L25.5911 59.5183C25.7372 60.9782 26.905 62 28.3649 62H34.6423C36.1022 62 37.2701 60.978 37.4161 59.5183L39.0219 44.4817H41.9416C43.6934 44.4817 45.0073 43.1679 45.0073 41.4161V26.8175C45.1533 14.9926 18 14.9926 18 26.8175Z" fill="<color-icon>" stroke="'+ stroke +'" stroke-width="'+ stroke_width +'" stroke-linejoin="round"/></svg>'
            // // let myIconUrl = encodeURI("data:image/svg+xml," + mySvgString).replace('<color-icon>',iconColor).replace('#','%23');
            // // let myIconUrl = encodeURI("data:image/svg+xml;utf8," + mySvgString.replaceAll('<color-icon>',iconColor)).replaceAll('#','%23');
            // let myIconUrl = encodeURI("data:image/svg+xml;utf8," + mySvgString.replace(/<color-icon>/g,iconColor)).replace(/#/g,'%23');
            let classNm = ''
            let name = location.target_name
            let cut = name.split(' ').slice(0,4).join(' ')
            let initialName = cut.match(/\b(\w)/g).join("").toUpperCase()
            let myIconUrl = "<div class='map-point map-point--map-name' style='background-color: "+location.iconColor+";'><span>"+initialName+"</span></div>"
            let type = 'initial'
            if(location.photo) {
                myIconUrl = location.photo
                classNm = 'map-point map-point--map-avatar'
                type = 'photo'
            }
            if (type == 'photo') {
                return L.icon({
                    iconUrl: myIconUrl,
                    className: classNm,
                    iconSize: [50,50],
                    iconAnchor: [24.5, 49],
                    popupAnchor: [0, -48],
                });   
            } else {
                return L.divIcon({
                    html: myIconUrl,
                    iconSize: [50,50],
                    className: '',
                    iconAnchor: [24.5, 49],
                    popupAnchor: [0, -45],
                }) 
            }
        },
        async startMap() {
            var self = this;
            // var mapsLatLng = [];

            // gmap
            self.infoWindows = [];
            self.markers = [];

            // osm
            self.locations = [];

            // monas, jakarta
            this.center = {
                lat: -6.1763562,
                lng: 106.8227796,
            };

            this.mapLoaded = true;

            switch(self.mapVendor) {
                case 'osm':
                    await self.$nextTick(() => {
                        const map = self.$refs.mapRefOSM.mapObject

                        if (self.list.length > 0) {
                            var bounds = [];
                            
                            for (var i = 0; i < self.list.length; i++) {

                                if (Object.keys(self.list[i].data).length > 0) {
                                    let latTmp = parseFloat(self.list[i].data.lat);
                                    let lngTmp = parseFloat(self.list[i].data.lng);
                                    let centerTmp = [latTmp, lngTmp]
                                    var contentString = self.createInfoWindow(self.list[i].target.name, (self.list[i].msisdn ? self.list[i].msisdn : '-'), (self.list[i].data.address ? self.list[i].data.address : '-'), (self.list[i].data.lat+','+self.list[i].data.lng));
                                    
                                    var tmpPhoto = ''
                                    try {
                                        tmpPhoto = self.list[i].target.first_photos
                                    } catch (error) {
                                        console.log(error)
                                    }

                                    self.locations.push({
                                        name:contentString,
                                        target_name:self.list[i].target.name,
                                        position:centerTmp, 
                                        iconColor:self.list[i].target.label,
                                        photo: tmpPhoto,
                                    });

                                    bounds.push(centerTmp);
                                }

                            }

                            if (bounds.length)
                                map.fitBounds(L.latLngBounds([bounds]));
                        }
                    });
                    break;

                default:
                    await self.$nextTick(() => {
                        self.$refs.mapRef.$mapPromise.then((map) => {
                            const google = self.google;

                            map.center = new google.maps.LatLng(self.center);

                            // console.log(self.list.length);

                            if (self.list.length > 0) {
                                var bounds = new google.maps.LatLngBounds();

                                for (var i = 0; i < self.list.length; i++) {

                                    let name = self.list[i].target.name;
                                    let cut = name.split(' ').slice(0,4).join(' ');
                                    let initialName = cut.match(/\b(\w)/g).join("").toUpperCase();

                                    if (Object.keys(self.list[i].data).length > 0) {


                                        
                                        let icon = {
                                            path: 'M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0',
                                            // path: 'M31.5766 15.7225C35.366 15.7225 38.4379 12.6506 38.4379 8.86127C38.4379 5.07189 35.366 2 31.5766 2C27.7872 2 24.7153 5.07189 24.7153 8.86127C24.7153 12.6506 27.7872 15.7225 31.5766 15.7225Z M18 26.8175V41.4161C18 43.1679 19.4599 44.4817 21.0657 44.4817H23.9854L25.5911 59.5183C25.7372 60.9782 26.905 62 28.3649 62H34.6423C36.1022 62 37.2701 60.978 37.4161 59.5183L39.0219 44.4817H41.9416C43.6934 44.4817 45.0073 43.1679 45.0073 41.4161V26.8175C45.1533 14.9926 18 14.9926 18 26.8175Z',
                                            // fillColor: self.marker_color[self.list[i].target.label],
                                            fillColor: self.list[i].target.label,
                                            fillOpacity: 1,
                                            // strokeWeight: 1.2,
                                            strokeWeight: 1.2,
                                            strokeColor: '#ffffff',
                                            // scale: 1.25,
                                            scale: .25,
                                            anchor: new google.maps.Point(30,65),
                                            labelOrigin: new google.maps.Point(100, 100)
                                        }

                                        if (self.list[i].target.first_photos) {
                                            // console.log('======ada photo')
                                            console.log(self.list[i].target.first_photos)
                                            icon = { 
                                                url: self.list[i].target.first_photos+ '#custom_marker',
                                                scaledSize: new google.maps.Size(50, 50)
                                            }
                                        }

                                        let marker = new google.maps.Marker({
                                            position: {
                                                lat: parseFloat(self.list[i].data.lat),
                                                lng: parseFloat(self.list[i].data.lng)
                                            },
                                            map: map,
                                            icon: icon,
                                            label: initialName,
                                            // optimized: false,
                                            // draggable: true,
                                        });
                                        // console.log(marker);
                                        self.markers.push(marker);

                                        // var dtm = (self.tactical_devices_map_location[i].created_at ? self.datetimeFormat(self.tactical_devices_map_location[i].created_at) : '-');
                                        
                                        var contentString = self.createInfoWindow(self.list[i].target.name, (self.list[i].msisdn ? self.list[i].msisdn : '-'), (self.list[i].data.address ? self.list[i].data.address : '-'), (self.list[i].data.lat+','+self.list[i].data.lng));

                                        let infowindow = new google.maps.InfoWindow({
                                            content: contentString,
                                            maxWidth: 400
                                        });
                                        
                                        self.infoWindows.push(infowindow);
                                        
                                        google.maps.event.addListener(marker, 'click', function() {
                                            // hideAllInfoWindows(map);
                                            self.hideAllInfoWindows();
                                            infowindow.open(map, marker);
                                            // console.log(marker.position.lat(), marker.position.lng())
                                        });

                                        bounds.extend(new google.maps.LatLng(marker.position.lat(), marker.position.lng()));
                                    }

                                }
                                // Apply fitBounds
                                map.fitBounds(bounds); 
                            }
                        });
                    });
            }
        },
    },
    created() {
        this.$store.commit('target/setShowNavbar', true);
        this.getData();
    },
    mounted() {
        // 
    },
}
</script>

<style lang="sass">
.map-point
    width: 40px
    height: 40px
    overflow: hidden
    border-radius: 100px
    border: 2px solid #fff
    display: flex
    align-items: center
    justify-content: center
    &--map-name
        span
            color: white
            mix-blend-mode: difference

.marker-cluster
    span
        mix-blend-mode: difference
#bodyContent
    color: #000

img[src$="#custom_marker"]
    border: 2px solid white !important
    border-radius: 50% !important

</style>