<template lang="pug">
.card.card--table
    .card__header.card--table__header
        h2.text-xl List Browsing Log Data
        .header-cta
            //- Export to Excel
            JsonExcel.btn-primary-o.btn-small.cursor-pointer.mr-3(
                v-if="tableData.length > 0"
                :title="[title, from_date + ' - ' + to_date]"
                :name="excelName"
                :data="exportData"
            )
                IconSet(type="download" size="s")
                span.m-hide.ml-3
                | Download Excel

            //- Export to CSV
            JsonExcel.btn-primary-o.btn-small.cursor-pointer.mr-3(
                v-if="tableData.length > 0"
                type = "csv"
                :title="[title, from_date + ' - ' + to_date]"
                :name="csvName"
                :data="exportData"
            )
                IconSet(type="download" size="s")
                span.m-hide.ml-3
                | Download CSV

    .card__body
        template(v-if="status_history.status === 'loading'")
            template(v-if="route_name === 'TargetDetailBrowsingLog'")
                Loading(show="true")
            template(v-else)
                LoadingAnimation(show="true" title="browsing-log-data")
        template(v-else) 
            NoDataAvailable(v-if='filterNull')
            template(v-else-if="history && history.length > 0")
                .box__body.p-4
                    <v-client-table ref='myTable' :data="tableData" :columns="columns" :options="options" @pagination="pageChange($event)" @limit="limitChange($event)" @loaded="loaded()">
                        <span slot="no" slot-scope="props">{{props.index}}.</span>
                    </v-client-table>  
            NoDataAvailable(v-else)

        //- .box-filter-table.p-4.pt-0
            .field(
                class="mr-0 lg:mr-4"
            )
                input.field-style(
                    placeholder="Search data here..."
                )
                .field-icon
                    IconSet(
                        type="search"
                    )
            .field
                select.field-style
                    option 10 record
                    option 20 record
                .field-icon
                    IconSet(
                        type="chevron-down"
                    )
        //- .box-table.box-table--overflow
            table.table
                thead
                    tr
                        th Datetime
                        th Time
                        th Provider
                        th Rat Name
                        th Country
                        th Province
                        th Village
                        th MCC
                        th MNC
                        th region
                        th column a
                        th column b
                        th column c
                        th column d
                        th column e
                        th column f
                        th column g
                        th column h
                        th column i
                        th column j
                        th column k
                tbody
                    tr(v-for="item in LogData" :key="item.id")
                        td {{ item.datetime }}
                        td {{ item.time }}
                        td {{ item.provider }}
                        td {{ item.rat }}
                        td {{ item.country }}
                        td {{ item.province }}
                        td {{ item.village }}
                        td {{ item.mcc }}
                        td {{ item.mnc }}
                        td {{ item.city }}
                        td {{ item.region }}
                        td {{ item.random }}
                        td {{ item.random }}
                        td {{ item.random }}
                        td {{ item.random }}
                        td {{ item.random }}
                        td {{ item.random }}
                        td {{ item.random }}
                        td {{ item.random }}
                        td {{ item.random }}
                        td {{ item.random }}
    //- .card__footer
        .box-table-footer
            span.text-xs Showing 10 from 100
            .pagination
                ul
                    li
                        span 1
                    li
                        router-link(to="")  2
                    li
                        router-link(to="")  3

</template>

<script>
import IconSet from '@/components/IconSet.vue'
import { mapState } from 'vuex';
import Loading from '@/components/Loading.vue';
import LoadingAnimation from '@/components/LoadingAnimation.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';
import JsonCSV from 'vue-json-csv';
import JsonExcel from "vue-json-excel";
import moment from 'moment';

export default {
    name: 'BrowsingLogLogData',
    components: {
        IconSet,
        Loading,
        NoDataAvailable,
        JsonCSV,
        JsonExcel,
        LoadingAnimation
    },
    data() {
        return {
            // LogData: [
            //     {
            //         datetime: '2020-10-05',
            //         time: '15:05:00',
            //         provider: 'Telkomsel',
            //         rat: '4G',
            //         country: 'Indonesia',
            //         province: 'JAWA BARAT',
            //         village: 'Medan Satria - Medan Satria',
            //         mcc: '510',
            //         mnc: '10',
            //         city: 'KOTA BEKASI',
            //         region: 'JABODETABEK',
            //         random: 'column random value'
            //     },
            // ],

            // --------------------------------------------

            filterNull: false,
            columns: ['datetime','provider','rat_name','domain','duration','msisdn','imsi','imei','hostname','server_ip','server_port','web_category','app_category','method','server_delay','ssl','client_ip','client_port','client_delay','browser_name','vol_in','vol_out','vol_total','response_code','referer_category','location','apn','url','referer_url','referer_domain'],
            options: {
                headings: {
                    'rat_name': "Rat Name",
                    'msisdn': "MSISDN",
                    'imsi': "IMSI",
                    'imei': "IMEI",
                    'server_ip': "Server IP",
                    'server_port': "Server Port",
                    'web_category': "Web Category",
                    'app_category': "App Category",
                    'server_delay': "Server Delay",
                    'client_ip': "Client IP",
                    'client_port': "Client Port",
                    'client_delay': "Client Delay",
                    'browser_name': "Browser Name",
                    'vol_in': "Vol In",
                    'vol_out': "Vol Out",
                    'vol_total': "Vol Total",
                    'response_code': "Res Code",
                    'referer_category': "Referer Category",
                    'referer_url': "Referer URL",
                    'referer_domain': "Referer Domain",
                },
                // filterable: false,
                // perPage: 10,
                pagination: { 
                    chunk: 5 
                    // dropdown: false 
                },
                // perPageValues: [10,25,50,100],
                skin: 'table table-overflow',
                // skin: 'table-static w-full p-4',
                sortIcon: { 
                    base:'iconfont',
                    is:'iconsort',
                    up: 'iconup',
                    down: 'icondown'
                },
                columnsClasses: {
                    'datetime': 'font-mini',
                },
                customSorting: {
                    datetime: function (ascending) {
                        return function (a, b) {
                            const parseDate = (dateStr) => {
                                if (typeof dateStr !== 'string') return 0;
                                const months = {
                                    Jan: 0, Feb: 1, Mar: 2, Apr: 3, May: 4, Jun: 5,
                                    Jul: 6, Aug: 7, Sep: 8, Oct: 9, Nov: 10, Dec: 11
                                };
                                const [day, month, year, time, offset] = dateStr.split(' ');
                                const [hours, minutes, seconds] = time.split(':').map(Number);
                                const date = new Date(year, months[month], day, hours, minutes, seconds);
                                return date;
                            };
                            const dateA = parseDate(a.datetime);
                            const dateB = parseDate(b.datetime);

                            if (ascending)
                                return dateA >= dateB ? 1 : -1;

                            return dateB >= dateA ? 1 : -1;
                        }
                    }
                }
            },
            tableData:[],

            page: 1,
            limit: 10,

            csvName: 'result_browsing_log_log_data'+moment().format('YYYY-MM-DD_HH:mm:ss')+'.csv',
            exportData: [],

            excelName: 'result_browsing_log_log_data'+moment().format('YYYY-MM-DD_HH:mm:ss')+'.xls',

            title: 'Browsing Log - Log Data',
        }
    },
    computed: {
        ...mapState('browsingLog', [
            'status_history',
            'history',
            'from_date',
            'to_date',
            'default_export',
        ]),
        route_name() {
            return this.$route.name;
        },
    },
    watch:{
        history() {
            this.getData();
        },
    },
    methods: {
        getData() {
            if (this.history && this.history.length > 0) {
                this.tableData = this.history
                this.filterNull=false
            } else {
                this.filterNull=true

                this.exportData = [{
                    datetime: '-',
                    provider: '-',
                    rat_name: '-',
                    domain: '-',
                    duration: '-',
                    msisdn: '-',
                    imsi: '-',
                    imei: '-',
                    hostname: '-',
                    server_ip: '-',
                    server_port: '-',
                    web_category: '-',
                    app_category: '-',
                    method: '-',
                    server_delay: '-',
                    ssl: '-',
                    client_ip: '-',
                    client_port: '-',
                    client_delay: '-',
                    browser_name: '-',
                    vol_in: '-',
                    vol_out: '-',
                    vol_total: '-',
                    response_code: '-',
                    referer_category: '-',
                    location: '-',
                    apn: '-',
                    url: '-',
                    referer_url: '-',
                    referer_domain: '-',
                }];
            }
        },
        pageChange(data) {
            this.page = data;
        },
        limitChange(data) {
            this.limit = data;
        },
        loaded() {
            let data = [];

            if (this.tableData.length > 0) {
                if (this.default_export == 'all') {
                    data = this.tableData;
                } else {
                    data = this.tableData.slice((this.page - 1) * this.limit, this.page * this.limit);
                }
            }
            this.exportData = data.map((item) => {
                return {
                    datetime: item.datetime ? item.datetime : '-',
                    provider: item.provider ? item.provider : '-',
                    rat_name: item.rat_name ? item.rat_name : '-',
                    domain: item.domain ? item.domain : '-',
                    duration: item.duration ? item.duration : '-',
                    msisdn: item.msisdn ? item.msisdn : '-',
                    imsi: item.imsi ? item.imsi : '-',
                    imei: item.imei ? item.imei : '-',
                    hostname: item.hostname ? item.hostname : '-',
                    server_ip: item.server_ip ? item.server_ip : '-',
                    server_port: item.server_port ? item.server_port : '-',
                    web_category: item.web_category ? item.web_category : '-',
                    app_category: item.app_category ? item.app_category : '-',
                    method: item.method ? item.method : '-',
                    server_delay: item.server_delay ? item.server_delay : '-',
                    ssl: item.ssl ? item.ssl : '-',
                    client_ip: item.client_ip ? item.client_ip : '-',
                    client_port: item.client_port ? item.client_port : '-',
                    client_delay: item.client_delay ? item.client_delay : '-',
                    browser_name: item.browser_name ? item.browser_name : '-',
                    vol_in: item.vol_in ? item.vol_in : '-',
                    vol_out: item.vol_out ? item.vol_out : '-',
                    vol_total: item.vol_total ? item.vol_total : '-',
                    response_code: item.response_code ? item.response_code : '-',
                    referer_category: item.referer_category ? item.referer_category : '-',
                    location: item.location ? item.location : '-',
                    apn: item.apn ? item.apn : '-',
                    url: item.url ? item.url : '-',
                    referer_url: item.referer_url ? item.referer_url : '-',
                    referer_domain: item.referer_domain ? item.referer_domain : '-',
                };
            });
        },
    },
    mounted () {
        this.getData();
    },
}
</script>

<style lang="sass">
@import "@/assets/sass/components/table-overflow.sass"
</style>
