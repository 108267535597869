<template lang="pug">
    .monitoring-log-management
        .box
            // .box__header
            //     TitlePage(
            //         title="Monitoring Log"
            //         :breadcrumb="breadcrumb"
            //         )
            .box__body
            .card.card--table.card--no-bg
                .card__header.card--table__header
                    h2.text-xl Monitoring Log Management
                    .header-cta
                        .block
                            // button.btn-primary.m-btn-icon
                                // IconSet(type="download")
                                // span.m-hide.ml-3
                                // JsonCSV(
                                //     :data="csvData"
                                //     :name="csvName"
                                // )
                                //     | Download CSV
                .card--table__body
                    .box-filter.px-4.pt-6
                        //- showing data information mobile
                        .m-only
                            .filter-info
                                .flex.items-center.justify-between.mb-3
                                    Strong.uppercase.text-xs.font-bold Filtered by
                                    button.btn-default-o.btn-small(
                                        type='button'
                                        v-on:click="showFilter = !showFilter"
                                        ) Change Filter
                                p {{ dateRange.startDate }} -  {{ dateRange.endDate }}, All Target, 10 entries

                        //- end showing data information mobile
                        .mobile-filter(
                            v-bind:class="{ 'is-show' : showFilter}"
                        )
                            .m-only
                                .flex.items-center.justify-between.p-4.border-b
                                    h2.text-xl Filter
                                    button.btn-icon-40(
                                        v-on:click="showFilter = !showFilter"
                                    )
                                        IconSet(type="close")
                            .items-filter
                                .field.box-find(class="lg:mr-3 lg:mb-0 mr-0 mb-4")
                                    .relative
                                        .field.relative.w-full
                                            input.field-style(
                                                placeholder="Select user (write minimum 3 characters)"
                                                v-model="user_keyword"
                                                @keyup="searchUser()"
                                            )
                                            .field-icon
                                                IconSet(type="search")

                                            .box-find-selected(v-if="user_selected")
                                                .flex.items-center.justify-between
                                                    .flex.items-center
                                                        figure.mr-3
                                                            //- img.w-6.h-6.rounded-full.object-fit(src="https://randomuser.me/api/portraits/men/41.jpg")
                                                        span {{user_keyword}}
                                                    button.btn-icon-24(@click="setEmptyUser" v-tooltip="'Remove user'")
                                                        IconSet(type="close")

                                        .p-4.absolute.container-box-users(v-if="status_list.status === 'loading'")
                                            .flex.flex-wrap.items-center.justify-center
                                                Spinner(show="true" size="24")
                                        .p-4.absolute.container-box-users(v-else-if="list.data && list.data.length > 0")
                                            .small-heading Choose User
                                            .box-users
                                                .item-user(
                                                    v-for="(user,index) in list.data"
                                                    :key="user.id"
                                                    @click="selectUser(user)"
                                                )
                                                    //- figure.mr-3
                                                        img.w-6.h-6.rounded-full.object-fit(src="https://randomuser.me/api/portraits/men/41.jpg")
                                                    span {{ user.name }}

                                                //- .item-user
                                                    figure.mr-3
                                                        img.w-6.h-6.rounded-full.object-fit(src="https://randomuser.me/api/portraits/men/41.jpg")
                                                    span Raul Andrews
                                        .p-4.absolute.container-box-users(v-else-if="list.data && list.data.length == 0")
                                            | No data available.

                                .field(class="lg:mr-3 lg:mb-0 lg:w-64 mr-0 w-full mb-4")
                                    .relative.field-style.w-full
                                        date-range-picker(
                                            v-if="dateRange.startDate && dateRange.endDate" 
                                            ref="picker" 
                                            v-model="dateRange" 
                                            @update="pickerUpdate($event)" 
                                            :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }" 
                                            :date-format="dateFormat" 
                                            opens="right"
                                            :ranges="default_ranges"
                                            autoApply=true
                                        )
                                            div(
                                                slot="input" 
                                                slot-scope="picker") 
                                                    | {{ picker.startDate | date}} - {{ picker.endDate  | date}}

                                        .field-icon
                                            IconSet(type="calendar")

                                // .field(class="lg:mr-3 lg:mb-0 lg:w-64 mr-0 w-full mb-4")
                                    // .field-style(v-if="status_log_management_table.status == 'loading'")
                                    //     .field-style--loading
                                    //         Spinner(show="true" size="20")
                                    // .relative(v-else)
                                    //     select.field-style(v-model="table_choose")
                                    //         option(v-for="(label, key) in log_management_table" :key="key" :value="key") {{ label }}
                                    //     .field-icon
                                    //         IconSet(
                                    //             type="chevron-down"
                                    //         )
                                index-filter-table(v-if="checkFilterTableShown()" v-model="table_choose")

                                .field(class="lg:mr-3 lg:mb-0 lg:w-32 mr-0 w-full mb-4")
                                    select.field-style#filter-record-size(@change="sizeChange($event)" v-model="items_per_page" style="min-width:80px")
                                        option(v-for="(s,index) in size" :key="index" :value="s") {{ s }}
                                    .field-icon
                                        IconSet(
                                            type="chevron-down"
                                        )

                                button.btn-primary.mr-3(
                                    @click="filterList()"
                                ) Filter
                                button.btn-default(
                                    @click="resetList()"
                                ) Reset
                        //- end filter

                    .box-table.box-table--monitoring
                        table.table
                            thead.m-hide
                                tr
                                    th(v-for="(th,index) in tableThead" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}
                            tbody
                                tr(v-if="status_log_management.status && status_log_management.status === 'loading'")
                                    td.text-center(:colspan="tableThead.length")
                                        .flex.flex-wrap.items-center.justify-center
                                            Spinner(show="true" size="48")
                                        
                                template(v-else-if="log_management.data && log_management.data.length > 0")
                                    tr(v-for="(data,index) in log_management.data" :key="data.id")
                                        td 
                                            .text-xs {{ data.table_label || '-' }}
                                        td 
                                            button.link.flex.items-center.text-sm.text-left(
                                                v-if="data.action !== 'delete'"
                                                @click="detailTarget(data)"
                                            )
                                                span {{ data.name }}
                                            span.text-sm(v-else) {{ data.name }}
                                        td 
                                            template(v-if="data.action === 'delete'")
                                                .label-red.text-xs DELETE
                                            template(v-else-if="data.action === 'update'")
                                                button.link.text-sm.text-right(@click="dataChangeModal(true, data.data)") 
                                                    span.mr-2
                                                        .label-green.text-xs UPDATE 
                                                    span View Changes
                                            template(v-else-if="data.action === 'create'")
                                                .label-green.text-xs SAVE
                                            template(v-else-if="data.action === 'search'")
                                                button.link.text-sm.text-right(@click="searchInputModal(true, data.data)") 
                                                    span.mr-2
                                                        .label-green.text-xs SEARCH 
                                                    span View Keywords
                                        // td 
                                            template(v-if="data.action === 'update'")
                                                button.btn-icon-40(v-tooltip="'Show Data Change'" @click="dataChangeModal(true, data.data)")
                                                    IconSet(type="code")
                                        td 
                                            .text-xs {{ data.created_by?data.created_by.name:'-' }}
                                        td 
                                            .text-xs {{ data.created_at || '-' }}
                                        
                                tr(v-else)
                                    td.text-center(:colspan="tableThead.length") No Data Available
                    .box-table-footer(v-if="status_list.status !== 'loading' && log_management.data && log_management.data.length > 0")
                        .pagination
                            vue-ads-pagination(
                                :total-items="total_data"
                                :items-per-page="items_per_page"
                                :page="page"
                                :max-visible-pages="5"
                            )
                                template(slot="buttons" slot-scope="props")
                                    vue-ads-page-button(
                                        v-for="(button, key) in props.buttons"
                                        :key="key"
                                        v-bind="button"
                                        :class="{'bg-yellow-dark': button.active}"
                                        @page-change="page = button.page"
                                        @range-change="start = button.start; end = button.end"
                                    )
        

        .popup-layer.is-show(
            v-if="openDataChange"
        )
            .popup-container(style="max-width:1000px")
                .popup-container__header
                    .flex.items-center
                        h2.text-xl Data Change

                    button.btn-icon-40(
                        @click="dataChangeModal(false)"
                    )
                        IconSet( type="close")

                .popup-container__body
                    .box-table.mb-2.pb-2
                        table.table
                            tbody
                                template(v-for="(data,index) in dataChange")
                                    tr
                                        th.text-left(colspan=3) {{ data.field }}
                                    tr
                                        td
                                            template(v-if="typeof data.value_old==='object'")
                                                p(v-if="data.value_old.length==0") {{ '-' }}
                                                template(v-else-if="data.field=='photos'")
                                                    .list-target
                                                        .list-target__avatar(v-for="(data1,index1) in data.value_old")
                                                            figure.w-10.h-10.rounded-full.overflow-hidden
                                                                img.w-full.h-full(:src="data1 ? data1 : ''")
                                                p(v-else v-for="(data1,index1) in data.value_old") {{ data1 || '-' }}
                                            template(v-else) {{ data.value_old || '-' }}
                                        td.px-4 =>
                                        td
                                            template(v-if="typeof data.value_new==='object'")
                                                p(v-if="data.value_new.length==0") {{ '-' }}
                                                template(v-else-if="data.field=='photos'")
                                                    .list-target
                                                        .list-target__avatar(v-for="(data1,index1) in data.value_new")
                                                            figure.w-10.h-10.rounded-full.overflow-hidden
                                                                img.w-full.h-full(:src="data1 ? data1 : ''")
                                                p(v-else v-for="(data1,index1) in data.value_new") {{ data1 || '-' }}
                                            template(v-else) {{ data.value_new || '-' }}

        .popup-layer.is-show(
            v-if="openSearchInput"
        )
            .popup-container(style="max-width:1000px")
                .popup-container__header
                    .flex.items-center
                        h2.text-xl Search Keywords

                    button.btn-icon-40(
                        @click="searchInputModal(false)"
                    )
                        IconSet( type="close")

                .popup-container__body
                    .box-table.mb-2.pb-2
                        table.table
                            tbody
                                template(v-for="(data,index) in searchInput")
                                    tr
                                        th.text-left(colspan=3) {{ data.field }}
                                    tr
                                        td
                                            template(v-if="typeof data.value_new==='object'")
                                                p(v-if="data.value_new.length==0") {{ '-' }}
                                                template(v-else-if="data.field=='photos'")
                                                    .list-target
                                                        .list-target__avatar(v-for="(data1,index1) in data.value_new")
                                                            figure.w-10.h-10.rounded-full.overflow-hidden
                                                                img.w-full.h-full(:src="data1 ? data1 : ''")
                                                p(v-else v-for="(data1,index1) in data.value_new") {{ data1 || '-' }}
                                            template(v-else) {{ data.value_new || '-' }}
</template>

<script>
import moment from 'moment';
import IconSet from '@/components/IconSet.vue';
import DateRangePicker from 'vue2-daterange-picker';
import { mapState } from 'vuex';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
import Spinner from '@/components/Spinner.vue';
import JsonCSV from 'vue-json-csv'
import Loading from '@/components/Loading.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';
// import VueJsonPretty from 'vue-json-pretty'
// import 'vue-json-pretty/lib/styles.css'

import { env } from '@/config/env';
import IndexFilterTable from '@/pages/logManagement/components/index-filter-table.vue';

export default {
    name: 'PageLogManagement',
    components: {
        IconSet,
        Spinner,
        VueAdsPagination,
        VueAdsPageButton,
        DateRangePicker,
        JsonCSV,
        Loading,
        NoDataAvailable,

        IndexFilterTable,
    },
    data() {
        return {
            show_table_filter: env.LOG_MANAGEMENT__INDEX__FILTER_TABLE__SHOWN || false,
            roles_bypass_table_filter: env.LOG_MANAGEMENT__INDEX__FILTER_TABLE__ROLES_BYPASS || [],

            showFilter: false,
            ShowResponseCode: false,
            filterUser: '',
            optFilterUser: [],

            from_date: '',
            to_date: '',
            dateRange: {
                startDate: '',
                endDate: '',
            },
            default_ranges: {
                'Today': [moment().toDate(), moment().toDate()],
                'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                'Last 7 days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                'Last week': [moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(), moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate()],
            },
            total_data: 0,
            items_per_page: 10,

            size: [10, 25, 50, 100],

            page: 0,
            start: 0,
            end: 0,

            keyword: '',
            timeout: null,

            user_keyword:'',
            user_id:null,
            user_selected:false,

            csvName: 'log_management'+moment().format('YYYY-MM-DD_HH:mm:ss')+'.csv',
            csvData: [],
            
            openResponse: false,
            tmpLogCode:null,
            tmpLogMessage:null,

            table_choose: '',

            openDataChange: false,
            dataChange: null,

            openSearchInput: false,
            searchInput: null,            
        };
    },
    computed: {
        ...mapState('auth', ['user']),
        ...mapState('users', [
            'status_list',
            'list',
        ]),
        ...mapState('logManagement', [
            'status_log_management',
            'log_management',
            // 'status_log_management_table',
            // 'log_management_table',
        ]),
        route() {
            return this.$route;
        },
        route_name() {
            return this.$route.name;
        },
        tableThead(){ 
            let thead = [
                {
                    name: 'Table',
                },
                {
                    name: 'Name',
                },
                {
                    name: 'Action',
                },
                // {
                //     name: 'Data Change',
                // },
                {
                    name: 'Created By',
                },
                {
                    name: 'Created At',
                },
            ];
            // if(this.user.role.label === 'superadmin'){
            //     thead.unshift({
            //         name: 'Response',
            //     })
            // }

            return thead;
        }
    },
    watch: {
        route_name(newData, oldData) {
            if (newData === 'pageLogManagement') {
                if (oldData !== 'pageLogManagement') {
                    this.items_per_page = 10;
                    this.page = 0;
                    this.keyword = '';
                    this.$store.commit('users/setDetail', {});
                    this.$store.commit('logManagement/setLogManagementData', {});
                }

                this.getData(true);
            }
        },
        log_management(newData) {
            if (Object.keys(newData).length > 0) {
                this.total_data = parseInt(newData.total_data);
                // this.page = (parseInt(newData.current_page) - 1);
            }
            this.getDataCsv(newData.data)

        },
        total_data() {
            // this.pagination();
        },
        // items_per_page() {
            // this.getData();
            // this.pagination();
        // },
        page() {
            this.getData();
        },
        keyword() {},
        user_keyword(newData) {
            if (newData.length < 3)
                this.$store.commit('users/setList', {});
        },
    },
    methods: {
        checkFilterTableShown() {
            if (!Object.keys(this.user).length > 0) 
                return false

            if (!this.user.role)
                return false

            if (this.roles_bypass_table_filter) 
                if (this.roles_bypass_table_filter.includes(this.user.role.label))
                    return true

            return this.show_table_filter
        },
        detailTarget(data) {
            if (data.route){
                this.$router.push({ name: data.route, params: { id: data.table_id}  })
                return
            } 
            
            EventEmit.$emit('error', 'Not Found')
            
        },
        getData() {
            this.$store.commit('users/setList', {});
            // for dispatch get data
            // this.$store.dispatch('logManagement/getListTable');
            this.$store.dispatch('logManagement/getList', [this.items_per_page, (this.page + 1), this.keyword, this.from_date, this.to_date, '', this.table_choose, this.user_id]);

        },
        getDataCsv(data) {
            // let role = this.user.role.label
            if (data.length > 0) {
                this.csvData = data.map((item) => {
                    return {
                        status: (item.response.code === 200 || item.response.code === 201)?'success':'failed',
                        user: item.user?item.user.name:'-',
                        key: item.key?item.key:'-',
                        type: item.type?item.type:'-',
                        from_date: item.from_date?item.from_date:'-',
                        to_date: item.to_date?item.to_date:'-',
                        module: item.function?this.getModule(item.function):'-',
                        searched_on: item.created_at?item.created_at.split('+')[0]:'-',
                        browser: item.browser?item.browser:'-',
                        platform: item.os?item.os:'-',
                        ip: item.ip?item.ip:'-',
                    };
                })
            } else {
                this.csvData = [{
                    status: '-',
                    user: '-',
                    key: '-',
                    type: '-',
                    from_date: '-',
                    to_date: '-',
                    module: '-',
                    searched_on: '-',
                    browser: '-',
                    platform: '-',
                    ip: '-',
                }];
            }
        },
        pageChange(page) {
            this.page = page;
        },
        sizeChange() {
            this.page = 0;
        },
        datetimeFormat(datetime) {
            return moment(datetime, 'YYYY-MM-DDTHH:mm:ssZ').format('DD MMM YYYY HH:mm:ssZ');
        },
        /* eslint-disable no-unused-vars */
        dateFormat (classes, date) {
            if (!classes.disabled) {
                classes.disabled = moment(date.getTime()).subtract({ hours: 12})._d > new Date();
            }
            return classes;
        },
        getResponse(open=true, log=null){
            if (open) {
                EventEmit.$emit('overflowHidden', true);
                this.dataChange = log.code || null
                this.tmpLogMessage = null

                var msg = log.message || log.data
                if (msg) {
                    this.tmpLogMessage = msg
                }
                this.openResponse = true
            } else {
                EventEmit.$emit('overflowHidden', false);
                this.tmpLogCode = null
                this.tmpLogMessage = null
                this.openResponse = false
            }
        },
        dataChangeModal(open=true, data=null){
            if (open) {
                EventEmit.$emit('overflowHidden', true);
                this.dataChange = data
                this.openDataChange = true
            } else {
                EventEmit.$emit('overflowHidden', false);
                this.dataChange = null
                this.openDataChange = false
            }
        },
        searchInputModal(open=true, data=null){
            if (open) {
                EventEmit.$emit('overflowHidden', true);
                this.searchInput = data
                this.openSearchInput = true
            } else {
                EventEmit.$emit('overflowHidden', false);
                this.searchInput = null
                this.openSearchInput = false
            }
        },
        resetList() {
            this.$swal.fire({
                icon: 'warning',
                title: 'Are you sure you want to reset filter?',
                confirmButtonText: 'Yes!',
                confirmButtonColor: '#DD6B55',
                showCancelButton: true,
                allowOutsideClick: false,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.defaultFilter()
                    this.getData()
                }
            });
        },
        filterList() {
            this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');
            
            this.getData();
        },
        defaultFilter(){
            let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
            const default_date = this.$store.getters['config/getDefaultDate'](def);

            this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
            this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();
            this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');

            this.user_keyword=''
            this.user_id=null
            this.items_per_page=10
        },
        async pickerUpdate(e) {
            // console.log('========= DateRangePicker on update')
            // console.log(e)
            let ranges = moment(e.endDate.getTime()).diff(moment(e.startDate.getTime()), 'days') + 1;

            if (ranges > this.$store.getters['config/getValidateRange']) {
                let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
                const default_date = this.$store.getters['config/getDefaultDate'](def);
                
                this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
                this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();
    
                this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');
                
                this.$swal.fire({
                    icon: 'error',
                    title: 'Date range is not more than ' + this.$store.getters['config/getValidateRange'] + ' days!',
                    text: 'Your date range is ' + ranges + ' days.',
                })

                // return;
            } else {
                this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');
                this.page = 0;
            }
        },
        searchUser() {
            // clear timeout variable
            clearTimeout(this.timeout);

            var self = this;
            this.timeout = setTimeout(function () {
                if (self.user_keyword.length > 2) {
                    self.$store.commit('users/setList', {});
                    self.$store.dispatch('users/getList', [30, 1, self.user_keyword]);
                }
            }, 650);
        },
        selectUser(user){
            this.user_keyword = user.name
            this.user_id = user.id
            this.$store.commit('users/setList', {});
            this.user_selected = true
        },
        setEmptyUser() {
            this.user_keyword = ''
            this.user_id=null
            this.user_selected = false
        },
        getModule(string) {
            let def = '-'
            string = string.toLowerCase()
            console.log("+++getModule|string: ", string)
            switch(string) {
                case 'kk':
                case 'ktp':
                    def = 'Digital ID';
                    break;
                case 'recent-location':
                    def = 'Recent Location';
                    break;
                case 'subheader':
                case 'subheader-subintel':
                case 'subheader-target-lbs':
                case 'subheader-target':
                    def = 'Summary Data';
                    break;
                case 'subheader-name':
                    def = 'Profile Name';
                    break;
                case 'app-usage':
                    def = 'App Usage';
                    break;
                case 'browsing-all':
                    def = 'Browsing Log';
                    break;
                case 'device-history':
                    def = 'Device History';
                    break;
                case 'aerial':
                    def = 'Aerial Log';
                    break;
                case 'location-log':
                case 'location-aerial-log':
                    def = 'Location Log';
                    break;
                case 'tethered-device':
                    def = 'Tethered Device';
                    break;
                case 'location-movement':
                    def = 'Location Movement';
                    break;
                case 'history-device-roles':
                    def = 'History Device Roles';
                    break;
                case 'prediction-name':
                    def = 'Prediction Name';
                    break;
                case 'recent-location-detail':
                    def = 'Detail Target - Recent Location';
                    break;
                case 'personal-data':
                    def = 'Digital Trace';
                    break;
                case 'fr':
                case 'fr-find':
                case 'fr-new-search':
                    def = 'Face Recognition';
                    break;
            }
            return def
        },
    },
    // created() {
    //     this.defaultFilter()
    //     this.getData();
    // },
    mounted() {
        let status_log_managementTmp = this.status_log_management
        let log_managementTmp = this.log_management
        let status_listTmp = this.status_list
        let listTmp = this.list
        console.log({status_log_managementTmp,log_managementTmp,status_listTmp,listTmp})
        // if (this.route_name === 'pageMonitoringLog') {
            // reset date filter
        this.defaultFilter()
        this.getData();
        // }
    },
    filters: {
        date: function (date) {
            return moment(date).format('YYYY-MM-DD');
        },
    },
}
</script>

<style lang="sass" scoped>
    .box-table--monitoring
        tr
            th, td
                @apply .whitespace-no-wrap

    .box-find
        width: 100%
        max-width: 400px
        background-color: $color-secondary-500
        border-radius: 4px
        position: relative

    .container-box-users
        top: 100%
        z-index: 100
        background-color: $color-secondary-700
        width: 100%

    .box-users
        .item-user
            @apply .flex .items-center .p-2 .border-b .cursor-pointer
            &:hover 
                background-color: $color-secondary-600
            &:last-child
                border-bottom: 0px
    .box-find-selected
        background-color: $color-secondary-600
        padding-top: 2px
        @apply .absolute .inset-0 .rounded .px-2 .border
        border-color: #2d3061

</style>