<template lang="pug">
.box__body
    .card.card--table.card--no-bg
        .card__header.card--table__header
            h2.text-xl {{ page_title }}
            .header-cta
                .block
                    router-link.btn-default.m-btn-icon(to="/case-management")
                        IconSet(type="chevron-left")
                        span.m-hide.block.ml-3 Back
        .card__body
            .container-form
                .group-fields.border-b
                    .field
                        .field__label
                            label.block.text-sm.font-bold Case Name <sup>*</sup>
                        .field__form
                            input.field-style(type="text" placeholder="Write case name here" v-model="model.operation_name")
                            .pt-3
                                .message.text-xs.text-red-500(v-if="$v.model.operation_name.$error") This operation_name is required &amp; max length is 255 char.
                                .message.text-xs.text-red-500(v-if="error_operation_name !== ''") {{ error_operation_name }}

                            //- input.field-style(type="text" placeholder="Write name here")
                            //- .py-3
                            //-     .message.text-xs.text-red-500 Message

                    .field
                        .field__label
                            label.block.text-sm.font-bold Location <sup>*</sup>
                        .field__form
                            input.field-style(type="text" placeholder="Write location here" v-model="model.location")
                            .pt-3
                                .message.text-xs.text-red-500(v-if="$v.model.location.$error") This location is required &amp; max length is 255 char.
                                .message.text-xs.text-red-500(v-if="error_location !== ''") {{ error_location }}
                    .field
                        .field__label
                            label.block.text-sm.font-bold Assign to <sup>*</sup>
                        .field__form

                            .relative(v-if="status_users.status === 'loading'")
                                .field-style
                                    .field-style--mirroring.field-style--loading
                                        Spinner(show="true" size="32")
                            template(v-if="is_operator")
                                input.field-style.field-style--disabled(
                                :disabled="true"
                                type="text"
                                v-model="user.name"
                            )

                            template(v-else)
                                .field-multiple-select(v-if="list_users.data !== undefined && Object.keys(list_users.data).length > 0")
                                    multiselect(
                                        :value="completeValue",
                                        :options="filteredUsers ? filteredUsers : []",
                                        :multiple="true",
                                        placeholder="Select users",
                                        label="name",
                                        :taggable="true",
                                        track-by="id",
                                        @input="onChange"
                                    )
                                label.italic.block.text-sm.font-bold(v-else) You have no users under you
                            .pt-3
                                .message.text-xs.text-red-500(v-if="$v.model.assign_id.$error") This assign_id is required.
                                .message.text-xs.text-red-500(v-if="error_assign_id !== ''") {{ error_assign_id }}

                .group-fields.border-b
                    .field
                        .field__label
                            label.block.text-sm.font-bold Status <sup>*</sup>
                        .field__form
                            .flex.mt-2
                                .ui-radio.mr-8
                                    input(type="radio" name="status" value="1" v-model="model.status")
                                    .style-radio
                                        .radio
                                        span Active

                                .ui-radio
                                    input(type="radio" name="status" value="0" v-model="model.status")
                                    .style-radio
                                        .radio
                                        span Inactive
                            .pt-3
                                .message.text-xs.text-red-500(v-if="$v.model.status.$error") This status is required.
                                .message.text-xs.text-red-500(v-if="error_status !== ''") {{ error_status }}
                    .field
                        .field__label
                            label.block.text-sm.font-bold Description
                        .field__form
                            textarea.field-style(v-model="model.description" placeholder="Write description here...")
                            .pt-3
                                .message.text-xs.text-red-500(v-if="$v.model.description.$error") This description is required.
                                .message.text-xs.text-red-500(v-if="error_description !== ''") {{ error_description }}

                .flex.items-center.justify-end.mb-8
                    button.btn.btn-default(type="button" @click="reset()")
                        | Reset
                    button.btn-primary-o.ml-2(type="submit" @click="submit($event, true)")
                        | Save &amp; Continue
                    button.btn.btn-primary.ml-4(type="submit" @click="submit($event)")
                        | Save

</template>

<script>
import IconSet from '@/components/IconSet.vue';
import Spinner from '@/components/Spinner.vue';
import Multiselect from 'vue-multiselect';
import { mapState } from 'vuex';
import { required, maxLength } from "vuelidate/lib/validators";

export default {
    name: 'pageTargetCreate',
    components: {
        IconSet,
        Spinner,
        Multiselect,
    },
    data() {
        return {
            model: {
                operation_name: '',
                location: '',
                assign_id: [],
                status: '',
                description: '',
            },

            // error message
            error_operation_name: '',
            error_location: '',
            error_assign_id: '',
            error_status: '',
            error_description: '',
            is_operator: false,
            is_superadmin: false,
        }
    },
    validations: {
        model: {
            operation_name: {
                required,
                maxLength: maxLength(255)
            },
            location: {
                required,
                maxLength: maxLength(255)
            },
            assign_id: {
                required: function() {
                    return this.is_superadmin || this.model.assign_id.length > 0;
                },
            },
            status: {
                required,
            },
            description: {
                // 
            },
        },
    },
    computed: {
        ...mapState('auth', ['user']),
        ...mapState({
            status_detail_case: (state) => state.caseManagement.status_detail,
            detail_case: (state) => state.caseManagement.detail,
            status_users: (state) => state.users.status_list,
            list_users: (state) => state.users.list
        }),
        route_name() {
            return this.$route.name;
        },
        id() {
            return this.$route.params.id;
        },
        page_title() {
            let title = 'Edit Case Management';
            if (this.route_name === 'pageCaseManagementCreate')
                title = 'Add Case Management';
            return title;
        },
        filteredUsers() {
            if (this.list_users.data && this.list_users.data.length > 0) {
                return this.list_users.data.filter(user => {
                    return ('superadmin' != user.role.label && 'admin' != user.role.label)
                });
            }
            return []
        },
        completeValue: {
            get() {
                if (!this.model.assign_id) return null
                // console.log('=====onChange:get')
                // console.log(this.model.assign_id)
                try {
                    return this.model.assign_id.map(value => this.findOption(value.id)).filter(value => value.id)
                } catch (error) {
                    return []
                }
            },
            set(v) {
                // console.log('=====completeValue:set')
                // console.log(v)
                this.model.assign_id=v.map(value => {
                    let rObj = {}
                    rObj['id'] = value.id
                    return rObj
                })
            }
        },
        isSuperadmin(){
            return this.user && this.user.role ? this.user.role.label === 'superadmin' : false
        }
    },
    watch: {
        route_name() {
            this.getData();
        },
        id() {},
        page_title() {},
        // form data
        detail_case(newData) {
            this.model.operation_name = (newData.operation_name ? newData.operation_name : '');
            this.model.location = (newData.location ? newData.location : '');
            if (!this.is_operator || this.route_name === 'pageCaseManagementEdit') {
                this.model.assign_id = (newData.assign_id ? newData.assign_id : []);
            }
            this.model.status = (newData.status ? (newData.status.toLowerCase() === 'active' ? '1' : '0') : '');
            this.model.description = (newData.description ? newData.description : '');
        },
        // status_users(newData) {
        //     if (newData.status === 'loading') {
        //         EventEmit.$emit('showLoading', true);
        //     } else {
        //         EventEmit.$emit('showLoading', false);
        //     }
        // },
        is_superadmin(newVal) {
            this.updateAssignIdValidation();
        },
    },
    methods: {
        updateAssignIdValidation() {
            this.$v.model.assign_id.$touch();
        },
        setEmptyModel(all=true) {
            for (let key in Object.keys(this.model)) {
                if (all === true)
                    this.model[Object.keys(this.model)[key]] = '';
            }
        },
        setErrors(empty=true, errors=null) {
            if (empty === true) {
                this.error_operation_name = '';
                this.error_location = '';
                this.error_assign_id = '';
                this.error_status = '';
                this.error_description = '';
            } else if (empty !== true && errors !== null) {
                this.error_operation_name = (errors.operation_name ? errors.operation_name : '');
                this.error_location = (errors.location ? errors.location : '');
                this.error_assign_id = (errors.assign_id ? errors.assign_id : '');
                this.error_status = (errors.status ? errors.status : '');
                this.error_description = (errors.description ? errors.description : '');
            }
        },
        getData() {
            this.reset(true);

            // get client list
            if (this.route_name === 'pageCaseManagementEdit' || this.route_name === 'pageCaseManagementCreate') {
                if(this.isSuperadmin){
                    this.$store.dispatch('users/getList', [100, 1]);
                } else {
                    this.$store.dispatch('users/getList', [10, 1]);
                }
            }

            if (this.route_name === 'pageCaseManagementEdit') {
                EventEmit.$emit('showLoading', true);

                this.$store.dispatch('caseManagement/getDetail', this.id)
                .then((resp) => {
                    // console.log({resp})
                    if (resp.result === 'success') {
                        EventEmit.$emit('showLoading', false);
                    } else {
                        var code = resp.code || null 
                        var message = null;

                        let statusCodeCheck = ["403", 403]
                        if (statusCodeCheck.includes(code))
                            message = resp && resp.data && resp.data.message? resp.data.message : null;
                        EventEmit.$emit('error', [message]);
                    }
                });
            } else {
                if (this.route_name === 'pageCaseManagementCreate')
                    this.$store.commit('caseManagement/setDetail', {});
            }
        },
        reset(direct=false) {
            if (direct === true) {
                this.setEmptyModel(false);
                // reset vuelidate
                this.$nextTick(() => { this.$v.$reset(); });
                this.setErrors(true);
                window.scrollTo(0,0);
            } else {
                this.$swal.fire({
                    icon: 'warning',
                    title: 'Are you sure you want to reset form?',
                    // text: id,
                    confirmButtonText: 'Yes!',
                    confirmButtonColor: '#DD6B55',
                    showCancelButton: true,
                    allowOutsideClick: false,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        if (this.route_name === 'pageCaseManagementCreate') {
                            this.setEmptyModel(false);
                            // reset vuelidate
                            this.$nextTick(() => { this.$v.$reset(); });
                            this.setErrors(true);
                            window.scrollTo(0,0);
                        } else {
                            this.getData();
                        }
                    }
                });
            }
        },
        submit(e, save_continue=false) {
            e.preventDefault();
            this.$v.$touch();

            if (this.$v.model.$invalid) {
                for (let key in Object.keys(this.$v.model)) {
                    const input = Object.keys(this.$v.model)[key];
                    if (input.includes("$")) return false;
                    if (this.$v.model[input].$error) {
                        break;
                    }
                }
                // EventEmit.$emit('error', 'Please fill the form correctly.');
                // EventEmit.$emit('showLoading', false);
                window.scrollTo(0,0);
                return;
            }
            
            EventEmit.$emit('showLoading', true);

            let model_data = Object.keys(this.model).map(
                (key) => (key !== 'assign_id')?this.model[key]:[JSON.stringify(this.model[key])]
            );

            if (this.route_name === 'pageCaseManagementCreate') {

                this.$store.dispatch('caseManagement/create', model_data)
                .then((resp) => {
                    if (resp.result === 'success') {
                        if (save_continue === false) {
                            this.$router.push('/case-management');
                        } else {
                            this.$router.push('/case-management/'+ resp.data.data.id +'/edit');
                        }

                        this.$swal.fire({
                            icon: 'success',
                            title: 'Data successfully created!',
                            timer: 3000,
                        });
                    } else {
                        if (resp.data.message && resp.data.message.errors) {
                            this.setErrors(false, resp.data.message.errors);
                            // EventEmit.$emit('error', null, resp.data.message.errors);
                            EventEmit.$emit('showLoading', false);
                            window.scrollTo(0,0);
                        } else {
                            var code = resp.code || null 
                            var message = null;

                            let statusCodeCheck = ["403", 403]
                            if (statusCodeCheck.includes(code))
                                message = resp && resp.data && resp.data.message? resp.data.message : null;
                            EventEmit.$emit('error', [message]);
                        }
                    }
                });

            // pageCaseManagementEdit
            } else {

                model_data = [this.id].concat(model_data);

                this.$store.dispatch('caseManagement/update', model_data)
                .then((resp) => {
                    if (resp.result === 'success') {                        
                        if (save_continue === false)
                            this.$router.push('/case-management');

                        this.$swal.fire({
                            icon: 'success',
                            title: 'Data successfully updated!',
                            timer: 3000,
                        });
                    } else {
                        if (resp.data.message && resp.data.message.errors) {
                            this.setErrors(false, resp.data.message.errors);
                            // EventEmit.$emit('error', null, resp.data.message.errors);
                            EventEmit.$emit('showLoading', false);
                            window.scrollTo(0,0);
                        } else {
                            var code = resp.code || null 
                            var message = null;

                            let statusCodeCheck = ["403", 403, "430", 430]
                            if (statusCodeCheck.includes(code))
                                message = resp && resp.data && resp.data.message? resp.data.message : null;
                            EventEmit.$emit('error', [message]);
                        }
                    }
                });
            }
        },
        findOption(value) {
            // console.log('=====findOption')
            // console.log(value)
            const opt = this.filteredUsers
            return opt.find(option => option['id'] === value)
        },
        onChange(value) {
            this.completeValue = value
        },
    },
    created() {
        if ( this.user?.role?.label && this.user.role.label === 'operator') {
            this.is_operator = true
            if (this.route_name === 'pageCaseManagementCreate') {                
                this.model.assign_id.push({
                    id: this.user.id
                })
            }
        }
        if ( this.user?.role?.label && this.user.role.label === 'superadmin') {
            this.is_superadmin = true
        }
    },
    mounted() {
        this.getData();
    },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="sass" scoped>
    .fields-box
        @apply .rounded
        background-color: $color-secondary-600
        &__item
            @apply .p-4 .rounded .flex .border-b
            &:last-child
                border-bottom: 0px
            .field-options
                input
                    margin-top: 1rem
                    &:first-child
                        margin-top: 0px
    .targets-case
        @apply .w-full
        .target-item
            @apply .flex .items-center .p-3 .w-full .rounded .cursor-pointer
            transition: all 0.3s ease-in-out
            &:hover
                background-color: $color-secondary-800

</style>
