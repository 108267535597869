import axios from "@/axios-main";
import axiosOrigin from "axios";
import { env } from '@/config/env';

let cancelList, cancelActivityList;
var cancelToken = axiosOrigin.CancelToken;

const clients = {
  namespaced: true,
  state: {
    // load data clients
    status_list: {
      status: false,
      cancel: null,
      code: null,
    },
    status_detail: false,
    status_log_activity: {
      status: false,
      cancel: null,
      code: null,
    },

    // clients
    list: {},
    detail: {},
    log_activity: {},
  },
  mutations: {
    // load data clients
    setStatusList: (state, status) => {
      state.status_list = status;
    },
    setStatusDetail: (state, status) => {
      state.status_detail = status;
    },
    setStatusLogActivity: (state, status) => {
      state.status_log_activity = status;
    },

    // clients
    setList: (state, data) => {
      state.list = data;
    },
    setDetail: (state, data) => {
      state.detail = data;
    },
    setLogActivity: (state, data) => {
      state.log_activity = data;
    },
  },
  getters: {},
  actions: {
    async getList({ state, commit, dispatch }, [limit=null, page=null, keyword=null, order_by=null, from_date=null, to_date=null]) {

      if (state.status_list.cancel !== null && state.status_list.status === 'loading')
        await state.status_list.cancel('Operation canceled by the user.');

      let params = {
        limit: limit,
        page: page,
        keyword: keyword,
        order_by: order_by,
        from_date: from_date,
        to_date: to_date,
      };
    
      axios.get('api/v1/client/list', {
        params: params,
        cancelToken: new cancelToken(function executor(c) {
          cancelList = c;
          commit('setStatusList', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setList', data);

        if (Object.keys(data).length===0)
          result.status = 'empty';

        commit('setStatusList', result);
      })
      .catch((resp) => {
        // console.log(resp)
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelList;
        }

        commit('setStatusList', result);
        if (!axiosOrigin.isCancel(resp)) 
          dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
      });
    },

    getDetail({ commit, dispatch }, id) {

      let params = new FormData();
      params.append('id', id);
    
      return axios.post('api/v1/client', params)
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        commit('setDetail', data);
        return 'success';
      })
      .catch((resp) => {
        // console.log(resp)
        dispatch('errors/handleApiResponse', [(resp.response && resp.response.status ? resp.response.status : null)], { root: true });
        return 'failed';
      });
    },

    getLogActivity({ commit, dispatch }, [limit='', page='', keyword='', order_by='', from_date='', to_date='', user_id='']) {
      commit('setStatusLogActivity', 'loading');

      let params = new FormData();
      params.append('limit', limit);
      params.append('page', page);
      params.append('keyword', keyword);
      params.append('order_by', order_by);
      params.append('from_date', from_date);
      params.append('to_date', to_date);
      params.append('user_id', user_id);
    
      const urlPath = env.VUE_APP_API_VERSION_LOG_MANAGEMENT == 'v2' ? 'v2/data-log/log-activity/list-client' : 'api/v1/log-activity/list-client';
      axios.post(urlPath, params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelActivityList = c;
          commit('setStatusLogActivity', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };
        commit('setLogActivity', data);

        if (Object.keys(data).length===0) {
          result.status = 'empty';
        }

        commit('setStatusLogActivity', result);
      })
      .catch((resp) => {
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelActivityList;
        }
        commit('setStatusLogActivity', result);
        dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
      });
    },

    create({ commit, dispatch }, [name=null, email=null, label=null, key=null, secret=null, status=null, description=null]) {

      let params = new FormData();
      params.append('name', name);
      params.append('email', email);
      params.append('label', label);
      params.append('key', key);
      params.append('secret', secret);
      params.append('status', status);
      params.append('description', description);

      return axios.post('api/v1/client/create', params)
      .then((resp) => {

        if (resp.status === 200 && resp.data.message === 'Data Successfully Created') {
          return {result: 'success', data: resp.data};
        } else {
          return {result: 'failed', data: resp.data};
        }
      })
      .catch((resp) => {
        // console.log(resp)
        dispatch('errors/handleApiResponse', [(resp.response && resp.response.status ? resp.response.status : null)], { root: true });
        return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
      });
    },

    update({ commit, dispatch }, [id, name=null, email=null, label=null, key=null, secret=null, status=null, description=null]) {

      let params = new FormData();
      params.append('id', id);
      params.append('name', name);
      // params.append('email', email);
      params.append('label', label);
      params.append('key', key);
      params.append('secret', secret);
      params.append('status', status);
      params.append('description', description);
    
      return axios.post('api/v1/client/update', params)
      .then((resp) => {
        // console.log(resp.data)
        
        if (resp.status === 200 && resp.data.message === 'Data Successfully Updated') {
          return {result: 'success', data: resp.data};
        } else {
          return {result: 'failed', data: resp.data};
        }
      })
      .catch((resp) => {
        // console.log(resp)
        dispatch('errors/handleApiResponse', [(resp.response && resp.response.status ? resp.response.status : null)], { root: true });
        return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
      });
    },

    delete({ commit, dispatch }, id) {

      let params = new FormData();
      params.append('id', id);

      return axios.post('api/v1/client/delete', params)
      .then((resp) => {
        // console.log(resp)
        
        if (resp.status === 200 && resp.data.message === 'Data Successfully Deleted') {
          return 'success';
        } else {
          return 'failed';
        }
      })
      .catch((resp) => {
        // console.log(resp)
        dispatch('errors/handleApiResponse', [(resp.response && resp.response.status ? resp.response.status : null)], { root: true });
        return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
      });

    },
  }
};

export default clients;
