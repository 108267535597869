<template lang="pug">
.box__body
    .card
        .card__body
            .flex.flex-wrap(
                v-if="status_location_es_list.status === 'loading'"
                class="lg:-mx-2"
            )
                Loading(show="true")
            .flex.flex-wrap(v-else)
                template(v-if="Object.keys(location_es_list).length > 0 && location_es_list.data && location_es_list.data.length > 0 && mapLoaded")
                    .block(class="w-full lg:w-12/12" style="height: 450px;")

                        l-map(v-if="mapVendor === 'osm'" ref="mapRefOSM" :id="idOsm" :zoom="10" :center="center" :options="options" style="height: 100%; width: 100%;")
                            l-tile-layer(:url="url" :attribution="attribution")
                            l-marker(v-for="(location, index) in locations" :lat-lng="location.position" v-bind:key="index" :icon="getMarkerOsmIcon(location)")
                                l-popup(:content="location.name")
                            l-polyline(v-if="polylineOsm.latlngs.length > 0" :lat-lngs="polylineOsm.latlngs" :color="polylineOsm.color")
                        
                        gmap-map(v-else ref="mapRef" :center="center" :zoom="10" :options="options" style="width:100%; height: 100%;")

                .block.box-map(v-else)
                    NoDataAvailable

    .card.card--table.card--no-bg
        .card--table__body
            //- filter
            .box-filter
                //- .box-filter.px-4.pt-6
                //- showing data information mobile
                .m-only
                    .filter-info
                        .flex.items-center.justify-between.mb-3
                            Strong.uppercase.text-xs.font-bold Filtered by
                            button.btn-default-o.btn-small(
                                type='button'
                                v-on:click="showFilter = !showFilter"
                                ) Change Filter
                        p {{ dateRange.startDate }} -  {{ dateRange.endDate }}

                //- end showing data information mobile
                .mobile-filter(
                    v-bind:class="{ 'is-show' : showFilter}"
                )
                    .m-only
                        .flex.items-center.justify-between.p-4.border-b
                            h2.text-xl Filter
                            button.btn-icon-40(
                                v-on:click="showFilter = !showFilter"
                            )
                                IconSet(type="close")
                    .items-filter
                        .field.mr-3
                            .relative.mr-3.field-style
                                date-range-picker(
                                    v-if="dateRange.startDate && dateRange.endDate" 
                                    ref="picker" 
                                    v-model="dateRange" 
                                    @update="pickerUpdate($event)" 
                                    :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }" 
                                    :date-format="dateFormat" 
                                    opens="right"
                                    :ranges="default_ranges"
                                    autoApply=true
                                )
                                    div(
                                        slot="input" 
                                        slot-scope="picker") 
                                            | {{ picker.startDate | date}} - {{ picker.endDate  | date}}

                                .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2
                                    IconSet(type="calendar")

                        .field.mr-3
                            select.field-style#filter-record-size(@change="sizeChange($event)" v-model="type_data" style="min-width:80px")
                                    option(v-for="(item,index) in additional" :key="item" :value="item") {{ titleCase(item) }}
                            .field-icon
                                IconSet(
                                    type="chevron-down"
                                )
                        .field.mr-3
                            select.field-style#filter-record-size(@change="sizeChange($event)" v-model="items_per_page" style="min-width:80px")
                                    option(v-for="(s,index) in size" :key="index" :value="s") {{ s }}
                            .field-icon
                                IconSet(
                                    type="chevron-down"
                                )
                                
                        .block(v-if="location_es_list.data && location_es_list.data.length > 0")
                            .flex.flex-wrap.items-center.justify-between
                                .field.mr-3.relative
                                JsonCSV(
                                    :data="csvData"
                                    :name="csvName"
                                    class="btn btn-primary"
                                )
                                    | Download CSV
            //- end filter
        .box-table.box-table--target
            table.table
                thead.m-hide
                    tr
                        th(v-for="(th,index) in tableThead" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}
                tbody
                    tr(v-if="status_location_es_list.status === 'loading'")
                        td.text-center(:colspan="tableThead.length")
                            .flex.flex-wrap.items-center.justify-center
                                Spinner(show="true" size="48")
                    
                    template(v-else-if="location_es_list.data && location_es_list.data.length > 0")
                        tr(v-for="(data,index) in location_es_list.data" :key="data.id")
                            //- td {{getTargetName(data.features[0].properties.msisdn)}}
                            td {{ data.features && data.features[0].properties && data.features[0].properties.msisdn ? data.features[0].properties.msisdn : '-' }}
                            td 
                                small {{ data.features && data.features[0].properties && data.features[0].properties.alamat ? data.features[0].properties.alamat : '-' }}
                            td 
                                small {{ data.features && data.features[0].properties && data.features[0].properties.bts ? data.features[0].properties.bts : '-' }}
                            td 
                                small {{ data.features && data.features[0].properties && data.features[0].properties.tac ? data.features[0].properties.tac : '-' }}
                            td 
                                small {{ data.features && data.features[0].properties && data.features[0].properties.lac ? data.features[0].properties.lac : '-' }}
                            td 
                                small {{ data.features && data.features[0].properties && data.features[0].properties.enb ? data.features[0].properties.enb : '-' }}
                            td 
                                small {{ data.features && data.features[0].properties && data.features[0].properties.ci ? data.features[0].properties.ci : '-' }}

                                //- small
                                    template(v-if="data.features")
                                        template(v-if="(data.features[0].properties.enb && data.features[0].properties.enb.length > 0) && (data.features[0].properties.ci && data.features[0].properties.ci.length > 0)")
                                            | {{ data.features[0].properties.enb }} {{ data.features[0].properties.ci }}

                                        template(v-else-if="(data.features[0].properties.enb && data.features[0].properties.enb.length > 0) && (!data.features[0].properties.ci || !data.features[0].properties.ci.length > 0)")
                                            | {{ data.features[0].properties.enb }}

                                        template(v-else-if="(data.features[0].properties.ci && data.features[0].properties.ci.length > 0) && (!data.features[0].properties.enb || !data.features[0].properties.enb.length > 0)")
                                            | {{ data.features[0].properties.ci }}

                                        template(v-else)
                                            | -
                                    template(v-else)
                                        | -

                            //- td 
                                small {{ data.features && data.features[0].properties && data.features[0].properties.imei ? data.features[0].properties.imei : '-' }}
                            td 
                                small {{ data.features && data.features[0].properties && data.features[0].properties.state ? data.features[0].properties.state : '-' }}
                            td 
                                small {{ data.features && data.features[0].properties && data.features[0].properties.dtm ? datetimeFormat(data.features[0].properties.dtm) : '-' }}
                            td 
                                small {{ data.features && data.features[0].properties && data.features[0].properties.requested_at ? data.features[0].properties.requested_at : '-' }}
                            td.text-center
                                div.inline-flex.items-center
                                    template(v-if="data.features[0].geometry.coordinates[0]")
                                        a.btn-small.btn-default.ml-2(:href="'https://www.google.com/maps?q='+data.features[0].geometry.coordinates[1]+','+data.features[0].geometry.coordinates[0]" target='_blank' v-tooltip="'Google Maps'")
                                            IconSet(type="maps")
                                    template(v-else)
                                        | -
                    //- template(v-else-if="location_es_list.data && location_es_list.data.length > 0")
                        tr(v-for="(data,index) in location_es_list.data" :key="data.id")
                            //- td 
                                strong {{data.multi_tracking.group_name}}
                            //- td 
                                button.flex.items-center(@click="detailTarget(data)")
                                    figure.w-12.h-12.mr-4
                                        img.w-full.h-full.object-cover.rounded-full.bg-gray-100(:src="data.target.photo ? data.target.photo : getDefaultImage" @error="getDefaultImage")

                                    strong.text-link {{ data.target.name }}
                            td 
                                small {{data.started_at}} • {{data.started_by.name}} 
                            td 
                                small {{data.stoped_at}} • {{data.stoped_by.name}}
                            td {{freqName(data.frequency)}}
                            td {{data.msisdn}}
                            td(:class="data.status.toLowerCase()==='started' ? 'status-active' : 'status-inactive'") 
                                span {{ data.status }}
                    tr(v-else)
                        td.text-center(:colspan="tableThead.length") No Data Available
        .box-table-footer
            .pagination
                vue-ads-pagination(
                    :total-items="total_data"
                    :items-per-page="items_per_page"
                    :page="page"
                    :max-visible-pages="5"
                )
                    template(slot="buttons" slot-scope="props")
                        vue-ads-page-button(
                            v-for="(button, key) in props.buttons"
                            :key="key"
                            v-bind="button"
                            :class="{'bg-yellow-dark': button.active}"
                            @page-change="page = button.page"
                            @range-change="start = button.start; end = button.end"
                        )
</template>

<script>
import { env } from '@/config/env';
import IconSet from '@/components/IconSet.vue';
import { mapState } from 'vuex';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
// import '../../../../node_modules/vue-ads-pagination/dist/vue-ads-pagination.css';
import Spinner from '@/components/Spinner.vue';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import moment from 'moment';
import { encrypt,decrypt } from '@/util/crypto';
import JsonCSV from 'vue-json-csv';
import NoDataAvailable from '@/components/NoDataAvailable.vue';
import Loading from '@/components/Loading.vue';
import { gmapApi } from 'vue2-google-maps';

// open street map
import {LMap, LTileLayer, LMarker, LPopup, LPolyline} from 'vue2-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { GestureHandling } from "leaflet-gesture-handling";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
// import LeafletHeatmap from '@/components/LeafletHeatmap.vue';
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.imagePath = '';
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export default {
    name: 'STLocationHistory',
    components: {
        IconSet,
        Spinner,
        VueAdsPagination,
        VueAdsPageButton,
        DateRangePicker,
        JsonCSV,
        NoDataAvailable,
        Loading,

        // open street map
        LMap,
        // GestureHandling,
        LTileLayer,
        LMarker,
        LPopup,
        LPolyline,
    },
    data() {
        return {
            showFilter: false,
            tableThead: [
                {
                    name: 'MSISDN',
                },{
                    name: 'Location',
                },{
                    name: 'BTS ID',
                },{
                    name: 'TAC',
                },{
                    name: 'LAC',
                },{
                    name: 'ENB',
                },{
                    name: 'CI',
                // },{
                //     name: 'IMEI',
                },{
                    name: 'State',
                },{
                    name: 'Datetime',
                },{
                    name: 'Requested At',
                },{
                    name: 'Maps',
                    action: true,
                },
            ],
            
            paramMsisdn: [],

            total_data: 0,
            items_per_page: 10,

            size: [10, 25, 50, 100],

            page: 0,
            start: 0,
            end: 0,

            keyword: '',
            timeout: null,

            from_date: '',
            to_date: '',

            dateRange: {
                startDate: '',
                endDate: '',
            },
            default_ranges: {
                'Today': [moment().toDate(), moment().toDate()],
                'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                'Last 7 days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                'Last week': [moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(), moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate()],
            },
            additional: ['all', 'available_coordinate'],
            type_data: 'all',

            csvName: 'location_history_list_'+moment().format('YYYY-MM-DD_HH:mm:ss')+'.csv',
            csvData: [],

            mapVendor: this.$store.getters['config/getMapVendor'],
            mapLoaded: false,
            startMapsTab: false,
            options: {
                gestureHandling: 'cooperative',
                styles: [
                    {elementType: 'geometry', stylers: [{color: '#242f3e'}]},
                    {elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}]},
                    {elementType: 'labels.text.fill', stylers: [{color: '#746855'}]},
                    {
                        featureType: 'administrative.locality',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#d59563'}]
                    },
                    {
                        featureType: 'poi',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#d59563'}]
                    },
                    {
                        featureType: 'poi.park',
                        elementType: 'geometry',
                        stylers: [{color: '#263c3f'}]
                    },
                    {
                        featureType: 'poi.park',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#6b9a76'}]
                    },
                    {
                        featureType: 'road',
                        elementType: 'geometry',
                        stylers: [{color: '#38414e'}]
                    },
                    {
                        featureType: 'road',
                        elementType: 'geometry.stroke',
                        stylers: [{color: '#212a37'}]
                    },
                    {
                        featureType: 'road',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#9ca5b3'}]
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'geometry',
                        stylers: [{color: '#746855'}]
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'geometry.stroke',
                        stylers: [{color: '#1f2835'}]
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#f3d19c'}]
                    },
                    {
                        featureType: 'transit',
                        elementType: 'geometry',
                        stylers: [{color: '#2f3948'}]
                    },
                    {
                        featureType: 'transit.station',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#d59563'}]
                    },
                    {
                        featureType: 'water',
                        elementType: 'geometry',
                        stylers: [{color: '#17263c'}]
                    },
                    {
                        featureType: 'water',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#515c6d'}]
                    },
                    {
                        featureType: 'water',
                        elementType: 'labels.text.stroke',
                        stylers: [{color: '#17263c'}]
                    }
                ],
            },
            infoWindows: [],
            center: {},
            markers: [],
            mapsLatLng: [],
            polyline: null,

            // openstreetmap
            // url: 'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
            url: `${env.VUE_APP_OSM_CDN_URL}{z}/{x}/{y}.png`,
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            locations: [],
            idOsm: 'openstreetmap-target_detail',
            polylineOsm: {
                latlngs:[],
                color:'red'
            },
            newLoc: '',
            newLt : 0, 
            newLng : 0,
            icon: L.icon({iconUrl: "null",}),
        }
    },
    computed: {
        ...mapState({
            single_target_detail: (state) => state.target.detail,
            location_es_list: (state) => state.locationEs.list,
            status_location_es_list: (state) => state.locationEs.status_list,
        }),
        route_name() {
            return this.$route.name;
        },
        id() {
            return this.$route.params.id;
        },
        key() {
            return decrypt(this.$route.params.key).split('&&');
        },
        google: gmapApi,
    },
    methods: {
        titleCase(string) {
            string = string.replace("_", " ");
            let sentence = string.toLowerCase().split(" ");
            for (let i = 0; i < sentence.length; i++) {
                sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
            }
            
            return sentence.join(" ");
        },
        datetimeFormat(datetime) {
            // return moment(datetime, 'YYYY-MM-DDTHH:mm:ssZ').format('DD MMM YYYY HH:mm:ssZ');
            return datetime;
        },
        /* eslint-disable no-unused-vars */
        dateFormat (classes, date) {
            if (!classes.disabled) {
                // classes.disabled = date.getTime() > new Date();
                classes.disabled = moment(date.getTime()).subtract({ hours: 12})._d > new Date();
            }
            return classes;
        },
        detailMaps(id) {
            // console.log(id)
        },
        getData() {
            var msisdn = this.key[0];
            this.$store.dispatch('locationEs/getList', ['list' ,msisdn, this.items_per_page, (this.page + 1), this.from_date, this.to_date,'monitoringsingle',this.type_data])
        },
        getDataCsv(data) {
            this.csvData = data.map((item) => {

                let ci = '-';

                if ((item.features[0].properties.enb && item.features[0].properties.enb.length > 0) && (item.features[0].properties.ci && item.features[0].properties.ci.length > 0)) {
                    ci = item.features[0].properties.enb +' '+ item.features[0].properties.ci;

                } else if ((item.features[0].properties.enb && item.features[0].properties.enb.length > 0) && (!item.features[0].properties.ci || !item.features[0].properties.ci.length > 0)) {
                    ci = item.features[0].properties.enb;

                } else if ((item.features[0].properties.ci && item.features[0].properties.ci.length > 0) && (!item.features[0].properties.enb || !item.features[0].properties.enb.length > 0)) {
                    ci = item.features[0].properties.ci;
                }

                return {
                    // target_name  : this.getTargetName(item.features[0].properties.msisdn),
                    msisdn       : item.features[0].properties.msisdn?item.features[0].properties.msisdn:'-',
                    location_name: item.features[0].properties.alamat ? item.features[0].properties.alamat : '-',
                    bts_id: item.features[0].properties.bts ? item.features[0].properties.bts : '-',
                    tac: item.features[0].properties.tac ? item.features[0].properties.tac : '-',
                    lac: item.features[0].properties.lac ? item.features[0].properties.lac : '-',
                    enb: item.features[0].properties.enb ? item.features[0].properties.enb : '-',
                    ci: ci,
                    state: item.features[0].properties.state ? item.features[0].properties.state : '-',
                    coordinates  : item.features[0].geometry.coordinates[0]?item.features[0].geometry.coordinates[1]+','+item.features[0].geometry.coordinates[0]:'-',
                    datetime     : item.features[0].properties.dtm?item.features[0].properties.dtm:'-',
                    requested_at : item.features[0].properties.requested_at?item.features[0].properties.requested_at:'-',
                };
            })
        },
        pageChange(page) {
            this.page = page;
        },
        rangeChange(start, end) {
            this.start = start;
            this.end = end;
        },
        sizeChange() {
            this.page = 0;
        },
        pagination() {
            // update fontawesome to iconfont
            var paginationButtonPreview = document.getElementsByClassName("fa-angle-left");

            if (paginationButtonPreview[0]) {
                var classListPrev = paginationButtonPreview[0].classList;

                if (Object.values(classListPrev).filter(key => ['iconfont'].includes(key)).length === 0) {
                    paginationButtonPreview[0].classList.add('iconfont');
                    paginationButtonPreview[0].classList.add('iconleft');
                    paginationButtonPreview[0].style.fontSize = '12px';
                }
            }

            var paginationButtonNext = document.getElementsByClassName("fa-angle-right");

            if (paginationButtonNext[0]) {
                var classListNext = paginationButtonNext[0].classList;

                if (Object.values(classListNext).filter(key => ['iconfont'].includes(key)).length === 0) {
                    paginationButtonNext[0].classList.add('iconfont');
                    paginationButtonNext[0].classList.add('iconright');
                    paginationButtonNext[0].style.fontSize = '12px';
                }
            }
        },
        searchKeyword() {
            // clear timeout variable
            clearTimeout(this.timeout);
            
            var self = this;
            this.timeout = setTimeout(function () {
                // enter this block of code after 1 second
                // handle stuff, call search API etc.
                self.page = 0;
                self.$store.commit('locationEs/setList', {});

                // if (self.keyword.length > 0) {
                    self.getData();
                // }
            }, 650);
        },
        getDefaultImage(e) {
            e.target.src = this.defaultImage;
        },
        async pickerUpdate(e) {
            // console.log('========= DateRangePicker on update')
            // console.log(e)

            let ranges = moment(e.endDate.getTime()).diff(moment(e.startDate.getTime()), 'days') + 1;

            if (ranges > this.$store.getters['config/getValidateRange']) {
                const default_date = this.$store.getters['config/getDefaultDate']();

                // this.dateRange.startDate = moment().toDate();
                // this.dateRange.endDate = moment().toDate();

                // this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                // this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');

                this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
                this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();

                this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');

                this.$swal.fire({
                    icon: 'error',
                    title: 'Date range is not more than ' + this.$store.getters['config/getValidateRange'] + ' days!',
                    text: 'Your date range is ' + ranges + ' days.',
                    // confirmButtonText: 'Yes!',
                    // confirmButtonColor: '#DD6B55',
                    // showCancelButton: true,
                    // allowOutsideClick: false,
                })

                // return;
            } else {
                this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');

                this.page = 0;
                this.getData();
            }
        },
        hideAllInfoWindows() {
            for (var j=0; j < this.infoWindows.length; j++) {
                this.infoWindows[j].close();
            }
        },
        createInfoWindow(msisdn='-', address='-', datetime='-', lat_lng='') {
            var contentString = '<div id="content">'+
                '<div id="bodyContent">'+
                    '<table>'+
                        '<tr>'+
                            '<td class="pr-1 cell-info-window whitespace-no-wrap">MSISDN</td>'+
                            '<td class="pr-1 cell-info-window">:</td>'+
                            '<td class="cell-info-window">'+ msisdn +'</td>'+
                        '</tr>'+
                        '<tr>'+
                            '<td class="cell-info-window whitespace-no-wrap">Address</td>'+
                            '<td class="cell-info-window">:</td>'+
                            '<td class="cell-info-window">'+ address +'</td>'+
                        '</tr>'+
                        '<tr>'+
                            '<td class="cell-info-window whitespace-no-wrap">Datetime</td>'+
                            '<td class="cell-info-window">:</td>'+
                            '<td class="cell-info-window">'+ datetime +'</td>'+
                        '</tr>'+
                        '<tr>'+
                            '<td class="cell-info-window whitespace-no-wrap">Link Location</td>'+
                            '<td class="cell-info-window">:</td>'+
                            '<td class="cell-info-window"><a target="_blank" href="https://www.google.com/maps?q='+ lat_lng +'" style="color: #43ea92;">'+'Google Maps</a> '+
                            '</td>'+
                        '</tr>'+
                    '</table>'+
                '</div>'+
            '</div>';

            return contentString;
        },
        getMarkerOsmIcon(location){
            let iconColor = '#ffffff'
            if (location.iconColor){
                iconColor = location.iconColor
            }
            let stroke_width = '1.8';
            let stroke = 'white';
            let mySvgString = '<svg width="64" height="64" viewBox="0 0 64 64" fill="<color-icon>" xmlns="http://www.w3.org/2000/svg"><path d="M31.5766 15.7225C35.366 15.7225 38.4379 12.6506 38.4379 8.86127C38.4379 5.07189 35.366 2 31.5766 2C27.7872 2 24.7153 5.07189 24.7153 8.86127C24.7153 12.6506 27.7872 15.7225 31.5766 15.7225Z" fill="<color-icon>" stroke="'+ stroke +'" stroke-width="'+ stroke_width +'"/><path d="M18 26.8175V41.4161C18 43.1679 19.4599 44.4817 21.0657 44.4817H23.9854L25.5911 59.5183C25.7372 60.9782 26.905 62 28.3649 62H34.6423C36.1022 62 37.2701 60.978 37.4161 59.5183L39.0219 44.4817H41.9416C43.6934 44.4817 45.0073 43.1679 45.0073 41.4161V26.8175C45.1533 14.9926 18 14.9926 18 26.8175Z" fill="<color-icon>" stroke="'+ stroke +'" stroke-width="'+ stroke_width +'" stroke-linejoin="round"/></svg>'
            // let myIconUrl = encodeURI("data:image/svg+xml," + mySvgString).replace('<color-icon>',iconColor).replace('#','%23');
            // let myIconUrl = encodeURI("data:image/svg+xml;utf8," + mySvgString.replaceAll('<color-icon>',iconColor)).replaceAll('#','%23');
            let myIconUrl = encodeURI("data:image/svg+xml;utf8," + mySvgString.replace(/<color-icon>/g,iconColor)).replace(/#/g,'%23');

            return L.icon({
                iconUrl: myIconUrl,
                iconSize: 50,
                iconAnchor: [24.5, 49],
                popupAnchor: [0, -48],
            });
        },
        async openTab(){
            var tmpStatus = this.status_location_es_list
            await this.$store.commit('locationEs/setStatusList', {
                status: 'loading',
                cancel: null,
                code: null
            });
            this.$store.commit('locationEs/setStatusList', tmpStatus);
        },
        async startMap() {
            var self = this;
            // var mapsLatLng = [];

            // gmap
            self.infoWindows = [];
            self.markers = [];
            self.mapsLatLng = [];
            self.polyline = [];

            // osm
            self.locations = [];
            self.polylineOsm = {
                latlngs: [],
                color:'red'
            };

            // monas, jakarta
            this.center = {
                lat: -6.1763562,
                lng: 106.8227796,
            };

            this.mapLoaded = true;

            switch(self.mapVendor) {
                case 'osm':
                    await self.$nextTick(() => {
                        if (self.location_es_list.data.length > 0) {
                            const map = self.$refs.mapRefOSM.mapObject;

                            var bounds = [];
                            
                            for (var i = 0; i < self.location_es_list.data.length; i++) {

                                if (Object.keys(self.location_es_list.data[i].features[0].geometry.coordinates).length > 0 && self.location_es_list.data[i].features[0].geometry.coordinates[0] && self.location_es_list.data[i].features[0].geometry.coordinates[1]) {
                                    let latTmp = parseFloat(self.location_es_list.data[i].features[0].geometry.coordinates[1]);
                                    let lngTmp = parseFloat(self.location_es_list.data[i].features[0].geometry.coordinates[0]);
                                    let centerTmp = [latTmp, lngTmp]
                                    var contentString = self.createInfoWindow(self.location_es_list.data[i].features[0].properties.msisdn, self.location_es_list.data[i].features[0].properties.alamat, self.datetimeFormat(self.location_es_list.data[i].features[0].properties.dtm), (self.location_es_list.data[i].features[0].geometry.coordinates[1]+','+self.location_es_list.data[i].features[0].geometry.coordinates[0]));

                                    self.polylineOsm.latlngs.push(centerTmp);
                                    
                                    self.locations.push({
                                        name: contentString,
                                        position: centerTmp, 
                                        iconColor: self.single_target_detail.label
                                    });

                                    bounds.push(centerTmp);
                                }

                            }

                            if (self.polylineOsm.latlngs.length > 0)
                                self.polylineOsm.color = self.single_target_detail.label;

                            if (bounds.length)
                                map.fitBounds(L.latLngBounds([bounds]));
                        }
                    });
                    break;

                default:
                    await self.$nextTick(() => {
                        self.$refs.mapRef.$mapPromise.then((map) => {
                            const google = self.google;

                            // map.center = new google.maps.LatLng(self.center);

                            if (self.location_es_list.data.length > 0) {
                                var bounds = new google.maps.LatLngBounds();

                                for (var i = 0; i < self.location_es_list.data.length; i++) {

                                    self.mapsLatLng[i] = new google.maps.LatLng(
                                        parseFloat(self.location_es_list.data[i].features[0].geometry.coordinates[1]),
                                        parseFloat(self.location_es_list.data[i].features[0].geometry.coordinates[0])
                                    );

                                    var icon = {
                                        path: 'M31.5766 15.7225C35.366 15.7225 38.4379 12.6506 38.4379 8.86127C38.4379 5.07189 35.366 2 31.5766 2C27.7872 2 24.7153 5.07189 24.7153 8.86127C24.7153 12.6506 27.7872 15.7225 31.5766 15.7225Z M18 26.8175V41.4161C18 43.1679 19.4599 44.4817 21.0657 44.4817H23.9854L25.5911 59.5183C25.7372 60.9782 26.905 62 28.3649 62H34.6423C36.1022 62 37.2701 60.978 37.4161 59.5183L39.0219 44.4817H41.9416C43.6934 44.4817 45.0073 43.1679 45.0073 41.4161V26.8175C45.1533 14.9926 18 14.9926 18 26.8175Z',
                                        fillColor: self.single_target_detail.label,
                                        fillOpacity: 1,
                                        // strokeWeight: 1.2,
                                        strokeWeight: 1.2,
                                        strokeColor: '#ffffff',
                                        // scale: 1.25,
                                        scale: .65,
                                        anchor: new google.maps.Point(30,65),
                                    }

                                    let marker = new google.maps.Marker({
                                        position: self.mapsLatLng[i],
                                        // position: {
                                        //     lat: parseFloat(self.location_es_list.data[i].features[0].geometry.coordinates[1]),
                                        //     lng: parseFloat(self.location_es_list.data[i].features[0].geometry.coordinates[0])
                                        // },
                                        map: map,
                                        icon: icon,
                                        // draggable: true,
                                    });
                                    // console.log(marker);
                                    self.markers.push(marker);

                                    // var dtm = (self.location_es_list.data[i].created_at ? self.datetimeFormat(self.location_es_list.data[i].created_at) : '-');
                                    
                                    var contentString = self.createInfoWindow(self.location_es_list.data[i].features[0].properties.msisdn, self.location_es_list.data[i].features[0].properties.alamat, self.datetimeFormat(self.location_es_list.data[i].features[0].properties.dtm), (self.location_es_list.data[i].features[0].geometry.coordinates[1]+','+self.location_es_list.data[i].features[0].geometry.coordinates[0]));

                                    // var contentString = '<div id="content">'+
                                    //     '<div id="bodyContent">'+
                                    //         '<table>'+
                                    //             '<tr>'+
                                    //                 '<td class="pr-1 cell-info-window whitespace-no-wrap">MSISDN</td>'+
                                    //                 '<td class="pr-1 cell-info-window">:</td>'+
                                    //                 '<td class="cell-info-window">'+self.location_es_list.data[i].features[0].properties.msisdn+'</td>'+
                                    //             '</tr>'+
                                    //             '<tr>'+
                                    //                 '<td class="cell-info-window whitespace-no-wrap">Address</td>'+
                                    //                 '<td class="cell-info-window">:</td>'+
                                    //                 '<td class="cell-info-window">'+self.location_es_list.data[i].features[0].properties.alamat+'</td>'+
                                    //             '</tr>'+
                                    //             '<tr>'+
                                    //                 '<td class="cell-info-window whitespace-no-wrap">Datetime</td>'+
                                    //                 '<td class="cell-info-window">:</td>'+
                                    //                 '<td class="cell-info-window">'+self.location_es_list.data[i].features[0].properties.dtm+'</td>'+
                                    //             '</tr>'+
                                    //             '<tr>'+
                                    //                 '<td class="cell-info-window whitespace-no-wrap">Link Location</td>'+
                                    //                 '<td class="cell-info-window">:</td>'+
                                    //                 '<td class="cell-info-window"><a target="_blank" href="https://www.google.com/maps?q='+self.location_es_list.data[i].features[0].geometry.coordinates[1]+','+self.location_es_list.data[i].features[0].geometry.coordinates[0]+'" style="color: #43ea92;">'+'Google Maps</a> '+
                                    //                 '</td>'+
                                    //             '</tr>'+
                                    //         '</table>'+
                                    //     '</div>'+
                                    // '</div>';

                                    let infowindow = new google.maps.InfoWindow({
                                        content: contentString,
                                        maxWidth: 400
                                    });
                                    
                                    self.infoWindows.push(infowindow);
                                    
                                    google.maps.event.addListener(marker, 'click', function() {
                                        // hideAllInfoWindows(map);
                                        self.hideAllInfoWindows();
                                        infowindow.open(map, marker);
                                    });

                                    self.polyline.push(self.mapsLatLng[i]);

                                    bounds.extend(new google.maps.LatLng(marker.position.lat(), marker.position.lng()));
                                }

                                if (self.mapsLatLng.length > 0) {
                                    self.polyline = new google.maps.Polyline({
                                        path: self.mapsLatLng,
                                        strokeColor: self.single_target_detail.label,
                                        // strokeColor: '#FF0000',
                                        strokeOpacity:0.8,
                                        strokeWeight:1.5
                                    });
                                    
                                    self.polyline.setMap(map);
                                }

                                // Apply fitBounds
                                map.fitBounds(bounds); 
                            } else {
                                self.mapLoaded = false;
                            }
                        });
                    });
            }
        },
    },
    watch: {
        status_location_es_list(newData){
            if (newData.status !== 'loading') {
                this.startMap()
            }
        },
        location_es_list(newData) {
            if (Object.keys(newData).length > 0) {
                this.total_data = parseInt(newData.total_data);
                // this.page = (parseInt(newData.current_page) - 1);

                if (newData.data && newData.data.length > 0) {
                    this.getDataCsv(newData.data);
                }
            } else {
                this.total_data = 0;
            }
        },
        total_data() {
        },
        items_per_page() {
            this.getData();
        },
        page() {
            this.getData();
        },
        type_data() {  
            this.getData();
        },
        keyword() {},
        route_name(newData, oldData) {
            if (newData === 'pageTargetDetailHome') {
                if (oldData !== 'pageTargetDetailHome') {
                    const default_date = this.$store.getters['config/getDefaultDate']();

                    this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
                    this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();

                    this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                    this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');

                    // this.optFilterTarget= []
                    // this.filterTarget= ''
                    this.paramMsisdn= []
                    this.items_per_page = 10
                    this.page = 0
                    this.keyword = ''
                    this.$store.commit('target/setMonitoringList', {})
                    this.$store.commit('target/setStatusMonitoringList', {
                        status: 'loading',
                        cancel: null,
                        code: null
                    });
                }
            }
        },
        id() {},
        key() {},
        google() {},
    },
    filters: {
        date: function (date) {
            return moment(date).format('YYYY-MM-DD');
        }
    },
    created() {
        EventEmit.$on('startMapLocationHistory', () => {
            if (this.status_location_es_list.status !== 'loading') {
                this.openTab()
            }
        });
    },
    mounted() {
        if (this.route_name === 'pageTargetDetailHome') {
            const default_date = this.$store.getters['config/getDefaultDate']();

            this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
            this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();

            this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');
            
            this.getData();
        }
    },
}
</script>

<style lang="sass">
    .draw-area
        height: 500px
    .cell-info-window
        vertical-align: top
    .box-map
        @apply .flex .items-center .justify-center .w-full
        min-height: 450px
        background-color: $color-secondary-800
    .btn-primary
        cursor: pointer !important
</style>