<template lang="pug">
.card.card--table.card--no-bg
    .card--table__body
        //- filter
        .box-filter.px-4.pt-6
            //- showing data information mobile
            .m-only
                .filter-info
                    .flex.items-center.justify-between.mb-3
                        Strong.uppercase.text-xs.font-bold Filtered by
                        button.btn-default-o.btn-small(
                            type='button'
                            v-on:click="showFilter = !showFilter"
                            ) Change Filter
                    p {{ dateRange.startDate }} -  {{ dateRange.endDate }}, All Target, 10 entries

            //- end showing data information mobile
            .mobile-filter(
                v-bind:class="{ 'is-show' : showFilter}"
            )
                .m-only
                    .flex.items-center.justify-between.p-4.border-b
                        h2.text-xl Filter
                        button.btn-icon-40(
                            v-on:click="showFilter = !showFilter"
                        )
                            IconSet(type="close")
                .items-filter
                    .field(class="lg:mr-3 lg:mb-0 lg:w-64 mr-0 w-full mb-4")
                        .relative.field-style.w-full
                            date-range-picker(
                                v-if="dateRange.startDate && dateRange.endDate" 
                                ref="picker" 
                                v-model="dateRange" 
                                @update="pickerUpdate($event)" 
                                :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }" 
                                :date-format="dateFormat" 
                                opens="right"
                                :ranges="default_ranges"
                                autoApply=true
                            )
                                div(
                                    slot="input" 
                                    slot-scope="picker") 
                                        | {{ picker.startDate | date}} - {{ picker.endDate  | date}}

                            .field-icon
                                IconSet(type="calendar")

                    .field(class="lg:mr-3 lg:mb-0 lg:w-56 mr-0 w-full mb-4")
                        select.field-style.w-full(name="filter-target" v-model="filterTarget")
                            option(selected value='') - All Targets - 
                            option(v-for="opt in optFilterTarget" :key="opt.msisdn" :value="opt.msisdn") {{ opt.name }}    
                            
                        .field-icon
                            IconSet(type="chevron-down")

                    .field(class="lg:mr-3 lg:mb-0 lg:w-32 mr-0 w-full mb-4")
                        select.field-style#filter-record-size(@change="sizeChange($event)" v-model="items_per_page" style="min-width:80px")
                            option(v-for="(s,index) in size" :key="index" :value="s") {{ s }}
                        .field-icon
                            IconSet(type="chevron-down")

                    .block(v-if="location_es_list.data && location_es_list.data.length > 0")
                        .flex.flex-wrap.items-center.justify-between
                            .field.mr-3.relative
                            JsonCSV(
                                :data="csvData"
                                :name="csvName"
                                class="btn btn-primary"
                            )
                                | Download CSV
        //- end filter

        .box-table
            table.table
                thead
                    tr
                        th(v-for="(th,index) in tableThead" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}
                tbody
                    tr(v-if="status_location_es_list.status === 'loading' || status_multi_tracking_detail.status === 'loading'")
                        td.text-center(:colspan="tableThead.length")
                            .flex.flex-wrap.items-center.justify-center
                                Spinner(show="true" size="48")
                    template(v-else-if="location_es_list.data && location_es_list.data.length > 0")
                        tr(v-for="(data,index) in location_es_list.data" :key="data.id")
                            td {{getTargetName(data.features[0].properties.msisdn)}}
                            td {{data.features[0].properties.msisdn}}
                            td 
                                .text-xs {{data.features[0].properties.alamat ? data.features[0].properties.alamat : '-'}}
                            td 
                                .text-xs {{data.features[0].properties.bts}}
                            td 
                                .text-xs {{data.features[0].properties.state}}
                            td 
                                .text-xs {{data.features[0].properties.dtm ? datetimeFormat(data.features[0].properties.dtm) : '-'}}
                            td 
                                .text-xs {{data.features[0].properties.requested_at ? datetimeFormat(data.features[0].properties.requested_at) : '-'}}
                            td.text-center
                                div.inline-flex.items-center
                                    template(v-if="data.features[0].geometry.coordinates[0]")
                                        a.btn-small.btn-default.ml-2(:href="'https://www.google.com/maps?q='+data.features[0].geometry.coordinates[1]+','+data.features[0].geometry.coordinates[0]" target='_blank' v-tooltip="'Google Maps'")
                                            IconSet(type="maps")
                                    template(v-else)
                                        | -

                    tr(v-else)
                        td.text-center(:colspan="tableThead.length") No Data Available

    .card__footer
        .box-table-footer(v-if="status_location_es_list.status !== 'loading'")
            .pagination
                vue-ads-pagination(
                    :total-items="total_data"
                    :items-per-page="items_per_page"
                    :page="page"
                    :max-visible-pages="5"
                )
                    template(slot="buttons" slot-scope="props")
                        vue-ads-page-button(
                            v-for="(button, key) in props.buttons"
                            :key="key"
                            v-bind="button"
                            :class="{'bg-yellow-dark': button.active}"
                            @page-change="page = button.page"
                            @range-change="start = button.start; end = button.end"
                        )

    .card__body.p-4
        .w-full
            .block.box-map.flex.items-center.justify-center(v-if="status_location_es_list.status === 'loading' || status_multi_tracking_detail.status === 'loading'")
                Loading(show="true")
            .block.box-map.flex.items-center.justify-center(style="height:400px" v-else)
                template(v-if="mapHistoryLoaded && location_es_list.data && location_es_list.data.length > 0")

                    l-map(v-if="mapVendor==='osm'" ref="mapRefOSM" :id="idOSM" :zoom="10" :center="center" :options="options" style="height: 100%;width: 100%;")
                        l-tile-layer(:url="url" :attribution="attribution")
                        template(v-if="locations.length > 0")
                            l-marker(v-for="(location, index) in locations" :lat-lng="location.position" v-bind:key="index" :icon="getMarkerOsmIcon(location)")
                                l-popup(:content="location.name")
                        template(v-if="polyline.latlngs.length > 0")
                            l-polyline(:lat-lngs="polyline.latlngs" :color="polyline.color")

                    gmap-map#mapHistory(v-else ref="mapHistoryRef" :center="center" :zoom="11" :options="options" style="width:100%; height: 100%;")
                
                NoDataAvailable(v-else)

</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import { env } from '@/config/env';
import IconSet from '@/components/IconSet.vue';
import DateRangePicker from 'vue2-daterange-picker';
import JsonCSV from 'vue-json-csv'
import Spinner from '@/components/Spinner.vue';
import Loading from '@/components/Loading.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';
import { gmapApi } from 'vue2-google-maps';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';

// openstreetmap
import {LMap, LTileLayer, LMarker, LPopup, LPolyline} from 'vue2-leaflet';
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.imagePath = ''
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

export default {
    name: 'MultiTrackingLocationHistory',
    components: {
        IconSet,
        DateRangePicker,
        Spinner,
        Loading,
        NoDataAvailable,
        JsonCSV,
        VueAdsPagination,
        VueAdsPageButton,

        // open street map
        LMap,
        // GestureHandling,
        LTileLayer,
        LMarker,
        LPopup,
        // LeafletHeatmap,
        LPolyline,
    },
    data() {
        return {
            showFilter: false,
            tableThead: [
                {
                    name: 'Target',
                },{
                    name: 'MSISDN',
                },{
                    name: 'Location',
                },{
                    name: 'BTS ID',
                },{
                    name: 'State',
                },{
                    name: 'Datetime',
                },{
                    name: 'Requested At',
                },{
                    name: 'Maps',
                    action: true,
                },
            ],
            
            optFilterTarget: [],
            filterTarget: '',
            paramMsisdn: [],
            total_data: 0,
            items_per_page: 10,

            size: [10, 25, 50, 100],

            page: 0,
            start: 0,
            end: 0,

            keyword: '',
            timeout: null,

            from_date: '',
            to_date: '',

            dateRange: {
                startDate: '',
                endDate: '',
            },
            default_ranges: {
                'Today': [moment().toDate(), moment().toDate()],
                'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                'Last 7 days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                'Last week': [moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(), moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate()],
            },
            csvName: 'location_history_list'+moment().format('YYYY-MM-DD_HH:mm:ss')+'.csv',
            csvData: [],
            marker_color: {
                green: '#48bb78',
                red: '#f56565',
                purple: '#9f7aea',
                orange: '#ed8936',
                blue: '#4299e1',
            },

            // map history
            mapVendor: this.$store.getters['config/getMapVendor'],
            infoWindowsHistory: [],
            mapHistoryLoaded: false,
            markers_history: [],
            mapsLatLngHistory: [], // map history
            mapsLatLngTarget: {}, // map history
            // mapsLatLngTarget: [], // map history
            polyline_history: null, // map history
            options: {
                gestureHandling: 'cooperative',
                styles: [
                    {elementType: 'geometry', stylers: [{color: '#242f3e'}]},
                    {elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}]},
                    {elementType: 'labels.text.fill', stylers: [{color: '#746855'}]},
                    {
                        featureType: 'administrative.locality',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#d59563'}]
                    },
                    {
                        featureType: 'poi',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#d59563'}]
                    },
                    {
                        featureType: 'poi.park',
                        elementType: 'geometry',
                        stylers: [{color: '#263c3f'}]
                    },
                    {
                        featureType: 'poi.park',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#6b9a76'}]
                    },
                    {
                        featureType: 'road',
                        elementType: 'geometry',
                        stylers: [{color: '#38414e'}]
                    },
                    {
                        featureType: 'road',
                        elementType: 'geometry.stroke',
                        stylers: [{color: '#212a37'}]
                    },
                    {
                        featureType: 'road',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#9ca5b3'}]
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'geometry',
                        stylers: [{color: '#746855'}]
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'geometry.stroke',
                        stylers: [{color: '#1f2835'}]
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#f3d19c'}]
                    },
                    {
                        featureType: 'transit',
                        elementType: 'geometry',
                        stylers: [{color: '#2f3948'}]
                    },
                    {
                        featureType: 'transit.station',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#d59563'}]
                    },
                    {
                        featureType: 'water',
                        elementType: 'geometry',
                        stylers: [{color: '#17263c'}]
                    },
                    {
                        featureType: 'water',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#515c6d'}]
                    },
                    {
                        featureType: 'water',
                        elementType: 'labels.text.stroke',
                        stylers: [{color: '#17263c'}]
                    }
                ],
            },

            // openstreetmap
            // url: 'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
            url: `${env.VUE_APP_OSM_CDN_URL}{z}/{x}/{y}.png`,
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            locations: [],
            polyline: {
                latlngs:[],
                color:'red'
            },
            idOSM: 'location_history_multitarget',
            newLoc: '',
            newLt : 0, 
            newLng : 0,
            icon: L.icon({iconUrl: "null",}),

            imgAvatar: this.$store.getters['config/getDefaultImageProfile'],
        }
    },
    computed: {
        ...mapState({
            multi_tracking_detail: (state) => state.multiTracking.detail,
            status_multi_tracking_detail: (state) => state.multiTracking.status_detail,
            location_es_list: (state) => state.locationEs.list,
            status_location_es_list: (state) => state.locationEs.status_list,
        }),
        route() {
            return this.$route;
        },
        route_name() {
            return this.$route.name;
        },
        id() {
            return this.$route.params.id;
        },
        google: gmapApi,
    },
    methods: {
        getTargetName(msisdn) {
            var target = this.optFilterTarget.filter( val => val.msisdn == msisdn )
            if (target.length > 0) {
                return target[0].name
            } else {
                return '-'
            }
        },
        getTargetData(msisdn) {
            var target = this.optFilterTarget.filter( val => val.msisdn == msisdn )
            if (target.length > 0) {
                return target[0]
            } else {
                return '-'
            }
        },
        setData(data) {
            // this.mapHistoryLoaded = false;
            this.optFilterTarget= []
            this.filterTarget= ''
            this.paramMsisdn= []
            // var targets = data.target
            var targets = data.target_history
            for (var i = 0; i < targets.length; i++) {
                for (var j = 0; j < targets[i].msisdn.length; j++) {
                    this.paramMsisdn.push(targets[i].msisdn[j])
                    var tmpTarget = {
                        'id': targets[i].id,
                        'name': targets[i].target_name,
                        'msisdn': targets[i].msisdn[j],
                        'label': targets[i].label,
                    }
                    this.optFilterTarget.push(tmpTarget)
                }
            }
            
            this.getData();
        },
        datetimeFormat(datetime) {
            // return moment(datetime, 'YYYY-MM-DDTHH:mm:ssZ').format('DD MMM YYYY HH:mm:ssZ');
            return datetime;
        },
        /* eslint-disable no-unused-vars */
        dateFormat (classes, date) {
            if (!classes.disabled) {
                classes.disabled = moment(date.getTime()).subtract({ hours: 12})._d > new Date();
            }
            return classes;
        },
        getData() {
            this.setEmptyMap();
            // this.mapHistoryLoaded = false;

            var msisdn = null
            if (this.filterTarget) {
                msisdn = this.filterTarget
            } else {
                msisdn = JSON.stringify(this.paramMsisdn)
            }
            // system_type='monitoringsingle' || 'monitoringmulti'
            this.$store.dispatch('locationEs/getList', ['list', msisdn, this.items_per_page, (this.page + 1), this.from_date, this.to_date, 'monitoringmulti', 'all', this.id]);
            console.log("=====this.id",this.id)
            // if (this.location_es_list.data && this.location_es_list.data.length > 0) {
                // this.getDataCsv(this.location_es_list.data)
                // this.startMapHistory();        
            // }
            // if (this.location_es_list.total_page && this.location_es_list.total_page > 1) {
            //     this.pagination();
            // }
        },
        getDataCsv(data) {
            this.csvData = data.map((item) => {
                return {
                    target_name  : this.getTargetName(item.features[0].properties.msisdn),
                    msisdn       : item.features[0].properties.msisdn?item.features[0].properties.msisdn:'-',
                    location_name: item.features[0].properties.alamat ? item.features[0].properties.alamat : '-',
                    bts_id: item.features[0].properties.bts ? item.features[0].properties.bts : '-',
                    state: item.features[0].properties.state ? item.features[0].properties.state : '-',
                    coordinates  : item.features[0].geometry.coordinates[0]?item.features[0].geometry.coordinates[1]+','+item.features[0].geometry.coordinates[0]:'-',
                    datetime     : item.features[0].properties.dtm?item.features[0].properties.dtm:'-',
                };
            })
        },
        pageChange(page) {
            this.page = page;
        },
        rangeChange(start, end) {
            this.start = start;
            this.end = end;
        },
        sizeChange() {
            this.page = 0;
        },
        pagination() {
            // update fontawesome to iconfont
            var paginationButtonPreview = document.getElementsByClassName("fa-angle-left");

            if (paginationButtonPreview[0]) {
                var classListPrev = paginationButtonPreview[0].classList;

                if (Object.values(classListPrev).filter(key => ['iconfont'].includes(key)).length === 0) {
                    paginationButtonPreview[0].classList.add('iconfont');
                    paginationButtonPreview[0].classList.add('iconleft');
                    paginationButtonPreview[0].style.fontSize = '12px';
                }
            }

            var paginationButtonNext = document.getElementsByClassName("fa-angle-right");

            if (paginationButtonNext[0]) {
                var classListNext = paginationButtonNext[0].classList;

                if (Object.values(classListNext).filter(key => ['iconfont'].includes(key)).length === 0) {
                    paginationButtonNext[0].classList.add('iconfont');
                    paginationButtonNext[0].classList.add('iconright');
                    paginationButtonNext[0].style.fontSize = '12px';
                }
            }
        },
        searchKeyword() {
            // clear timeout variable
            clearTimeout(this.timeout);
            
            var self = this;
            this.timeout = setTimeout(function () {
                // enter this block of code after 1 second
                // handle stuff, call search API etc.
                self.page = 0;
                self.$store.commit('multiTracking/setList', {});

                // if (self.keyword.length > 0) {
                    self.getData();
                // }
            }, 650);
        },
        getDefaultImage(e) {
            e.target.src = this.imgAvatar;
        },
        async pickerUpdate(e) {
            this.setEmptyMap();
            // this.mapHistoryLoaded = false;
            // console.log('========= DateRangePicker on update')
            // console.log(e)

            let ranges = moment(e.endDate.getTime()).diff(moment(e.startDate.getTime()), 'days') + 1;

            if (ranges > this.$store.getters['config/getValidateRange']) {
                const default_date = this.$store.getters['config/getDefaultDate']();

                // this.dateRange.startDate = moment().toDate();
                // this.dateRange.endDate = moment().toDate();

                // this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                // this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');

                this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
                this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();

                this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');

                this.$swal.fire({
                    icon: 'error',
                    title: 'Date range is not more than ' + this.$store.getters['config/getValidateRange'] + ' days!',
                    text: 'Your date range is ' + ranges + ' days.',
                    // confirmButtonText: 'Yes!',
                    // confirmButtonColor: '#DD6B55',
                    // showCancelButton: true,
                    // allowOutsideClick: false,
                })

                // return;
            } else {
                this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');

                this.getData();
            }
        },
        hideAllInfoWindows(history=false) {
            // console.log('on hideAllInfoWindows history true')
            for (var i=0; i < this.infoWindowsHistory.length; i++) {
                // console.log(this.infoWindowsHistory[i])
                this.infoWindowsHistory[i].close();
            }
        },
        createInfoWindow(name='-', msisdn='-', datetime='-', lat_lng='') {
            var contentString = '<div id="content">'+
                '<div id="bodyContent">'+
                '<table>'+
                    '<tr>'+
                        '<td class="pr-1 cell-info-window">Target</td>'+
                        '<td class="pr-1 cell-info-window">:</td>'+
                        '<td class="cell-info-window">'+ name +'</td>'+
                    '</tr>'+
                    '<tr>'+
                        '<td class="cell-info-window">MSISDN</td>'+
                        '<td class="cell-info-window">:</td>'+
                        '<td class="cell-info-window">'+ msisdn +'</td>'+
                    '</tr>'+
                    '<tr>'+
                        '<td class="cell-info-window">Datetime</td>'+
                        '<td class="cell-info-window">:</td>'+
                        '<td class="cell-info-window">'+ datetime +'</td>'+
                    '</tr>'+
                    '<tr>'+
                        '<td class="cell-info-window">Link Location</td>'+
                        '<td class="cell-info-window">:</td>'+
                        '<td class="cell-info-window"><a target="_blank" href="https://www.google.com/maps?q='+ lat_lng +'" style="color: #43ea92;">'+'Google Maps</a> '+
                        '</td>'+
                    '</tr>'+
                    '</table>'+
                '</div>'+
            '</div>';

            return contentString;
        },
        getMarkerOsmIcon(location){
            let iconColor = '#ffffff'
            if (location.iconColor){
                iconColor = location.iconColor
            }
            let stroke_width = '1.8';
            let stroke = 'white';
            let mySvgString = '<svg width="64" height="64" viewBox="0 0 64 64" fill="<color-icon>" xmlns="http://www.w3.org/2000/svg"><path d="M31.5766 15.7225C35.366 15.7225 38.4379 12.6506 38.4379 8.86127C38.4379 5.07189 35.366 2 31.5766 2C27.7872 2 24.7153 5.07189 24.7153 8.86127C24.7153 12.6506 27.7872 15.7225 31.5766 15.7225Z" fill="<color-icon>" stroke="'+ stroke +'" stroke-width="'+ stroke_width +'"/><path d="M18 26.8175V41.4161C18 43.1679 19.4599 44.4817 21.0657 44.4817H23.9854L25.5911 59.5183C25.7372 60.9782 26.905 62 28.3649 62H34.6423C36.1022 62 37.2701 60.978 37.4161 59.5183L39.0219 44.4817H41.9416C43.6934 44.4817 45.0073 43.1679 45.0073 41.4161V26.8175C45.1533 14.9926 18 14.9926 18 26.8175Z" fill="<color-icon>" stroke="'+ stroke +'" stroke-width="'+ stroke_width +'" stroke-linejoin="round"/></svg>'
            // let myIconUrl = encodeURI("data:image/svg+xml," + mySvgString).replace('<color-icon>',iconColor).replace('#','%23');
            // let myIconUrl = encodeURI("data:image/svg+xml;utf8," + mySvgString.replaceAll('<color-icon>',iconColor)).replaceAll('#','%23');
            let myIconUrl = encodeURI("data:image/svg+xml;utf8," + mySvgString.replace(/<color-icon>/g,iconColor)).replace(/#/g,'%23');

            return L.icon({
                iconUrl: myIconUrl,
                iconSize: 50,
                iconAnchor: [24.5, 49],
                popupAnchor: [0, -48],
            });
        },
        async startMapHistory() {
            var self = this;

            this.center = {
                lat: -6.1763562,
                lng: 106.8227796,
            };

            this.mapHistoryLoaded = true;
            
            switch(self.mapVendor) {
                case 'osm':
                    await self.$nextTick(() => {
                        self.$nextTick(() => {
                            const map = self.$refs.mapRefOSM.mapObject

                            if (self.location_es_list.data && self.location_es_list.data.length > 0) {
                                var data = self.location_es_list.data;
                                var bounds = [];
                                // marker
                                for (var i = 0; i < data.length; i++) {
                                    // console.log(i);
                                    if (data[i].features[0].geometry.coordinates.length > 0 && data[i].features[0].geometry.coordinates[1] != '') {
                                        var target = self.getTargetData(data[i].features[0].properties.msisdn)
                                        let latTmp = parseFloat(data[i].features[0].geometry.coordinates[1]);
                                        let lngTmp = parseFloat(data[i].features[0].geometry.coordinates[0]);

                                        var dtm = (data[i].features[0].properties.dtm ? self.datetimeFormat(data[i].features[0].properties.dtm) : '-');

                                        var contentString = self.createInfoWindow(target.name, data[i].features[0].properties.msisdn, dtm, (data[i].features[0].geometry.coordinates[1]+','+data[i].features[0].geometry.coordinates[0]));
                                        let centerTmp = [latTmp, lngTmp]
                                        let labelColor = target.label

                                        self.locations.push({name:contentString,position:centerTmp, iconColor:labelColor});

                                        // target.id in self.mapsLatLngTarget || 
                                        if (!self.mapsLatLngTarget[target.id]){
                                            self.mapsLatLngTarget[target.id] = {};
                                        } 
                                        if (!self.mapsLatLngTarget[target.id].coor) {
                                            self.mapsLatLngTarget[target.id].coor = [];
                                        }
                                        if (!self.mapsLatLngTarget[target.id].label) {
                                            self.mapsLatLngTarget[target.id].label = '#fff';
                                        }

                                        // self.mapsLatLngTarget[target.id].coor.push({lat: parseFloat(data[i].features[0].geometry.coordinates[1]), lng: parseFloat(data[i].features[0].geometry.coordinates[0]))} )
                                        self.mapsLatLngTarget[target.id].coor.push(centerTmp);
                                        self.mapsLatLngTarget[target.id].label = labelColor;

                                        // self.polyline.latlngs.push(centerTmp)
                                        // self.polyline.color = labelColor

                                        bounds.push(centerTmp);
                                    }
                                }

                                if (Object.keys(self.mapsLatLngTarget).length > 0) {
                                    for (const [key, value] of Object.entries(self.mapsLatLngTarget)) {
                                        self.polyline.latlngs.push(value.coor);
                                        self.polyline.color = value.label;
                                    }
                                }

                                if (bounds.length){
                                    map.fitBounds(L.latLngBounds([bounds]));
                                }
                            } 
                        });
                    });
                    break;

                default:
                    self.$nextTick(() => {
                        self.$refs.mapHistoryRef.$mapPromise.then((map) => {
                            const google = self.google;

                            if (self.location_es_list.data && self.location_es_list.data.length > 0) {
                                var data = self.location_es_list.data
                                // marker
                                for (var i = 0; i < data.length; i++) {
                                    // console.log(i);
                                    if (data[i].features[0].geometry.coordinates.length > 0 && data[i].features[0].geometry.coordinates[1] != '') {
                                        var target = self.getTargetData(data[i].features[0].properties.msisdn)
                                        // console.log("coord")
                                        // console.log(parseFloat(data[i].features[0].geometry.coordinates[1])+ ', '+parseFloat(data[i].features[0].geometry.coordinates[0]))
                                        // console.log('target')
                                        // console.log(target)
                                        // console.log(target.label)
                                        self.mapsLatLngHistory[i] = new google.maps.LatLng(parseFloat(data[i].features[0].geometry.coordinates[1]), parseFloat(data[i].features[0].geometry.coordinates[0]));
                                        // target.id in self.mapsLatLngTarget || 
                                        if (!self.mapsLatLngTarget[target.id]){
                                            self.mapsLatLngTarget[target.id] = {};
                                        } 
                                        if (!self.mapsLatLngTarget[target.id].coor) {
                                            self.mapsLatLngTarget[target.id].coor = [];
                                        }
                                        if (!self.mapsLatLngTarget[target.id].label) {
                                            self.mapsLatLngTarget[target.id].label = '#fff';
                                        }
                                        
                                        self.mapsLatLngTarget[target.id].coor.push(new google.maps.LatLng(parseFloat(data[i].features[0].geometry.coordinates[1]), parseFloat(data[i].features[0].geometry.coordinates[0])))
                                        self.mapsLatLngTarget[target.id].label=target.label

                                        var icon = {
                                            // path: "m12 0c-4.962 0-9 4.066-9 9.065 0 7.103 8.154 14.437 8.501 14.745.143.127.321.19.499.19s.356-.063.499-.189c.347-.309 8.501-7.643 8.501-14.746 0-4.999-4.038-9.065-9-9.065zm0 14c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5z",
                                            path: 'M31.5766 15.7225C35.366 15.7225 38.4379 12.6506 38.4379 8.86127C38.4379 5.07189 35.366 2 31.5766 2C27.7872 2 24.7153 5.07189 24.7153 8.86127C24.7153 12.6506 27.7872 15.7225 31.5766 15.7225Z M18 26.8175V41.4161C18 43.1679 19.4599 44.4817 21.0657 44.4817H23.9854L25.5911 59.5183C25.7372 60.9782 26.905 62 28.3649 62H34.6423C36.1022 62 37.2701 60.978 37.4161 59.5183L39.0219 44.4817H41.9416C43.6934 44.4817 45.0073 43.1679 45.0073 41.4161V26.8175C45.1533 14.9926 18 14.9926 18 26.8175Z',
                                            // fillColor: self.marker_color[target.label],
                                            fillColor: target.label,
                                            // fillColor: '#ffffff',
                                            fillOpacity: 1,
                                            // strokeWeight: 1.2,
                                            strokeWeight: 2,
                                            strokeColor: '#ffffff',
                                            // scale: 1.25,
                                            scale: 0.75,
                                            anchor: new google.maps.Point(29,60),
                                        }

                                        let marker = new google.maps.Marker({
                                            position: new google.maps.LatLng(parseFloat(data[i].features[0].geometry.coordinates[1]), parseFloat(data[i].features[0].geometry.coordinates[0])),
                                            map: map,
                                            icon: icon,
                                            // draggable: true
                                        });

                                        // console.log(marker);
                                        self.markers_history.push(marker);

                                        // var dtm = (data[i].features[0].properties.dtm ? moment(data[i].features[0].properties.dtm, 'YYYY-MM-DDTHH:mm:ssZ').format('DD MMMM YYYY, hh:mm') : '-');
                                        var dtm = (data[i].features[0].properties.dtm ? self.datetimeFormat(data[i].features[0].properties.dtm) : '-');

                                        var contentString = self.createInfoWindow(target.name, data[i].features[0].properties.msisdn, dtm, (data[i].features[0].geometry.coordinates[1]+','+data[i].features[0].geometry.coordinates[0]));

                                        let infowindow = new google.maps.InfoWindow({
                                            content: contentString,
                                            maxWidth: 400
                                        });
                                        
                                        self.infoWindowsHistory.push(infowindow); 
                                        
                                        google.maps.event.addListener(marker, 'click', function() {
                                            self.hideAllInfoWindows(true);
                                            infowindow.open(map, marker);
                                        });
                                    }
                                    // Apply fitBounds
                                }
                                // console.log('self.mapsLatLngTarget')
                                // console.log(self.mapsLatLngTarget)
                                // console.log(Object.keys(self.mapsLatLngTarget).length)
                                if (Object.keys(self.mapsLatLngTarget).length > 0) {
                                    
                                    // for (var data in self.mapsLatLngTarget) {
                                    for (const [key, value] of Object.entries(self.mapsLatLngTarget)) {
                                        // console.log(key, value);
                                        // console.log(value);
                                        // console.log(value.coor);
                                        // console.log(value.label);
                                        
                                        // console.log('data')
                                        // console.log(data)
                                        // console.log(self.mapsLatLngHistory.data)
                                        // console.log(self.mapsLatLngHistory[data])
                                        self.polyline_history = new google.maps.Polyline({
                                            path: value.coor,
                                            strokeColor: value.label,
                                            strokeOpacity:0.8,
                                            strokeWeight:1.5
                                        });

                                        self.polyline_history.setMap(map);
                                    }
                                    // for (let index = 0; index < self.mapsLatLngTarget.length; index++) {
                                        // const element = array[index];
                                        // self.polyline_history = new google.maps.Polyline({
                                        //     path: self.mapsLatLngHistory,
                                        //     strokeColor: '#FF0000',
                                        //     strokeOpacity:0.8,
                                        //     strokeWeight:1.5
                                        // });

                                        // self.polyline_history.setMap(map);   
                                    // }
                                }
                            }

                            var bounds = new google.maps.LatLngBounds();

                            if (self.markers_history.length > 0) {
                                // console.log('markers_history');
                                for (var j = 0; j < self.markers_history.length; j++) {
                                    bounds.extend(new google.maps.LatLng(self.markers_history[j].position.lat(), self.markers_history[j].position.lng()));
                                }
                            }

                            // Apply fitBounds
                            map.fitBounds(bounds); 
                        });
                    });
            }
        },
        setEmptyMap() {
            // map history
            this.mapHistoryLoaded = false;
            this.infoWindowsHistory = [];
            this.markers_history = [];
            this.mapsLatLngHistory = []; // map history
            this.mapsLatLngTarget = {}; // map history
            this.polyline_history = null; // map history

            // osm
            this.locations = [];
            this.polyline = {
                latlngs:[],
                color:'red'
            };
        },
    },
    watch: {
        location_es_list(newData) {
            this.setEmptyMap();
            // this.mapHistoryLoaded = false;
            if (Object.keys(newData).length > 0) {
                this.total_data = parseInt(newData.total_data);
            } else {
                this.total_data = 0;
            }
            if (this.location_es_list.data && this.location_es_list.data.length > 0) {
                this.getDataCsv(this.location_es_list.data)
                this.startMapHistory();        
            }
        },
        total_data() {
            // this.pagination();
            if (isNaN(this.total_data)) {            
                this.total_data = 0
            }
        },
        items_per_page() {
            this.getData();
            // this.pagination();
        },
        page() {
            this.getData();
        },
        keyword() {},
        multi_tracking_detail() {
            // if (this.multi_tracking_detail != undefined && this.multi_tracking_detail.target != undefined) {
            if (this.multi_tracking_detail != undefined && this.multi_tracking_detail.target_history != undefined) {
                this.setData(this.multi_tracking_detail)
            }
        },
        filterTarget(){
            this.getData();
        },
        route(newData, oldData) {
            this.setEmptyMap();
            // this.mapHistoryLoaded = false;
            if (newData.name === 'pageMultiTrackingDetail') {
                if (oldData !== 'PageMultiTrackingDetail') {
                    this.optFilterTarget= []
                    this.filterTarget= ''
                    this.paramMsisdn= []
                    this.items_per_page = 10
                    this.page = 0
                    this.keyword = ''
                    this.$store.commit('multiTracking/setMonitoringList', {})
                    this.$store.commit('multiTracking/setStatusMonitoringList', {cancel: null, code: null, status: 'loading'})
                }

                // if (this.multi_tracking_detail != undefined && this.multi_tracking_detail.target != undefined) {
                if (this.multi_tracking_detail != undefined && this.multi_tracking_detail.target_history != undefined) {
                    this.setData(this.multi_tracking_detail)
                }
            }
        },
    },
    filters: {
        date: function (date) {
            return moment(date).format('YYYY-MM-DD');
        }
    },
    mounted() {
        this.setEmptyMap();
        // this.mapHistoryLoaded = false;
        if (this.route.matched[1].name === 'pageMultiTrackingDetail') {
            // reset date filter
            const default_date = this.$store.getters['config/getDefaultDate']();

            this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
            this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();

            this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');

            // if (this.multi_tracking_detail != undefined && this.multi_tracking_detail.target != undefined) {
            if (this.multi_tracking_detail != undefined && this.multi_tracking_detail.target_history != undefined) {
                this.setData(this.multi_tracking_detail)
            }
        }
    },
}
</script>
<style lang="sass" scoped>
    .btn-primary
        cursor: pointer !important
</style>