<template lang="pug">
.result-page__head.pt-4.flex.justify-between
    //- mobile only displaying the key of search
        example, search MSISDN 081234567890
    .m-only
        .flex.items-center.justify-between
            .block 
                span.text-xs MSISDN
                template(v-if="status_subheader.status === 'loading'")
                    Spinner(show="true" size="22")
                p.text-light(v-else) {{ subheader.msisdn ? subheader.msisdn : '-' }}
            .block
            button.btn-icon-40(
                v-on:click="showHead = !showHead"
            )
                template(v-if="showHead === false")
                    IconSet(type="chevron-down")
                template(v-else)
                    IconSet(type="chevron-up")
    //- end
    .m-head-menu.flex.items-center(
        v-bind:class="{'is-show' : showHead}"
    )
        .rollout
            figure.w-12.h-12
                //- img.w-full.h-full.object-fit.rounded(src="https://static.bmdstatic.com/pk/product/medium/5de487ad80876.jpg")
                img.w-full.h-full.object-fit.rounded(:src="subheader.photo ? subheader.photo : defaultImage" @error="getDefaultImage")
        .rollout.rollout--name
            span.text-xs Name
            template(v-if="status_subheader.status === 'loading'")
                Spinner(show="true" size="22")
            p.text-light(v-else) {{ subheader.name ? subheader.name : '-' }}
        .rollout
            span.text-xs MSISDN
            template(v-if="status_subheader.status === 'loading'")
                Spinner(show="true" size="22")
            .flex.justify-between.w-full(v-else)
                .flex-grow.flex.items-center
                    //- span.inline-block.text-red-600.text-xs(v-if="subheader.state == 'unknown' || subheader.state == 'switch off' || subheader.aol == '-1' || (subheader.aol == null && subheader.state == null)")
                    //-     | [OFFLINE]
                    //- span.inline-block.text-green-600.text-xs(v-else)
                    //-     | [ONLINE]
                    span.inline-block.text-green-600.text-xs(v-if="subheader.state == 'idle' || subheader.state == 'busy'")
                        | [ONLINE]
                    span.inline-block.text-red-600.text-xs(v-else)
                        | [OFFLINE]
                    .mr-1
                    span.text-right {{ subheader.msisdn ? subheader.msisdn : '-' }}
        .rollout
            span.text-xs IMEI
            template(v-if="status_subheader.status === 'loading'")
                Spinner(show="true" size="22")
            p.text-light(v-else) {{ subheader.imei ? subheader.imei : '-' }}
        .rollout
            span.text-xs IMEI-SV
            template(v-if="status_subheader.status === 'loading'")
                Spinner(show="true" size="22")
            p.text-light(v-else) {{ subheader.imeiSv ? subheader.imeiSv : '-' }}
        .rollout
            span.text-xs IMSI
            template(v-if="status_subheader.status === 'loading'")
                Spinner(show="true" size="22")
            p.text-light(v-else) {{ subheader.imsi ? subheader.imsi : '-' }}
        .rollout
            span.text-xs Device
            template(v-if="status_subheader.status === 'loading'")
                Spinner(show="true" size="22")
            p.text-light(v-else) {{ subheader.phone ? subheader.phone : '-' }}
    .block 
        button.btn-primary(type='submit') Add As Target
</template>


<script>
import { mapState } from 'vuex';
import IconSet from '@/components/IconSet.vue';
import Spinner from '@/components/Spinner.vue';

export default {
    name: 'ResultComponentsHead',
    components: {
        IconSet,
        Spinner,
    },
    data() {
        return {
            showHead : false,
            defaultImage: '/images/no-image-device.jpg',
        }
    },
    computed: {
        ...mapState('subheader', [
            'status_subheader',
            'subheader',
        ]),
    },
    methods: {
        getDefaultImage(e) {
            e.target.src = this.defaultImage;
        },
    },
}
</script>

<style lang="sass" scoped>
    .rollout
        @apply .mr-10 .mb-0
        &--name
            width: auto
            min-width: 140px

    @media screen and (max-width: 480px)
        .result-page__head
            padding: 1rem
        .m-head-menu
            display: none
            padding: 1rem 0rem
            &.is-show
                display: block
                
</style>