import axios from "@/axios-main";
import axiosOrigin from "axios";
import { env } from '@/config/env';

let cancelBrowsingLog, cancelLog, cancelAccessDomain, cancelIpAddress, cancelUsage, cancelDuration, cancelHistory;
var cancelToken = axiosOrigin.CancelToken;

const browsingLog = {
  namespaced: true,
  state: {
    module:'Browsing Log',
    default_range: env.VUE_APP_BROWSING_RANGE?env.VUE_APP_BROWSING_RANGE:6,
    default_export: env.VUE_APP_BROWSING_FILTER?env.VUE_APP_BROWSING_FILTER:'all',

    record_size: null,
    from_date: null,
    to_date: null,

    status: false,
    status_browsing_log: {
      status: false,
      cancel: null,
      code: null
    },
    status_log: {
      status: false,
      cancel: null,
      code: null
    },
    status_access_domain: {
      status: false,
      cancel: null,
      code: null
    },
    status_ip_address: {
      status: false,
      cancel: null,
      code: null
    },
    status_usage: {
      status: false,
      cancel: null,
      code: null
    },
    status_duration: {
      status: false,
      cancel: null,
      code: null
    },
    status_history: {
      status: false,
      cancel: null,
      code: null
    },

    log: {},
    access_domain: {},
    ip_address: {},
    usage: {},
    duration: {},
    history: {},
  },
  mutations: {
    setRecordSize: (state, data) => {
      state.record_size = data;
    },
    setFromDate: (state, data) => {
      state.from_date = data;
    },
    setToDate: (state, data) => {
      state.to_date = data;
    },

    setStatus: (state, status) => {
      state.status = status;
    },
    setStatusBrowsingLog: (state, status) => {
      state.status_browsing_log = status;
    },
    setStatusLog: (state, status) => {
      state.status_log = status;
    },
    setStatusAccessDomain: (state, status) => {
      state.status_access_domain = status;
    },
    setStatusIpAddress: (state, status) => {
      state.status_ip_address = status;
    },
    setStatusUsage: (state, status) => {
      state.status_usage = status;
    },
    setStatusDuration: (state, status) => {
      state.status_duration = status;
    },
    setStatusHistory: (state, status) => {
      state.status_history = status;
    },

    setLog: (state, data) => {
      state.log = data;
    },
    setAccessDomain: (state, data) => {
      state.access_domain = data;
    },
    setIpAddress: (state, data) => {
      state.ip_address = data;
    },
    setUsage: (state, data) => {
      state.usage = data;
    },
    setDuration: (state, data) => {
      state.duration = data;
    },
    setHistory: (state, data) => {
      state.history = data;
    },
  },
  getters: {},
  actions: {
    async getResultData({ state, rootGetters, commit, dispatch }, [key, type, service_source=null]) {

      const default_date = rootGetters['config/getDefaultDate'](state.default_range);

      let record_size = state.record_size!==null ? state.record_size : rootGetters['config/getDefaultRecordSize'];
      let from_date = state.from_date!==null ? state.from_date : default_date.from_date;
      let to_date = state.to_date!==null ? state.to_date : default_date.to_date;

      await dispatch('setLoading');
      
      await dispatch('getBrowsingLog', [key, type, record_size, from_date, to_date, service_source]);
      // await dispatch('getLog', [key, type, record_size, from_date, to_date]);
      // await dispatch('getAccessDomain', [key, type, record_size, from_date, to_date]);
      // await dispatch('getIpAddress', [key, type, record_size, from_date, to_date]);
      // await dispatch('getUsage', [key, type, record_size, from_date, to_date]);
      // await dispatch('getDuration', [key, type, record_size, from_date, to_date]);
      // await dispatch('getHistory', [key, type, record_size, from_date, to_date]);
      
      commit('setStatus', true);
    },

    async setLoading({ commit, state }) {
      await commit('setStatus', 'loading');

      var loading = function(sub_mod) {
        return {
          status: 'loading',
          cancel: state[sub_mod].cancel,
          code: null
        };
      };

      await commit('setStatusBrowsingLog', loading('status_browsing_log'));
      await commit('setStatusLog', loading('status_log'));
      await commit('setStatusAccessDomain', loading('status_access_domain'));
      await commit('setStatusIpAddress', loading('status_ip_address'));
      await commit('setStatusUsage', loading('status_usage'));
      await commit('setStatusDuration', loading('status_duration'));
      await commit('setStatusHistory', loading('status_history'));
    },

    async setEmpty({ state, commit }) {
      commit('setStatus', false);

      let default_status = {
        status: false,
        cancel: null,
        code: null
      };

      if (state.status_browsing_log.cancel !== null && state.status_browsing_log.status === 'loading')
        await state.status_browsing_log.cancel('Operation canceled by the user.');
      commit('setStatusBrowsingLog', default_status);

      if (state.status_log.cancel !== null && state.status_log.status === 'loading')
        await state.status_log.cancel('Operation canceled by the user.');
      commit('setStatusLog', default_status);

      if (state.status_access_domain.cancel !== null && state.status_access_domain.status === 'loading')
        await state.status_access_domain.cancel('Operation canceled by the user.');
      commit('setStatusAccessDomain', default_status);

      if (state.status_ip_address.cancel !== null && state.status_ip_address.status === 'loading')
        await state.status_ip_address.cancel('Operation canceled by the user.');
      commit('setStatusIpAddress', default_status);

      if (state.status_usage.cancel !== null && state.status_usage.status === 'loading')
        await state.status_usage.cancel('Operation canceled by the user.');
      commit('setStatusUsage', default_status);

      if (state.status_duration.cancel !== null && state.status_duration.status === 'loading')
        await state.status_duration.cancel('Operation canceled by the user.');
      commit('setStatusDuration', default_status);

      if (state.status_history.cancel !== null && state.status_history.status === 'loading')
        await state.status_history.cancel('Operation canceled by the user.');
      commit('setStatusHistory', default_status);

      commit('setLog', {});
      commit('setAccessDomain', {});
      commit('setIpAddress', {});
      commit('setUsage', {});
      commit('setDuration', {});
      commit('setHistory', {});
    },

    async getBrowsingLog({ commit, dispatch, state }, [key, type, record_size, from_date, to_date, service_source]) {

      if (state.status_browsing_log.cancel !== null && state.status_browsing_log.status === 'loading')
        await state.status_browsing_log.cancel('Operation canceled by the user.');
      
      let service = 'service';
      if (service_source === 'internal') {
        service = 'service-int'
      } else if (service_source === 'external') {
        service = 'service-ext'
      }

      let params = new FormData();
      params.append('key', key);
      params.append('type', type);
      params.append('size', record_size);
      params.append('from_date', from_date);
      params.append('to_date', to_date);
    
      await axios.post('api/v1/'+service+'/overall/browsing-all', params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelBrowsingLog = c;
          commit('setStatusBrowsingLog', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        // console.log(resp.data)
        let data_all = resp.data || {};
        let data = {};
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        // browsing log -------------------------------------
        data = data_all?.browsing_log || {};
        result.status = true;

        commit('setLog', data);

        if (Object.keys(data).length===0 || data.chart_browser.categories === undefined)
          result.status = 'empty';

        commit('setStatusLog', result);

        // browsing access domain -------------------------------------
        data = data_all?.browsing_access_domain || {};
        result.status = true;

        commit('setAccessDomain', data);

        if (Object.keys(data).length===0 || data.chart_access_app_cat.categories === undefined)
          result.status = 'empty';

        commit('setStatusAccessDomain', result);

        // browsing ip address -------------------------------------
        data = data_all?.browsing_ip_address || {};
        result.status = true;

        commit('setIpAddress', data);

        if (Object.keys(data).length===0 || data.chart_client_ip.categories === undefined)
          result.status = 'empty';

        commit('setStatusIpAddress', result);

        // browsing usage -------------------------------------
        data = data_all?.browsing_usage || {};
        result.status = true;

        commit('setUsage', data);

        if (Object.keys(data).length===0 || data.chart_usage_app_cat.categories === undefined)
          result.status = 'empty';

        commit('setStatusUsage', result);

        // browsing duration -------------------------------------
        data = data_all?.browsing_duration || {};
        result.status = true;

        commit('setDuration', data);

        if (Object.keys(data).length===0 || data.chart_duration_app_cat.categories === undefined)
          result.status = 'empty';

        commit('setStatusDuration', result);

        // browsing history -------------------------------------
        data = data_all?.browsing_history || [];
        result.status = true;

        commit('setHistory', data);

        if (data.length===0)
          result.status = 'empty';

        commit('setStatusHistory', result);

      })
      .catch((resp) => {
        // console.log(resp)
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelBrowsingLog;
        }

        commit('setStatusBrowsingLog', result);
        commit('setStatusLog', result);
        commit('setStatusAccessDomain', result);
        commit('setStatusIpAddress', result);
        commit('setStatusUsage', result);
        commit('setStatusDuration', result);
        commit('setStatusHistory', result);
        if (!axiosOrigin.isCancel(resp)){
          // dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null),state.module], { root: true });
          let respMsg = resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null
          if(respMsg!=null && result.code != 500) {
            dispatch('errors/handleApiResponse', [result.code, respMsg,state.module], { root: true });
          }
        } 
      });
    },

    async getLog({ commit, dispatch, state }, [key, type, record_size, from_date, to_date]) {

      if (state.status_log.cancel !== null && state.status_log.status === 'loading')
        await state.status_log.cancel('Operation canceled by the user.');

      let params = new FormData();
      params.append('key', key);
      params.append('type', type);
      params.append('size', record_size);
      params.append('from_date', from_date);
      params.append('to_date', to_date);

      await axios.post('api/v1/service/overall/browsing-log', params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelLog = c;
          commit('setStatusLog', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };
        
        commit('setLog', data);

        if (data.chart_browser.categories === undefined)
          result.status = 'empty';

        commit('setStatusLog', result);
      })
      .catch((resp) => {
        // console.log(resp)
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelLog;
        }

        commit('setStatusLog', result);
        if (!axiosOrigin.isCancel(resp)) 
        dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
      });
    },

    async getAccessDomain({ commit, dispatch, state }, [key, type, record_size, from_date, to_date]) {

      if (state.status_access_domain.cancel !== null && state.status_access_domain.status === 'loading')
        await state.status_access_domain.cancel('Operation canceled by the user.');

      let params = new FormData();
      params.append('key', key);
      params.append('type', type);
      params.append('size', record_size);
      params.append('from_date', from_date);
      params.append('to_date', to_date);

      await axios.post('api/v1/service/overall/browsing-access-domain', params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelAccessDomain = c;
          commit('setStatusAccessDomain', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };
        
        commit('setAccessDomain', data);

        if (data.chart_access_app_cat.categories === undefined)
          result.status = 'empty';

        commit('setStatusAccessDomain', result);
      })
      .catch((resp) => {
        // console.log(resp)
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelAccessDomain;
        }

        commit('setStatusAccessDomain', result);
        if (!axiosOrigin.isCancel(resp)) 
        dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
      });
    },

    async getIpAddress({ commit, dispatch, state }, [key, type, record_size, from_date, to_date]) {

      if (state.status_ip_address.cancel !== null && state.status_ip_address.status === 'loading')
        await state.status_ip_address.cancel('Operation canceled by the user.');

      let params = new FormData();
      params.append('key', key);
      params.append('type', type);
      params.append('size', record_size);
      params.append('from_date', from_date);
      params.append('to_date', to_date);

      await axios.post('api/v1/service/overall/browsing-ip-address', params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelIpAddress = c;
          commit('setStatusIpAddress', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setIpAddress', data);

        if (data.chart_client_ip.categories === undefined)
          result.status = 'empty';

        commit('setStatusIpAddress', result);
      })
      .catch((resp) => {
        // console.log(resp)
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelIpAddress;
        }

        commit('setStatusIpAddress', result);
        if (!axiosOrigin.isCancel(resp)) 
        dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
      });
    },

    async getUsage({ commit, dispatch, state }, [key, type, record_size, from_date, to_date]) {

      if (state.status_usage.cancel !== null && state.status_usage.status === 'loading')
        await state.status_usage.cancel('Operation canceled by the user.');

      let params = new FormData();
      params.append('key', key);
      params.append('type', type);
      params.append('size', record_size);
      params.append('from_date', from_date);
      params.append('to_date', to_date);

      await axios.post('api/v1/service/overall/browsing-usage', params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelUsage = c;
          commit('setStatusUsage', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setUsage', data);

        if (data.chart_usage_app_cat.categories === undefined)
          result.status = 'empty';

        commit('setStatusUsage', result);
      })
      .catch((resp) => {
        // console.log(resp)
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelUsage;
        }

        commit('setStatusUsage', result);
        if (!axiosOrigin.isCancel(resp)) 
        dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
      });
    },

    async getDuration({ commit, dispatch, state }, [key, type, record_size, from_date, to_date]) {

      if (state.status_duration.cancel !== null && state.status_duration.status === 'loading')
        await state.status_duration.cancel('Operation canceled by the user.');

      let params = new FormData();
      params.append('key', key);
      params.append('type', type);
      params.append('size', record_size);
      params.append('from_date', from_date);
      params.append('to_date', to_date);

      await axios.post('api/v1/service/overall/browsing-duration', params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelDuration = c;
          commit('setStatusDuration', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setDuration', data);

        if (data.chart_duration_app_cat.categories === undefined)
          result.status = 'empty';

        commit('setStatusDuration', result);
      })
      .catch((resp) => {
        // console.log(resp)
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelDuration;
        }

        commit('setStatusDuration', result);
        if (!axiosOrigin.isCancel(resp)) 
        dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
      });
    },

    async getHistory({ commit, dispatch, state }, [key, type, record_size, from_date, to_date]) {

      if (state.status_history.cancel !== null && state.status_history.status === 'loading')
        await state.status_history.cancel('Operation canceled by the user.');

      let params = new FormData();
      params.append('key', key);
      params.append('type', type);
      params.append('size', record_size);
      params.append('from_date', from_date);
      params.append('to_date', to_date);

      await axios.post('api/v1/service/overall/browsing-history', params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelHistory = c;
          commit('setStatusHistory', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setHistory', data);

        if (data.length===0)
          result.status = 'empty';

        commit('setStatusHistory', result);
      })
      .catch((resp) => {
        // console.log(resp)
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelHistory;
        }

        commit('setStatusHistory', result);
        if (!axiosOrigin.isCancel(resp))
        dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
      });
    },
  },
};

export default browsingLog;
