<template lang="pug">
.card
    .card__header
        h2.text-xl Personal Information
    .card__body
        template(v-if="status_profile_identity.status === 'loading'")
            Loading(show="true")

        .grid(class="grid-cols-1 lg:grid-cols-4" v-else)
            .block
                .rollout
                    span.text-xs Name
                    p.text-light.uppercase
                        | {{ profile_identity.name ? profile_identity.name : '-' }}
                        //- router-link.link-text(v-if="profile_identity.name && profile_identity.name.length > 0" :to="'/search-by-name/'+ encrypt(profile_identity.name, 'name')" target="_blank")
                        //-     | {{ profile_identity.name }}
                        //- template(v-else) -
                .rollout
                    span.text-xs Gender
                    p.text-light.uppercase {{ profile_identity.gender ? profile_identity.gender : '-' }}
                .rollout
                    span.text-xs Religion
                    p.text-light.uppercase {{ profile_identity.religion ? profile_identity.religion : '-' }}
                .rollout
                    span.text-xs Marital
                    p.text-light.uppercase {{ profile_identity.marital_status ? profile_identity.marital_status : '-' }}
                .rollout
                    span.text-xs Job
                    p.text-light.uppercase {{ profile_identity.occupation ? profile_identity.occupation : '-' }}
                .rollout
                    span.text-xs Blood Type
                    p.text-light.uppercase {{ profile_identity.blood ? profile_identity.blood : '-' }}
                

            .block
                .rollout
                    span.text-xs DOB
                    p.text-light.uppercase {{ profile_identity.birth_date ? profile_identity.birth_date : '-' }}
                .rollout
                    span.text-xs POB
                    p.text-light.uppercase {{ profile_identity.birth_place ? profile_identity.birth_place : '-' }}
                .rollout
                    //- span.text-xs KTP/NIK
                    span.text-xs Citizen ID
                    p.text-light.uppercase {{ profile_identity.ktp ? profile_identity.ktp : '-' }}
                .rollout
                    //- span.text-xs NKK
                    span.text-xs Family ID
                    p.text-light.uppercase {{ profile_identity.family_id ? profile_identity.family_id : '-' }}
                .rollout
                    span.text-xs Mother
                    p.text-light.uppercase {{ profile_identity.mothers_name ? profile_identity.mothers_name : '-' }}
                .rollout
                    span.text-xs Father
                    p.text-light.uppercase {{ profile_identity.fathers_name ? profile_identity.fathers_name : '-' }}
                .rollout
                    span.text-xs Status
                    p.text-light.uppercase {{ profile_identity.status ? profile_identity.status : '-' }}
            
            .block
                .rollout
                    span.text-xs Province
                    p.text-light.uppercase {{ profile_identity.address_province ? profile_identity.address_province : '-' }}
                .rollout
                    span.text-xs Address
                    p.text-light.uppercase {{ profile_identity.address ? profile_identity.address : '-' }}
                .rollout
                    span.text-xs City
                    p.text-light.uppercase {{ profile_identity.address_district ? profile_identity.address_district : '-' }}
                .rollout
                    span.text-xs Sub Distrcit
                    p.text-light.uppercase {{ profile_identity.address_sub_district ? profile_identity.address_sub_district : '-' }}
                .rollout
                    span.text-xs Village
                    p.text-light.uppercase {{ profile_identity.address_urban_village ? profile_identity.address_urban_village : '-' }}
                .rollout
                    //- span.text-xs RT/RW
                    span.text-xs NH-ID/CA-ID
                    p.text-light.uppercase {{ profile_identity.rt_rw ? profile_identity.rt_rw : '-' }}
                .rollout
                    span.text-xs ZIP
                    p.text-light.uppercase {{ profile_identity.zip ? profile_identity.zip : '-' }}

            //- .block
                .rollout
                    span.text-xs Nationality
                    p.text-light.uppercase {{ profile_identity.nationality ? profile_identity.nationality : '-' }}
                .rollout
                    span.text-xs NOK
                    p.text-light.uppercase {{ profile_identity.nok ? profile_identity.nok : '-' }}
                .rollout
                    span.text-xs Print At
                    p.text-light.uppercase {{ profile_identity.print ? profile_identity.print : '-' }}
                .rollout
                    span.text-xs Zodiac
                    p.text-light.uppercase {{ profile_identity.zodiac ? profile_identity.zodiac : '-' }}
                
            //- .block
                .rollout
                    span.text-xs Phone
                    template(v-if="profile_identity.phone && profile_identity.phone.length > 0 && profile_identity.phone[0]")
                        ul
                            li.mb-2(v-for="item in profile_identity.phone")
                                .label-green {{ item }}
                    template(v-else)
                        p.text-gray-600.text-xs.italic Phone number not found

            //- .block(v-if="profile_identity.photo && profile_identity.photo != '-'")
                .avatar.w-32
                    img.w-full(:src="(profile_identity.photo && profile_identity.photo != '-') ? checkPhotoBase64(profile_identity.photo) : defaultImage" @error="getDefaultImage" alt="alt")


</template>

<script>
import { mapState } from 'vuex';
import Loading from '@/components/Loading.vue';
import LoadingAnimation from '@/components/LoadingAnimation.vue';
import { encrypt } from '@/util/crypto';

export default {
    name: 'ResultProfilePersonal',
    components: {
        Loading,
        LoadingAnimation
    },
    data: function() {
        return {
            defaultImage: this.$store.getters['config/getDefaultImageProfile'],
        };
    },
    computed: {
        ...mapState('profile', [
            'status_profile_identity',
            'profile_identity',
        ]),
        route_name() {
            return this.$route.name;
        },           
    },
    methods: {
        checkPhotoBase64(photo) {
            if (!(photo && photo.substring(0, 11)=='data:image/')){
                photo = 'data:image/jpeg;base64,'+photo
            }
            return photo
        },
        getDefaultImage(e) {
            e.target.src = this.defaultImage;
        },
        encrypt(key, type) {
            return encrypt(key + '&&' + type);
        },
    },
}
</script>