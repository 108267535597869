import axios from "@/axios-main";
import axiosOrigin from "axios";
import { env } from "../../config/env";

let cancelList;
var cancelToken = axiosOrigin.CancelToken;

const license = {
  namespaced: true,
  state: {
    // load data license
    status_list: {
      status: false,
      cancel: null,
      code: null,
    },
    status_source_list: {
      status: false,
      cancel: null,
      code: null,
    },
    status_detail: false,

    // license
    list: {},
    source_list: {},
    detail: {},
  },
  mutations: {
    // load data license
    setStatusList: (state, status) => {
      state.status_list = status;
    },
    setStatusDetail: (state, status) => {
      state.status_detail = status;
    },
    setStatusSourceList: (state, status) => {
      state.status_source_list = status;
    },

    // license
    setList: (state, data) => {
      state.list = data;
    },
    setDetail: (state, data) => {
      state.detail = data;
    },

    // source list
    setSourceList: (state, data) => {
      state.source_list = data;
    },
  },
  getters: {},
  actions: {
    async getList({ state, commit, dispatch }, [limit=null, page=null, client_id=null, status=null]) {

      if (state.status_list.cancel !== null && state.status_list.status === 'loading')
        await state.status_list.cancel('Operation canceled by the user.');

      let params = new FormData();
      params.append('limit', limit);
      params.append('page', page);
      params.append('clientId', client_id);
      params.append('sortDirection', 'DESC');
      // params.append('status', 'inactive');
      // params.append('sortBy', 'createdAt');

      // delete this if v1
      params = new URLSearchParams(params).toString()

      const api_path = env.VUE_APP_API_VERSION_LICENSE == 'v2' ? 'v2/license_service/list' : 'api/v1/license/list-license';

      // await axios.post('api/v1/license/list-license', params, {
      await axios.post(api_path, params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelList = c;
          commit('setStatusList', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        let data = resp?.data?.code ? resp?.data?.data : resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setList', data);

        if (Object.keys(data).length===0)
          result.status = 'empty';

        commit('setStatusList', result);
      })
      .catch((resp) => {
        // console.log(resp)
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelList;
        }

        commit('setStatusList', result);
        if (!axiosOrigin.isCancel(resp)) 
          dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
      });
    },

    getDetail({ commit, dispatch }, id) {

      let params = new FormData();
      params.append('id', id);

      // delete this if v1
      params = new URLSearchParams(params).toString()
    
      const api_path = env.VUE_APP_API_VERSION_LICENSE == 'v2' ? 'v2/license_service/detail' : 'api/v1/license/detail-license';
      return axios.post(api_path, params)
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        commit('setDetail', data);
        return 'success';
      })
      .catch((resp) => {
        // console.log(resp)
        dispatch('errors/handleApiResponse', [(resp.response && resp.response.status ? resp.response.status : null)], { root: true });
        return 'failed';
      });
    },

    async getSourceList({ commit, dispatch }) {

      // let params = new FormData();
      // params.append('id', id);
    
      const api_path = env.VUE_APP_API_VERSION_LICENSE == 'v2' ? 'v2/license_service/source-list' : 'api/v1/license/source-list';
      await axios.get(api_path, {
        cancelToken: new cancelToken(function executor(c) {
          cancelList = c;
          commit('setStatusSourceList', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        console.log('data source')
        console.log({data})
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setSourceList', data);

        if (Object.keys(data).length===0)
          result.status = 'empty';

        commit('setStatusSourceList', result);
      })
      .catch((resp) => {
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelList;
        }

        commit('setStatusSourceList', result);
        if (!axiosOrigin.isCancel(resp)) 
          dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
      });
    },

    create({ commit, dispatch }, [
      // client_id='', 
      // client_name='', 
      license_name='', 
      license_key='', 
      quantity='', 
      installed_in='', 
      binded_in='', 
      status='', 
      activated_date='',
      expired_date='',
      source_label=''
    ]) {

      let params = new FormData();
      // params.append('clientId', client_id);
      // params.append('clientName', client_name);
      params.append('licenseName', license_name);
      params.append('licenseKey', license_key);
      params.append('qty', quantity);
      params.append('installedIn', installed_in);
      params.append('bindedIn', binded_in);
      params.append('status', status);
      params.append('activatedDate', activated_date || '-');
      params.append('expiredDate', expired_date || '-');
      params.append('sourceLabel', source_label);

      params = new URLSearchParams(params).toString()

      const api_path = env.VUE_APP_API_VERSION_LICENSE == 'v2' ? 'v2/license_service/create' : 'api/v1/license/create-license';
      return axios.post(api_path, params)
      .then((resp) => {

        // if (resp.status === 200 && resp.data.message === 'Data Successfully Created') {
        if (resp.status === 200 && (resp.data && resp.data.message.toLowerCase() === 'success')) {
          return {result: 'success', data: resp.data};
        } else {
          return {result: 'failed', data: resp.data};
        }
      })
      .catch((resp) => {
        // console.log(resp)
        dispatch('errors/handleApiResponse', [(resp.response && resp.response.status ? resp.response.status : null)], { root: true });
        return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
      });
    },

    update({ commit, dispatch }, [
      id,
      // client_id='', 
      // client_name='', 
      license_name='', 
      license_key='', 
      quantity='', 
      installed_in='', 
      binded_in='', 
      status='', 
      activated_date='',
      expired_date='',
      source_label=''
    ]) {

      let params = new FormData();
      params.append('id', id);
      // params.append('clientId', client_id);
      // params.append('clientName', client_name);
      params.append('licenseName', license_name);
      params.append('licenseKey', license_key);
      params.append('qty', quantity);
      params.append('installedIn', installed_in);
      params.append('bindedIn', binded_in);
      params.append('status', status);
      params.append('activatedDate', activated_date || '-');
      params.append('expiredDate', expired_date || '-');
      params.append('sourceLabel', source_label);

      // delete this if v1
      params = new URLSearchParams(params).toString()
    
      const api_path = env.VUE_APP_API_VERSION_LICENSE == 'v2' ? 'v2/license_service/update' : 'api/v1/license/update-license';
      return axios.post(api_path, params)
      .then((resp) => {
        // console.log(resp.data)
        
        // if (resp.status === 200 && resp.data.message === 'Data Successfully Updated') {
        if (resp.status === 200 && (resp.data && resp.data.message.toLowerCase() === 'success')) {
          return {result: 'success', data: resp.data};
        } else {
          return {result: 'failed', data: resp.data};
        }
      })
      .catch((resp) => {
        // console.log(resp)
        dispatch('errors/handleApiResponse', [(resp.response && resp.response.status ? resp.response.status : null)], { root: true });
        return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
      });
    },

    // delete({ commit, dispatch }, id) {

    //   let params = new FormData();
    //   params.append('id', id);

    //   return axios.post('api/v1/license/delete', params)
    //   .then((resp) => {
    //     // console.log(resp)
        
    //     if (resp.status === 200 && resp.data.message === 'Data Successfully Deleted') {
    //       return 'success';
    //     } else {
    //       return 'failed';
    //     }
    //   })
    //   .catch((resp) => {
    //     // console.log(resp)
    //     dispatch('errors/handleApiResponse', [(resp.response && resp.response.status ? resp.response.status : null)], { root: true });
    //     return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
    //   });

    // },
  }
};

export default license;
