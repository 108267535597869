<template lang="pug">
.mb-8
    .card
        header.card__header.mb-4
            h2.text-xl {{ title }} from {{datetimeFormat(from_date,'DD MMM YYYY')}} to {{datetimeFormat(to_date,'DD MMM YYYY')}}
        .card__body.container-timeline
            template(v-if="status_aerial_log.status === 'loading'")
                template(v-if="['TargetDetailAerialLog', 'TargetDetailLocationMovement'].includes(route_name)")
                    Loading(show="true")
                template(v-else)
                    LoadingAnimation(show="true" title="timeline-activity")            
            ul.box-aerial-timeline(
                v-else-if="aerial_log.aerial_activity && Object.keys(aerial_log.aerial_activity).length > 0"
            )
                li(v-for="(data,index) in aerial_log.aerial_activity" :key="index")
                    .location-name.text-light.cursor-pointer(
                        @click="openMap((index), data.coordinates)"
                        v-tooltip="'Show in Maps'"
                    ) {{ data.address }}
                    span.block.mx-4 {{ data.datetime }}
                    span.bubble-notif.rounded-2xl.bg-green-600.text-white.cursor-pointer(
                        v-if="data?.datetime_detail && data.datetime_detail.length > 1"
                        v-tooltip="'Datetime Detail'"
                        @click="openDetailDatetime(true, data.datetime_detail)"
                    ) {{ data.datetime_detail.length }}
                    template(v-if="data.coordinates && data.coordinates[0] && data.coordinates[1]")
                        a.btn-small.btn-default.ml-2(:href="'https://www.google.com/maps?q='+data.coordinates[0]+','+data.coordinates[1]" target='_blank' v-tooltip="'Google Maps'")
                            IconSet(type="maps")
                    template(v-else)
                        | -
            NoDataAvailable(v-else)
    //- popup
    .popup-layer(
        v-bind:class="{'is-show' : dtmDetail}"
    )
        .popup-container
            .popup-container__header
                h2.text-xl Datetime Detail
                button.btn-icon-40(
                    @click="openDetailDatetime(false)"
                )
                    IconSet( type="close")

            .popup-container__body
                .py-2(v-for="data in dtmData")
                    span {{ data }}

</template>    

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import IconSet from '@/components/IconSet.vue'
import Loading from '@/components/Loading.vue';
import LoadingAnimation from '@/components/LoadingAnimation.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';
export default {
    name: 'ResultAerialLogTimelineActivity',
    props: {
        title: {
            type: String,
            default: 'Aerial Log Activity'
        },
    },
    components: {
        IconSet,
        Loading,
        NoDataAvailable,
        LoadingAnimation
    },
    data(){
        return {
            dtmDetail: false,
            dtmData: [],
        };
    },
    computed: {
        ...mapState('aerialLog', [
            'status',
            'from_date',
            'to_date',
            'status_aerial_log',
            'aerial_log',
        ]),
        route_name() {
            return this.$route.name;
        },
    },
    watch: {
    },
    methods: {
        datetimeFormat(datetime, format='DD MMMM YYYY HH:mm:ss') {
            return moment(datetime, 'YYYY-MM-DD HH:mm:ssZ').format(format);
        },
        openDetailDatetime(open = true, data=[]) {
            if(open) {
                this.dtmDetail = true;
                EventEmit.$emit('overflowHidden', true);
            } else {
                this.dtmDetail = false;
                EventEmit.$emit('overflowHidden', false);
            }
            this.dtmData = data
        },
        openMap(index, coor) {
            EventEmit.$emit('showPopupAerialLog', {index, coor});
        }
    },
    filters: {
    },
    created() {
        // 
    },
    mounted() {
    },
}
</script>

<style lang="sass">
    .container-timeline    
        max-height: 400px
        overflow: auto
    .box-aerial-timeline
        @apply .ml-8
        border-left: 1px solid $color-main-900
        li
            @apply .flex .items-center .relative .mb-4 .pl-8
            &:before
                content: ''
                width: 10px
                height: 10px
                background-color: $color-main-100
                border-radius: 100%
                position: absolute
                left: -5px
            .location-name
                min-width: 600px
    .bubble-notif
        font-size: 14px
        font-weight: 900
        padding: 2px 8px
        margin-left: -0.5rem
</style>
