<template lang="pug">
.territory-virtual-index
    .box__body
        .card.card--table.card--no-bg
            .card__header.card--table__header
                h2.text-xl Lists of Territory Virtual
                .header-cta
                    .block
                        router-link.btn-primary.m-btn-icon(:to="{ name: 'pageTerritoryVirtualCreate', params: {} }")
                            IconSet(type="add")
                            span.m-hide.ml-3 Add Territory Virtual
            .card--table__body
                .box-filter-table.p-4.pt-0
                    .field(class="lg:mr-3 lg:mb-0 lg:w-64 mr-0 w-full mb-4")
                        input.field-style(
                            v-model="keyword"
                            type="text"
                            placeholder="Search here..."
                            @keyup="searchKeyword"
                        )
                        .field-icon
                            IconSet(
                                type="search"
                            )
                    .field(class="lg:mr-3 lg:mb-0 lg:w-32 mr-0 w-full mb-4")
                        select.field-style#filter-record-size(@change="sizeChange($event)" v-model="items_per_page")
                            option(v-for="(s,index) in size" :key="index" :value="s") {{ s }}
                        .field-icon
                            IconSet(
                                type="chevron-down"
                            )
                .box-table.box-table--target
                    table.table
                        thead.m-hide
                            tr
                                th(v-for="(th,index) in tableThead" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}
                        tbody
                            tr(v-if="status_list.status === 'loading'")
                                td.text-center(:colspan="tableThead.length")
                                    .flex.flex-wrap.items-center.justify-center
                                        Spinner(show="true" size="48")
                                    
                            template(v-else-if="list && list.data && list.data.length > 0")
                                tr(v-for="(data,index) in list.data" :key="data.id")
                                    td
                                        //- router-link.link(:to="'/territory-virtual/'+data.id+'/detail'") {{ data.name ? data.name : '-' }}
                                        router-link.link(:to="{name: 'territory-virtual-detail', params: {id:data.id, data:'external'}}") {{ data.name ? data.name : '-' }}

                                    td(v-if="data.target && data.target.length > 0")
                                        //- .flex.items-center.list-target
                                            .list-target__avatar(v-for="target in data.target.slice(0, 9)")
                                                .hidden {{ target.target_name }} : {{ target.msisdn && target.msisdn.length > 0 ? target.msisdn.join(', ') : '-' }}
                                                figure.w-12.h-12.overflow-hidden.rounded-full.bg-white.border-4(v-tooltip="target.target_name")
                                                    img.w-full.h-full.object-cover(
                                                        :src="target.photo ? target.photo : getDefaultImage"
                                                        @error="getDefaultImage"
                                                    )
                                            .list-target__more.w-12.h-12.overflow-hidden.rounded-full.bg-white.border-4.flex.items-center.justify-center.bg-white(v-if="data.target.length > 10")
                                                span.text-sm +{{ data.target.length - 10 }}

                                        .list-target
                                            .list-target__avatar(v-for="target in data.target.slice(0, 9)")
                                                .hidden {{ target.target_name }} : {{ target.msisdn && target.msisdn.length > 0 ? target.msisdn.join(', ') : '-' }}
                                                figure.w-10.h-10.rounded-full.overflow-hidden(v-tooltip="target.target_name")
                                                    img.w-full.h-full(
                                                        :src="target.photo ? target.photo : getDefaultImage"
                                                        @error="getDefaultImage"
                                                    )
                                            .list-target__more.w-12.h-12.overflow-hidden.rounded-full.bg-white.border-4.flex.items-center.justify-center.bg-white(v-if="data.target.length > 10")
                                                span.text-sm +{{ data.target.length - 10 }}
                                    td(v-else) -

                                    td
                                        p {{ data.description ? (data.description.length > 50 ? data.description.substring(0, 80)+'...' : data.description) : '-' }}

                                    td(:class="data.status.toLowerCase()==='stop' ? 'action' : 'text-center'")
                                        .flex.items-center.justify-end
                                            template(v-if="data.status.toLowerCase()==='stop'")
                                                button.btn-icon-40(
                                                    @click="monitoringAction(data.id, data.name, 1)"
                                                    v-tooltip="'Start Monitoring'"
                                                )
                                                    IconSet(type="play")
                                                router-link.btn-icon-40.m-hide(
                                                    :to="'/territory-virtual/'+data.id+'/edit'"
                                                    v-tooltip="'Edit'"
                                                )
                                                    IconSet(type="edit")
                                                button.btn-icon-40.m-hide(
                                                    @click="deleteData(data.id, data.name)"
                                                    v-tooltip="'Delete'"
                                                )
                                                    IconSet(type="delete")
                                            template(v-else)
                                                button.btn-icon-40.text-red-500(
                                                    @click="monitoringAction(data.id, data.name, 0)"
                                                    v-tooltip="'Stop Monitoring'"
                                                )
                                                    IconSet(type="stop")
                                                button.btn-icon-40.btn-disabled.m-hide
                                                    IconSet(type="edit")
                                                button.btn-icon-40.btn-disabled.m-hide
                                                    IconSet(type="delete")
                            tr(v-else)
                                td.text-center(:colspan="tableThead.length") No Data Available

                                //- tr(v-for="item in DataTerritory" :key="item.id")
                                    td
                                        router-link.link(:to='item.url') {{ item.group }}
                                    td
                                        .list-target
                                            .list-target__avatar
                                                figure.w-10.h-10.rounded-full.overflow-hidden
                                                    img.w-full.h-full(:src="avatar")
                                            .list-target__avatar
                                                figure.w-10.h-10.rounded-full.overflow-hidden
                                                    img.w-full.h-full(:src="avatar")
                                            .list-target__avatar
                                                figure.w-10.h-10.rounded-full.overflow-hidden
                                                    img.w-full.h-full(:src="avatar")
                                    td
                                        span.text-sm {{ item.desc }}
                                    td.m-action-target
                                        .flex.items-center.justify-end
                                            template(v-if="item.monitoring === true")
                                                button.btn-icon-40
                                                    IconSet(type="play")
                                                button.btn-icon-40.m-hide
                                                    IconSet(type="edit")
                                                button.btn-icon-40.m-hide
                                                    IconSet(type="delete")

                                            template(v-if="item.monitoring === false")
                                                button.btn-icon-40.text-red-500
                                                    IconSet(type="stop")
                                                button.btn-icon-40.btn-disabled.m-hide
                                                    IconSet(type="edit")
                                                button.btn-icon-40.btn-disabled.m-hide
                                                    IconSet(type="delete")

            .card__footer
                .box-table-footer
                    //- span.text-xs Showing 10 from 100
                    //- .pagination
                        ul
                            li
                                span 1
                            li
                                router-link(to="")  2
                            li
                                router-link(to="")  3

                    //- .mt-5
                    .pagination
                        vue-ads-pagination(
                            :total-items="total_data"
                            :items-per-page="items_per_page"
                            :page="page"
                            :max-visible-pages="5"
                        )
                            template(slot="buttons" slot-scope="props")
                                vue-ads-page-button(
                                    v-for="(button, key) in props.buttons"
                                    :key="key"
                                    v-bind="button"
                                    :class="{'bg-yellow-dark': button.active}"
                                    @page-change="page = button.page"
                                    @range-change="start = button.start; end = button.end"
                                )

</template>

<script>
import { mapState } from 'vuex';
import IconSet from '@/components/IconSet.vue';
import Spinner from '@/components/Spinner.vue';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
// import '../../../node_modules/vue-ads-pagination/dist/vue-ads-pagination.css';

export default {
    name: 'pageTerritoryVirtualIndex',
    components: {
        IconSet,
        Spinner,
        VueAdsPagination,
        VueAdsPageButton,
    },
    data() {
        return {
            // avatar: 'https://randomuser.me/api/portraits/men/24.jpg',
            // DataTerritory: [
            //     {
            //         url: '/territory-virtual/detail',
            //         group: 'Group A',
            //         operation: 'Case A',
            //         desc: 'Theres nothing new under the suuun',
            //         updated: '10 agustus 2020 11:00 WIB',
            //         monitoring: true,
            //     },
            //     {
            //         url: '/territory-virtual/detail',
            //         group: 'Group A',
            //         operation: 'Case A',
            //         desc: 'Theres nothing new under the suuun',
            //         updated: '10 agustus 2020 11:00 WIB',
            //         monitoring: false,
            //     },
            //     {
            //         url: '/territory-virtual/detail',
            //         group: 'Group A',
            //         operation: 'Case A',
            //         desc: 'Theres nothing new under the suuun',
            //         updated: '10 agustus 2020 11:00 WIB',
            //         monitoring: true,
            //     },
            //     {
            //         url: '/territory-virtual/detail',
            //         group: 'Group A',
            //         operation: 'Case A',
            //         desc: 'Theres nothing new under the suuun',
            //         updated: '10 agustus 2020 11:00 WIB',
            //         monitoring: true,
            //     },
            // ],

            tableThead: [
                {
                    name: 'Name',
                    // number: true,
                },
                // {
                //     name: 'Point',
                // },
                {
                    name: 'Target',
                },
                {
                    name: 'Description',
                },
                {
                    name: '',
                    action: true,
                },
            ],

            total_data: 0,
            items_per_page: 10,

            size: [10, 25, 50, 100],

            page: 0,
            start: 0,
            end: 0,

            keyword: '',
            timeout: null,

            // ImgAvatar: require('../../assets/images/default-avatar.png'),
            imgAvatar: this.$store.getters['config/getDefaultImageProfile'],
        }
    },
    computed: {
        ...mapState('territoryVirtual', [
            'status_list',
            'list',
        ]),
        route_name() {
            return this.$route.name;
        },
    },
    watch: {
        route_name(newData, oldData) {
            if (newData === 'pageTerritoryVirtualIndex') {
                if (oldData !== 'pageTerritoryVirtualIndex') {
                    this.items_per_page = 10;
                    this.page = 0;
                    this.keyword = '';
                    this.$store.commit('territoryVirtual/setList', {});
                }

                this.getData();
            }
        },
        list(newData) {
            if (Object.keys(newData).length > 0) {
                this.total_data = parseInt(newData.total_data);
                // this.page = (parseInt(newData.current_page) - 1);
            } else {
                this.total_data = 0;
            }
        },
        items_per_page() {
            this.getData();
        },
        page() {
            this.getData();
        },
    },
    methods: {
        getDefaultImage(e) {
            e.target.src = this.imgAvatar;
        },
        getData() {
            if (this.route_name === 'pageTerritoryVirtualIndex') {

                this.$store.dispatch('territoryVirtual/getList', [this.items_per_page, (this.page + 1), this.keyword]);

                // if (this.list.total_page && this.list.total_page > 1)
                    // this.pagination();
            }
        },
        monitoringAction(id, territory_name, status=1,) {
            // console.log(this.monitoringTarget.length)
            var confirm = true
            var title = 'Are you sure you want to start monitoring ?'
            if (status !== 1) {
                title = 'Are you sure you want to stop monitoring?'
            }

            if(confirm) {
                this.$swal.fire({
                    icon: 'warning',
                    title: title,
                    text: 'Territory Name : ' + territory_name,
                    confirmButtonText: 'Yes!',
                    confirmButtonColor: '#DD6B55',
                    showCancelButton: true,
                    allowOutsideClick: false,
                })
                .then((result) => {
                    // console.log('on result');
                    if (result.isConfirmed) {

                        EventEmit.$emit('showLoading', true);

                        this.$store.dispatch('territoryVirtual/updateStatus', [id, status])
                        .then((resp) => {
                            // console.log(resp);
                            if (resp.result === 'success') {
                                this.page = 0;

                                this.getData();

                                this.$swal.fire({
                                    icon: 'success',
                                    title: resp.data.message,
                                    timer: 3000,
                                });
                            } else {
                                if (resp.data.message) {
                                    EventEmit.$emit('error', resp.data.message);
                                } else {
                                    EventEmit.$emit('error');
                                }
                            }
                        });
                    }
                });
            }
        },
        deleteData(id, name) {
            this.$swal.fire({
                icon: 'warning',
                title: 'Are you sure you want to delete?',
                text: 'Territory Virtual : ' + name,
                confirmButtonText: 'Yes!',
                confirmButtonColor: '#DD6B55',
                showCancelButton: true,
                allowOutsideClick: false,
            })
            .then((result) => {

                if (result.isConfirmed) {
                    EventEmit.$emit('showLoading', true);

                    this.$store.dispatch('territoryVirtual/delete', [id])
                    .then((resp) => {
                        if (resp === 'success') {
                            this.page = 0;

                            this.getData();

                            this.$swal.fire({
                                icon: 'success',
                                title: 'Data successfully deleted!',
                                timer: 3000,
                            });
                        } else {
                            if (resp.data && resp.data.message) {
                                EventEmit.$emit('error', resp.data.message);
                            } else {
                                EventEmit.$emit('error');
                            }
                        }
                    });
                }
            });
        },
        pageChange(page) {
            this.page = page;
        },
        rangeChange(start, end) {
            this.start = start;
            this.end = end;
        },
        sizeChange() {
            this.page = 0;
        },
        searchKeyword() {
            clearTimeout(this.timeout);            
            var self = this;
            this.timeout = setTimeout(function () {
                self.page = 0;
                self.$store.commit('territoryVirtual/setList', {});
                self.getData();
            }, 650);
        },
    },
    created() {
        this.getData();
    },
    mounted() {
        // 
    },
}
</script>

<style lang="sass">
    .list-target
        @apply .flex .items-center
        &__avatar
            margin-left: -8px
            figure
                border: 4px solid $color-secondary-500
            &::first-child
                margin-left: 0px
        &__more
            margin-left: -12px
            z-index: 9
            background-color: #10191e !important
            border-color: #22333e !important
</style>