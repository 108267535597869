const env_window = window 
const env_process = process.env

const env_prefix = "VUE_APP"

let env = env_window

const from_env = process.env.VUE_APP_FROM_ENV || false
if ([true,'true'].includes(from_env)){
	env = env_process
}
console.log('=====from_env', from_env)

function isNumeric(str) {
	if (typeof str != "string") return false
	return !isNaN(str) && !isNaN(parseFloat(str))
}

function checkAndGetValue(value, type_of){
	if (type_of === Boolean)
		return String(value).toLowerCase() == 'false' ? false : Boolean(value)
	if (type_of === Array){
		const separator = value.includes(",") ? "," : "|"

		let text = []
		for (let v of value.split(separator)){
			v = v.trim()
			if (!v) continue
			if (isNumeric(v))
				v = Number(v)
			text.push(v)
		}
		return text
	}
	
	let default_data = null
	if (type_of == Number)
		default_data = 0
	if (type_of == String)
		default_data = ""
		
	return value ? type_of(value) : 0
}

const defaultValue = (env_obj) => {
	for (let [env_label, value] of Object.entries(env_obj)) {
		let type_of = value.length ? value[0] : String 
		let default_data = value.length>1 ? value[1] : null 

		const data = env[env_label] || env[`${env_prefix}_${env_label}`] || default_data

		env[env_label] = checkAndGetValue(data, type_of)
	}
	return env
}

export default {
	defaultValue
}