<template lang="pug">
.case-management-index
    .box__body
        .card.card--table.card--no-bg
            .card__header.card--table__header
                h2.text-xl Lists of Case Management
                .header-cta.flex.items-center.mb-2.justify-between.gap-4
                    .block(v-if="checkFeatureByAcl('case_management', 'export')")
                        .relative.box-more
                            button.btn-primary.m-btn-icon
                                template(v-if="is_exporting")
                                    Spinner(show="true" size="24")
                                    span.m-hide.ml-3 Exporting ...
                                template(v-else)
                                    IconSet(type="download")
                                    span.m-hide.ml-3 Export
                            .box-popup-more
                                ul
                                    li
                                        .btn-primary-o.btn-small(
                                            :disabled="{'disabled':is_exporting}"
                                            :class="{'btn-disabled':is_exporting}"
                                            @click="!is_exporting?getDataExport('dlCsv'):null"
                                        )
                                            | Download CSV
                                        JsonCSV.btn-primary-o.btn-small(
                                            :data="export_data"
                                            :name="csv_name"
                                            ref="dlCsv"
                                            style="display:none"
                                        )
                                            | Download CSV
                                    li
                                        .btn-primary-o.btn-small(
                                            :disabled="{'disabled':is_exporting}"
                                            :class="{'btn-disabled':is_exporting}"
                                            @click="!is_exporting?getDataExport('dlXls'):null"
                                        )
                                            | Download XLS
                                        JsonCSV.btn-primary-o.btn-small(
                                            :data="export_data"
                                            :name="xls_name"
                                            delimiter=";"
                                            ref="dlXls"
                                            style="display:none"
                                        )
                                            | Download XLS
                    .block(v-if="checkFeatureByAcl('case_management', 'create')")
                        router-link.btn-primary.m-btn-icon(
                            :to="{ name: 'pageCaseManagementCreate', params: {} }"
                        )
                            IconSet(type="add")
                            span.m-hide.ml-3 Add Case
            .card--table__body
                .box-filter-table.p-4.pt-0
                    .field(class="lg:mr-3 lg:mb-0 lg:w-64 mr-0 w-full mb-4")
                        input.field-style(
                            v-model="keyword"
                            type="text"
                            placeholder="Search here..."
                            @keyup="searchKeyword"
                        )
                        .field-icon
                            IconSet(
                                type="search"
                            )
                    .field(class="lg:mr-3 lg:mb-0 lg:w-32 mr-0 w-full mb-4")
                        select.field-style(@change="sizeChange($event)" v-model="items_per_page")
                            option(v-for="(s,index) in size" :key="index" :value="s") {{ s }}
                        .field-icon
                            IconSet(
                                type="chevron-down"
                            )
                .box-table.box-table--target
                    table.table
                        thead.m-hide
                            tr
                                th(v-for="(th,index) in tableThead" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}
                        tbody
                            tr(v-if="status_list.status === 'loading'")
                                td.text-center(:colspan="tableThead.length")
                                    .flex.flex-wrap.items-center.justify-center
                                        Spinner(show="true" size="48")
                            template(v-else-if="list && list.data && list.data.length > 0")
                                tr(v-for="(data,index) in list.data" :key="data.id")
                                    td 
                                        .text-sm {{ data.operation_name?data.operation_name:'-' }}
                                    td 
                                        .text-sm {{ data.location?data.location:'-' }}
                                    td 
                                        .w-64
                                            .text-sm {{ data.description ? (data.description.length > 16 ? data.description.substring(0, 16)+'...' : data.description) : '-' }}
                                    td
                                        .w-64
                                            template(v-if="canTakeNotes")
                                                button.link.text-sm(@click="getNotes(true, data.id, data.operation_name)") {{data.note?data.note.content:'Add a note'}}
                                            template(v-else)
                                                .text-sm.note {{data.note?data.note.content:'No notes'}}


                                    td
                                        template(v-if="data.targets && data.targets.length>0")
                                            .flex.pb-2(v-for="target in data.targets.slice(0,4)")
                                                .label-dark(:class="data.targets.length > 1 ? 'mr-2' : ''") {{ target.name }}
                                            template(v-if="data.targets.length > 4")
                                                button.link(@click="getTarget(true, data.targets, data.operation_name)") More
                                        span(v-else) -
                                    td
                                        .flex.pb-2(v-for="assign in data.assigns")
                                            .label-dark(:class="data.assigns.length > 1 ? 'mr-2' : ''") {{ assign.name }} 

                                    td(:class="data.status.toLowerCase()==='active' ? 'status-active' : 'status-inactive'") 
                                        span {{ data.status }}
                                    td.m-hide
                                        span.text-xs {{ data.created_by_obj && data.created_by_obj.name ? data.created_by_obj.name : '-' }}
                                    td.m-hide
                                        span.text-xs {{ data.updated_at_view ? data.updated_at_view : '-' }}
                                    td.action.action--three
                                        .flex.items-center.justify-end
                                            button.note-case.btn-icon-40(
                                                v-if="checkFeatureByAcl('case_management', 'note-case-create')"
                                                @click="getNotes(true, data.id, data.operation_name)"
                                            )
                                                IconSet(type="add note")
                                            router-link.edit-case.btn-icon.btn-icon-40(
                                                v-if="checkFeatureByAcl('case_management', 'edit')"
                                                :to="'/case-management/'+data.id+'/edit'" v-tooltip="'Edit'"
                                            )
                                                IconSet(type="edit")
                                            button.delete-case.btn-icon.btn-icon-40(
                                                v-if="checkFeatureByAcl('case_management', 'delete')"
                                                @click="deleteData(data.id, data.operation_name)"
                                                v-tooltip="'Delete'"
                                            )
                                                IconSet(type="delete")
                            tr(v-else)
                                td.text-center(:colspan="tableThead.length") No Data Available

                .box-table-footer(v-if="status_list.status !== 'loading'")
                    .pagination
                        vue-ads-pagination(
                            :total-items="total_data"
                            :items-per-page="items_per_page"
                            :page="page"
                            :max-visible-pages="5"
                        )
                            template(slot="buttons" slot-scope="props")
                                vue-ads-page-button(
                                    v-for="(button, key) in props.buttons"
                                    :key="key"
                                    v-bind="button"
                                    :class="{'bg-yellow-dark': button.active}"
                                    @page-change="page = button.page"
                                    @range-change="start = button.start; end = button.end"
                                )
    .popup-layer(
        v-bind:class="{'is-show' : openNote}"
    )
        .popup-container
            .popup-container__header
                h2.text-xl {{tmpCaseName}}'s Notes
                button.btn-icon-40(
                    @click="getNotes(false)"
                )
                    IconSet( type="close")

            .popup-container__body
                .mb-4
                    .field.w-full.mb-2
                        textarea.field-style(
                            v-model="tmpNote"
                            placeholder="Write new note here..."
                            style="min-height:60px"
                        )
                    .flex.justify-end
                        template(v-if="tmpNote && tmpNote.length>0")
                            button.btn-primary.btn.small(@click="addNote") Add note
                        template(v-else)
                            button.btn-primary.btn.small.btn-disabled(disabled) Add note
                .mb-4
                    .small-heading History Notes
                    template(v-if="status_notes_list.status == 'loading'")
                        .flex.flex-wrap.items-center.justify-center
                            Spinner(show="true" size="48")

                    .mb-4.pb-4.border-b(v-else-if="dataNotes && dataNotes.length > 0" v-for="note in dataNotes")
                        p.mb-1 {{note.content}}
                        small.text-xs.opacity-75 {{note.created_at?note.created_at:'-'}} • added by {{note.created_by?note.created_by.name:'-'}}

                    .flex.flex-wrap.items-center.justify-center(v-else)
                        strong.block.text-xs.uppercase.text-light.mb-4 No History Available
                    .block                            
                        template(v-if="status_notes_list.status != 'loading' && (dataNotes && dataNotes.length > 0)")
                            small.text-xs.opacity-75(v-if="!isLoadingNext") {{dataNotes.length}} of {{totalNotes}} items
                            .flex.flex-wrap.items-center.justify-center
                                template(v-if="isLoadingNext")
                                    Spinner(show="true" size="48")
                                template(v-else)
                                    template(v-if="pageNotes<totalPageNotes")
                                        button.btn-small.btn-primary(@click="handleNextNotes") Load More

    .popup-layer(
        v-bind:class="{'is-show' : openTarget}"
    )
        .popup-container
            .popup-container__header
                h2.text-xl {{tmpCaseName}}'s Targets
                button.btn-icon-40(
                    @click="getTarget(false)"
                )
                    IconSet( type="close")

            .popup-container__body
                .mb-4
                    .small-heading Target Lists
                    .mb-4
                        .flex.pb-2(v-for="target in tmpTargets")
                            .label-dark(:class="tmpTargets.length > 1 ? 'mr-2' : ''") {{ target.name }}
</template>

<script>
import { mapState } from 'vuex';
import IconSet from '@/components/IconSet.vue';
import Spinner from '@/components/Spinner.vue';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
import moment from 'moment'
import JsonCSV from 'vue-json-csv'

export default {
    name: 'pageCaseManagementIndex',
    components: {
        IconSet,
        Spinner,
        VueAdsPagination,
        VueAdsPageButton,
        JsonCSV,
    },
    data() {
        return {
            // tableThead: [
                // {
                //     name: 'Case Name',
                //     // number: true,
                // },{
                //     name: 'Location',
                // },{
                //     name: 'Description',
                // },{
                //     name: 'Latest Note',
                // },{
                //     name: 'Target',
                // },{
                //     name: 'Assign To',
                // },{
                //     name: 'Status',
                // },{
                //     name: 'Created By',
                // },{
                //     name: 'Last Updated',
                // },{
                //     name: '',
                //     action: true,
                // },
            // ],
            total_data: 0,
            items_per_page: 10,

            size: [10, 25, 50, 100, 'All'],

            page: 0,
            start: 0,
            end: 0,

            keyword: '',
            timeout: null,

            openNote: false,
            openTarget: false,

            tmpCaseId: null,
            tmpCaseName: null,
            tmpNote: null,
            tmpTargets: [],

            dataNotes: [],
            pageNotes: 0,
            totalPageNotes: 1,
            limitNotes: 10,
            totalNotes: 0,
            
            isLoadingNext: false,

            xls_name: 'case_management_'+moment().format('YYYY-MM-DD_HH:mm:ss')+'.xls',
            csv_name: 'case_management_'+moment().format('YYYY-MM-DD_HH:mm:ss')+'.csv',
            export_data: [],
            export_data_raw: [],
            is_exporting: false,
            limit_export: 1000000,
            btn_ref: null,
        }
    },
    computed: {    
        ...mapState('caseManagement', [
            'status_list',
            'list',
            'status_notes_list',
            'notes_list',
        ]),
        ...mapState('auth', [
            'user',
        ]),
        route_name() {
            return this.$route.name;
        },
        tableThead() {
            let tableThead = [
                {
                    name: 'Case Name',
                },{
                    name: 'Location',
                },{
                    name: 'Description',
                },{
                    name: 'Latest Note',
                },{
                    name: 'Target',
                },{
                    name: 'Assign To',
                },{
                    name: 'Status',
                },{
                    name: 'Created By',
                },{
                    name: 'Last Updated',
                },
            ];
            if (this.checkFeatureByAcl('case_management', 'note-case-create')||this.checkFeatureByAcl('case_management', 'edit')||this.checkFeatureByAcl('case_management', 'delete')) {
                tableThead.push({
                    name: 'Action',
                    action: true,
                });
            }

            return tableThead;
        },
        canTakeNotes(){
            return this.checkFeatureByAcl('case_management', 'note-case-create')
        }
    },
    watch: {
        route_name(newData, oldData) {
            if (newData === 'pageCaseManagementIndex') {
                if (oldData !== 'pageCaseManagementIndex') {
                    this.items_per_page = 10;
                    this.page = 0;
                    this.keyword = '';
                    this.$store.commit('caseManagement/setList', {});
                }

                this.getData();
            }
        },
        tableThead() {},
        list(newData) {
            if (Object.keys(newData).length > 0) {
                const tmp_total_data = parseInt(newData.total_data);
                this.total_data = tmp_total_data;
                if (this.keyword == '') {
                    this.limit_export = tmp_total_data;
                    if (this.btn_ref != null) {
                        this.getDataExport(this.btn_ref)
                    } 
                }
                // this.page = (parseInt(newData.current_page) - 1);
            } else {
                this.total_data = 0;
            }
        },
        items_per_page() {
            this.getData();
        },
        page() {
            this.getData();
        },
        notes_list(newData){
            // console.log('newData')
            // console.log(newData)
            if (Object.keys(newData).length > 0) {
                // console.log('masuk')
                this.dataNotes = this.dataNotes.concat(newData.data);
                this.totalNotes = parseInt(newData.total_data);
                this.limitNotes = parseInt(newData.per_page);
                this.pageNotes = parseInt(newData.current_page);
                this.totalPageNotes = parseInt(newData.total_page);
            } else {
                this.setDefaulDataNotes()
                // console.log('else')
                // this.dataNotes = [];
                // this.totalNotes = 0;
                // this.limitNotes = 10;
                // this.pageNotes=0;
                // this.totalPageNotes=1;
            }
            this.isLoadingNext=false
        },
    },
    methods: {
        checkActionButton() {
            return (Object.keys(this.user).length > 0 && this.user.role && this.user.role.label != 'analyst')
        },
        getData() {
            // for dispatch get data
            if (this.route_name === 'pageCaseManagementIndex') {
                let limit = this.items_per_page
                if (this.items_per_page == 'All') {
                    limit = this.list.total_data
                }
                this.$store.dispatch('caseManagement/getList', [limit, (this.page + 1), this.keyword]);

                // if (this.list.total_page && this.list.total_page > 1)
                    // this.pagination();
            }
        },
        deleteData(id, name) {
            this.$swal.fire({
                icon: 'warning',
                title: 'Are you sure you want to delete?',
                text: 'Case Management : ' + name,
                confirmButtonText: 'Yes!',
                confirmButtonColor: '#DD6B55',
                showCancelButton: true,
                allowOutsideClick: false,
            })
            .then((result) => {

                if (result.isConfirmed) {
                    EventEmit.$emit('showLoading', true);

                    this.$store.dispatch('caseManagement/delete', [id])
                    .then((resp) => {
                        if (resp === 'success') {
                            this.page = 0;

                            this.getData();

                            this.$swal.fire({
                                icon: 'success',
                                title: 'Data successfully deleted!',
                                timer: 3000,
                            });
                        } else {
                            if (resp.data && resp.data.message) {
                                EventEmit.$emit('error', resp.data.message);
                            } else {
                                EventEmit.$emit('error');
                            }
                        }
                    });
                }
            });
        },
        pageChange(page) {
            this.page = page;
        },
        rangeChange(start, end) {
            this.start = start;
            this.end = end;
        },
        sizeChange() {
            this.page = 0;
        },
        searchKeyword() {
            clearTimeout(this.timeout);            
            var self = this;
            this.timeout = setTimeout(function () {
                self.page = 0;
                self.$store.commit('caseManagement/setList', {});
                self.getData();
            }, 650);
        },
        getNotes(open=true, case_id=null,case_name=null) {
            this.$store.commit('caseManagement/setNotesList', {});
            if(open){
                this.tmpCaseId = case_id
                this.tmpCaseName = case_name
                this.$store.dispatch('caseManagement/getNotesList', [this.tmpCaseId, this.limitNotes, this.pageNotes+1]);
                this.openNote = true
                EventEmit.$emit('overflowHidden', true);
            } else {
                this.setEmptyNotes()
                EventEmit.$emit('overflowHidden', false);
            }
        },
        handleNextNotes() {
            // console.log(case_id)
            this.isLoadingNext=true
            this.$store.dispatch('caseManagement/getNotesList', [this.tmpCaseId, this.limitNotes, this.pageNotes+1]);
        },
        addNote(){
            EventEmit.$emit('showLoading', true);
            this.$store.dispatch('caseManagement/createNotes', [this.tmpCaseId, this.tmpNote])
                .then((resp) => {
                    if (resp.result === 'success') {
                        // this.getNotes(false)
                        this.tmpNote = null
                        this.setDefaulDataNotes()

                        this.getNotes(true, this.tmpCaseId, this.tmpCaseName);
                        this.getData();

                        this.$swal.fire({
                            icon: 'success',
                            title: 'Note successfully added!',
                            timer: 3000,
                        });
                    } else {
                        // console.log('error')
                        if (resp.data.message) {
                            EventEmit.$emit('error', resp.data.message);
                        } else {
                            EventEmit.$emit('error');
                        }
                    }
                });
        },
        setEmptyNotes(){
            this.tmpCaseId = null
            this.tmpCaseName = null
            this.tmpNote = null
            this.openNote = false
        },
        setDefaulDataNotes(){
            // console.log('else')
            this.dataNotes = [];
            this.totalNotes = 0;
            this.limitNotes = 10;
            this.pageNotes=0;
            this.totalPageNotes=1;
        },
        getTarget(open=true, targets=[],case_name=null) {
            if(open){
                this.tmpTargets = targets
                this.tmpCaseName = case_name
                this.openTarget = true
                EventEmit.$emit('overflowHidden', true);
            } else {
                this.setEmptyTarget()
                EventEmit.$emit('overflowHidden', false);
            }
        },
        setEmptyTarget(){
            this.tmpTargets = []
            this.tmpCaseName = null
            this.openTarget = false
        },
        async getDataExport(btn_ref=null) {
            this.btn_ref = btn_ref
            this.is_exporting = true
            if (![0,1000000].includes(this.limit_export)) {
                let data_export = this.export_data_raw
                if (data_export.length == 0) {
                    data_export = await this.$store.dispatch('caseManagement/getExport', [this.limit_export]);
                    this.export_data_raw = data_export 
                }
                this.export_data = await data_export.map((item) => {
                    let dataNotes = (this.canTakeNotes? (item.note?item.note.content:'-'): '-')
                    
                    let tmpTargets = item.targets && item.targets.length>0 ? item.targets : []
                    let dataTargets = '-'
                    if (tmpTargets.length > 0) {
                        dataTargets = tmpTargets.map(function(item) {
                            return item['name'];
                        }).join(', ');
                    }
                    
                    let dataAssigns = '-'
                    if (item.assigns && item.assigns.length > 0) {
                        dataAssigns = item.assigns.map(function(item) {
                            return item['name'];
                        }).join(', ');
                    }
                    
                    return {
                        case_name: (item?.operation_name || '-'),
                        location: (item?.location || '-'),
                        description: (item?.description || '-'),
                        latest_note: dataNotes,
                        target: dataTargets,
                        assign_to: dataAssigns,
                        status: (item?.status || '-'),
                        created_by: (item?.created_by_obj?.name || '-'),
                        last_updated: (item?.updated_at_view || '-'),
                    };
                })
                this.is_exporting = false
                this.btn_ref = null
                setTimeout(() => this.$refs[btn_ref].$el.click(), 1000);
            }  
        },
    },
    created() {
        this.getData();
    },
    mounted() {
        // 
    },
}
</script>

<style lang="sass" scoped>
    .note
        &:hover
            text-decoration: underline
            cursor: pointer

    .box-more
        &:hover
            .box-popup-more
                display: block


    .box-popup-more
        @apply .absolute
        top: 100%
        right: 0px
        z-index: 999
        font-size: 14px
        min-width: 168px
        display: none
        // transform: translateY(16px)
        padding-top: 16px
        ul
            background-color: $color-secondary-700
            @apply .grid .p-6 .gap-5
            grid-template-rows: repeat(2, minmax(0, 1fr))
            grid-auto-flow: column
            li
                cursor: pointer
                a
                    &:hover
                        color: $color-main-500
</style>