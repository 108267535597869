import axios from "@/axios-main";
import axiosOrigin from "axios";
import { env } from '@/config/env';

let cancelList;
var cancelToken = axiosOrigin.CancelToken;

const trackingHistory = {
  namespaced: true,
  state: {
    // module
    status: false,

    // tracking
    status_list: {
      status: false,
      cancel: null,
      code: null,
    },
    list: {},
  },
  mutations: {
    // module
    setStatus: (state, status) => {
      state.status = status;
    },

    // tracking
    setStatusList: (state, status) => {
      state.status_list = status;
    },
    setList: (state, data) => {
      state.list = data;
    },
  },
  getters: {},
  actions: {
    async getList({ commit, dispatch, state }, [limit='', page='', keyword='', from_date='', to_date='']) {

      if(state.status_list.cancel != null && state.status_list.status == 'loading')
        await state.status_list.cancel('Operation canceled by the user.');
      
      let params = new FormData();
      
      params.append('limit', limit);
      params.append('page', page);
      params.append('keyword', keyword);
      params.append('from_date', from_date);
      params.append('to_date', to_date);

      const urlPath = env.VUE_APP_API_VERSION_LOG_TRACKING_TARGET == 'v2' ? 'v2/data-log/tracking-target/list' : 'api/v1/tracking-target/list';
      axios.post(urlPath, params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelList = c;
          commit('setStatusList', {
            status: 'loading',
            cancel: c,
            code: null
          });
        }),
      })
      .then((resp) => {
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setList', data);
  
        if (Object.keys(data).length===0)
          result.status = 'empty';

        commit('setStatusList', result);
      })
      .catch((resp) => {
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelList;
        }

        commit('setStatusList', result);
        if (!axiosOrigin.isCancel(resp))
        dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
      });
    },
  }
};

export default trackingHistory;
