<template lang="pug">
.roles-shell
    .box
        .box__header
            TitlePage(
                title="Notifications"
                :breadcrumb="breadcrumb"
                )
        .box__body
            .card.card--table.card--no-bg
                .card__header.p-4
                    h2.text-xl Your Notification
                    .header-cta
                        button.btn-default(type='button' @click="updateReadNotification(null, true)") Mark all as read
                .card__body
                    .notification-type.flex.items-center.p-4(v-if="is_use_notification.filter_type === true")
                        .fied.mr-10
                            .flex.items-center
                                .ui-radio.mr-3
                                    input(type="radio" name="type" value="all" v-model="notification_type")
                                    .style-radio
                                        .radio
                                        span All
                                .rounded.bg-red-600.text-white.px-1.text-xs(v-if="notification_count_all > 0")  {{ notification_count_all }}

                        .fied.mr-10
                            .flex.items-center
                                .ui-radio.mr-3
                                    input(type="radio" name="type" value="territory_virtual" v-model="notification_type")
                                    .style-radio
                                        .radio
                                        span Territory Virtual
                                .rounded.bg-red-600.text-white.px-1.text-xs(v-if="notification_count_territory_virtual > 0") {{ notification_count_territory_virtual }}

                        .fied.mr-10
                            .flex.items-center
                                .ui-radio.mr-3
                                    input(type="radio" name="type" value="meeting_point" v-model="notification_type")
                                    .style-radio
                                        .radio
                                        span Meeting Point
                                .rounded.bg-red-600.text-white.px-1.text-xs(v-if="notification_count_meeting_point > 0") {{ notification_count_meeting_point }}

                        .fied.mr-10
                            .flex.items-center
                                .ui-radio.mr-3
                                    input(type="radio" name="type" value="target_active_again" v-model="notification_type")
                                    .style-radio
                                        .radio
                                        span Target Active Again
                                .rounded.bg-red-600.text-white.px-1.text-xs(v-if="notification_count_target_active_again > 0") {{ notification_count_target_active_again }}

                        .fied.mr-10
                            .flex.items-center
                                .ui-radio.mr-3
                                    input(type="radio" name="type" value="multitracking_active_again" v-model="notification_type")
                                    .style-radio
                                        .radio
                                        span Multitracking Active Again
                                .rounded.bg-red-600.text-white.px-1.text-xs(v-if="notification_count_multitracking_active_again > 0") {{ notification_count_multitracking_active_again }}

                    .box-notification
                        //- .list-notification.unread
                        .block(v-if="status_list.status === 'loading'")
                            .flex.flex-wrap.items-center.justify-center
                                Spinner(show="true" size="48")

                        template(v-else-if="list.data && list.data.length > 0")
                            //- router-link.list-notification(v-for="(data,index) in list.data" :key="data.id" :to="data.type === 'territory_virtual' ? '/territory-virtual/'+ data.data.territory_virtual.id +'/detail/notification/'+ data.data.target.id +'/'+ encrypt(data.data.target.msisdn) : '/multi-tracking/'+data.data.multi_tracking.id+'/meeting-point/'+ data.data.meeting_point_id" @click.native="data.status.toLowerCase() === 'unread' ? updateReadNotification(data.id) : ''" :class="data.status.toLowerCase() === 'unread' ? '' : 'unread'")
                                div
                                    p
                                        | Notification {{ data.type === 'territory_virtual' ? 'Territory Virtual: There is a target named '+ data.data.target.name +' into the territory '+ data.data.territory_virtual.name : 'Meeting Point: There was a meeting of '+ data.data.multi_tracking.group_name }}.
                                    span.text-xs.opacity-50 {{ data.type === 'territory_virtual' ? 'Territory Virtual' : 'Meeting Point' }} • {{ data.created_at.substring(0, 24) }}
                                .bullet(v-if="data.status.toLowerCase() === 'unread'")
                                    .w-3.h-3.rounded-full.bg-red-600

                            router-link.list-notification(v-for="(data,index) in list.data" :key="data.id" :to="getRoute(data)" @click.native="data.status.toLowerCase() === 'unread' ? updateReadNotification(data.id) : ''" :class="data.status.toLowerCase() === 'unread' ? '' : 'unread'")
                                div(v-if="data.type === 'territory_virtual'")
                                    p Notification Territory Virtual: There is a target named {{ data.data.target.name +' into the territory '+ data.data.territory_virtual.name }}.
                                    span.text-xs.opacity-50 Territory Virtual • {{ data.created_at.substring(0, 24) }}

                                div(v-else-if="data.type === 'meeting_point'")
                                    p Notification Meeting Point: There was a meeting of {{ data.data.multi_tracking.group_name }}.
                                    span.text-xs.opacity-50 Meeting Point • {{ data.created_at.substring(0, 24) }}

                                div(v-else-if="data.type === 'target_active_again'")
                                    p Notification Target Active Again: There was a target {{ data.data.target[0].name +' ('+ data.data.target[0].msisdn +')'}} active again.
                                    span.text-xs.opacity-50 Target Active Again • {{ data.created_at.substring(0, 24) }}

                                div(v-else-if="data.type === 'multitracking_active_again'")
                                    p Notification Target on Multitracking Active Again: There was a target {{ data.data.target[0].name +' ('+ data.data.target[0].msisdn +')'}} listed on multitracking {{ data.data.multi_tracking.group_name }} active again.
                                    span.text-xs.opacity-50 Multitracking Active Again • {{ data.created_at.substring(0, 24) }}

                                .bullet(v-if="data.status.toLowerCase() === 'unread'")
                                    .w-3.h-3.rounded-full.bg-red-600

                        .block.text-center(v-else) No Data Available

                    .box-table-footer(v-if="status_list.status !== 'loading'")
                        .pagination
                            vue-ads-pagination(
                                :total-items="total_data"
                                :items-per-page="items_per_page"
                                :page="page"
                                :max-visible-pages="5"
                            )
                                template(slot="buttons" slot-scope="props")
                                    vue-ads-page-button(
                                        v-for="(button, key) in props.buttons"
                                        :key="key"
                                        v-bind="button"
                                        :class="{'bg-yellow-dark': button.active}"
                                        @page-change="page = button.page"
                                        @range-change="start = button.start; end = button.end" 
                                    )

                        //- .list-notification.unread
                            div
                                p Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                span.text-xs.opacity-50 Territory Virtual • 13 Oct 2020 21:55:51 WIB
                            .bullet
                                .w-3.h-3.rounded-full.bg-red-600
                        //- .list-notification.unread
                            div
                                p Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                span.text-xs.opacity-50 Territory Virtual • 13 Oct 2020 21:55:51 WIB
                            .bullet
                                .w-3.h-3.rounded-full.bg-red-600
                        //- .list-notification
                            div
                                p Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                span.text-xs.opacity-50 Territory Virtual • 13 Oct 2020 21:55:51 WIB
                            .bullet
                                .w-3.h-3.rounded-full.bg-red-600
                        //- .list-notification
                            div
                                p Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                span.text-xs.opacity-50 Territory Virtual • 13 Oct 2020 21:55:51 WIB
                            .bullet
                                .w-3.h-3.rounded-full.bg-red-600



</template>

<script>
import { mapState } from 'vuex';
import TitlePage from '@/components/shell/TitlePage.vue';
import Spinner from '@/components/Spinner.vue';
import { encrypt } from '@/util/crypto';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
// import '../../../node_modules/vue-ads-pagination/dist/vue-ads-pagination.css';

export default {
    name: 'PageNotifications',
    components: {
        TitlePage,
        Spinner,
        VueAdsPagination,
        VueAdsPageButton,
    },
    data() {
        return {
            breadcrumb: [
                {
                    name: 'Notifications',
                    url: '/notification',
                },
            ],

            is_use_notification: this.$store.getters['config/getIsUseNotification'],
            notification_type: 'all',
            notification_count_all: 0,
            notification_count_territory_virtual: 0,
            notification_count_meeting_point: 0,
            notification_count_target_active_again: 0,
            notification_count_multitracking_active_again: 0,

            // pagination
            total_data: 0,
            items_per_page: 10,
            // size: [10, 25, 50, 100],

            page: 0,
            start: 0,
            end: 0,
        };
    },
    computed: {
        ...mapState('notification', [
            'status_list',
            'list',
            'count',
        ]),
        route_name() {
            return this.$route.name;
        },
    },
    watch: {
        route_name(newData, oldData) {
            if (newData === 'pageNotifications') {
                this.getData();
            }

            if (newData === 'pageNotifications') {
                if (oldData !== 'pageNotifications') {
                    this.items_per_page = 10;
                    this.page = 0;
                    // this.keyword = '';
                    this.$store.commit('notification/setList', {});
                }

                this.getData();
            }
        },
        list(newData) {
            if (Object.keys(newData).length > 0) {
                this.total_data = parseInt(newData.total_data);
                // this.page = (parseInt(newData.current_page) - 1);
            } else {
                this.total_data = 0;
            }
        },
        count(newData) {
            this.setNotificationCount(newData);
        },
        total_data() {
            // this.pagination();
        },
        items_per_page() {
            this.getData();
            // this.pagination();
        },
        page() {
            this.getData();
        },
        notification_type() {
            this.items_per_page = 10;
            this.page = 0;
            this.$store.commit('notification/setList', {});

            this.getData();
        },
        notification_count_all() {},
        notification_count_territory_virtual() {},
        notification_count_meeting_point() {},
    },
    methods: {
        // encrypt(string) {
        //     return encrypt(string);
        // },
        getData(get_count=true) {
            if (this.route_name === 'pageNotifications') {

                if (get_count)
                    this.$store.dispatch('notification/getCount');
                this.$store.dispatch('notification/getList', [this.items_per_page, (this.page + 1), (this.notification_type === 'all' ? null : this.notification_type)]);

                // if (this.list.total_page && this.list.total_page > 1) {
                    // console.log(this.list.total_page)
                    // this.pagination();
                // }
            }
        },
        setNotificationCount(data=[]) {
            if (data.length === 0)
                data = this.notification_count;

            if (data.length > 0) {
                let find_territory_virtual = false;
                let find_meeting_point = false;
                let find_target_active_again = false;
                let find_multitracking_active_again = false;

                for (var i = 0; i < data.length; i++) {
                    this['notification_count_'+data[i].type] = data[i].count;

                    if (data[i].type === 'territory_virtual') 
                        find_territory_virtual = true;

                    if (data[i].type === 'meeting_point') 
                        find_meeting_point = true;

                    if (data[i].type === 'target_active_again') 
                        find_target_active_again = true;

                    if (data[i].type === 'multitracking_active_again') 
                        find_multitracking_active_again = true;
                }

                if (find_territory_virtual === false)
                    this.notification_count_territory_virtual = 0;

                if (find_meeting_point === false)
                    this.notification_count_meeting_point = 0;

                if (find_target_active_again === false)
                    this.notification_count_target_active_again = 0;

                if (find_multitracking_active_again === false)
                    this.notification_count_multitracking_active_again = 0;

            } else {
                this.notification_count_all = 0;
                this.notification_count_territory_virtual = 0;
                this.notification_count_meeting_point = 0;
                this.notification_count_target_active_again = 0;
                this.notification_count_multitracking_active_again = 0;
            }
        },
        async updateReadNotification(id=null, unread_all=false) {
            // console.log('update notif')

            if (id !== null) {
                await this.$store.dispatch('notification/update', id)
                .then(() => {
                    this.$store.dispatch('notification/getCount');
                    // this.getData();
                });
                await this.getData();
            }

            if (unread_all === true) {
                await this.$swal.fire({
                    icon: 'warning',
                    title: 'Are you sure want to mark '+ this.notification_type.replace('_', ' ') +' as read?',
                    // text: 'Role : ' + name,
                    confirmButtonText: 'Yes!',
                    confirmButtonColor: '#DD6B55',
                    showCancelButton: true,
                    allowOutsideClick: false,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        EventEmit.$emit('showLoading', true);
                        this.$store.dispatch('notification/updateAll', (this.notification_type === 'all' ? null : this.notification_type))
                        .then(() => {
                            // this.$store.dispatch('notification/getCount');
                            EventEmit.$emit('showLoading', false);
                            this.getData();
                        });
                    }
                });
                // await this.getData();
            }
            // await this.$store.dispatch('notification/getCount');
        },
        getRoute(data) {
            let url = '/';

            switch(data.type) {
                case 'territory_virtual':
                    url = '/territory-virtual/'+ data.data.territory_virtual.id +'/detail/notification/'+ data.data.target.id +'/'+ encrypt(data.data.target.msisdn);
                    break;

                case 'meeting_point':
                    url = '/multi-tracking/'+data.data.multi_tracking.id+'/meeting-point/'+ data.data.meeting_point_id;
                    break;

                case 'target_active_again':
                    url = '/target/'+data.data.target[0].id+'/detail/'+ encrypt(data.data.target[0].msisdn + '&&msisdn') + '/home';
                    break;

                case 'multitracking_active_again':
                    url = '/target/'+data.data.target[0].id+'/detail/'+ encrypt(data.data.target[0].msisdn + '&&msisdn') + '/home';
                    break;

                default:
                    // code block
            }

            return url;
        },
        // pagination() {
        //     // update fontawesome to iconfont
        //     var paginationButtonPreview = document.getElementsByClassName("fa-angle-left");

        //     if (paginationButtonPreview[0]) {
        //         var classListPrev = paginationButtonPreview[0].classList;

        //         if (Object.values(classListPrev).filter(key => ['iconfont'].includes(key)).length === 0) {
        //             paginationButtonPreview[0].classList.add('iconfont');
        //             paginationButtonPreview[0].classList.add('iconleft');
        //             paginationButtonPreview[0].style.fontSize = '12px';
        //         }
        //     }

        //     var paginationButtonNext = document.getElementsByClassName("fa-angle-right");

        //     if (paginationButtonNext[0]) {
        //         var classListNext = paginationButtonNext[0].classList;

        //         if (Object.values(classListNext).filter(key => ['iconfont'].includes(key)).length === 0) {
        //             paginationButtonNext[0].classList.add('iconfont');
        //             paginationButtonNext[0].classList.add('iconright');
        //             paginationButtonNext[0].style.fontSize = '12px';
        //         }
        //     }
        // },
    },
    created() {
        // 
    },
    mounted() {
        // this.$store.dispatch('notification/getCount');
        this.getData();
        EventEmit.$on('notificationOnPageNotifications', () => {
            this.items_per_page = 10;
            this.page = 0;
            this.notification_type = 'all';
            this.$store.commit('notification/setList', {});
            this.getData();
        });
    },
}
</script>

<style lang="sass" scoped>
    .box-notification
        .list-notification
            @apply .border-b .p-4 .flex .items-center .justify-between .cursor-pointer
            color: $color-main-500
            font-weight: 900
            &:hover
                background-color: $color-secondary-600
            &.unread
                @apply .text-gray-600
                font-weight: 400
                .bullet
                    display: none
</style>
