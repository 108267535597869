<template lang="pug">
  .card
      .card__header
          h2.text-xl Activity Location
      .card__body
          .flex.flex-wrap(
              v-if="status_activity_location.status === 'loading'"
              class="lg:-mx-2"
          )
              //- Loading(show="true")
              LoadingAnimation(show="true" title="overall-activity-location")
          .flex.flex-wrap(v-else)
              .block(class="w-full mb-4 lg:w-4/12 lg:mb-0")
                  .rollout.pb-4
                      span.text-xs Operator
                      p.text-light
                          | {{ activity_location.data_location && activity_location.data_location.operator ? activity_location.data_location.operator : (activity_location.recent_location && activity_location.recent_location.features ? activity_location.recent_location.features[0].properties.provider : '-') }}
                  //- .rollout.pb-4
                      span.text-xs Signal
                      p.text-light 
                          | {{ activity_location.data_location && activity_location.data_location.net_type ? activity_location.data_location.net_type : '-' }}
                  .rollout.pb-4
                      span.text-xs BTS
                      p.text-light 
                          | {{ activity_location.data_location && activity_location.data_location.bts ? activity_location.data_location.bts : (activity_location.recent_location && activity_location.recent_location.features ? activity_location.recent_location.features[0].properties.bts : '-') }}
                  .rollout.pb-4
                      span.text-xs Country
                      p.text-light 
                          | {{ activity_location.data_location && activity_location.data_location.country ? activity_location.data_location.country : (activity_location.recent_location && activity_location.recent_location.features ? activity_location.recent_location.features[0].properties.country : '-') }}
                  .rollout.pb-4
                      span.text-xs Date time
                      p.text-light 
                          | {{ activity_location.data_location && activity_location.data_location.datetime ? activity_location.data_location.datetime : (activity_location.recent_location && activity_location.recent_location.features ? activity_location.recent_location.features[0].properties.dtm : '-') }}
                  //- .rollout.pb-4
                      span.text-xs Information
                      p.text-light
                          | {{ status_activity_location.status ? ((activity_location.data_location && Object.keys(activity_location.heatmap).length > 0 && Object.keys(activity_location.recent_location).length > 0 && activity_location.recent_location.features && (activity_location.recent_location.features[0].geometry.coordinates[0] !== '' && activity_location.recent_location.features[0].geometry.coordinates[0] !== null) && (activity_location.recent_location.features[0].geometry.coordinates[1] !== '' && activity_location.recent_location.features[0].geometry.coordinates[1] !== null)) ? 'Success load data.' : 'Failed load data, some data is missing or something wrong.') : '-' }}
                  .rollout.pb-4
                      span.text-xs Address
                      p.text-light
                          | {{ activity_location.recent_location && activity_location.recent_location.features && activity_location.recent_location.features[0].properties.alamat ? activity_location.recent_location.features[0].properties.alamat : '-' }}

              //- .block(class="w-full lg:w-8/12")
                  //- BoxMap
                  //- .box-map

              template(v-if="(activity_location.data_location && (Object.keys(activity_location.heatmap).length > 0 || Object.keys(activity_location.recent_location).length > 0)) && mapLoaded")
                  .block(class="w-full lg:w-8/12")
                      //- .box-map
                      gmap-map(ref="mapRef" :center="center" :zoom="10" :options="options" style="width:100%; height: 100%;")

              .block(v-else class="w-full lg:w-8/12")
                  //- .box-map
                  NoDataAvailable

</template>

<script>
// import BoxMap from '@/components/Map.vue'
import { mapState } from 'vuex';
import { gmapApi } from 'vue2-google-maps';
import moment from 'moment';
import Loading from '@/components/Loading.vue';
import LoadingAnimation from '@/components/LoadingAnimation.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';

export default {
    name: 'OverallActivityLocation',
    components: {
        // BoxMap,
        Loading,
        NoDataAvailable,
        LoadingAnimation
    },
    props: ['isKey'],
    data() {
        return {
            infoWindows: [],
            mapLoaded: false,
            options: {
                // zoomControl: true,
                // mapTypeControl: true,
                // scaleControl: true,
                // streetViewControl: true,
                // rotateControl: true,
                // fullscreenControl: false,
                // disableDefaultUi: false
                // scrollwheel: false,
                gestureHandling: 'cooperative',
                styles: [
                    {elementType: 'geometry', stylers: [{color: '#242f3e'}]},
                    {elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}]},
                    {elementType: 'labels.text.fill', stylers: [{color: '#746855'}]},
                    {
                      featureType: 'administrative.locality',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#d59563'}]
                    },
                    {
                      featureType: 'poi',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#d59563'}]
                    },
                    {
                      featureType: 'poi.park',
                      elementType: 'geometry',
                      stylers: [{color: '#263c3f'}]
                    },
                    {
                      featureType: 'poi.park',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#6b9a76'}]
                    },
                    {
                      featureType: 'road',
                      elementType: 'geometry',
                      stylers: [{color: '#38414e'}]
                    },
                    {
                      featureType: 'road',
                      elementType: 'geometry.stroke',
                      stylers: [{color: '#212a37'}]
                    },
                    {
                      featureType: 'road',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#9ca5b3'}]
                    },
                    {
                      featureType: 'road.highway',
                      elementType: 'geometry',
                      stylers: [{color: '#746855'}]
                    },
                    {
                      featureType: 'road.highway',
                      elementType: 'geometry.stroke',
                      stylers: [{color: '#1f2835'}]
                    },
                    {
                      featureType: 'road.highway',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#f3d19c'}]
                    },
                    {
                      featureType: 'transit',
                      elementType: 'geometry',
                      stylers: [{color: '#2f3948'}]
                    },
                    {
                      featureType: 'transit.station',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#d59563'}]
                    },
                    {
                      featureType: 'water',
                      elementType: 'geometry',
                      stylers: [{color: '#17263c'}]
                    },
                    {
                      featureType: 'water',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#515c6d'}]
                    },
                    {
                      featureType: 'water',
                      elementType: 'labels.text.stroke',
                      stylers: [{color: '#17263c'}]
                    }
                ],
            },
            center: {},
            markers: [],
            places: [],
            // myLatlng: null,
        };
    },
    computed: {
        ...mapState('overall', [
          'status_activity_location',
          'activity_location',
        ]),
        google: gmapApi,
        route_name() {
            return this.$route.name;
        },        
    },
    watch: {
        // activity_location (newData, oldData) {
        activity_location() {
            // Our fancy notification (2).
            // console.log('====== watch on RecentLocation.vue')
            // console.log({newData, oldData})
            this.startMap();
        },
        google() {},
    },
    methods: {
        hideAllInfoWindows() {
            for (var i=0; i < this.infoWindows.length; i++) {
                this.infoWindows[i].close();
            }
        },
        moment: function (string, string_format, format) {
            return moment(string, string_format).format(format);
        },
        startMap() {
            // console.log('start Map')
            var self = this;
            var location = [];
            // const google = self.google;

            if(typeof this.activity_location.recent_location !== undefined && typeof this.activity_location.recent_location !== 'undefined' && this.activity_location.recent_location !== null){
                location = this.activity_location.recent_location
            } else {
                return;
            }

            if (Object.keys(location).length > 0 || Object.keys(this.activity_location.heatmap).length > 0) {
                

                var result = Object.keys(location).length > 0 ? location.features[0] : null;

                var lng = parseFloat(result ? result.geometry.coordinates[0] : this.activity_location.heatmap[0][1]);

                var lat = parseFloat(result ? result.geometry.coordinates[1] : this.activity_location.heatmap[0][0]);

                // for marker
                // var dtm = moment().format('DD MMMM YYYY, hh:mm');
                // var dtm = (result && result.properties.dtm ? moment(result.properties.dtm, 'YYYY-MM-DDThh:mm:ss').format('DD MMMM YYYY, hh:mm') : '-');
                // var dtm = (result && result.properties.dtm ? result.properties.dtm : '-');
                var dtm = (this.activity_location.data_location && this.activity_location.data_location.datetime ?this.activity_location.data_location.datetime: '-');
                // var bts = (this.activity_location.data_location && this.activity_location.data_location.bts ? this.activity_location.data_location.bts : '-');
                var bts = (this.activity_location.data_location && this.activity_location.data_location.bts ? this.activity_location.data_location.bts : (result && result.properties.bts ? result.properties.bts : '-'));
                // var state = (result && result.properties.state ? result.properties.state : '-');

                // console.log('lan, lng on recent location : ');
                // console.log({lat, lng});

                var name = result ? result.properties.name : '-';
                // set_map(name, lat, lng, results.heatmap)

                if (Number.isNaN(lat) || Number.isNaN(lng)) {
                    return;
                }

                this.center = {
                    lat: lat,
                    lng: lng,
                };
                // console.log('center :')
                // console.log(this.center)

                this.mapLoaded = true;
                // console.log('mapLoaded :')
                // console.log(this.mapLoaded)

                // ------------------------------------------------------

                // if (this.activity_location.heatmap == null) {
                //     this.$nextTick(() => {
                //         this.$refs.mapRef.$mapPromise.then((map) => {
                //             map.zoom = 10;
                //         });
                //     });
                // }

                // console.log('before if else map')
                // map config
                if (self.activity_location.heatmap != null && typeof self.activity_location.heatmap[0] !== 'undefined' && typeof self.activity_location.heatmap[0] !== undefined ) {
                    
                    // console.log('if map')

                    self.$nextTick(() => {
                        self.$refs.mapRef.$mapPromise.then((map) => {
                            const google = self.google;

                            map.center = new google.maps.LatLng(self.center);
                            map.zoom = 10;

                            // eslint-disable next-line
                            let marker = new google.maps.Marker({
                                position: new google.maps.LatLng(self.center),
                                map: map,
                                // title: name,
                            });
                            // console.log(marker)
                            self.markers.push(marker);

                            // let marker2 = new google.maps.Marker({
                            //     position: new google.maps.LatLng({
                            //         lat: -6.2256095,
                            //         lng: 106.7754866
                            //     }),
                            //     map: map 
                            // });
                            // self.markers.push(marker2);

                            // -------------------------
                            var contentString = '<div id="content">'+
                              '<div id="bodyContent">'+
                                  // '<p>Link Location: <strong><a target="_blank" href="https://www.google.com/maps?q='+self.center.lat+','+self.center.lng+'">'+
                                  // 'Google Maps</a></strong> '+
                                  // '</p>'+
                                '<table>'+
                                  '<tr>'+
                                    '<td class="pr-1">Last Updated</td>'+
                                    '<td class="pr-1">:</td>'+
                                    '<td>'+dtm+'</td>'+
                                  '</tr>'+
                                  '<tr>'+
                                    '<td>BTS</td>'+
                                    '<td>:</td>'+
                                    '<td>'+bts+'</td>'+
                                  '</tr>'+
                                  // '<tr>'+
                                  //   '<td>State</td>'+
                                  //   '<td>:</td>'+
                                  //   '<td>'+state+'</td>'+
                                  // '</tr>'+
                                  '<tr>'+
                                    '<td>Link Location</td>'+
                                    '<td>:</td>'+
                                    '<td><a target="_blank" href="https://www.google.com/maps?q='+self.center.lat+','+self.center.lng+'" style="color: #43ea92;">'+'Google Maps</a> '+
                                    '</td>'+
                                  '</tr>'+
                                '</table>'+
                              '</div>'+
                            '</div>';

                            const infowindow = new google.maps.InfoWindow({
                                content: contentString,
                                maxWidth: 300
                            });
                            
                            self.infoWindows.push(infowindow);

                            // -------------------------
                            // var contentString2 = '<div id="content">'+
                            //       '<div id="bodyContent">'+
                            //       '<p>Link Location 2: <strong><a target="_blank" href="https://www.google.com/maps?q='+-6.2256095+','+106.7754866+'">'+
                            //       'Google Maps</a></strong> '+
                            //       '</p>'+
                            //       '</div>'+
                            //       '</div>';

                            // const infowindow2 = new google.maps.InfoWindow({
                            //     content: contentString2,
                            //     maxWidth: 200
                            // });
                            
                            // self.infoWindows.push(infowindow2); 
                            
                            google.maps.event.addListener(marker, 'click', function() {
                              // self.hideAllInfoWindows(map);
                              self.hideAllInfoWindows();
                              infowindow.open(map, marker);
                            });

                            // google.maps.event.addListener(marker2, 'click', function() {
                            //   // self.hideAllInfoWindows(map);
                            //   self.hideAllInfoWindows();
                            //   infowindow2.open(map, marker2);
                            // });
                            // -------------------------

                            var heatmapData = [];
                            self.activity_location.heatmap.forEach(function(coord) {
                                heatmapData.push(new google.maps.LatLng(coord[0], coord[1]));
                            });

                            var setHeatmap = new google.maps.visualization.HeatmapLayer({
                              data: heatmapData,
                              map: map
                            });

                            var bounds = new google.maps.LatLngBounds();

                            // Extend bounds with each point
                            for (var i = 0; i < heatmapData.length; i++) {
                                bounds.extend(heatmapData[i]);
                            }

                            // add marker to bounds
                            bounds.extend(new google.maps.LatLng(self.center.lat, self.center.lng));

                            // Apply fitBounds
                            map.fitBounds(bounds);

                            setHeatmap.setMap(map);
                            setHeatmap.set('radius', 30);
                        });
                    });
                } else {
                    // console.log('else map')

                    self.$nextTick(() => {
                        self.$refs.mapRef.$mapPromise.then((map) => {
                            const google = self.google;

                            // map.center = self.myLatlng;
                            map.center = new google.maps.LatLng(self.center);
                            map.zoom = 10;

                            let marker = new google.maps.Marker({
                                position: new google.maps.LatLng(self.center),
                                // position: self.myLatlng,
                                map: map,
                                title: name,
                            });

                            // console.log(name)
                            
                            // marker.setMap(map);
                            var contentString = '<div id="content">'+
                              '<div id="bodyContent">'+
                                  // '<div id="bodyContent">'+
                                  // '<p>Link Location: <a target="_blank" href="https://www.google.com/maps?q='+lat+','+lng+'">'+
                                  // 'Google Maps</a> '+
                                  // '</p>'+
                                '<table>'+
                                  '<tr>'+
                                    '<td class="pr-1">Last Updated</td>'+
                                    '<td class="pr-1">:</td>'+
                                    '<td>'+dtm+'</td>'+
                                  '</tr>'+
                                  '<tr>'+
                                    '<td>BTS</td>'+
                                    '<td>:</td>'+
                                    '<td>'+bts+'</td>'+
                                  '</tr>'+
                                  '<tr>'+
                                    '<td>State</td>'+
                                    '<td>:</td>'+
                                    '<td>'+state+'</td>'+
                                  '</tr>'+
                                  '<tr>'+
                                    '<td>Link Location</td>'+
                                    '<td>:</td>'+
                                    '<td><a target="_blank" href="https://www.google.com/maps?q='+lat+','+lng+'" style="color: #43ea92;">'+'Google Maps</a> '+
                                    '</td>'+
                                  '</tr>'+
                                '</table>'+
                              '</div>'+
                            '</div>';

                            let infowindow = new google.maps.InfoWindow({
                                content: contentString,
                                maxWidth: 300,
                            });

                            self.infoWindows.push(infowindow);
                            
                            // marker.addListener('click', function() {
                            google.maps.event.addListener(marker, 'click', function() {
                                infowindow.open(map, marker);
                            });
                        });
                    });
                }
            }
        },
    },
    mounted() {
        if (this.isKey)
            this.startMap();
    },
}
</script>

<style lang="sass">
    .box-map
        @apply .flex .items-center .justify-center .w-full
        min-height: 450px
        background-color: $color-secondary-800
</style>
