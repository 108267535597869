<template lang="pug">
.header-menu
    .header-menu__header
        .m-only
            button.btn-icon-40(
                    v-on:click="showMenu = !showMenu"
                )
                IconSet(type="menu")
    .header-menu__body(
        v-bind:class="{'is-show': showMenu}"
    )
        .m-only
            .flex.items-center.justify-between.p-4.border-b
                h2.text-xl Menu
                button.btn-icon-40(
                    v-on:click="showMenu = !showMenu"
                )
                    IconSet(type="close")
        
        ul(v-if="targetMenu.length > 0 || otherMenu.length > 0")
            li.parent-menu(v-if="targetMenu.length > 0")
                router-link(to="")
                    span Target Management
                    IconSet(type="chevron-down")
                ul.sub-menu
                    li(v-for="item in targetMenu" :key="item.id")
                        router-link(
                            :to="item.url"
                            @click="showMenu = !showMenu"
                            ) {{ item.name}}
            li.parent-menu(v-if="otherMenu.length > 0")
                router-link(to="")
                    span Other
                    IconSet(type="chevron-down")
                ul.sub-menu
                    li(v-for="item in otherMenu" :key="item.id")
                        router-link(
                            :to="item.url"
                            @click="showMenu = !showMenu"
                            ) {{ item.name}}
</template>

<script>
import { mapState } from 'vuex';
import IconSet from '@/components/IconSet.vue';
import { checkFeature } from '@/config/features';

export default {
    name: 'HeaderMenu',
    components: {
        IconSet
    },
    data() {
        return {
            showMenu: false,
        };
    },
    computed: {
        ...mapState('auth', ['user']),
        // ...mapGetters({
        //     hideMultiTracking: 'config/getHideMultiTracking',
        // }),
        targetMenu() {
            let targetMenu = [];

            let userRole = this.user && this.user.role ? this.user.role : null
            let roleLabel = userRole && userRole.label ? userRole.label : ''
            let roleModule = userRole && userRole.module && userRole.module.length > 0 ? userRole.module : []
            let isSuper = roleLabel ? roleLabel === 'superadmin' : false

            // if (roleLabel && roleLabel === 'analyst')
            //     return targetMenu;

            if (roleModule || isSuper) {
            // if (this.user && this.user.role && (this.user.role.label === 'superadmin' || (this.user.role.module && this.user.role.module.length > 0))) {
                
                // case management
                if (checkFeature('case_management')) {
                    let check_case_management = roleModule.filter(function(item) {
                        return item.label === 'case_management';
                    });
                    if (check_case_management.length > 0 || isSuper)
                        targetMenu.push({
                            name: 'Case Management',
                            url: '/case-management'
                        });
                }

                // target
                if (checkFeature('target')) {
                    let check_target = roleModule.filter(function(item) {
                        return item.label === 'single_target';
                    });
                    if (check_target.length > 0 || isSuper)
                        targetMenu.push({
                            name: 'Target',
                            url: '/target'
                        });
                }

                // multi tracking
                if (checkFeature('multi_tracking')) {
                    let check_multi_tracking = roleModule.filter(function(item) {
                        return item.label === 'multi_target';
                    });
                    if (check_multi_tracking.length > 0 || isSuper)
                        targetMenu.push({
                            name: 'Multi Tracking',
                            url: '/multi-tracking'
                        });
                }

                // dcd
                if (checkFeature('dcd')) {
                    let check_dcd = roleModule.filter(function(item) {
                        return item.label === 'dcd';
                    });
                    if (check_dcd.length > 0 || isSuper)
                        targetMenu.push({
                            name: 'DCD',
                            url: '/dcd'
                        });
                }

                // territory virtual
                // if (checkFeature('territory_virtual')) {
                    // let check_territory_virtual = roleModule.filter(function(item) {
                    //     return item.label === 'territory_virtual';
                    // });
                    // if (check_territory_virtual.length > 0 || isSuper)
                    //     targetMenu.push({
                    //         name: 'Territory Virtual',
                    //         url: '/territory-virtual'
                    //     });
                // }
            }
            
            return targetMenu;
        },
        otherMenu() {
            let otherMenu = [];

            let userRole = this.user && this.user.role ? this.user.role : null
            let roleLabel = userRole && userRole.label ? userRole.label : ''
            let roleModule = userRole && userRole.module && userRole.module.length > 0 ? userRole.module : []
            let isSuper = roleLabel ? roleLabel === 'superadmin' : false

            // if (roleLabel && roleLabel === 'analyst')
            //     return otherMenu;

            if (roleModule || isSuper) {
            // if (this.user && this.user.role && (this.user.role.label === 'superadmin' || (this.user.role.module && this.user.role.module.length > 0))) {

                // tactical devices
                // if (checkFeature('tactical_devices')) {
                    // let check_tactical_devices = roleModule.filter(function(item) {
                    //     return item.label === 'tactical_device';
                    // });
                    // if (check_tactical_devices.length > 0 || isSuper)
                    //     otherMenu.push({
                    //         name: 'Tactical Devices',
                    //         url: '/tactical-device'
                    //     });
                // }

                // monitoring log
                if (checkFeature('monitoring_log')) {
                    let check_monitoring_log = roleModule.filter(function(item) {
                        return item.label === 'log_activity';
                    });
                    if (check_monitoring_log.length > 0 || isSuper)
                        otherMenu.push({
                            name: 'Monitoring Log',
                            url: '/monitoring-log'
                        });
                }

                // tracking history
                if (checkFeature('tracking_history')) {
                    let check_tracking_history = roleModule.filter(function(item) {
                        return item.label === 'tracking_target';
                    });
                    if (check_tracking_history.length > 0 || isSuper)
                        otherMenu.push({
                            name: 'Tracking History',
                            url: '/tracking-history'
                        });
                }

                // tracking history
                if (checkFeature('log_management')) {
                    let check_tracking_history = roleModule.filter(function(item) {
                        return item.label === 'log_management';
                    });
                    if (check_tracking_history.length > 0 || isSuper || roleLabel === 'admin')
                        otherMenu.push({
                            name: 'Log Management',
                            url: '/log-management'
                        });
                }

                // predictoin name
                // if (checkFeature('prediction_name')) {
                //     let check_prediction_name = roleModule.filter(function(item) {
                //         return item.label === 'prediction_name';
                //     });
                //     if (check_prediction_name.length > 0 || isSuper)
                //         otherMenu.push({
                //             name: 'Prediction Name',
                //             url: '/prediction-name'
                //         });
                // }

                // tracking history
                if (checkFeature('log_auth')) {
                    let check_log_auth = roleModule.filter(function(item) {
                        return item.label === 'log_auth';
                    });
                    if (check_log_auth.length > 0 || isSuper)
                        otherMenu.push({
                            name: 'Users Log',
                            url: '/users-log'
                        });
                }
            }
            
            return otherMenu;
        },
    },
    watch: {
        targetMenu() {},
        otherMenu() {},
    },
    methods: {
        // checkFeature(feature) {
        //     console.log(features);
        //     return features.includes(feature);
        //     console.log(feature);
        //     console.log(checkFeature(feature));
        //     return checkFeature(feature);
        // },
    },
}
</script>

<style lang="sass">
.header-menu
    &__header
    &__body
        &.is-show
            display: block
        > ul
            @apply .flex .items-center
            li
                > a
                    @apply .block .px-3
                    font-size: 14px
                &.parent-menu
                    @apply .relative
                    a
                        @apply .flex .items-center .justify-between .py-6 .px-4
                        i
                            @apply .ml-2
                            transition: all 0.3s ease-in-out
                            transform: rotate(0deg)
                            svg
                                width: 16px
                                height: 16px
                    .sub-menu
                        @apply .absolute .z-20
                        width: 200px
                        right: 0
                        top: 100%
                        background-color: $color-secondary-700
                        display: none
                        li
                            a
                                @apply .block .py-4 .px-6
                                &:hover
                                    background-color: $color-main-500
                                    color: $color-secondary-600
                &:hover
                    a
                        background-color: $color-secondary-700
                        i
                            transform: rotate(180deg)
                    .sub-menu
                        display: block
                        animation: FadeIn 1.3s ease

    @keyframes FadeIn
        from
            opacity: 0
        to
            opacity: 1

@media screen and (max-width: 480px)
    .header-menu
        &__header
        &__body
            @apply .fixed .inset-0 .w-full .h-full
            background-color: $color-secondary-700
            z-index: 1000
            display: none
            > ul
                @apply .block
                padding-top: 2rem
                li
                    > a
                        @apply .block .px-3
                        font-size: 14px
                    &.parent-menu
                        @apply .relative .pb-4 .mb-4
                        border-bottom-width: 2px
                        border-bottom-style: solid
                        > a
                            @apply .block .opacity-50 .px-6 .py-0
                            i
                                display: none
                        .sub-menu
                            @apply .relative .z-20 .w-full
                            right: unset
                            top: unset
                            background-color: transparent
                            display: block
                            li
                                a
                                    @apply .block .py-4 .px-6 .text-xl
                                    &:hover
                                        background-color: $color-main-500
                                        color: $color-secondary-600


</style>