import axios from "@/axios-main";
import axiosOrigin from "axios";
import Vue from "vue";

let cancelList, cancelCount;
var cancelToken = axiosOrigin.CancelToken;

const notification = {
  namespaced: true,
  state: {
    fcm_token: null,

    status_list: {
      status: false,
      cancel: null,
      code: null,
    },

    cancel_count: null,

    list: {},
    count: [],
  },
  mutations: {
    setFcmToken: (state, data) => {
      state.fcm_token = data;
    },

    setStatusList: (state, status) => {
      state.status_list = status;
    },

    setCancelCount: (state, cancel) => {
      state.cancel_count = cancel;
    },

    setList: (state, data) => {
      state.list = data;
    },
    setCount: (state, data) => {
      state.count = data;
    },
  },
  getters: {},
  actions: {
    fcmSubscribeToTopic({ state, commit }) {

      const fcmConfig = Vue.prototype.$fcmConfig;

      if (
        state.fcm_token !== null && 
        state.fcm_token !== '' && 
        fcmConfig.TOPIC !== null && 
        fcmConfig.TOPIC !== '' &&
        fcmConfig.SERVER_KEY !== null && 
        fcmConfig.SERVER_KEY !== ''
      ) {

        let params = new FormData();
        // params.append('size', 100);

        axiosOrigin.post('https://iid.googleapis.com/iid/v1/'+ state.fcm_token +'/rel/topics/'+ fcmConfig.TOPIC, params, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'key='+ fcmConfig.SERVER_KEY
          },
        });
        // .then((resp) => {
        //   console.log(resp)
        // });

      } else {
        console.log('subscribe failed');

        if (state.fcm_token !== null || state.fcm_token !== '')
          console.log('fcm token is empty');

        if (fcmConfig.TOPIC !== null || fcmConfig.TOPIC !== '')
          console.log('fcm topic is empty');

        if (fcmConfig.SERVER_KEY !== null || fcmConfig.SERVER_KEY !== '')
          console.log('fcm serve key is empty');
      }
    },

    // getNotificationList({ commit, dispatch }, [limit=null, page=null, keyword=null, order_by=null, from_date=null, to_date=null]) {
    async getList({ state, commit, dispatch }, [limit=null, page=null, type=null]) {

      if (state.status_list.cancel !== null && state.status_list.status === 'loading')
        await state.status_list.cancel('Operation canceled by the user.');

      let params = new FormData();
      params.append('limit', limit);
      params.append('page', page);
      params.append('type', type);
      // params.append('keyword', keyword);
      // params.append('order_by', order_by);
      // params.append('from_date', from_date);
      // params.append('to_date', to_date);
    
      axios.post('api/v1/notification/list', params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelList = c;
          commit('setStatusList', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setList', data);

        if (Object.keys(data).length===0)
          result.status = 'empty';

        commit('setStatusList', result);
      })
      .catch((resp) => {
        // console.log(resp)
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelList;
        }

        commit('setStatusList', result);
        if (!axiosOrigin.isCancel(resp)) 
          dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
      });
    },

    async getCount({ commit, dispatch, state }) {

      if (state.cancel_count !== null)
        await state.cancel_count('Operation canceled by the user.');

      let params = new FormData();
      // params.append('limit', limit);
      // params.append('page', page);
      // params.append('keyword', keyword);
      // params.append('order_by', order_by);
      // params.append('from_date', from_date);
      // params.append('to_date', to_date);

      axios.post('api/v1/notification/count', params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelCount = c;
          commit('setCancelCount', c);
        }),
      })
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        commit('setCount', data);
        commit('setCancelCount', null);
      })
      .catch((resp) => {
        // console.log(resp)
        if (axiosOrigin.isCancel(resp))
          commit('setCancelCount', cancelCount);
        if (!axiosOrigin.isCancel(resp)) 
        dispatch('errors/handleApiResponse', [(resp.response && resp.response.status ? resp.response.status : null)], { root: true });
      });
    },

    // update notification as read
    async update({ commit, dispatch }, id) {

      let params = new FormData();
      params.append('id', id);
    
      await axios.post('api/v1/notification/update', params)
      // .then((resp) => {
      //   console.log(resp.data)
      // })
      .catch((resp) => {
        // console.log(resp)
        dispatch('errors/handleApiResponse', [(resp.response && resp.response.status ? resp.response.status : null)], { root: true });
      });
    },

    async updateAll({ commit, dispatch }, type=null) {

      let params = new FormData();
      params.append('type', type);
    
      await axios.post('api/v1/notification/bulk-update', params)
      // .then((resp) => {
      //   console.log(resp.data)
      // })
      .catch((resp) => {
        // console.log(resp)
        dispatch('errors/handleApiResponse', [(resp.response && resp.response.status ? resp.response.status : null)], { root: true });
      });
    },
  }
};

export default notification;
