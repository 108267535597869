import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/index'

import DashboardPage from './../pages/dashboard/Index'
// import AuthPage from './../pages/auth/Login'
import LoginPage from '@/pages/auth/Login.vue'
import ForgotPage from '@/pages/auth/Forgot.vue'
import ResetPage from '@/pages/auth/Reset.vue'

// License auth
import PageLicense from '@/pages/auth/License.vue'
import PageLicenseExpired from '@/pages/auth/Expired.vue'

// license pages
import LicenseShell from '@/pages/license/LicenseShell.vue'
import pageLicenseIndex from '@/pages/license/Index.vue'
import pageLicenseHistory from '@/pages/license/History.vue'
import pageLicenseForm from '@/pages/license/Form.vue'

// result
import ResultShell from '@/pages/result/ResultShell.vue'
import ResultRecentLocation from '@/pages/result/recent-location/Index.vue'
import ResultOverall from '@/pages/result/overall/Index.vue'
import ResultLocationMovement from '@/pages/result/location-movement/Index.vue'
import ResultLocationLog from '@/pages/result/location-log/Index.vue'
import ResultBrowsingLog from '@/pages/result/browsing-log/Index.vue'
import ResultAerialLog from '@/pages/result/aerial-log/Index.vue'
import ResultProfile from '@/pages/result/profile/Index.vue'
// import ResultDeviceLog from '@/pages/result/device-log/Index.vue'
import ResultHistoryDeviceRoles from '@/pages/result/history-device-roles/Index.vue'
import ResultDeviceHistory from '@/pages/result/device-history/Index.vue'
import ResultTetheredDevice from '@/pages/result/tethered-device/Index.vue'
import ResultPredictionName from '@/pages/result/prediction-name/Index.vue'
import ResultDigitalFootprint from '@/pages/result/digital-footprint/Index.vue'
import pageTargetDetailHome from '@/pages/result/information/Index.vue'

// Clients
// import ClientsShell from '@/pages/clients/Clients.vue'
// import pageClientsIndex from '@/pages/clients/Index.vue'
// import pageClientsForm from '@/pages/clients/Form.vue'

// Modules
import ModulesShell from '@/pages/modules/Modules.vue'
import pageModulesIndex from '@/pages/modules/Index.vue'
import pageModulesForm from '@/pages/modules/Form.vue'

// Functions
import FunctionsShell from '@/pages/functions/Functions.vue'
import pageFunctionsIndex from '@/pages/functions/Index.vue'
import pageFunctionsForm from '@/pages/functions/Form.vue'

// Roles
import RolesShell from '@/pages/roles/Roles.vue'
import pageRolesIndex from '@/pages/roles/Index.vue'
import pageRolesForm from '@/pages/roles/Form.vue'

// Versions
import VersionsShell from '@/pages/versions/Versions.vue'
import pageVersionsIndex from '@/pages/versions/Index.vue'
import pageVersionsForm from '@/pages/versions/Form.vue'

// Users
import UsersShell from '@/pages/users/Users.vue'
import pageUsersIndex from '@/pages/users/Index.vue'
import pageUsersForm from '@/pages/users/Form.vue'
import pageUsersLogActivity from '@/pages/users/LogActivity.vue'

// Target
import targetShell from '@/pages/target/TargetShell.vue'
import pageTargetIndex from '@/pages/target/Index.vue'
import pageTargetForm from '@/pages/target/Form.vue'
// page form target without fr/tensorflow
import pageTargetFormOld from '@/pages/target/Form-old.vue'
import pageTargetMaps from '@/pages/target/Maps.vue'
import pageTargetHistory from '@/pages/target/History.vue'
import pageTargetSummary from '@/pages/target/Summary.vue'

// Multi Target
import multiTrackingShell from '@/pages/multiTracking/MultiTrackingShell.vue'
import pageMultiTrackingIndex from '@/pages/multiTracking/Index.vue'
import pageMultiTrackingForm from '@/pages/multiTracking/Form.vue'
import pageMultiTrackingDetailShell from '@/pages/multiTracking/detail/MultiTrackingDetailShell.vue'
import pageMultiTrackingMeetingPoint from '@/pages/multiTracking/detail/MeetingPoint.vue'
import pageMultiTrackingLocationHistory from '@/pages/multiTracking/detail/LocationHistory.vue'
import pageMultiTrackingMonitoringHistory from '@/pages/multiTracking/detail/MonitoringHistory.vue'
import pageMultiTrackingMeetingPointDetail from '@/pages/multiTracking/detail/meetingPoint/Detail.vue'

// Case Management
import pageCaseManagementShell from '@/pages/caseManagement/CaseManagementShell.vue'
import pageCaseManagementIndex from '@/pages/caseManagement/Index.vue'
import pageCaseManagementForm from '@/pages/caseManagement/Form.vue'


// DCD
import pageDcdShell from '@/pages/dcd/DcdShell.vue'
import pageDcdIndex from '@/pages/dcd/Index.vue'
import pageDcdForm from '@/pages/dcd/Form.vue'
import pageDcdDetail from '@/pages/dcd/Detail.vue'

// Territory Virtual
import TerritoryVirtualShell from '@/pages/territoryVirtual/TerritoryVirtualShell.vue'
import pageTerritoryVirtualIndex from '@/pages/territoryVirtual/Index.vue'
import pageTerritoryVirtualForm from '@/pages/territoryVirtual/Form.vue'
import pageTerritoryVirtualDetail from '@/pages/territoryVirtual/Detail.vue';
import pageTerritoryVirtualDetailNotification from '@/pages/territoryVirtual/DetailNotification.vue';

// Tactical Device
import TacticalDeviceShell from '@/pages/tacticalDevice/TacticalDeviceShell.vue';
import pageTacticalDeviceIndex from '@/pages/tacticalDevice/Index.vue';
import pageTacticalDeviceForm from '@/pages/tacticalDevice/Form.vue';
import pageTacticalDeviceMaps from '@/pages/tacticalDevice/Maps.vue';


// Monitoring Log
import MonitoringLogShell from '@/pages/monitoring/MonitoringLogShell.vue'
import pageMonitoringLogIndex from '@/pages/monitoring/Index.vue'
import pageMonitoringLogChart from '@/pages/monitoring/Chart.vue'

// page notification
import pageNotifications from '@/pages/notifications/Index.vue'

// Tracking History
import pageTrackingHistory from '@/pages/trackingHistory/Index.vue'

// Acl
import AclShell from '@/pages/acl/Acl.vue'
import pageAclForm from '@/pages/acl/Form.vue'

// Profile
import pageProfile from '@/pages/profile/Index.vue'

// Negative Case
import PageNotFound from '@/pages/errors/NotFound'
import PageForbidden from '@/pages/errors/Forbidden'

// face recognition
import PageFaceRecognition from '@/pages/faceRecognition/Index.vue'

// Prediction Name
import PredictionName from '@/pages/predictionName/Index.vue'

// Log Management
import PageLogManagement from '@/pages/logManagement/Index.vue'

// UsersLog
import PageUsersLog from '@/pages/usersLog/Index.vue'

// Digital Cyber Data
// import pageDeepVoidShell from '@/pages/deepVoid/DeepVoidShell.vue'
import pageDeepVoidForm from '@/pages/deepVoid/Form.vue'

// Ultimo
// import pageDeepVoidShell from '@/pages/deepVoid/DeepVoidShell.vue'
import pageUltimoForm from '@/pages/ultimo/Form.vue'
import pageUltimoShell from '@/pages/ultimo/UltimoShell.vue'
import pageUltimoAsyncDataForm from '@/pages/ultimo/AsyncDataForm.vue'

// get list/check function features active
// import features, { checkFeature } from '@/config/features';
import { checkFeature } from '@/config/features';

Vue.use(Router);

function checkFeatureByAcl(module, func) {
  let user = store.getters['auth/getUser']
  // console.log('user', user)
  // console.log('module', module)
  // console.log('func', func)
  if (!user) {
    return false
  }
  if (user?.role?.label === 'superadmin') {
      // console.log('masuk superadmin')
      return true
  } else {
      // console.log('masuk !superadmin')
      let funcArr = []
      try {
          let moduleACL = user.role.module.filter(function(item) {
              return item.label.toUpperCase() === module.toUpperCase();
          });
          // console.log('moduleACL')
          // console.log(moduleACL)
          if (func == null) {
              funcArr = moduleACL
          } else {                        
              funcArr = moduleACL[0].function.filter(function(item) {
                  return item.label.toUpperCase() === func.toUpperCase();
              });
          }
          // console.log('funcArr')
          // console.log(funcArr)
      } catch (error) {
          console.log('error checkFeatureByAcl')
          console.log(error)
      }
      return !!funcArr.length
  }
}

let listMenu = [
  {
    path: 'recent-location',
    name: 'RecentLocation',
    component: ResultRecentLocation,
    isTarget: checkFeatureByAcl('single_target','recent_location'),
  },
  {
    path: 'overall',
    name: 'Overall',
    component: ResultOverall,
    isTarget: checkFeatureByAcl('single_target','overall'),
    isHistorical: checkFeatureByAcl('query_historical','overall'),
  },
  {
    path: 'location-movement',
    name: 'LocationMovement',
    component: ResultLocationMovement,
    isTarget: checkFeatureByAcl('single_target','location_movement'),
    isHistorical: checkFeatureByAcl('query_historical','location_movement'),
  },
  {
    path: 'location-log',
    name: 'LocationLog',
    component: ResultLocationLog,
    isTarget: checkFeatureByAcl('single_target','location_log'),
    isHistorical: checkFeatureByAcl('query_historical','location_log'),
  },
  {
    path: 'browsing-log',
    name: 'BrowsingLog',
    component: ResultBrowsingLog,
    isTarget: checkFeatureByAcl('single_target','browsing_log'),
    isHistorical: checkFeatureByAcl('query_historical','browsing_log'),
  },
  {
    path: 'aerial-log',
    name: 'AerialLog',
    component: ResultAerialLog,
    isTarget: checkFeatureByAcl('single_target','aerial_log'),
    isHistorical: checkFeatureByAcl('query_historical','aerial_log'),
  },
  {
    path: 'profile',
    name: 'Profile',
    component: ResultProfile,
    isTarget: checkFeatureByAcl('single_target','profile'),
    isHistorical: checkFeatureByAcl('query_historical','profile'),
  },
  {
    path: 'history-device-roles',
    name: 'HistoryDeviceRoles',
    component: ResultHistoryDeviceRoles,
    isTarget: checkFeatureByAcl('single_target','history_device_roles'),
    isHistorical: checkFeatureByAcl('query_historical','history_device_roles'),
  },
  {
    path: 'device-history',
    name: 'DeviceHistory',
    component: ResultDeviceHistory,
    isTarget: checkFeatureByAcl('single_target','device_history'),
    isHistorical: checkFeatureByAcl('query_historical','device_history'),
  },
  {
    path: 'tethered-device',
    name: 'TetheredDevice',
    component: ResultTetheredDevice,
    isTarget: checkFeatureByAcl('single_target','tethered_device'),
    isHistorical: checkFeatureByAcl('query_historical','tethered_device'),
  },
  {
    path: 'prediction-name',
    name: 'PredictionName',
    component: ResultPredictionName,
    isTarget: checkFeatureByAcl('single_target','prediction_name'),
    isHistorical: checkFeatureByAcl('query_historical','prediction_name'),
  },
  {
    path: 'digital-footprint',
    name: 'DigitalFootprint',
    component: ResultDigitalFootprint,
    isTarget: checkFeatureByAcl('single_target','digital_footprint'),
  }
]

// let resultMenu = listMenu;
let resultMenu = [];
let targetDetailMenu = [
  {
    path: 'home',
    name: 'pageTargetDetailHome',
    component: pageTargetDetailHome
  },
];
let realtimeMenu = [];
let historicalMenu = [];

if (listMenu.length > 0) {
  for (var i = 0; i < listMenu.length; i++) {
    // resultMenu.push(listMenu[i]);
    resultMenu.push({
        path: listMenu[i].path,
        name: 'Result' + listMenu[i].name,
        component: listMenu[i].component
    });
    // console.log('=====listMenu[i]', listMenu[i])
    if (listMenu[i].isTarget) {
      targetDetailMenu.push({
        path: listMenu[i].path,
        name: 'TargetDetail' + listMenu[i].name,
        component: listMenu[i].component
      });
    }
    if (listMenu[i].name === 'RecentLocation') {
        realtimeMenu.push({
            path: listMenu[i].path,
            name: 'ResultRealtime' + listMenu[i].name,
            component: listMenu[i].component
        });
    } else {
        if (listMenu[i].isHistorical) {          
          historicalMenu.push({
              path: listMenu[i].path,
              name: 'ResultHistorical' + listMenu[i].name,
              component: listMenu[i].component
          });
        }
    }    
  }
  // console.log('=====realtimeMenu', realtimeMenu)
  // console.log('=====historicalMenu', historicalMenu)
  // console.log('=====targetDetailMenu', targetDetailMenu)
}

// default
let routes = [
  {
    path: '/',
    name: 'DashboardPage',
    component: DashboardPage,
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //     path: '/auth',
  //     name: 'AuthPage',
  //     component: AuthPage,
  //     meta: {
  //         layout: 'blank'
  //     }
  // },
  {
    path: '/forgot-password',
    name: 'ForgotPage',
    component: ForgotPage,
    meta: {
      layout: 'blank'
    }
  },
  
  // Login
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage,
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/reset-password',
    name: 'ResetPage',
    component: ResetPage,
    meta: {
      layout: 'blank'
    }
  },

  // Profile
  {
    path:'/profile',
    name: 'pageProfile',
    component: pageProfile,
    meta: {
      requiresAuth: true
    },
  },

  // additional menus
  {
    path: '/device-track',
    // name: 'pageImeiSvShell',
    component: () => import('@/pages/imei-sv-to-imsi/Shell.vue'),
    children: [
      {
        path: '',
        name: 'pageImeiSvIndex',
        component: () => import('@/pages/imei-sv-to-imsi/Index.vue')
      },
      {
        path: ':id/detail/:source?',
        name: 'pageImeiSvDetail',
        component: () => import('@/pages/imei-sv-to-imsi/Detail.vue')
      },
    ]
  },
  {
    path: '/me-id-track',
    // name: 'pageMeIdTrackShell',
    component: () => import('@/pages/me-id-track/Shell.vue'),
    children: [
      {
        path: '',
        name: 'pageMeIdTrackIndex',
        component: () => import('@/pages/me-id-track/Index.vue')
      },
      {
        path: ':id/detail/:source?',
        name: 'pageMeIdTrackDetail',
        component: () => import('@/pages/me-id-track/Detail.vue')
      },
    ]
  },
  {
    path: '/global-territory-virtual',
    component: () => import('@/pages/territory-virtual/Shell.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'territory-virtual-index',
        component: () => import('@/pages/territory-virtual/Index.vue')
      },
      {
        path: 'create',
        name: 'territory-virtual-create',
        component: () => import('@/pages/territory-virtual/Form.vue')
      },
      {
        path: ':id/edit',
        name: 'territory-virtual-edit',
        component: () => import('@/pages/territory-virtual/Form.vue')
      },      
      {
        path: ':id/detail/:data?',
        name: 'territory-virtual-detail',
        component: () => import('@/pages/territory-virtual/Detail.vue')
      }
    ]
  },
  {
    path: '/compare-target-loc',
    // name: 'pageCompareTargetShell',
    component: () => import('@/pages/compare-target-loc/Shell.vue'),
    children: [
      {
        path: '',
        name: 'pageCompareTargetIndex',
        // name: 'compare-target-loc-index',
        component: () => import('@/pages/compare-target-loc/Index.vue')
      },
      {
        path: ':id/detail',
        name: 'pageCompareTargetDetail',
        // name: 'compare-target-loc-detail',
        component: () => import('@/pages/compare-target-loc/Detail.vue')
      },
    ]
  },
  {
    path: '/vehicle',
    component: () => import('@/pages/vehicle/Shell.vue'),
    children: [
      {
        path: '',
        name: 'vehicle-index',
        component: () => import('@/pages/vehicle/Index.vue')
      },
      {
        path: ':id/detail',
        name: 'vehicle-detail',
        component: () => import('@/pages/vehicle/Detail.vue')
      }
    ]
  },
  {
    path: '/prediction-msisdn',
    component: () => import('@/pages/prediction-msisdn/Shell.vue'),
    children: [
      {
        path: '',
        name: 'prediction-msisdn-Index',
        component: () => import('@/pages/prediction-msisdn/Index.vue')
      },
      {
        path: ':id/detail',
        name: 'prediction-msisdn-detail',
        component: () => import('@/pages/prediction-msisdn/Detail.vue')
      },
    ]
  },
  {
    path: '/npwp',
    component: () => import('@/pages/npwp/Shell.vue'),
    children: [
      {
        path: '',
        name: 'npwp-Index',
        component: () => import('@/pages/npwp/Index.vue')
      }
    ]
  },
  {
    path: '/query-by-nik',
    component: () => import('@/pages/query-by-nik/Shell.vue'),
    children: [
      {
        path: '',
        name: 'query-by-nik-index',
        component: () => import('@/pages/query-by-nik/Index.vue')
      },
      {
        path: ':id/detail',
        name: 'query-by-nik-detail',
        component: () => import('@/pages/query-by-nik/Detail.vue')
      }
    ]
  },
  {
    path: '/profiling',
    component: () => import('@/pages/profiling/Shell.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'profiling-Index',
        component: () => import('@/pages/profiling/Index.vue')
      }
    ]
  },
  {
    path: '/bpjs',
    component: () => import('@/pages/bpjs/Shell.vue'),
    children: [
      {
        path: '',
        name: 'bpjs-Index',
        component: () => import('@/pages/bpjs/Index.vue')
      }
    ]
  },
  {
    path: '/target-tags',
    // name: 'pageTargetTagsShell',
    component: () => import('@/pages/social-media/Shell.vue'),
    children: [
      {
        path: '',
        name: 'pageTargetTagsIndex',
        component: () => import('@/pages/social-media/Index.vue')
      },
      {
        path: ':id/:source/detail',
        name: 'pageTargetTagsDetail',
        component: () => import('@/pages/social-media/Detail.vue')
      },
    ]
  },
];
// -----------------------------------------------------------

if (checkFeature('license')) {
  routes.push(
    // License auth
    {
      path: '/license-auth',
      name: 'PageLicense',
      component: PageLicense,
      meta: {
        layout: 'blank'
      }
    },
    {
      path: '/expired',
      name: 'PageLicenseExpired',
      component: PageLicenseExpired,
      meta: {
        layout: 'blank'
      }
    },

    // license pages
    {
      path: '/license/:id?',
      component: LicenseShell,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: 'pageLicenseIndex',
          component: pageLicenseIndex
        },
        {
          path: 'history',
          name: 'pageLicenseHistory',
          component: pageLicenseHistory
        },
        {
          path: 'create',
          name: 'pageLicenseCreate',
          component: pageLicenseForm
        },
        {
          path: 'edit',
          name: 'pageLicenseEdit',
          component: pageLicenseForm
        },
      ]
    },
  );
}

routes.push(
  // result
  {
    path: '/result/:key?',
    name: 'ResultShell',
    component: ResultShell,
    meta: {
      requiresAuth: true,
    },
    children: resultMenu
  },

  {
    path: '/result-realtime/:id/:key?',
    name: 'ResultRealtimeShell',
    component: ResultShell,
    meta: {
      requiresAuth: true,
    },
    children: realtimeMenu
  },
);

if (checkFeatureByAcl('query_historical', null)) {
  routes.push(
    {
      path: '/result-historical/:id/:key?',
      name: 'ResultHistoricalShell',
      component: ResultShell,
      meta: {
        requiresAuth: true,
      },
      children: historicalMenu
    },
  );
}

if (checkFeature('modules')) {
  routes.push(
    // modules
    {
      path: '/modules/:id?',
      component: ModulesShell,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: 'pageModulesIndex',
          component: pageModulesIndex
        },
        {
          path: 'create',
          name: 'pageModulesCreate',
          component: pageModulesForm
        },
        {
          path: 'edit',
          name: 'pageModulesEdit',
          component: pageModulesForm
        },
      ]
    },
  );
}

if (checkFeature('functions')) {
  routes.push(
    // functions
    {
      path: '/functions/:id?',
      component: FunctionsShell,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: 'pageFunctionsIndex',
          component: pageFunctionsIndex
        },
        {
          path: 'create',
          name: 'pageFunctionsCreate',
          component: pageFunctionsForm
        },
        {
          path: 'edit',
          name: 'pageFunctionsEdit',
          component: pageFunctionsForm
        },
      ]
    },
  );
}

if (checkFeature('roles')) {
  routes.push(
    // roles
    {
      path: '/roles/:id?',
      component: RolesShell,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: 'pageRolesIndex',
          component: pageRolesIndex
        },
        {
          path: 'create',
          name: 'pageRolesCreate',
          component: pageRolesForm
        },
        {
          path: 'edit',
          name: 'pageRolesEdit',
          component: pageRolesForm
        },
      ]
    },
  );
}

if (checkFeature('acl')) {
  routes.push(
    // acl
    {
      path:'/acl',
      // name: 'AclShell',
      component: AclShell,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '',
          name: 'pageAclEdit',
          component: pageAclForm
        },
      ]
    },
  );
}

if (checkFeature('versions')) {
  routes.push(
    // versions
    {
      path: '/versions/:id?',
      component: VersionsShell,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: 'pageVersionsIndex',
          component: pageVersionsIndex
        },
        {
          path: 'create',
          name: 'pageVersionsCreate',
          component: pageVersionsForm
        },
        {
          path: 'edit',
          name: 'pageVersionsEdit',
          component: pageVersionsForm
        },
      ]
    },
  );
}

if (checkFeature('users')) {
  routes.push(
    // users
    {
      path: '/users/:id?',
      // name: 'UsersShell',
      component: UsersShell,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: 'pageUsersIndex',
          component: pageUsersIndex
        },
        {
          path: 'create',
          name: 'pageUsersCreate',
          component: pageUsersForm
        },
        {
          path: 'edit',
          name: 'pageUsersEdit',
          component: pageUsersForm
        },
        {
          path: 'log-activity',
          name: 'pageUsersLogActivity',
          component: pageUsersLogActivity
        },
      ]
    },
  );
}

if (checkFeature('tactical_device')) {
  routes.push(
    // Tactical Device
    {
      path: '/tactical-device/:id?',
      component: TacticalDeviceShell,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: 'pageTacticalDeviceIndex',
          component: pageTacticalDeviceIndex
        },
        {
          path: 'create',
          name: 'pageTacticalDeviceCreate',
          component: pageTacticalDeviceForm
        },
        {
          path: 'edit',
          name: 'pageTacticalDeviceEdit',
          component: pageTacticalDeviceForm
        },
        {
          path: 'maps',
          name: 'pageTacticalDeviceMaps',
          component: pageTacticalDeviceMaps
        },
      ],
    },
  );
}

if (checkFeature('monitoring_log')) {
  routes.push(
    // Monitoring Log
    {
        path: '/monitoring-log',
        component: MonitoringLogShell,
        meta: {
          requiresAuth: true,
        },
        children: [
            {
                path: '',
                name: 'pageMonitoringLogIndex',
                component: pageMonitoringLogIndex
            },
            {
                path: 'chart',
                name: 'pageMonitoringLogChart',
                component: pageMonitoringLogChart
            },
        ]
    },
  );
}

if (checkFeature('tracking_history')) {
  routes.push(
    // Tracking History
    {
        path: '/tracking-history',
        name: 'pageTrackingHistory',
        component: pageTrackingHistory,
        meta: {
          requiresAuth: true,
        },
    },
  );
}

if (checkFeature('target')) {

  routes.push(
    // target
    {
      path:'/target/:id?',
      // name: targetShell,
      component: targetShell,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
            path: '',
            name: 'pageTargetIndex',
            component: pageTargetIndex
          },
          {
            path: 'create',
            name: 'pageTargetCreate',
            // page form target without fr/tensorflow
            component: (checkFeature('target|form_old') ? pageTargetFormOld : pageTargetForm)
          },
          {
            path: 'edit',
            name: 'pageTargetEdit',
            // page form target without fr/tensorflow
            component: (checkFeature('target|form_old') ? pageTargetFormOld : pageTargetForm)
          },
          {
            path: 'maps',
            name: 'pageTargetMaps',
            component: pageTargetMaps
          },
          {
            path: 'history',
            name: 'pageTargetHistory',
            component: pageTargetHistory
          },
          {
            path: 'summary',
            name: 'pageTargetSummary',
            component: pageTargetSummary
          },
          // {
          //   path:'detail/:key?',
          //   name: 'pageTargetDetail',
          //   component: ResultShell,
          //   children: targetDetailMenu
          // },
      ]
    },
    {
        path:'/target/:id/detail/:key?',
        name: 'pageTargetDetail',
        component: ResultShell,
        meta: {
            requiresAuth: true
        },
        children: targetDetailMenu
    },
  );
}

if (checkFeature('notification')) {
  routes.push(
    // Notifications
    {
        path: '/notifications',
        name: 'pageNotifications',
        component: pageNotifications,
        meta: {
          requiresAuth: true,
        },
    },
  );
}

if (checkFeature('prediction_name')) {
  routes.push(
    // Prediction Name
    {
        path: '/prediction-name',
        name: 'PredictionNameTemplate',
        component: PredictionName,
        meta: {
          requiresAuth: true,
        },
    },
  );
}

if (checkFeature('multi_tracking')) {
  routes.push(
    // multi target / multi tracking
    {
      path:'/multi-tracking/:id?',
      component: multiTrackingShell,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: 'pageMultiTrackingIndex',
          component: pageMultiTrackingIndex
        },
        {
            path: 'create',
            name: 'pageMultiTrackingCreate',
            component: pageMultiTrackingForm
        },
        {
          path: 'edit',
          name: 'pageMultiTrackingEdit',
          component: pageMultiTrackingForm
        },
        {
          path:'meeting-point/:idMeet',
          name: 'pageMultiTrackingMeetingPointDetail',
          component: pageMultiTrackingMeetingPointDetail,
        },
        {
          path: 'detail',
          name: 'pageMultiTrackingDetail',
          component: pageMultiTrackingDetailShell,
          children: [
            {
              path: 'meeting-point',
              name: 'pageMultiTrackingMeetingPoint',
              component: pageMultiTrackingMeetingPoint,
            },
            {
              path: 'location-history',
              name: 'pageMultiTrackingLocationHistory',
              component: pageMultiTrackingLocationHistory,
            },
            {
              path: 'monitoring-history',
              name: 'pageMultiTrackingMonitoringHistory',
              component: pageMultiTrackingMonitoringHistory,
            }
          ]
        },
      ]
    },
  );
}

if (checkFeature('case_management')) {
  routes.push(
    // Case Management
    {
      path: '/case-management/:id?',
      component: pageCaseManagementShell,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: 'pageCaseManagementIndex',
          component: pageCaseManagementIndex
        },
        {
          path: 'create',
          name: 'pageCaseManagementCreate',
          component: pageCaseManagementForm
        },
        {
          path: 'edit',
          name: 'pageCaseManagementEdit',
          component: pageCaseManagementForm
        }
      ]
    },
  );
}

if (checkFeature('dcd')) {
  routes.push(
    // Case Management
    {
      path: '/dcd',
      component: pageDcdShell,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: 'pageDcdIndex',
          component: pageDcdIndex
        },
        {
          path: 'create',
          name: 'pageDcdCreate',
          component: pageDcdForm
        },
        {
          path: 'edit',
          name: 'pageDcdEdit',
          component: pageDcdForm
        },
        {
          path: ':id/detail/:source?',
          name: 'pageDcdDetail',
          component: pageDcdDetail
        },
      ]
    },
  );
}

if (checkFeature('deep_void')) {
  routes.push(
    // Digital Cyber Data
    {
      path: '/digital-cyber',
      name: 'pageDeepVoidForm',
      component: pageDeepVoidForm,
      meta: {
        requiresAuth: true,
      },
    },
  );
}

if (checkFeature('ultimo')) {
  routes.push(
    // Ultimo
    {
      path: '/ultimo',
      name: 'pageUltimoShell',
      component: pageUltimoShell,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: 'pageUltimoForm',
          component: pageUltimoForm
        },
        {
          path: 'async',
          name: 'pageUltimoAsyncDataForm',
          component: pageUltimoAsyncDataForm
        },
      ]
    },
  );
}

if (checkFeature('territory_virtual')) {
  routes.push(
    // Territory Virtual
    {
      path: '/territory-virtual/:id?',
      component: TerritoryVirtualShell,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: 'pageTerritoryVirtualIndex',
          component: pageTerritoryVirtualIndex
        },
        {
          path: 'create',
          name: 'pageTerritoryVirtualCreate',
          component: pageTerritoryVirtualForm
        },
        {
          path: 'edit',
          name: 'pageTerritoryVirtualEdit',
          component: pageTerritoryVirtualForm
        },
        {
          path: 'detail',
          name: 'pageTerritoryVirtualDetail',
          component: pageTerritoryVirtualDetail
        },
        {
          path: 'detail/notification/:target_id/:msisdn',
          name: 'pageTerritoryVirtualDetailNotification',
          component: pageTerritoryVirtualDetailNotification
        },
      ]
    },
  );
}

if (checkFeature('face_recognition')) {
  routes.push(
    // Face Recognition
    {
      path: '/face-recognition',
      name: 'PageFaceRecognition',
      component: PageFaceRecognition,
      meta: {
        requiresAuth: true,
      },
    },
  );
}

if (checkFeature('log_management')) {
  routes.push(
    // Log Management
    {
      path: '/log-management',
      name: 'PageLogManagement',
      component: PageLogManagement,
      meta: {
        requiresAuth: true,
        // requiresAuth: false,
      },
    },
  );
}

if (checkFeature('log_auth')) {
  routes.push(
    // Log Management
    {
      path: '/users-log',
      name: 'PageUsersLog',
      component: PageUsersLog,
      meta: {
        requiresAuth: true,
        // requiresAuth: false,
      },
    },
  );
}

// -----------------------------------------------------------
// default
routes.push(

  // forbidden
  {
    path: '/errors/forbidden',
    name: 'PageForbidden',
    component: PageForbidden,
    meta: {
      layout: 'blank'
    },
  },

  // not found
  {
    path: '*',
    name: 'PageNotFound',
    component: PageNotFound,
    meta: {
      layout: 'blank'
    },
  },
);

export default new Router({
  mode: 'history',
  // scrollBehavior (to, from, savedPosition) {
  // scrollBehavior() {
  //     return { x: 0, y: 0 }
  // },
  scrollBehavior: function(to) {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
  // scrollBehavior(to, from, savedPosition) {
  //   if (savedPosition) {
  //     return savedPosition;
  //   } else {
  //     return { x: 0, y: 0 };
  //   }
  // },
  routes: routes
  // routes: []
});
