import axios from "@/axios-main";
import axiosOrigin from "axios";

let cancelList;
var cancelToken = axiosOrigin.CancelToken;

const searchbyName = {
  namespaced: true,
  state: {
    search_by_name: [],
    search_by_name_filter_province: [],
    search_by_name_filter_city: [],
    search_by_name_filter_district: [],
    
    load_data_status_search_by_name: false,
    load_data_status_search_by_name_filter_province: false,
    load_data_status_search_by_name_filter_city: false,
    load_data_status_search_by_name_filter_district: false,
  
    // search by name travel history
    load_data_status_search_by_name_travel_history: false,
  
    // search by name marketplace
    load_data_status_search_by_name_marketplace: false,
  
    // search by name university
    load_data_status_search_by_name_university: false,
  
    // search by name or user name personal identity
    load_data_status_search_by_name_personal_identity: {cancel:null, status:false, code:null},
  },
  mutations: {
    setSearchByName: (state, data) => {
        state.search_by_name = data;
      },
      setSearchByNameFilter: (state, [type, data]) => {
        state['search_by_name_filter_' + type] = data;
      },
      // setSearchByNameFilterProvince: (state, data) => {
      //   state.search_by_name_filter_province = data;
      // },
      // setSearchByNameFilterCity: (state, data) => {
      //   state.search_by_name_filter_city = data;
      // },
      // setSearchByNameFilterDistrict: (state, data) => {
      //   state.search_by_name_filter_district = data;
      // },
    
      // search by name travel history
      setSearchByNameTravelHistory: (state, data) => {
        state.search_by_name_travel_history = data;
      },
      // search by name marketplace
      setSearchByNameMarketplace: (state, data) => {
        state.search_by_name_marketplace = data;
      },
      // search by name university
      setSearchByNameUniversity: (state, data) => {
        state.search_by_name_university = data;
      },
    
      // search by name or username personal identity
      setSearchByNamePersonalIdentity: (state, data) => {
        state.search_by_name_personal_identity = data;
      },
    setLoadDataStatusSearchByName: (state, status) => {
        state.load_data_status_search_by_name = status;
      },
      setLoadDataStatusSearchByNameFilter: (state, [type, status]) => {
        state['load_data_status_search_by_name_filter_' + type] = status;
      },
      // setLoadDataStatusSearchByNameFilterProvince: (state, status) => {
      //   state.load_data_status_search_by_name_filter_province = status;
      // },
      // setLoadDataStatusSearchByNameFilterCity: (state, status) => {
      //   state.load_data_status_search_by_name_filter_city = status;
      // },
      // setLoadDataStatusSearchByNameFilterDistrict: (state, status) => {
      //   state.load_data_status_search_by_name_filter_district = status;
      // },
    
      // search by name travel history
      setLoadDataStatusSearchByNameTravelHistory: (state, status) => {
        state.load_data_status_search_by_name_travel_history = status;
      },
    
      // search by name marketplace
      setLoadDataStatusSearchByNameMarketplace: (state, status) => {
        state.load_data_status_search_by_name_marketplace = status;
      },
    
      // search by name university
      setLoadDataStatusSearchByNameUniversity: (state, status) => {
        state.load_data_status_search_by_name_university = status;
      },
    
      // search by name or username personal identity
      setLoadDataStatusSearchByNamePersonalIdentity: (state, status) => {
        state.load_data_status_search_by_name_personal_identity = status;
      },
  },
  getters: {},
  actions: {
    async getSearchByName({ commit, dispatch }, [keyword, limit='10', page='1', gender='', province='', city='', district='', year_of_birth='', age_range='']) {
    // console.log('================================= in getSearchByName()')
    
    commit('setLoadDataStatusSearchByName', 'loading');

    let params = new FormData();

    params.append('keyword', keyword);
    // params.append('limit', limit);
    params.append('size', limit);
    params.append('page', page);
    params.append('is_new_system', 'yes');

    params.append('gender', gender);
    params.append('province', province);
    params.append('city', city);
    params.append('district', district);
    params.append('year_of_birth', year_of_birth);
    params.append('age_range', age_range);

    await axios.post('api/v1/service/rig/search-ktp-strict-name', params)
    .then((resp) => {
      // console.log(resp)
      let data = resp.data;

      commit('setSearchByName', data);

      if (Object.keys(data).length===0) {
        commit('setLoadDataStatusSearchByName', 'empty');
      } else {
        commit('setLoadDataStatusSearchByName', true);
      }
    })
    .catch((resp) => {
      // console.log(resp)
      dispatch('errors/handleApiResponse', [(resp.response && resp.response.status ? resp.response.status : null)], { root: true });
      return 'failed';
    });
  },
  async getSearchByNameFilter({ commit, dispatch }, [type, id=null]) {
    
    // let type_title_case = type.charAt(0).toUpperCase() + type.slice(1);
    // console.log(type)
    commit('setLoadDataStatusSearchByNameFilter', [type, 'loading']);

    let params = new FormData();

    if (type === 'city') {
      params.append('province_id', id);
    } else if (type === 'district') {
      params.append('city_id', id);
    }
    // params.append('limit', 100);
    params.append('size', 100);

    await axios.post('api/v1/service/rig/location-' + type, params)
    .then((resp) => {
      // console.log(resp)
      let data = resp.data;

      commit('setSearchByNameFilter', [type, data]);

      if (Object.keys(data).length===0) {
        commit('setLoadDataStatusSearchByNameFilter', [type, 'empty']);
      } else {
        commit('setLoadDataStatusSearchByNameFilter', [type, true]);
      }
    })
    .catch((resp) => {
      // console.log(resp)
      commit('setLoadDataStatusSearchByNameFilter', [type, 'failed']);
      dispatch('handleErrors', (resp.response && resp.response.status ? resp.response.status : null));
    });
  },

// search by name travel history
  async getSearchByNameTravelHistory({ commit, dispatch, getters }, [keyword, limit='10', page='1', gender='', age_min=0, age_max=200]) {
    // console.log('================================= in getSearchByNameTravelHistory()')
    
    commit('setLoadDataStatusSearchByNameTravelHistory', 'loading');

    const ageRange = getters.getDefaultAgeRange;
    if (ageRange.set) {
      if (!age_min) {
        age_min=ageRange.min
      }
      if (!age_max) {
        age_max=ageRange.max
      }
    }

    let params = new FormData();
    params.append('name', keyword);
    params.append('limit', limit);
    params.append('page', page);

    params.append('gender', gender);
    params.append('minAge', age_min);
    params.append('maxAge', age_max);

    await axios.post('api/v1/service/ehac/ehac-search', params)
    .then((resp) => {
      // console.log(resp)
      let data = resp.data;

      commit('setSearchByNameTravelHistory', data);

      if (Object.keys(data).length===0) {
        commit('setLoadDataStatusSearchByNameTravelHistory', 'empty');
      } else {
        commit('setLoadDataStatusSearchByNameTravelHistory', true);
      }
    })
    .catch((resp) => {
      // console.log(resp)
      commit('setLoadDataStatusSearchByNameTravelHistory', 'failed');
      dispatch('handleErrors', (resp.response && resp.response.status ? resp.response.status : null));
    });
  },

  // search by name marketplace
  async getSearchByNameMarketplace({ commit, dispatch, getters }, [keyword, limit='10', page='1', gender='', year_of_birth='', age_min=0, age_max=200]) {
    // console.log('================================= in getSearchByNameMarketplace()')
    
    commit('setLoadDataStatusSearchByNameMarketplace', 'loading');

    const ageRange = getters.getDefaultAgeRange;
    if (ageRange.set) {
      if (!age_min) {
        age_min=ageRange.min
      }
      if (!age_max) {
        age_max=ageRange.max
      }
    }

    let params = new FormData();

    params.append('name', keyword);
    params.append('limit', limit);
    params.append('page', page);

    params.append('gender', gender);
    params.append('birthDate', year_of_birth);
    params.append('minAge', age_min);
    params.append('maxAge', age_max);

    await axios.post('api/v1/service/tokped/tokped-search', params)
    .then((resp) => {
      // console.log(resp)
      let data = resp.data;

      commit('setSearchByNameMarketplace', data);

      if (Object.keys(data).length===0) {
        commit('setLoadDataStatusSearchByNameMarketplace', 'empty');
      } else {
        commit('setLoadDataStatusSearchByNameMarketplace', true);
      }
    })
    .catch((resp) => {
      // console.log(resp)
      commit('setLoadDataStatusSearchByNameMarketplace', 'failed');
      dispatch('handleErrors', (resp.response && resp.response.status ? resp.response.status : null));
    });
  },

  // search by name university
  async getSearchByNameUniversity({ commit, dispatch, getters }, [keyword]) {
    // console.log('================================= in getSearchByNameUniversity()')
    
    commit('setLoadDataStatusSearchByNameUniversity', 'loading');

    let params = new FormData();

    params.append('name', keyword);

    await axios.post('api/v1/service/university/search-univ', params)
    .then((resp) => {
      // console.log(resp)
      let data = resp.data;

      commit('setSearchByNameUniversity', data);

      if (Object.keys(data).length===0) {
        commit('setLoadDataStatusSearchByNameUniversity', 'empty');
      } else {
        commit('setLoadDataStatusSearchByNameUniversity', true);
      }
    })
    .catch((resp) => {
      // console.log(resp)
      commit('setLoadDataStatusSearchByNameUniversity', 'failed');
      dispatch('handleErrors', (resp.response && resp.response.status ? resp.response.status : null));
    });
  },

  // search by name with name(last name & first name) or username
  async getSearchByNamePersonalIdentity({ commit, dispatch }, [keyword]) {
    // console.log('================================= in getSearchByNamePersonalIdentity()')
    
    commit('setLoadDataStatusSearchByNamePersonalIdentity', {cancel:null, status:'loading', code:null});

    let checkKeyword = keyword.split(' ');

    // console.log('checkKeyword')
    // console.log(checkKeyword)

    let params = new FormData();
    if (checkKeyword.length > 1) {
      params.append('first_name', checkKeyword[0]);

      checkKeyword.shift();
      
      if (checkKeyword.length > 1) {
        // checkKeyword = checkKeyword.join(' ');
        checkKeyword = checkKeyword[0];
      }

      params.append('last_name', checkKeyword);

    } else {
      params.append('username', keyword);
    }

    await axios.post('api/v1/service/personal_indentity/personal-data', params)
    .then((resp) => {
      // console.log(resp)
      let data = resp.data;
      let code = (resp.response && resp.response.status ? resp.response.status : null)

      commit('setSearchByNamePersonalIdentity', data);

      if (Object.keys(data).length===0) {
        commit('setLoadDataStatusSearchByNamePersonalIdentity', {cancel:null, status:'empty', code:code});
      } else {
        commit('setLoadDataStatusSearchByNamePersonalIdentity', {cancel:null, status:true, code:code});
      }
    })
    .catch((resp) => {
      // console.log(resp)
      let code = (resp.response && resp.response.status ? resp.response.status : null)

      commit('setLoadDataStatusSearchByNamePersonalIdentity', {cancel:null, status:'failed', code:code});
      dispatch('handleErrors', code);
    });
  },
  }
};

export default searchbyName;
