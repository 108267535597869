import axios from "@/axios-main";
import axiosOrigin from "axios";

let cancelList, cancelListRecentLocation;
var cancelToken = axiosOrigin.CancelToken;

const locationEs = {
  namespaced: true,
  state: {
    // module
    status: false,

    // tracking
    status_list: {
      status: false,
      cancel: null,
      code: null,
    },
    list: {},

    status_list_recent_location: {
      status: false,
      cancel: null,
      code: null,
    },
    list_recent_location: {},
  },
  mutations: {
    // module
    setStatus: (state, status) => {
      state.status = status;
    },

    // tracking
    setStatusList: (state, status) => {
      state.status_list = status;
    },
    setList: (state, data) => {
      state.list = data;
    },

    setStatusListRecentLocation: (state, status) => {
      state.status_list_recent_location = status;
    },
    setListRecentLocation: (state, data) => {
      state.list_recent_location = data;
    },
  },
  getters: {},
  actions: {
    async getList({ commit, dispatch, state }, [type='list', msisdn, limit='', page='', from_date='', to_date='', system_type='monitoringsingle', type_data='all', multi_tracking_id='', order_by='']) {
      
      if (state.status_list.cancel !== null && state.status_list.status === 'loading')
        await state.status_list.cancel('Operation canceled by the user.');
      
      let params = new FormData();
    
      if (type === 'last') {
        params.append('type', type);
        params.append('full_respond', true);
      } else {
        params.append('msisdn', msisdn);
        params.append('limit', limit);
        params.append('page', page);
        params.append('from_date', from_date);
        params.append('to_date', to_date);
        params.append('system_type', system_type);
        params.append('type_data', type_data);
        params.append('multi_tracking_id', multi_tracking_id);
        params.append('type', type);
        params.append('order_by', order_by);
      }

      axios.post('api/v1/service/overall/recent-location-es', params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelList = c;
          commit('setStatusList', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setList', data);

        if (Object.keys(data).length===0)
          result.status = 'empty';

        commit('setStatusList', result);
      })
      .catch((resp) => {
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelList;
        }

        commit('setStatusList', result);
        if (!axiosOrigin.isCancel(resp)) 
          dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
      });
    },

    async getListRecentLocation({ commit, dispatch, state }, [key='', type='', limit='', page='', from_date='', to_date='']) {

      if (state.status_list_recent_location.cancel !== null && state.status_list_recent_location.status === 'loading')
        await state.status_list_recent_location.cancel('Operation canceled by the user.');
      
      let params = new FormData();
      
      params.append('key', key);
      params.append('type', type);
      params.append('limit', limit);
      params.append('page', page);
      params.append('from_date', from_date);
      params.append('to_date', to_date);
      params.append('system_type', 'web');

      axios.post('api/v1/service/overall/recent-location-detail', params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelListRecentLocation = c;
          commit('setStatusListRecentLocation', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setListRecentLocation', data);

        if (Object.keys(data).length===0)
          result.status = 'empty';

        commit('setStatusListRecentLocation', result);
      })
      .catch((resp) => {
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelListRecentLocation;
        }

        commit('setStatusListRecentLocation', result);
        if (!axiosOrigin.isCancel(resp)) 
          dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
      });
    },
  }
};

export default locationEs;
