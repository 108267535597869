<template lang="pug">
.card.card--tab
    //- .card--tab__header
    //-     h2.text-xl Photos
    .card--tab__body
        .box-tabs
            vue-tabs(
                @tab-change="handleTabChange"
                ref="tabsRef"
            )
                v-tab(title="Current Photos")
                    CurrentPhotos
                v-tab(title="Photo History")
                    PhotoHistory

</template>

<script>
import { VueTabs,VTab } from 'vue-nav-tabs'
// import { mapState } from 'vuex';
import CurrentPhotos from './Photos.vue';
import PhotoHistory from './PhotoHistory.vue';

export default {
    name: 'TargetInformationPhotos',
    components: {
        VueTabs,
        VTab,
        CurrentPhotos,
        PhotoHistory,
    },
    computed: {
        // ...mapState('locationEs', [
        //     'status_list'
        // ]),
    },
    methods: {
        handleTabChange(tabIndex, newTab, oldTab){
            // console.log('startMapPhotoHistory')
            // if(newTab.title.toLowerCase() == 'location history' && this.status_list.status !== 'loading')
            //     EventEmit.$emit('startMapPhotoHistory');
        },
    },
}
</script>